import React from 'react';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import TranscriptBubble from './TranscriptBubble';

const MessagesContainer = styled(Box)(({ theme }) => ({
  flex: 1,
  padding: theme.spacing(3),
  overflowY: 'auto',
  backgroundColor: theme.palette.grey[50],
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3)
}));

const MessageGroup = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(0.5),
}));

const TranscriptContent = ({ transcript = [] }) => {
  const groupedTranscript = transcript.reduce((groups, message) => {
    const lastGroup = groups[groups.length - 1];
    if (lastGroup && lastGroup[0].speaker === message.speaker) {
      lastGroup.push(message);
    } else {
      groups.push([message]);
    }
    return groups;
  }, []);

  if (transcript.length === 0) {
    return (
      <MessagesContainer>
        <Typography 
          variant="body1" 
          color="text.secondary"
          sx={{ textAlign: 'center', mt: 4 }}
        >
          No transcript available
        </Typography>
      </MessagesContainer>
    );
  }

  return (
    <MessagesContainer>
      {groupedTranscript.map((group, groupIndex) => (
        <MessageGroup 
          key={groupIndex} 
          isAgent={group[0].speaker === 'agent'}
        >
          {group.map((message, messageIndex) => (
            <TranscriptBubble
              key={message.id}
              message={message}
              isAgent={message.speaker === 'agent'}
              showAvatar={messageIndex === group.length - 1}
            />
          ))}
        </MessageGroup>
      ))}
    </MessagesContainer>
  );
};

export default TranscriptContent; 