import React, { useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { NAV_ITEMS } from './navItems';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import { useAuth } from '../AuthContext';
import styles from './MobileNav.module.scss';
import classNames from 'classnames';

const MobileNav = ({ onNavToggle }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { logout } = useAuth();
  const [isOpen, setIsOpen] = useState(false);

  const handleLogout = () => {
    logout();
    navigate('/login');
    setIsOpen(false);
  };

  const toggleNav = (newState) => {
    setIsOpen(newState);
    onNavToggle?.(newState);
    document.body.style.overflow = newState ? 'hidden' : 'auto';
  };

  return (
    <div className={styles.mobileNavContainer}>
      <button 
        className={styles.menuButton}
        onClick={() => toggleNav(!isOpen)}
        aria-label={isOpen ? "Close menu" : "Open menu"}
      >
        {isOpen ? <CloseIcon /> : <MenuIcon />}
      </button>

      <div 
        className={classNames(styles.overlay, {
          [styles.visible]: isOpen
        })}
        onClick={() => toggleNav(false)}
      />

      <nav className={classNames(styles.mobileNav, {
        [styles.open]: isOpen
      })}>
        <div className={styles.navHeader}>
          <button 
            onClick={() => toggleNav(false)}
            className={styles.closeButton}
          >
            <CloseIcon />
          </button>
        </div>
        <List>
          {NAV_ITEMS.map((item) => (
            <ListItem
              key={item.text}
              component={Link}
              to={item.path}
              className={classNames(styles.navItem, {
                [styles.active]: location.pathname === item.path
              })}
              onClick={() => toggleNav(false)}
              disablePadding
            >
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.text} />
            </ListItem>
          ))}
          <ListItem
            onClick={handleLogout}
            className={styles.navItem}
            disablePadding
          >
            <ListItemIcon>
              <LogoutOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="Logout" />
          </ListItem>
        </List>
      </nav>
    </div>
  );
};

export default MobileNav; 