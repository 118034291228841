import React from 'react';
import { 
  Box, 
  Paper, 
  Typography, 
  IconButton,
  Grid,
  Card,
  CardContent,
  Button
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DescriptionIcon from '@mui/icons-material/Description';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

const TaxFormsView = ({ onBack }) => {
  const forms = [
    {
      name: 'W-2 Forms',
      description: 'Annual Wage and Tax Statement',
      year: '2023'
    },
    {
      name: '1099 Forms',
      description: 'Independent Contractor Income',
      year: '2023'
    },
    {
      name: '941 Form',
      description: 'Quarterly Federal Tax Return',
      quarter: 'Q1 2024'
    },
    // Add more forms
  ];

  return (
    <Box>
      <Box sx={{ mb: 4, display: 'flex', alignItems: 'center' }}>
        <IconButton onClick={onBack} sx={{ mr: 2 }}>
          <ArrowBackIcon />
        </IconButton>
        <DescriptionIcon sx={{ mr: 2, color: '#34495e' }} />
        <Typography variant="h5">Tax Forms</Typography>
      </Box>

      <Grid container spacing={3}>
        {forms.map((form, index) => (
          <Grid item xs={12} md={4} key={index}>
            <Paper elevation={0} sx={{ p: 3 }}>
              <Typography variant="h6" gutterBottom>
                {form.name}
              </Typography>
              <Typography color="text.secondary" gutterBottom>
                {form.description}
              </Typography>
              <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                {form.year || form.quarter}
              </Typography>
              <Button
                variant="outlined"
                startIcon={<FileDownloadIcon />}
                sx={{ textTransform: 'none' }}
              >
                Download
              </Button>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default TaxFormsView; 