import React from 'react';
import { 
  Box, 
  Paper, 
  Typography, 
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Chip,
  Button
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const TimeAttendanceView = ({ onBack }) => {
  const data = [
    {
      employee: 'John Doe',
      date: '2024-03-01',
      clockIn: '09:00 AM',
      clockOut: '05:00 PM',
      totalHours: 8,
      status: 'Pending'
    },
    // Add more sample data
  ];

  return (
    <Box>
      <Box sx={{ mb: 4, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <IconButton onClick={onBack} sx={{ mr: 2 }}>
            <ArrowBackIcon />
          </IconButton>
          <AccessTimeIcon sx={{ mr: 2, color: '#34495e' }} />
          <Typography variant="h5">Time & Attendance</Typography>
        </Box>
        <Button
          variant="contained"
          startIcon={<CheckCircleIcon />}
          sx={{ textTransform: 'none' }}
        >
          Approve Selected
        </Button>
      </Box>

      <Paper elevation={0} sx={{ p: 3 }}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Employee</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Clock In</TableCell>
                <TableCell>Clock Out</TableCell>
                <TableCell align="right">Total Hours</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>{row.employee}</TableCell>
                  <TableCell>{row.date}</TableCell>
                  <TableCell>{row.clockIn}</TableCell>
                  <TableCell>{row.clockOut}</TableCell>
                  <TableCell align="right">{row.totalHours}</TableCell>
                  <TableCell>
                    <Chip 
                      label={row.status} 
                      color={row.status === 'Approved' ? 'success' : 'warning'} 
                      size="small" 
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
};

export default TimeAttendanceView; 