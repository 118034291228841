import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { getItems, createItem, deleteItem } from '../services/api';
import ActiveItemsList from './items/ActiveItemsList';
import TotalSection from './items/TotalSection';
import SavedItemsModal from './items/SavedItemsModal';
import styles from './CreateProjectPage.module.scss';

const itemStyles = {
  backgroundColor: 'var(--background-paper)',
  color: 'var(--text-primary)',
  borderColor: 'var(--divider)',
  '& .MuiInputBase-root': {
    color: 'var(--text-primary)'
  },
  '& .MuiIconButton-root': {
    color: 'var(--text-secondary)',
    '&:hover': {
      color: 'var(--text-primary)'
    }
  }
};

const ProjectItemsManager = ({ onItemsChange }) => {
  const [savedItems, setSavedItems] = useState([]);
  const [estimateItems, setEstimateItems] = useState([{
    title: '',
    description: '',
    quantity: 1,
    unit_price: 0,
    unitPrice: 0,
    total: 0,
    isNew: true,
    isEdited: false,
    fromAutocomplete: false,
    saved: false,
    hideLibraryBanner: false
  }]);
  const [isManageModalOpen, setIsManageModalOpen] = useState(false);
  const [itemToEdit, setItemToEdit] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  useEffect(() => {
    const fetchItems = async () => {
      try {
        const fetchedItems = await getItems();
        setSavedItems(fetchedItems);
      } catch (error) {
        console.error('Error fetching items:', error);
      }
    };
    fetchItems();
  }, []);

  const handleAddItem = (item = {}, index = null) => {
    const emptyItem = {
      title: '',
      description: '',
      quantity: 1,
      unit_price: 0,
      unitPrice: 0,
      total: 0,
      isNew: true,
      isEdited: false,
      fromAutocomplete: false,
      saved: false,
      hideLibraryBanner: false
    };
    
    handleAddToEstimate(emptyItem);
  };

  const handleAddToEstimate = (item, index) => {
    setEstimateItems(prev => {
      const newItems = [...prev];
      if (index !== undefined) {
        newItems[index] = item;
      } else {
        newItems.push(item);
      }
      return newItems;
    });
  };

  const handleAutocompleteSelect = (index, selectedItem) => {
    console.log('Autocomplete selected - Input:', selectedItem);
    
    const updatedItem = {
      id: selectedItem.id,
      title: selectedItem.title || '',
      description: selectedItem.description || '',
      quantity: 1,
      unit_price: selectedItem.unit_price || selectedItem.unitPrice || 0,
      unitPrice: selectedItem.unit_price || selectedItem.unitPrice || 0,
      total: selectedItem.unit_price || selectedItem.unitPrice || 0,
      fromAutocomplete: true,
      isFromLibrary: true,
      isSavedItem: true,
      isNew: false,
      isEdited: false,
      hideLibraryBanner: true,
      saved: true,
      id_check: true,
      saved_check: true,
      autocomplete_check: true,
      banner_check: false
    };
    
    console.log('Autocomplete - Final item:', updatedItem);
    handleAddToEstimate(updatedItem, index);
  };

  const handleSaveItem = (index) => {
    setSelectedIndex(index);
    setIsManageModalOpen(true);
  };

  const handleDeleteSavedItem = async (itemId) => {
    try {
      await deleteItem(itemId);
      const updatedItems = await getItems();
      setSavedItems(updatedItems);
    } catch (error) {
      console.error('Error deleting item:', error);
    }
  };

  const handleDeleteItem = (index) => {
    setEstimateItems(prev => prev.filter((_, i) => i !== index));
  };

  const handleDirectSave = async (index) => {
    console.log('Attempting to save item at index:', index);
    const itemToSave = estimateItems[index];
    console.log('Item to save:', itemToSave);

    try {
      const saveData = {
        title: itemToSave.title,
        description: itemToSave.description,
        unit_price: Number(itemToSave.unit_price || itemToSave.unitPrice) || 0,
        quantity: Number(itemToSave.quantity) || 1,
      };

      console.log('Saving item with data:', saveData);
      const savedItem = await createItem(saveData);
      console.log('Item saved successfully:', savedItem);

      const updatedSavedItems = await getItems();
      setSavedItems(updatedSavedItems);

      const updatedItem = {
        ...itemToSave,
        saved: true,
        id: savedItem.id,
        hideLibraryBanner: true
      };
      handleAddToEstimate(updatedItem, index);

      setShowSuccessModal(true);
      setTimeout(() => setShowSuccessModal(false), 2000);

    } catch (error) {
      console.error('Error saving item:', error);
    }
  };

  const handleEditItem = ({ index, field, value }) => {
    console.log('handleEditItem called with:', { index, field, value });
    const currentItem = estimateItems[index];
    
    // First, check if this is a library/saved item
    const isFromLibrary = !!(
      currentItem.fromAutocomplete || 
      currentItem.isFromLibrary || 
      currentItem.isSavedItem || 
      currentItem.saved || 
      currentItem.id
    );

    // Create base updated item with ALL flags explicitly set
    let updatedItem = {
      ...currentItem,
      fromAutocomplete: isFromLibrary,
      isFromLibrary: isFromLibrary,
      isSavedItem: isFromLibrary,
      isNew: false,
      isEdited: true,
      hideLibraryBanner: false,
      saved: false,
      id_check: !!currentItem.id,
      saved_check: true,
      autocomplete_check: isFromLibrary,
      banner_check: true
    };

    // Apply the edit
    switch (field) {
      case 'quantity':
        updatedItem.quantity = value === '' ? 1 : Number(value);
        break;
      case 'unit_price':
      case 'unitPrice':
        const priceValue = value === '' ? 0 : Number(value);
        updatedItem.unit_price = priceValue;
        updatedItem.unitPrice = priceValue;
        break;
      default:
        updatedItem[field] = value;
    }

    // Update total
    if (field === 'quantity' || field === 'unit_price' || field === 'unitPrice') {
      updatedItem.total = (updatedItem.quantity || 1) * (updatedItem.unit_price || 0);
    }
    
    console.log('Final item with all flags:', updatedItem);
    handleAddToEstimate(updatedItem, index);
  };

  useEffect(() => {
    onItemsChange(estimateItems);
  }, [estimateItems, onItemsChange]);

  return (
    <Box sx={{ p: 3 }}>
      <Box sx={{ 
        display: 'flex', 
        gap: 2, 
        mb: 3,
        px: 3,
        py: 2,
        color: 'var(--text-primary)',
        borderBottom: '2px solid #f6f6f6',
        '& > div': {
          color: 'var(--text-primary)',
          fontWeight: 600,
          fontSize: '1.1rem',
          textTransform: 'uppercase',
          letterSpacing: '0.8px'
        }
      }}>
        <div style={{ flex: '1 1 50%', color: 'var(--text-primary)' }}>Title/Description</div>
        <div style={{ 
          width: '100px', 
          textAlign: 'right', 
          paddingRight: '14px',
          marginRight: '105px',
          color: 'var(--text-primary) !important'
        }}>Qty</div>
        <div style={{ 
          width: '100px', 
          textAlign: 'right', 
          paddingRight: '14px',
          marginRight: '110px',
          color: 'var(--text-primary) !important'
        }}>Price</div>
        <div style={{ 
          width: '100px', 
          textAlign: 'right', 
          paddingRight: '14px',
          marginRight: '115px',
          color: 'var(--text-primary)'
        }}>Total</div>
      </Box>

      <ActiveItemsList
        items={estimateItems}
        onEdit={handleEditItem}
        onAddItem={handleAddItem}
        onDelete={handleDeleteItem}
        onSaveItem={handleDirectSave}
        savedItems={savedItems}
        onAutocompleteSelect={handleAutocompleteSelect}
        setIsManageModalOpen={setIsManageModalOpen}
      />

      <TotalSection items={estimateItems} />

      <SavedItemsModal
        open={isManageModalOpen}
        onClose={() => setIsManageModalOpen(false)}
        savedItems={savedItems}
        itemToEdit={itemToEdit}
        setItemToEdit={setItemToEdit}
        onDelete={handleDeleteSavedItem}
      />

      <Dialog 
        open={showSuccessModal} 
        onClose={() => setShowSuccessModal(false)}
        maxWidth="xs"
        fullWidth
      >
        <Box sx={{ position: 'relative' }}>
          <IconButton
            onClick={() => setShowSuccessModal(false)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: 'white',
              '&:hover': {
                backgroundColor: 'rgba(255, 255, 255, 0.1)'
              }
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogTitle sx={{ 
            backgroundColor: '#4caf50', 
            color: 'white',
            textAlign: 'center',
            pb: 2
          }}>
            <Box sx={{ 
              display: 'flex', 
              flexDirection: 'column', 
              alignItems: 'center',
              gap: 1
            }}>
              <CheckCircleOutlineIcon 
                sx={{ 
                  fontSize: '48px',
                  animation: 'checkmark 0.5s ease-in-out',
                  '@keyframes checkmark': {
                    '0%': {
                      transform: 'scale(0)',
                      opacity: 0
                    },
                    '50%': {
                      transform: 'scale(1.2)',
                    },
                    '100%': {
                      transform: 'scale(1)',
                      opacity: 1
                    }
                  }
                }} 
              />
              Success
            </Box>
          </DialogTitle>
          <DialogContent sx={{ 
            mt: 2, 
            textAlign: 'center',
            color: '#666'
          }}>
            Item has been saved successfully.
          </DialogContent>
        </Box>
      </Dialog>
    </Box>
  );
};

export default ProjectItemsManager; 