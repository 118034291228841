import React, { useState } from 'react';
import {
  Box,
  Card,
  Grid,
  Typography,
  Button,
  Chip,
  IconButton,
  LinearProgress,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import {
  Add as AddIcon,
  MoreVert as MoreIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  ContentCopy as DuplicateIcon,
  Group as GroupIcon,
} from '@mui/icons-material';

const AudienceCard = ({ audience, onMenuClick }) => (
  <Card sx={{
    height: '100%',
    borderRadius: 2,
    transition: 'transform 0.2s ease-in-out',
    '&:hover': {
      transform: 'translateY(-4px)',
      boxShadow: (theme) => theme.shadows[4],
    },
  }}>
    <Box sx={{ p: 3 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
        <Chip
          icon={<GroupIcon />}
          label={`${audience.contacts.toLocaleString()} Contacts`}
          size="small"
          sx={{ backgroundColor: 'primary.light', color: 'primary.dark' }}
        />
        <IconButton size="small" onClick={(e) => onMenuClick(e, audience)}>
          <MoreIcon />
        </IconButton>
      </Box>

      <Typography variant="h6" sx={{ mb: 1 }}>
        {audience.name}
      </Typography>
      <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
        {audience.description}
      </Typography>

      <Box sx={{ mt: 3 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
          <Typography variant="caption" color="text.secondary">
            Engagement Rate
          </Typography>
          <Typography variant="caption" color="text.secondary">
            {audience.engagementRate}%
          </Typography>
        </Box>
        <LinearProgress
          variant="determinate"
          value={audience.engagementRate}
          sx={{
            height: 6,
            borderRadius: 3,
            backgroundColor: 'grey.100',
            '& .MuiLinearProgress-bar': {
              borderRadius: 3,
              backgroundImage: 'linear-gradient(45deg, #2196f3 30%, #21cbf3 90%)',
            }
          }}
        />
      </Box>
    </Box>
  </Card>
);

const CampaignAudiences = () => {
  const [menuAnchor, setMenuAnchor] = useState(null);
  const [selectedAudience, setSelectedAudience] = useState(null);

  // Mock data - replace with API call
  const audiences = [
    {
      id: 1,
      name: 'High-Value Customers',
      description: 'Customers with lifetime value > $1000',
      contacts: 1234,
      engagementRate: 75,
      lastUpdated: '2024-03-15',
    },
    {
      id: 2,
      name: 'Recent Signups',
      description: 'Users who signed up in the last 30 days',
      contacts: 567,
      engagementRate: 45,
      lastUpdated: '2024-03-14',
    },
    // Add more audience segments...
  ];

  const handleMenuClick = (event, audience) => {
    setMenuAnchor(event.currentTarget);
    setSelectedAudience(audience);
  };

  const handleMenuClose = () => {
    setMenuAnchor(null);
    setSelectedAudience(null);
  };

  return (
    <Box sx={{ p: 3 }}>
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'space-between',
        alignItems: 'center',
        mb: 4
      }}>
        <Typography variant="h5" sx={{ fontWeight: 600 }}>
          Audience Segments
        </Typography>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          sx={{
            borderRadius: 2,
            textTransform: 'none',
            background: 'linear-gradient(45deg, #2196f3 30%, #21cbf3 90%)',
          }}
        >
          Create Segment
        </Button>
      </Box>

      <Grid container spacing={3}>
        {audiences.map((audience) => (
          <Grid item xs={12} md={4} key={audience.id}>
            <AudienceCard
              audience={audience}
              onMenuClick={handleMenuClick}
            />
          </Grid>
        ))}
      </Grid>

      <Menu
        anchorEl={menuAnchor}
        open={Boolean(menuAnchor)}
        onClose={handleMenuClose}
        PaperProps={{
          sx: { width: 200, borderRadius: 2 }
        }}
      >
        <MenuItem onClick={handleMenuClose}>
          <ListItemIcon>
            <EditIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Edit Segment</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleMenuClose}>
          <ListItemIcon>
            <DuplicateIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Duplicate</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleMenuClose} sx={{ color: 'error.main' }}>
          <ListItemIcon>
            <DeleteIcon fontSize="small" color="error" />
          </ListItemIcon>
          <ListItemText>Delete</ListItemText>
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default CampaignAudiences; 