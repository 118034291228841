import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { createClient } from '../services/api';
import { TextField, Button, Typography, Container, Box, Grid, Alert } from '@mui/material';
import styles from './CreateClient.module.scss';

const CreateClient = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [phone, setPhone] = useState('');
  const [company, setCompany] = useState('');
  const [address, setAddress] = useState('');
  const [notes, setNotes] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    try {
      const clientData = {
        name,
        email,
        password,
        phone,
        company,
        address,
        notes
      };
      console.log('Submitting client data:', clientData);
      const response = await createClient(clientData);
      console.log('Create client response:', response);
      navigate('/admin/clients');
    } catch (err) {
      console.error('Error creating client:', err);
      if (err.response) {
        console.error('Error response data:', err.response.data);
        console.error('Error response status:', err.response.status);
        console.error('Error response headers:', err.response.headers);
        setError(`Failed to create client: ${err.response.data.error || JSON.stringify(err.response.data)}`);
      } else {
        setError('Failed to create client. Please try again.');
      }
    }
  };

  return (
    <Container className={styles.createClientContainer}>
      <Box className={styles.formWrapper}>
        <Typography 
          variant="h5" 
          component="h1" 
          gutterBottom
          sx={{
            fontWeight: 600,
            color: '#2c3e50',
            mb: 3,
            borderBottom: '2px solid #3498db',
            paddingBottom: '8px'
          }}
        >
          Create New Client
        </Typography>

        <Box component="form" onSubmit={handleSubmit} className={styles.form}>
          <Grid container spacing={2}>
            {/* Personal Information */}
            <Grid item xs={12}>
              <Typography variant="subtitle2" sx={{ mb: 2, color: '#7f8c8d', fontWeight: 500 }}>
                Personal Information
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
                fullWidth
                variant="outlined"
                sx={{ mb: 2 }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                fullWidth
                variant="outlined"
                sx={{ mb: 2 }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                fullWidth
                variant="outlined"
                sx={{ mb: 2 }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Phone"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                fullWidth
                variant="outlined"
                sx={{ mb: 2 }}
              />
            </Grid>

            {/* Business Information */}
            <Grid item xs={12}>
              <Typography variant="subtitle2" sx={{ mt: 2, mb: 2, color: '#7f8c8d', fontWeight: 500 }}>
                Business Information
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Company"
                value={company}
                onChange={(e) => setCompany(e.target.value)}
                fullWidth
                variant="outlined"
                sx={{ mb: 2 }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Address"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                fullWidth
                variant="outlined"
                sx={{ mb: 2 }}
              />
            </Grid>

            {/* Additional Information */}
            <Grid item xs={12}>
              <Typography variant="subtitle2" sx={{ mt: 2, mb: 2, color: '#7f8c8d', fontWeight: 500 }}>
                Additional Information
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Notes"
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
                fullWidth
                multiline
                rows={4}
                variant="outlined"
                sx={{ mb: 3 }}
              />
            </Grid>
          </Grid>

          {error && (
            <Alert severity="error" sx={{ mb: 2 }}>
              {error}
            </Alert>
          )}

          <Button 
            type="submit" 
            variant="contained" 
            fullWidth
            sx={{
              mt: 2,
              py: 1.5,
              backgroundColor: '#3498db',
              '&:hover': {
                backgroundColor: '#2980b9'
              }
            }}
          >
            Create Client
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default CreateClient;
