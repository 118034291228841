import React from 'react';
import {
  Box,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Slider,
} from '@mui/material';

const AnimationControls = ({ section, onUpdate }) => {
  const animations = {
    none: 'No Animation',
    fadeIn: 'Fade In',
    slideIn: 'Slide In',
    zoomIn: 'Zoom In',
    bounceIn: 'Bounce In',
  };

  const handleChange = (property, value) => {
    onUpdate({
      ...section,
      animation: {
        ...section.animation,
        [property]: value,
      },
    });
  };

  return (
    <Box sx={{ p: 2, width: 300 }}>
      <Typography variant="subtitle2" gutterBottom>
        Animation Settings
      </Typography>
      
      <FormControl fullWidth size="small" sx={{ mb: 2 }}>
        <InputLabel>Animation Type</InputLabel>
        <Select
          value={section.animation?.type || 'none'}
          onChange={(e) => handleChange('type', e.target.value)}
          label="Animation Type"
        >
          {Object.entries(animations).map(([key, label]) => (
            <MenuItem key={key} value={key}>{label}</MenuItem>
          ))}
        </Select>
      </FormControl>

      <Typography variant="caption" gutterBottom>
        Duration (seconds)
      </Typography>
      <Slider
        value={section.animation?.duration || 0.3}
        onChange={(_, value) => handleChange('duration', value)}
        min={0.1}
        max={2}
        step={0.1}
        valueLabelDisplay="auto"
      />

      <Typography variant="caption" gutterBottom>
        Delay (seconds)
      </Typography>
      <Slider
        value={section.animation?.delay || 0}
        onChange={(_, value) => handleChange('delay', value)}
        min={0}
        max={1}
        step={0.1}
        valueLabelDisplay="auto"
      />
    </Box>
  );
};

export default AnimationControls; 