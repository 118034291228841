import React from 'react';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import MessageBubble from './MessageBubble';

const MessagesContainer = styled(Box)(({ theme }) => ({
  flex: 1,
  padding: theme.spacing(3),
  overflowY: 'auto',
  backgroundColor: theme.palette.grey[50],
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3)
}));

const MessageGroup = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
  maxWidth: '70%',
  alignSelf: props => props.sent ? 'flex-end' : 'flex-start'
}));

const MessagesList = ({ messages = [] }) => {
  if (messages.length === 0) {
    return (
      <MessagesContainer>
        <Typography 
          variant="body1" 
          color="text.secondary"
          sx={{ 
            textAlign: 'center', 
            mt: 4,
            fontFamily: 'Urbanist, sans-serif' 
          }}
        >
          No messages yet
        </Typography>
      </MessagesContainer>
    );
  }

  const groupedMessages = messages.reduce((groups, message) => {
    const lastGroup = groups[groups.length - 1];
    if (lastGroup && lastGroup[0].sent === message.sent) {
      lastGroup.push(message);
    } else {
      groups.push([message]);
    }
    return groups;
  }, []);

  return (
    <MessagesContainer>
      {groupedMessages.map((group, groupIndex) => (
        <MessageGroup key={groupIndex} sent={group[0].sent}>
          {group.map((message) => (
            <MessageBubble 
              key={message.id} 
              message={message} 
              showAvatar={group.indexOf(message) === group.length - 1}
            />
          ))}
        </MessageGroup>
      ))}
    </MessagesContainer>
  );
};

export default MessagesList; 