import React, { useState, useEffect, useCallback } from 'react';
import { getProjectEvents, getAllEvents, updateEvent, updateProjectDates, updateProjectStatus } from '../services/api';
import { Typography, Button, Paper } from '@mui/material';
import { Add as AddIcon } from '@mui/icons-material';
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import EventForm from './EventForm';
import EventDetails from './EventDetails';
import UnscheduledEventsBanner from './UnscheduledEventsBanner';
import ScheduleEventsModal from './ScheduleEventsModal';
import styles from './ScheduleUpdater.module.scss';
import moment from 'moment';

const stringToColor = (str) => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  const hue = Math.abs(hash % 360);
  return `hsl(${hue}, 70%, 80%)`;
};

const ScheduleUpdater = ({ projectId, projectName }) => {
  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [isDetailsOpen, setIsDetailsOpen] = useState(false);
  const [unscheduledEvents, setUnscheduledEvents] = useState([]);
  const [showScheduleModal, setShowScheduleModal] = useState(false);
  const calendarRef = React.useRef(null);

  const formatCalendarEvents = useCallback((scheduledEvents) => {
    return scheduledEvents.map(event => ({
      id: event.id,
      title: event.title,
      start: event.time_slots[0].start_time,
      end: event.time_slots[0].end_time,
      description: event.description,
      value: event.value,
      status: event.status,
      employees: event.employees || [],
      project: event.project,
      project_id: event.project_id,
      emoji: event.emoji,
      display: 'block',
      classNames: [styles.eventWrapper],
      extendedProps: {
        ...event,
        project: event.project,
        time_slots: event.time_slots
      }
    }));
  }, []);

  const fetchEvents = useCallback(async () => {
    if (!projectId) return;

    try {
      const calendar = calendarRef.current;
      if (!calendar) return;

      const view = calendar.getApi().view;
      const filters = {
        startDate: view.activeStart.toISOString(),
        endDate: view.activeEnd.toISOString()
      };

      // Get ALL events for the calendar
      const response = await getAllEvents(filters);
      console.log('Raw events from API:', response);
      
      if (response && Array.isArray(response)) {
        // Format all events with time slots for the calendar
        const formattedEvents = response
          .filter(event => event.time_slots && event.time_slots.length > 0)
          .map(event => ({
            id: event.id,
            title: event.title,
            start: event.time_slots[0].start_time,
            end: event.time_slots[0].end_time,
            description: event.description,
            value: event.value,
            status: event.status,
            employees: event.employees || [],
            project: event.project,
            project_id: event.project_id,
            emoji: event.emoji,
            classNames: [styles.eventWrapper],
            display: 'block',
            allDay: false,
            extendedProps: {
              ...event,
              project: event.project,
              time_slots: event.time_slots,
              color: stringToColor(event.title)
            }
          }));

        console.log('Formatted calendar events:', formattedEvents);
        setEvents(formattedEvents);
        
        // Only get unscheduled events for THIS project
        const projectUnscheduledEvents = response
          .filter(event => 
            event.project_id === parseInt(projectId) && 
            (!event.time_slots || event.time_slots.length === 0)
          );
        
        console.log('Unscheduled events for this project:', projectUnscheduledEvents);
        setUnscheduledEvents(projectUnscheduledEvents);
      }
    } catch (error) {
      console.error('Error fetching events:', error);
    }
  }, [projectId]);

  useEffect(() => {
    if (projectId) {
      fetchEvents();
    }
  }, [projectId, fetchEvents]);

  const handleEventClick = useCallback((info) => {
    const event = info.event;
    setSelectedEvent({
      id: event.id,
      title: event.title,
      description: event.extendedProps.description,
      value: event.extendedProps.value,
      status: event.extendedProps.status,
      employees: event.extendedProps.employees,
      time_slots: [{
        start_time: event.start.toISOString(),
        end_time: event.end.toISOString()
      }],
      project_id: event.extendedProps.project_id
    });
    setIsDetailsOpen(true);
  }, []);

  const handleScheduleSubmit = useCallback(async (filledTimeSlots) => {
    try {
      await Promise.all(
        unscheduledEvents.map(async (event, index) => {
          const timeSlot = filledTimeSlots[index];
          if (timeSlot?.start_time && timeSlot?.end_time) {
            await updateEvent(event.id, {
              ...event,
              time_slots: [{
                start_time: timeSlot.start_time,
                end_time: timeSlot.end_time
              }],
              status: 'scheduled'
            });
          }
        })
      );
      
      const datesResponse = await updateProjectDates(projectId);
      if (datesResponse.start_time && datesResponse.end_time) {
        await updateProjectStatus(projectId, 'scheduled');
      }
      
      await fetchEvents();
      setShowScheduleModal(false);
    } catch (error) {
      console.error('Error in schedule submit:', error);
    }
  }, [unscheduledEvents, projectId, fetchEvents]);

  const renderEventContent = useCallback((eventInfo) => {
    const { event } = eventInfo;
    const eventData = event.extendedProps;

    return (
      <div className={styles.eventCard} style={{ '--event-color': eventData.color }}>
        <div className={styles.eventContent}>
          <div className={styles.eventHeader}>
            <div className={styles.headerTop}>
              {eventData.status && (
                <span className={`${styles.eventStatus} ${styles[eventData.status.toLowerCase()]}`}>
                  {eventData.status}
                </span>
              )}
            </div>
            <div className={styles.titleRow}>
              <div className={styles.titleStack}>
                <div className={styles.eventTitle}>{event.title}</div>
              </div>
            </div>
          </div>
          
          <div className={styles.eventDetails}>
            <div className={styles.eventTime}>
              {moment(event.start).format('h:mm A')} - {moment(event.end).format('h:mm A')}
            </div>
            
            {eventData.value && (
              <div className={styles.eventValue}>
                ${eventData.value.toFixed(2)}
              </div>
            )}
            
          </div>
        </div>
      </div>
    );
  }, []);

  return (
    <div className={styles.scheduleUpdater}>
      <div className={styles.header}>
        <Typography variant="h4" className={styles.title}>
          Schedule
        </Typography>
        {projectId && (
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => {
              setSelectedEvent(null);
              setIsFormOpen(true);
            }}
            className={styles.addButton}
          >
            Add Event
          </Button>
        )}
      </div>

      {projectId && unscheduledEvents.length > 0 && (
        <UnscheduledEventsBanner 
          count={unscheduledEvents.length}
          onClick={() => setShowScheduleModal(true)}
        />
      )}

      <Paper className={styles.calendarWrapper}>
        <FullCalendar
          ref={calendarRef}
          plugins={[timeGridPlugin, dayGridPlugin, interactionPlugin]}
          initialView="timeGridWeek"
          headerToolbar={{
            left: 'prev,next today',
            center: 'title',
            right: 'timeGridWeek,timeGridDay,dayGridMonth'
          }}
          events={events}
          eventContent={renderEventContent}
          eventClick={handleEventClick}
          selectable={!!projectId}
          select={(info) => {
            setSelectedEvent(null);
            setIsFormOpen(true);
          }}
          slotMinTime="00:00:00"
          slotMaxTime="24:00:00"
          expandRows={true}
          slotDuration="00:30:00"
          allDaySlot={false}
          height="100%"
          datesSet={fetchEvents}
          slotLabelFormat={{
            hour: 'numeric',
            minute: '2-digit',
            meridiem: 'short'
          }}
          nowIndicator={true}
          scrollTime={moment().format('HH:mm:ss')}
          businessHours={{
            daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
            startTime: '07:00',
            endTime: '17:00',
          }}
          eventTimeFormat={{
            hour: 'numeric',
            minute: '2-digit',
            meridiem: 'short'
          }}
          displayEventEnd={true}
          eventDisplay="block"
          eventOverlap={false}
          eventConstraint="businessHours"
        />
      </Paper>

      <EventForm
        open={isFormOpen}
        onClose={() => {
          setIsFormOpen(false);
          setSelectedEvent(null);
        }}
        projectId={projectId}
        onEventCreated={handleScheduleSubmit}
        event={selectedEvent}
      />
      <EventDetails
        eventId={selectedEvent?.id}
        open={isDetailsOpen}
        onClose={() => {
          setIsDetailsOpen(false);
          setSelectedEvent(null);
        }}
        onEventUpdated={handleScheduleSubmit}
      />
      <ScheduleEventsModal
        open={showScheduleModal}
        onClose={() => setShowScheduleModal(false)}
        events={unscheduledEvents}
        onSubmit={handleScheduleSubmit}
        title="Schedule Unscheduled Events"
      />
    </div>
  );
};

export default ScheduleUpdater;