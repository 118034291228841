import React from 'react';
import { Paper, Typography } from '@mui/material';
import { ScatterChart, Scatter, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

const EstimateTimelineChart = ({ estimates, height }) => {
  const prepareData = () => {
    return estimates.map(est => ({
      date: new Date(est.issue_date).getTime(),
      value: (Array.isArray(est.items) ? est.items : []).reduce((sum, item) => {
        return sum + (Number(item.quantity) || 0) * (Number(item.unit_price) || 0);
      }, 0),
      status: est.status
    }));
  };

  const data = prepareData();
  const statusColors = {
    approved: '#4caf50',
    pending: '#ff9800',
    draft: '#2196f3'
  };

  return (
    <ResponsiveContainer width="100%" height={height}>
      <ScatterChart>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis 
          dataKey="date" 
          domain={['auto', 'auto']}
          type="number"
          tickFormatter={timestamp => new Date(timestamp).toLocaleDateString()}
        />
        <YAxis 
          dataKey="value"
          tickFormatter={value => `$${value.toLocaleString()}`}
        />
        <Tooltip 
          formatter={(value, name) => [
            name === 'value' ? `$${value.toLocaleString()}` : value,
            name === 'value' ? 'Value' : 'Date'
          ]}
          labelFormatter={timestamp => new Date(timestamp).toLocaleDateString()}
        />
        {Object.keys(statusColors).map(status => (
          <Scatter
            key={status}
            name={status}
            data={data.filter(d => d.status === status)}
            fill={statusColors[status]}
          />
        ))}
      </ScatterChart>
    </ResponsiveContainer>
  );
};

export default EstimateTimelineChart; 