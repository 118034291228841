import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Paper, 
  Typography, 
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { getOneOffJobs } from '../../../services/api';
import SearchIcon from '@mui/icons-material/Search';
import styles from '../../../components/Employees.module.scss';
import { useNavigate } from 'react-router-dom';

const OneOffJobsView = ({ onBack }) => {
  const [jobs, setJobs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchJobs = async () => {
      try {
        setLoading(true);
        const response = await getOneOffJobs();
        console.log('Raw jobs data:', response); // Debug log
        
        // Format the jobs data
        const formattedJobs = response.map(event => ({
          id: event.id,
          date: event.time_slots?.[0]?.start_time ? 
            new Date(event.time_slots[0].start_time).toLocaleDateString() : 'Unscheduled',
          client: event.project?.client?.name || 'N/A',
          projectName: event.project?.name || 'N/A',
          team: event.employees?.map(emp => emp.name).join(', ') || 'Unassigned',
          revenue: event.value || 0,
          project: {
            id: event.project?.id
          }
        }));

        console.log('Formatted jobs:', formattedJobs); // Debug log
        setJobs(formattedJobs);
      } catch (err) {
        console.error('Error fetching one-off jobs:', err);
        setError('Failed to load jobs');
      } finally {
        setLoading(false);
      }
    };

    fetchJobs();
  }, []);

  // Filter jobs based on search term
  const filteredJobs = jobs.filter(job => 
    job.client.toLowerCase().includes(searchTerm.toLowerCase()) ||
    job.projectName.toLowerCase().includes(searchTerm.toLowerCase()) ||
    job.team.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const formatCurrency = (value) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD'
    }).format(value);
  };

  const handleRowClick = (projectId) => {
    navigate(`/admin/projects/${projectId}`);
  };

  return (
    <Box>
      <Box sx={{ mb: 4, display: 'flex', alignItems: 'center' }}>
        <IconButton onClick={onBack} sx={{ mr: 2 }}>
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h5">One-Off Jobs</Typography>
      </Box>

      {/* Search Bar */}
      <div className={styles.searchContainer} style={{ marginBottom: '24px' }}>
        <div className={styles.searchIcon}>
          <SearchIcon />
        </div>
        <input
          type="text"
          placeholder="Search jobs..."
          className={styles.searchInput}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>

      <Paper elevation={0} sx={{ p: 3, border: '1px solid #e0e0e0' }}>
        <TableContainer>
          {loading ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
              <CircularProgress />
            </Box>
          ) : error ? (
            <Typography color="error" align="center">{error}</Typography>
          ) : (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Date</TableCell>
                  <TableCell>Client</TableCell>
                  <TableCell>Project</TableCell>
                  <TableCell>Team</TableCell>
                  <TableCell align="right">Project Value</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredJobs.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={5} align="center">
                      <Typography color="textSecondary">
                        {loading ? 'Loading...' : 'No one-off jobs found'}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ) : (
                  filteredJobs.map((job) => (
                    <TableRow 
                      key={job.id}
                      onClick={() => handleRowClick(job.project.id)}
                      sx={{ 
                        '&:hover': { 
                          backgroundColor: 'rgba(0, 0, 0, 0.04)',
                          cursor: 'pointer' 
                        } 
                      }}
                    >
                      <TableCell>{job.date}</TableCell>
                      <TableCell>{job.client}</TableCell>
                      <TableCell>{job.projectName}</TableCell>
                      <TableCell>{job.team}</TableCell>
                      <TableCell align="right">{formatCurrency(job.revenue)}</TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          )}
        </TableContainer>
      </Paper>
    </Box>
  );
};

export default OneOffJobsView;
