import React from 'react';
import { Paper, Typography, Box } from '@mui/material';

const NoTimesheetsBanner = ({ projectName }) => {
  return (
    <Paper
      elevation={0}
      sx={{
        p: 1.5,
        mb: 2,
        backgroundColor: 'rgba(211, 47, 47, 0.15)',
        backdropFilter: 'blur(8px)',
        WebkitBackdropFilter: 'blur(8px)',
        color: '#d32f2f',
        transition: 'all 0.2s ease',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 1,
        mx: 2,
        border: '1px solid rgba(211, 47, 47, 0.2)',
        position: 'relative',
        overflow: 'hidden',
        '&::before': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background: 'linear-gradient(135deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0))',
          zIndex: 1
        }
      }}
    >
      <Box sx={{ 
        display: 'flex', 
        alignItems: 'center', 
        gap: 1.5,
        position: 'relative',
        zIndex: 2
      }}>
        <Typography sx={{ 
          fontSize: '0.95rem',
          fontWeight: 500,
          letterSpacing: 0.3,
          display: 'flex',
          alignItems: 'center',
          gap: 1
        }}>
          🛑 No timesheets found for {projectName || 'this project'}
        </Typography>
      </Box>
    </Paper>
  );
};

export default NoTimesheetsBanner; 