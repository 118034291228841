import React, { useState, useEffect, useCallback } from 'react';
import { 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogActions, 
  Button, 
  Typography,
  Grid,
  Paper,
  Avatar,
  IconButton,
  Chip
} from '@mui/material';
import { formatDateTime } from '../utils/dateUtils';
import { getEventDetails, getEventEmployees, updateEventStatus } from '../services/api';
import EventForm from './EventForm';
import styles from './EventDetails.module.scss';
// Add these imports
import { keyframes } from '@emotion/react';
import { 
  Work as WorkIcon, 
  LocationOn, 
  AttachMoney, 
  Schedule, 
  Person,
  Notes as DescriptionIcon,
  PlayArrow,
  Stop,
  Close as CloseIcon,
  CheckCircle as CheckCircleIcon,
  Update as UpdateIcon,
  Receipt as ReceiptIcon,
  Circle as CircleIcon,
  Pending as PendingIcon
} from '@mui/icons-material';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import EmployeeAssignment from './EmployeeAssignment';

// Modify the animation to include a translate effect
const fadeInUp = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

// Simplified styles
const sectionTitleStyles = {
  fontWeight: 500,
  color: 'text.primary',
  mb: 2,
  display: 'flex',
  alignItems: 'center',
  gap: 1,
  borderBottom: '1px solid #eee',
  pb: 1
};

const detailItemStyles = {
  display: 'flex', 
  alignItems: 'flex-start', 
  gap: 2,
  mb: 3,
  '& .MuiSvgIcon-root': {
    color: 'text.primary',
    mt: 0.5
  }
};

// Simplify the gridItemStyles
const gridItemStyles = {
  p: 3,
};

// Add this styled button component
const UrbanistButton = styled(Button)({
  fontFamily: 'Urbanist, sans-serif',
  textTransform: 'none',
  fontSize: '0.95rem',
  padding: '8px 24px',
  backgroundColor: '#1976d2',
  color: 'white',
  borderRadius: '8px',
  '&:hover': {
    backgroundColor: '#1565c0'
  }
});

// Add these constants at the top of your file
const STATUS_COLORS = {
  pending_scheduling: {
    bg: '#FFF3E0',
    color: '#E65100',
  },
  scheduled: {
    bg: '#E3F2FD',
    color: '#1565C0',
  },
  in_progress: {
    bg: '#E8F5E9',
    color: '#2E7D32',
  },
  pending_invoicing: {
    bg: '#F3E5F5',
    color: '#7B1FA2',
  },
  completed: {
    bg: '#E8EAF6',
    color: '#303F9F',
  }
};

const PROJECT_STATUS_LABELS = {
  pending_scheduling: 'Pending Scheduling',
  scheduled: 'Scheduled',
  in_progress: 'In Progress',
  pending_invoicing: 'Pending Invoicing',
  completed: 'Completed'
};

// Update the StatusChip styling to handle all statuses
const StatusChip = styled(Chip)(({ theme, status }) => ({
  backgroundColor: 
    status === 'completed' ? '#E8F5E9' :
    status === 'in_progress' ? '#E3F2FD' :
    status === 'pending' ? '#FFF3E0' :
    status === 'invoiced' ? '#F3E5F5' :
    '#F5F5F5',
  color: 
    status === 'completed' ? '#2E7D32' :
    status === 'in_progress' ? '#1565C0' :
    status === 'pending' ? '#E65100' :
    status === 'invoiced' ? '#7B1FA2' :
    '#757575',
  border: `1px solid ${
    status === 'completed' ? '#2E7D32' :
    status === 'in_progress' ? '#1565C0' :
    status === 'pending' ? '#E65100' :
    status === 'invoiced' ? '#7B1FA2' :
    '#757575'
  }`,
  fontFamily: 'Urbanist, sans-serif',
  height: '24px',
  '& .MuiChip-label': {
    padding: '0 8px',
    fontSize: '0.75rem',
    fontWeight: 500
  }
}));

const EventDetails = ({ eventId, open, onClose, onEventUpdated }) => {
  const [loading, setLoading] = useState(true);
  const [eventDetails, setEventDetails] = useState(null);
  const [employees, setEmployees] = useState([]);
  const [error, setError] = useState(null);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [isAssignOpen, setIsAssignOpen] = useState(false);

  const fetchEventData = useCallback(async () => {
    if (!eventId) return;
    setLoading(true);
    try {
      const [details, employeesData] = await Promise.all([
        getEventDetails(eventId),
        getEventEmployees(eventId)
      ]);
      console.log('Fetched event details:', details);
      console.log('Time slots received:', details.time_slots);
      setEventDetails(details);
      setEmployees(employeesData.employees || []);
    } catch (err) {
      console.error('Error fetching event details:', err);
      setError('Failed to load event details. Please try again.');
    } finally {
      setLoading(false);
    }
  }, [eventId]);

  useEffect(() => {
    if (open) {
      fetchEventData();
    }
  }, [open, fetchEventData]);

  const handleEdit = () => {
    setIsEditOpen(true);
  };

  const handleCloseEdit = () => {
    setIsEditOpen(false);
  };

  const handleEventUpdated = async (updatedEvent) => {
    setIsEditOpen(false);
    await fetchEventData();
    if (onEventUpdated) {
      onEventUpdated(updatedEvent);
    }
  };

  const formatTimeWithTimezone = (dateString) => {
    if (!dateString) return 'N/A';
    return formatDateTime(new Date(dateString));
  };

  const handleAssignEmployees = () => {
    setIsAssignOpen(true);
  };

  const handleCloseAssign = async () => {
    setIsAssignOpen(false);
    await fetchEventData();
    if (onEventUpdated) {
      onEventUpdated();
    }
  };

  const handleStatusUpdate = async (newStatus) => {
    try {
      await updateEventStatus(eventId, newStatus);
      await fetchEventData(); // Refresh event data
      if (onEventUpdated) onEventUpdated();
    } catch (error) {
      console.error('Failed to update status:', error);
    }
  };

  const handleCompleteEvent = async () => {
    try {
      await updateEventStatus(eventId, 'completed');
      await fetchEventData();
      if (onEventUpdated) {
        onEventUpdated();
      }
    } catch (error) {
      console.error('Failed to complete event:', error);
    }
  };

  return (
    <>
      {!isEditOpen && (
        <Dialog 
          open={open && !isEditOpen} 
          onClose={onClose} 
          maxWidth="lg" 
          fullWidth
          PaperProps={{
            sx: {
              minHeight: '80vh',
              maxHeight: '90vh',
              p: 2,
              position: 'relative',
              backgroundColor: 'var(--background-paper) !important'
            }
          }}
        >
          <IconButton 
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: '#d32f2f'
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent 
            sx={{
              animation: `${fadeInUp} 0.8s ease-out`,
              overflowY: 'auto',
              maxHeight: '80vh',
              padding: '24px',
              '&::-webkit-scrollbar': {  // Hide scrollbar for Chrome/Safari/Newer Edges
                display: 'none'
              },
              '-ms-overflow-style': 'none',  // Hide scrollbar for IE/Legacy Edge
              'scrollbarWidth': 'none',      // Hide scrollbar for Firefox
            }}
          >
            <DialogTitle sx={{ padding: 0, marginBottom: 3 }}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, color: 'var(--text-primary) !important'}}>
                <Typography variant="h5">
                  {eventDetails?.emoji} {eventDetails?.title}
                </Typography>
                <StatusChip 
                  label={eventDetails?.status?.charAt(0).toUpperCase() + eventDetails?.status?.slice(1)} 
                  size="small"
                  status={eventDetails?.status}
                  icon={
                    eventDetails?.status === 'completed' ? <CheckCircleIcon sx={{ fontSize: 16 }} /> :
                    eventDetails?.status === 'in_progress' ? <UpdateIcon sx={{ fontSize: 16 }} /> :
                    eventDetails?.status === 'invoiced' ? <ReceiptIcon sx={{ fontSize: 16 }} /> :
                    eventDetails?.status === 'pending' ? <PendingIcon sx={{ fontSize: 16 }} /> :
                    <CircleIcon sx={{ fontSize: 16 }} />
                  }
                />
              </Box>
            </DialogTitle>

            {/* Project and Client Info Section */}
            <Grid container spacing={3} sx={{ mb: 4 }}>
              {/* Project Details */}
              <Grid item xs={12} md={6}>
                <Box sx={gridItemStyles}>
                  <Typography variant="h6" sx={{
                    ...sectionTitleStyles,
                    color: 'var(--text-primary) !important'
                  }}>
                    <WorkIcon /> Project Details
                  </Typography>
                  <Typography sx={detailItemStyles}>
                    <Box>
                      <strong>Project</strong><br />
                      {eventDetails?.project?.name || 'N/A'}
                    </Box>
                  </Typography>
                </Box>
              </Grid>

              {/* Client Details */}
              <Grid item xs={12} md={6}>
                <Box sx={gridItemStyles}>
                  <Typography variant="h6" sx={{
                    ...sectionTitleStyles,
                    color: 'var(--text-primary) !important'
                  }}>
                    <Person /> Client Information
                  </Typography>
                  {eventDetails?.project?.client ? (
                    <>
                      <Typography sx={detailItemStyles}>
                        <Box>
                          <strong>Name</strong><br />
                          {eventDetails.project.client.name}
                        </Box>
                      </Typography>
                      {eventDetails.project.client.company && (
                        <Typography sx={detailItemStyles}>
                          <Box>
                            <strong>Company</strong><br />
                            {eventDetails.project.client.company}
                          </Box>
                        </Typography>
                      )}
                      <Typography sx={detailItemStyles}>
                        <Box>
                          <strong>Contact</strong><br />
                          {eventDetails.project.client.email}<br />
                          {eventDetails.project.client.phone && (
                            <span>{eventDetails.project.client.phone}</span>
                          )}
                        </Box>
                      </Typography>
                    </>
                  ) : (
                    <Typography color="text.secondary">
                      No client information available
                    </Typography>
                  )}
                </Box>
              </Grid>
            </Grid>

            {/* Grid Layout for the four main sections */}
            <Grid container spacing={3} sx={{ mb: 4 }}>
              {/* Value and Address Section */}
              <Grid item xs={12} md={6}>
                <Box sx={gridItemStyles}>
                  <Typography variant="h6" sx={{
                    ...sectionTitleStyles,
                    color: 'var(--text-primary) !important'
                  }}>
                    <WorkIcon /> Event Details
                  </Typography>
                  <Typography sx={detailItemStyles}>
                    <AttachMoney sx={{ color: '#2e7d32 !important' }} />
                    <Box>
                      <strong>Value</strong><br />
                      ${eventDetails?.value ? eventDetails.value.toFixed(2) : 'N/A'}
                    </Box>
                  </Typography>
                  {eventDetails?.addresses && eventDetails.addresses.map((address, index) => (
                    <Typography key={index} sx={detailItemStyles}>
                      <LocationOn sx={{ color: '#d32f2f !important' }} />
                      <Box>
                        <strong>Address</strong><br />
                        {address}
                      </Box>
                    </Typography>
                  ))}
                </Box>
              </Grid>

              {/* Time Slots Section */}
              <Grid item xs={12} md={6}>
                <Box sx={gridItemStyles}>
                  <Typography variant="h6" sx={{
                    ...sectionTitleStyles,
                    color: 'var(--text-primary) !important'
                  }}>
                    <Schedule /> Time Slots
                  </Typography>
                  <Box sx={{ display: 'flex', gap: 3 }}>
                    {eventDetails?.time_slots?.map((slot, index) => (
                      <Box key={index} sx={{ flex: 1 }}>
                        <Typography sx={detailItemStyles}>
                          <PlayArrow sx={{ color: '#2e7d32 !important' }} />
                          <Box>
                            <strong>Start</strong><br />
                            {formatTimeWithTimezone(slot.start_time)}
                          </Box>
                        </Typography>
                        <Typography sx={detailItemStyles}>
                          <Stop sx={{ color: '#d32f2f !important' }} />
                          <Box>
                            <strong>End</strong><br />
                            {formatTimeWithTimezone(slot.end_time)}
                          </Box>
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                </Box>
              </Grid>

              {/* Employees Section - Full width */}
              <Grid item xs={12}>
                <Box sx={gridItemStyles}>
                  <Typography variant="h6" sx={{
                    ...sectionTitleStyles,
                    color: 'var(--text-primary) !important'
                  }}>
                    <Person /> Assigned Employees
                  </Typography>
                  {employees.length > 0 ? (
                    <ul className={styles.employeeList}>
                      {employees.map((employee) => (
                        <li key={employee.id} className={styles.employeeItem}>
                          <Avatar src={employee.avatar} alt={employee.name} />
                          <Box sx={{ ml: 2 }}>
                            <Typography variant="subtitle1" sx={{ color: 'var(--text-primary) !important' }}>{employee.name}</Typography>
                            <Typography variant="body2" color="text.secondary" sx={{ color: 'var(--text-primary) !important' }}>{employee.email}</Typography>
                            <Typography variant="body2" color="text.secondary" sx={{ color: 'var(--text-primary) !important' }}>{employee.phone}</Typography>
                            <Typography variant="body2" color="text.secondary" sx={{ color: 'var(--text-primary) !important' }}>
                              {employee.payment_type === 'hourly' && `$${employee.rate}/hour`}
                              {employee.payment_type === 'fixed' && `$${employee.rate}`}
                              {employee.payment_type === 'percentage' && `${employee.percentage}%`}
                            </Typography>
                          </Box>
                        </li>
                      ))}
                    </ul>
                  ) : (
                    <Typography color="text.secondary">No employees assigned to this event.</Typography>
                  )}
                </Box>
              </Grid>

              {/* Description Section - Full width */}
              <Grid item xs={12}>
                <Box sx={gridItemStyles}>
                  <Typography variant="h6" sx={{
                    ...sectionTitleStyles,
                    color: 'var(--text-primary) !important'
                  }}>
                    <DescriptionIcon /> Description
                  </Typography>
                  <Typography sx={{
                    ...detailItemStyles,
                    '& .description-text': {
                      whiteSpace: 'pre-wrap',
                      wordBreak: 'break-word',
                      fontFamily: 'inherit',
                      maxWidth: '100%'
                    }
                  }}>
                    <Box>
                      <span className="description-text">
                        {eventDetails?.description || 'No description provided'}
                      </span>
                    </Box>
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions className={styles.dialogActions} sx={{ padding: '16px 24px', gap: 2 }}>
            <UrbanistButton onClick={handleAssignEmployees}>
              Assign Employees
            </UrbanistButton>
            <UrbanistButton onClick={handleEdit}>
              Edit Event
            </UrbanistButton>
            <UrbanistButton 
              onClick={handleCompleteEvent}
              sx={{ 
                backgroundColor: '#4caf50',
                '&:hover': {
                  backgroundColor: '#388e3c'
                }
              }}
            >
              Mark as Completed
            </UrbanistButton>
          </DialogActions>
        </Dialog>
      )}
      <EventForm
        open={isEditOpen}
        onClose={handleCloseEdit}
        projectId={eventDetails?.project_id}
        onEventCreated={handleEventUpdated}
        event={eventDetails}
      />
      <Dialog 
        open={isAssignOpen} 
        onClose={handleCloseAssign}
        maxWidth="lg"
        fullWidth
        PaperProps={{
          sx: {
            height: '90vh',
            maxHeight: '90vh',
            m: 2,
            borderRadius: '12px',
            overflow: 'hidden',
            backgroundColor: 'var(--background-paper)'
          }
        }}
      >
        <IconButton 
          onClick={handleCloseAssign}
          sx={{
            position: 'absolute',
            right: 16,
            top: 16,
            color: '#d32f2f',
            zIndex: 2,
            backgroundColor: 'rgba(255,255,255,0.8)',
            '&:hover': {
              backgroundColor: 'rgba(255,255,255,0.9)',
            }
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent sx={{ 
          p: 0,
          animation: `${fadeInUp} 0.5s ease-out`,
          height: '85vh',
          overflow: 'hidden'
        }}>
          <EmployeeAssignment 
            projectId={eventDetails?.project_id}
            onUpdate={handleCloseAssign}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default EventDetails;
