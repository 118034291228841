import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, IconButton, Box, Typography } from '@mui/material';
import EmojiPicker from 'emoji-picker-react';
import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon';
import { createEvent, updateEvent, createRecurringEvent } from '../services/api';
import InputAdornment from '@mui/material/InputAdornment';
import RepeatIcon from '@mui/icons-material/Repeat';
import RecurringEventForm from './RecurringEventForm';
import { styled } from '@mui/material/styles';
import styles from './EventForm.module.scss';
import CloseIcon from '@mui/icons-material/Close';

const EventForm = ({ open, onClose, projectId, onEventCreated, event }) => {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [emoji, setEmoji] = useState('📅');
  const [address, setAddress] = useState('');
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [eventData, setEventData] = useState({ value: 0 });
  const [showRecurringForm, setShowRecurringForm] = useState(false);
  const [recurringSettings, setRecurringSettings] = useState(null);

  useEffect(() => {
    if (event) {
      setTitle(event.title || '');
      setDescription(event.description || '');
      setEmoji(event.emoji || '📅');
      setAddress(event.addresses?.[0] || '');
      setStartTime(event.time_slots?.[0]?.start_time || '');
      setEndTime(event.time_slots?.[0]?.end_time || '');
      setEventData({ value: event.value || 0 });
    } else {
      // Reset form for new event
      setTitle('');
      setDescription('');
      setEmoji('📅');
      setAddress('');
      setStartTime('');
      setEndTime('');
      setEventData({ value: 0 });
    }
  }, [event]);

  const handleSubmit = async () => {
    try {
      const cleanEventData = {
        title,
        description,
        project_id: projectId,
        value: parseFloat(eventData.value) || 0,
        emoji: emoji || '📅',
        addresses: [address].filter(Boolean),
        time_slots: [{
          start_time: startTime,
          end_time: endTime
        }].filter(slot => slot.start_time && slot.end_time)
      };

      if (event) {
        await updateEvent(event.id, cleanEventData);
      } else {
        if (recurringSettings) {
          const recurringEventData = {
            ...cleanEventData,
            recurring: {
              frequency: recurringSettings.frequency,
              interval: parseInt(recurringSettings.interval),
              days_of_week: recurringSettings.daysOfWeek,
              end_date: recurringSettings.endDate,
              end_after_occurrences: parseInt(recurringSettings.endAfterOccurrences)
            }
          };
          await createRecurringEvent(recurringEventData);
        } else {
          await createEvent(cleanEventData);
        }
      }

      onEventCreated();
      onClose();
    } catch (error) {
      console.error('Error submitting event:', error);
    }
  };

  const onEmojiClick = (emojiObject) => {
    setEmoji(emojiObject.emoji);
    setShowEmojiPicker(false);
  };

  const handleRecurringSubmit = (settings) => {
    setRecurringSettings(settings);
    setShowRecurringForm(false);
  };

  return (
    <>
      <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
        <DialogTitle className={styles.dialogTitle}>
          {event ? 'Edit Event' : 'Create New Event'}
          <IconButton
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: '#FF4842'
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className={styles.dialogContent}>
          <form onSubmit={handleSubmit} className={styles.form}>
            <TextField
              label="Title"
              type="text"
              fullWidth
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              required
              margin="dense"
              variant="filled"
              className={styles.textField}
            />
            <TextField
              label="Description"
              type="text"
              fullWidth
              multiline
              rows={4}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              margin="dense"
              variant="filled"
              className={styles.textField}
            />
            <div className={styles.emojiSelector}>
              <TextField
                label="Emoji"
                value={emoji}
                variant="filled"
                className={styles.textField}
                InputProps={{
                  readOnly: true,
                  endAdornment: (
                    <IconButton onClick={() => setShowEmojiPicker(!showEmojiPicker)}>
                      <InsertEmoticonIcon />
                    </IconButton>
                  ),
                }}
                style={{ width: '100%' }}
              />
            </div>
            {showEmojiPicker && (
              <div className={styles.emojiSelector}>
                <EmojiPicker onEmojiClick={onEmojiClick} />
              </div>
            )}
            <TextField
              label="Address"
              type="text"
              fullWidth
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              margin="dense"
              variant="filled"
              className={styles.textField}
            />
            <TextField
              label="Start Time"
              type="datetime-local"
              fullWidth
              value={startTime}
              onChange={(e) => setStartTime(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
              required
              margin="dense"
              variant="filled"
              className={styles.textField}
            />
            <TextField
              label="End Time"
              type="datetime-local"
              fullWidth
              value={endTime}
              onChange={(e) => setEndTime(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
              required
              margin="dense"
              variant="filled"
              className={styles.textField}
            />
            <TextField
              label="Event Value"
              type="number"
              value={eventData.value || ''}
              onChange={(e) => setEventData({ ...eventData, value: parseFloat(e.target.value) })}
              fullWidth
              margin="normal"
              variant="filled"
              className={styles.textField}
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
            />
          </form>
        </DialogContent>
        <DialogActions className={styles.formActions}>
          <Button 
            onClick={onClose}
            sx={{
              color: 'text.secondary',
              borderRadius: '8px',
              textTransform: 'none',
              fontFamily: 'Urbanist, sans-serif',
              '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.04)'
              }
            }}
          >
            Cancel
          </Button>
          <Button 
            onClick={() => setShowRecurringForm(true)}
            variant="contained"
            startIcon={<RepeatIcon />}
            sx={{
              backgroundColor: '#2065D1',
              borderRadius: '8px',
              textTransform: 'none',
              fontFamily: 'Urbanist, sans-serif',
              boxShadow: 'none',
              '&:hover': {
                backgroundColor: '#1C4FB8',
                boxShadow: 'none'
              }
            }}
          >
            Make Recurring
          </Button>
          <Button 
            onClick={handleSubmit}
            variant="contained"
            sx={{
              backgroundColor: '#2065D1',
              borderRadius: '8px',
              textTransform: 'none',
              fontFamily: 'Urbanist, sans-serif',
              boxShadow: 'none',
              '&:hover': {
                backgroundColor: '#1C4FB8',
                boxShadow: 'none'
              }
            }}
          >
            {event ? 'Update Event' : 'Create Event'}
          </Button>
        </DialogActions>
        {recurringSettings && (
          <Box className={styles.recurringInfo}>
            <Typography variant="subtitle2">
              Recurring: {recurringSettings.frequency} 
              (Every {recurringSettings.interval} {recurringSettings.frequency})
            </Typography>
          </Box>
        )}
      </Dialog>

      <RecurringEventForm
        open={showRecurringForm}
        onClose={() => setShowRecurringForm(false)}
        onSubmit={handleRecurringSubmit}
      />
    </>
  );
};

export default EventForm;