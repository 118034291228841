import React, { useState } from 'react';
import { Paper, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import ChatHeader from './ChatHeader';
import MessagesList from './MessagesList';
import MessageInput from './MessageInput';

const StyledChatContainer = styled(Paper)(({ theme }) => ({
  flex: 1,
  height: 'calc(100vh - 280px)',
  borderRadius: 12,
  display: 'flex',
  flexDirection: 'column',
  border: `1px solid ${theme.palette.divider}`,
  marginLeft: theme.spacing(2),
  backgroundColor: theme.palette.background.default
}));

const ChatWindow = ({ selectedContact }) => {
  const [message, setMessage] = useState('');

  const handleSendMessage = () => {
    if (message.trim()) {
      // Add message sending logic
      setMessage('');
    }
  };

  return (
    <StyledChatContainer>
      <ChatHeader contact={selectedContact} />
      <MessagesList messages={selectedContact?.messages || []} />
      <Box sx={{ p: 2, borderTop: 1, borderColor: 'divider' }}>
        <MessageInput
          message={message}
          setMessage={setMessage}
          onSend={handleSendMessage}
          disabled={!selectedContact}
        />
      </Box>
    </StyledChatContainer>
  );
};

export default ChatWindow;