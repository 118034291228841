import React from 'react';
import { useMediaQuery } from '@mui/material';
import DesktopNav from './navigation/DesktopNav';
import MobileNav from './navigation/MobileNav';

const EmployeeNavbar = ({ onNavToggle }) => {
  const isMobile = useMediaQuery('(max-width:768px)');
  return isMobile ? <MobileNav onNavToggle={onNavToggle} /> : <DesktopNav />;
};

export default EmployeeNavbar;
