import React, { useState, useEffect } from 'react';
import { Grid, TextField, Button, Typography, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { useTheme } from '../ThemeContext';
import styles from './AdminSettings.module.scss';
import { getBusinessDetails, updateBusinessDetails } from '../services/api';

function AdminSettingsBusinessDetails() {
  const [businessProfile, setBusinessProfile] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { darkMode } = useTheme();

  useEffect(() => {
    fetchBusinessProfile();
  }, []);

  const fetchBusinessProfile = async () => {
    try {
      setLoading(true);
      const data = await getBusinessDetails();
      console.log('Fetched business profile:', data);
      if (data === null) {
        console.log('No business profile found, opening dialog');
        setOpenDialog(true);
      } else {
        setBusinessProfile(data);
      }
    } catch (error) {
      console.error('Error fetching business profile:', error);
      setError('Failed to fetch business profile');
    } finally {
      setLoading(false);
    }
  };

  const handleBusinessProfileChange = (event) => {
    const { name, value } = event.target;
    setBusinessProfile((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async () => {
    try {
      const updatedProfile = await updateBusinessDetails(businessProfile);
      setBusinessProfile(updatedProfile);
      alert('Business profile updated successfully');
    } catch (error) {
      console.error('Error updating business profile:', error);
      alert('Error updating business profile');
    }
  };

  const handleCreateProfile = () => {
    console.log('Creating new business profile');
    setBusinessProfile({
      company_name: '',
      owner_name: '',
      address: '',
      city: '',
      state: '',
      zip_code: '',
      phone: '',
      email: '',
    });
    setOpenDialog(false);
  };

  const getFieldLabel = (key) => {
    const labels = {
      company_name: 'Company Name',
      owner_name: 'Owner Name',
      address: 'Address',
      city: 'City',
      state: 'State',
      zip_code: 'ZIP Code',
      phone: 'Phone',
      email: 'Email'
    };
    return labels[key] || key.charAt(0).toUpperCase() + key.slice(1).replace(/_/g, ' ');
  };

  if (loading) {
    return <Typography>Loading...</Typography>;
  }

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  return (
    <Grid container spacing={3} className={`${styles.tabPanel} ${darkMode ? styles.darkMode : ''}`}>
      <Dialog 
        open={openDialog} 
        onClose={() => setOpenDialog(false)}
        PaperProps={{
          sx: {
            backgroundColor: darkMode ? 'var(--background-primary)' : '#fff',
            color: darkMode ? 'var(--text-primary)' : 'inherit'
          }
        }}
      >
        <DialogTitle sx={{ color: darkMode ? 'var(--text-primary)' : 'inherit' }}>
          Create Business Profile
        </DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ color: darkMode ? 'var(--text-secondary)' : 'inherit' }}>
            No business profile found. Would you like to create one?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
          <Button onClick={handleCreateProfile} color="primary">Create Profile</Button>
        </DialogActions>
      </Dialog>

      {businessProfile ? (
        <>
          {Object.entries(businessProfile).map(([key, value]) => {
            if (key !== 'logo') {
              return (
                <Grid item xs={12} sm={6} key={key}>
                  <TextField
                    fullWidth
                    label={getFieldLabel(key)}
                    name={key}
                    value={value || ''}
                    onChange={handleBusinessProfileChange}
                    variant="outlined"
                    className={`${styles.formGroup} ${darkMode ? styles.darkMode : ''}`}
                    sx={{
                      '& .MuiInputLabel-root': {
                        color: darkMode ? 'var(--text-secondary)' : 'inherit'
                      },
                      '& .MuiOutlinedInput-root': {
                        backgroundColor: darkMode ? 'var(--input-background)' : 'inherit',
                        color: darkMode ? 'var(--text-primary)' : 'inherit',
                        '& fieldset': {
                          borderColor: darkMode ? 'var(--border-color)' : 'inherit'
                        },
                        '&:hover fieldset': {
                          borderColor: darkMode ? 'var(--text-secondary)' : 'inherit'
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: darkMode ? '#90caf9' : '#1976d2'
                        }
                      }
                    }}
                  />
                </Grid>
              );
            }
            return null;
          })}
          <Grid item xs={12}>
            <Button 
              variant="contained" 
              color="primary" 
              onClick={handleSubmit}
              className={darkMode ? styles.darkButton : ''}
            >
              Save Changes
            </Button>
          </Grid>
        </>
      ) : (
        <Grid item xs={12}>
          <Typography 
            variant="h6" 
            sx={{ color: darkMode ? 'var(--text-primary)' : 'inherit' }}
          >
            Please create a business profile to get started.
          </Typography>
          <Button 
            variant="contained" 
            color="primary" 
            onClick={handleCreateProfile}
            className={darkMode ? styles.darkButton : ''}
          >
            Create Business Profile
          </Button>
        </Grid>
      )}
    </Grid>
  );
}

export default AdminSettingsBusinessDetails;
