import React, { useState, useEffect } from 'react';
import {
  Typography, Box, CircularProgress, TableContainer, Table, TableHead,
  TableRow, TableCell, TableBody, Chip, Stack, Tooltip, IconButton
} from '@mui/material';
import { Clear as ClearIcon } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { getClientInvoices } from '../../services/api';

const Invoices = ({ client }) => {
  const navigate = useNavigate();
  const [invoices, setInvoices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  useEffect(() => {
    const fetchInvoices = async () => {
      if (!client?.id) {
        console.log('No client ID available');
        setLoading(false);
        return;
      }

      try {
        console.log('Fetching invoices for client:', client.id);
        const data = await getClientInvoices(client.id);
        console.log('Received invoices:', data);
        setInvoices(data);
      } catch (err) {
        console.error('Error fetching invoices:', err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchInvoices();
  }, [client]);

  const handleClearDates = () => {
    setStartDate(null);
    setEndDate(null);
  };

  const filteredInvoices = invoices.filter(invoice => {
    const invoiceDate = new Date(invoice.issue_date);
    
    if (startDate && endDate) {
      return invoiceDate >= startDate && invoiceDate <= endDate;
    }
    if (startDate) {
      return invoiceDate >= startDate;
    }
    if (endDate) {
      return invoiceDate <= endDate;
    }
    return true;
  });

  const calculateTotal = (items) => {
    if (!items || !Array.isArray(items)) return 0;
    return items.reduce((sum, item) => {
      const quantity = Number(item.quantity) || 0;
      const unitPrice = Number(item.unitPrice) || 0;
      return sum + (quantity * unitPrice);
    }, 0);
  };

  const getInvoiceStatus = (invoice) => {
    const now = new Date();
    const dueDate = new Date(invoice.due_date);
    
    if (invoice.status) return invoice.status;
    if (dueDate < now) return 'overdue';
    return 'pending';
  };

  const getStatusColor = (status) => {
    switch (status?.toLowerCase()) {
      case 'paid':
        return '#4CAF50';
      case 'pending':
        return '#FFA726';
      case 'overdue':
        return '#EF5350';
      default:
        return '#757575';
    }
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" p={3}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box p={3}>
        <Typography color="error">Error: {error}</Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ p: 3, backgroundColor: 'var(--background-default)' }}>
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center',
        mb: 3 
      }}>
        <Typography variant="h6" sx={{ fontWeight: 600, color: 'var(--text-primary)' }}>
          Invoices
        </Typography>
        <Stack direction="row" spacing={2} alignItems="center">
          <DatePicker
            label="Start date"
            value={startDate}
            onChange={setStartDate}
            maxDate={endDate}
            slotProps={{
              textField: {
                size: "small",
                sx: { 
                  width: 170,
                  '& .MuiInputBase-root': {
                    color: 'var(--text-primary)',
                    backgroundColor: 'var(--background-paper)',
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'var(--divider)',
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'var(--primary-main)',
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'var(--primary-main)',
                    }
                  },
                  '& .MuiInputLabel-root': {
                    color: 'var(--text-secondary)',
                    '&.Mui-focused': {
                      color: 'var(--primary-main)',
                    }
                  },
                  '& .MuiIconButton-root': {
                    color: 'var(--text-primary)',
                  }
                }
              },
              popper: {
                sx: {
                  '& .MuiPaper-root': {
                    backgroundColor: 'var(--background-paper)',
                    color: 'var(--text-primary)',
                    '& .MuiPickersDay-root': {
                      color: 'var(--text-primary)',
                      '&:hover': {
                        backgroundColor: 'var(--hover-overlay)',
                      },
                      '&.Mui-selected': {
                        backgroundColor: 'var(--primary-main)',
                        color: 'var(--primary-contrast)',
                        '&:hover': {
                          backgroundColor: 'var(--primary-dark)',
                        }
                      }
                    }
                  }
                }
              }
            }}
          />
          <DatePicker
            label="End date"
            value={endDate}
            onChange={setEndDate}
            minDate={startDate}
            slotProps={{
              textField: {
                size: "small",
                sx: { 
                  width: 170,
                  '& .MuiInputBase-root': {
                    color: 'var(--text-primary)',
                    backgroundColor: 'var(--background-paper)',
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'var(--divider)',
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'var(--primary-main)',
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'var(--primary-main)',
                    }
                  },
                  '& .MuiInputLabel-root': {
                    color: 'var(--text-secondary)',
                    '&.Mui-focused': {
                      color: 'var(--primary-main)',
                    }
                  },
                  '& .MuiIconButton-root': {
                    color: 'var(--text-primary)',
                  }
                }
              },
              popper: {
                sx: {
                  '& .MuiPaper-root': {
                    backgroundColor: 'var(--background-paper)',
                    color: 'var(--text-primary)',
                    '& .MuiPickersDay-root': {
                      color: 'var(--text-primary)',
                      '&:hover': {
                        backgroundColor: 'var(--hover-overlay)',
                      },
                      '&.Mui-selected': {
                        backgroundColor: 'var(--primary-main)',
                        color: 'var(--primary-contrast)',
                        '&:hover': {
                          backgroundColor: 'var(--primary-dark)',
                        }
                      }
                    }
                  }
                }
              }
            }}
          />
          {(startDate || endDate) && (
            <Tooltip title="Clear dates">
              <IconButton 
                onClick={handleClearDates}
                size="small"
                sx={{ 
                  ml: 1,
                  bgcolor: 'var(--hover-overlay)',
                  '&:hover': {
                    bgcolor: 'var(--hover-overlay)'
                  }
                }}
              >
                <ClearIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          )}
        </Stack>
      </Box>

      <TableContainer sx={{ backgroundColor: 'var(--background-paper)' }}>
        <Table>
          <TableHead>
            <TableRow>
              {['Invoice #', 'Date', 'Due Date', 'Amount', 'Status'].map((header) => (
                <TableCell 
                  key={header} 
                  align={header === 'Amount' ? 'right' : 'left'}
                  sx={{ 
                    color: 'var(--text-primary)',
                    borderBottom: '1px solid var(--divider)'
                  }}
                >
                  {header}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredInvoices.map((invoice) => {
              const total = calculateTotal(invoice.items);
              const status = getInvoiceStatus(invoice);
              
              return (
                <TableRow 
                  key={invoice.id}
                  hover
                  onClick={() => navigate(`/admin/invoices/${invoice.id}`)}
                  sx={{ 
                    cursor: 'pointer',
                    '&:hover': {
                      backgroundColor: 'var(--hover-overlay)'
                    },
                    '& .MuiTableCell-root': {
                      color: 'var(--text-primary)',
                      border: '1px solid var(--divider)'
                    }
                  }}
                >
                  <TableCell>{invoice.invoice_number}</TableCell>
                  <TableCell>
                    {new Date(invoice.issue_date).toLocaleDateString()}
                  </TableCell>
                  <TableCell>
                    {new Date(invoice.due_date).toLocaleDateString()}
                  </TableCell>
                  <TableCell align="right">
                    ${total.toLocaleString('en-US', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2
                    })}
                  </TableCell>
                  <TableCell>
                    <Chip 
                      label={status.charAt(0).toUpperCase() + status.slice(1)}
                      sx={{
                        backgroundColor: `${getStatusColor(status)}20`,
                        color: getStatusColor(status),
                        fontWeight: 500,
                        fontSize: '0.75rem'
                      }}
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default Invoices; 