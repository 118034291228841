import React, { useState, useEffect, useMemo } from 'react';
import { 
  Box, 
  Typography, 
  IconButton,
  Grid,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Paper,
  Popover,
  Stack,
  Button,
  styled
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import InsightsIcon from '@mui/icons-material/Insights';
import FilterListIcon from '@mui/icons-material/FilterList';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import { getEstimates } from '../../../services/api';
import MetricCard from './components/MetricCard';
import ConversionChart from './components/ConversionChart';
import StatusDistribution from './components/StatusDistribution';
import EstimateValueChart from './components/EstimateValueChart';
import TimelineAnalysis from './components/TimelineAnalysis';

// Styled Components
const StyledHeader = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(2),
  borderBottom: `1px solid ${theme.palette.divider}`
}));

const StyledFilterPopover = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  width: 300,
  maxHeight: '80vh',
  overflow: 'auto',
  position: 'absolute',
  top: theme.spacing(8),
  right: theme.spacing(2)
}));

const StyledMetricCard = styled(MetricCard)(({ theme }) => ({
  background: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[1],
  transition: 'transform 0.2s',
  '&:hover': {
    transform: 'translateY(-4px)',
    boxShadow: theme.shadows[4]
  }
}));

const StyledChartsContainer = styled(Box)(({ theme }) => ({
  display: 'grid',
  gap: theme.spacing(3),
  gridTemplateColumns: 'repeat(auto-fit, minmax(500px, 1fr))',
  padding: theme.spacing(3)
}));

const StyledChartHeader = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  marginBottom: theme.spacing(2),
  borderBottom: `2px solid ${theme.palette.primary.main}`,
  paddingBottom: theme.spacing(1)
}));

const EstimateAnalyticsView = ({ onBack }) => {
  const [estimates, setEstimates] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [dateRange, setDateRange] = useState({ start: null, end: null });
  const [statusFilter, setStatusFilter] = useState('all');
  const [anchorEl, setAnchorEl] = useState(null);

  const handleFilterClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleFilterClose = () => {
    setAnchorEl(null);
  };

  const fetchEstimateData = async () => {
    try {
      setLoading(true);
      const data = await getEstimates();
      setEstimates(data);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchEstimateData();
  }, []);

  // Filter estimates based on date range and status
  const filteredEstimates = useMemo(() => {
    return estimates.filter(est => {
      const date = new Date(est.issue_date);
      const matchesDateRange = (!dateRange.start || date >= dateRange.start) &&
                              (!dateRange.end || date <= dateRange.end);
      const matchesStatus = statusFilter === 'all' || est.status === statusFilter;
      return matchesDateRange && matchesStatus;
    });
  }, [estimates, dateRange, statusFilter]);

  // Calculate metrics based on filtered estimates
  const metrics = useMemo(() => {
    if (!filteredEstimates.length) return [];
    
    const totalValue = filteredEstimates.reduce((sum, est) => {
      const items = Array.isArray(est.items) ? est.items : [];
      return sum + items.reduce((itemSum, item) => 
        itemSum + (Number(item.quantity) || 0) * (Number(item.unit_price) || 0), 0);
    }, 0);

    const signedEstimates = filteredEstimates.filter(est => est.client_signature);
    const conversionRate = ((signedEstimates.length / filteredEstimates.length) * 100).toFixed(1);

    const avgResponseTime = signedEstimates.reduce((sum, est) => {
      const issueDate = new Date(est.issue_date);
      const signedDate = new Date(est.client_signed_at);
      return sum + (signedDate - issueDate) / (1000 * 60 * 60 * 24);
    }, 0) / (signedEstimates.length || 1);

    return [
      {
        title: 'Total Value',
        value: `$${totalValue.toLocaleString()}`,
        subtitle: 'Total value of all estimates',
        icon: <MonetizationOnIcon sx={{ color: 'primary.main' }} />
      },
      {
        title: 'Conversion Rate',
        value: `${conversionRate}%`,
        subtitle: 'Estimates converted to jobs',
        icon: <TrendingUpIcon sx={{ color: 'success.main' }} />
      },
      {
        title: 'Average Response Time',
        value: `${avgResponseTime.toFixed(1)} days`,
        subtitle: 'Average time to signature',
        icon: <AccessTimeIcon sx={{ color: 'warning.main' }} />
      },
      {
        title: 'Total Estimates',
        value: filteredEstimates.length,
        subtitle: 'Number of estimates',
        icon: <AssignmentTurnedInIcon sx={{ color: 'info.main' }} />
      }
    ];
  }, [filteredEstimates]);

  const open = Boolean(anchorEl);

  return (
    <Box sx={{ height: '100vh', display: 'flex', flexDirection: 'column', bgcolor: 'background.default' }}>
      <StyledHeader>
        <IconButton onClick={onBack}>
          <ArrowBackIcon />
        </IconButton>
        <InsightsIcon sx={{ fontSize: 28 }} />
        <Typography variant="h5" sx={{ fontWeight: 600, flex: 1 }}>
          Estimate Analytics
        </Typography>
        <IconButton onClick={handleFilterClick}>
          <FilterListIcon />
        </IconButton>
      </StyledHeader>

      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleFilterClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{
          sx: {
            width: 300,
            p: 3,
            mt: 1
          }
        }}
      >
        <Stack spacing={2}>
          <Typography variant="h6" sx={{ mb: 2 }}>
            Filter Estimates
          </Typography>
          
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Start Date"
              value={dateRange.start}
              onChange={(newValue) => setDateRange(prev => ({ ...prev, start: newValue }))}
              slotProps={{ textField: { size: 'small', fullWidth: true } }}
            />
            <DatePicker
              label="End Date"
              value={dateRange.end}
              onChange={(newValue) => setDateRange(prev => ({ ...prev, end: newValue }))}
              slotProps={{ textField: { size: 'small', fullWidth: true } }}
            />
          </LocalizationProvider>

          <FormControl fullWidth size="small">
            <InputLabel>Status</InputLabel>
            <Select
              value={statusFilter}
              label="Status"
              onChange={(e) => setStatusFilter(e.target.value)}
            >
              <MenuItem value="all">All Status</MenuItem>
              <MenuItem value="draft">Draft</MenuItem>
              <MenuItem value="pending">Pending</MenuItem>
              <MenuItem value="approved">Approved</MenuItem>
            </Select>
          </FormControl>

          <Button 
            variant="contained" 
            onClick={handleFilterClose}
            fullWidth
          >
            Apply Filters
          </Button>
        </Stack>
      </Popover>

      <Box sx={{ flex: 1, overflow: 'auto', p: 3 }}>
        {loading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <CircularProgress />
          </Box>
        ) : error ? (
          <Typography color="error" align="center">{error}</Typography>
        ) : (
          <Grid container spacing={4}>
            {/* Metrics Cards */}
            <Grid item xs={12}>
              <Grid container spacing={3}>
                {metrics.map((metric, index) => (
                  <Grid item xs={12} sm={6} md={3} key={index}>
                    <Box
                      sx={{
                        p: 3,
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        transition: 'transform 0.2s',
                        '&:hover': {
                          transform: 'translateY(-4px)',
                        }
                      }}
                    >
                      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                        {metric.icon}
                        <Typography variant="subtitle2" sx={{ ml: 1, color: 'text.secondary' }}>
                          {metric.title}
                        </Typography>
                      </Box>
                      <Typography variant="h4" sx={{ mb: 1 }}>
                        {metric.value}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {metric.subtitle}
                      </Typography>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Grid>

            {/* Add spacing between metrics and charts */}
            <Box sx={{ mb: 4 }} />

            {/* Charts */}
            <Grid item xs={12}>
              <StyledChartsContainer>
                <Grid container spacing={3}>
                  <Grid item xs={12} lg={8}>
                    <StyledChartHeader>
                      <Typography variant="h6">Conversion Rate Trends</Typography>
                    </StyledChartHeader>
                    <ConversionChart estimates={estimates} height={300} />
                  </Grid>

                  <Grid item xs={12} lg={4}>
                    <StyledChartHeader>
                      <Typography variant="h6">Status Distribution</Typography>
                    </StyledChartHeader>
                    <StatusDistribution estimates={estimates} height={300} />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <StyledChartHeader>
                      <Typography variant="h6">Monthly Revenue</Typography>
                    </StyledChartHeader>
                    <EstimateValueChart estimates={estimates} height={300} />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <StyledChartHeader>
                      <Typography variant="h6">Response Time Analysis</Typography>
                    </StyledChartHeader>
                    <TimelineAnalysis estimates={estimates} height={300} />
                  </Grid>
                </Grid>
              </StyledChartsContainer>
            </Grid>
          </Grid>
        )}
      </Box>
    </Box>
  );
};

export default EstimateAnalyticsView;

