import React, { useState } from 'react';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import ContactsSidebar from './ContactsSidebar';
import EmailThread from './email/EmailThread';
import EmailCompose from './email/EmailCompose';

const StyledContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2),
  height: '100%',
  flex: 1
}));

const EmailView = ({ selectedContact, setSelectedContact }) => {
  const [isComposing, setIsComposing] = useState(false);
  const [emailThreads] = useState([
    {
      id: 1,
      subject: 'Service Quote Request',
      messages: [
        {
          id: 1,
          sender: 'John Doe',
          recipient: 'Me',
          content: 'Hi, I would like to request a quote for your services...',
          timestamp: '2024-03-10 09:30',
          direction: 'received'
        },
        {
          id: 2,
          sender: 'Me',
          recipient: 'John Doe',
          content: 'Thank you for your interest. Here is our quote...',
          timestamp: '2024-03-10 10:15',
          direction: 'sent'
        }
      ]
    }
  ]);

  const handleCompose = () => {
    setIsComposing(true);
  };

  const handleCloseCompose = () => {
    setIsComposing(false);
  };

  const handleSendEmail = (content) => {
    // Add email sending logic here
    setIsComposing(false);
  };

  return (
    <StyledContainer>
      <ContactsSidebar 
        contacts={[]}
        selectedContact={selectedContact}
        setSelectedContact={setSelectedContact}
        communicationType="email"
      />
      {isComposing ? (
        <EmailCompose onClose={handleCloseCompose} onSend={handleSendEmail} />
      ) : (
        <EmailThread
          contact={selectedContact}
          threads={emailThreads}
          onCompose={handleCompose}
        />
      )}
    </StyledContainer>
  );
};

export default EmailView;