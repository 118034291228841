import React from 'react';
import {
  Box,
  TextField,
  FormControl,
  Select,
  MenuItem,
  styled,
} from '@mui/material';
import {
  Person,
  Badge,
  Info,
} from '@mui/icons-material';

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    borderRadius: 8,
    transition: 'all 0.2s ease-in-out',
    '&:hover': {
      borderColor: theme.palette.primary.main,
    },
    '&.Mui-focused': {
      boxShadow: `0 0 0 2px ${theme.palette.primary.main}25`,
    }
  }
}));

const StyledSelect = styled(Select)(({ theme }) => ({
  borderRadius: 8,
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: 'rgba(0, 0, 0, 0.23)',
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.primary.main,
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.primary.main,
    boxShadow: `0 0 0 2px ${theme.palette.primary.main}25`,
  }
}));

const BasicInfoSection = ({ data, onChange }) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      <StyledTextField
        fullWidth
        label="First Name"
        value={data.firstName}
        onChange={(e) => onChange('firstName', e.target.value)}
        InputProps={{
          startAdornment: <Person sx={{ mr: 1, color: 'action.active' }} />,
        }}
      />

      <StyledTextField
        fullWidth
        label="Last Name"
        value={data.lastName}
        onChange={(e) => onChange('lastName', e.target.value)}
        InputProps={{
          startAdornment: <Person sx={{ mr: 1, color: 'action.active' }} />,
        }}
      />

      <StyledTextField
        fullWidth
        label="Title"
        value={data.title}
        onChange={(e) => onChange('title', e.target.value)}
        InputProps={{
          startAdornment: <Badge sx={{ mr: 1, color: 'action.active' }} />,
        }}
      />

      <FormControl fullWidth>
        <StyledSelect
          value={data.status}
          displayEmpty
          onChange={(e) => onChange('status', e.target.value)}
          startAdornment={<Info sx={{ ml: 1, mr: 1, color: 'action.active' }} />}
          renderValue={(value) => value || "Status"}
        >
          <MenuItem value="Active">Active</MenuItem>
          <MenuItem value="Inactive">Inactive</MenuItem>
          <MenuItem value="Pending">Pending</MenuItem>
        </StyledSelect>
      </FormControl>
    </Box>
  );
};

export default BasicInfoSection;