import React, { useState, useEffect } from 'react';
import { Box, Button } from '@mui/material';
import { Add as AddIcon } from '@mui/icons-material';
import { getPricingRules, deletePricingRule, createPricingRule, updatePricingRule } from '../../services/api';
import PricingRuleTable from './PricingRuleTable';
import PricingRuleEditor from './PricingRuleEditor';
import { calculationTypeFields } from './pricingConstants';

const PricingConfigurator = () => {
  const [pricingRules, setPricingRules] = useState([]);
  const [loading, setLoading] = useState(false);
  const [editingRule, setEditingRule] = useState(null);

  useEffect(() => {
    loadPricingRules();
  }, []);

  const loadPricingRules = async () => {
    try {
      setLoading(true);
      const rules = await getPricingRules();
      setPricingRules(rules.map(rule => ({
        ...rule,
        fields: calculationTypeFields[rule.calculationType] || []
      })));
    } catch (error) {
      console.error('Error loading pricing rules:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleAddRule = () => {
    const newRule = {
      id: 'new',
      name: 'New Pricing Rule',
      description: '',
      serviceType: 'cleaning',
      calculationType: 'fixed',
      areaCalculationType: 'dimensions',
      baseRate: 0,
      manualArea: false,
      fields: calculationTypeFields.fixed,
      fieldValues: {},
      result: 0,
      isNew: true
    };
    setPricingRules(prev => [...prev, newRule]);
    setEditingRule('new');
  };

  const handleRuleUpdate = async (updatedRule) => {
    try {
      let savedRule;
      
      // Log the rule data before sending
      console.log('Rule being saved:', updatedRule);
      
      if (updatedRule.isNew) {
        // Create new rule - only send the fields the backend expects
        const ruleData = {
          name: updatedRule.name,
          description: updatedRule.description || '',
          serviceType: updatedRule.serviceType,
          calculationType: updatedRule.calculationType,
          areaCalculationType: updatedRule.areaCalculationType,
          baseRate: parseFloat(updatedRule.baseRate) || 0,
          fieldValues: updatedRule.fieldValues || {}
        };
        
        console.log('Creating new rule with data:', ruleData);
        savedRule = await createPricingRule(ruleData);
      } else {
        // Update existing rule
        const ruleData = {
          id: updatedRule.id,
          name: updatedRule.name,
          description: updatedRule.description || '',
          serviceType: updatedRule.serviceType,
          calculationType: updatedRule.calculationType,
          areaCalculationType: updatedRule.areaCalculationType,
          baseRate: parseFloat(updatedRule.baseRate) || 0,
          fieldValues: updatedRule.fieldValues || {}
        };
        
        console.log('Updating rule with data:', ruleData);
        savedRule = await updatePricingRule(ruleData);
      }

      console.log('Save successful:', savedRule);
      await loadPricingRules();
      setEditingRule(null);
    } catch (error) {
      console.error('Detailed save error:', {
        error,
        message: error.message,
        response: error.response?.data,
        status: error.response?.status,
        data: error.config?.data
      });
      alert(`Failed to save rule: ${error.response?.data?.error || error.message}`);
    }
  };

  const handleRuleDelete = async (ruleId) => {
    try {
      if (ruleId !== 'new') {
        await deletePricingRule(ruleId);
        await loadPricingRules();
      } else {
        setPricingRules(prev => prev.filter(rule => !rule.isNew));
      }
      setEditingRule(null);
    } catch (error) {
      console.error('Error deleting rule:', error);
      alert('Failed to delete rule. Please try again.');
    }
  };

  const handleTemporaryUpdate = (updatedRule) => {
    setPricingRules(prev => prev.map(rule => {
      if ((rule.id === updatedRule.id) || (rule.isNew && updatedRule.isNew)) {
        // Ensure fields are properly set based on calculation type
        const fields = calculationTypeFields[updatedRule.calculationType] || [];
        return {
          ...updatedRule,
          fields,
          // Preserve only relevant field values for the current calculation type
          fieldValues: updatedRule.calculationType === rule.calculationType 
            ? updatedRule.fieldValues 
            : {}
        };
      }
      return rule;
    }));
  };

  return (
    <Box sx={{ p: 3 }}>
      <PricingRuleTable 
        rules={pricingRules}
        editingRule={editingRule}
        onEdit={setEditingRule}
        onDelete={handleRuleDelete}
        loading={loading}
      />

      <Button
        variant="contained"
        startIcon={<AddIcon />}
        onClick={handleAddRule}
        sx={{
          backgroundColor: 'var(--primary-main)',
          color: '#fff',
          '&:hover': {
            backgroundColor: 'var(--primary-dark)',
          }
        }}
      >
        Add Pricing Rule
      </Button>

      {editingRule && (
        <PricingRuleEditor
          rule={pricingRules.find(r => r.id === editingRule || (r.isNew && editingRule === 'new')) || {
            id: 'new',
            name: 'New Pricing Rule',
            description: '',
            serviceType: 'cleaning',
            calculationType: 'fixed',
            areaCalculationType: 'dimensions',
            baseRate: 0,
            fields: calculationTypeFields.fixed,
            fieldValues: {},
            result: 0,
            isNew: true
          }}
          onSave={handleRuleUpdate}
          onChange={handleTemporaryUpdate}
          onCancel={() => {
            setEditingRule(null);
            loadPricingRules();
          }}
        />
      )}
    </Box>
  );
};

export default PricingConfigurator; 