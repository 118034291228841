import React from 'react';
import {
  Card,
  CardContent,
  Typography,
  Box,
  IconButton,
  Chip,
  LinearProgress,
} from '@mui/material';
import {
  ContentCopy as DuplicateIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Visibility as ViewIcon,
} from '@mui/icons-material';

const FormCard = ({ form, onEdit, onDuplicate, onDelete, onView }) => (
  <Card sx={{ 
    height: '100%',
    borderRadius: 2,
    transition: 'transform 0.2s ease-in-out',
    '&:hover': {
      transform: 'translateY(-4px)',
      boxShadow: (theme) => theme.shadows[4],
    }
  }}>
    <CardContent>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
        <Chip 
          label={form.type}
          size="small"
          sx={{ 
            backgroundColor: 
              form.type === 'contact' ? 'primary.light' :
              form.type === 'feedback' ? 'secondary.light' :
              form.type === 'survey' ? 'info.light' :
              'warning.light',
            color: 
              form.type === 'contact' ? 'primary.dark' :
              form.type === 'feedback' ? 'secondary.dark' :
              form.type === 'survey' ? 'info.dark' :
              'warning.dark',
          }}
        />
        <Box>
          <IconButton size="small" onClick={() => onView(form)}>
            <ViewIcon fontSize="small" />
          </IconButton>
          <IconButton size="small" onClick={() => onEdit(form)}>
            <EditIcon fontSize="small" />
          </IconButton>
          <IconButton size="small" onClick={() => onDuplicate(form)}>
            <DuplicateIcon fontSize="small" />
          </IconButton>
          <IconButton size="small" onClick={() => onDelete(form)}>
            <DeleteIcon fontSize="small" />
          </IconButton>
        </Box>
      </Box>
      
      <Typography variant="h6" sx={{ mb: 1 }}>
        {form.name}
      </Typography>
      <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
        {form.description}
      </Typography>
      
      <Box sx={{ mt: 3 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
          <Typography variant="caption" color="text.secondary">
            Submission Rate
          </Typography>
          <Typography variant="caption" color="text.secondary">
            {form.submissionRate}%
          </Typography>
        </Box>
        <LinearProgress
          variant="determinate"
          value={form.submissionRate}
          sx={{
            height: 6,
            borderRadius: 3,
            backgroundColor: 'grey.100',
            '& .MuiLinearProgress-bar': {
              borderRadius: 3,
              backgroundImage: 'linear-gradient(45deg, #2196f3 30%, #21cbf3 90%)',
            }
          }}
        />
      </Box>

      <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
        <Typography variant="caption" color="text.secondary">
          Submissions: {form.submissions}
        </Typography>
        <Typography variant="caption" color="text.secondary">
          Fields: {form.fields.length}
        </Typography>
      </Box>
    </CardContent>
  </Card>
);

export default FormCard;