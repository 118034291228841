import React, { useState } from 'react';
import {
  Box,
  Grid,
  Card,
  CardContent,
  Typography,
  Button,
  TextField,
  Switch,
  Divider,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListItemSecondaryAction,
  IconButton,
  Chip,
  Alert,
} from '@mui/material';
import {
  Language as GlobeIcon,
  Speed as SpeedIcon,
  Security as SecurityIcon,
  Code as CodeIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Add as AddIcon,
  Link as LinkIcon,
  Search as SearchIcon,
  CloudUpload as UploadIcon,
  Settings as SettingsIcon,
  Visibility as VisibilityIcon,
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

const WebsiteMetricCard = ({ icon: Icon, title, value, trend, color }) => (
  <Card sx={{ height: '100%' }}>
    <CardContent>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
        <Icon sx={{ color: color, mr: 1 }} />
        <Typography variant="subtitle1" color="textSecondary">
          {title}
        </Typography>
      </Box>
      <Typography variant="h4" sx={{ mb: 1 }}>
        {value}
      </Typography>
      {trend && (
        <Chip
          size="small"
          label={`${trend > 0 ? '+' : ''}${trend}%`}
          color={trend > 0 ? 'success' : 'error'}
        />
      )}
    </CardContent>
  </Card>
);

const WebsiteOverview = () => {
  const navigate = useNavigate();
  const [editMode, setEditMode] = useState(false);
  const [websiteData, setWebsiteData] = useState({
    domain: 'example.com',
    ssl: true,
    analytics: true,
    pages: [
      { id: 1, title: 'Home', url: '/', status: 'published' },
      { id: 2, title: 'About', url: '/about', status: 'published' },
      { id: 3, title: 'Services', url: '/services', status: 'draft' },
    ],
  });

  return (
    <Box>
      {/* Header Section */}
      <Box sx={{ mb: 4, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h5" sx={{ fontWeight: 600 }}>
          Website Management
        </Typography>
        <Box sx={{ display: 'flex', gap: 2 }}>
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={() => navigate('/admin/website/create')}
          >
            Create Website
          </Button>
          <Button
            variant="outlined"
            startIcon={<EditIcon />}
            onClick={() => setEditMode(!editMode)}
          >
            {editMode ? 'Save Changes' : 'Edit Website'}
          </Button>
        </Box>
      </Box>

      {/* Metrics Overview */}
      <Grid container spacing={3} sx={{ mb: 4 }}>
        <Grid item xs={12} sm={6} md={3}>
          <WebsiteMetricCard
            icon={SpeedIcon}
            title="Performance Score"
            value="94"
            trend={2.5}
            color="#2196f3"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <WebsiteMetricCard
            icon={VisibilityIcon}
            title="Monthly Visitors"
            value="12.5K"
            trend={5.8}
            color="#4caf50"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <WebsiteMetricCard
            icon={SecurityIcon}
            title="Security Score"
            value="A+"
            color="#ff9800"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <WebsiteMetricCard
            icon={CodeIcon}
            title="Active Pages"
            value={websiteData.pages.length}
            color="#9c27b0"
          />
        </Grid>
      </Grid>

      {/* Website Configuration */}
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
              <Typography variant="h6" sx={{ mb: 3 }}>
                Website Configuration
              </Typography>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                <TextField
                  label="Domain Name"
                  value={websiteData.domain}
                  disabled={!editMode}
                  fullWidth
                />
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <Typography>SSL Certificate</Typography>
                  <Switch checked={websiteData.ssl} disabled={!editMode} />
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <Typography>Google Analytics</Typography>
                  <Switch checked={websiteData.analytics} disabled={!editMode} />
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>

        {/* Pages Management */}
        <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
                <Typography variant="h6">
                  Pages
                </Typography>
                <Button
                  startIcon={<AddIcon />}
                  variant="outlined"
                  size="small"
                >
                  Add Page
                </Button>
              </Box>
              <List>
                {websiteData.pages.map((page) => (
                  <ListItem
                    key={page.id}
                    secondaryAction={
                      <Box>
                        <Chip
                          size="small"
                          label={page.status}
                          color={page.status === 'published' ? 'success' : 'default'}
                          sx={{ mr: 1 }}
                        />
                        <IconButton edge="end" aria-label="edit" size="small">
                          <EditIcon />
                        </IconButton>
                        <IconButton edge="end" aria-label="delete" size="small">
                          <DeleteIcon />
                        </IconButton>
                      </Box>
                    }
                  >
                    <ListItemIcon>
                      <LinkIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={page.title}
                      secondary={page.url}
                    />
                  </ListItem>
                ))}
              </List>
            </CardContent>
          </Card>
        </Grid>

        {/* SEO Tools */}
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Typography variant="h6" sx={{ mb: 3 }}>
                SEO Tools
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                  <Button
                    fullWidth
                    variant="outlined"
                    startIcon={<SearchIcon />}
                    sx={{ height: '100%' }}
                  >
                    Analyze SEO Performance
                  </Button>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Button
                    fullWidth
                    variant="outlined"
                    startIcon={<UploadIcon />}
                    sx={{ height: '100%' }}
                  >
                    Submit Sitemap
                  </Button>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Button
                    fullWidth
                    variant="outlined"
                    startIcon={<SettingsIcon />}
                    sx={{ height: '100%' }}
                  >
                    Configure Meta Tags
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default WebsiteOverview; 