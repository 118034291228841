import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Chip,
  Grid,
  Tabs,
  Tab,
  Divider,
  Collapse,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Snackbar,
} from '@mui/material';
import {
  Add as AddIcon,
  EditOutlined as EditIcon,
  DeleteOutline as DeleteIcon,
  CheckCircleOutline as CheckCircleOutlineIcon,
  Close as CloseIcon,
  Inventory2Outlined as InventoryIcon,
  MenuBookOutlined as MenuBookIcon,
  AttachMoneyOutlined as MoneyIcon,
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
} from '@mui/icons-material';
import { getPriceBookItems, getPricingRules, getItems, getPackages, createPackage, deletePackage, updatePackage } from '../../services/api';


const colors = {
  packages: '#9C27B0',
  packageBorder: '#777b83'  // Much darker blue-grey color
};

const PackageRow = ({ pkg, onEdit, onDelete }) => {
  const [expanded, setExpanded] = useState(false);

  const getItemIcon = (type) => {
    switch(type) {
      case 'inventory':
        return <InventoryIcon color="primary" />;
      case 'saved':
        return <MenuBookIcon color="secondary" />;
      case 'pricing':
        return <MoneyIcon sx={{ color: 'success.main' }} />;
      default:
        return null;
    }
  };

  const formatDescription = (description) => {
    if (!description) return null;
    return description.split('\n').map((line, i) => (
      <Typography 
        key={i} 
        variant="body2" 
        component="div"
        sx={{ 
          color: 'var(--text-secondary)',
          whiteSpace: 'pre-line',  // This preserves line breaks
          mb: 0.5
        }}
      >
        {line}
      </Typography>
    ));
  };

  return (
    <>
      <TableRow 
        onClick={() => setExpanded(!expanded)}
        sx={{ 
          cursor: 'pointer',
          transition: 'background-color 0.2s ease',
          '&.MuiTableRow-root:hover': {
            backgroundColor: 'var(--background-default) !important'
          }
        }}
      >
        <TableCell>
          <IconButton size="small">
            {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </TableCell>
        <TableCell>{pkg.name}</TableCell>
        <TableCell>{pkg.description}</TableCell>
        <TableCell>{pkg.items?.length || 0}</TableCell>
        <TableCell>
          <IconButton 
            onClick={(e) => {
              e.stopPropagation();
              onEdit(pkg);
            }}
          >
            <EditIcon color="primary" />
          </IconButton>
          <IconButton 
            onClick={(e) => {
              e.stopPropagation();
              onDelete(pkg);
            }}
            sx={{ 
              color: 'var(--error-main)',
              '&:hover': {
                backgroundColor: 'rgba(211, 47, 47, 0.04)'
              }
            }}
          >
            <DeleteIcon sx={{ color: 'red' }} />
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={5}>
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <Box sx={{ m: 2 }}>
              <List dense>
                {pkg.items?.map((item, index) => (
                  <React.Fragment key={index}>
                    <ListItem 
                      sx={{
                        bgcolor: 'transparent',
                        p: 2,
                        position: 'relative',
                        border: `1px solid ${colors.packageBorder}`,
                        borderRadius: '8px',
                        mb: 2,
                        '&:hover': {
                          bgcolor: 'var(--background-paper)'
                        },
                        transition: 'all 0.2s ease'
                      }}
                    >
                      <Box sx={{ display: 'flex', width: '100%', alignItems: 'flex-start' }}>
                        <ListItemIcon sx={{ minWidth: 40, mt: 0.5 }}>
                          {getItemIcon(item.type)}
                        </ListItemIcon>
                        
                        <Box sx={{ flexGrow: 1, pr: 15 }}>
                          <Typography variant="subtitle1" sx={{ 
                            color: 'var(--text-primary)',
                            fontWeight: 500,
                            mb: 1
                          }}>
                            {item.name || item.item?.name}
                          </Typography>
                          
                          <Typography variant="body2" sx={{ 
                            color: 'var(--text-secondary)',
                            mb: 1,
                            display: 'inline-block',
                            bgcolor: 'var(--background-paper)',
                            px: 1,
                            py: 0.5,
                            borderRadius: 1
                          }}>
                            {item.type.charAt(0).toUpperCase() + item.type.slice(1)}
                          </Typography>
                          
                          {(item.description || item.item?.description) && (
                            <Box sx={{ mt: 1, color: 'var(--text-secondary)' }}>
                              {formatDescription(item.description || item.item?.description)}
                            </Box>
                          )}
                        </Box>

                        <Box sx={{ 
                          position: 'absolute',
                          top: 16,
                          right: 16,
                          display: 'flex',
                          alignItems: 'center',
                          gap: 2,
                          bgcolor: 'var(--background-paper)',
                          padding: '6px 16px',
                          borderRadius: '20px',
                          boxShadow: '0 1px 2px rgba(0,0,0,0.1)',
                          border: '1px solid var(--divider)'
                        }}>
                          <Typography variant="body2" sx={{ 
                            color: 'var(--text-secondary)',
                            display: 'flex',
                            alignItems: 'center',
                            gap: 0.5
                          }}>
                            <Box component="span" sx={{ fontWeight: 500 }}>Qty:</Box> {item.quantity}
                          </Typography>
                          <Divider orientation="vertical" flexItem sx={{ borderColor: 'var(--divider)' }} />
                          <Typography variant="body2" sx={{ 
                            color: 'success.main',
                            fontWeight: 500
                          }}>
                            ${parseFloat(item.price || item.item?.price || 0).toFixed(2)}
                          </Typography>
                        </Box>
                      </Box>
                    </ListItem>
                  </React.Fragment>
                ))}
              </List>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

const PackagesManager = () => {
  const [packages, setPackages] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [currentPackage, setCurrentPackage] = useState({
    name: '',
    description: '',
    items: [], // [{type: 'inventory|saved|pricing', id: '1', quantity: 1}]
  });
  const [inventoryItems, setInventoryItems] = useState([]);
  const [savedItems, setSavedItems] = useState([]);
  const [pricingRules, setPricingRules] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [packageToDelete, setPackageToDelete] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  // Define colors object
  const colors = {
    packages: '#9C27B0',
    packageBorder: '#1a1f2b'  // Much darker blue-grey color
  };

  // Define colors and styles
  const styles = {
    tableContainer: {
      backgroundColor: 'var(--background-paper)',
      borderRadius: '8px',
      boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
      '& .MuiTableCell-root': {
        border: 'none',
        color: 'var(--text-primary)'
      }
    },
    chip: {
      m: 0.5,
      backgroundColor: 'var(--background-default)',
      color: 'var(--text-primary)',
      borderColor: 'var(--divider)',
      '&:hover': {
        backgroundColor: 'var(--background-paper)'
      }
    },
    dialog: {
      '& .MuiDialog-paper': {
        backgroundColor: 'var(--background-paper)',
        color: 'var(--text-primary)'
      }
    },
    select: {
      '& .MuiOutlinedInput-root': {
        color: 'var(--text-primary)',
        '& fieldset': {
          borderColor: 'var(--divider)'
        },
        '&:hover fieldset': {
          borderColor: 'var(--primary-main)'
        }
      },
      '& .MuiInputLabel-root': {
        color: 'var(--text-secondary)'
      }
    },
    itemList: {
      backgroundColor: 'var(--background-default)',
      borderRadius: '8px',
      p: 2,
      mb: 2
    }
  };

  useEffect(() => {
    loadAllItems();
  }, []);

  const loadAllItems = async () => {
    setLoading(true);
    setError(null);
    try {
      const [inventory, saved, rules, pkgs] = await Promise.all([
        getPriceBookItems(),
        getItems(),
        getPricingRules(),
        getPackages()
      ]);
      
      console.log('=== Debug Logs ===');
      console.log('Packages received:', pkgs);
      console.log('Inventory items:', inventory);
      console.log('Saved items:', saved);
      console.log('Pricing rules:', rules);
      
      // Set the items, ensuring we handle both array and object responses
      setInventoryItems(Array.isArray(inventory) ? inventory : inventory?.data || []);
      setSavedItems(Array.isArray(saved) ? saved : saved?.data || []);
      setPricingRules(Array.isArray(rules) ? rules : rules?.data || []);
      setPackages(Array.isArray(pkgs) ? pkgs : pkgs?.data || []);

      console.log('State after setting:', {
        packages: Array.isArray(pkgs) ? pkgs : pkgs?.data || [],
        inventoryItems: Array.isArray(inventory) ? inventory : inventory?.data || [],
        savedItems: Array.isArray(saved) ? saved : saved?.data || [],
        pricingRules: Array.isArray(rules) ? rules : rules?.data || []
      });
    } catch (error) {
      console.error('Error loading items:', error);
      setError('Failed to load items. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = (pkg) => {
    // Format the package data to match the expected structure
    const formattedPackage = {
      id: pkg.id,
      name: pkg.name,
      description: pkg.description,
      items: pkg.items.map(item => ({
        id: item.id,
        type: item.type,
        item_id: item.item_id,
        quantity: item.quantity,
        price: item.price,
        item: {
          id: item.item_id,
          name: item.name,
          title: item.name,
          price: item.price,
          baseRate: item.price
        }
      }))
    };

    console.log('Editing package:', formattedPackage);
    setCurrentPackage(formattedPackage);
    setIsEditing(true);
    setOpenDialog(true);
  };

  const handleDelete = async (id) => {
    if (!id) {
      console.error('Invalid package ID:', id);
      setSnackbarMessage('Cannot delete package: Invalid ID');
      setSnackbarOpen(true);
      return;
    }

    try {
      console.log('Attempting to delete package:', id);
      await deletePackage(id);
      console.log('Package deleted successfully');
      
      // Refresh the packages list
      await loadAllItems();
      
      setSnackbarMessage('Package deleted successfully');
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Error deleting package:', error);
      setSnackbarMessage('Failed to delete package');
      setSnackbarOpen(true);
    }
  };

  const handleDeleteClick = (pkg) => {
    if (!pkg || !pkg.id) {
      console.error('Invalid package:', pkg);
      return;
    }
    setPackageToDelete(pkg);
    setDeleteConfirmOpen(true);
  };

  const handleConfirmDelete = async () => {
    if (packageToDelete) {
      await handleDelete(packageToDelete.id);
      setDeleteConfirmOpen(false);
      setPackageToDelete(null);
    }
  };

  const handleSave = async () => {
    console.log('Current package before save:', currentPackage);
    
    try {
      const packageData = {
        name: currentPackage.name,
        description: currentPackage.description,
        items: currentPackage.items.map(item => {
          console.log('Processing item for save:', item);
          return {
            type: item.type,
            item_id: item.item_id,
            quantity: item.quantity,
            price: item.price,
            name: item.item.name || item.name,
            description: item.item.description || item.description || ''
          };
        })
      };

      console.log('Final package data to send:', JSON.stringify(packageData, null, 2));

      if (isEditing) {
        const result = await updatePackage(currentPackage.id, packageData);
        console.log('Update package result:', result);
      } else {
        const result = await createPackage(packageData);
        console.log('Create package result:', result);
      }

      setShowSuccessModal(true);
      loadAllItems();
      setOpenDialog(false);
      setCurrentPackage({
        name: '',
        description: '',
        items: []
      });
    } catch (error) {
      console.error('Error saving package:', error);
      // Handle error (show error message to user)
    }
  };

  const handleAddItem = (type, item) => {
    console.log('Adding item:', { type, item });
    
    let itemPrice = 0;
    switch (type) {
      case 'inventory':
        itemPrice = parseFloat(item.price || 0);
        break;
      case 'saved':
        itemPrice = parseFloat(item.unitPrice || item.price || 0);
        break;
      case 'pricing':
        itemPrice = parseFloat(item.baseRate || 0);
        break;
      default:
        itemPrice = 0;
    }

    const newItem = {
      type,
      item_id: item.id,
      item: {
        id: item.id,
        name: item.title || item.name || '',
        description: item.description || '',
        price: itemPrice
      },
      quantity: 1,
      price: itemPrice,
      description: item.description || ''
    };

    console.log('Formatted new item:', newItem);

    setCurrentPackage(prev => {
      const updated = {
        ...prev,
        items: [...prev.items, newItem]
      };
      console.log('Updated package:', updated);
      return updated;
    });
  };

  const handleQuantityChange = (index, value) => {
    const quantity = parseInt(value) || 1;
    setCurrentPackage(prev => ({
      ...prev,
      items: prev.items.map((item, i) => 
        i === index ? { ...item, quantity } : item
      )
    }));
  };

  const handleRemoveItem = (index) => {
    setCurrentPackage(prev => ({
      ...prev,
      items: prev.items.filter((_, i) => i !== index)
    }));
  };

  const calculatePackageTotal = (pkg) => {
    return pkg.items.reduce((total, item) => {
      const price = parseFloat(item.price) || 0;
      const quantity = parseInt(item.quantity) || 1;
      return total + (price * quantity);
    }, 0);
  };

  // Handle tab change
  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  // Render item selection based on tab
  const renderItemSelection = () => {
    if (loading) {
      return (
        <Box sx={{ ...styles.itemList, textAlign: 'center' }}>
          <Typography>Loading items...</Typography>
        </Box>
      );
    }

    switch (selectedTab) {
      case 0: // Inventory
        return (
          <Box sx={styles.itemList}>
            <Typography variant="subtitle2" sx={{ mb: 2, color: 'var(--text-secondary)' }}>
              Select Inventory Items
            </Typography>
            {inventoryItems.map((item) => (
              <Box 
                key={item.id}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  p: 2,
                  mb: 1,
                  borderRadius: '4px',
                  backgroundColor: 'var(--background-paper)',
                  border: '1px solid var(--divider)'
                }}
              >
                <Box sx={{ flex: 1 }}>
                  <Typography sx={{ color: 'var(--text-primary)' }}>
                    {item.title}
                  </Typography>
                  <Typography variant="body2" sx={{ color: 'var(--text-secondary)' }}>
                    ${parseFloat(item.price || 0).toFixed(2)}
                  </Typography>
                </Box>
                <Button
                  onClick={() => handleAddItem('inventory', {
                    ...item,
                    price: parseFloat(item.price || 0),
                    description: item.description
                  })}
                  variant="outlined"
                  size="small"
                  sx={{
                    borderColor: 'var(--primary-main)',
                    color: 'var(--primary-main)',
                    '&:hover': {
                      backgroundColor: 'var(--primary-main)',
                      color: '#fff'
                    }
                  }}
                >
                  Add
                </Button>
              </Box>
            ))}
          </Box>
        );
      case 1: // Saved Items
        return (
          <Box sx={styles.itemList}>
            <Typography variant="subtitle2" sx={{ mb: 2, color: 'var(--text-secondary)' }}>
              Select Saved Items
            </Typography>
            {savedItems.map((item) => (
              <Box 
                key={item.id}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  p: 1,
                  mb: 1,
                  borderRadius: '4px',
                  backgroundColor: 'var(--background-paper)',
                  '&:hover': {
                    backgroundColor: 'var(--background-default)'
                  }
                }}
              >
                <Typography sx={{ flex: 1 }}>{item.title}</Typography>
                <Typography sx={{ mx: 2 }}>${item.unitPrice || 0}</Typography>
                <Button
                  size="small"
                  variant="outlined"
                  onClick={() => handleAddItem('saved', { 
                    ...item, 
                    price: item.unitPrice,
                    description: item.description
                  })}
                  sx={{
                    borderColor: 'var(--primary-main)',
                    color: 'var(--primary-main)',
                    '&:hover': {
                      backgroundColor: 'var(--primary-main)',
                      color: '#fff'
                    }
                  }}
                >
                  Add
                </Button>
              </Box>
            ))}
          </Box>
        );
      case 2: // Pricing Rules
        return (
          <Box sx={styles.itemList}>
            <Typography variant="subtitle2" sx={{ mb: 2, color: 'var(--text-secondary)' }}>
              Select Pricing Rules
            </Typography>
            {pricingRules.map((rule) => (
              <Box 
                key={rule.id}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  p: 1,
                  mb: 1,
                  borderRadius: '4px',
                  backgroundColor: 'var(--background-paper)',
                  '&:hover': {
                    backgroundColor: 'var(--background-default)'
                  }
                }}
              >
                <Typography sx={{ flex: 1 }}>{rule.name}</Typography>
                <Typography sx={{ mx: 2 }}>${rule.baseRate || 0}/unit</Typography>
                <Button
                  size="small"
                  variant="outlined"
                  onClick={() => handleAddItem('pricing', { 
                    ...rule, 
                    price: rule.baseRate,
                    description: rule.description
                  })}
                  sx={{
                    borderColor: 'var(--primary-main)',
                    color: 'var(--primary-main)',
                    '&:hover': {
                      backgroundColor: 'var(--primary-main)',
                      color: '#fff'
                    }
                  }}
                >
                  Add
                </Button>
              </Box>
            ))}
          </Box>
        );
      default:
        return null;
    }
  };

  return (
    <Box>
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center',
        mb: 3 
      }}>
        <Typography variant="h6" sx={{ color: 'var(--text-primary)' }}>
          Package Management
        </Typography>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() => {
            setIsEditing(false);
            setCurrentPackage({ name: '', description: '', items: [] });
            setOpenDialog(true);
          }}
          sx={{
            backgroundColor: colors.packages,
            color: '#fff',
            '&:hover': {
              backgroundColor: 'var(--primary-dark)',
            }
          }}
        >
          Create Package
        </Button>
      </Box>

      <TableContainer component={Paper} sx={styles.tableContainer}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell width="50px" />
              <TableCell>Name</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Items</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {packages.map((pkg) => (
              <PackageRow 
                key={pkg.id}
                pkg={pkg}
                onEdit={handleEdit}
                onDelete={handleDeleteClick}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog 
        open={openDialog} 
        onClose={() => setOpenDialog(false)}
        maxWidth="lg"
        fullWidth
        sx={{
          ...styles.dialog,
          '& .MuiDialog-paper': {
            minHeight: '80vh',
            maxHeight: '90vh',
            backgroundColor: 'var(--background-paper)',
            color: 'var(--text-primary)'
          }
        }}
      >
        <DialogTitle sx={{ 
          borderBottom: 1, 
          borderColor: 'divider',
          color: 'var(--text-primary)',
          backgroundColor: 'var(--background-paper)'
        }}>
          {isEditing ? 'Edit Package' : 'Create New Package'}
        </DialogTitle>
        <DialogContent sx={{ backgroundColor: 'var(--background-paper)' }}>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                label="Package Name"
                value={currentPackage.name || ''}
                onChange={(e) => setCurrentPackage(prev => ({
                  ...prev,
                  name: e.target.value
                }))}
                error={!currentPackage.name}
                helperText={!currentPackage.name ? 'Package name is required' : ''}
                sx={{
                  mb: 2,
                  '& .MuiInputBase-input': {
                    color: 'var(--text-primary)'
                  }
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Description"
                multiline
                rows={2}
                value={currentPackage.description}
                onChange={(e) => setCurrentPackage(prev => ({ ...prev, description: e.target.value }))}
                sx={{
                  ...styles.select,
                  '& .MuiInputBase-input': {
                    color: 'var(--text-primary)'
                  },
                  '& .MuiOutlinedInput-root': {
                    backgroundColor: 'var(--background-default)'
                  }
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <Divider sx={{ my: 2, borderColor: 'var(--divider)' }} />
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Typography variant="h6" sx={{ mb: 2, color: 'var(--text-primary)' }}>Add Items</Typography>
                  <Tabs 
                    value={selectedTab} 
                    onChange={handleTabChange}
                    sx={{
                      borderBottom: 1,
                      borderColor: 'var(--divider)',
                      mb: 2,
                      '& .MuiTab-root': {
                        color: 'var(--text-secondary)',
                        '&.Mui-selected': {
                          color: 'var(--primary-main)'
                        }
                      }
                    }}
                  >
                    <Tab icon={<InventoryIcon />} label="Inventory" />
                    <Tab icon={<MenuBookIcon />} label="Saved Items" />
                    <Tab icon={<MoneyIcon />} label="Pricing Rules" />
                  </Tabs>
                  <Box sx={{ 
                    maxHeight: '50vh', 
                    overflowY: 'auto',
                    pr: 2,
                    mr: -2,
                    backgroundColor: 'var(--background-paper)',
                    '&::-webkit-scrollbar': {
                      width: '8px'
                    },
                    '&::-webkit-scrollbar-track': {
                      backgroundColor: 'var(--background-default)'
                    },
                    '&::-webkit-scrollbar-thumb': {
                      backgroundColor: 'var(--divider)',
                      borderRadius: '4px'
                    }
                  }}>
                    {renderItemSelection()}
                  </Box>
                </Grid>

                <Grid item xs={6}>
                  <Typography variant="h6" sx={{ mb: 2, color: 'var(--text-primary)' }}>Selected Items</Typography>
                  <Box sx={{ 
                    maxHeight: '60vh', 
                    overflowY: 'auto',
                    backgroundColor: 'var(--background-paper)',
                    borderRadius: '8px',
                    p: 2,
                    '&::-webkit-scrollbar': {
                      width: '8px'
                    },
                    '&::-webkit-scrollbar-track': {
                      backgroundColor: 'var(--background-default)'
                    },
                    '&::-webkit-scrollbar-thumb': {
                      backgroundColor: 'var(--divider)',
                      borderRadius: '4px'
                    }
                  }}>
                    {currentPackage.items.map((item, index) => (
                      <Box 
                        key={index} 
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          p: 1,
                          mb: 1,
                          borderRadius: '4px',
                          backgroundColor: 'var(--background-default)',
                          border: '1px solid var(--divider)',
                          color: 'var(--text-primary)'
                        }}
                      >
                        <Typography sx={{ flex: 1, color: 'var(--text-primary)' }}>
                          {item.item.name || item.item.title}
                        </Typography>
                        <Typography sx={{ mx: 2, color: 'var(--text-primary)' }}>
                          ${(item.item.price || item.item.baseRate || item.item.unitPrice || 0).toFixed(2)}
                        </Typography>
                        <TextField
                          type="number"
                          value={item.quantity}
                          onChange={(e) => handleQuantityChange(index, e.target.value)}
                          sx={{ 
                            width: 80, 
                            mx: 2,
                            '& .MuiInputBase-input': {
                              color: 'var(--text-primary)'
                            },
                            '& .MuiOutlinedInput-root': {
                              backgroundColor: 'var(--background-default)'
                            }
                          }}
                          size="small"
                        />
                        <IconButton 
                          onClick={() => handleRemoveItem(index)}
                          sx={{ color: 'var(--error-main)' }}
                          size="small"
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Box>
                    ))}
                    {currentPackage.items.length === 0 && (
                      <Typography sx={{ color: 'var(--text-secondary)', textAlign: 'center', py: 2 }}>
                        No items selected
                      </Typography>
                    )}
                  </Box>
                  <Box sx={{ 
                    mt: 2, 
                    p: 2, 
                    backgroundColor: 'var(--background-default)',
                    borderRadius: '8px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                  }}>
                    <Typography variant="subtitle1" sx={{ color: 'var(--text-primary)' }}>
                      Total Price:
                    </Typography>
                    <Typography variant="h6" sx={{ color: 'var(--text-primary)' }}>
                      ${calculatePackageTotal(currentPackage).toFixed(2)}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ 
          borderTop: 1, 
          borderColor: 'var(--divider)', 
          p: 2,
          backgroundColor: 'var(--background-paper)'
        }}>
          <Button 
            onClick={() => setOpenDialog(false)}
            sx={{ color: 'var(--text-secondary)' }}
          >
            Cancel
          </Button>
          <Button 
            onClick={handleSave}
            variant="contained"
            sx={{ backgroundColor: colors.packages }}
          >
            Save Package
          </Button>
        </DialogActions>
      </Dialog>

      {/* Success Modal */}
      <Dialog 
        open={showSuccessModal} 
        onClose={() => setShowSuccessModal(false)}
        maxWidth="xs"
        fullWidth
      >
        <Box sx={{ position: 'relative' }}>
          <IconButton
            onClick={() => setShowSuccessModal(false)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: 'white',
              '&:hover': {
                backgroundColor: 'rgba(255, 255, 255, 0.1)'
              }
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogTitle sx={{ 
            backgroundColor: '#4caf50', 
            color: 'white',
            textAlign: 'center',
            pb: 2
          }}>
            <Box sx={{ 
              display: 'flex', 
              flexDirection: 'column', 
              alignItems: 'center',
              gap: 1
            }}>
              <CheckCircleOutlineIcon 
                sx={{ 
                  fontSize: '48px',
                  animation: 'checkmark 0.5s ease-in-out',
                  '@keyframes checkmark': {
                    '0%': {
                      transform: 'scale(0)',
                      opacity: 0
                    },
                    '50%': {
                      transform: 'scale(1.2)',
                    },
                    '100%': {
                      transform: 'scale(1)',
                      opacity: 1
                    }
                  }
                }} 
              />
              Success
            </Box>
          </DialogTitle>
          <DialogContent sx={{ 
            mt: 2, 
            textAlign: 'center',
            color: '#666'
          }}>
            Package has been saved successfully.
          </DialogContent>
        </Box>
      </Dialog>

      {/* Confirmation Dialog */}
      <Dialog
        open={deleteConfirmOpen}
        onClose={() => setDeleteConfirmOpen(false)}
      >
        <DialogTitle>Delete Package</DialogTitle>
        <DialogContent>
          Are you sure you want to delete this package?
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={() => setDeleteConfirmOpen(false)}
            sx={{ color: 'var(--text-secondary)' }}
          >
            Cancel
          </Button>
          <Button 
            onClick={handleConfirmDelete}
            sx={{ color: 'var(--error-main)' }}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
      />
    </Box>
  );
};

export default PackagesManager; 