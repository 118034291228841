import React from 'react';
import { 
  Box, 
  FormControl, 
  TextField, 
  MenuItem,
  styled 
} from '@mui/material';
import {
  Email,
  Phone,
  PhoneAndroid,
  ContactMail,
} from '@mui/icons-material';

// Rest of the component remains the same
const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    borderRadius: 8,
    backgroundColor: theme.palette.background.paper,
    transition: 'all 0.2s ease-in-out',
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
    '&.Mui-focused': {
      backgroundColor: theme.palette.background.paper,
      boxShadow: `0 0 0 2px ${theme.palette.primary.main}25`,
    }
  }
}));

const ContactDetailsSection = ({ data, onChange }) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2.5 }}>
      <StyledTextField
        fullWidth
        label="Email Address"
        value={data.email}
        onChange={(e) => onChange('email', e.target.value)}
        InputProps={{
          startAdornment: <Email sx={{ mr: 1, color: 'action.active' }} />,
        }}
      />

      <StyledTextField
        fullWidth
        label="Phone Number"
        value={data.phone}
        onChange={(e) => onChange('phone', e.target.value)}
        InputProps={{
          startAdornment: <Phone sx={{ mr: 1, color: 'action.active' }} />,
        }}
      />

      <StyledTextField
        fullWidth
        label="Mobile Number"
        value={data.mobile}
        onChange={(e) => onChange('mobile', e.target.value)}
        InputProps={{
          startAdornment: <PhoneAndroid sx={{ mr: 1, color: 'action.active' }} />,
        }}
      />

      <StyledTextField
        select
        fullWidth
        label="Preferred Contact Method"
        value={data.preferredContact}
        onChange={(e) => onChange('preferredContact', e.target.value)}
        InputProps={{
          startAdornment: <ContactMail sx={{ mr: 1, color: 'action.active' }} />,
        }}
      >
        <MenuItem value="email">Email</MenuItem>
        <MenuItem value="phone">Phone</MenuItem>
        <MenuItem value="mobile">Mobile</MenuItem>
        <MenuItem value="whatsapp">WhatsApp</MenuItem>
      </StyledTextField>
    </Box>
  );
};

export default ContactDetailsSection; 