import React from 'react';
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Chip,
  TextField,
  InputAdornment,
  FormControl,
  Select,
  MenuItem,
  IconButton,
  Tooltip,
  Grid
} from '@mui/material';
import {
  Search as SearchIcon,
  FilterList as FilterListIcon,
  MoreVert as MoreVertIcon
} from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import LeadMetricsCards from './LeadMetricsCards';
import LeadFilters from './LeadFilters';
import LeadQuickActions from './LeadQuickActions';

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  '& .MuiTableCell-head': {
    fontWeight: 600,
    backgroundColor: theme.palette.background.default,
    fontFamily: 'Urbanist, sans-serif',
    fontSize: '0.95rem',
    color: theme.palette.text.secondary,
    borderBottom: `2px solid ${theme.palette.divider}`,
    padding: '16px 24px',
  },
  '& .MuiTableCell-body': {
    fontFamily: 'Urbanist, sans-serif',
    fontSize: '0.9rem',
    padding: '16px 24px',
  },
  '& .MuiTableRow-root:hover': {
    backgroundColor: 'rgba(25, 118, 210, 0.04)',
    cursor: 'pointer',
  }
}));

const StyledChip = styled(Chip)(({ theme, status }) => ({
  fontFamily: 'Urbanist, sans-serif',
  fontWeight: 500,
  fontSize: '0.8rem',
  height: '24px',
  backgroundColor: 
    status === 'New' ? '#e3f2fd' :
    status === 'In Progress' ? '#fff3e0' :
    status === 'Qualified' ? '#e8f5e9' :
    status === 'Lost' ? '#ffebee' :
    status === 'Won' ? '#e8f5e9' : '#f5f5f5',
  color:
    status === 'New' ? '#1976d2' :
    status === 'In Progress' ? '#f57c00' :
    status === 'Qualified' ? '#2e7d32' :
    status === 'Lost' ? '#c62828' :
    status === 'Won' ? '#2e7d32' : '#616161',
}));

const AdminLeadsListPanel = ({ 
  leads, 
  searchTerm, 
  setSearchTerm,
  sourceFilter,
  setSourceFilter,
  statusFilter,
  setStatusFilter,
  onLeadClick
}) => {
  const sources = ['All', 'Website', 'Referral', 'Direct', 'Social'];
  const statuses = ['All', 'New', 'In Progress', 'Qualified', 'Proposal', 'Won', 'Lost'];

  const handleQuickAction = (action) => {
    console.log('Quick action:', action);
    // Implement action handlers
  };

  return (
    <Box>
      <LeadMetricsCards leads={leads} />
      
      <Paper elevation={0} sx={{ p: 3, borderRadius: 2 }}>
        <LeadFilters 
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          sourceFilter={sourceFilter}
          setSourceFilter={setSourceFilter}
          statusFilter={statusFilter}
          setStatusFilter={setStatusFilter}
        />
        <StyledTableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Source</TableCell>
                <TableCell>Status</TableCell>
                <TableCell align="right">Value</TableCell>
                <TableCell>Date</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {leads.map((lead) => (
                <TableRow key={lead.id} onClick={() => onLeadClick(lead.id)}>
                  <TableCell>{lead.name}</TableCell>
                  <TableCell>{lead.source}</TableCell>
                  <TableCell>
                    <StyledChip 
                      label={lead.status} 
                      status={lead.status}
                      size="small"
                    />
                  </TableCell>
                  <TableCell align="right">
                    ${lead.value.toLocaleString()}
                  </TableCell>
                  <TableCell>
                    {new Date(lead.date).toLocaleDateString()}
                  </TableCell>
                  <TableCell align="right">
                    <Tooltip title="More actions">
                      <IconButton size="small" onClick={(e) => {
                        e.stopPropagation();
                        // Handle actions menu
                      }}>
                        <MoreVertIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </StyledTableContainer>
      </Paper>
      
      <LeadQuickActions onAction={handleQuickAction} />
    </Box>
  );
};

export default AdminLeadsListPanel; 