import React, { useState, useEffect, useCallback } from 'react';
import { 
  Typography, TextField, Button, Checkbox, 
  FormControlLabel, List, ListItem, ListItemText, ListItemSecondaryAction,
  Select, MenuItem, FormControl, InputLabel, Avatar, Box, CircularProgress, Autocomplete, InputAdornment, OutlinedInput, Chip
} from '@mui/material';
import { 
  getEmployees,
  assignEmployeesToEvent,
  assignEmployeesToProject,
  getProjectEvents,
  getProjectEmployees,
  getEventEmployees,
  checkEventAvailability
} from '../services/api';
import styles from './EmployeeAssignment.module.scss';
import SearchIcon from '@mui/icons-material/Search';

const AVAILABILITY_COLORS = {
  available: {
    bg: '#E8F5E9',
    color: '#2E7D32',
  },
  unavailable: {
    bg: '#FFEBEE',
    color: '#C62828',
  }
};

const EventEmployeeAssignment = ({ projectId, onAssignmentUpdate }) => {
  const [employees, setEmployees] = useState([]);
  const [selectedEmployees, setSelectedEmployees] = useState({});
  const [selectedEventId, setSelectedEventId] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [events, setEvents] = useState([]);
  const [availabilityMap, setAvailabilityMap] = useState({});

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      const [allEmployeesResponse, eventsData, projectEmployeesData] = await Promise.all([
        getEmployees(),
        getProjectEvents(projectId),
        getProjectEmployees(projectId)
      ]);

      console.log('All Employees Response:', allEmployeesResponse);
      console.log('Events Data:', eventsData);
      console.log('Project Employees Data:', projectEmployeesData);

      // Filter out events without time slots
      const eventsWithTimeSlots = eventsData.filter(event => 
        event.time_slots && event.time_slots.length > 0
      );

      setEmployees(allEmployeesResponse.employees || []);
      setEvents(eventsWithTimeSlots);

      if (eventsWithTimeSlots.length > 0) {
        const eventId = eventsWithTimeSlots[0].id;
        setSelectedEventId(eventId);
        
        try {
          const [eventEmployeesData, availabilityResponse] = await Promise.all([
            getEventEmployees(eventId),
            checkEventAvailability(eventId)
          ]);

          // Process availability data
          const availabilityData = availabilityResponse.reduce((acc, emp) => {
            acc[emp.id] = {
              available: emp.available,
              conflicts: emp.conflicts
            };
            return acc;
          }, {});

          setAvailabilityMap(availabilityData);

          // Initialize selectedEmployees state
          const initialSelectedEmployees = {};
          allEmployeesResponse.employees.forEach(emp => {
            const isAssigned = eventEmployeesData.employees.some(ee => ee.id === emp.id);
            initialSelectedEmployees[emp.id] = {
              selected: isAssigned,
              paymentType: isAssigned ? eventEmployeesData.employees.find(ee => ee.id === emp.id).payment_type : 'hourly',
              rate: isAssigned ? eventEmployeesData.employees.find(ee => ee.id === emp.id).rate : '',
              percentage: isAssigned ? eventEmployeesData.employees.find(ee => ee.id === emp.id).percentage : ''
            };
          });
          setSelectedEmployees(initialSelectedEmployees);
        } catch (err) {
          console.error('Error fetching event details:', err);
        }
      }

      setLoading(false);
    } catch (err) {
      console.error('Error fetching data:', err);
      setError('Failed to fetch data. Please try again.');
      setLoading(false);
    }
  }, [projectId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleEventChange = async (event) => {
    const eventId = event.target.value;
    setSelectedEventId(eventId);
    try {
      setLoading(true);
      const [employeesResponse, availabilityResponse] = await Promise.all([
        getEventEmployees(eventId),
        checkEventAvailability(eventId)
      ]);
      
      console.log('Availability Response:', availabilityResponse);
      
      // Process employee assignments
      if (employeesResponse && Array.isArray(employeesResponse.employees)) {
        console.log('Employee Assignments:', employeesResponse.employees);
        // Update selectedEmployees state
        const updatedSelectedEmployees = { ...selectedEmployees };
        employees.forEach(emp => {
          const assignedEmployee = employeesResponse.employees.find(e => e.id === emp.id);
          updatedSelectedEmployees[emp.id] = {
            selected: !!assignedEmployee,
            paymentType: assignedEmployee ? assignedEmployee.payment_type : 'hourly',
            rate: assignedEmployee ? assignedEmployee.rate || '' : '',
            percentage: assignedEmployee ? assignedEmployee.percentage || '' : ''
          };
        });
        setSelectedEmployees(updatedSelectedEmployees);
      }

      // Process availability data
      const availabilityData = availabilityResponse.reduce((acc, emp) => {
        acc[emp.id] = {
          available: emp.available,
          conflicts: emp.conflicts
        };
        return acc;
      }, {});

      console.log('Processed Availability Map:', availabilityData);
      console.log('Current Employees:', employees);
      
      setAvailabilityMap(availabilityData);
      
      // Log the final state after setting
      console.log('Filtered Available:', filteredEmployees.filter(emp => availabilityMap[emp.id]?.available));
      console.log('Filtered Unavailable:', filteredEmployees.filter(emp => !availabilityMap[emp.id]?.available));
      
      setLoading(false);
    } catch (err) {
      console.error('Error fetching event data:', err);
      setError('Failed to fetch event data. Please try again.');
      setLoading(false);
    }
  };

  const handleEmployeeToggle = (employeeId) => {
    setSelectedEmployees(prev => ({
      ...prev,
      [employeeId]: { 
        ...prev[employeeId],
        selected: !prev[employeeId]?.selected,
        rate: !prev[employeeId]?.selected 
          ? employees.find(e => e.id === employeeId)?.default_rate?.toString() || ''
          : prev[employeeId]?.rate || '',
        paymentType: 'hourly',
        percentage: ''
      }
    }));
  };

  const handlePaymentTypeChange = (employeeId, paymentType) => {
    setSelectedEmployees(prev => ({
      ...prev,
      [employeeId]: { 
        ...prev[employeeId],
        paymentType,
        rate: paymentType === 'hourly' 
          ? employees.find(e => e.id === employeeId)?.default_rate?.toString() || ''
          : prev[employeeId]?.rate || '',
        percentage: paymentType === 'percentage' ? prev[employeeId]?.percentage || '' : ''
      }
    }));
  };

  const handleRateChange = (employeeId, value) => {
    setSelectedEmployees(prev => ({
      ...prev,
      [employeeId]: { 
        ...prev[employeeId],
        rate: prev[employeeId]?.paymentType !== 'percentage' ? value : prev[employeeId]?.rate,
        percentage: prev[employeeId]?.paymentType === 'percentage' ? value : prev[employeeId]?.percentage
      }
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const employeesToAssign = Object.entries(selectedEmployees)
      .filter(([_, value]) => value.selected)
      .map(([id, value]) => ({
        id: parseInt(id),
        payment_type: value.paymentType,
        rate: value.paymentType === 'hourly' || value.paymentType === 'fixed' ? parseFloat(value.rate) || null : null,
        percentage: value.paymentType === 'percentage' ? parseFloat(value.percentage) || null : null
      }));

    console.log('Employees to assign:', employeesToAssign);

    if (employeesToAssign.length === 0) {
      setError('Please select at least one employee to assign.');
      return;
    }

    try {
      setLoading(true);
      
      // Assign employees to the event
      const eventResult = await assignEmployeesToEvent(selectedEventId, employeesToAssign);
      console.log('Event assignment result:', eventResult);

      // Assign employees to the project
      const projectResult = await assignEmployeesToProject(projectId, employeesToAssign);
      console.log('Project assignment result:', projectResult);

      if (eventResult.success && projectResult.success) {
        // After successful assignment, fetch updated assignments
        const updatedAssignments = await getProjectEvents(projectId);
        onAssignmentUpdate(updatedAssignments);
        
        // Update the local state to reflect the new assignments
        setEvents(updatedAssignments);
        
        // Update the selectedEmployees state to reflect the new assignments
        const updatedSelectedEmployees = { ...selectedEmployees };
        employeesToAssign.forEach(emp => {
          updatedSelectedEmployees[emp.id] = {
            ...updatedSelectedEmployees[emp.id],
            selected: true,
            paymentType: emp.payment_type,
            rate: emp.rate || '',
            percentage: emp.percentage || ''
          };
        });
        setSelectedEmployees(updatedSelectedEmployees);

        // Fetch the updated event employees
        const updatedEventEmployees = await getEventEmployees(selectedEventId);
        console.log('Updated Event Employees:', updatedEventEmployees);

        setError(null);
      } else {
        throw new Error('Failed to assign employees to event or project');
      }
    } catch (err) {
      console.error('Error assigning employees:', err);
      setError(`Failed to assign employees: ${err.message}`);
    } finally {
      setLoading(false);
    }
  };

  const filteredEmployees = Array.isArray(employees) 
    ? employees.filter(emp => 
        (emp.name || emp.first_name + ' ' + emp.last_name)
          .toLowerCase()
          .includes(searchTerm.toLowerCase())
      )
    : [];

  if (loading) return <CircularProgress />;
  if (error) return <Typography color="error">{error}</Typography>;

  return (
    <Box className={styles.employeeAssignment}>
      <FormControl fullWidth variant="outlined" className={styles.eventSelect}>
        <InputLabel id="event-select-label">Select Event</InputLabel>
        <Select
          labelId="event-select-label"
          value={selectedEventId}
          onChange={handleEventChange}
          label="Select Event"
        >
          {events.map((event) => (
            <MenuItem 
              key={event.id} 
              value={event.id}
              disabled={!event.time_slots || event.time_slots.length === 0}
            >
              {event.title} 
              {(!event.time_slots || event.time_slots.length === 0) && 
                " (No time slots)"
              }
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <Box className={styles.searchContainer}>
        <SearchIcon className={styles.searchIcon} />
        <input
          type="text"
          placeholder="Search employees..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className={styles.searchInput}
        />
      </Box>

      <List className={styles.employeeList}>
        <Typography variant="subtitle1" sx={{ padding: '8px 16px', borderBottom: '1px solid #eee' }}>
          Available Employees ({filteredEmployees.filter(emp => availabilityMap[emp.id]?.available).length})
        </Typography>
        {filteredEmployees
          .filter(emp => {
            const isAvailable = availabilityMap[emp.id]?.available;
            console.log(`Employee ${emp.id} availability:`, isAvailable);
            return isAvailable;
          })
          .map((employee) => (
            <ListItem 
              key={employee.id} 
              className={styles.employeeItem}
              onClick={() => handleEmployeeToggle(employee.id)}
            >
              <div className={styles.employeeContent} style={{ 
                display: 'flex', 
                alignItems: 'center',
                width: '100%'
              }}>
                <FormControlLabel
                  className={styles.employeeCheckbox}
                  style={{ width: '400px' }}
                  control={
                    <Checkbox
                      checked={selectedEmployees[employee.id]?.selected || false}
                      tabIndex={-1}
                      disableRipple
                    />
                  }
                  label={
                    <div className={styles.employeeInfo}>
                      <Avatar src={employee.avatar} alt={employee.name} />
                      <div className={styles.employeeDetails}>
                        <Typography variant="subtitle2">{employee.name}</Typography>
                        <Typography variant="body2" color="textSecondary">
                          {employee.position}
                          {employee.assignedToProject && ""}
                        </Typography>
                      </div>
                    </div>
                  }
                />
                <div style={{ 
                  display: 'flex', 
                  gap: '8px',
                  marginLeft: 'auto',
                  width: '300px',
                  justifyContent: 'flex-end'
                }}>
                  <FormControl size="small">
                    <Select
                      value={selectedEmployees[employee.id]?.paymentType || 'hourly'}
                      onChange={(e) => handlePaymentTypeChange(employee.id, e.target.value)}
                      disabled={!selectedEmployees[employee.id]?.selected}
                      input={<OutlinedInput />}
                      sx={{ 
                        width: '140px',
                        height: '40px',
                        '& .MuiOutlinedInput-input': {
                          padding: '8px 14px'
                        }
                      }}
                    >
                      <MenuItem value="hourly">Hourly</MenuItem>
                      <MenuItem value="fixed">Fixed</MenuItem>
                      <MenuItem value="percentage">Percentage</MenuItem>
                    </Select>
                  </FormControl>
                  <TextField
                    type="number"
                    size="small"
                    value={selectedEmployees[employee.id]?.paymentType === 'percentage' 
                      ? selectedEmployees[employee.id]?.percentage || ''
                      : selectedEmployees[employee.id]?.rate || ''}
                    onChange={(e) => handleRateChange(employee.id, e.target.value)}
                    disabled={!selectedEmployees[employee.id]?.selected}
                    sx={{ 
                      width: '140px',
                      height: '40px',
                      '& .MuiOutlinedInput-root': {
                        height: '40px'
                      }
                    }}
                    InputProps={{
                      startAdornment: selectedEmployees[employee.id]?.paymentType !== 'percentage' ? 
                        <InputAdornment position="start">$</InputAdornment> : null,
                      endAdornment: selectedEmployees[employee.id]?.paymentType === 'percentage' ? 
                        <InputAdornment position="end">%</InputAdornment> : null
                    }}
                  />
                </div>
              </div>
            </ListItem>
          ))}

        <Typography 
          variant="subtitle1" 
          sx={{ 
            padding: '8px 16px', 
            borderBottom: '1px solid #eee',
            marginTop: '16px',
            color: '#d32f2f' 
          }}
        >
          Unavailable Employees
        </Typography>
        {filteredEmployees
          .filter(emp => !availabilityMap[emp.id]?.available)
          .map((employee) => (
            <ListItem 
              key={employee.id} 
              className={`${styles.employeeItem} ${styles.unavailable}`}
              sx={{ opacity: 0.7 }}
            >
              <div className={styles.employeeContent}>
                <FormControlLabel
                  className={styles.employeeCheckbox}
                  style={{ width: '400px' }}
                  control={
                    <Checkbox
                      checked={selectedEmployees[employee.id]?.selected || false}
                      tabIndex={-1}
                      disableRipple
                    />
                  }
                  label={
                    <div className={styles.employeeInfo}>
                      <Avatar src={employee.avatar} alt={employee.name} />
                      <div className={styles.employeeDetails}>
                        <Typography variant="subtitle2">{employee.name}</Typography>
                        <Typography variant="body2" color="textSecondary">
                          {employee.position}
                          {employee.assignedToProject && ""}
                        </Typography>
                      </div>
                    </div>
                  }
                />
                <div style={{ 
                  display: 'flex', 
                  gap: '8px',
                  marginLeft: 'auto',
                  width: '300px',
                  justifyContent: 'flex-end'
                }}>
                  <FormControl size="small">
                    <Select
                      value={selectedEmployees[employee.id]?.paymentType || 'hourly'}
                      onChange={(e) => handlePaymentTypeChange(employee.id, e.target.value)}
                      disabled={!selectedEmployees[employee.id]?.selected}
                      input={<OutlinedInput />}
                      sx={{ 
                        width: '140px',
                        height: '40px',
                        '& .MuiOutlinedInput-input': {
                          padding: '8px 14px'
                        }
                      }}
                    >
                      <MenuItem value="hourly">Hourly</MenuItem>
                      <MenuItem value="fixed">Fixed</MenuItem>
                      <MenuItem value="percentage">Percentage</MenuItem>
                    </Select>
                  </FormControl>
                  <TextField
                    type="number"
                    size="small"
                    value={selectedEmployees[employee.id]?.paymentType === 'percentage' 
                      ? selectedEmployees[employee.id]?.percentage || ''
                      : selectedEmployees[employee.id]?.rate || ''}
                    onChange={(e) => handleRateChange(employee.id, e.target.value)}
                    disabled={!selectedEmployees[employee.id]?.selected}
                    sx={{ 
                      width: '140px',
                      height: '40px',
                      '& .MuiOutlinedInput-root': {
                        height: '40px'
                      }
                    }}
                    InputProps={{
                      startAdornment: selectedEmployees[employee.id]?.paymentType !== 'percentage' ? 
                        <InputAdornment position="start">$</InputAdornment> : null,
                      endAdornment: selectedEmployees[employee.id]?.paymentType === 'percentage' ? 
                        <InputAdornment position="end">%</InputAdornment> : null
                    }}
                  />
                </div>
                {availabilityMap[employee.id]?.conflicts && (
                  <Chip
                    label="Unavailable"
                    sx={{
                      backgroundColor: AVAILABILITY_COLORS.unavailable.bg,
                      color: AVAILABILITY_COLORS.unavailable.color,
                      fontWeight: 'medium',
                      marginLeft: 'auto',
                      '& .MuiChip-label': {
                        padding: '4px 8px',
                      }
                    }}
                  />
                )}
              </div>
            </ListItem>
          ))}
      </List>

      <Button 
        onClick={handleSubmit}
        variant="contained" 
        sx={{
          fontFamily: 'Urbanist, sans-serif',
          textTransform: 'none',
          fontSize: '0.95rem',
          padding: '8px 24px',
          backgroundColor: '#1976d2',
          color: 'white',
          borderRadius: '8px',
          '&:hover': {
            backgroundColor: '#1565c0'
          }
        }}
        className={styles.submitButton}
        fullWidth
      >
        Assign Employees
      </Button>
    </Box>
  );
};

export default EventEmployeeAssignment;

