import React from 'react';
import { Typography, Paper } from '@mui/material';
import styles from './CustomTooltip.module.scss';

const CustomTooltip = ({ active, payload, label }) => {
  if (!active || !payload?.length) return null;
  
  return (
    <Paper className={styles.tooltip}>
      <Typography variant="subtitle2">
        {new Date(label).toLocaleDateString('en-US', {
          month: 'short',
          day: 'numeric',
          year: 'numeric'
        })}
      </Typography>
      <Typography variant="body2" color="primary">
        ${payload[0].value.toLocaleString()}
      </Typography>
    </Paper>
  );
};

export default CustomTooltip; 