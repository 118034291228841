import React from 'react';
import { Paper, Typography } from '@mui/material';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from 'recharts';

const monthlyData = [
  { name: 'Jan', revenue: 4000, expenses: 2400 },
  { name: 'Feb', revenue: 3000, expenses: 1398 },
  { name: 'Mar', revenue: 2000, expenses: 9800 },
  { name: 'Apr', revenue: 2780, expenses: 3908 },
  { name: 'May', revenue: 1890, expenses: 4800 },
  { name: 'Jun', revenue: 2390, expenses: 3800 },
];

const RevenueChart = () => (
  <Paper 
    elevation={0} 
    sx={{ 
      p: 3, 
      height: '100%',
      border: '1px solid #e0e0e0',
      borderRadius: 1
    }}
  >
    <Typography variant="h6" gutterBottom sx={{ color: '#34495e' }}>
      Revenue vs Expenses
    </Typography>
    <ResponsiveContainer width="100%" height={400}>
      <LineChart data={monthlyData}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Line 
          type="monotone" 
          dataKey="revenue" 
          stroke="#2196f3" 
          strokeWidth={2}
        />
        <Line 
          type="monotone" 
          dataKey="expenses" 
          stroke="#f44336" 
          strokeWidth={2}
        />
      </LineChart>
    </ResponsiveContainer>
  </Paper>
);

export default RevenueChart; 