import React, { useState } from 'react';
import { 
  Dialog, 
  DialogTitle, 
  DialogContent,
  IconButton,
  Box,
  ToggleButtonGroup,
  ToggleButton,
  Tooltip
} from '@mui/material';
import {
  Close as CloseIcon,
  Smartphone as MobileIcon,
  Tablet as TabletIcon,
  Laptop as DesktopIcon
} from '@mui/icons-material';
import SectionRenderer from './SectionRenderer';

const PreviewModal = ({ open, onClose, sections, settings }) => {
  const [deviceView, setDeviceView] = useState('desktop');

  const getPreviewWidth = () => {
    switch(deviceView) {
      case 'mobile': return '375px';
      case 'tablet': return '768px';
      case 'desktop': return '100%';
      default: return '100%';
    }
  };

  return (
    <Dialog 
      fullScreen 
      open={open} 
      onClose={onClose}
    >
      <DialogTitle sx={{ 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center',
        borderBottom: 1,
        borderColor: 'divider'
      }}>
        <ToggleButtonGroup
          value={deviceView}
          exclusive
          onChange={(e, value) => value && setDeviceView(value)}
          size="small"
        >
          <ToggleButton value="mobile">
            <Tooltip title="Mobile View">
              <MobileIcon />
            </Tooltip>
          </ToggleButton>
          <ToggleButton value="tablet">
            <Tooltip title="Tablet View">
              <TabletIcon />
            </Tooltip>
          </ToggleButton>
          <ToggleButton value="desktop">
            <Tooltip title="Desktop View">
              <DesktopIcon />
            </Tooltip>
          </ToggleButton>
        </ToggleButtonGroup>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ bgcolor: 'grey.100', p: 0 }}>
        <Box sx={{
          width: getPreviewWidth(),
          margin: '0 auto',
          minHeight: '100%',
          bgcolor: 'background.paper',
          transition: 'width 0.3s ease',
          boxShadow: deviceView !== 'desktop' ? 3 : 0,
          overflow: 'auto'
        }}>
          {sections?.map((section, index) => (
            <SectionRenderer
              key={section.id || index}
              section={section}
              isPreview={true}
            />
          ))}
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default PreviewModal;