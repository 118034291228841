import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import { useAuth, AuthProvider } from './components/AuthContext';
import Layout from './components/Layout';
import Login from './components/Login';
import AdminDashboard from './components/AdminDashboard';
import PrivateRoute from './components/PrivateRoute';
import Projects from './components/Projects';
import ProjectDetails from './components/ProjectDetails';
import MaterialCostDetails from './components/MaterialCostDetails';
import ProjectPhotos from './components/ProjectPhotos';
import Camera from './components/Camera';
import ScheduleUpdater from './components/ScheduleUpdater';
import EventDetailsPage from './components/EventDetailsPage';
import Events from './components/Events';
import CreateProjectPage from './components/CreateProjectPage';
import CreateEventForm from './components/CreateEventForm';
import Employees from './components/Employees';
import CreateEmployee from './components/CreateEmployee';
import EventEmployeeAssignment from './components/EventEmployeeAssignment';
import EventAssignmentsList from './components/EventAssignmentsList';
import Navbar from './components/Navbar';
import Calendar from './components/Calendar';
import Payroll from './components/Payroll';
import Timesheets from './components/Timesheets';
import EmployeeLayout from './components/EmployeeLayout';
import EmployeeDashboard from './components/EmployeeDashboard';
import EmployeeSchedule from './components/EmployeeSchedule';
import EmployeeTimesheets from './components/EmployeeTimesheets';
import EmployeeProfile from './components/EmployeeProfile';
import EmployeeProjects from './components/EmployeeProjects';
import ClientDashboard from './components/ClientDashboard';
import ClientProjects from './components/ClientProjects';
import ClientProjectDetails from './components/ClientProjectDetails';
import Financials from './components/Financials';
import ClientLayout from './components/ClientLayout';
import Clients from './components/Clients';
import CreateClient from './components/CreateClient';
import EmployeeAvailability from './components/EmployeeAvailability';
import ClientDetails from './components/ClientDetails';
import ShareProject from './components/ShareProject';
import EmployeeEventDetails from './components/EmployeeEventDetails';
import ClientDocuments from './components/ClientDocuments';
import ClientPayments from './components/ClientPayments';
import ClientProfile from './components/ClientProfile';
import ClientEventDetails from './components/ClientEventDetails';
import ClientSettings from './components/ClientSettings';
import { ThemeProvider } from './ThemeContext';
import EmployeeDetails from './components/EmployeeDetails';
import AdminEstimates from './components/AdminEstimates';
import AdminCreateEstimate from './components/AdminCreateEstimate';
import AdminSettings from './components/AdminSettings';
import AdminInvoices from './components/AdminInvoices';
import AdminCreateInvoice from './components/AdminCreateInvoice';
import AdminInvoiceDetails from './components/AdminInvoiceDetails';
import AdminEstimateDetails from './components/AdminEstimateDetails';
import PublicEstimateView from './components/PublicEstimateView';
import EstimateBooking from './components/EstimateBooking';
import AdminCreateEstimateNoTabs from './components/AdminCreateEstimateNoTabs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import AdminLeads from './components/AdminLeads';
import AdminLeadDetails from './components/AdminLeadDetails';
import AdminCreateLead from './components/AdminCreateLead';
import WebsiteCreate from './components/leads/website/WebsiteCreate';
import WebsiteOverview from './components/leads/website/WebsiteOverview';
import PublicInvoiceView from './components/PublicInvoiceView';
import PublicEmployeeRegistration from './components/PublicEmployeeRegistration';
import TimesheetDetails from './components/TimesheetDetails';
import NewRequest from './components/NewRequest';
import AdminEditEstimate from './components/AdminEditEstimate';
import AdminEditInvoice from './components/AdminEditInvoice';
import TopBar from './components/TopBar';
import PriceBook from './components/PriceBook';
import AdminDocuments from './components/AdminDocuments';

function App() {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <ThemeProvider>
        <Router>
          <AppRoutes />
        </Router>
      </ThemeProvider>
    </LocalizationProvider>
  );
}

function AppRoutes() {
  const { user, isAuthenticated } = useAuth();

  if (!isAuthenticated && localStorage.getItem('token')) {
    return <div>Loading...</div>;
  }

  return (
    <Routes>
      <Route path="/login" element={
        isAuthenticated ? <Navigate to={`/${user.role}`} replace /> : <Login />
      } />
      <Route path="/public/estimate/:uuid" element={<PublicEstimateView />} />
      <Route path="/estimate/book/:uuid" element={<EstimateBooking />} />
      <Route path="/public/invoice/:uuid" element={<PublicInvoiceView />} />
      <Route path="/public/employee-registration/:token" element={<PublicEmployeeRegistration />} />
      
      {/* Admin routes */}
      <Route path="/admin/*" element={
        <PrivateRoute allowedRoles={['admin']}>
          <Layout>
            <Routes>
              <Route index element={<AdminDashboard />} />
              <Route path="navbar" element={<Navbar />} />
              <Route path="dashboard" element={<AdminDashboard />} />
              <Route path="projects" element={<Projects />} />
              <Route path="projects/:id" element={<ProjectDetails />} />
              <Route path="projects/:id/share" element={<ShareProject />} />
              <Route path="projects/:projectId/material-costs/:costId" element={<MaterialCostDetails />} />
              <Route path="projects/:projectId/photos" element={<ProjectPhotos />} />
              <Route path="projects/:projectId/camera" element={<Camera />} />
              <Route path="projects/:projectId/schedule" element={<ScheduleUpdater />} />
              <Route path="event/:eventId" element={<EventDetailsPage />} />
              <Route path="projects/:projectId/events" element={<Events />} />
              <Route path="projects/create" element={<CreateProjectPage />} />
              <Route path="projects/:projectId/create-event" element={<CreateEventForm />} />
              <Route path="employees" element={<Employees />} />
              <Route path="employees/:id" element={<EmployeeDetails />} />
              <Route path="create-employee" element={<CreateEmployee />} />
              <Route path="event-assignments/:eventId" element={<EventAssignmentsList />} />
              <Route path="event-assignments/:eventId/assign-employees" element={<EventEmployeeAssignment />} />
              <Route path="calendar" element={<Calendar />} />
              <Route path="payroll" element={<Payroll />} />
              <Route path="timesheets">
                <Route index element={<Timesheets />} />
                <Route path=":id" element={<TimesheetDetails />} />
              </Route>
              <Route path="clients" element={<Clients />} />
              <Route path="create-client" element={<CreateClient />} />
              <Route path="clients/:id" element={<ClientDetails />} />
              <Route path="projects/:projectId/financials" element={<Financials />} />
              <Route path="/admin/employees" element={<Employees />} />
              <Route path="/admin/payroll" element={<Payroll />} />
              <Route path="estimates" element={<AdminEstimates />} />
              <Route path="/admin/estimates" element={<AdminEstimates />} />
              <Route path="/estimates/create" element={<AdminCreateEstimateNoTabs />} />
              <Route path="settings" element={<AdminSettings />} />
              <Route path="invoices" element={<AdminInvoices />} />
              <Route path="invoices/create" element={<AdminCreateInvoice />} />
              <Route path="invoices/:id" element={<AdminInvoiceDetails />} />
              <Route path="estimates/:id" element={<AdminEstimateDetails />} />
              <Route path="leads" element={<AdminLeads />} />
              <Route path="leads/:id" element={<AdminLeadDetails />} />
              <Route path="leads/create" element={<AdminCreateLead />} />
              <Route path="leads/website/create" element={<WebsiteCreate />} />
              <Route path="leads/website/overview" element={<WebsiteOverview />} />
              <Route path="website" element={<WebsiteOverview />} />
              <Route path="website/create" element={<WebsiteCreate />} />
              <Route path="/admin/timesheets" element={<Timesheets />} />
              <Route path="/admin/timesheets/:id" element={<TimesheetDetails />} />
              <Route path="estimates/edit/:id" element={<AdminEditEstimate />} />
              <Route path="invoices/edit/:id" element={<AdminEditInvoice />} />
              <Route path="price-book" element={<PriceBook />} />
              <Route path="documents" element={<AdminDocuments />} />
            </Routes>
          </Layout>
        </PrivateRoute>
      } />
      
      {/* Employee routes */}
      <Route 
        path="/employee/*" 
        element={
          <PrivateRoute allowedRoles={['employee']}>
            <EmployeeLayout />
          </PrivateRoute>
        }
      >
        <Route index element={<EmployeeDashboard />} />
        <Route path="schedule" element={<EmployeeSchedule />} />
        <Route path="timesheets" element={<EmployeeTimesheets />} />
        <Route path="profile" element={<EmployeeProfile />} />
        <Route path="projects" element={<EmployeeProjects />} />
        <Route path="availability" element={<EmployeeAvailability />} />
        <Route path="events/:eventId" element={<EmployeeEventDetails />} />
        <Route path="requests/new" element={<NewRequest />} />
      </Route>
      
      {/* Client routes */}
      <Route path="/client" element={<ClientLayout />}>
        <Route index element={<ClientDashboard />} />
        <Route path="projects" element={<ClientProjects />} />
        <Route path="projects/:projectId" element={<ClientProjectDetails />} />
        <Route path="documents" element={<ClientDocuments />} />
        <Route path="payments" element={<ClientPayments />} />
        <Route path="profile" element={<ClientProfile />} />
        <Route path="events/:eventId" element={<ClientEventDetails />} />
        <Route path="settings" element={<ClientSettings />} />
      </Route>
      
      {/* Redirect to login if accessing root */}
      <Route path="/" element={<Navigate to={user ? (user.role === 'admin' ? '/admin' : user.role === 'employee' ? '/employee' : '/client') : '/login'} />} />
      
      {/* Catch-all route for undefined paths */}
      <Route path="*" element={<Navigate to={user ? (user.role === 'admin' ? '/admin' : user.role === 'employee' ? '/employee' : '/client') : '/login'} />} />
    </Routes>
  );
}

export default App;
