import React from 'react';
import {
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  LinearProgress,
} from '@mui/material';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';

const StatCard = ({ title, value, subtitle, progress }) => (
  <Card sx={{ height: '100%', borderRadius: 2 }}>
    <CardContent>
      <Typography color="text.secondary" variant="overline">
        {title}
      </Typography>
      <Typography variant="h4" sx={{ my: 1, fontWeight: 600 }}>
        {value}
      </Typography>
      {progress && (
        <Box sx={{ mt: 1 }}>
          <LinearProgress 
            variant="determinate" 
            value={progress} 
            sx={{ 
              height: 6, 
              borderRadius: 3,
              backgroundColor: 'grey.100',
              '& .MuiLinearProgress-bar': {
                borderRadius: 3,
                backgroundImage: 'linear-gradient(45deg, #2196f3 30%, #21cbf3 90%)',
              }
            }}
          />
        </Box>
      )}
      <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
        {subtitle}
      </Typography>
    </CardContent>
  </Card>
);

const CampaignAnalytics = ({ campaignId }) => {
  // Mock data - replace with real data from your API
  const performanceData = [
    { day: 'Mon', opens: 120, clicks: 45, responses: 22 },
    { day: 'Tue', opens: 132, clicks: 62, responses: 31 },
    { day: 'Wed', opens: 145, clicks: 55, responses: 28 },
    { day: 'Thu', opens: 132, clicks: 47, responses: 25 },
    { day: 'Fri', opens: 128, clicks: 51, responses: 27 },
  ];

  return (
    <Box sx={{ p: 3 }}>
      <Grid container spacing={3}>
        {/* Stats Cards */}
        <Grid item xs={12} md={3}>
          <StatCard
            title="OPEN RATE"
            value="68%"
            subtitle="12% higher than average"
            progress={68}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <StatCard
            title="CLICK RATE"
            value="42%"
            subtitle="5% higher than average"
            progress={42}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <StatCard
            title="RESPONSE RATE"
            value="28%"
            subtitle="8% higher than average"
            progress={28}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <StatCard
            title="CONVERSION"
            value="18%"
            subtitle="3% higher than average"
            progress={18}
          />
        </Grid>

        {/* Performance Chart */}
        <Grid item xs={12}>
          <Card sx={{ p: 3, borderRadius: 2 }}>
            <Typography variant="h6" sx={{ mb: 3 }}>
              Campaign Performance
            </Typography>
            <Box sx={{ height: 400 }}>
              <ResponsiveContainer width="100%" height="100%">
                <BarChart data={performanceData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="day" />
                  <YAxis />
                  <Tooltip />
                  <Bar dataKey="opens" fill="#2196f3" name="Opens" />
                  <Bar dataKey="clicks" fill="#4caf50" name="Clicks" />
                  <Bar dataKey="responses" fill="#ff9800" name="Responses" />
                </BarChart>
              </ResponsiveContainer>
            </Box>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CampaignAnalytics; 