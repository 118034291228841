import React, { useState, useEffect } from 'react';
import {
  Box,
  Autocomplete,
  TextField,
  Chip,
  IconButton,
  Typography,
  styled,
  Collapse
} from '@mui/material';
import LabelIcon from '@mui/icons-material/Label';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import { getEntityTags, updateEntityTags, createTag, getAllTags } from '../services/api';

const StyledChip = styled(Chip)(({ theme }) => ({
  borderRadius: 16,
  backgroundColor: 'var(--background-paper)',
  color: 'var(--text-primary)',
  border: '1px solid var(--divider)',
  transition: 'all 0.2s ease-in-out',
  '&:hover': {
    transform: 'translateY(-1px)',
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
    backgroundColor: 'var(--hover-overlay)',
    color: 'var(--primary-main)',
  },
  '& .MuiChip-deleteIcon': {
    color: 'var(--text-secondary)',
    '&:hover': {
      color: 'var(--primary-main)',
    },
  },
  margin: '4px',
  fontWeight: 500,
}));

const TagsSection = ({ entityType, entityId, onTagsUpdate }) => {
  const [tags, setTags] = useState([]);
  const [allTags, setAllTags] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [inputValue, setInputValue] = useState('');
  const [showInput, setShowInput] = useState(false);

  const fetchTags = async () => {
    try {
      const [entityTags, availableTags] = await Promise.all([
        getEntityTags(entityType, entityId),
        getAllTags()
      ]);
      setTags(entityTags);
      setAllTags(availableTags);
    } catch (err) {
      setError('Failed to load tags');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTags();
  }, [entityId, entityType]);

  const handleCreateTag = async (newTagName) => {
    try {
      const newTag = {
        name: newTagName,
      };
      const createdTag = await createTag(newTag);
      setAllTags(prev => [...prev, createdTag]);
      await handleAddTag(createdTag);
      setInputValue('');
    } catch (err) {
      setError('Failed to create tag');
    }
  };

  const handleAddTag = async (tag) => {
    try {
      await updateEntityTags(entityType, entityId, {
        add: [tag.id],
        replace_all: false
      });
      setTags(prev => [...prev, tag]);
      if (onTagsUpdate) onTagsUpdate();
    } catch (err) {
      setError('Failed to add tag');
    }
  };

  const handleRemoveTag = async (tagId) => {
    try {
      await updateEntityTags(entityType, entityId, {
        remove: [tagId],
        replace_all: false
      });
      setTags(prev => prev.filter(t => t.id !== tagId));
      if (onTagsUpdate) onTagsUpdate();
    } catch (err) {
      setError('Failed to remove tag');
    }
  };

  return (
    <Box sx={{ mb: 3 }}>
      <Box sx={{ 
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'space-between',
        mb: 2 
      }}>
        <Typography 
          variant="subtitle1" 
          sx={{ 
            fontWeight: 500,
            color: 'var(--text-primary)'
          }}
        >
          Tags
        </Typography>
        <IconButton 
          size="small" 
          onClick={() => setShowInput(!showInput)}
          sx={{ 
            color: showInput ? 'var(--error-main)' : 'var(--primary-main)',
            '&:hover': {
              backgroundColor: 'var(--hover-overlay)'
            }
          }}
        >
          {showInput ? <CloseIcon /> : <AddIcon />}
        </IconButton>
      </Box>

      <Collapse in={showInput}>
        <Box sx={{ 
          display: 'flex', 
          alignItems: 'center', 
          gap: 2,
          mb: 2,
          border: '1px solid var(--divider)',
          borderRadius: 1,
          px: 2,
          py: 0.5,
          width: '100%',
          backgroundColor: 'var(--background-paper)'
        }}>
          <LabelIcon sx={{ color: 'var(--text-secondary)' }} />
          <Autocomplete
            freeSolo
            fullWidth
            options={allTags.filter(tag => !tags.find(t => t.id === tag.id))}
            getOptionLabel={(option) => typeof option === 'string' ? option : option.name}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
              setInputValue(newInputValue);
            }}
            onChange={(event, newValue) => {
              if (!newValue) return;
              
              if (typeof newValue === 'string') {
                handleCreateTag(newValue);
                setShowInput(false);
              } else {
                handleAddTag(newValue);
                setShowInput(false);
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                placeholder="Add or create tag..."
                sx={{ 
                  width: '100%',
                  '& .MuiInput-underline:before': { borderBottom: 'none' },
                  '& .MuiInput-underline:hover:before': { borderBottom: 'none' },
                  '& .MuiInput-underline:after': { borderBottom: 'none' },
                  '& .MuiInputBase-input': {
                    color: 'var(--text-primary)'
                  },
                  '& .MuiAutocomplete-clearIndicator': {
                    color: 'var(--text-secondary)',
                    position: 'absolute',
                    right: 0
                  },
                  '& .MuiAutocomplete-popupIndicator': {
                    color: 'var(--text-secondary)'
                  },
                  '& .MuiAutocomplete-endAdornment': {
                    position: 'absolute',
                    right: 24
                  }
                }}
              />
            )}
            renderOption={(props, option) => (
              <li {...props}>
                <Box sx={{ 
                  display: 'flex', 
                  alignItems: 'center', 
                  gap: 1,
                  color: 'var(--text-primary)'
                }}>
                  <LabelIcon sx={{ color: 'var(--text-secondary)' }} />
                  {option.name}
                </Box>
              </li>
            )}
          />
        </Box>
      </Collapse>

      <Box sx={{ 
        display: 'flex',
        flexWrap: 'wrap',
        gap: 1
      }}>
        {tags.map((tag) => (
          <StyledChip
            key={tag.id}
            label={tag.name}
            onDelete={() => handleRemoveTag(tag.id)}
          />
        ))}
      </Box>
    </Box>
  );
};

export default TagsSection; 