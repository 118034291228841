import React from 'react';
import { Grid, Paper, Typography, Divider, Box } from '@mui/material';
import PeopleIcon from '@mui/icons-material/People';
import EmailIcon from '@mui/icons-material/Email';
import ContactsIcon from '@mui/icons-material/Contacts';
import HomeIcon from '@mui/icons-material/Home';
import RestoreIcon from '@mui/icons-material/Restore';
import ReportCard from '../ReportCard';
import { useTheme } from '../../../ThemeContext';

const ClientReports = ({ onNavigate }) => {
  const { darkMode } = useTheme();

  return (
    <Paper elevation={0} sx={{ p: 3, height: '100%' }}>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
        <PeopleIcon sx={{ mr: 1, color: darkMode ? '#fff' : '#34495e' }} />
        <Typography variant="h6" sx={{ color: darkMode ? '#fff' : '#34495e' }}>
          Client reports
        </Typography>
      </Box>
      <Divider sx={{ mb: 3 }} />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <ReportCard
            title="Client communications"
            description="All email messages sent through the system"
            onClick={() => onNavigate('client-communications')}
          />
        </Grid>
        <Grid item xs={12}>
          <ReportCard
            title="Client contact info"
            description="All clients and their contact information"
            onClick={() => onNavigate('client-contact')}
          />
        </Grid>
        <Grid item xs={12}>
          <ReportCard
            title="Property list"
            description="All properties and their details"
            onClick={() => onNavigate('property-list')}
          />
        </Grid>
        <Grid item xs={12}>
          <ReportCard
            title="Client re-engagement"
            description="Detailed list of clients that haven't had a closed job in the past 12 months"
            onClick={() => onNavigate('client-reengagement')}
          />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default ClientReports; 