import React, { useState } from 'react';
import {
  Box,
  Grid,
  Typography,
  Button,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Chip,
  TextField,
  InputAdornment,
  IconButton,
} from '@mui/material';
import {
  Add as AddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Mail as MailIcon,
  Phone as PhoneIcon,
  WhatsApp as WhatsAppIcon,
  Business as CompanyIcon,
  Label as TagIcon,
  Search as SearchIcon,
} from '@mui/icons-material';
import ContactCard from './ContactCard';
import ContactCreate from './ContactCreate';
import ContactNavigation from './ContactNavigation';
import SearchContacts from './SearchContacts';
import ImportContacts from './ImportContacts';


const ContactsOverview = () => {
  const [view, setView] = useState('all');
  const [menuAnchor, setMenuAnchor] = useState(null);
  const [selectedContact, setSelectedContact] = useState(null);
  const [showCreateDialog, setShowCreateDialog] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [showImportDialog, setShowImportDialog] = useState(false);

  // Mock data - in real app this would come from API
  const contacts = [
    {
      id: 1,
      name: 'John Doe',
      email: 'john.doe@example.com',
      phone: '+1 234 567 8900',
      company: 'Tech Corp',
      status: 'Active',
      lastContact: '2024-03-15',
      tags: ['VIP', 'Decision Maker'],
      source: 'Website'
    },
    // Add more mock contacts...
  ];

  // Get unique companies and tags
  const companies = [...new Set(contacts.map(contact => contact.company))];
  const allTags = [...new Set(contacts.flatMap(contact => contact.tags))];

  const filteredContacts = contacts.filter(contact => {
    const searchLower = searchQuery.toLowerCase();
    return (
      contact.name.toLowerCase().includes(searchLower) ||
      contact.email.toLowerCase().includes(searchLower) ||
      contact.company.toLowerCase().includes(searchLower) ||
      contact.tags.some(tag => tag.toLowerCase().includes(searchLower))
    );
  });

  const renderContent = () => {
    const contactsToRender = searchQuery ? filteredContacts : contacts;

    switch(view) {
      case 'companies':
        return (
          <>
            {companies.map(company => (
              <Box key={company} sx={{ mb: 4 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2, gap: 1 }}>
                  <CompanyIcon color="primary" />
                  <Typography variant="h6">{company}</Typography>
                  <Chip 
                    label={`${contacts.filter(c => c.company === company).length} contacts`}
                    size="small"
                    sx={{ ml: 1 }}
                  />
                </Box>
                <Grid container spacing={3}>
                  {contacts
                    .filter(contact => contact.company === company)
                    .map(contact => (
                      <Grid item xs={12} md={6} lg={4} key={contact.id}>
                        <ContactCard 
                          contact={contact}
                          onMenuClick={handleMenuClick}
                        />
                      </Grid>
                    ))}
                </Grid>
              </Box>
            ))}
          </>
        );

      case 'tags':
        return (
          <>
            {allTags.map(tag => (
              <Box key={tag} sx={{ mb: 4 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2, gap: 1 }}>
                  <TagIcon color="primary" />
                  <Typography variant="h6">{tag}</Typography>
                  <Chip 
                    label={`${contacts.filter(c => c.tags.includes(tag)).length} contacts`}
                    size="small"
                    sx={{ ml: 1 }}
                  />
                </Box>
                <Grid container spacing={3}>
                  {contacts
                    .filter(contact => contact.tags.includes(tag))
                    .map(contact => (
                      <Grid item xs={12} md={6} lg={4} key={contact.id}>
                        <ContactCard 
                          contact={contact}
                          onMenuClick={handleMenuClick}
                        />
                      </Grid>
                    ))}
                </Grid>
              </Box>
            ))}
          </>
        );

      default:
        return (
          <>
            <Grid container spacing={3}>
              {contactsToRender.map(contact => (
                <Grid item xs={12} md={6} lg={4} key={contact.id}>
                  <ContactCard 
                    contact={contact}
                    onMenuClick={handleMenuClick}
                  />
                </Grid>
              ))}
            </Grid>
          </>
        );
    }
  };

  const handleMenuClick = (event, contact) => {
    setMenuAnchor(event.currentTarget);
    setSelectedContact(contact);
  };

  const handleMenuClose = () => {
    setMenuAnchor(null);
    setSelectedContact(null);
  };

  return (
    <Box sx={{ p: 3 }}>
      <Box sx={{ 
        display: 'flex',
        flexDirection: 'column',
        gap: 3,
        mb: 4
      }}>
        <Box sx={{ 
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}>
          <Typography variant="h5" sx={{ fontWeight: 600 }}>
            {view === 'all' && 'All Contacts'}
            {view === 'companies' && 'Contacts by Company'}
            {view === 'tags' && 'Contacts by Tags'}
            {view === 'settings' && 'Contact Settings'}
          </Typography>
          <ContactNavigation
            currentView={view}
            onViewChange={setView}
            onCreateClick={() => setShowCreateDialog(true)}
            onImportClick={() => setShowImportDialog(true)}
          />
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
          <SearchContacts onSearch={setSearchQuery} />
        </Box>
      </Box>
      {renderContent()}

      <ContactCreate 
        open={showCreateDialog}
        onClose={() => setShowCreateDialog(false)}
      />

      <ImportContacts 
        open={showImportDialog}
        onClose={() => setShowImportDialog(false)}
      />

      <Menu
        anchorEl={menuAnchor}
        open={Boolean(menuAnchor)}
        onClose={handleMenuClose}
        PaperProps={{
          sx: { width: 200, borderRadius: 2 }
        }}
      >
        <MenuItem onClick={handleMenuClose}>
          <ListItemIcon>
            <EditIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Edit</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleMenuClose}>
          <ListItemIcon>
            <MailIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Email</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleMenuClose}>
          <ListItemIcon>
            <PhoneIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Call</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleMenuClose}>
          <ListItemIcon>
            <WhatsAppIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>WhatsApp</ListItemText>
        </MenuItem>
        <MenuItem 
          onClick={handleMenuClose}
          sx={{ color: 'error.main' }}
        >
          <ListItemIcon>
            <DeleteIcon fontSize="small" color="error" />
          </ListItemIcon>
          <ListItemText>Delete</ListItemText>
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default ContactsOverview; 