import React from 'react';
import { Box, TextField, FormControl, Select, MenuItem, InputAdornment } from '@mui/material';
import { Search as SearchIcon, FilterList as FilterListIcon } from '@mui/icons-material';
import { styled } from '@mui/material/styles';

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    borderRadius: 8,
    transition: 'all 0.2s ease',
    '&:hover': {
      backgroundColor: theme.palette.action.hover
    }
  }
}));

const LeadFilters = ({ 
  searchTerm, 
  setSearchTerm,
  sourceFilter,
  setSourceFilter,
  statusFilter,
  setStatusFilter 
}) => {
  const sources = ['All', 'Website', 'Referral', 'Direct', 'Social'];
  const statuses = ['All', 'New', 'In Progress', 'Qualified', 'Proposal', 'Won', 'Lost'];

  return (
    <Box sx={{ 
      display: 'flex', 
      gap: 2, 
      mb: 3,
      flexWrap: { xs: 'wrap', md: 'nowrap' }
    }}>
      <TextField
        placeholder="Search leads..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        sx={{ 
          flex: { xs: '1 1 100%', md: 1 },
          '& .MuiOutlinedInput-root': {
            borderRadius: 2
          }
        }}
      />
      <StyledFormControl sx={{ minWidth: { xs: '100%', md: 200 } }}>
        <Select
          value={sourceFilter}
          onChange={(e) => setSourceFilter(e.target.value)}
          displayEmpty
          startAdornment={<FilterListIcon sx={{ mr: 1, color: 'action.active' }} />}
        >
          {sources.map((source) => (
            <MenuItem key={source} value={source}>{source}</MenuItem>
          ))}
        </Select>
      </StyledFormControl>
      <StyledFormControl sx={{ minWidth: { xs: '100%', md: 200 } }}>
        <Select
          value={statusFilter}
          onChange={(e) => setStatusFilter(e.target.value)}
          displayEmpty
          startAdornment={<FilterListIcon sx={{ mr: 1, color: 'action.active' }} />}
        >
          {statuses.map((status) => (
            <MenuItem key={status} value={status}>{status}</MenuItem>
          ))}
        </Select>
      </StyledFormControl>
    </Box>
  );
};

export default LeadFilters;