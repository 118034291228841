import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, Box, Chip } from '@mui/material';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import { getClientEvents } from '../../services/api';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import styles from './ClientCalendar.module.scss';
import EventDetails from '../EventDetails';

const localizer = momentLocalizer(moment);

const getStatusColor = (status) => {
  switch (status) {
    case 'completed':
      return '#4CAF50';
    case 'in_progress':
      return '#2196F3';
    case 'cancelled':
      return '#F44336';
    default:
      return '#FF9800';
  }
};

const EventComponent = ({ event }) => (
  <Box sx={{
    backgroundColor: 'var(--background-paper)',
    position: 'relative',
    height: '100%',
    margin: '1px 0',
    overflow: 'hidden',
  }}>
    <Box sx={{
      backgroundColor: 'var(--background-paper)',
      borderRadius: '4px',
      borderLeft: `4px solid ${getStatusColor(event.status)}`,
      boxShadow: '0 1px 3px rgba(0,0,0,0.1)',
      padding: '4px 8px',
      display: 'flex',
      flexDirection: 'column',
      gap: '2px',
      height: '100%',
      width: '100%',
      transition: 'box-shadow 0.2s ease',
      '&:hover': {
        boxShadow: '0 4px 6px rgba(0,0,0,0.2)',
      }
    }}>
      <Typography sx={{ 
        fontSize: '12px', 
        fontWeight: 500, 
        color: 'var(--text-primary)', 
        overflow: 'hidden', 
        textOverflow: 'ellipsis', 
        whiteSpace: 'nowrap',
        lineHeight: 1.2
      }}>
        {event.title}
      </Typography>
      {event.project && (
        <Typography sx={{ 
          fontSize: '11px', 
          color: 'var(--text-secondary)', 
          overflow: 'hidden', 
          textOverflow: 'ellipsis', 
          whiteSpace: 'nowrap',
          lineHeight: 1.2
        }}>
          {event.project.name}
        </Typography>
      )}
    </Box>
  </Box>
);

const MoreEventsPopup = ({ events, onClose, date }) => (
  <Dialog 
    open={true} 
    onClose={onClose} 
    maxWidth="sm" 
    fullWidth
    PaperProps={{
      sx: {
        backgroundColor: 'var(--background-paper)',
        color: 'var(--text-primary)'
      }
    }}
  >
    <DialogTitle>
      <Typography variant="h6" sx={{ color: 'var(--text-primary)' }}>
        Events for {moment(date).format('MMMM D, YYYY')}
      </Typography>
    </DialogTitle>
    <DialogContent>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, pt: 2 }}>
        {events.map((event, index) => (
          <Box key={index} sx={{
            p: 2,
            borderRadius: '4px',
            borderLeft: `4px solid ${getStatusColor(event.status)}`,
            backgroundColor: 'var(--background-paper)',
            border: '1px solid var(--divider)',
            boxShadow: '0 1px 3px rgba(0,0,0,0.1)',
          }}>
            <Typography variant="subtitle1" sx={{ fontWeight: 500, color: 'var(--text-primary)' }}>
              {event.title}
            </Typography>
            {event.project && (
              <Typography variant="body2" sx={{ color: 'var(--text-secondary)' }}>
                {event.project.name}
              </Typography>
            )}
            <Typography variant="body2" sx={{ color: 'var(--text-secondary)', mt: 1 }}>
              {moment(event.start).format('h:mm A')} - {moment(event.end).format('h:mm A')}
            </Typography>
          </Box>
        ))}
      </Box>
    </DialogContent>
    <DialogActions>
      <Button 
        onClick={onClose}
        sx={{ 
          color: 'var(--text-primary)',
          '&:hover': {
            backgroundColor: 'var(--hover-overlay)'
          }
        }}
      >
        Close
      </Button>
    </DialogActions>
  </Dialog>
);

const ClientCalendar = ({ clientId }) => {
  const [events, setEvents] = useState([]);
  const [selectedEventId, setSelectedEventId] = useState(null);
  const [isEventDetailsOpen, setIsEventDetailsOpen] = useState(false);
  const [moreEventsDate, setMoreEventsDate] = useState(null);
  const [moreEvents, setMoreEvents] = useState([]);

  useEffect(() => {
    if (clientId) {
      fetchEvents();
    }
  }, [clientId]);

  const fetchEvents = async () => {
    try {
      const eventsData = await getClientEvents(clientId);
      setEvents(eventsData);
    } catch (error) {
      console.error('Error fetching events:', error);
    }
  };

  const handleSelectEvent = (event) => {
    setSelectedEventId(event.id);
    setIsEventDetailsOpen(true);
  };

  const handleCloseEventDetails = () => {
    setIsEventDetailsOpen(false);
    setSelectedEventId(null);
  };

  const handleEventUpdated = () => {
    fetchEvents();
  };

  const handleShowMore = (events, date) => {
    setMoreEvents(events);
    setMoreEventsDate(date);
  };

  const CustomToolbar = (toolbar) => {
    return (
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center',
        mb: 3
      }}>
        <Box sx={{ display: 'flex', gap: 1 }}>
          <Button 
            variant="outlined" 
            size="small"
            onClick={() => toolbar.onNavigate('TODAY')}
            sx={{ 
              textTransform: 'none',
              color: 'var(--text-primary)',
              borderColor: 'var(--divider)',
              '&:hover': {
                borderColor: 'var(--text-primary)',
                backgroundColor: 'var(--hover-overlay)'
              }
            }}
          >
            Today
          </Button>
          <Button
            variant="outlined"
            size="small"
            onClick={() => toolbar.onNavigate('PREV')}
            sx={{ 
              textTransform: 'none',
              color: 'var(--text-primary)',
              borderColor: 'var(--divider)',
              '&:hover': {
                borderColor: 'var(--text-primary)',
                backgroundColor: 'var(--hover-overlay)'
              }
            }}
          >
            Back
          </Button>
          <Button
            variant="outlined"
            size="small"
            onClick={() => toolbar.onNavigate('NEXT')}
            sx={{ 
              textTransform: 'none',
              color: 'var(--text-primary)',
              borderColor: 'var(--divider)',
              '&:hover': {
                borderColor: 'var(--text-primary)',
                backgroundColor: 'var(--hover-overlay)'
              }
            }}
          >
            Next
          </Button>
        </Box>
        <Typography sx={{ 
          color: 'var(--text-primary)',
          fontWeight: 500,
          fontSize: '0.875rem'
        }}>
          {toolbar.label}
        </Typography>
      </Box>
    );
  };

  return (
    <div className={styles.calendarContainer}>
      <Calendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        onSelectEvent={handleSelectEvent}
        selectable={false}
        components={{
          event: EventComponent,
          toolbar: CustomToolbar,
        }}
        formats={{
          eventTimeRangeFormat: () => null,
          monthHeaderFormat: 'MMMM YYYY',
          dayHeaderFormat: 'ddd',
          dayRangeHeaderFormat: ({ start, end }) =>
            `${moment(start).format('MMM D')} - ${moment(end).format('MMM D')}`,
        }}
        popup={false}
        views={['month']}
        defaultView="month"
        dayLayoutAlgorithm="no-overlap"
        style={{ flex: 1 }}
        sx={{
          '.rbc-calendar': {
            backgroundColor: 'var(--background-default)',
          },
          '.rbc-month-view': {
            backgroundColor: 'var(--background-default)',
            border: '1px solid var(--divider)',
          },
          '.rbc-header': {
            color: 'var(--text-primary)',
            borderBottom: '1px solid var(--divider)',
            padding: '8px',
            fontWeight: 500,
          },
          '.rbc-header + .rbc-header': {
            borderLeft: '1px solid var(--divider)',
          },
          '.rbc-day-bg + .rbc-day-bg': {
            borderLeft: '1px solid var(--divider)',
          },
          '.rbc-month-row + .rbc-month-row': {
            borderTop: '1px solid var(--divider)',
          },
          '.rbc-date-cell': {
            color: 'var(--text-primary)',
            padding: '4px',
          },
          '.rbc-off-range-bg': {
            backgroundColor: 'var(--background-paper)',
          },
          '.rbc-off-range': {
            color: 'var(--text-secondary)',
          },
          '.rbc-today': {
            backgroundColor: 'var(--primary-lighter)',
          },
          '.rbc-current': {
            color: 'var(--primary-main)',
            fontWeight: 600,
          },
          '.rbc-show-more': {
            color: 'var(--primary-main)',
            backgroundColor: 'transparent',
            '&:hover': {
              color: 'var(--primary-dark)',
              backgroundColor: 'var(--hover-overlay)',
            }
          },
          '.rbc-event': {
            position: 'relative',
            padding: '0',
            backgroundColor: 'transparent',
            border: 'none',
            margin: '1px 0',
          },
          '.rbc-event-content': {
            position: 'relative',
            height: '100%',
            width: '100%',
          },
          '.rbc-row-segment': {
            padding: '1px 2px',
          },
          '.rbc-row': {
            minHeight: '100%',
          },
          '.rbc-month-row': {
            overflow: 'visible',
            minHeight: 'auto',
          },
          '.rbc-overlay': {
            backgroundColor: 'white',
            border: '1px solid #e2e8f0',
            borderRadius: '4px',
            boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
          },
          '.rbc-show-more': {
            display: 'none',
          }
        }}
      />

      <EventDetails
        eventId={selectedEventId}
        open={isEventDetailsOpen}
        onClose={handleCloseEventDetails}
        onEventUpdated={handleEventUpdated}
      />

      {moreEventsDate && (
        <MoreEventsPopup
          events={moreEvents}
          date={moreEventsDate}
          onClose={() => setMoreEventsDate(null)}
        />
      )}
    </div>
  );
};

export default ClientCalendar; 