import React from 'react';
import { 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogActions, 
  Button, 
  Box, 
  Typography, 
  TextField,
  InputAdornment
} from '@mui/material';
import { styled } from '@mui/material/styles';

// Styled components to match your app's theme
const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    backgroundColor: '#fff',
    borderRadius: '8px',
    border: `1px solid ${theme.palette.divider}`,
    '&:hover': {
      borderColor: theme.palette.primary.main,
    },
    '&.Mui-focused': {
      borderColor: theme.palette.primary.main,
      boxShadow: `0 0 0 2px ${theme.palette.primary.main}15`,
    }
  },
  '& .MuiInputLabel-root': {
    color: theme.palette.text.secondary,
  },
  '& .MuiInputBase-input': {
    padding: '12px 14px',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none'
  }
}));

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    borderRadius: '12px',
    boxShadow: '0 8px 16px rgba(0,0,0,0.1)',
  }
}));

const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  borderBottom: `1px solid ${theme.palette.divider}`,
  padding: theme.spacing(2),
}));

const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  padding: theme.spacing(3),
}));

const StyledDialogActions = styled(DialogActions)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: `1px solid ${theme.palette.divider}`,
}));

const EventBox = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  padding: theme.spacing(3),
  backgroundColor: theme.palette.background.paper,
  borderRadius: '12px',
  border: `1px solid ${theme.palette.divider}`,
  boxShadow: '0 2px 4px rgba(0,0,0,0.05)',
}));

const EventHeader = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  padding: theme.spacing(2),
  backgroundColor: theme.palette.background.default,
  borderRadius: '8px'
}));

const EventTitle = styled(Typography)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  fontWeight: 500,
  marginBottom: theme.spacing(1),
  '& .icon': {
    width: '24px',
    display: 'flex',
    alignItems: 'center'
  }
}));

const EventDetails = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontSize: '0.875rem',
  marginBottom: theme.spacing(0.5),
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  '& .icon': {
    width: '24px',
    display: 'flex',
    alignItems: 'center'
  }
}));

const TimeLabel = styled(Typography)(({ theme }) => ({
  fontSize: '0.875rem',
  fontWeight: 500,
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(1),
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1)
}));

const ScheduleEventsModal = ({ 
  open, 
  onClose, 
  events = [], 
  onSubmit,
  title = "Schedule Events"
}) => {
  const [localTimeSlots, setLocalTimeSlots] = React.useState(
    events.map(() => ({ start_time: '', end_time: '' }))
  );

  React.useEffect(() => {
    setLocalTimeSlots(events.map(() => ({ start_time: '', end_time: '' })));
  }, [events]);

  const handleTimeSlotChange = (index, field, value) => {
    setLocalTimeSlots(prev => {
      const newSlots = [...prev];
      newSlots[index] = {
        ...newSlots[index],
        [field]: value
      };
      return newSlots;
    });
  };

  const hasOneScheduledEvent = localTimeSlots.some(slot => 
    slot.start_time !== '' && slot.end_time !== ''
  );

  const handleSubmit = () => {
    const filledTimeSlots = localTimeSlots.map((slot, index) => ({
      ...slot,
      event_id: events[index].id
    })).filter(slot => slot.start_time && slot.end_time);
    
    onSubmit(filledTimeSlots);
    onClose();
  };

  return (
    <StyledDialog 
      open={open} 
      onClose={onClose} 
      maxWidth="sm" 
      fullWidth
    >
      <StyledDialogTitle>
        <Typography variant="h6" component="div">
          {title}
        </Typography>
      </StyledDialogTitle>
      
      <StyledDialogContent>
        <Typography variant="subtitle1" color="textSecondary" sx={{ mb: 3 }}>
          Schedule at least one event. Others can remain unscheduled.
        </Typography>
        
        {events.map((event, index) => (
          <EventBox key={index}>
            <EventHeader>
              <EventTitle variant="subtitle1">
                <span className="icon">📝</span>
                <span>{event.title}</span>
                <Box component="span" sx={{ ml: 'auto', color: 'success.main' }}>
                  ${event.value.toFixed(2)}
                </Box>
              </EventTitle>
              
              <EventDetails>
                <span className="icon">🏢</span>
                <span>{event.project?.name || 'Unnamed Project'}</span>
              </EventDetails>
              
              <EventDetails>
                <span className="icon">📍</span>
                <span>
                  {event.addresses && event.addresses.length > 0 
                    ? event.addresses[0]
                    : 'No address provided'}
                </span>
              </EventDetails>
            </EventHeader>
            
            <Box sx={{ display: 'flex', gap: 2, flexDirection: 'column' }}>
              <Box>
                <TimeLabel>
                  <span>📅</span>
                  <span>Start Date & Time</span>
                </TimeLabel>
                <StyledTextField
                  type="datetime-local"
                  value={localTimeSlots[index]?.start_time || ''}
                  onChange={(e) => handleTimeSlotChange(index, 'start_time', e.target.value)}
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                />
              </Box>
              
              <Box>
                <TimeLabel>
                  <span>🕒</span>
                  <span>End Date & Time</span>
                </TimeLabel>
                <StyledTextField
                  type="datetime-local"
                  value={localTimeSlots[index]?.end_time || ''}
                  onChange={(e) => handleTimeSlotChange(index, 'end_time', e.target.value)}
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                />
              </Box>
            </Box>
          </EventBox>
        ))}
      </StyledDialogContent>
      
      <StyledDialogActions>
        <Button 
          onClick={onClose}
          variant="outlined"
          sx={{ 
            borderRadius: '8px',
            textTransform: 'none',
            px: 3
          }}
        >
          Cancel
        </Button>
        <Button 
          onClick={handleSubmit}
          variant="contained"
          disabled={!hasOneScheduledEvent}
          sx={{ 
            borderRadius: '8px',
            textTransform: 'none',
            px: 3
          }}
        >
          Schedule Events
        </Button>
      </StyledDialogActions>
    </StyledDialog>
  );
};

export default ScheduleEventsModal;
