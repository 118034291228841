import React from 'react';
import { ScatterChart, Scatter, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

const TimelineAnalysis = ({ estimates, height = 300 }) => {
  const prepareData = () => {
    return estimates
      .filter(est => est.issue_date && est.client_signed_at)
      .map(est => {
        const issueDate = new Date(est.issue_date);
        const signedDate = new Date(est.client_signed_at);
        const responseTime = Math.max(0, (signedDate - issueDate) / (1000 * 60 * 60 * 24));
        
        const items = Array.isArray(est.items) ? est.items : [];
        const value = items.reduce((sum, item) => {
          return sum + (Number(item.quantity) || 0) * (Number(item.unit_price) || 0);
        }, 0);

        return {
          responseTime: Number(responseTime.toFixed(1)),
          value: Number(value.toFixed(2))
        };
      });
  };

  const data = prepareData();

  return (
    <ResponsiveContainer width="100%" height={height}>
      <ScatterChart margin={{ top: 20, right: 20, bottom: 20, left: 20 }}>
        <CartesianGrid />
        <XAxis 
          type="number" 
          dataKey="responseTime" 
          name="Response Time" 
          unit=" days"
        />
        <YAxis 
          type="number" 
          dataKey="value" 
          name="Value" 
          tickFormatter={(value) => `$${value}`}
        />
        <Tooltip 
          cursor={{ strokeDasharray: '3 3' }}
          formatter={(value, name) => [
            name === 'responseTime' ? `${value} days` : `$${value}`,
            name === 'responseTime' ? 'Response Time' : 'Value'
          ]}
        />
        <Scatter name="Estimates" data={data} fill="#8884d8" />
      </ScatterChart>
    </ResponsiveContainer>
  );
};

export default TimelineAnalysis; 