import React from 'react';
import { ButtonGroup, Button } from '@mui/material';
import styles from './TimeRangeSelector.module.scss';

const TimeRangeSelector = ({ timeRange, setTimeRange }) => (
  <ButtonGroup size="small" className={styles.timeRangeButtons}>
    {['week', 'month', 'quarter', 'year', 'all'].map((range) => (
      <Button
        key={range}
        onClick={() => setTimeRange(range)}
        variant={timeRange === range ? 'contained' : 'outlined'}
        className={timeRange === range ? styles.activeButton : ''}
      >
        {range === 'all' ? 'All Time' : range.charAt(0).toUpperCase() + range.slice(1)}
      </Button>
    ))}
  </ButtonGroup>
);

export default TimeRangeSelector; 