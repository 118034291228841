import React from 'react';
import {
  Dialog,
  DialogContent,
  IconButton,
  Box,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import PriceBook from '../PriceBook';

const SavedItemsModal = ({
  open,
  onClose,
}) => {
  return (
    <Dialog 
      open={open} 
      onClose={onClose}
      maxWidth="xl"
      fullWidth
      PaperProps={{
        sx: {
          height: '90vh',
          maxWidth: '95vw',
          margin: '16px',
          backgroundColor: 'var(--background-default)',
          backgroundImage: 'none',
          '& .MuiDialogContent-root': {
            backgroundColor: 'var(--background-default)'
          }
        }
      }}
    >
      <Box sx={{ 
        position: 'relative',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'var(--background-default)'
      }}>
        <IconButton
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: '#666',
            zIndex: 1,
            '&:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.04)'
            }
          }}
        >
          <CloseIcon />
        </IconButton>
        
        <DialogContent sx={{ 
          p: 0, 
          flex: 1,
          overflow: 'hidden',
          backgroundColor: 'var(--background-default)'
        }}>
          <PriceBook />
        </DialogContent>
      </Box>
    </Dialog>
  );
};

export default SavedItemsModal; 