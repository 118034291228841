import React from 'react';
import { 
  Box, 
  Paper, 
  Typography, 
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Chip
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import AddIcon from '@mui/icons-material/Add';

const DeductionsBenefitsView = ({ onBack }) => {
  const data = [
    {
      name: 'Health Insurance',
      type: 'Benefit',
      amount: '250.00',
      frequency: 'Monthly',
      status: 'Active'
    },
    {
      name: '401(k)',
      type: 'Deduction',
      amount: '5%',
      frequency: 'Per Paycheck',
      status: 'Active'
    },
    // Add more sample data
  ];

  return (
    <Box>
      <Box sx={{ mb: 4, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <IconButton onClick={onBack} sx={{ mr: 2 }}>
            <ArrowBackIcon />
          </IconButton>
          <AccountBalanceWalletIcon sx={{ mr: 2, color: '#34495e' }} />
          <Typography variant="h5">Deductions & Benefits</Typography>
        </Box>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          sx={{ textTransform: 'none' }}
        >
          Add New
        </Button>
      </Box>

      <Paper elevation={0} sx={{ p: 3 }}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>Amount</TableCell>
                <TableCell>Frequency</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>{row.name}</TableCell>
                  <TableCell>
                    <Chip 
                      label={row.type} 
                      color={row.type === 'Benefit' ? 'success' : 'info'} 
                      size="small" 
                    />
                  </TableCell>
                  <TableCell>{row.amount}</TableCell>
                  <TableCell>{row.frequency}</TableCell>
                  <TableCell>
                    <Chip 
                      label={row.status} 
                      color="success" 
                      size="small" 
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
};

export default DeductionsBenefitsView; 