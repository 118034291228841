import React, { useState } from 'react';
import {
  Box,
  Button,
  Grid,
  Avatar,
  Card,
  CardContent,
  Rating,
} from '@mui/material';
import EditableElement from './EditableElement';
import SectionStyleControls from './SectionStyleControls';
import SectionControls from './SectionControls';

const SectionRenderer = ({ section, onUpdate, isPreview, dragHandleProps, onDelete, onDuplicate }) => {
  const [showControls, setShowControls] = useState(false);

  const handleUpdate = (field, value) => {
    if (isPreview) return;
    onUpdate({
      ...section,
      content: {
        ...section.content,
        [field]: value
      }
    });
  };

  const renderHeader = () => (
    <Box
      sx={{
        textAlign: 'center',
        py: 8,
        px: 2,
        backgroundColor: section.content.backgroundColor,
      }}
    >
      <EditableElement
        value={section.content.title}
        onChange={(value) => handleUpdate('title', value)}
        variant="h2"
        component="h2"
        sx={{
          color: section.content.textColor,
          fontWeight: 'bold',
          mb: 2,
        }}
      />
      <EditableElement
        value={section.content.subtitle}
        onChange={(value) => handleUpdate('subtitle', value)}
        variant="h5"
        component="h5"
        sx={{
          color: section.content.textColor,
          mb: 4,
          opacity: 0.9,
        }}
      />
      {section.content.buttonText && (
        <EditableElement
          value={section.content.buttonText}
          onChange={(value) => handleUpdate('buttonText', value)}
          variant="button"
          component="div"
          sx={{
            display: 'inline-block',
            px: 4,
            py: 1.5,
            borderRadius: 2,
            backgroundColor: 'primary.main',
            color: 'white',
            cursor: 'pointer',
          }}
        />
      )}
    </Box>
  );

  const renderFeatures = () => (
    <Box sx={{ py: 8, px: 2 }}>
      <Grid container spacing={4}>
        {section.content.features?.map((feature, index) => (
          <Grid item xs={12} md={4} key={index}>
            <Box sx={{ textAlign: 'center' }}>
              {feature.icon}
              <EditableElement
                value={feature.title}
                onChange={(value) => handleUpdate('title', value)}
                variant="h6"
                component="h6"
                sx={{ my: 2 }}
              />
              <EditableElement
                value={feature.description}
                onChange={(value) => handleUpdate('description', value)}
                variant="body1"
                component="p"
              />
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );

  const renderTeam = () => (
    <Box sx={{ py: 8, px: 2 }}>
      <Grid container spacing={4}>
        {section.content.members?.map((member, index) => (
          <Grid item xs={12} sm={6} md={3} key={index}>
            <Box sx={{ textAlign: 'center' }}>
              <Avatar
                src={member.image}
                sx={{ width: 120, height: 120, mx: 'auto', mb: 2 }}
              />
              <EditableElement
                value={member.name}
                onChange={(value) => handleUpdate('name', value)}
                variant="h6"
                component="h6"
              />
              <EditableElement
                value={member.role}
                onChange={(value) => handleUpdate('role', value)}
                variant="body1"
                component="p"
                color="text.secondary"
              />
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );

  const renderTestimonials = () => (
    <Box sx={{ py: 8, px: 2 }}>
      <Grid container spacing={4}>
        {section.content.testimonials?.map((testimonial, index) => (
          <Grid item xs={12} md={4} key={index}>
            <Card>
              <CardContent>
                <Rating value={testimonial.rating} readOnly sx={{ mb: 2 }} />
                <EditableElement
                  value={testimonial.text}
                  onChange={(value) => handleUpdate('text', value)}
                  variant="body1"
                  component="p"
                  sx={{ mb: 2 }}
                />
                <EditableElement
                  value={testimonial.author}
                  onChange={(value) => handleUpdate('author', value)}
                  variant="subtitle2"
                  component="p"
                  color="text.secondary"
                />
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );

  const sectionMap = {
    header: renderHeader,
    features: renderFeatures,
    team: renderTeam,
    testimonials: renderTestimonials,
  };

  return (
    <Box
      sx={{
        position: 'relative',
        '&:hover': !isPreview ? {
          outline: '1px dashed #1976d2',
        } : {},
      }}
      onMouseEnter={() => setShowControls(true)}
      onMouseLeave={() => setShowControls(false)}
    >
      {showControls && !isPreview && (
        <SectionControls
          section={section}
          onEdit={() => {
            // Your edit handler
          }}
          onDelete={() => onDelete(section.id)}
          onDuplicate={() => onDuplicate(section.id)}
          onStyleEdit={(section, closePopper) => (
            <SectionStyleControls
              section={section}
              onUpdate={(updatedSection) => {
                onUpdate(updatedSection);
                closePopper();
              }}
            />
          )}
          dragHandleProps={dragHandleProps}
        />
      )}
      {sectionMap[section.type]?.() || null}
    </Box>
  );
};

export default SectionRenderer; 