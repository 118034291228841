import React from 'react';
import {
  Paper,
  IconButton,
  Box,
  Tooltip,
  Fade,
  Popper,
  ClickAwayListener,
} from '@mui/material';
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  DragIndicator as DragIcon,
  Settings as StyleIcon,
  ContentCopy as DuplicateIcon,
  Animation as AnimationIcon,
  Devices as DevicesIcon,
} from '@mui/icons-material';
import AnimationControls from './AnimationControls';
import ResponsiveControls from './ResponsiveControls';

const SectionControls = ({ 
  section, 
  onEdit, 
  onDelete, 
  onDuplicate, 
  onStyleEdit,
  dragHandleProps 
}) => {
  const [styleAnchor, setStyleAnchor] = React.useState(null);
  const [animationAnchor, setAnimationAnchor] = React.useState(null);
  const [responsiveAnchor, setResponsiveAnchor] = React.useState(null);

  return (
    <Paper
      elevation={2}
      sx={{
        position: 'absolute',
        top: 8,
        right: 8,
        display: 'flex',
        gap: 0.5,
        p: 0.5,
        backgroundColor: 'rgba(255, 255, 255, 0.95)',
        zIndex: 1000,
      }}
    >
      <Tooltip title="Drag to reorder">
        <Box {...dragHandleProps} sx={{ cursor: 'grab', display: 'flex' }}>
          <DragIcon fontSize="small" />
        </Box>
      </Tooltip>

      <Tooltip title="Edit content">
        <IconButton size="small" onClick={onEdit}>
          <EditIcon fontSize="small" />
        </IconButton>
      </Tooltip>

      <Tooltip title="Style settings">
        <IconButton 
          size="small" 
          onClick={(e) => setStyleAnchor(e.currentTarget)}
        >
          <StyleIcon fontSize="small" />
        </IconButton>
      </Tooltip>

      <Tooltip title="Duplicate section">
        <IconButton size="small" onClick={onDuplicate}>
          <DuplicateIcon fontSize="small" />
        </IconButton>
      </Tooltip>

      <Tooltip title="Delete section">
        <IconButton size="small" onClick={onDelete}>
          <DeleteIcon fontSize="small" />
        </IconButton>
      </Tooltip>

      <Tooltip title="Animation settings">
        <IconButton 
          size="small" 
          onClick={(e) => setAnimationAnchor(e.currentTarget)}
        >
          <AnimationIcon fontSize="small" />
        </IconButton>
      </Tooltip>

      <Tooltip title="Responsive settings">
        <IconButton 
          size="small" 
          onClick={(e) => setResponsiveAnchor(e.currentTarget)}
        >
          <DevicesIcon fontSize="small" />
        </IconButton>
      </Tooltip>

      <Popper
        open={Boolean(styleAnchor)}
        anchorEl={styleAnchor}
        placement="right-start"
        transition
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <ClickAwayListener onClickAway={() => setStyleAnchor(null)}>
              <Box>
                {onStyleEdit(section, () => setStyleAnchor(null))}
              </Box>
            </ClickAwayListener>
          </Fade>
        )}
      </Popper>

      <Popper
        open={Boolean(animationAnchor)}
        anchorEl={animationAnchor}
        placement="right-start"
        transition
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <ClickAwayListener onClickAway={() => setAnimationAnchor(null)}>
              <Box>
                <AnimationControls section={section} onUpdate={onStyleEdit} />
              </Box>
            </ClickAwayListener>
          </Fade>
        )}
      </Popper>

      <Popper
        open={Boolean(responsiveAnchor)}
        anchorEl={responsiveAnchor}
        placement="right-start"
        transition
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <ClickAwayListener onClickAway={() => setResponsiveAnchor(null)}>
              <Box>
                <ResponsiveControls section={section} onUpdate={onStyleEdit} />
              </Box>
            </ClickAwayListener>
          </Fade>
        )}
      </Popper>
    </Paper>
  );
};

export default SectionControls; 