import React from 'react';
import { 
  Grid, 
  Paper, 
  Typography, 
  Box,
  Divider 
} from '@mui/material';
import PaymentsIcon from '@mui/icons-material/Payments';
import GroupIcon from '@mui/icons-material/Group';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import SettingsIcon from '@mui/icons-material/Settings';
import ReportCard from '../../reports/ReportCard';
import { useTheme } from '../../../ThemeContext';

const PayrollPanel = ({ onNavigate }) => {
  const { darkMode } = useTheme();

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} md={6} lg={3}>
        <Paper elevation={0} sx={{ 
          p: 3, 
          height: '100%',
          backgroundColor: darkMode ? 'var(--background-paper)' : '#ffffff',
        }}>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
            <PaymentsIcon sx={{ 
              mr: 1, 
              color: darkMode ? 'var(--text-primary)' : '#34495e' 
            }} />
            <Typography variant="h6" sx={{ 
              color: darkMode ? 'var(--text-primary)' : '#34495e'
            }}>
              Payroll Processing
            </Typography>
          </Box>
          <Divider sx={{ 
            mb: 3,
            borderColor: darkMode ? 'var(--border-color)' : 'inherit'
          }} />
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <ReportCard
                title="Run Payroll"
                description="Process payroll for the current period"
                onClick={() => onNavigate('run-payroll')}
              />
            </Grid>
            <Grid item xs={12}>
              <ReportCard
                title="Payroll History"
                description="View past payroll runs and reports"
                onClick={() => onNavigate('payroll-history')}
              />
            </Grid>
          </Grid>
        </Paper>
      </Grid>

      <Grid item xs={12} md={6} lg={3}>
        <Paper elevation={0} sx={{ 
          p: 3, 
          height: '100%',
          backgroundColor: darkMode ? 'var(--background-paper)' : '#ffffff',
        }}>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
            <GroupIcon sx={{ 
              mr: 1, 
              color: darkMode ? 'var(--text-primary)' : '#34495e' 
            }} />
            <Typography variant="h6" sx={{ 
              color: darkMode ? 'var(--text-primary)' : '#f4f4f4'
            }}>
              Employee Management
            </Typography>
          </Box>
          <Divider sx={{ 
            mb: 3,
            borderColor: darkMode ? 'var(--border-color)' : 'inherit'
          }} />
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <ReportCard
                title="Employee List"
                description="Manage employee information and status"
                onClick={() => onNavigate('employee-list')}
              />
            </Grid>
            <Grid item xs={12}>
              <ReportCard
                title="Time & Attendance"
                description="Review and approve employee time cards"
                onClick={() => onNavigate('time-attendance')}
              />
            </Grid>
          </Grid>
        </Paper>
      </Grid>

      <Grid item xs={12} md={6} lg={3}>
        <Paper elevation={0} sx={{ 
          p: 3, 
          height: '100%',
          backgroundColor: darkMode ? 'var(--background-paper)' : '#ffffff',
        }}>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
            <ReceiptLongIcon sx={{ 
              mr: 1, 
              color: darkMode ? 'var(--text-primary)' : '#34495e' 
            }} />
            <Typography variant="h6" sx={{ 
              color: darkMode ? 'var(--text-primary)' : '#34495e'
            }}>
              Tax & Compliance
            </Typography>
          </Box>
          <Divider sx={{ 
            mb: 3,
            borderColor: darkMode ? 'var(--border-color)' : 'inherit'
          }} />
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <ReportCard
                title="Tax Forms"
                description="Access and file tax forms"
                onClick={() => onNavigate('tax-forms')}
              />
            </Grid>
            <Grid item xs={12}>
              <ReportCard
                title="Compliance Reports"
                description="Generate compliance reports"
                onClick={() => onNavigate('compliance-reports')}
              />
            </Grid>
          </Grid>
        </Paper>
      </Grid>

      <Grid item xs={12} md={6} lg={3}>
        <Paper elevation={0} sx={{ 
          p: 3, 
          height: '100%',
          backgroundColor: darkMode ? 'var(--background-paper)' : '#ffffff',
        }}>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
            <SettingsIcon sx={{ 
              mr: 1, 
              color: darkMode ? 'var(--text-primary)' : '#34495e' 
            }} />
            <Typography variant="h6" sx={{ 
              color: darkMode ? 'var(--text-primary)' : '#34495e'
            }}>
              Settings
            </Typography>
          </Box>
          <Divider sx={{ 
            mb: 3,
            borderColor: darkMode ? 'var(--border-color)' : 'inherit'
          }} />
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <ReportCard
                title="Payroll Settings"
                description="Configure payroll rules and preferences"
                onClick={() => onNavigate('payroll-settings')}
              />
            </Grid>
            <Grid item xs={12}>
              <ReportCard
                title="Deductions & Benefits"
                description="Manage employee benefits and deductions"
                onClick={() => onNavigate('deductions-benefits')}
              />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default PayrollPanel; 