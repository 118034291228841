import React from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './AuthContext';
import { Box, List, ListItem, ListItemIcon, ListItemText, Divider } from '@mui/material';
import { useTheme } from '../ThemeContext';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined';
import HandymanOutlinedIcon from '@mui/icons-material/HandymanOutlined';
import PaymentsOutlinedIcon from '@mui/icons-material/PaymentsOutlined';
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
import RequestQuoteOutlinedIcon from '@mui/icons-material/RequestQuoteOutlined';
import ReceiptOutlinedIcon from '@mui/icons-material/ReceiptOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import LeaderboardOutlinedIcon from '@mui/icons-material/LeaderboardOutlined';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import ShowChartOutlinedIcon from '@mui/icons-material/ShowChartOutlined';
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';
import ContactsOutlinedIcon from '@mui/icons-material/ContactsOutlined';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';

const Navbar = () => {
  const navigate = useNavigate();
  const { logout } = useAuth();
  const { darkMode } = useTheme();

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  const navItems = [
    { icon: <HomeOutlinedIcon />, text: 'Home', path: '/admin' },
    { icon: <CalendarMonthOutlinedIcon />, text: 'Calendar', path: '/admin/calendar' },
    { icon: <PeopleOutlinedIcon />, text: 'Team', path: '/admin/employees' },
    { type: 'divider' },
    { icon: <ContactsOutlinedIcon />, text: 'Leads', path: '/admin/leads' },
    { icon: <RequestQuoteOutlinedIcon />, text: 'Estimates', path: '/admin/estimates' },
    { icon: <HandymanOutlinedIcon />, text: 'Projects', path: '/admin/projects' },
    { icon: <ReceiptOutlinedIcon />, text: 'Invoices', path: '/admin/invoices' },
    { icon: <BusinessOutlinedIcon />, text: 'Clients', path: '/admin/clients' },
    { icon: <LocalOfferOutlinedIcon />, text: 'Price book', path: '/admin/price-book' },
    { type: 'divider' },
    { icon: <PaymentsOutlinedIcon />, text: 'Reports', path: '/admin/payroll' },
    { icon: <SettingsOutlinedIcon />, text: 'Settings', path: '/admin/settings' },
  ];

  return (
    <Box
      component="nav"
      sx={{
        width: 240,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        bgcolor: darkMode ? 'var(--background-paper)' : '#fff',
        color: darkMode ? 'var(--text-primary)' : '#1a2027',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        padding: '1rem 0',
        fontFamily: 'Urbanist, sans-serif',
        zIndex: 1105,
        background: darkMode ? 'rgba(45, 54, 60, 0.95)' : 'rgba(255, 255, 255, 0.95)',
        backdropFilter: 'blur(10px)',
        borderRight: darkMode 
          ? '1px solid var(--border-color)' 
          : '1px solid rgba(0, 0, 0, 0.12)',
        transition: 'width 0.4s cubic-bezier(0.25, 0.1, 0.25, 1)',
        position: 'relative',
      }}
    >
      <List
        sx={{
          height: '100%',
          overflowY: 'auto',
          overflowX: 'hidden',
          '&::-webkit-scrollbar': {
            width: '4px',
          },
          '&::-webkit-scrollbar-track': {
            background: 'transparent',
          },
          '&::-webkit-scrollbar-thumb': {
            background: darkMode ? 'rgba(255, 255, 255, 0.2)' : 'rgba(0, 0, 0, 0.2)',
            borderRadius: '4px',
          },
          '&::-webkit-scrollbar-thumb:hover': {
            background: darkMode ? 'rgba(255, 255, 255, 0.3)' : 'rgba(0, 0, 0, 0.3)',
          },
          paddingBottom: '60px',
        }}
      >
        {navItems.map((item) => (
          item.type === 'divider' ? (
            <Divider 
              key={Math.random()} 
              sx={{ 
                my: 0.5,
                borderColor: darkMode ? 'var(--border-color)' : 'rgba(0, 0, 0, 0.1)',
              }} 
            />
          ) : (
            <ListItem
              button
              key={item.text}
              component={Link}
              to={item.path}
              sx={{
                color: darkMode ? 'var(--text-primary)' : '#1a2027',
                padding: '0.5rem 1rem',
                marginBottom: '0.25rem',
                transition: 'all 0.3s ease',
                '&:hover': {
                  backgroundColor: darkMode 
                    ? 'var(--background-hover)' 
                    : 'rgba(0, 0, 0, 0.04)',
                  '& .MuiListItemText-root': {
                    color: darkMode ? '#90caf9' : '#1976d2',
                    transform: 'translateX(5px)',
                  },
                  '& .MuiListItemIcon-root': {
                    color: darkMode ? '#90caf9' : '#1976d2',
                  }
                },
              }}
            >
              <ListItemIcon 
                sx={{ 
                  minWidth: 0, 
                  mr: 2, 
                  justifyContent: 'center',
                  color: darkMode ? 'var(--text-secondary)' : 'inherit',
                  transition: 'color 0.3s ease',
                }}
              >
                <Box
                  sx={{
                    backgroundColor: 'transparent',
                    borderRadius: '50%',
                    padding: '8px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  {item.icon}
                </Box>
              </ListItemIcon>
              <ListItemText
                primary={item.text}
                primaryTypographyProps={{
                  fontFamily: 'Urbanist, sans-serif',
                  fontWeight: 500,
                  sx: {
                    transition: 'color 0.3s ease, transform 0.3s ease',
                  }
                }}
              />
            </ListItem>
          )
        ))}
      </List>
      <ListItem
        button
        onClick={handleLogout}
        sx={{
          color: darkMode ? 'var(--text-primary)' : '#1a2027',
          padding: '0.5rem 1rem',
          borderTop: darkMode 
            ? '1px solid var(--border-color)' 
            : '1px solid rgba(0, 0, 0, 0.12)',
          position: 'absolute',
          bottom: 0,
          left: 0,
          right: 0,
          bgcolor: darkMode ? 'var(--background-paper)' : '#fff',
          transition: 'all 0.3s ease',
          '&:hover': {
            backgroundColor: darkMode 
              ? 'var(--background-hover)' 
              : 'rgba(0, 0, 0, 0.04)',
            '& .MuiListItemText-root': {
              color: '#ef5350',
              transform: 'translateX(5px)',
            },
            '& .MuiListItemIcon-root': {
              color: '#ef5350',
            }
          },
        }}
      >
        <ListItemIcon 
          sx={{ 
            minWidth: 0, 
            mr: 2, 
            justifyContent: 'center',
            color: darkMode ? 'var(--text-secondary)' : 'inherit',
            transition: 'color 0.3s ease',
          }}
        >
          <Box
            sx={{
              backgroundColor: 'transparent',
              borderRadius: '50%',
              padding: '8px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <LogoutOutlinedIcon />
          </Box>
        </ListItemIcon>
        <ListItemText
          primary="Logout"
          primaryTypographyProps={{
            fontFamily: 'Urbanist, sans-serif',
            fontWeight: 500,
            sx: {
              transition: 'color 0.3s ease, transform 0.3s ease',
            }
          }}
        />
      </ListItem>
    </Box>
  );
};

export default Navbar;

