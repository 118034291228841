import React from 'react';
import { 
  Box, 
  Paper, 
  Typography, 
  IconButton,
  Grid,
  Card,
  CardContent,
  Button,
  Chip
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AssessmentIcon from '@mui/icons-material/Assessment';
import DownloadIcon from '@mui/icons-material/Download';

const ComplianceReportsView = ({ onBack }) => {
  const reports = [
    {
      name: 'EEOC Report',
      description: 'Equal Employment Opportunity Commission Report',
      lastGenerated: '2024-02-28',
      status: 'Up to date'
    },
    {
      name: 'OSHA Report',
      description: 'Occupational Safety and Health Administration Report',
      lastGenerated: '2024-02-15',
      status: 'Action needed'
    },
    // Add more reports
  ];

  return (
    <Box>
      <Box sx={{ mb: 4, display: 'flex', alignItems: 'center' }}>
        <IconButton onClick={onBack} sx={{ mr: 2 }}>
          <ArrowBackIcon />
        </IconButton>
        <AssessmentIcon sx={{ mr: 2, color: '#34495e' }} />
        <Typography variant="h5">Compliance Reports</Typography>
      </Box>

      <Grid container spacing={3}>
        {reports.map((report, index) => (
          <Grid item xs={12} md={6} key={index}>
            <Paper elevation={0} sx={{ p: 3 }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                <Typography variant="h6">
                  {report.name}
                </Typography>
                <Chip 
                  label={report.status} 
                  color={report.status === 'Up to date' ? 'success' : 'warning'} 
                  size="small" 
                />
              </Box>
              <Typography color="text.secondary" gutterBottom>
                {report.description}
              </Typography>
              <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                Last generated: {report.lastGenerated}
              </Typography>
              <Button
                variant="outlined"
                startIcon={<DownloadIcon />}
                sx={{ textTransform: 'none' }}
              >
                Generate Report
              </Button>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default ComplianceReportsView; 