import React, { useState } from 'react';
import { Box, Button, IconButton, Drawer, Typography, Divider, TextField, InputAdornment } from '@mui/material';
import {
  ViewList as ListIcon,
  ViewModule as GridIcon,
  Add as AddIcon,
  FilterList as FilterIcon,
  Upload as UploadIcon,
  Menu as MenuIcon,
  Close as CloseIcon,
  Person as ContactsIcon,
  Label as TagsIcon,
  Business as CompaniesIcon,
  Settings as SettingsIcon,
  Search as SearchIcon,
} from '@mui/icons-material';



const NavItem = ({ icon: Icon, label, active, onClick }) => (
  <Box
    onClick={onClick}
    sx={{
      display: 'flex',
      alignItems: 'center',
      gap: 2,
      py: 2,
      px: 3,
      cursor: 'pointer',
      borderRadius: 2,
      backgroundColor: active ? 'primary.main' : 'transparent',
      color: active ? 'white' : 'text.secondary',
      transition: 'all 0.2s ease',
      '&:hover': {
        backgroundColor: active ? 'primary.dark' : 'action.hover',
      }
    }}
  >
    <Icon sx={{ fontSize: 22 }} />
    <Typography sx={{ fontWeight: 500, fontSize: '0.95rem' }}>{label}</Typography>
  </Box>
);

const ContactNavigation = ({ currentView, onViewChange, onCreateClick, onImportClick }) => {
  const [open, setOpen] = useState(false);

  const toggleDrawer = () => {
    setOpen(!open);
  };

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
        <Box sx={{ display: 'flex', gap: 1 }}>
          <Button
            variant="outlined"
            startIcon={<UploadIcon />}
            onClick={onImportClick}
            sx={{
              borderRadius: 2,
              textTransform: 'none',
              borderColor: 'grey.300',
              color: 'grey.700',
              '&:hover': {
                borderColor: 'primary.main',
                backgroundColor: 'primary.50',
              }
            }}
          >
            Import
          </Button>
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={onCreateClick}
            sx={{
              borderRadius: 2,
              textTransform: 'none',
              background: 'linear-gradient(45deg, #2196f3 30%, #21cbf3 90%)',
              boxShadow: '0 3px 5px 2px rgba(33, 203, 243, .3)',
              '&:hover': {
                background: 'linear-gradient(45deg, #1976d2 30%, #21cbf3 90%)',
              }
            }}
          >
            Add Contact
          </Button>
        </Box>
        <IconButton
          onClick={toggleDrawer}
          size="large"
          sx={{
            color: 'grey.700',
            '&:hover': {
              backgroundColor: 'grey.100',
            },
          }}
        >
          <MenuIcon />
        </IconButton>
      </Box>

      <Drawer
        anchor="right"
        open={open}
        onClose={toggleDrawer}
        PaperProps={{
          sx: {
            width: 320,
            borderRadius: '0 12px 12px 0',
            mt: '64px',
            height: 'calc(100% - 64px)',
            backgroundColor: '#ffffff',
            boxShadow: '4px 0 20px rgba(0, 0, 0, 0.08)',
          }
        }}
      >
        <Box sx={{ 
          p: 3,
          display: 'flex', 
          flexDirection: 'column',
          height: '100%'
        }}>
          {/* Header */}
          <Box sx={{ 
            display: 'flex', 
            justifyContent: 'space-between', 
            alignItems: 'center',
            mb: 3
          }}>
            <Typography variant="h6" sx={{ fontWeight: 600, color: 'grey.900' }}>
              Menu
            </Typography>
            <IconButton onClick={toggleDrawer} size="small">
              <CloseIcon />
            </IconButton>
          </Box>

          {/* Navigation Items */}
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
            <Typography variant="overline" color="text.secondary" sx={{ px: 3, mt: 2 }}>
              Views
            </Typography>
            <NavItem
              icon={ContactsIcon}
              label="All Contacts"
              active={currentView === 'all'}
              onClick={() => {
                onViewChange('all');
                toggleDrawer();
              }}
            />
            <NavItem
              icon={TagsIcon}
              label="By Tags"
              active={currentView === 'tags'}
              onClick={() => {
                onViewChange('tags');
                toggleDrawer();
              }}
            />
            <NavItem
              icon={CompaniesIcon}
              label="By Company"
              active={currentView === 'companies'}
              onClick={() => {
                onViewChange('companies');
                toggleDrawer();
              }}
            />
            
            <Typography variant="overline" color="text.secondary" sx={{ px: 3, mt: 3 }}>
              Settings
            </Typography>
            <NavItem
              icon={SettingsIcon}
              label="Contact Settings"
              active={currentView === 'settings'}
              onClick={() => {
                onViewChange('settings');
                toggleDrawer();
              }}
            />
          </Box>
        </Box>
      </Drawer>
    </>
  );
};

export default ContactNavigation; 