import React from 'react';
import { Paper, Typography } from '@mui/material';
import {
  PieChart,
  Pie,
  Cell,
  Tooltip,
  Legend,
  ResponsiveContainer
} from 'recharts';

const projectData = [
  { name: 'Cleaning', value: 400 },
  { name: 'Maintenance', value: 300 },
  { name: 'Repairs', value: 300 },
  { name: 'Installation', value: 200 },
];

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

const ProjectDistributionChart = () => (
  <Paper 
    elevation={0} 
    sx={{ 
      p: 3, 
      height: '100%',
      border: '1px solid #e0e0e0',
      borderRadius: 1
    }}
  >
    <Typography variant="h6" gutterBottom sx={{ color: '#34495e' }}>
      Project Distribution
    </Typography>
    <ResponsiveContainer width="100%" height={400}>
      <PieChart>
        <Pie
          data={projectData}
          cx="50%"
          cy="50%"
          innerRadius={60}
          outerRadius={80}
          fill="#8884d8"
          paddingAngle={5}
          dataKey="value"
        >
          {projectData.map((entry, index) => (
            <Cell 
              key={`cell-${index}`} 
              fill={COLORS[index % COLORS.length]} 
            />
          ))}
        </Pie>
        <Tooltip />
        <Legend />
      </PieChart>
    </ResponsiveContainer>
  </Paper>
);

export default ProjectDistributionChart; 