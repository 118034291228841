import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { Typography, Paper, Grid, Button, CircularProgress, Box, Avatar, Tabs, Tab } from '@mui/material';
import { getInvoiceById } from '../services/api';
import DefaultInvoiceTemplate from '../templates/DefaultInvoiceTemplate';
import ModernInvoiceTemplate from '../templates/ModernInvoiceTemplate';
import ProfessionalInvoiceTemplate from '../templates/ProfessionalInvoiceTemplate';
import ClassicInvoiceTemplate from '../templates/ClassicInvoiceTemplate';
import MinimalistInvoiceTemplate from '../templates/MinimalistInvoiceTemplate';
import html2pdf from 'html2pdf.js';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ReceiptIcon from '@mui/icons-material/Receipt';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import BusinessIcon from '@mui/icons-material/Business';
import AssignmentIcon from '@mui/icons-material/Assignment';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Menu, MenuItem, ListItemIcon, ListItemText } from '@mui/material';
import PrintIcon from '@mui/icons-material/Print';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import NoteIcon from '@mui/icons-material/Note';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import Chip from '@mui/material/Chip';
import AddIcon from '@mui/icons-material/Add';
import IconButton from '@mui/material/IconButton';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import styles from './AdminInvoiceDetails.module.scss';
import PaymentIcon from '@mui/icons-material/Payment';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import LinkIcon from '@mui/icons-material/Link';
import ShareIcon from '@mui/icons-material/Share';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ShareLinkButton from './ShareLinkButton';
import { Snackbar, Slide } from '@mui/material';
import TagsSection from './TagsSection';
import BuildIcon from '@mui/icons-material/Build';
import EditIcon from '@mui/icons-material/Edit';

const theme = createTheme({
  typography: {
    fontFamily: 'Urbanist, sans-serif',
    allVariants: {
      fontFamily: 'Urbanist, sans-serif',
    },
    button: {
      fontFamily: 'Urbanist, sans-serif',
    }
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: 'Urbanist, sans-serif',
        },
      },
    },
  },
});

const AdminInvoiceDetails = () => {
  const { id } = useParams();
  const [invoice, setInvoice] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showSuccess, setShowSuccess] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [paymentAnchorEl, setPaymentAnchorEl] = useState(null);
  const [actionAnchorEl, setActionAnchorEl] = useState(null);
  const navigate = useNavigate();
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success'
  });

  const saveAsPDF = async () => {
    const element = document.getElementById('invoice-template');
    
    // First hide any elements we don't want in the PDF
    const actionsButtons = document.querySelectorAll('.no-print');
    actionsButtons.forEach(btn => btn.style.display = 'none');

    const opt = {
      margin: 0,
      filename: `Invoice-${invoice.invoice_number}.pdf`,
      image: { type: 'jpeg', quality: 1 },
      html2canvas: { 
        scale: 2,
        useCORS: true,
        letterRendering: true,
        scrollX: 0,
        scrollY: 0,
        windowWidth: 794, // A4 width in pixels at 96 DPI
        windowHeight: 1123, // A4 height in pixels at 96 DPI
        onclone: (doc) => {
          // Any manipulations to the cloned document if needed
          const content = doc.getElementById('invoice-template');
          if (content) {
            content.style.padding = '40px';
            content.style.backgroundColor = 'white';
          }
        }
      },
      jsPDF: { 
        unit: 'pt', 
        format: 'a4', 
        orientation: 'portrait',
      }
    };

    try {
      await html2pdf().set(opt).from(element).save();
    } catch (error) {
      console.error('Error generating PDF:', error);
    } finally {
      // Restore any hidden elements
      actionsButtons.forEach(btn => btn.style.display = '');
    }
  };

  const fetchInvoice = async () => {
    try {
      console.log('Fetching invoice with id:', id);
      const fetchedInvoice = await getInvoiceById(id);
      console.log('Fetched invoice:', fetchedInvoice);
      setInvoice(fetchedInvoice);
      setError(null); // Clear any previous errors
    } catch (err) {
      console.error('Error fetching invoice:', err);
      setError('Failed to fetch invoice details. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchInvoice();
  }, [id]);

  const renderInvoiceTemplate = () => {
    console.log('Rendering invoice template. Invoice:', invoice);
    if (!invoice || !invoice.business_details || !invoice.customer_details) {
      console.log('Invalid invoice data');
      return <Typography>Invalid invoice data</Typography>;
    }

    const templateProps = {
      id: invoice.id,
      businessDetails: invoice.business_details,
      customerDetails: invoice.customer_details,
      invoiceNumber: invoice.invoice_number,
      issueDate: new Date(invoice.issue_date),
      dueDate: new Date(invoice.due_date),
      items: invoice.items || [],
      tax_rate: invoice.tax_rate || 0,
      notes: invoice.notes,
      termsAndConditions: invoice.terms_and_conditions,
      includeNotes: invoice.include_notes,
      includeTerms: invoice.include_terms
    };

    console.log('Template props:', templateProps);
    console.log('Customer Details:', invoice.customer_details);

    switch (invoice.template) {
      case 'modern':
        return <ModernInvoiceTemplate invoice={templateProps} />;
      case 'professional':
        return <ProfessionalInvoiceTemplate invoice={templateProps} />;
      case 'classic':
        return <ClassicInvoiceTemplate invoice={templateProps} />;
      case 'minimalist':
        return <MinimalistInvoiceTemplate invoice={templateProps} />;
      default:
        return <DefaultInvoiceTemplate invoice={templateProps} />;
    }
  };

  const handleClose = () => setAnchorEl(null);

  const handlePaymentClose = () => {
    setPaymentAnchorEl(null);
  };

  const menuItemStyle = {
    padding: '8px 20px',
    '&:hover': {
      backgroundColor: 'rgba(25, 118, 210, 0.08)'
    }
  };

  const handleCopySuccess = () => {
    setSnackbar({
      open: true,
      message: 'Link copied to clipboard!',
      severity: 'success'
    });
  };

  if (loading) return <CircularProgress />;
  if (error) return <Typography color="error">{error}</Typography>;
  if (!invoice) return <Typography>Invoice not found</Typography>;

  console.log('Rendering AdminInvoiceDetails component');

  return (
    <ThemeProvider theme={theme}>
      <div style={{ 
        backgroundColor: 'var(--background-default)',
        minHeight: '100vh'
      }}>
        <Box sx={{ 
          maxWidth: '1400px',
          margin: '0 auto',
          padding: '20px',
        }}>
          <div className={styles.headerSection}>
            <Link 
              to="/admin/invoices" 
              className={styles.backLink}
              style={{
                color: 'var(--text-primary)',
                textDecoration: 'none',
                display: 'flex',
                alignItems: 'center',
                gap: '8px'
              }}
            >
              <ArrowBackIcon />
              Back to Invoices
            </Link>
            <Box sx={{ 
              display: 'flex', 
              gap: 2, 
              ml: 'auto',
              alignItems: 'center' 
            }}>
              <Button
                variant="outlined"
                startIcon={<PaymentIcon />}
                onClick={(event) => setPaymentAnchorEl(event.currentTarget)}
                endIcon={<KeyboardArrowDownIcon />}
                sx={{ 
                  borderColor: '#1976d2',
                  color: '#1976d2',
                  '&:hover': {
                    borderColor: '#1565c0',
                    backgroundColor: 'rgba(25, 118, 210, 0.08)'
                  },
                  boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                  borderRadius: '8px',
                  textTransform: 'none',
                  fontSize: '1rem'
                }}
              >
                Collect Payment
              </Button>

              <Button
                variant="contained"
                onClick={(event) => setActionAnchorEl(event.currentTarget)}
                endIcon={<KeyboardArrowDownIcon />}
                sx={{
                  backgroundColor: '#1976d2',
                  color: '#fff',
                  '&:hover': {
                    backgroundColor: '#1565c0'
                  },
                  boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                  borderRadius: '8px',
                  textTransform: 'none',
                  fontSize: '1rem'
                }}
              >
                Actions ⚡
              </Button>
            </Box>

            {/* Update Menu styles */}
            <Menu
              anchorEl={paymentAnchorEl}
              open={Boolean(paymentAnchorEl)}
              onClose={handlePaymentClose}
              PaperProps={{
                sx: {
                  backgroundColor: 'var(--background-paper)',
                  color: 'var(--text-primary)',
                  border: '1px solid var(--divider)',
                  mt: 1
                }
              }}
            >
              <MenuItem 
                onClick={handlePaymentClose}
                sx={{
                  padding: '8px 20px',
                  '&:hover': {
                    backgroundColor: 'rgba(25, 118, 210, 0.08)'
                  }
                }}
              >
                <ListItemIcon>
                  <img src="/stripe-icon.png" alt="Stripe" style={{ width: 24 }} />
                </ListItemIcon>
                <ListItemText>Stripe Payment</ListItemText>
              </MenuItem>
            </Menu>

            {/* Actions Menu with same styling */}
            <Menu
              anchorEl={actionAnchorEl}
              open={Boolean(actionAnchorEl)}
              onClose={() => setActionAnchorEl(null)}
              PaperProps={{
                sx: {
                  backgroundColor: 'var(--background-paper)',
                  color: 'var(--text-primary)',
                  border: '1px solid var(--divider)',
                  mt: 1
                }
              }}
            >
              <MenuItem 
                onClick={() => { 
                  navigate(`/admin/invoices/edit/${invoice.id}`);
                  setActionAnchorEl(null); 
                }}
                sx={{
                  padding: '8px 20px',
                  '&:hover': {
                    backgroundColor: 'rgba(25, 118, 210, 0.08)'
                  }
                }}
              >
                <ListItemIcon>
                  <EditIcon sx={{ color: 'var(--text-primary)' }} />
                </ListItemIcon>
                <ListItemText>Edit Invoice</ListItemText>
              </MenuItem>
            </Menu>
          </div>

          <Typography 
            variant="h5" 
            sx={{ 
              fontFamily: 'Urbanist, sans-serif',
              mb: 2,
              mt: 1,
              fontWeight: 600,
              color: 'var(--text-primary)',
              paddingBottom: '10px'
            }}
          >
            Invoice Preview
          </Typography>

          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <Box sx={{ 
                backgroundColor: 'var(--background-paper)',
                borderRadius: 2,
                p: 3,
                mb: 3,
                border: '1px solid var(--divider)'
              }}>
                <div className="invoice-details__client-card">
                  <Box sx={{ 
                    display: 'flex', 
                    alignItems: 'center',
                    mb: 3,
                    gap: 2
                  }}>
                    <Avatar 
                      sx={{ 
                        width: 56, 
                        height: 56, 
                        background: 'linear-gradient(135deg, #1976d2 0%, #1565c0 100%)',
                        color: '#fff',
                        fontSize: '1.5rem',
                        fontWeight: 500,
                        boxShadow: '0 2px 8px rgba(25, 118, 210, 0.25)'
                      }}
                    >
                      {invoice?.customer_details?.name?.split(' ').map(n => n[0]).join('').toUpperCase() || '?'}
                    </Avatar>
                    <Box>
                      <Typography variant="h6" sx={{ 
                        color: 'var(--text-primary)',
                        fontWeight: 600,
                        mb: 0.5
                      }}>
                        {invoice?.customer_details?.name || 'No Name'}
                      </Typography>
                      <Typography variant="body2" sx={{ 
                        color: 'var(--text-secondary)',
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1
                      }}>
                        <ReceiptIcon sx={{ fontSize: '1rem' }} />
                        Invoice #{invoice?.invoice_number}
                      </Typography>
                    </Box>
                  </Box>

                  {/* Client Details Boxes */}
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                    {/* Email Box */}
                    <Box sx={{ 
                      display: 'flex',
                      alignItems: 'center',
                      gap: 2,
                      p: 2,
                      backgroundColor: 'var(--background-default)',
                      borderRadius: '8px',
                      border: '1px solid var(--divider)',
                      '&:hover': {
                        backgroundColor: 'var(--hover-overlay)'
                      }
                    }}>
                      <EmailIcon sx={{ color: 'var(--primary-main)' }} />
                      <Box>
                        <Typography variant="caption" sx={{ color: 'var(--text-secondary)' }}>
                          Email
                        </Typography>
                        <Typography variant="body2" sx={{ color: 'var(--text-primary)' }}>
                          {invoice?.customer_details?.email || 'No email provided'}
                        </Typography>
                      </Box>
                    </Box>

                    {/* Phone Box */}
                    <Box sx={{ 
                      display: 'flex',
                      alignItems: 'center',
                      gap: 2,
                      p: 2,
                      backgroundColor: 'var(--background-default)',
                      borderRadius: '8px',
                      border: '1px solid var(--divider)',
                      '&:hover': {
                        backgroundColor: 'var(--hover-overlay)'
                      }
                    }}>
                      <PhoneIcon sx={{ color: 'var(--success-main)' }} />
                      <Box>
                        <Typography variant="caption" sx={{ color: 'var(--text-secondary)' }}>
                          Phone
                        </Typography>
                        <Typography variant="body2" sx={{ color: 'var(--text-primary)' }}>
                          {invoice?.customer_details?.phone || 'No phone provided'}
                        </Typography>
                      </Box>
                    </Box>

                    {/* Company Box */}
                    <Box sx={{ 
                      display: 'flex',
                      alignItems: 'center',
                      gap: 2,
                      p: 2,
                      backgroundColor: 'var(--background-default)',
                      borderRadius: '8px',
                      border: '1px solid var(--divider)',
                      '&:hover': {
                        backgroundColor: 'var(--hover-overlay)'
                      }
                    }}>
                      <BusinessIcon sx={{ color: 'var(--info-main)' }} />
                      <Box>
                        <Typography variant="caption" sx={{ color: 'var(--text-secondary)' }}>
                          Company
                        </Typography>
                        <Typography variant="body2" sx={{ color: 'var(--text-primary)' }}>
                          {invoice?.customer_details?.company || 'No company provided'}
                        </Typography>
                      </Box>
                    </Box>

                    {/* Address Box */}
                    <Box sx={{ 
                      display: 'flex',
                      alignItems: 'flex-start',
                      gap: 2,
                      p: 2,
                      backgroundColor: 'var(--background-default)',
                      borderRadius: '8px',
                      border: '1px solid var(--divider)',
                      '&:hover': {
                        backgroundColor: 'var(--hover-overlay)'
                      }
                    }}>
                      <LocationOnIcon sx={{ color: 'var(--error-main)' }} />
                      <Box>
                        <Typography variant="caption" sx={{ color: 'var(--text-secondary)' }}>
                          Address
                        </Typography>
                        <Typography variant="body2" sx={{ color: 'var(--text-primary)' }}>
                          {invoice?.customer_details?.address || 'No address provided'}
                          {invoice?.customer_details?.city && `, ${invoice.customer_details.city}`}
                          {invoice?.customer_details?.state && `, ${invoice.customer_details.state}`}
                          {invoice?.customer_details?.zip_code && ` ${invoice.customer_details.zip_code}`}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </div>
              </Box>

              <Box sx={{ 
                backgroundColor: 'var(--background-paper)',
                borderRadius: 2,
                p: 3,
                mb: 3,
                border: '1px solid var(--divider)'
              }}>
                <TagsSection 
                  entityType="invoices"
                  entityId={id}
                  onTagsUpdate={() => {
                    fetchInvoice();
                  }}
                />
              </Box>
            </Grid>

            <Grid item xs={12} md={8}>
              <Box sx={{ 
                backgroundColor: 'var(--background-paper)',
                borderRadius: 1,
                p: 3,
                border: '1px solid var(--divider)'
              }}>
                <div className="invoice-details__template" style={{ 
                }}>
                  {renderInvoiceTemplate()}
                </div>
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Snackbar
          open={snackbar.open}
          autoHideDuration={3000}
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          TransitionComponent={(props) => <Slide {...props} direction="up" />}
        >
          <Alert 
            onClose={() => setSnackbar({ ...snackbar, open: false })} 
            severity={snackbar.severity}
            variant="filled"
            sx={{ 
              minWidth: '200px',
              borderRadius: '8px',
              boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
              '& .MuiAlert-message': {
                fontSize: '0.95rem',
                fontWeight: 500
              }
            }}
          >
            {snackbar.message}
          </Alert>
        </Snackbar>
      </div>
    </ThemeProvider>
  );
};

export default AdminInvoiceDetails;


