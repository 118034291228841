import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Box, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { NAV_ITEMS } from './navItems';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import { useAuth } from '../AuthContext';
import styles from './DesktopNav.module.scss';
import classNames from 'classnames';

const DesktopNav = () => {
  const location = useLocation();
  const { logout } = useAuth();

  return (
    <Box
      component="nav"
      className={styles.desktopNav}
    >
      <List>
        {NAV_ITEMS.map((item) => (
          <ListItem
            key={item.text}
            component={Link}
            to={item.path}
            className={classNames(styles.navItem, {
              [styles.active]: location.pathname === item.path
            })}
          >
            <ListItemIcon className={styles.icon}>
              {item.icon}
            </ListItemIcon>
            <ListItemText 
              primary={item.text}
              className={styles.linkText}
            />
          </ListItem>
        ))}
        <ListItem
          onClick={logout}
          className={styles.navItem}
        >
          <ListItemIcon className={styles.icon}>
            <LogoutOutlinedIcon />
          </ListItemIcon>
          <ListItemText 
            primary="Logout"
            className={styles.linkText}
          />
        </ListItem>
      </List>
    </Box>
  );
};

export default DesktopNav; 