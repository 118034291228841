import React, { useState, useEffect, useRef } from 'react';
import { Box, IconButton, Slider, Typography } from '@mui/material';
import { 
  PlayArrow, 
  Pause, 
  FastForward, 
  FastRewind 
} from '@mui/icons-material';

const AudioPlayer = ({ onTimeUpdate }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const timerRef = useRef(null);
  const TOTAL_DURATION = 20; // Total duration in seconds for mock transcript

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds.toString().padStart(2, '0')}`;
  };

  useEffect(() => {
    if (isPlaying) {
      timerRef.current = setInterval(() => {
        setCurrentTime(prev => {
          const newTime = prev + 1;
          if (newTime >= TOTAL_DURATION) {
            setIsPlaying(false);
            clearInterval(timerRef.current);
            return TOTAL_DURATION;
          }
          onTimeUpdate(newTime);
          return newTime;
        });
      }, 1000);
    } else {
      clearInterval(timerRef.current);
    }

    return () => clearInterval(timerRef.current);
  }, [isPlaying, onTimeUpdate]);

  const handlePlayPause = () => {
    setIsPlaying(!isPlaying);
  };

  const handleSliderChange = (_, newValue) => {
    setCurrentTime(newValue);
    onTimeUpdate(newValue);
  };

  const handleSkip = (seconds) => {
    setCurrentTime(prev => {
      const newTime = Math.max(0, Math.min(prev + seconds, TOTAL_DURATION));
      onTimeUpdate(newTime);
      return newTime;
    });
  };

  return (
    <Box sx={{ 
      p: 2,
      display: 'flex',
      alignItems: 'center',
      gap: 2 
    }}>
      <IconButton onClick={() => handleSkip(-10)} size="small">
        <FastRewind />
      </IconButton>

      <IconButton onClick={handlePlayPause} size="large">
        {isPlaying ? <Pause /> : <PlayArrow />}
      </IconButton>

      <IconButton onClick={() => handleSkip(10)} size="small">
        <FastForward />
      </IconButton>

      <Typography variant="caption" sx={{ minWidth: 60 }}>
        {formatTime(currentTime)}
      </Typography>

      <Slider
        value={currentTime}
        onChange={handleSliderChange}
        max={TOTAL_DURATION}
        sx={{ flex: 1 }}
      />

      <Typography variant="caption" sx={{ minWidth: 60 }}>
        {formatTime(TOTAL_DURATION)}
      </Typography>
    </Box>
  );
};

export default AudioPlayer; 