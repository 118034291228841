import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  IconButton,
  Tabs,
  Tab,
  InputAdornment,
  OutlinedInput,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Snackbar,
  Grid,
  Paper,
  Alert
} from '@mui/material';
import { Add, Edit, Delete, Inventory2, MenuBook, HandymanOutlined, Close, QrCode2, Store, AttachMoney, PriceChange, TrendingUp, Label, Inventory, LocalShipping } from '@mui/icons-material';
import SavedItemsManagerSettings from './settings/SavedItemsManagerSettings';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import { getPriceBookItems, createPriceBookItem, updatePriceBookItem, deletePriceBookItem } from '../services/api';
import PricingConfigurator from './pricing/PricingConfigurator';
import PackagesManager from './packages/PackagesManager';

const ItemDetailsDialog = ({ item, open, onClose, onEdit }) => {
  if (!item) return null;

  const detailSections = [
    {
      label: 'Basic Information',
      fields: [
        { label: 'Title', value: item.title, icon: <MenuBook sx={{ color: '#2196F3' }}/> },
        { label: 'Model Number', value: item.model_number || '-', icon: <QrCode2 sx={{ color: '#4CAF50' }}/> },
        { label: 'Brand', value: item.brand || '-', icon: <Label sx={{ color: '#FF9800' }}/> },
      ]
    },
    {
      label: 'Pricing Details',
      fields: [
        { 
          label: 'Price', 
          value: `$${parseFloat(item.price).toFixed(2)}`,
          icon: <AttachMoney sx={{ color: '#66BB6A' }}/> 
        },
        { 
          label: 'Unit Cost', 
          value: `$${parseFloat(item.unit_cost).toFixed(2)}`,
          icon: <PriceChange sx={{ color: '#26A69A' }}/> 
        },
        { 
          label: 'Profit Margin', 
          value: `${(((item.price - item.unit_cost) / item.price) * 100).toFixed(1)}%`,
          icon: <TrendingUp sx={{ color: '#EC407A' }}/> 
        },
      ]
    },
    {
      label: 'Inventory Status',
      fields: [
        { 
          label: 'Current Stock', 
          value: item.count, 
          icon: <Inventory2 sx={{ color: '#7E57C2' }}/> 
        },
        { 
          label: 'Dealer', 
          value: item.dealer || '-',
          icon: <Store sx={{ color: '#FF7043' }}/> 
        }
      ]
    }
  ];

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      PaperProps={{
        sx: {
          backgroundImage: 'none',
          borderRadius: '12px',
        }
      }}
    >
      <DialogTitle 
        sx={{ 
          color: 'var(--text-primary)',
          borderBottom: '1px solid var(--divider)',
          padding: '20px 24px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          backgroundColor: 'var(--background-paper)',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, }}>
          <Typography variant="h6" sx={{ fontFamily: 'Urbanist', fontWeight: 600 }}>
            Item Details
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', gap: 1 }}>
          <IconButton 
            onClick={() => onEdit(item)}
            sx={{ 
              color: '#2196F3',
              '&:hover': {
                backgroundColor: 'rgba(33, 150, 243, 0.1)',
              }
            }}
          >
            <Edit />
          </IconButton>
          <IconButton 
            onClick={onClose}
            sx={{ 
              color: 'var(--text-secondary)',
              '&:hover': {
                backgroundColor: 'var(--action-hover)',
              }
            }}
          >
            <Close />
          </IconButton>
        </Box>
      </DialogTitle>

      <DialogContent sx={{ 
        backgroundColor: 'var(--background-paper)',
        color: 'var(--text-primary)',
        padding: '24px'
      }}>
        {detailSections.map((section, index) => (
          <Box key={section.label} sx={{ mb: index !== detailSections.length - 1 ? 4 : 0 }}>
            <Typography 
              variant="subtitle1" 
              sx={{ 
                color: 'var(--text-secondary)',
                fontWeight: 600,
                mb: 2,
                fontFamily: 'Urbanist',
              }}
            >
              {section.label}
            </Typography>
            <Grid container spacing={3}>
              {section.fields.map((field) => (
                <Grid item xs={12} sm={6} md={4} key={field.label}>
                  <Paper
                    elevation={0}
                    sx={{
                      p: 2,
                      backgroundColor: 'var(--background-default)',
                      border: '1px solid var(--divider)',
                      borderRadius: '8px',
                      height: '100%',
                    }}
                  >
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 1, gap: 1 }}>
                      {field.icon}
                      <Typography 
                        variant="caption" 
                        sx={{ 
                          color: 'var(--text-secondary)',
                          fontWeight: 500,
                          fontFamily: 'Urbanist',
                        }}
                      >
                        {field.label}
                      </Typography>
                    </Box>
                    <Typography 
                      variant="body1" 
                      sx={{ 
                        color: 'var(--text-primary)',
                        fontWeight: 600,
                        fontFamily: 'Urbanist',
                      }}
                    >
                      {field.value}
                    </Typography>
                  </Paper>
                </Grid>
              ))}
            </Grid>
          </Box>
        ))}

        {item.description && (
          <Box sx={{ mt: 4 }}>
            <Typography 
              variant="subtitle1" 
              sx={{ 
                color: 'var(--text-secondary)',
                fontWeight: 600,
                mb: 2,
                fontFamily: 'Urbanist',
              }}
            >
              Description
            </Typography>
            <Paper
              elevation={0}
              sx={{
                p: 2,
                backgroundColor: 'var(--background-default)',
                border: '1px solid var(--divider)',
                borderRadius: '8px',
              }}
            >
              <Typography 
                variant="body2" 
                sx={{ 
                  color: 'var(--text-primary)',
                  whiteSpace: 'pre-wrap',
                  fontFamily: 'Urbanist',
                }}
              >
                {item.description || 'No description provided'}
              </Typography>
            </Paper>
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
};

const PriceBook = () => {
  const [activeTab, setActiveTab] = useState('inventory');
  const [items, setItems] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [detailsModalOpen, setDetailsModalOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [detailsOpen, setDetailsOpen] = useState(false);

  const inputStyle = {
    width: '100%',
    height: '45px',
    padding: '8px 12px',
    fontSize: '0.95rem',
    color: 'var(--text-primary)',
    backgroundColor: 'var(--background-paper)',
    border: '1px solid var(--divider)',
    borderRadius: '8px',
    outline: 'none',
    transition: 'all 0.2s ease-in-out',
    '&:hover': {
      borderColor: 'var(--primary-light)',
    },
    '&:focus': {
      borderColor: 'var(--primary-main)',
      borderWidth: '2px',
    },
  };

  const customInputStyle = {
    '& .MuiFilledInput-root': {
      backgroundColor: 'var(--background-paper)',
      border: '1px solid var(--divider)',
      borderRadius: '8px',
      padding: '8px 12px',
      
      // Remove all default MUI styling
      '&:before, &:after': {
        display: 'none'
      },
      
      // Clean hover state
      '&:hover': {
        backgroundColor: 'var(--background-paper)',
        border: '1px solid var(--primary-main)',
      },
      
      // Clean focused state
      '&.Mui-focused': {
        backgroundColor: 'var(--background-paper)',
        border: '2px solid var(--primary-main)',
      }
    },

    // Clean input styling
    '& .MuiFilledInput-input': {
      padding: '8px 0',
      backgroundColor: 'var(--background-paper)',
    },

    // Make the dollar signs prominent
    '& .MuiInputAdornment-root': {
      '& .MuiTypography-root': {
        fontSize: '1.25rem',
        fontWeight: 600,
        color: 'var(--text-primary)'
      }
    },

    // Remove label animations
    '& .MuiInputLabel-root': {
      position: 'static',
      transform: 'none',
      marginBottom: '8px'
    }
  };

  const [currentItem, setCurrentItem] = useState({
    title: '',
    model_number: '',
    brand: '',
    description: '',
    dealer: '',
    price: '',
    unit_cost: '',
    count: 0,
    name: '',
    id: null,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCurrentItem(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleEdit = (item) => {
    setCurrentItem(item);
    setIsEditing(true);
    setOpenDialog(true);
  };

  const handleSave = async () => {
    try {
      const itemData = {
        ...currentItem,
        name: currentItem.title,
        price: parseFloat(currentItem.price || 0),
        unit_cost: parseFloat(currentItem.unit_cost || 0),
        description: currentItem.description || ''
      };

      if (isEditing) {
        await updatePriceBookItem(selectedItem.id, itemData);
      } else {
        await createPriceBookItem(itemData);
      }
      
      // Refresh items list
      const response = await getPriceBookItems();
      const formattedItems = response.map(item => ({
        ...item,
        name: item.title,
        id: item.id,
        price: parseFloat(item.price || 0),
        unit_cost: parseFloat(item.unit_cost || 0),
        description: item.description || ''
      }));
      setItems(formattedItems);
      
      setOpenDialog(false);
      setSnackbarMessage(`Item ${isEditing ? 'updated' : 'created'} successfully`);
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Error saving item:', error);
      setSnackbarMessage('Failed to save item');
      setSnackbarOpen(true);
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const fetchItems = async () => {
    try {
      const data = await getPriceBookItems();
      setItems(data);
    } catch (error) {
      console.error('Error fetching items:', error);
    }
  };

  useEffect(() => {
    fetchItems();
  }, []);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case 'inventory':
        return renderInventoryTab();
      case 'priceBook':
        return renderPriceBookTab();
      case 'serviceItems':
        return <SavedItemsManagerSettings />;
      case 'packages':
        return renderPackagesTab();
      default:
        return null;
    }
  };

  const renderInventoryTab = () => {
    return (
      <Box>
        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'center',
          mb: 3 
        }}>
          <Typography variant="h6" sx={{ color: 'var(--text-primary)' }}>
            Inventory Management
          </Typography>
          <Button
            variant="contained"
            startIcon={<Add />}
            onClick={() => setOpenDialog(true)}
            sx={{
              backgroundColor: 'var(--primary-main)',
              color: 'var(--text-primary)',
              fontFamily: 'Urbanist',
              fontWeight: 500,
              textTransform: 'none',
              borderRadius: '8px',
              padding: '8px 16px',
              '&:hover': {
                backgroundColor: 'var(--primary-dark)',
              }
            }}
          >
            Add Item
          </Button>
        </Box>

        <TableContainer sx={{ 
          backgroundColor: 'var(--background-paper)',
          borderRadius: 1,
          boxShadow: '0 1px 3px rgba(0,0,0,0.12)',
        }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ color: 'var(--text-secondary)', fontWeight: 600 }}>Title</TableCell>
                <TableCell sx={{ color: 'var(--text-secondary)', fontWeight: 600 }}>Model Number</TableCell>
                <TableCell sx={{ color: 'var(--text-secondary)', fontWeight: 600 }}>Brand</TableCell>
                <TableCell sx={{ color: 'var(--text-secondary)', fontWeight: 600 }}>Dealer</TableCell>
                <TableCell sx={{ color: 'var(--text-secondary)', fontWeight: 600 }} align="right">Price</TableCell>
                <TableCell sx={{ color: 'var(--text-secondary)', fontWeight: 600 }} align="right">Unit Cost</TableCell>
                <TableCell sx={{ color: 'var(--text-secondary)', fontWeight: 600 }} align="center">Count</TableCell>
                <TableCell sx={{ color: 'var(--text-secondary)', fontWeight: 600 }} align="center">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items.map((item) => (
                <TableRow 
                  key={item.id}
                  onClick={() => handleRowClick(item)}
                  sx={{ 
                    cursor: 'pointer',
                    '&:hover': { 
                      backgroundColor: 'var(--background-hover)'
                    },
                    '& td': {
                      borderBottom: '1px solid var(--divider)'
                    }
                  }}
                >
                  <TableCell sx={{ color: 'var(--text-primary)', padding: '16px' }}>
                    {item.title}
                  </TableCell>
                  <TableCell sx={{ color: 'var(--text-primary)', padding: '16px' }}>
                    {item.model_number}
                  </TableCell>
                  <TableCell sx={{ color: 'var(--text-primary)', padding: '16px' }}>
                    {item.brand}
                  </TableCell>
                  <TableCell sx={{ color: 'var(--text-primary)', padding: '16px' }}>
                    {item.dealer}
                  </TableCell>
                  <TableCell sx={{ color: 'var(--text-primary)', padding: '16px' }} align="right">
                    ${item.price}
                  </TableCell>
                  <TableCell sx={{ color: 'var(--text-primary)', padding: '16px' }} align="right">
                    ${item.unit_cost}
                  </TableCell>
                  <TableCell sx={{ color: 'var(--text-primary)', padding: '16px' }} align="center">
                    {item.count}
                  </TableCell>
                  <TableCell align="center" sx={{ whiteSpace: 'nowrap' }}>
                    <IconButton 
                      onClick={(e) => {
                        e.stopPropagation();
                        handleEdit(item);
                      }}
                      size="small"
                      sx={{ 
                        color: '#2196F3',
                        '&:hover': {
                          backgroundColor: 'rgba(33, 150, 243, 0.1)',
                          color: '#1976D2'
                        }
                      }}
                    >
                      <Edit fontSize="small" />
                    </IconButton>
                    <IconButton 
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDelete(item.id);
                      }}
                      size="small"
                      sx={{ 
                        color: '#F44336',
                        ml: 1,
                        '&:hover': {
                          backgroundColor: 'rgba(244, 67, 54, 0.1)',
                          color: '#D32F2F'
                        }
                      }}
                    >
                      <Delete fontSize="small" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        {/* Add/Edit Dialog */}
        <Dialog 
          open={openDialog} 
          onClose={() => {
            setOpenDialog(false);
            setIsEditing(false);
            setCurrentItem({
              title: '',
              model_number: '',
              brand: '',
              description: '',
              dealer: '',
              price: '',
              unit_cost: '',
              count: 0,
            });
          }}
          maxWidth="sm"
          fullWidth
          PaperProps={{
            sx: {
              backgroundColor: 'var(--background-paper)',
              backgroundImage: 'none',
            }
          }}
        >
          <DialogTitle sx={{ 
            color: 'var(--text-primary)',
            borderBottom: '1px solid var(--divider)'
          }}>
            {isEditing ? 'Edit Item' : 'Add New Item'}
          </DialogTitle>
          <DialogContent sx={{ 
            backgroundColor: 'var(--background-paper)',
            color: 'var(--text-primary)',
            pt: 3
          }}>
            <Box sx={{ 
              display: 'flex',
              flexDirection: 'column',
              gap: 2.5
            }}>
              <input
                type="text"
                name="title"
                placeholder="Title"
                value={currentItem.title}
                onChange={handleInputChange}
                style={inputStyle}
              />

              <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 2 }}>
                <input
                  type="text"
                  name="model_number"
                  placeholder="Model Number"
                  value={currentItem.model_number}
                  onChange={handleInputChange}
                  style={inputStyle}
                />
                <input
                  type="text"
                  name="brand"
                  placeholder="Brand"
                  value={currentItem.brand}
                  onChange={handleInputChange}
                  style={inputStyle}
                />
              </Box>

              <textarea
                name="description"
                placeholder="Description"
                value={currentItem.description}
                onChange={handleInputChange}
                style={{
                  ...inputStyle,
                  height: '90px',
                  resize: 'vertical',
                }}
              />

              <input
                type="text"
                name="dealer"
                placeholder="Dealer"
                value={currentItem.dealer}
                onChange={handleInputChange}
                style={inputStyle}
              />

              <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', gap: 2 }}>
                <Box sx={{ position: 'relative' }}>
                  <span style={{
                    position: 'absolute',
                    left: '12px',
                    top: '50%',
                    transform: 'translateY(-50%)',
                    color: 'var(--text-primary)',
                    fontSize: '1rem',
                    fontWeight: 500,
                    zIndex: 1,
                  }}>$</span>
                  <input
                    type="number"
                    name="price"
                    placeholder="Price"
                    value={currentItem.price}
                    onChange={handleInputChange}
                    style={{
                      ...inputStyle,
                      paddingLeft: '24px',
                    }}
                    min="0"
                    step="0.01"
                  />
                </Box>
                <Box sx={{ position: 'relative' }}>
                  <span style={{
                    position: 'absolute',
                    left: '12px',
                    top: '50%',
                    transform: 'translateY(-50%)',
                    color: 'var(--text-primary)',
                    fontSize: '1rem',
                    fontWeight: 500,
                    zIndex: 1,
                  }}>$</span>
                  <input
                    type="number"
                    name="unit_cost"
                    placeholder="Unit Cost"
                    value={currentItem.unit_cost}
                    onChange={handleInputChange}
                    style={{
                      ...inputStyle,
                      paddingLeft: '24px',
                    }}
                    min="0"
                    step="0.01"
                  />
                </Box>
                <input
                  type="number"
                  name="count"
                  placeholder="Count"
                  value={currentItem.count}
                  onChange={handleInputChange}
                  style={inputStyle}
                  min="0"
                />
              </Box>
            </Box>
          </DialogContent>
          <DialogActions sx={{ 
            borderTop: '1px solid var(--divider)',
            padding: 2,
          }}>
            <Button 
              onClick={() => {
                setOpenDialog(false);
                setIsEditing(false);
                setCurrentItem({
                  title: '',
                  model_number: '',
                  brand: '',
                  description: '',
                  dealer: '',
                  price: '',
                  unit_cost: '',
                  count: 0,
                });
              }}
              sx={{ 
                color: 'var(--text-secondary)',
                '&:hover': {
                  backgroundColor: '#1976D2',
                }
              }}
            >
              Cancel
            </Button>
            <Button 
              onClick={handleSave} 
              variant="contained"
              sx={{
                backgroundColor: '#2196F3',
                '&:hover': {
                  backgroundColor: '#1976D2',
                }
              }}
            >
              {isEditing ? 'Update' : 'Save'}
            </Button>
          </DialogActions>
        </Dialog>

        {/* Details Modal */}
        <ItemDetailsDialog
          item={selectedItem}
          open={detailsOpen}
          onClose={() => {
            setDetailsOpen(false);
            setSelectedItem(null);
          }}
          onEdit={(item) => {
            setDetailsOpen(false);
            handleEdit(item);
          }}
        />
      </Box>
    );
  };

  const renderPriceBookTab = () => {
    return (
      <Box sx={{ p: 3 }}>
        <PricingConfigurator />
      </Box>
    );
  };

  const renderPackagesTab = () => {
    return (
      <Box sx={{ p: 3 }}>
        <PackagesManager />
      </Box>
    );
  };

  const handleDelete = async (itemId) => {
    if (window.confirm('Are you sure you want to delete this item?')) {
      try {
        console.log('Deleting item with ID:', itemId);
        await deletePriceBookItem(itemId);
        
        await fetchItems();
        
        setSnackbarMessage('Item deleted successfully');
        setSnackbarOpen(true);
      } catch (error) {
        console.error('Error deleting item:', error);
        setSnackbarMessage('Error deleting item: ' + (error.response?.data?.message || error.message));
        setSnackbarOpen(true);
      }
    }
  };

  const handleRowClick = (item) => {
    setSelectedItem(item);
    setDetailsOpen(true);
  };

  const styles = {
    header: {
      borderBottom: '1px solid var(--divider)',
    },
    headerContent: {
      maxWidth: '1400px',
      margin: '0 auto',
    },
    titleRow: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: '24px',
    },
    tabsWrapper: {
      borderBottom: '1px solid var(--divider)',
      position: 'sticky',
      top: 0,
      zIndex: 1,
    },
    contentWrapper: {
      flex: 1,
      overflow: 'auto',
      backgroundColor: 'var(--background-default)',
      padding: '24px',
    }
  };

  // Add these color constants at the top of your file
  const tabColors = {
    inventory: '#2196F3',    // Blue
    priceBook: '#4CAF50',    // Green
    serviceItems: '#FF9800', // Orange
    packages: '#9C27B0'      // Purple
  };

  return (
    <Box sx={{ 
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: 'var(--background-secondary)',
    }}>
      {/* Header Section */}
      <Box sx={styles.header}>
        <Box sx={styles.headerContent}>
          <Alert 
            severity="info" 
            sx={{ 
              mb: 2,
              backgroundColor: 'var(--background-paper)',
              color: 'var(--text-primary)',
              border: '1px solid var(--divider)',
              '& .MuiAlert-icon': {
                visibility: 'hidden'
              },
              '& .MuiAlert-message': {
                width: '100%',
                textAlign: 'center',
                fontSize: '1rem',
                fontWeight: 500
              }
            }}
          >
            🚀 Implementation will be added in the next update ⚡
          </Alert>
          
          <Box sx={styles.titleRow}>
            <Typography 
              variant="h4" 
              sx={{ 
                fontWeight: 600,
                fontFamily: 'Urbanist',
                color: 'var(--text-primary)',
              }}
            >
              Price Book Management
            </Typography>
          </Box>
        </Box>
      </Box>

      {/* Tabs Section */}
      <Box sx={{ 
        width: '100%',
        position: 'static',
        zIndex: 1000
      }}>
        <Box sx={{ maxWidth: '1400px', margin: '0 auto' }}>
          <Tabs 
            value={activeTab}
            onChange={handleTabChange}
            variant="scrollable"
            scrollButtons="auto"
            allowScrollButtonsMobile
            sx={{
              '& .MuiTabs-indicator': {
                display: 'block',
                backgroundColor: activeTab === 'inventory' 
                  ? tabColors.inventory 
                  : activeTab === 'priceBook' 
                  ? tabColors.priceBook 
                  : activeTab === 'serviceItems' 
                  ? tabColors.serviceItems 
                  : tabColors.packages,
                height: '3px',
                borderRadius: '3px 3px 0 0',
                transition: 'all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
              },
              '& .MuiTab-root': {
                textTransform: 'none',
                fontSize: '0.95rem',
                fontFamily: 'Urbanist',
                fontWeight: 500,
                color: 'var(--text-secondary)',
                minHeight: 48,
                padding: '12px 24px',
                '& .MuiSvgIcon-root': {
                  marginBottom: '0',
                  marginRight: '8px',
                  transition: 'color 0.2s ease-in-out',
                },
                '&.Mui-selected': {
                  color: 'var(--text-primary)',
                  fontWeight: 600,
                },
                '&:hover': {
                  color: 'var(--text-primary)',
                  opacity: 0.8,
                },
                transition: 'color 0.2s ease-in-out',
              },
            }}
            TabIndicatorProps={{
              sx: {
                height: '3px',
                borderRadius: '3px 3px 0 0',
              }
            }}
          >
            <Tab 
              icon={<Inventory2 sx={{ 
                color: activeTab === 'inventory' ? tabColors.inventory : 'var(--text-secondary)',
                '&:hover': {
                  color: tabColors.inventory,
                }
              }} />}
              iconPosition="start"
              label="Inventory" 
              value="inventory"
              sx={{
                '&.Mui-selected': {
                  color: tabColors.inventory,
                },
                '&:hover': {
                  color: tabColors.inventory,
                }
              }}
            />
            <Tab 
              icon={<MenuBook sx={{ 
                color: activeTab === 'priceBook' ? tabColors.priceBook : 'var(--text-secondary)',
                '&:hover': {
                  color: tabColors.priceBook,
                }
              }} />}
              iconPosition="start"
              label="Price Book" 
              value="priceBook"
              sx={{
                '&.Mui-selected': {
                  color: tabColors.priceBook,
                },
                '&:hover': {
                  color: tabColors.priceBook,
                }
              }}
            />
            <Tab 
              icon={<HandymanOutlined sx={{ 
                color: activeTab === 'serviceItems' ? tabColors.serviceItems : 'var(--text-secondary)',
                '&:hover': {
                  color: tabColors.serviceItems,
                }
              }} />}
              iconPosition="start"
              label="Service Items" 
              value="serviceItems"
              sx={{
                '&.Mui-selected': {
                  color: tabColors.serviceItems,
                },
                '&:hover': {
                  color: tabColors.serviceItems,
                }
              }}
            />
            <Tab 
              icon={<LocalShipping sx={{ 
                color: activeTab === 'packages' ? tabColors.packages : 'var(--text-secondary)',
                '&:hover': {
                  color: tabColors.packages,
                }
              }} />}
              iconPosition="start"
              label="Packages" 
              value="packages"
              sx={{
                '&.Mui-selected': {
                  color: tabColors.packages,
                },
                '&:hover': {
                  color: tabColors.packages,
                }
              }}
            />
          </Tabs>
        </Box>
      </Box>

      {/* Content Area */}
      <Box sx={styles.contentWrapper}>
        <Box sx={{ 
          maxWidth: '1400px',
          margin: '0 auto',
          borderRadius: '8px',
          boxShadow: '0 1px 3px rgba(0,0,0,0.12)',
          overflow: 'hidden',
        }}>
          {renderTabContent()}
        </Box>
      </Box>

      {/* Dialogs remain unchanged */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        message={snackbarMessage}
      />
    </Box>
  );
};

export default PriceBook; 