import React from 'react';
import { 
  Box, 
  Paper, 
  Typography, 
  Avatar, 
  Divider, 
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar 
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { 
  Reply as ReplyIcon,
  Forward as ForwardIcon,
  Delete as DeleteIcon,
  Archive as ArchiveIcon,
  Email as EmailIcon 
} from '@mui/icons-material';

const StyledThreadContainer = styled(Paper)(({ theme }) => ({
  flex: 1,
  height: 'calc(100vh - 280px)',
  borderRadius: 12,
  display: 'flex',
  flexDirection: 'column',
  border: `1px solid ${theme.palette.divider}`,
  backgroundColor: theme.palette.background.default,
  overflow: 'hidden'
}));

const EmailThread = ({ contact, threads, onCompose }) => {
  if (!contact || threads.length === 0) {
    return (
      <StyledThreadContainer>
        <Box sx={{ p: 3, textAlign: 'center' }}>
          <Typography variant="body1" color="text.secondary">
            Select a conversation or start a new one
          </Typography>
        </Box>
      </StyledThreadContainer>
    );
  }

  const currentThread = threads[0]; // For now, showing the first thread

  return (
    <StyledThreadContainer>
      <Box sx={{ p: 2, borderBottom: 1, borderColor: 'divider', display: 'flex', justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <Avatar sx={{ bgcolor: '#1976d2' }}>
            <EmailIcon />
          </Avatar>
          <Typography variant="h6">{currentThread.subject}</Typography>
        </Box>
        <Box>
          <IconButton onClick={onCompose}><ReplyIcon /></IconButton>
          <IconButton><ForwardIcon /></IconButton>
          <IconButton><ArchiveIcon /></IconButton>
          <IconButton><DeleteIcon /></IconButton>
        </Box>
      </Box>
      <List sx={{ flex: 1, overflow: 'auto', p: 0 }}>
        {currentThread.messages.map((message, index) => (
          <React.Fragment key={message.id}>
            <ListItem 
              alignItems="flex-start"
              sx={{ 
                bgcolor: message.direction === 'received' ? 'background.default' : 'grey.50',
                p: 3
              }}
            >
              <ListItemAvatar>
                <Avatar>{message.sender[0]}</Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={
                  <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
                      {message.sender}
                    </Typography>
                    <Typography variant="caption" color="text.secondary">
                      {message.timestamp}
                    </Typography>
                  </Box>
                }
                secondary={
                  <Typography
                    variant="body1"
                    sx={{ mt: 1, color: 'text.primary' }}
                  >
                    {message.content}
                  </Typography>
                }
              />
            </ListItem>
            {index < currentThread.messages.length - 1 && <Divider />}
          </React.Fragment>
        ))}
      </List>
    </StyledThreadContainer>
  );
};

export default EmailThread;