import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { 
  Typography, 
  Paper, 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableRow,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  Snackbar,
  Alert,
  Box,
  CircularProgress,
  Tooltip,
  Avatar,
  Grid,
  Chip,
} from '@mui/material';
import { 
  Add as AddIcon, 
  Delete as DeleteIcon, 
  Visibility as VisibilityIcon,
  Search as SearchIcon,
  ArrowBack as ArrowBackIcon,
  CalendarToday as CalendarTodayIcon,
  AttachMoney as AttachMoneyIcon,
  LocationOn as LocationOnIcon,
  Assignment as AssignmentIcon,
  Engineering as EngineeringIcon,
  Receipt as ReceiptIcon,
} from '@mui/icons-material';
import { getProjectEvents, deleteEvent, getProjectDetails, updateEvent } from '../services/api';
import EventForm from './EventForm';
import EventDetails from './EventDetails';
import { format } from 'date-fns';
import styles from './Events.module.scss';
import UnscheduledEventsBanner from './UnscheduledEventsBanner';
import ScheduleEventsModal from './ScheduleEventsModal';

// Add this helper function at the top of the file
const capitalizeFirstLetter = (string) => {
  if (!string) return '';
  return string.charAt(0).toUpperCase() + string.slice(1).replace(/_/g, ' ');
};

const Events = ({ projectId, onEventUpdated }) => {
  // State management
  const [events, setEvents] = useState([]);
  const [filteredEvents, setFilteredEvents] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortBy, setSortBy] = useState('date');
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [selectedEventForEdit, setSelectedEventForEdit] = useState(null);
  const [isEventDetailsOpen, setIsEventDetailsOpen] = useState(false);
  const [selectedEventIdForDetails, setSelectedEventIdForDetails] = useState(null);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [projectName, setProjectName] = useState('');
  const [isScheduleModalOpen, setIsScheduleModalOpen] = useState(false);

  // Hooks
  const navigate = useNavigate();
  const location = useLocation();

  // API Calls
  const fetchProjectDetails = useCallback(async () => {
    if (!projectId) return;
    try {
      const response = await getProjectDetails(projectId);
      setProjectName(response.data.name || '');
    } catch (error) {
      console.error('Error fetching project details:', error);
      setError('Failed to fetch project details');
    }
  }, [projectId]);

  const fetchEvents = useCallback(async () => {
    if (!projectId) return;
    try {
      const fetchedEvents = await getProjectEvents(projectId);
      setEvents(fetchedEvents);
    } catch (error) {
      console.error('Error fetching events:', error);
      setError('Failed to fetch events');
    }
  }, [projectId]);

  // Effects
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        await Promise.all([fetchProjectDetails(), fetchEvents()]);
      } catch (error) {
        setError('Failed to fetch data');
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [fetchProjectDetails, fetchEvents]);

  useEffect(() => {
    filterAndSortEvents();
  }, [events, searchTerm, sortBy]);

  // Helper Functions
  const filterAndSortEvents = () => {
    let filtered = events.filter(event => 
      event.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      event.description.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const scheduledEvents = filtered.filter(event => event.time_slots?.length > 0);
    const unscheduledEvents = filtered.filter(event => !event.time_slots?.length);

    if (sortBy === 'pending_invoice') {
      // Move completed events without invoices to the top
      const pendingInvoice = filtered.filter(event => event.status === 'completed' && !event.invoice_id);
      const others = filtered.filter(event => !(event.status === 'completed' && !event.invoice_id));
      setFilteredEvents([...pendingInvoice, ...others]);
      return;
    }

    scheduledEvents.sort((a, b) => {
      if (sortBy === 'date') {
        return new Date(a.time_slots[0].start_time) - new Date(b.time_slots[0].start_time);
      }
      return a.title.localeCompare(b.title);
    });

    unscheduledEvents.sort((a, b) => a.title.localeCompare(b.title));
    setFilteredEvents([...scheduledEvents, ...unscheduledEvents]);
  };

  const formatDate = (dateString) => {
    return format(new Date(dateString), 'MMM d, yyyy h:mm a');
  };

  const getInitials = (name) => {
    if (!name) return '??';
    return name
      .split(' ')
      .filter(word => word.length > 0)
      .map(word => word[0])
      .join('')
      .toUpperCase()
      .slice(0, 2);
  };

  // Event Handlers
  const handleAddEvent = () => {
    setSelectedEventForEdit(null);
    setIsFormOpen(true);
  };

  const handleEditEvent = (event) => {
    setSelectedEventForEdit(event);
    setIsFormOpen(true);
  };

  const handleDeleteEvent = async (eventId) => {
    if (window.confirm('Are you sure you want to delete this event?')) {
      try {
        await deleteEvent(eventId);
        await fetchEvents();
        setSnackbar({ open: true, message: 'Event deleted successfully', severity: 'success' });
      } catch (error) {
        setSnackbar({ open: true, message: 'Failed to delete event', severity: 'error' });
      }
    }
  };

  const handleViewEventDetails = (eventId) => {
    setSelectedEventIdForDetails(eventId);
    setIsEventDetailsOpen(true);
  };

  const handleEventCreated = async () => {
    await fetchEvents();
    setIsFormOpen(false);
    setSnackbar({ open: true, message: 'Event created successfully', severity: 'success' });
  };

  const handleBack = () => {
    location.state?.from ? navigate(location.state.from) : navigate(-1);
  };

  // Add summary cards
  const summaryCards = (
    <Grid container spacing={3} className={styles.summaryContainer}>      
      <Grid item xs={12} sm={6} md={3}>
        <div className={styles.summaryCard}>
          <div className={styles.cardContent}>
            <div className={styles.iconWrapper} style={{ color: '#54D62C' }}>
              <AssignmentIcon />
            </div>
            <div className={styles.cardInfo}>
              <h3 className={styles.cardValue}>
                {events.filter(event => event.time_slots?.length > 0).length}
              </h3>
              <p className={styles.cardLabel}>Scheduled Events</p>
            </div>
          </div>
        </div>
      </Grid>

      <Grid item xs={12} sm={6} md={3}>
        <div className={styles.summaryCard}>
          <div className={styles.cardContent}>
            <div className={styles.iconWrapper} style={{ color: '#FFC107' }}>
              <AttachMoneyIcon />
            </div>
            <div className={styles.cardInfo}>
              <h3 className={styles.cardValue}>
                ${events.reduce((sum, event) => sum + (event.value || 0), 0).toLocaleString()}
              </h3>
              <p className={styles.cardLabel}>Total Value</p>
            </div>
          </div>
        </div>
      </Grid>

      <Grid item xs={12} sm={6} md={3}>
        <div className={styles.summaryCard}>
          <div className={styles.cardContent}>
            <div className={styles.iconWrapper} style={{ color: '#00AB55' }}>
              <EngineeringIcon />
            </div>
            <div className={styles.cardInfo}>
              <h3 className={styles.cardValue}>
                {new Set(events.flatMap(event => event.employees?.map(emp => emp.id) || [])).size}
              </h3>
              <p className={styles.cardLabel}>Assigned Employees</p>
            </div>
          </div>
        </div>
      </Grid>

      <Grid item xs={12} sm={6} md={3}>
        <div className={styles.summaryCard}>
          <div className={styles.cardContent}>
            <div className={styles.iconWrapper} style={{ color: '#7635dc' }}>
              <ReceiptIcon />
            </div>
            <div className={styles.cardInfo}>
              <h3 className={styles.cardValue}>
                {events.filter(event => 
                  event.status === 'completed' && !event.invoice_id
                ).length}
              </h3>
              <p className={styles.cardLabel}>Pending Invoice</p>
            </div>
          </div>
        </div>
      </Grid>
    </Grid>
  );

  // Update the employee avatar section in the event card
  const handleEmployeeClick = (e, employeeId) => {
    e.stopPropagation();
    navigate(`/admin/employees/${employeeId}`);
  };

  if (loading) return <CircularProgress className={styles.loader} />;
  if (error) return <Typography color="error" className={styles.error}>{error}</Typography>;

  return (
    <div className={styles.eventsContainer}>

      {/* Summary Cards */}
      {summaryCards}

      {/* Unscheduled Events Banner */}
      <UnscheduledEventsBanner 
        count={events.filter(event => !event.time_slots?.length).length}
        onClick={() => setIsScheduleModalOpen(true)}
      />

      {/* Schedule Events Modal */}
      <ScheduleEventsModal
        open={isScheduleModalOpen}
        onClose={() => setIsScheduleModalOpen(false)}
        events={events.filter(event => !event.time_slots?.length)}
        onSubmit={async (timeSlots) => {
          try {
            // Handle the scheduling
            await Promise.all(timeSlots.map(async (slot) => {
              const response = await updateEvent(slot.event_id, { time_slots: [slot] });
              return response;
            }));
            
            // Refresh events and show success message
            await fetchEvents();
            setSnackbar({ 
              open: true, 
              message: 'Events scheduled successfully', 
              severity: 'success' 
            });
          } catch (error) {
            console.error('Error scheduling events:', error);
            setSnackbar({ 
              open: true, 
              message: 'Failed to schedule events', 
              severity: 'error' 
            });
          }
        }}
      />

      {/* Controls Section */}
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
        <div className={styles.leftSection}>
          <div className={styles.searchContainer}>
            <SearchIcon className={styles.searchIcon} />
            <input
              type="text"
              placeholder="Search events..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className={styles.searchInput}
            />
          </div>
        </div>

        <div className={styles.rightSection}>
          <div className={styles.buttonGroup}>
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              onClick={handleAddEvent}
              className={styles.addButton}
            >
              Add Event
            </Button>
          </div>
        </div>
      </Box>

      {/* Events Table */}
      <div className={styles.eventListSection}>
        {filteredEvents.length === 0 ? (
          <p className={styles.message}>No events found.</p>
        ) : (
          <div className={styles.eventList}>
            {filteredEvents.map((event) => (
              <div 
                key={event.id} 
                className={styles.eventRow}
                onClick={() => handleViewEventDetails(event.id)}
              >
                <div className={styles.eventInfo}>
                  <h3 className={styles.eventTitle}>
                    {event.emoji || '📅'} {event.title}
                    <Chip
                      label={capitalizeFirstLetter(event.status)}
                      size="small"
                      sx={{
                        ml: 1,
                        height: '20px',
                        backgroundColor: event.status === 'completed' ? '#E8F5E9' : 
                                        event.status === 'in_progress' ? '#E3F2FD' :
                                        event.status === 'pending' ? '#FFF3E0' : '#F5F5F5',
                        color: event.status === 'completed' ? '#2E7D32' :
                               event.status === 'in_progress' ? '#1565C0' :
                               event.status === 'pending' ? '#E65100' : '#757575',
                        '& .MuiChip-label': {
                          px: 1,
                          fontSize: '0.75rem',
                          fontWeight: 500
                        }
                      }}
                    />
                  </h3>
                  <p className={styles.description}>
                    {event.project?.name || 'No Project'}
                  </p>
                </div>
                <div className={styles.eventDetails}>
                  <span className={styles.detailItem}>
                    <CalendarTodayIcon fontSize="small" />
                    {event.time_slots && event.time_slots.length > 0 ? (
                      `${formatDate(event.time_slots[0].start_time)} - ${formatDate(event.time_slots[0].end_time)}`
                    ) : (
                      'Not scheduled'
                    )}
                  </span>
                  <span className={styles.detailItem}>
                    <AttachMoneyIcon fontSize="small" />
                    ${event.value ? event.value.toLocaleString() : 'N/A'}
                  </span>
                  <span className={styles.detailItem}>
                    <LocationOnIcon fontSize="small" />
                    {event.addresses?.[0] || 'N/A'}
                  </span>
                  <span className={styles.detailItem}>
                    <div className={styles.avatarGroup}>
                      {Array.isArray(event?.employees) && event.employees.length > 0 ? (
                        <>
                          {event.employees.slice(0, 3).map((employee, index) => (
                            <Tooltip key={employee.id} title={employee.name}>
                              <Avatar 
                                className={styles.employeeAvatar}
                                onClick={(e) => handleEmployeeClick(e, employee.id)}
                                sx={{ 
                                  width: 24,
                                  height: 24,
                                  fontSize: '0.75rem',
                                  bgcolor: `hsl(${(index * 90) % 360}, 70%, 50%)`,
                                  border: '2px solid white',
                                  marginLeft: index > 0 ? '-8px' : 0,
                                  zIndex: 3 - index,
                                  cursor: 'pointer',
                                  '&:hover': {
                                    transform: 'scale(1.1)',
                                    transition: 'transform 0.2s'
                                  }
                                }}
                              >
                                {getInitials(employee.name)}
                              </Avatar>
                            </Tooltip>
                          ))}
                          {event.employees.length > 3 && (
                            <Tooltip title={`${event.employees.length - 3} more employees`}>
                              <Avatar 
                                className={styles.employeeAvatar}
                                sx={{ 
                                  width: 24,
                                  height: 24,
                                  fontSize: '0.75rem',
                                  bgcolor: 'grey.500',
                                  border: '2px solid white',
                                  marginLeft: '-8px',
                                  zIndex: 0,
                                  cursor: 'pointer'
                                }}
                              >
                                +{event.employees.length - 3}
                              </Avatar>
                            </Tooltip>
                          )}
                        </>
                      ) : (
                        <Tooltip title="No employees assigned">
                          <Avatar 
                            className={styles.employeeAvatar}
                            sx={{ 
                              width: 24,
                              height: 24,
                              fontSize: '0.75rem',
                              bgcolor: 'grey.400',
                              border: '2px solid white'
                            }}
                          >
                            0
                          </Avatar>
                        </Tooltip>
                      )}
                    </div>
                  </span>
                </div>
                <div className={styles.actionButtons}>
                  <IconButton 
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDeleteEvent(event.id);
                    }} 
                    color="error"
                  >
                    <DeleteIcon />
                  </IconButton>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>

      {/* Modals and Snackbar */}
      <EventForm
        open={isFormOpen}
        onClose={() => setIsFormOpen(false)}
        projectId={projectId}
        onEventCreated={handleEventCreated}
        event={selectedEventForEdit}
      />
      <EventDetails
        eventId={selectedEventIdForDetails}
        open={isEventDetailsOpen}
        onClose={() => setIsEventDetailsOpen(false)}
        onEdit={handleEditEvent}
        onEventUpdated={() => {
          fetchEvents();  // Refresh events list
          if (onEventUpdated) onEventUpdated();  // Propagate to parent
        }}
      />
      <Snackbar 
        open={snackbar.open} 
        autoHideDuration={6000} 
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default Events;
  