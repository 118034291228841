import React, { useState, useEffect } from 'react';
import { Chip, CircularProgress } from '@mui/material';
import { getProjectStatus } from '../services/api';

// Define statuses in lowercase to match incoming data
const PROJECT_STATUSES = {
  PENDING_SCHEDULING: 'pending_scheduling',
  SCHEDULED: 'scheduled',
  IN_PROGRESS: 'in_progress',
  PENDING_INVOICING: 'pending_invoicing',
  COMPLETED: 'completed'
};

const STATUS_STYLES = {
  'pending_scheduling': {
    color: '#E65100',
    backgroundColor: '#FFF3E0'
  },
  'scheduled': {
    color: '#1565C0',
    backgroundColor: '#E3F2FD'
  },
  'in_progress': {
    color: '#2E7D32',
    backgroundColor: '#E8F5E9'
  },
  'pending_invoicing': {
    color: '#7B1FA2',
    backgroundColor: '#F3E5F5'
  },
  'completed': {
    color: '#303F9F',
    backgroundColor: '#E8EAF6'
  }
};

const ProjectStatusBadge = ({ project, shouldRefresh }) => {
  const [status, setStatus] = useState(project?.status || PROJECT_STATUSES.PENDING_SCHEDULING);
  const [loading, setLoading] = useState(false);

  console.log('ProjectStatusBadge - Raw project:', project);
  console.log('ProjectStatusBadge - Raw status:', project?.status);
  console.log('ProjectStatusBadge - State status:', status);

  const normalizedStatus = status?.toLowerCase()?.replace(/ /g, '_');
  console.log('ProjectStatusBadge - Normalized status:', normalizedStatus);
  console.log('ProjectStatusBadge - Style found:', STATUS_STYLES[normalizedStatus]);

  const fetchStatus = async () => {
    if (project?.id) {
      setLoading(true);
      try {
        const response = await getProjectStatus(project.id);
        if (response.status) {
          console.log('Status updated:', response.status);
          setStatus(response.status);
        }
      } catch (error) {
        console.error('Error fetching project status:', error);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    if (project?.status) {
      setStatus(project.status);
    }
  }, [project?.status]);

  useEffect(() => {
    fetchStatus();
  }, [project?.id, shouldRefresh]);

  if (loading) {
    return <CircularProgress size={24} />;
  }

  const statusStyle = STATUS_STYLES[normalizedStatus] || STATUS_STYLES[PROJECT_STATUSES.PENDING_SCHEDULING];
  
  return (
    <Chip
      label={status.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}
      sx={{
        backgroundColor: statusStyle.backgroundColor || '#E3F2FD',
        color: statusStyle.color || '#1565C0',
        fontWeight: 500,
        '&:hover': {
          backgroundColor: statusStyle.backgroundColor || '#E3F2FD',
          opacity: 0.9
        }
      }}
    />
  );
};

export default ProjectStatusBadge;