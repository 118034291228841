import React, { useState, useEffect } from 'react';
import { 
  Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, 
  CircularProgress, Select, MenuItem, FormControl, Box, Grid, Card, CardContent,
  TextField, Button, IconButton, Tooltip
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { getAdminTimesheets, exportTimesheetsCSV } from '../services/api';
import { 
  AccessTime, EventNote, AttachMoney, WorkOutline, 
  CalendarToday, HourglassEmpty, CheckCircleOutline, Person, Event
} from '@mui/icons-material';
import styles from './Timesheets.module.scss';
import { useNavigate } from 'react-router-dom';
import { Visibility as VisibilityIcon } from '@mui/icons-material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { formatDateTime, getStartOfDay, getEndOfDay } from '../utils/dateUtils';
import NoTimesheetsBanner from './NoTimesheetsBanner';

const Timesheets = ({ projectId }) => {
  const [timesheets, setTimesheets] = useState([]);
  const [summary, setSummary] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [projectFilter, setProjectFilter] = useState('All');
  const [employeeFilter, setEmployeeFilter] = useState('All');
  const [eventFilter, setEventFilter] = useState('All');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [projects, setProjects] = useState(['All']);
  const [employees, setEmployees] = useState(['All']);
  const [events, setEvents] = useState(['All']);
  const navigate = useNavigate();

  useEffect(() => {
    fetchTimesheets();
  }, [projectId]);

  const fetchTimesheets = async () => {
    try {
      setLoading(true);
      const data = await getAdminTimesheets();
      console.log('Raw response from server:', data);

      if (data && Array.isArray(data.timesheets)) {
        setTimesheets(data.timesheets);
        setSummary(data.summary);
        
        // Extract unique values for filters
        const uniqueProjects = [...new Set(data.timesheets.map(ts => 
          ts.event?.project_name || 'Unassigned'))];
        const uniqueEmployees = [...new Set(data.timesheets.map(ts => 
          ts.employee?.name || 'Unassigned'))];
        const uniqueEvents = [...new Set(data.timesheets.map(ts => 
          ts.event?.title || 'Unassigned'))];
        
        const projectsList = ['All', ...uniqueProjects];
        setProjects(projectsList);
        setEmployees(['All', ...uniqueEmployees]);
        setEvents(['All', ...uniqueEvents]);

        // If projectId exists, find and set the matching project filter
        if (projectId) {
          const matchingTimesheet = data.timesheets.find(ts => 
            String(ts.event?.project_id) === String(projectId)
          );
          if (matchingTimesheet?.event?.project_name) {
            const projectName = matchingTimesheet.event.project_name;
            // Verify the project name exists in our list before setting it
            if (projectsList.includes(projectName)) {
              console.log('Setting project filter to:', projectName);
              setProjectFilter(projectName);
            }
          }
        }
      } else {
        throw new Error('Invalid data received from server');
      }
      setError(null);
    } catch (err) {
      console.error('Error fetching timesheets:', err);
      setError('Failed to fetch timesheets. Please try again later.');
      setTimesheets([]);
      setSummary(null);
    } finally {
      setLoading(false);
    }
  };

  const filteredTimesheets = timesheets.filter(timesheet => {
    const projectMatch = projectFilter === 'All' || timesheet.event.project_name === projectFilter;
    const employeeMatch = employeeFilter === 'All' || timesheet.employee.name === employeeFilter;
    const eventMatch = eventFilter === 'All' || timesheet.event.title === eventFilter;
    
    const clockInDate = new Date(timesheet.clock_in);
    const dateMatch = (!startDate || clockInDate >= getStartOfDay(startDate)) &&
                     (!endDate || clockInDate <= getEndOfDay(endDate));
    
    return projectMatch && employeeMatch && eventMatch && dateMatch;
  });

  const totalHoursWorked = filteredTimesheets.reduce((sum, ts) => sum + (ts.hours_worked || 0), 0);
  const totalCalculatedPayment = filteredTimesheets.reduce((sum, ts) => sum + (ts.calculated_payment || 0), 0);

  const handleViewDetails = (timesheetId) => {
    console.log('Attempting to navigate to timesheet:', timesheetId);
    console.log('Current location:', window.location.pathname);
    navigate(`/admin/timesheets/${timesheetId}`);
    console.log('Navigation completed');
  };

  const handleExport = async () => {
    try {
      console.log('Export button clicked');
      console.log('Filters:', {
        project: projectFilter,
        employee: employeeFilter,
        event: eventFilter,
        startDate,
        endDate
      });
      
      await exportTimesheetsCSV({
        project: projectFilter,
        employee: employeeFilter,
        event: eventFilter,
        startDate,
        endDate
      });
      console.log('Export completed');
    } catch (error) {
      console.error('Failed to export timesheets:', error);
    }
  };

  const hasProjectTimesheets = projectId ? 
    timesheets.some(ts => String(ts.event?.project_id) === String(projectId)) : 
    true;

  if (loading) return <div className={styles.loadingContainer}><CircularProgress /></div>;
  if (error) return <div className={styles.errorContainer}><Typography color="error">{error}</Typography></div>;
  if (timesheets.length === 0) return <div className={styles.errorContainer}><Typography>No timesheets found.</Typography></div>;

  const headerCells = [
    { icon: <AccessTime />, label: 'Clock In', color: '#3498db' },
    { icon: <EventNote />, label: 'Clock Out', color: '#e74c3c' },
    { icon: <HourglassEmpty />, label: 'Hours Worked', color: '#f39c12' },
    { icon: <WorkOutline />, label: 'Project', color: '#9b59b6' },
    { icon: <Person />, label: 'Employee', color: '#34495e' },
    { icon: <Event />, label: 'Event', color: '#16a085' },
    { icon: <AttachMoney />, label: 'Payment Type', color: '#2ecc71' },
    { icon: <CalendarToday />, label: 'Rate/Percentage', color: '#1abc9c' },
    { icon: <CheckCircleOutline />, label: 'Calculated Payment', color: '#2ecc71' },
    { icon: <VisibilityIcon />, label: 'Actions', color: '#607d8b' },
  ];

  return (
    <div className={styles.timesheetsContainer}>
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center',
        mb: 3 
      }}>
        <h1 className={styles.timesheetsTitle}>Timesheets</h1>
        
        {/* Export Button */}
        <Button
          variant="contained"
          color="primary"
          startIcon={<FileDownloadIcon />}
          onClick={handleExport}
          sx={{ 
            height: '40px',
            backgroundColor: '#1976d2',
            '&:hover': {
              backgroundColor: '#115293'
            },
            textTransform: 'none',
            borderRadius: '8px',
            boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
          }}
        >
          Export CSV
        </Button>
      </Box>

      {/* Move NoTimesheetsBanner here, right after the header */}
      {projectId && !hasProjectTimesheets && (
        <NoTimesheetsBanner 
          projectName={timesheets.find(ts => 
            String(ts.event?.project_id) === String(projectId)
          )?.event?.project_name || 'this project'} 
        />
      )}

      {/* Summary Cards */}
      {summary && (
        <Grid container spacing={3} className={styles.summaryContainer}>
          <Grid item xs={12} md={6}>
            <Card className={styles.summaryCard} sx={{ backgroundColor: 'var(--background-secondary)' }}>
              <CardContent>
                <Typography variant="h6" gutterBottom className={styles.summaryTitle} sx={{ color: 'var(--text-primary) !important' }}>
                  <HourglassEmpty className={styles.summaryIcon} />
                  Total Hours Worked
                </Typography>
                <Typography variant="h4" sx={{ color: 'var(--text-primary) !important' }}>{totalHoursWorked.toFixed(2)}</Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card className={styles.summaryCard} sx={{ backgroundColor: 'var(--background-secondary)' }}>
              <CardContent>
                <Typography variant="h6" gutterBottom className={styles.summaryTitle} sx={{ color: 'var(--text-primary) !important' }}>
                  <AttachMoney className={styles.summaryIcon} />
                  Total Calculated Payment
                </Typography>
                <Typography variant="h4" sx={{ color: 'var(--text-primary) !important' }}>${totalCalculatedPayment.toFixed(2)}</Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      )}

      {/* Filters */}
      <Box sx={{ 
        display: 'flex', 
        alignItems: 'flex-end',
        gap: 2,
        mb: 3,
        flexWrap: 'nowrap',  // Prevent wrapping
        overflowX: 'auto',   // Allow horizontal scroll if needed
        pb: 1               // Add padding bottom for scroll bar
      }}>
        {/* Project Filter */}
        <FormControl sx={{ minWidth: 150 }}>
          <Typography variant="subtitle2" sx={{ color: 'var(--text-primary)' }}>Project</Typography>
          <Select
            value={projectFilter}
            onChange={(e) => setProjectFilter(e.target.value)}
            size="small"
            sx={{ 
              height: '40px',
              color: 'var(--text-primary)',
              '& .MuiSelect-icon': {
                color: 'var(--text-primary)'
              }
            }}
          >
            {projects.map((project) => (
              <MenuItem key={project} value={project}>{project}</MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* Employee Filter */}
        <FormControl sx={{ minWidth: 150 }}>
          <Typography variant="subtitle2" sx={{ color: 'var(--text-primary)' }}>Employee</Typography>
          <Select
            value={employeeFilter}
            onChange={(e) => setEmployeeFilter(e.target.value)}
            size="small"
            sx={{ 
              height: '40px',
              color: 'var(--text-primary)',
              '& .MuiSelect-icon': {
                color: 'var(--text-primary)'
              }
            }}
          >
            {employees.map((emp) => (
              <MenuItem key={emp} value={emp}>{emp}</MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* Event Filter */}
        <FormControl sx={{ minWidth: 150 }}>
          <Typography variant="subtitle2" sx={{ color: 'var(--text-primary)' }}>Event</Typography>
          <Select
            value={eventFilter}
            onChange={(e) => setEventFilter(e.target.value)}
            size="small"
            sx={{ 
              height: '40px',
              color: 'var(--text-primary)',
              '& .MuiSelect-icon': {
                color: 'var(--text-primary)'
              }
            }}
          >
            {events.map((event) => (
              <MenuItem key={event} value={event}>{event}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      {/* Loading State */}
      {loading ? (
        <div className={styles.loadingContainer}>
          <CircularProgress />
        </div>
      ) : error ? (
        <div className={styles.errorContainer}>
          <Typography color="error">{error}</Typography>
        </div>
      ) : timesheets.length === 0 ? (
        <div className={styles.errorContainer}>
          <Typography>No timesheets found.</Typography>
        </div>
      ) : (
        /* Table Content */
        <TableContainer className={styles.tableContainer}>
          <Table>
            <TableHead>
              <TableRow className={styles.tableHeader}>
                {headerCells.map((cell, index) => (
                  <TableCell key={index} className={styles.tableCell} align="center">
                    <div className={styles.headerContent}>
                      <span className={styles.headerIcon} style={{ color: cell.color }}>
                        {cell.icon}
                      </span>
                      <span className={styles.headerLabel}>{cell.label}</span>
                    </div>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredTimesheets.map((timesheet) => (
                <TableRow 
                  key={timesheet.id} 
                  className={styles.tableRow}
                  sx={{ '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.04)' } }}
                >
                  <TableCell className={styles.tableCell} align="center">
                    {formatDateTime(timesheet.clock_in)}
                  </TableCell>
                  <TableCell className={styles.tableCell} align="center">
                    {timesheet.clock_out ? formatDateTime(timesheet.clock_out) : 'Not clocked out'}
                  </TableCell>
                  <TableCell className={styles.tableCell} align="center">
                    {timesheet.hours_worked ? timesheet.hours_worked.toFixed(2) : 'N/A'}
                  </TableCell>
                  <TableCell className={styles.tableCell} align="center">
                    {timesheet.event.project_name || 'N/A'}
                  </TableCell>
                  <TableCell className={styles.tableCell} align="center">
                    {timesheet.employee.name || 'N/A'}
                  </TableCell>
                  <TableCell className={styles.tableCell} align="center">
                    {timesheet.event.title || 'N/A'}
                  </TableCell>
                  <TableCell className={styles.tableCell} align="center">
                    {timesheet.payment_type || 'N/A'}
                  </TableCell>
                  <TableCell className={styles.tableCell} align="center">
                    {timesheet.payment_type === 'percentage' 
                      ? `${timesheet.percentage}%` 
                      : timesheet.rate ? `$${timesheet.rate}${timesheet.payment_type === 'hourly' ? '/hr' : ''}` : 'N/A'
                    }
                  </TableCell>
                  <TableCell className={styles.tableCell} align="center">
                    ${timesheet.calculated_payment ? timesheet.calculated_payment.toFixed(2) : 'N/A'}
                  </TableCell>
                  <TableCell className={styles.tableCell} align="center">
                    <Tooltip title="View Details">
                      <IconButton 
                        onClick={() => handleViewDetails(timesheet.id)}
                        size="small"
                        sx={{ 
                          color: 'primary.main',
                          '&:hover': { backgroundColor: 'rgba(25, 118, 210, 0.04)' }
                        }}
                      >
                        <VisibilityIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
};

export default Timesheets;
