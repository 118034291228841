import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
} from '@mui/material';
import {
  Delete as DeleteIcon,
  Edit as EditIcon,
} from '@mui/icons-material';
import { serviceTypes, calculationTypes } from './pricingConstants';

const formatCalculationDetails = (rule) => {
  if (rule.calculationType === 'area') {
    return `$${parseFloat(rule.baseRate).toFixed(2)}/sq ft`;
  }
  return `$${parseFloat(rule.baseRate).toFixed(2)}${
    rule.calculationType === 'hourly' ? '/hr' : 
    rule.calculationType === 'unit' ? '/unit' : ''
  }`;
};

const PricingRuleTable = ({ 
  rules, 
  onEdit, 
  onDelete 
}) => {
  return (
    <TableContainer component={Paper} sx={{ mb: 3, backgroundColor: 'var(--background-paper)' }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={{ color: 'var(--text-primary)', fontWeight: 'bold' }}>Name</TableCell>
            <TableCell sx={{ color: 'var(--text-primary)', fontWeight: 'bold' }}>Description</TableCell>
            <TableCell sx={{ color: 'var(--text-primary)', fontWeight: 'bold' }}>Service Type</TableCell>
            <TableCell sx={{ color: 'var(--text-primary)', fontWeight: 'bold' }}>Calculation Type</TableCell>
            <TableCell sx={{ color: 'var(--text-primary)', fontWeight: 'bold' }}>Rate</TableCell>
            <TableCell align="right" sx={{ color: 'var(--text-primary)', fontWeight: 'bold' }}>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rules.map((rule) => (
            <TableRow 
              key={rule.id || 'new'}
              sx={{ 
                '& .MuiTableCell-root': {
                  color: 'var(--text-primary)'
                },
                backgroundColor: rule.isNew ? 'var(--background-secondary)' : 'inherit'
              }}
            >
              <TableCell>{rule.name}</TableCell>
              <TableCell>{rule.description || '-'}</TableCell>
              <TableCell>
                {serviceTypes.find(t => t.value === rule.serviceType)?.label || rule.serviceType}
              </TableCell>
              <TableCell>
                {calculationTypes.find(t => t.value === rule.calculationType)?.label || rule.calculationType}
              </TableCell>
              <TableCell>{formatCalculationDetails(rule)}</TableCell>
              <TableCell align="right">
                <IconButton
                  onClick={() => onEdit(rule.id || 'new')}
                  sx={{ color: 'var(--primary-main)' }}
                >
                  <EditIcon />
                </IconButton>
                <IconButton
                  onClick={() => onDelete(rule.id || 'new')}
                  sx={{ color: 'var(--error-main)' }}
                >
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default PricingRuleTable; 