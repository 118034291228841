import React from 'react';
import {
  Paper,
  Typography,
  Box,
  Card,
  CardContent,
  Grid,
  IconButton,
  Collapse,
  Tooltip,
  Fade,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Chip
} from '@mui/material';
import CodeIcon from '@mui/icons-material/Code';
import ThinkingAnimation from './ThinkingAnimation';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import TableChartIcon from '@mui/icons-material/TableChart';
import BarChartIcon from '@mui/icons-material/BarChart';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip as RechartsTooltip,
  ResponsiveContainer
} from 'recharts';

const VannaResults = ({ results, sqlQuery, loading, error, width = '800px' }) => {
  const [showSQL, setShowSQL] = React.useState(false);
  const [viewMode, setViewMode] = React.useState('cards'); // 'cards', 'table', 'chart'

  if (loading) {
    return (
      <Paper
        sx={{
          width,
          mt: 1,
          borderRadius: '12px',
          boxShadow: '0 4px 20px rgba(0,0,0,0.15)',
          border: '1px solid rgba(0,0,0,0.08)',
          overflow: 'hidden',
          transition: 'width 0.3s ease-in-out',
          backgroundColor: 'white'
        }}
      >
        <ThinkingAnimation />
      </Paper>
    );
  }

  if (error) {
    return (
      <Paper sx={{ /* existing styles */ }}>
        <Box sx={{ p: 2 }}>
          <Typography color="error" variant="body1">
            {error}
          </Typography>
        </Box>
      </Paper>
    );
  }

  if (!results || results.length === 0) return null;

  const formatValue = (value) => {
    if (value === null || value === undefined) return '—';
    if (typeof value === 'boolean') return value ? 'Yes' : 'No';
    if (typeof value === 'number') return value.toLocaleString();
    return value;
  };

  const renderNaturalResponse = () => {
    const count = results.length;
    const type = Object.keys(results[0]).find(key => 
      key.toLowerCase().includes('name') || 
      key.toLowerCase().includes('title')
    ) || 'item';

    return (
      <Typography variant="h6" sx={{ mb: 2, color: 'text.secondary' }}>
        I found {count} {count === 1 ? type : `${type}s`} for you.
        {count > 0 && " Here's what I discovered:"}
      </Typography>
    );
  };

  const renderCards = () => (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      {results.map((item, index) => (
        <Card
          key={index}
          elevation={0}
          sx={{
            border: '1px solid',
            borderColor: 'divider',
            borderRadius: 1.5,
            transition: 'transform 0.2s, box-shadow 0.2s',
            '&:hover': {
              transform: 'translateY(-2px)',
              boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
            }
          }}
        >
          <CardContent>
            <Grid container spacing={2}>
              {Object.entries(item).map(([key, value]) => (
                <Grid item xs={12} sm={6} key={key}>
                  <Typography 
                    variant="caption" 
                    color="text.secondary" 
                    sx={{ 
                      display: 'block',
                      mb: 0.5,
                      fontSize: '0.7rem',
                      textTransform: 'uppercase',
                      letterSpacing: '0.5px'
                    }}
                  >
                    {key.replace(/_/g, ' ')}
                  </Typography>
                  <Typography variant="body2">
                    {formatValue(value)}
                  </Typography>
                </Grid>
              ))}
            </Grid>
          </CardContent>
        </Card>
      ))}
    </Box>
  );

  const renderTable = () => (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            {Object.keys(results[0]).map((key) => (
              <TableCell key={key}>
                <Typography variant="subtitle2">
                  {key.replace(/_/g, ' ').toUpperCase()}
                </Typography>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {results.map((row, i) => (
            <TableRow key={i}>
              {Object.values(row).map((value, j) => (
                <TableCell key={j}>{formatValue(value)}</TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  const renderChart = () => {
    const numericKeys = Object.keys(results[0]).filter(
      key => typeof results[0][key] === 'number'
    );
    const categoryKey = Object.keys(results[0]).find(
      key => typeof results[0][key] === 'string'
    );

    if (!numericKeys.length || !categoryKey) return null;

    return (
      <Box sx={{ height: 300, width: '100%' }}>
        <ResponsiveContainer>
          <BarChart data={results}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey={categoryKey} />
            <YAxis />
            <RechartsTooltip />
            {numericKeys.map((key, index) => (
              <Bar
                key={key}
                dataKey={key}
                fill={`hsl(${index * 137.5}, 70%, 50%)`}
              />
            ))}
          </BarChart>
        </ResponsiveContainer>
      </Box>
    );
  };

  return (
    <Fade in={true} timeout={200}>
      <Paper
        sx={{
          width,
          mt: 1,
          borderRadius: '12px',
          boxShadow: '0 4px 20px rgba(0,0,0,0.15)',
          border: '1px solid rgba(0,0,0,0.08)',
          transition: 'width 0.3s ease-in-out',
          maxHeight: '600px',
          overflow: 'auto',
          backgroundColor: 'white',
          '&::-webkit-scrollbar': {
            width: '8px',
          },
          '&::-webkit-scrollbar-track': {
            background: '#f1f1f1',
            borderRadius: '4px',
          },
          '&::-webkit-scrollbar-thumb': {
            background: '#888',
            borderRadius: '4px',
            '&:hover': {
              background: '#666',
            },
          },
        }}
      >
        <Box sx={{ p: 2 }}>
          <Chip
            label="This feature is experimental"
            color="success"
            size="small"
            icon={<LightbulbIcon />}
            sx={{ mb: 2 }}
          />

          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <AutoAwesomeIcon color="primary" />
              <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
                AI Assistant
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', gap: 1 }}>
              <Tooltip title="View as Cards">
                <IconButton 
                  size="small"
                  onClick={() => setViewMode('cards')}
                  color={viewMode === 'cards' ? 'primary' : 'default'}
                >
                  <AutoAwesomeIcon fontSize="small" />
                </IconButton>
              </Tooltip>
              <Tooltip title="View as Table">
                <IconButton 
                  size="small"
                  onClick={() => setViewMode('table')}
                  color={viewMode === 'table' ? 'primary' : 'default'}
                >
                  <TableChartIcon fontSize="small" />
                </IconButton>
              </Tooltip>
              <Tooltip title="View as Chart">
                <IconButton 
                  size="small"
                  onClick={() => setViewMode('chart')}
                  color={viewMode === 'chart' ? 'primary' : 'default'}
                >
                  <BarChartIcon fontSize="small" />
                </IconButton>
              </Tooltip>
              <Tooltip title="View SQL Query">
                <IconButton 
                  size="small"
                  onClick={() => setShowSQL(!showSQL)}
                  color={showSQL ? 'primary' : 'default'}
                >
                  <CodeIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </Box>
          </Box>

          {renderNaturalResponse()}

          <Collapse in={showSQL}>
            <Box
              sx={{
                mb: 2,
                p: 1.5,
                bgcolor: 'grey.50',
                borderRadius: 1,
                border: '1px solid',
                borderColor: 'divider',
                fontSize: '0.75rem'
              }}
            >
              <Typography
                variant="caption"
                sx={{
                  fontFamily: 'monospace',
                  whiteSpace: 'pre-wrap',
                  overflowX: 'auto',
                  display: 'block'
                }}
              >
                {sqlQuery}
              </Typography>
            </Box>
          </Collapse>

          {viewMode === 'cards' && renderCards()}
          {viewMode === 'table' && renderTable()}
          {viewMode === 'chart' && renderChart()}
        </Box>
      </Paper>
    </Fade>
  );
};

export default VannaResults; 