import React, { useState, useEffect } from 'react';
import { 
  Typography, List, ListItem, ListItemText, 
  Accordion, AccordionSummary, AccordionDetails,
  CircularProgress
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { getProjectEvents, getEventEmployees } from '../services/api';
import styles from './EmployeeAssignment.module.scss';

const EventAssignmentsList = ({ projectId, refreshTrigger }) => {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        setLoading(true);
        const eventsData = await getProjectEvents(projectId);
        const eventsWithEmployees = await Promise.all(eventsData.map(async (event) => {
          const employeesData = await getEventEmployees(event.id);
          return { ...event, employees: employeesData.employees || [] };
        }));
        setEvents(eventsWithEmployees);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching events and assignments:', err);
        setError('Failed to fetch events and assignments. Please try again.');
        setLoading(false);
      }
    };

    fetchEvents();
  }, [projectId, refreshTrigger]); // Add refreshTrigger to the dependency array

  if (loading) return <CircularProgress />;
  if (error) return <Typography color="error">{error}</Typography>;

  return (
    <div className={styles.assignmentsList}>
      <Typography variant="h6" gutterBottom>
        Event Assignments
      </Typography>
      {events.length > 0 ? (
        events.map((event) => (
          <Accordion key={event.id} className={styles.eventAccordion}>
            <AccordionSummary 
              expandIcon={<ExpandMoreIcon />}
              className={styles.accordionSummary}
            >
              <Typography className={styles.eventTitle}>{event.title}</Typography>
            </AccordionSummary>
            <AccordionDetails className={styles.accordionDetails}>
              {event.employees.length > 0 ? (
                <List className={styles.assignedList}>
                  {event.employees.map((emp) => (
                    <ListItem key={emp.id} className={styles.assignedEmployee}>
                      <ListItemText 
                        primary={emp.employee?.name || emp.name || 'Unknown Employee'}
                        secondary={
                          <Typography variant="body2" className={styles.paymentInfo}>
                            {emp.payment_type === 'percentage' 
                              ? `${emp.percentage}% of event value`
                              : `$${emp.rate}${emp.payment_type === 'hourly' ? '/hour' : ' fixed'}`
                            }
                          </Typography>
                        }
                      />
                    </ListItem>
                  ))}
                </List>
              ) : (
                <Typography className={styles.noEmployees}>
                  No employees assigned to this event.
                </Typography>
              )}
            </AccordionDetails>
          </Accordion>
        ))
      ) : (
        <Typography className={styles.noEvents}>
          No events found for this project.
        </Typography>
      )}
    </div>
  );
};

export default EventAssignmentsList;
