import React, { useEffect } from 'react';
import { Paper, Typography, Box } from '@mui/material';

const UnscheduledEventsBanner = ({ count, onClick }) => {
  useEffect(() => {
    console.log('UnscheduledEventsBanner - Current count:', count);
    console.log('UnscheduledEventsBanner - Will render:', Boolean(count && count > 0));
  }, [count]);

  useEffect(() => {
    if (count === 0) {
      console.log('UnscheduledEventsBanner - All events scheduled, project dates should update');
    }
  }, [count]);

  if (!count || count === 0) {
    console.log('UnscheduledEventsBanner - Not rendering due to zero/null count');
    return null;
  }

  console.log('UnscheduledEventsBanner - Rendering with count:', count);

  const handleClick = () => {
    console.log('UnscheduledEventsBanner - Clicked, count before scheduling:', count);
    onClick();
  };

  return (
    <Paper
      elevation={0}
      sx={{
        p: 1.5,
        mb: 2,
        backgroundColor: 'rgba(25, 118, 210, 0.15)',
        backdropFilter: 'blur(8px)',
        WebkitBackdropFilter: 'blur(8px)',
        color: '#1976d2',
        cursor: 'pointer',
        transition: 'all 0.2s ease',
        '&:hover': {
          backgroundColor: 'rgba(25, 118, 210, 0.25)',
          transform: 'translateY(-1px)',
          boxShadow: '0 4px 15px rgba(25, 118, 210, 0.15)',
        },
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 1,
        mx: 2,
        border: '1px solid rgba(25, 118, 210, 0.2)',
        position: 'relative',
        overflow: 'hidden',
        '&::before': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background: 'linear-gradient(135deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0))',
          zIndex: 1
        }
      }}
      onClick={handleClick}
    >
      <Box sx={{ 
        display: 'flex', 
        alignItems: 'center', 
        gap: 1.5,
        position: 'relative',
        zIndex: 2
      }}>
        <Typography sx={{ 
          fontSize: '0.95rem',
          fontWeight: 500,
          letterSpacing: 0.3,
          display: 'flex',
          alignItems: 'center',
          gap: 1
        }}>
          You have {count} unscheduled event{count !== 1 ? 's' : ''} - Click here to schedule ⚡
        </Typography>
      </Box>
    </Paper>
  );
};

export default UnscheduledEventsBanner;
