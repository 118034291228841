import React, { useState, useEffect } from 'react';
import { Paper, Typography, TextField, Button, Divider, Chip, Avatar, Grid, InputAdornment, ButtonGroup, Box, Menu, MenuItem, ListItemIcon, Stack, IconButton} from '@mui/material';
import DescriptionIcon from '@mui/icons-material/Description';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import DateRangeIcon from '@mui/icons-material/DateRange';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import GroupIcon from '@mui/icons-material/Group';
import EventIcon from '@mui/icons-material/Event';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import WorkIcon from '@mui/icons-material/Work';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import ReceiptIcon from '@mui/icons-material/Receipt';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import SavingsIcon from '@mui/icons-material/Savings';
import PersonIcon from '@mui/icons-material/Person';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import BusinessIcon from '@mui/icons-material/Business';
import styles from './ProjectDetailsContent.module.scss';
import { getProjectEvents, getProjectFinancials, getClients, updateProjectClient, getEventDetails } from '../services/api';
import PropertyMap from './PropertyMap';
import PropertyDetails from './PropertyDetails';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import PropTypes from 'prop-types';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import UpdateIcon from '@mui/icons-material/Update';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import EventDetails from './EventDetails';
import TagsSection from './TagsSection';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const formatNumber = (value) => {
  if (!value && value !== 0) return '0';
  return value.toLocaleString('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });
};

const formatPhoneNumber = (phoneNumber) => {
  if (!phoneNumber) return 'N/A';
  // Remove all non-digits
  const cleaned = phoneNumber.replace(/\D/g, '');
  // Check if it's a valid 10-digit number
  if (cleaned.length !== 10) return phoneNumber;
  // Format as (XXX) XXX-XXXX
  return `(${cleaned.slice(0,3)}) ${cleaned.slice(3,6)}-${cleaned.slice(6)}`;
};

const formatDateTime = (dateTimeString) => {
  if (!dateTimeString) return 'Not set';
  
  // Split into date and time parts
  const [datePart, timePart] = dateTimeString.split('T');
  
  // Format date
  const [year, month, day] = datePart.split('-');
  const formattedDate = `${month}/${day}/${year}`;
  
  // Format time
  const [hours, minutes] = timePart.slice(0, 5).split(':');
  const hour = parseInt(hours);
  const ampm = hour >= 12 ? 'PM' : 'AM';
  const hour12 = hour % 12 || 12;
  const formattedTime = `${hour12}:${minutes} ${ampm}`;
  
  return `${formattedDate}, ${formattedTime}`;
};

const capitalizeFirstLetter = (string) => {
  if (!string) return '';
  return string.charAt(0).toUpperCase() + string.slice(1).replace(/_/g, ' ');
};

const ProjectDetailsContent = ({ 
  project, 
  isEditing, 
  editedDetails, 
  handleInputChange, 
  handleEdit, 
  handleSave, 
  setIsEditing,
  onProjectUpdated,
  scopeOfWork,
  setScopeOfWork,
  handleSaveScopeOfWork
}) => {
  const [events, setEvents] = useState([]);
  const [financials, setFinancials] = useState({});
  const [clientDetails, setClientDetails] = useState(null);
  const [openClientDialog, setOpenClientDialog] = useState(false);
  const [availableClients, setAvailableClients] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedEventId, setSelectedEventId] = useState(null);
  const [isEventDetailsOpen, setIsEventDetailsOpen] = useState(false);

  useEffect(() => {
    const fetchEvents = async () => {
      if (project?.id) {
        try {
          const eventData = await getProjectEvents(project.id);
          setEvents(eventData);
        } catch (error) {
          console.error('Failed to fetch events:', error);
        }
      }
    };

    fetchEvents();
  }, [project?.id]);

  useEffect(() => {
    const fetchFinancials = async () => {
      if (project?.id) {
        try {
          const data = await getProjectFinancials(project.id);
          setFinancials(data);
        } catch (error) {
          console.error('Failed to fetch financials:', error);
        }
      }
    };

    fetchFinancials();
  }, [project?.id]);

  useEffect(() => {
    const fetchClientDetails = async () => {
      if (project?.id) {
        try {
          console.log("Fetching client details for Project:", project);
          
          const token = localStorage.getItem('token');
          if (!token) {
            throw new Error('No authentication token found');
          }

          // First get the project details to ensure we have the latest client_id
          const projectResponse = await fetch(
            `${process.env.REACT_APP_API_URL}/api/projects/${project.id}`,
            {
              headers: {
                'Authorization': `Bearer ${token}`
              }
            }
          );

          if (!projectResponse.ok) {
            throw new Error('Failed to fetch project details');
          }

          const projectData = await projectResponse.json();
          console.log("Project data received:", projectData);
          
          const clientId = projectData.client_id;
          console.log("Retrieved client_id from project:", clientId);

          if (clientId) {
            // Now fetch the client details using the correct endpoint
            const clientResponse = await fetch(
              `${process.env.REACT_APP_API_URL}/api/clients/${clientId}`,
              {
                headers: {
                  'Authorization': `Bearer ${token}`
                }
              }
            );
            
            if (!clientResponse.ok) {
              throw new Error('Failed to fetch client details');
            }

            const clientData = await clientResponse.json();
            console.log("Client details found:", clientData);
            setClientDetails(clientData);
          } else {
            console.log("No client associated with project");
            setClientDetails(null);
          }
        } catch (error) {
          console.error('Failed to fetch client details:', error);
          setClientDetails(null);
        }
      } else {
        setClientDetails(null);
      }
    };

    fetchClientDetails();
  }, [project?.id]);

  const formatTimeSlot = (timeSlot) => {
    try {
      // Log the incoming timeSlot for debugging
      console.log('Raw timeSlot:', timeSlot);

      // Format date to display in local format
      const formatDate = (dateStr) => {
        const [year, month, day] = dateStr.split('-');
        return `${month}/${day}/${year}`;
      };

      // Format time without any conversion
      const formatTime = (timeStr) => {
        const [hours, minutes] = timeStr.split(':');
        const hour = parseInt(hours);
        const ampm = hour >= 12 ? 'PM' : 'AM';
        const hour12 = hour % 12 || 12;
        return `${hour12}:${minutes.slice(0,2)} ${ampm}`;
      };

      // Split the datetime strings
      const [startDate, startTime] = timeSlot.start_time.split('T');
      const [endDate, endTime] = timeSlot.end_time.split('T');

      return {
        date: formatDate(startDate),
        time: `${formatTime(startTime)} - ${formatTime(endTime)}`
      };
    } catch (error) {
      console.error('Error formatting time slot:', error);
      return { date: 'Invalid date', time: 'Invalid time' };
    }
  };

  const handleOpenClientDialog = async () => {
    setLoading(true);
    try {
      const response = await getClients(1, 100); // Get up to 100 clients
      setAvailableClients(response.clients);
      setOpenClientDialog(true);
    } catch (error) {
      console.error('Failed to fetch clients:', error);
    }
    setLoading(false);
  };

  const handleClientSelect = async (selectedClient) => {
    setError(null);
    setLoading(true);
    try {
      console.log('Attempting to associate client:', selectedClient.id, 'with project:', project.id);
      
      // Call the API to update the project's client
      await updateProjectClient(project.id, selectedClient.id);
      
      // After successful update, fetch the latest project details
      const token = localStorage.getItem('token');
      const projectResponse = await fetch(
        `${process.env.REACT_APP_API_URL}/api/project/${project.id}`,
        {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }
      );

      if (!projectResponse.ok) {
        throw new Error('Failed to verify client association');
      }

      const updatedProject = await projectResponse.json();
      console.log('Updated project details:', updatedProject);

      // Verify the client association
      if (updatedProject.client_id !== selectedClient.id) {
        throw new Error('Client association verification failed');
      }

      // Set the client details
      setClientDetails(selectedClient);
      setOpenClientDialog(false);
      
      // Notify parent component
      if (typeof onProjectUpdated === 'function') {
        onProjectUpdated();
      }

      console.log('Client association successful');
    } catch (error) {
      console.error('Failed to associate client:', error);
      setError(error.message || 'Failed to associate client with project');
    } finally {
      setLoading(false);
    }
  };

  const handleEmployeeClick = (employeeId) => {
    navigate(`/admin/employees/${employeeId}`);
  };



  const handleClose = () => {
    setAnchorEl(null);
  };

  const menuItemStyle = {
    fontSize: '1rem',
    color: '#333',
    '&:hover': {
      backgroundColor: '#f0f0f0'
    }
  };


  const handleEventClick = async (eventId) => {
    try {
      setSelectedEventId(eventId);
      setIsEventDetailsOpen(true);
    } catch (error) {
      console.error('Error handling event click:', error);
    }
  };

  const refreshData = async () => {
    try {
      // Refresh project data
      if (onProjectUpdated) {
        await onProjectUpdated();
      }
    } catch (error) {
      console.error('Error refreshing data:', error);
    }
  };

  const handleTimeSlotChange = async (eventId, timeSlotIndex, field, value) => {
    try {
      const updatedTimeSlots = [...events.find(e => e.id === eventId).time_slots];
      updatedTimeSlots[timeSlotIndex] = {
        ...updatedTimeSlots[timeSlotIndex],
        [field]: value
      };

      await axios.patch(`${process.env.REACT_APP_API_URL}/api/events/${eventId}`, {
        time_slots: updatedTimeSlots
      });

      // Update local state
      setEvents(events.map(event => {
        if (event.id === eventId) {
          return { ...event, time_slots: updatedTimeSlots };
        }
        return event;
      }));

      await refreshData();
    } catch (error) {
      console.error('Failed to update time slot:', error);
    }
  };

  const handleEventDetailsClose = async () => {
    setIsEventDetailsOpen(false);
    setSelectedEventId(null);
    await refreshData();
  };

  return (
    <Box className={styles.container}>
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'flex-end', 
        mb: 3,
        width: '100%' 
      }}>
      </Box>
      
      <Grid container spacing={3}>
        {/* Left Column */}
        <Grid item xs={12} md={4}>
          {/* Client Section */}
          <div className={styles.mainSection}>
            <div className={styles.header}>
              <Typography variant="h5" sx={{ fontSize: '1.5rem', fontWeight: 500, paddingTop: '12px' }}>Client Information</Typography>
            </div>
            {clientDetails ? (
              <div className={styles.clientDetails}>
                <div className={styles.clientItem}>
                  <span className={styles.iconCircle}>
                    <PersonIcon className={styles.iconBlue} />
                  </span>
                  <div>
                    <Typography variant="body2">Client Name</Typography>
                    <Typography variant="body1">
                      {clientDetails?.name || 'N/A'}
                    </Typography>
                  </div>
                </div>
                <div className={styles.clientItem}>
                  <span className={styles.iconCircle}>
                    <EmailIcon className={styles.iconGreen} />
                  </span>
                  <div>
                    <Typography variant="body2">Email</Typography>
                    <Typography variant="body1">
                      {clientDetails?.email || 'N/A'}
                    </Typography>
                  </div>
                </div>
                <div className={styles.clientItem}>
                  <span className={styles.iconCircle}>
                    <PhoneIcon className={styles.iconOrange} />
                  </span>
                  <div>
                    <Typography variant="body2">Phone</Typography>
                    <Typography variant="body1">
                      {formatPhoneNumber(clientDetails?.phone)}
                    </Typography>
                  </div>
                </div>
                <div className={styles.clientItem}>
                  <span className={styles.iconCircle}>
                    <BusinessIcon className={styles.iconPurple} />
                  </span>
                  <div>
                    <Typography variant="body2">Company</Typography>
                    <Typography variant="body1">
                      {clientDetails?.company || 'N/A'}
                    </Typography>
                  </div>
                </div>
              </div>
            ) : (
              <div className={styles.noClientContainer}>
                <Button
                  variant="outlined"
                  color="primary"
                  startIcon={<AddIcon />}
                  onClick={handleOpenClientDialog}
                  disabled={loading}
                >
                  Associate Client
                </Button>
              </div>
            )}
          </div>

          <div className={styles.rightColumn}>
            <div className={styles.mapSection}>
              <PropertyMap address={project.address} />
            </div>

            <TagsSection 
              projectId={project.id}
              tags={project.tags}
              onProjectUpdated={onProjectUpdated}
            />
          </div>
          <div className={styles.scopeOfWork}>
              <Paper 
                elevation={0}
                className={styles.scopeOfWorkPaper}
                sx={{ 
                  boxShadow: 'none !important',
                  background: 'transparent !important',
                  '& .MuiPaper-root': {
                    boxShadow: 'none !important',
                    background: 'transparent !important'
                  }
                }}
              >
                <div className={styles.scopeOfWorkHeader}>
                  <h2>Internal Notes</h2>
                  {!isEditing && (
                    <IconButton
                      onClick={() => {
                        setIsEditing(true);
                        setScopeOfWork(project?.scope_of_work || '');
                      }}
                      color="primary"
                      aria-label="edit internal notes"
                      size="small"
                    >
                      <EditIcon />
                    </IconButton>
                  )}
                </div>
                {isEditing ? (
                  <>
                    <ReactQuill 
                      value={scopeOfWork} 
                      onChange={setScopeOfWork}
                      modules={{
                        toolbar: [
                          [{ 'header': [1, 2, 3, false] }],
                          ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                          [{'list': 'ordered'}, {'list': 'bullet'}],
                          ['link', 'image'],
                          ['clean']
                        ],
                      }}
                      className={styles.quillEditor}
                    />
                    <div className={styles.buttonGroup}>
                      <Button 
                        onClick={handleSaveScopeOfWork} 
                        variant="contained" 
                        color="primary" 
                        startIcon={<SaveIcon />}
                      >
                        Save
                      </Button>
                      <Button 
                        onClick={() => setIsEditing(false)} 
                        variant="outlined" 
                        startIcon={<CancelIcon />}
                      >
                        Cancel
                      </Button>
                    </div>
                  </>
                ) : (
                  <div 
                    className={styles.scopeOfWorkContent}
                    dangerouslySetInnerHTML={{ 
                      __html: scopeOfWork || project?.scope_of_work || 'No internal notes defined for this project.' 
                    }} 
                  />
                )}
              </Paper>
            </div>
        </Grid>

        {/* Right Column */}
        <Grid item xs={12} md={8}>
          {/* Project Details */}
          <div className={styles.mainSection}>
            <div className={styles.header}>
              <Typography variant="h5" sx={{ fontSize: '1.5rem', fontWeight: 500 }}>Project Details</Typography>
              <div className={styles.actionButtons}>
                {isEditing ? (
                  <>
                    <Button
                      startIcon={<SaveIcon />}
                      onClick={handleSave}
                      variant="contained"
                      color="primary"
                      size="small"
                    >
                      Save
                    </Button>
                    <Button
                      startIcon={<CancelIcon />}
                      onClick={() => setIsEditing(false)}
                      variant="outlined"
                      color="error"
                      size="small"
                    >
                      Cancel
                    </Button>
                  </>
                ) : (
                  <Button
                    startIcon={<EditIcon />}
                    onClick={handleEdit}
                    variant="outlined"
                    color="primary"
                    size="small"
                  >
                    Edit
                  </Button>
                )}
              </div>
            </div>
            
            
            
            <div className={styles.content}>
              {/* Timeline Grid */}
              <Grid container spacing={0}>
                <Grid item xs={12} md={4}>
                  <div className={styles.section}>
                    <div className={styles.sectionHeader}>
                      <DateRangeIcon className={styles.iconBlue} />
                      <Typography variant="subtitle1">Start Time</Typography>
                    </div>
                    <div className={styles.contentText}>
                      {formatDateTime(project.start_time)}
                    </div>

                    <div className={styles.sectionHeader}>
                      <DateRangeIcon className={styles.iconBlue} />
                      <Typography variant="subtitle1">End Time</Typography>
                    </div>
                    <div className={styles.contentText}>
                      {formatDateTime(project.end_time)}
                    </div>
                  </div>
                </Grid>

                <Grid item xs={12} md={8}>
                  <Stack spacing={2}>
                    <div className={styles.section}>
                      <div className={styles.sectionHeader}>
                        <LocationOnIcon className={styles.iconRed} />
                        <Typography variant="subtitle1">Location</Typography>
                      </div>
                      <div className={styles.contentText}>
                        {project.address}
                      </div>
                    </div>

                    <div className={styles.section}>
                      <div className={styles.sectionHeader}>
                        <GroupIcon className={styles.iconPurple} />
                        <Typography variant="subtitle1">Assigned Employees</Typography>
                      </div>
                      <div className={styles.contentText}>
                        {project?.employees && project.employees.length > 0 ? (
                          <div className={styles.chipContainer}>
                            {project.employees.map((employee) => (
                              <Chip
                                key={employee.id}
                                avatar={
                                  <Avatar
                                    alt={employee.name}
                                    src={employee.avatar_url}
                                  >
                                    {employee.name.charAt(0)}
                                  </Avatar>
                                }
                                label={employee.name}
                                className={styles.chip}
                                onClick={() => handleEmployeeClick(employee.id)}
                                clickable
                                sx={{ cursor: 'pointer' }}
                              />
                            ))}
                          </div>
                        ) : (
                          "No employees assigned"
                        )}
                      </div>
                    </div>
                  </Stack>
                </Grid>
              </Grid>

              {/* Project Events */}
              <div className={styles.section}>
                <div className={`${styles.sectionHeader} ${styles.sectionHeaderEvents}`}>
                  <EventIcon className={styles.iconOrange} />
                  <Typography variant="subtitle1">Project Events</Typography>
                </div>
                
                <div className={styles.eventsContainer}>
                  {events.length > 0 ? (
                    events.map((event) => (
                      <div 
                        key={event.id} 
                        className={styles.eventItem}
                        onClick={() => handleEventClick(event.id)}
                      >
                        <div className={styles.eventHeader}>
                          <div className={styles.eventTitleSection} style={{ display: 'flex', alignItems: 'center' }}>
                            <span className={styles.eventEmoji}>{event.emoji}</span>
                            <Typography variant="subtitle2" className={styles.eventTitle}>
                              {event.title}
                            </Typography>
                            <Chip
                              label={event.status?.charAt(0).toUpperCase() + event.status?.slice(1).replace(/_/g, ' ')}
                              size="small"
                              sx={{
                                ml: 1,
                                height: '20px',
                                backgroundColor: event.status === 'completed' ? '#E8F5E9' : 
                                                event.status === 'in_progress' ? '#E3F2FD' :
                                                event.status === 'pending' ? '#FFF3E0' : '#F5F5F5',
                                color: event.status === 'completed' ? '#2E7D32' :
                                       event.status === 'in_progress' ? '#1565C0' :
                                       event.status === 'pending' ? '#E65100' : '#757575',
                                '& .MuiChip-label': {
                                  px: 1,
                                  fontSize: '0.75rem',
                                  fontWeight: 500
                                }
                              }}
                            />
                          </div>
                          <Typography variant="body2" className={styles.eventValue}>
                            ${event.value}
                          </Typography>
                        </div>
                        
                        {event.description && (
                          <div className={styles.eventDescription}>
                            {event.description.split('\n').map((line, i) => (
                              <div key={i}>{line}</div>
                            ))}
                          </div>
                        )}

                        {/* Event Details Container */}
                        <div className={styles.eventDetailsGroup}>
                          {/* Time Slots */}
                          {event.time_slots?.map((timeSlot, index) => (
                            <div key={index} className={styles.timeSlot}>
                              <div className={styles.timeSlotText}>
                                <DateRangeIcon fontSize="small" />
                                {formatDateTime(timeSlot.start_time)}
                                <span className={styles.timeSlotDivider}>to</span>
                                <DateRangeIcon fontSize="small" />
                                {formatDateTime(timeSlot.end_time)}
                              </div>
                            </div>
                          ))}

                          {/* Employees */}
                          {event.employees?.length > 0 && (
                            <div className={styles.eventEmployees}>
                              <GroupIcon fontSize="small" />
                              <div className={styles.employeeChips}>
                                {event.employees.map((employee) => (
                                  <Chip
                                    key={employee.id}
                                    avatar={<Avatar alt={employee.name} src={employee.avatar_url}>{employee.name.charAt(0)}</Avatar>}
                                    label={employee.name}
                                    size="small"
                                    className={styles.employeeChip}
                                    onClick={() => handleEmployeeClick(employee.id)}
                                    clickable
                                    sx={{ cursor: 'pointer' }}
                                  />
                                ))}
                              </div>
                            </div>
                          )}

                          {/* Location */}
                          {event.addresses?.length > 0 && (
                            <div className={styles.eventLocation}>
                              <LocationOnIcon fontSize="small" />
                              <TextField
                                fullWidth
                                size="small"
                                value={event.addresses[0]}
                                placeholder="Enter location"
                                onChange={(e) => {
                                  const updatedAddresses = [e.target.value];
                                  axios.patch(`${process.env.REACT_APP_API_URL}/api/events/${event.id}`, {
                                    addresses: updatedAddresses
                                  });
                                  setEvents(events.map(evt => 
                                    evt.id === event.id ? { ...evt, addresses: updatedAddresses } : evt
                                  ));
                                }}
                                InputProps={{
                                  sx: {
                                    '&.MuiOutlinedInput-root': {
                                      '& fieldset': { border: 'none' },
                                      '&:hover fieldset': { border: 'none' },
                                      '&.Mui-focused fieldset': { border: 'none' },
                                      '& input': {
                                        color: 'var(--text-primary)',
                                        fontSize: '1rem',
                                        padding: '8px',
                                        fontFamily: 'inherit'
                                      }
                                    }
                                  }
                                }}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    ))
                  ) : (
                    <Typography className={styles.sectionContent}>
                      No events scheduled
                    </Typography>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>

      {/* Add the Client Selection Dialog */}
      <Dialog 
        open={openClientDialog} 
        onClose={() => setOpenClientDialog(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Select Client</DialogTitle>
        <DialogContent>
          <List>
            {availableClients.map((client) => (
              <ListItem 
                button 
                key={client.id}
                onClick={() => handleClientSelect(client)}
                className={styles.clientListItem}
              >
                <ListItemText
                  primary={client.name}
                  secondary={
                    <>
                      {client.email}
                      {client.phone && ` • ${formatPhoneNumber(client.phone)}`}
                      {client.company && `  ${client.company}`}
                    </>
                  }
                />
              </ListItem>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenClientDialog(false)} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <EventDetails
        eventId={selectedEventId}
        open={isEventDetailsOpen}
        onClose={handleEventDetailsClose}
        onEventUpdated={() => {
          // Refresh events list after update
          const fetchEvents = async () => {
            if (project?.id) {
              try {
                const eventData = await getProjectEvents(project.id);
                setEvents(eventData);
              } catch (error) {
                console.error('Failed to fetch events:', error);
              }
            }
          };
          fetchEvents();
        }}
      />
    </Box>
  );
};

ProjectDetailsContent.propTypes = {
  project: PropTypes.object,
  isEditing: PropTypes.bool,
  editedDetails: PropTypes.object,
  handleInputChange: PropTypes.func,
  handleEdit: PropTypes.func,
  handleSave: PropTypes.func,
  setIsEditing: PropTypes.func,
  onProjectUpdated: PropTypes.func,
  scopeOfWork: PropTypes.string,
  setScopeOfWork: PropTypes.func,
  handleSaveScopeOfWork: PropTypes.func
};

export default ProjectDetailsContent;