import React, { useState } from 'react';
import { 
  TextField, 
  Button, 
  Typography, 
  Box, 
  Snackbar, 
  Select, 
  MenuItem, 
  InputLabel, 
  FormControl,
  Grid,
  InputAdornment,
  Alert,
  Container
} from '@mui/material';
import { createEmployee } from '../services/api';
import { useNavigate } from 'react-router-dom';
import styles from './CreateEmployee.module.scss';

const CreateEmployee = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [position, setPosition] = useState('');
  const [address, setAddress] = useState('');
  const [password, setPassword] = useState('');
  const [role, setRole] = useState('employee'); // Default role
  const [defaultRate, setDefaultRate] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const formatCurrency = (value) => {
    // Remove non-numeric characters except decimal point
    const numericValue = value.replace(/[^0-9.]/g, '');
    return numericValue;
  };

  const handleRateChange = (e) => {
    const formattedValue = formatCurrency(e.target.value);
    setDefaultRate(formattedValue);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await createEmployee({ 
        name, 
        email, 
        phone, 
        position, 
        address,
        password,
        role,
        default_rate: defaultRate
      });
      navigate('/employees');
    } catch (error) {
      console.error('Failed to create employee:', error);
      setError('Failed to create employee. Please try again.');
    }
  };

  return (
    <Container className={styles.createEmployeeContainer}>
      <Box className={styles.formWrapper}>
        <Typography 
          variant="h5" 
          component="h1" 
          gutterBottom
          sx={{
            fontWeight: 600,
            color: '#2c3e50',
            mb: 3,
            borderBottom: '2px solid #3498db',
            paddingBottom: '8px'
          }}
        >
          Create New User
        </Typography>

        <Box component="form" onSubmit={handleSubmit} className={styles.form}>
          <Grid container spacing={2}>
            {/* Personal Information */}
            <Grid item xs={12}>
              <Typography variant="subtitle2" sx={{ mb: 2, color: '#7f8c8d', fontWeight: 500 }}>
                Personal Information
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
                fullWidth
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                fullWidth
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                fullWidth
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Phone"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                fullWidth
                variant="outlined"
              />
            </Grid>

            {/* Employment Information */}
            <Grid item xs={12}>
              <Typography variant="subtitle2" sx={{ mt: 2, mb: 2, color: '#7f8c8d', fontWeight: 500 }}>
                Employment Information
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Position"
                value={position}
                onChange={(e) => setPosition(e.target.value)}
                fullWidth
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Default Rate"
                value={defaultRate}
                onChange={handleRateChange}
                fullWidth
                variant="outlined"
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  endAdornment: <InputAdornment position="end">/hr</InputAdornment>,
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth variant="outlined">
                <InputLabel id="role-select-label">Role</InputLabel>
                <Select
                  labelId="role-select-label"
                  value={role}
                  label="Role"
                  onChange={(e) => setRole(e.target.value)}
                >
                  <MenuItem value="employee">Employee</MenuItem>
                  <MenuItem value="admin">Admin</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Address"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                fullWidth
                variant="outlined"
              />
            </Grid>
          </Grid>

          {error && (
            <Alert severity="error" sx={{ mt: 2, mb: 2 }}>
              {error}
            </Alert>
          )}

          <Button 
            type="submit" 
            variant="contained" 
            fullWidth
            sx={{
              mt: 3,
              py: 1.5,
              backgroundColor: '#3498db',
              '&:hover': {
                backgroundColor: '#2980b9'
              }
            }}
          >
            Create New User
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default CreateEmployee;
