import React, { useRef, useEffect, useState } from 'react';
import { Dialog, DialogContent, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import TranscriptHeader from './transcript/TranscriptHeader';
import TranscriptContent from './transcript/TranscriptContent';
import AudioPlayer from './transcript/AudioPlayer';

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    borderRadius: theme.shape.borderRadius * 2,
    height: '95vh',
    width: '95vw',
    maxWidth: '1400px',
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    flexDirection: 'column'
  }
}));

const CallTranscript = ({ open, onClose }) => {
  const contentRef = useRef(null);
  const [visibleTranscript, setVisibleTranscript] = useState([]);
  const [showFullTranscript, setShowFullTranscript] = useState(false);

  const mockTranscript = [
    {
      id: 1,
      speaker: 'agent',
      message: "Hello, thank you for calling. How may I assist you today?",
      timestamp: '00:00',
      startTime: 0
    },
    {
      id: 2,
      speaker: 'customer',
      message: "Hi, I'm calling about my recent service request.",
      timestamp: '00:05',
      startTime: 5
    },
    {
      id: 3,
      speaker: 'agent',
      message: "I'd be happy to help you with that. Could you please provide your reference number?",
      timestamp: '00:10',
      startTime: 10
    },
    {
      id: 4,
      speaker: 'customer',
      message: "Yes, it's REF123456.",
      timestamp: '00:15',
      startTime: 15
    }
  ];

  const callDetails = {
    date: 'March 10, 2024',
    duration: '5:23',
    agent: 'John Smith'
  };

  const handleTimeUpdate = (currentTime) => {
    if (!showFullTranscript) {
      const visibleMessages = mockTranscript.filter(
        message => message.startTime <= currentTime
      );
      setVisibleTranscript(visibleMessages);
    }
  };

  const handleToggleFullTranscript = () => {
    setShowFullTranscript(prev => {
      const newValue = !prev;
      if (newValue) {
        setVisibleTranscript(mockTranscript);
      } else {
        handleTimeUpdate(0);
      }
      return newValue;
    });
  };

  useEffect(() => {
    if (open && contentRef.current) {
      contentRef.current.scrollTop = 0;
      setVisibleTranscript([]); // Reset transcript when dialog opens
    }
  }, [open]);

  if (!open) return null;

  return (
    <StyledDialog 
      open={open} 
      onClose={onClose}
      maxWidth={false}
      fullWidth
    >
      <Box sx={{ 
        display: 'flex', 
        flexDirection: 'column', 
        height: '100%',
      }}>
        <TranscriptHeader 
          onClose={onClose} 
          callDetails={callDetails}
          onToggleFullTranscript={handleToggleFullTranscript}
          showFullTranscript={showFullTranscript}
        />
        
        <Box sx={{ 
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
          position: 'relative'
        }}>
          <Box sx={{ 
            flex: 1,
            overflow: 'auto',
          }}>
            <TranscriptContent transcript={visibleTranscript} />
          </Box>
        </Box>

        <Box sx={{ 
          borderTop: 1,
          borderColor: 'divider',
          bgcolor: 'background.paper',
        }}>
          <AudioPlayer onTimeUpdate={handleTimeUpdate} />
        </Box>
      </Box>
    </StyledDialog>
  );
};

export default CallTranscript;