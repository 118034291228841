import React from 'react';
import { 
  Box, 
  Paper, 
  Typography, 
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const BadDebtView = ({ onBack }) => {
  const data = [
    {
      client: 'XYZ Inc',
      invoice: 'INV-002',
      amount: 1800,
      dateMarked: '2024-01-15',
      reason: 'Non-responsive client',
      attempts: 3
    },
    // Add more sample data
  ];

  return (
    <Box>
      <Box sx={{ mb: 4, display: 'flex', alignItems: 'center' }}>
        <IconButton onClick={onBack} sx={{ mr: 2 }}>
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h5">Bad Debt Report</Typography>
      </Box>

      <Paper elevation={0} sx={{ p: 3, border: '1px solid #e0e0e0' }}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Client</TableCell>
                <TableCell>Invoice</TableCell>
                <TableCell align="right">Amount</TableCell>
                <TableCell>Date Marked</TableCell>
                <TableCell>Reason</TableCell>
                <TableCell align="right">Collection Attempts</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>{row.client}</TableCell>
                  <TableCell>{row.invoice}</TableCell>
                  <TableCell align="right">${row.amount}</TableCell>
                  <TableCell>{row.dateMarked}</TableCell>
                  <TableCell>{row.reason}</TableCell>
                  <TableCell align="right">{row.attempts}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
};

export default BadDebtView; 