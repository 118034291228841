import React, { useState, useEffect } from 'react';
import { 
  Typography, Box, Grid, Button, Menu, MenuItem, Chip, Avatar, Tooltip, Popover 
} from '@mui/material';
import { 
  FilterList as FilterListIcon,
  Search as SearchIcon,
  Add as AddIcon,
  CalendarToday as CalendarTodayIcon,
  AttachMoney as AttachMoneyIcon,
  LocationOn as LocationOnIcon,
  Description as DescriptionIcon,
  Engineering as EngineeringIcon,
  Assignment as AssignmentIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { getAllProjects } from '../services/api';
import styles from './Projects.module.scss';

const PROJECT_STATUSES = {
  PENDING_SCHEDULING: 'pending_scheduling',
  SCHEDULED: 'scheduled',
  IN_PROGRESS: 'in_progress',
  PENDING_INVOICING: 'pending_invoicing',
  COMPLETED: 'completed'
};

const STATUS_STYLES = {
  [PROJECT_STATUSES.PENDING_SCHEDULING]: {
    color: '#E65100',
    backgroundColor: '#FFF3E0'
  },
  [PROJECT_STATUSES.SCHEDULED]: {
    color: '#1565C0',
    backgroundColor: '#E3F2FD'
  },
  [PROJECT_STATUSES.IN_PROGRESS]: {
    color: '#2E7D32',
    backgroundColor: '#E8F5E9'
  },
  [PROJECT_STATUSES.PENDING_INVOICING]: {
    color: '#7B1FA2',
    backgroundColor: '#F3E5F5'
  },
  [PROJECT_STATUSES.COMPLETED]: {
    color: '#303F9F',
    backgroundColor: '#E8EAF6'
  }
};

const Projects = () => {
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [filter, setFilter] = useState('all');
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const [totalProjects, setTotalProjects] = useState(0);
  const [sortOrder, setSortOrder] = useState('newest');
  const [sortAnchorEl, setSortAnchorEl] = useState(null);
  const [moreEmployeesAnchorEl, setMoreEmployeesAnchorEl] = useState(null);
  const [selectedProject, setSelectedProject] = useState(null);
  const [refreshTrigger, setRefreshTrigger] = useState(0);

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        setLoading(true);
        const projectsData = await getAllProjects();
        console.log('Fetched projects:', projectsData);
        
        const validProjects = projectsData.filter(project => project && typeof project === 'object');
        setProjects(validProjects);
        setTotalProjects(validProjects.length);
      } catch (err) {
        console.error('Error fetching projects:', err);
        setError('Failed to fetch projects. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchProjects();
  }, [refreshTrigger]);

  const refreshProjects = () => {
    setRefreshTrigger(prev => prev + 1);
  };

  const sortProjects = (projects) => {
    return [...projects].sort((a, b) => {
      const dateA = a.start_time ? new Date(a.start_time) : new Date(0);
      const dateB = b.start_time ? new Date(b.start_time) : new Date(0);
      return sortOrder === 'newest' ? dateB - dateA : dateA - dateB;
    });
  };

  const filteredProjects = sortProjects(
    projects.filter(project =>
      (project?.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
       project?.description?.toLowerCase().includes(searchTerm.toLowerCase()) ||
       '') &&
      (filter === 'all' || 
       filter === project?.status ||
       (filter === 'unscheduled' && project?.status === PROJECT_STATUSES.PENDING_SCHEDULING))
    )
  );

  const formatDate = (dateString) => {
    if (!dateString) return 'Not set';
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
  };

  const handleFilterClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleFilterClose = (value) => {
    if (value) {
      setFilter(value);
    }
    setAnchorEl(null);
  };

  const handleProjectClick = (projectId) => {
    navigate(`/admin/projects/${projectId}`);
    refreshProjects();
  };

  const handleCreateProject = () => {
    navigate('/admin/projects/create');
  };

  const handleSortClick = (event) => {
    setSortAnchorEl(event.currentTarget);
  };

  const handleSortClose = (value) => {
    if (value) {
      setSortOrder(value);
    }
    setSortAnchorEl(null);
  };

  const getClientDisplayName = (project) => {
    if (project?.client?.company) return project.client.company;
    if (project?.client?.name) return project.client.name;
    return 'No Client';
  };

  const getInitials = (name) => {
    return name
      .split(' ')
      .map(word => word[0])
      .join('')
      .toUpperCase()
      .slice(0, 2);
  };

  const handleEmployeeClick = (e, employeeId) => {
    e.stopPropagation();
    navigate(`/admin/employees/${employeeId}`);
  };

  const handleMoreEmployeesClick = (e, project) => {
    e.stopPropagation();
    setSelectedProject(project);
    setMoreEmployeesAnchorEl(e.currentTarget);
  };

  const handleMoreEmployeesClose = () => {
    setMoreEmployeesAnchorEl(null);
    setSelectedProject(null);
  };

  const summaryCards = (
    <Grid container spacing={3} className={styles.summaryContainer}>
      <Grid item xs={12} sm={6} md={3}>
        <div className={styles.summaryCard}>
          <div className={styles.cardContent}>
            <div className={styles.iconWrapper}>
              <EngineeringIcon sx={{ color: '#FF4842' }} />
            </div>
            <div className={styles.cardInfo}>
              <h3 className={styles.cardValue}>{totalProjects}</h3>
              <p className={styles.cardLabel}>Total Projects</p>
            </div>
          </div>
        </div>
      </Grid>
      
      <Grid item xs={12} sm={6} md={3}>
        <div className={styles.summaryCard}>
          <div className={styles.cardContent}>
            <div className={styles.iconWrapper}>
              <AssignmentIcon sx={{ color: '#54D62C' }} />
            </div>
            <div className={styles.cardInfo}>
              <h3 className={styles.cardValue}>
                {projects.filter(p => p.active).length}
              </h3>
              <p className={styles.cardLabel}>Active Projects</p>
            </div>
          </div>
        </div>
      </Grid>

      <Grid item xs={12} sm={6} md={3}>
        <div className={styles.summaryCard}>
          <div className={styles.cardContent}>
            <div className={styles.iconWrapper}>
              <DescriptionIcon sx={{ color: '#FFC107' }} />
            </div>
            <div className={styles.cardInfo}>
              <h3 className={styles.cardValue}>
                {projects.filter(p => !p.active).length}
              </h3>
              <p className={styles.cardLabel}>Inactive Projects</p>
            </div>
          </div>
        </div>
      </Grid>

      <Grid item xs={12} sm={6} md={3}>
        <div className={styles.summaryCard}>
          <div className={styles.cardContent}>
            <div className={styles.iconWrapper}>
              <AttachMoneyIcon sx={{ color: '#00AB55' }} />
            </div>
            <div className={styles.cardInfo}>
              <h3 className={styles.cardValue}>
                ${projects.reduce((sum, p) => sum + (p.total_value || 0), 0).toLocaleString()}
              </h3>
              <p className={styles.cardLabel}>Total Value</p>
            </div>
          </div>
        </div>
      </Grid>
    </Grid>
  );

  if (loading) return <div className={styles.message}>Loading...</div>;
  if (error) return <div className={styles.message}>Error: {error}</div>;

  return (
    <div className={styles.pageBackground}>
      <div className={styles.pageContent}>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={4}>
          <Typography variant="h5" className={styles.title}>Projects</Typography>
          <div className={styles.buttonGroup}>
          <span className={styles.separator}></span>
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={handleCreateProject}
            className={styles.createButton}
            >
              CREATE PROJECT
            </Button>
          </div>
        </Box>

        {summaryCards}

        <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
          <div className={styles.searchContainer}>
            <div className={styles.searchIcon}>
              <SearchIcon />
            </div>
            <input
              type="text"
              placeholder="Search projects..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className={styles.searchInput}
            />
          </div>

          <div className={styles.filterChips}>
            <Box display="flex" alignItems="center" gap={1} flexWrap="nowrap">
              <Chip
                label={`All (${totalProjects})`}
                onClick={() => setFilter('all')}
                variant={filter === 'all' ? 'filled' : 'outlined'}
                className={`${styles.filterChip} ${filter === 'all' ? styles.activeChip : ''}`}
              />
              <Chip
                label={`Active (${projects.filter(p => p.active).length})`}
                onClick={() => setFilter('active')}
                variant={filter === 'active' ? 'filled' : 'outlined'}
                className={`${styles.filterChip} ${filter === 'active' ? styles.activeChip : ''}`}
              />
              <Chip
                label={`Inactive (${projects.filter(p => !p.active).length})`}
                onClick={() => setFilter('inactive')}
                variant={filter === 'inactive' ? 'filled' : 'outlined'}
                className={`${styles.filterChip} ${filter === 'inactive' ? styles.activeChip : ''}`}
              />
              <Chip
                label={`Unscheduled (${projects.filter(p => !p?.start_time || p?.start_time === null).length})`}
                onClick={() => setFilter('unscheduled')}
                variant={filter === 'unscheduled' ? 'filled' : 'outlined'}
                className={`${styles.filterChip} ${filter === 'unscheduled' ? styles.activeChip : ''}`}
              />
              <Box display="flex" alignItems="center" ml={2}>
                <Chip
                  label={sortOrder === 'newest' ? "Newest First" : "Oldest First"}
                  onClick={handleSortClick}
                  variant="outlined"
                  className={styles.filterChip}
                  icon={<KeyboardArrowDownIcon style={{ color: '#000000' }} />}
                  sx={{ 
                    color: '#000000',
                    '&:hover': {
                      backgroundColor: 'rgba(0, 0, 0, 0.04)'
                    }
                  }}
                />
              </Box>
            </Box>
          </div>
        </Box>

        <div className={styles.projectListSection}>
          {filteredProjects.length === 0 ? (
            <p className={styles.message}>No projects found.</p>
          ) : (
            <div className={styles.projectList}>
              {filteredProjects.map(project => (
                <div 
                  key={project.id} 
                  className={styles.projectRow}
                  onClick={() => handleProjectClick(project.id)}
                >
                  <div className={styles.projectInfo}>
                    <h3 className={styles.projectTitle}>{project.name || 'Unnamed Project'}</h3>
                    <p className={styles.description}>{getClientDisplayName(project)}</p>
                  </div>
                  <div className={styles.projectDetails}>
                    <span className={styles.detailItem}>
                      <CalendarTodayIcon fontSize="small" />
                      {formatDate(project.start_time)} - {formatDate(project.end_time)}
                    </span>
                    <span className={styles.detailItem}>
                      <AttachMoneyIcon fontSize="small" />
                      ${project.total_value?.toLocaleString() || 'N/A'}
                    </span>
                    <span className={styles.detailItem}>
                      <LocationOnIcon fontSize="small" />
                      {project.address || 'N/A'}
                    </span>
                    <span className={styles.detailItem}>
                      <div className={styles.avatarGroup}>
                        {Array.isArray(project?.employees) && project.employees.length > 0 ? (
                          <>
                            {project.employees.slice(0, 3).map((employee, index) => (
                              <Tooltip key={employee.id} title={employee.name}>
                                <Avatar 
                                  className={styles.employeeAvatar}
                                  sx={{ 
                                    width: 24,
                                    height: 24,
                                    fontSize: '0.75rem',
                                    bgcolor: `hsl(${(index * 90) % 360}, 70%, 50%)`,
                                    border: '2px solid white',
                                    marginLeft: index > 0 ? '-8px' : 0,
                                    zIndex: 3 - index,
                                    cursor: 'pointer',
                                    '&:hover': {
                                      transform: 'scale(1.1)',
                                      transition: 'transform 0.2s'
                                    }
                                  }}
                                  onClick={(e) => handleEmployeeClick(e, employee.id)}
                                >
                                  {employee.name ? getInitials(employee.name) : '??'}
                                </Avatar>
                              </Tooltip>
                            ))}
                            {project.employees.length > 3 && (
                              <>
                                <Tooltip title={`${project.employees.length - 3} more employees`}>
                                  <Avatar 
                                    className={styles.employeeAvatar}
                                    sx={{ 
                                      width: 24,
                                      height: 24,
                                      fontSize: '0.75rem',
                                      bgcolor: 'grey.500',
                                      border: '2px solid white',
                                      marginLeft: '-8px',
                                      zIndex: 0,
                                      cursor: 'pointer',
                                      '&:hover': {
                                        transform: 'scale(1.1)',
                                        transition: 'transform 0.2s'
                                      }
                                    }}
                                    onClick={(e) => handleMoreEmployeesClick(e, project)}
                                  >
                                    +{project.employees.length - 3}
                                  </Avatar>
                                </Tooltip>

                                <Popover
                                  open={Boolean(moreEmployeesAnchorEl)}
                                  anchorEl={moreEmployeesAnchorEl}
                                  onClose={handleMoreEmployeesClose}
                                  anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                  }}
                                  transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                  }}
                                >
                                  <Box sx={{ p: 2, maxWidth: 300 }}>
                                    <Typography variant="subtitle2" sx={{ mb: 1 }}>All Employees</Typography>
                                    <div style={{ display: 'flex', flexWrap: 'wrap', gap: '8px' }}>
                                      {selectedProject?.employees.map((employee, index) => (
                                        <Tooltip key={employee.id} title={employee.name}>
                                          <Avatar
                                            sx={{
                                              width: 32,
                                              height: 32,
                                              fontSize: '0.875rem',
                                              bgcolor: `hsl(${(index * 90) % 360}, 70%, 50%)`,
                                              cursor: 'pointer',
                                              '&:hover': {
                                                transform: 'scale(1.1)',
                                                transition: 'transform 0.2s'
                                              }
                                            }}
                                            onClick={(e) => {
                                              handleEmployeeClick(e, employee.id);
                                              handleMoreEmployeesClose();
                                            }}
                                          >
                                            {getInitials(employee.name)}
                                          </Avatar>
                                        </Tooltip>
                                      ))}
                                    </div>
                                  </Box>
                                </Popover>
                              </>
                            )}
                          </>
                        ) : (
                          <Tooltip title="No employees assigned">
                            <Avatar 
                              className={styles.employeeAvatar}
                              sx={{ 
                                width: 24,
                                height: 24,
                                fontSize: '0.75rem',
                                bgcolor: 'grey.400',
                                border: '2px solid white',
                              }}
                            >
                              0
                            </Avatar>
                          </Tooltip>
                        )}
                      </div>
                    </span>
                  </div>
                  <div className={styles.projectStatus}>
                    <span 
                      style={{
                        padding: '4px 12px',
                        borderRadius: '16px',
                        fontSize: '0.875rem',
                        fontWeight: 500,
                        ...STATUS_STYLES[project.status || PROJECT_STATUSES.PENDING_SCHEDULING]
                      }}
                    >
                      {project.status 
                        ? project.status.split('_').map(word => 
                            word.charAt(0).toUpperCase() + word.slice(1)
                          ).join(' ')
                        : 'Pending Scheduling'}
                    </span>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>

        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => handleFilterClose()}
          PaperProps={{
            elevation: 0,
            className: styles.menuPaper
          }}
        >
          <MenuItem onClick={() => handleFilterClose('all')} className={styles.menuItem}>
            All Projects
          </MenuItem>
          <MenuItem onClick={() => handleFilterClose(PROJECT_STATUSES.PENDING_SCHEDULING)} className={styles.menuItem}>
            Pending Scheduling
          </MenuItem>
          <MenuItem onClick={() => handleFilterClose(PROJECT_STATUSES.SCHEDULED)} className={styles.menuItem}>
            Scheduled
          </MenuItem>
          <MenuItem onClick={() => handleFilterClose(PROJECT_STATUSES.IN_PROGRESS)} className={styles.menuItem}>
            In Progress
          </MenuItem>
          <MenuItem onClick={() => handleFilterClose(PROJECT_STATUSES.PENDING_INVOICING)} className={styles.menuItem}>
            Pending Invoicing
          </MenuItem>
          <MenuItem onClick={() => handleFilterClose(PROJECT_STATUSES.COMPLETED)} className={styles.menuItem}>
            Completed
          </MenuItem>
        </Menu>

        <Menu
          anchorEl={sortAnchorEl}
          open={Boolean(sortAnchorEl)}
          onClose={() => handleSortClose()}
          PaperProps={{
            elevation: 0,
            className: styles.menuPaper
          }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
          <MenuItem 
            onClick={() => handleSortClose('newest')}
            className={styles.menuItem}
          >
            <span style={{ color: '#000000' }}>Newest First</span>
          </MenuItem>
          <MenuItem 
            onClick={() => handleSortClose('oldest')}
            className={styles.menuItem}
          >
            <span style={{ color: '#000000' }}>Oldest First</span>
          </MenuItem>
        </Menu>
      </div>
    </div>
  );
};

export default Projects;
