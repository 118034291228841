import React from 'react';
import { Paper, Typography } from '@mui/material';
import {
  ComposedChart,
  Line,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from 'recharts';

const data = [
  { name: 'Jan', projects: 20, completion: 85, trend: 90 },
  { name: 'Feb', projects: 15, completion: 89, trend: 86 },
  { name: 'Mar', projects: 25, completion: 92, trend: 88 },
  { name: 'Apr', projects: 22, completion: 87, trend: 89 },
  { name: 'May', projects: 28, completion: 91, trend: 91 },
];

const ProjectTrendsChart = () => (
  <Paper elevation={0} sx={{ p: 3, height: '100%', border: '1px solid #e0e0e0', borderRadius: 1 }}>
    <Typography variant="h6" gutterBottom sx={{ color: '#34495e' }}>
      Project Trends
    </Typography>
    <ResponsiveContainer width="100%" height={400}>
      <ComposedChart data={data}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="projects" fill="#8884d8" />
        <Line type="monotone" dataKey="completion" stroke="#82ca9d" />
        <Line type="monotone" dataKey="trend" stroke="#ffc658" />
      </ComposedChart>
    </ResponsiveContainer>
  </Paper>
);

export default ProjectTrendsChart; 