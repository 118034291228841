import React, { useState } from 'react';
import { Typography, Paper, Box, Button, TextField, Grid, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import styles from './AdminSettings.module.scss';
import { useTheme } from '@mui/material/styles';

function AdminSettingsPayroll() {
  const { darkMode } = useTheme();
  const [payrollSettings, setPayrollSettings] = useState({
    payPeriod: 'biweekly',
    payDay: 'friday',
    payrollProvider: '',
    accountNumber: '',
    routingNumber: '',
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setPayrollSettings((prevSettings) => ({
      ...prevSettings,
      [name]: value,
    }));
  };

  const handleSaveSettings = () => {
    console.log('Saving payroll settings:', payrollSettings);
    // Implement the logic to save the payroll settings
  };

  const inputStyles = {
    '& .MuiInputLabel-root': {
      color: darkMode ? 'var(--text-secondary)' : 'inherit'
    },
    '& .MuiOutlinedInput-root': {
      backgroundColor: darkMode ? 'rgba(30, 41, 47, 0.5)' : 'inherit',
      color: darkMode ? 'var(--text-primary)' : 'inherit',
      '& fieldset': {
        borderColor: darkMode ? 'rgba(145, 158, 171, 0.32)' : 'inherit'
      },
      '&:hover fieldset': {
        borderColor: darkMode ? 'var(--text-secondary)' : 'inherit'
      },
      '&.Mui-focused fieldset': {
        borderColor: darkMode ? '#90caf9' : '#1976d2'
      }
    },
    '& .MuiInputBase-input': {
      color: darkMode ? 'var(--text-primary)' : 'inherit'
    },
    '& .MuiSelect-icon': {
      color: darkMode ? 'var(--text-secondary)' : 'inherit'
    }
  };

  return (
    <Paper 
      className={`${styles.tabPanel} ${darkMode ? styles.darkMode : ''}`}
      sx={{
        backgroundColor: darkMode ? 'var(--background-paper)' : '#fff',
      }}
    >
      <Box 
        display="flex" 
        alignItems="center" 
        mb={3}
        className={styles.sectionHeader}
      >
        <MonetizationOnIcon 
          className={styles.sectionIcon}
          sx={{ 
            color: darkMode ? '#90caf9' : '#1976d2',
            fontSize: '2rem',
            marginRight: '12px'
          }} 
        />
        <Typography 
          variant="h5" 
          component="h2" 
          sx={{ 
            color: darkMode ? 'var(--text-primary)' : 'inherit',
            fontWeight: 600,
            fontSize: '1.5rem',
          }}
        >
          Payroll Settings
        </Typography>
      </Box>

      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth margin="normal">
            <InputLabel sx={{ color: darkMode ? 'var(--text-secondary)' : 'inherit' }}>
              Pay Period
            </InputLabel>
            <Select
              name="payPeriod"
              value={payrollSettings.payPeriod}
              onChange={handleInputChange}
              variant="outlined"
              className={`${styles.formGroup} ${darkMode ? styles.darkMode : ''}`}
              sx={inputStyles}
            >
              <MenuItem value="weekly">Weekly</MenuItem>
              <MenuItem value="biweekly">Bi-weekly</MenuItem>
              <MenuItem value="monthly">Monthly</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} md={6}>
          <FormControl fullWidth margin="normal">
            <InputLabel sx={{ color: darkMode ? 'var(--text-secondary)' : 'inherit' }}>
              Pay Day
            </InputLabel>
            <Select
              name="payDay"
              value={payrollSettings.payDay}
              onChange={handleInputChange}
              variant="outlined"
              className={`${styles.formGroup} ${darkMode ? styles.darkMode : ''}`}
              sx={inputStyles}
            >
              <MenuItem value="monday">Monday</MenuItem>
              <MenuItem value="tuesday">Tuesday</MenuItem>
              <MenuItem value="wednesday">Wednesday</MenuItem>
              <MenuItem value="thursday">Thursday</MenuItem>
              <MenuItem value="friday">Friday</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            margin="normal"
            label="Payroll Provider"
            name="payrollProvider"
            value={payrollSettings.payrollProvider}
            onChange={handleInputChange}
            variant="outlined"
            className={`${styles.formGroup} ${darkMode ? styles.darkMode : ''}`}
            sx={inputStyles}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            margin="normal"
            label="Account Number"
            name="accountNumber"
            value={payrollSettings.accountNumber}
            onChange={handleInputChange}
            variant="outlined"
            className={`${styles.formGroup} ${darkMode ? styles.darkMode : ''}`}
            sx={inputStyles}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            margin="normal"
            label="Routing Number"
            name="routingNumber"
            value={payrollSettings.routingNumber}
            onChange={handleInputChange}
            variant="outlined"
            className={`${styles.formGroup} ${darkMode ? styles.darkMode : ''}`}
            sx={inputStyles}
          />
        </Grid>

        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSaveSettings}
            className={darkMode ? styles.darkButton : ''}
          >
            Save Payroll Settings
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
}

export default AdminSettingsPayroll;
