import React, { useState, useCallback } from 'react';
import { Grid, Typography, Box, Paper } from '@mui/material';
import EventEmployeeAssignment from './EventEmployeeAssignment';
import EventAssignmentsList from './EventAssignmentsList';
import styles from './EmployeeAssignment.module.scss';
import GroupIcon from '@mui/icons-material/Group';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import GroupsIcon from '@mui/icons-material/Groups';

const EmployeeAssignment = ({ projectId, onUpdate }) => {
  const [refreshTrigger, setRefreshTrigger] = useState(0);

  const handleAssignmentUpdate = useCallback(() => {
    setRefreshTrigger(prev => prev + 1);
    onUpdate();
  }, [onUpdate]);

  return (
    <Box className={styles.assignmentContainer}>
      <Box className={styles.header}>
        <GroupIcon className={styles.headerIcon} />
        <Typography variant="h6" className={styles.headerTitle}>
          Employee Assignment
        </Typography>
      </Box>
      <Grid container spacing={3} className={styles.gridContainer}>
        <Grid item xs={12} md={8}>
          <Paper elevation={0} className={styles.assignmentSection}>
            <Box className={styles.sectionHeader}>
              <PersonAddIcon className={styles.sectionIcon} />
              <Typography variant="subtitle1" className={styles.sectionTitle}>
                Available Employees
              </Typography>
            </Box>
            <Box className={styles.sectionContent}>
              <EventEmployeeAssignment 
                projectId={projectId} 
                onAssignmentUpdate={handleAssignmentUpdate}
              />
            </Box>
          </Paper>
        </Grid>
        
        <Grid item xs={12} md={4}>
          <Paper elevation={0} className={styles.assignmentSection}>
            <Box className={styles.sectionHeader}>
              <GroupsIcon className={styles.sectionIcon} />
              <Typography variant="subtitle1" className={styles.sectionTitle}>
                Assigned Employees
              </Typography>
            </Box>
            <Box className={styles.sectionContent}>
              <EventAssignmentsList 
                projectId={projectId} 
                refreshTrigger={refreshTrigger}
              />
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default EmployeeAssignment;