import React from 'react';
import { Box, Typography, Avatar } from '@mui/material';
import { styled } from '@mui/material/styles';

const Message = styled(Box)(({ theme, sent }) => ({
  maxWidth: '100%',
  padding: theme.spacing(1.5, 2),
  borderRadius: '16px',
  backgroundColor: sent ? theme.palette.primary.main : theme.palette.background.paper,
  color: sent ? theme.palette.primary.contrastText : theme.palette.text.primary,
  fontFamily: 'Urbanist, sans-serif',
  boxShadow: '0 2px 4px rgba(0,0,0,0.05)',
  border: sent ? 'none' : `1px solid ${theme.palette.divider}`,
  position: 'relative',
  '&:after': {
    content: '""',
    position: 'absolute',
    bottom: 0,
    [sent ? 'right' : 'left']: -8,
    borderStyle: 'solid',
    borderWidth: '8px 8px 0 8px',
    borderColor: `${sent ? theme.palette.primary.main : theme.palette.background.paper} transparent transparent transparent`,
    transform: sent ? 'rotate(-45deg)' : 'rotate(45deg)',
    transformOrigin: sent ? 'right bottom' : 'left bottom'
  }
}));

const MessageBubble = ({ message, showAvatar }) => {
  return (
    <Box sx={{ 
      display: 'flex', 
      alignItems: 'flex-end', 
      gap: 1,
      flexDirection: message.sent ? 'row-reverse' : 'row'
    }}>
      {showAvatar && (
        <Avatar 
          sx={{ 
            width: 28, 
            height: 28,
            display: showAvatar ? 'block' : 'none'
          }}
        />
      )}
      <Message sent={message.sent}>
        <Typography variant="body1">{message.text}</Typography>
        <Typography 
          variant="caption" 
          sx={{ 
            mt: 0.5, 
            opacity: 0.7, 
            display: 'block',
            textAlign: message.sent ? 'right' : 'left'
          }}
        >
          {message.time}
        </Typography>
      </Message>
    </Box>
  );
};

export default MessageBubble; 