import React, { useState } from 'react';
import {
  TextField,
  Typography,
  Box,
  ClickAwayListener,
  IconButton,
} from '@mui/material';
import { Edit as EditIcon } from '@mui/icons-material';

const EditableElement = ({ 
  value, 
  onChange, 
  variant = 'body1', 
  component = 'p',
  multiline = false,
  sx = {} 
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [showEdit, setShowEdit] = useState(false);

  return (
    <Box
      onMouseEnter={() => setShowEdit(true)}
      onMouseLeave={() => setShowEdit(false)}
      sx={{ position: 'relative', ...sx }}
    >
      {isEditing ? (
        <ClickAwayListener onClickAway={() => setIsEditing(false)}>
          <TextField
            fullWidth
            multiline={multiline}
            rows={multiline ? 4 : 1}
            value={value}
            onChange={(e) => onChange(e.target.value)}
            autoFocus
            onKeyPress={(e) => {
              if (e.key === 'Enter' && !multiline) {
                setIsEditing(false);
              }
            }}
            sx={{ my: -1 }}
          />
        </ClickAwayListener>
      ) : (
        <>
          <Typography
            variant={variant}
            component={component}
            onClick={() => setIsEditing(true)}
            sx={{
              cursor: 'pointer',
              '&:hover': {
                backgroundColor: 'rgba(0,0,0,0.04)',
              },
            }}
          >
            {value}
          </Typography>
          {showEdit && (
            <IconButton
              size="small"
              sx={{
                position: 'absolute',
                right: -28,
                top: '50%',
                transform: 'translateY(-50%)',
                opacity: 0.5,
              }}
              onClick={() => setIsEditing(true)}
            >
              <EditIcon fontSize="small" />
            </IconButton>
          )}
        </>
      )}
    </Box>
  );
};

export default EditableElement; 