import React, { useState, useEffect } from 'react';
import { 
  Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, 
  CircularProgress, Select, MenuItem, FormControl, InputLabel, Box, Grid, Card, CardContent, 
  Dialog, DialogTitle, DialogContent, DialogActions, 
  Button, Popover
} from '@mui/material';
import { getEmployeeTimesheets } from '../services/api';
import { 
  AccessTime, EventNote, AttachMoney, WorkOutline, 
  CalendarToday, HourglassEmpty, CheckCircleOutline
} from '@mui/icons-material';
import styles from './EmployeeTimesheets.module.scss';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import TextField from '@mui/material/TextField';
import { getStartOfDay, getEndOfDay, formatDateTime } from '../utils/dateUtils';

const EmployeeTimesheets = () => {
  const [timesheets, setTimesheets] = useState([]);
  const [summary, setSummary] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [filter, setFilter] = useState('');
  const [projects, setProjects] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [dateRange, setDateRange] = useState('all');
  const [anchorEl, setAnchorEl] = useState(null);
  const [tempStartDate, setTempStartDate] = useState(null);
  const [tempEndDate, setTempEndDate] = useState(null);
  const [showCustomDates, setShowCustomDates] = useState(false);

  useEffect(() => {
    fetchTimesheets();
  }, []);

  const fetchTimesheets = async () => {
    try {
      setLoading(true);
      const response = await getEmployeeTimesheets();
      if (response.timesheets && Array.isArray(response.timesheets)) {
        setTimesheets(response.timesheets);
        setSummary(response.summary);
        const uniqueProjects = [...new Set(response.timesheets.map(ts => ts.event.project_name))];
        setProjects(['All', ...uniqueProjects]);
      } else {
        throw new Error('Invalid data received from server');
      }
      setError(null);
    } catch (err) {
      console.error('Error fetching timesheets:', err);
      setError('Failed to fetch timesheets. Please try again later.');
      setTimesheets([]);
      setSummary(null);
    } finally {
      setLoading(false);
      // Set isLoaded to true after a short delay to trigger the animation
      setTimeout(() => setIsLoaded(true), 100);
    }
  };

  const filteredTimesheets = timesheets.filter(timesheet => {
    const projectMatch = filter === 'All' || filter === '' || timesheet.event.project_name === filter;
    const dateMatch = (!startDate || new Date(timesheet.clock_in) >= getStartOfDay(startDate)) &&
                     (!endDate || new Date(timesheet.clock_in) <= getEndOfDay(endDate));
    return projectMatch && dateMatch;
  });

  const dateRangeOptions = [
    { value: 'lastWeek', label: 'Last week' },
    { value: 'last30', label: 'Last 30 days' },
    { value: 'lastMonth', label: 'Last month' },
    { value: 'thisYear', label: 'This year' },
    { value: 'last12Months', label: 'Last 12 months' },
    { value: 'custom', label: 'Custom range' }
  ];

  const handleDateRangeChange = (event) => {
    const value = event.target.value;
    setDateRange(value);
    setShowCustomDates(value === 'custom');

    if (value !== 'custom') {
      const now = new Date();
      let start = null;
      let end = null;

      switch (value) {
        case 'lastWeek':
          start = new Date(now);
          start.setDate(now.getDate() - 7);
          break;
        case 'last30':
          start = new Date(now);
          start.setDate(now.getDate() - 30);
          break;
        case 'lastMonth':
          start = new Date(now.getFullYear(), now.getMonth() - 1, 1);
          end = new Date(now.getFullYear(), now.getMonth(), 0);
          break;
        case 'thisYear':
          start = new Date(now.getFullYear(), 0, 1);
          break;
        case 'last12Months':
          start = new Date(now);
          start.setFullYear(now.getFullYear() - 1);
          break;
        default:
          start = null;
          end = null;
      }

      if (start) start.setHours(0, 0, 0, 0);
      if (!end) end = new Date(now.setHours(23, 59, 59, 999));
      
      setStartDate(start);
      setEndDate(end);
    }
  };

  const handleCustomDateSubmit = () => {
    if (tempStartDate && tempEndDate) {
      setStartDate(tempStartDate);
      setEndDate(tempEndDate);
      setDateRange('custom');
    }
    setAnchorEl(null);
  };

  const handleCustomDateCancel = () => {
    setTempStartDate(null);
    setTempEndDate(null);
    setAnchorEl(null);
    if (dateRange === 'custom' && !startDate) {
      setDateRange('all'); // Reset only if no custom date was previously set
    }
  };

  if (loading) return <div className={styles.loadingContainer}><CircularProgress /></div>;
  if (error) return <div className={styles.errorContainer}><Typography color="error">{error}</Typography></div>;
  if (timesheets.length === 0) return <div className={styles.errorContainer}><Typography>No timesheets found.</Typography></div>;

  const headerCells = [
    { icon: <AccessTime />, label: 'Clock In', color: '#3498db' },
    { icon: <EventNote />, label: 'Clock Out', color: '#e74c3c' },
    { icon: <HourglassEmpty />, label: 'Hours Worked', color: '#f39c12' },
    { icon: <WorkOutline />, label: 'Project', color: '#9b59b6' },
    { icon: <AttachMoney />, label: 'Payment Type', color: '#2ecc71' },
    { icon: <CalendarToday />, label: 'Rate/Percentage', color: '#1abc9c' },
    { icon: <CheckCircleOutline />, label: 'Calculated Payment', color: '#2ecc71' },
  ];

  return (
    <div className={`${styles.timesheetsContainer} ${isLoaded ? styles.fadeIn : ''}`}>
  
      
      {summary && (
        <Grid container spacing={3} className={styles.summaryContainer}>
          <Grid item xs={12} md={6}>
            <Card className={styles.summaryCard}>
              <CardContent>
                <Typography variant="h6" gutterBottom className={styles.summaryTitle}>
                  <HourglassEmpty className={styles.summaryIcon} />
                  Total Hours Worked
                </Typography>
                <Typography variant="h4">{summary.total_hours_worked.toFixed(2)}</Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card className={styles.summaryCard}>
              <CardContent>
                <Typography variant="h6" gutterBottom className={styles.summaryTitle}>
                  <AttachMoney className={styles.summaryIcon} />
                  Total Calculated Payment
                </Typography>
                <Typography variant="h4">${summary.total_calculated_payment.toFixed(2)}</Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      )}

      <Box className={styles.filterContainer}>
        <Box className={styles.filterWrapper}>
          <Typography variant="subtitle2" className={styles.filterLabel}>Project</Typography>
          <FormControl variant="outlined" className={styles.filterSelect}>
            <Select
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
              label="Filter by Project"
            >
              {projects.map((project) => (
                <MenuItem key={project} value={project}>{project}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>

        <Box className={styles.filterWrapper}>
          <Typography variant="subtitle2" className={styles.filterLabel}>Date Range</Typography>
          <FormControl variant="outlined" className={styles.filterSelect}>
            <Select
              value={dateRange}
              onChange={handleDateRangeChange}
              displayEmpty
            >
              <MenuItem value="all">All Time</MenuItem>
              {dateRangeOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>

        {showCustomDates && (
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Box className={styles.filterWrapper}>
              <Typography variant="subtitle2" className={styles.filterLabel}>Start Date</Typography>
              <DatePicker
                value={startDate}
                onChange={(newValue) => setStartDate(newValue)}
                renderInput={(params) => (
                  <TextField 
                    {...params} 
                    size="small"
                    fullWidth
                    className={styles.datePicker}
                  />
                )}
                maxDate={endDate || new Date()}
              />
            </Box>

            <Box className={styles.filterWrapper}>
              <Typography variant="subtitle2" className={styles.filterLabel}>End Date</Typography>
              <DatePicker
                value={endDate}
                onChange={(newValue) => setEndDate(newValue)}
                renderInput={(params) => (
                  <TextField 
                    {...params} 
                    size="small"
                    fullWidth
                    className={styles.datePicker}
                  />
                )}
                minDate={startDate}
                maxDate={new Date()}
              />
            </Box>
          </LocalizationProvider>
        )}
      </Box>

      <TableContainer className={styles.tableContainer}>
        <Table>
          <TableHead>
            <TableRow className={styles.tableHeader}>
              {headerCells.map((cell, index) => (
                <TableCell key={index} className={styles.tableCell} align="center">
                  <div className={styles.headerContent}>
                    <span className={styles.headerIcon} style={{ color: cell.color }}>
                      {cell.icon}
                    </span>
                    <span className={styles.headerLabel}>{cell.label}</span>
                  </div>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredTimesheets.map((timesheet) => (
              <TableRow key={timesheet.id} className={styles.tableRow}>
                <TableCell className={styles.tableCell} align="center">
                  {formatDateTime(timesheet.clock_in)}
                </TableCell>
                <TableCell className={styles.tableCell} align="center">
                  {timesheet.clock_out ? formatDateTime(timesheet.clock_out) : 'Not clocked out'}
                </TableCell>
                <TableCell className={styles.tableCell} align="center">
                  {timesheet.hours_worked ? timesheet.hours_worked.toFixed(2) : 'N/A'}
                </TableCell>
                <TableCell className={styles.tableCell} align="center">
                  {timesheet.event.project_name || 'N/A'}
                </TableCell>
                <TableCell className={styles.tableCell} align="center">
                  {timesheet.payment_type || 'N/A'}
                </TableCell>
                <TableCell className={styles.tableCell} align="center">
                  {timesheet.payment_type === 'percentage' 
                    ? `${timesheet.percentage}%` 
                    : timesheet.rate ? `$${timesheet.rate}${timesheet.payment_type === 'hourly' ? '/hr' : ''}` : 'N/A'
                  }
                </TableCell>
                <TableCell className={styles.tableCell} align="center">
                  ${timesheet.calculated_payment ? timesheet.calculated_payment.toFixed(2) : 'N/A'}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleCustomDateCancel}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={{
          className: styles.customDatePopover
        }}
      >
        <Box className={styles.customDateContainer}>
          <Typography variant="subtitle1" sx={{ mb: 2, fontWeight: 500 }}>
            Select Custom Date Range
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="Start Date"
                value={tempStartDate}
                onChange={(newValue) => setTempStartDate(newValue)}
                renderInput={(params) => (
                  <TextField {...params} fullWidth size="small" />
                )}
                maxDate={tempEndDate || new Date()}
              />
              <DatePicker
                label="End Date"
                value={tempEndDate}
                onChange={(newValue) => setTempEndDate(newValue)}
                renderInput={(params) => (
                  <TextField {...params} fullWidth size="small" />
                )}
                minDate={tempStartDate}
                maxDate={new Date()}
              />
            </LocalizationProvider>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1, mt: 2 }}>
            <Button onClick={handleCustomDateCancel} size="small">
              Cancel
            </Button>
            <Button 
              onClick={handleCustomDateSubmit} 
              variant="contained" 
              size="small"
              disabled={!tempStartDate || !tempEndDate}
            >
              Apply
            </Button>
          </Box>
        </Box>
      </Popover>
    </div>
  );
};

export default EmployeeTimesheets;
