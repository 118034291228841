import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Stack,
  MenuItem,
  Typography,
  Stepper,
  Step,
  StepLabel,
  Box,
  IconButton,
} from '@mui/material';
import {
  Close as CloseIcon,
  Email as EmailIcon,
  Sms as SmsIcon,
  Phone as PhoneIcon,
  WhatsApp as WhatsAppIcon,
} from '@mui/icons-material';

const campaignTypes = [
  { value: 'email', label: 'Email Campaign', icon: EmailIcon },
  { value: 'sms', label: 'SMS Campaign', icon: SmsIcon },
  { value: 'call', label: 'Call Campaign', icon: PhoneIcon },
  { value: 'whatsapp', label: 'WhatsApp Campaign', icon: WhatsAppIcon },
];

const steps = ['Campaign Type', 'Basic Info', 'Audience', 'Content', 'Review'];

const CampaignCreate = ({ open, onClose }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [campaignData, setCampaignData] = useState({
    type: '',
    name: '',
    description: '',
    audience: '',
    startDate: '',
    endDate: '',
  });

  const handleNext = () => {
    setActiveStep((prev) => prev + 1);
  };

  const handleBack = () => {
    setActiveStep((prev) => prev - 1);
  };

  const handleChange = (field) => (event) => {
    setCampaignData({ ...campaignData, [field]: event.target.value });
  };

  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <Stack spacing={3}>
            <Typography variant="subtitle1" color="text.secondary">
              Select the type of campaign you want to create
            </Typography>
            {campaignTypes.map((type) => (
              <Button
                key={type.value}
                variant={campaignData.type === type.value ? "contained" : "outlined"}
                startIcon={<type.icon />}
                onClick={() => handleChange('type')({ target: { value: type.value } })}
                sx={{
                  py: 2,
                  justifyContent: 'flex-start',
                  textTransform: 'none',
                  borderRadius: 2,
                }}
              >
                {type.label}
              </Button>
            ))}
          </Stack>
        );

      case 1:
        return (
          <Stack spacing={3}>
            <TextField
              label="Campaign Name"
              value={campaignData.name}
              onChange={handleChange('name')}
            />
            <TextField
              label="Description"
              value={campaignData.description}
              onChange={handleChange('description')}
            />
          </Stack>
        );

      case 2:
        return (
          <Stack spacing={3}>
            <TextField
              label="Audience"
              value={campaignData.audience}
              onChange={handleChange('audience')}
            />
          </Stack>
        );

      case 3:
        return (
          <Stack spacing={3}>
            <TextField
              label="Content"
              value={campaignData.content}
              onChange={handleChange('content')}
            />
          </Stack>
        );

      case 4:
        return (
          <Stack spacing={3}>
            <TextField
              label="Start Date"
              type="date"
              value={campaignData.startDate}
              onChange={handleChange('startDate')}
            />
            <TextField
              label="End Date"
              type="date"
              value={campaignData.endDate}
              onChange={handleChange('endDate')}
            />
          </Stack>
        );

      default:
        return null;
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Create Campaign</DialogTitle>
      <DialogContent>
        <Stepper activeStep={activeStep} sx={{ mb: 4 }}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>

        <Box sx={{ mt: 4, mb: 4 }}>
          {renderStepContent(activeStep)}
        </Box>

        <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
          {activeStep !== 0 && (
            <Button onClick={handleBack}>
              Back
            </Button>
          )}
          {activeStep === steps.length - 1 ? (
            <Button
              variant="contained"
              onClick={handleNext}
              startIcon={<CloseIcon />}
            >
              Close
            </Button>
          ) : (
            <Button
              variant="contained"
              onClick={handleNext}
            >
              Next
            </Button>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>
          Cancel
        </Button>
        <Button onClick={handleNext}>
          Next
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CampaignCreate; 