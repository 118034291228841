import React, { useState } from 'react';
import { 
  Box, 
  Typography, 
  Switch, 
  FormGroup, 
  FormControlLabel, 
  Paper,
  Alert,
  Button 
} from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import api from '../services/api';
import { useTheme } from '@mui/material/styles';
import styles from './AdminSettings.module.scss';

function AdminSettingsNotifications() {
  const { darkMode } = useTheme();
  const [settings, setSettings] = useState({
    emailNotifications: false,
    projectUpdates: false,
    employeeClockIns: false
  });

  const [testStatus, setTestStatus] = useState(null);

  const handleChange = (event) => {
    setSettings({
      ...settings,
      [event.target.name]: event.target.checked
    });
  };

  const testNotification = async () => {
    try {
      const response = await api.post('/notifications/test', {
        title: 'Test Notification',
        message: 'This is a test notification',
        type: 'info'
      });
      
      if (response.status === 200) {
        setTestStatus('success');
        setTimeout(() => setTestStatus(null), 3000); // Clear after 3 seconds
      } else {
        setTestStatus('error');
      }
    } catch (error) {
      console.error('Error creating test notification:', error);
      setTestStatus('error');
    }
  };

  return (
    <Paper 
      className={`${styles.tabPanel} ${darkMode ? styles.darkMode : ''}`}
      sx={{
        backgroundColor: darkMode ? 'var(--background-paper)' : '#fff',
      }}
    >
      <Box 
        display="flex" 
        alignItems="center" 
        mb={3}
        className={styles.sectionHeader}
      >
        <NotificationsIcon 
          className={styles.sectionIcon}
          sx={{ 
            color: darkMode ? '#90caf9' : '#1976d2',
            fontSize: '2rem',
            marginRight: '12px'
          }} 
        />
        <Typography 
          variant="h5" 
          component="h2" 
          sx={{ 
            color: darkMode ? 'var(--text-primary)' : 'inherit',
            fontWeight: 600,
            fontSize: '1.5rem',
          }}
        >
          Notification Settings
        </Typography>
      </Box>

      {testStatus === 'success' && (
        <Alert severity="success" sx={{ mb: 3 }}>
          Test notification created successfully!
        </Alert>
      )}

      {testStatus === 'error' && (
        <Alert severity="error" sx={{ mb: 3 }}>
          Error creating test notification
        </Alert>
      )}
      
      <FormGroup>
        <FormControlLabel
          control={
            <Switch 
              checked={settings.emailNotifications}
              onChange={handleChange}
              name="emailNotifications"
              sx={{
                '& .MuiSwitch-switchBase.Mui-checked': {
                  color: darkMode ? '#90caf9' : '#1976d2',
                },
                '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                  backgroundColor: darkMode ? '#90caf9' : '#1976d2',
                }
              }}
            />
          }
          label={
            <Typography sx={{ color: darkMode ? 'var(--text-primary)' : 'inherit' }}>
              Email Notifications
            </Typography>
          }
        />
        <FormControlLabel
          control={
            <Switch 
              checked={settings.projectUpdates}
              onChange={handleChange}
              name="projectUpdates"
              sx={{
                '& .MuiSwitch-switchBase.Mui-checked': {
                  color: darkMode ? '#90caf9' : '#1976d2',
                },
                '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                  backgroundColor: darkMode ? '#90caf9' : '#1976d2',
                }
              }}
            />
          }
          label={
            <Typography sx={{ color: darkMode ? 'var(--text-primary)' : 'inherit' }}>
              Project Updates
            </Typography>
          }
        />
        <FormControlLabel
          control={
            <Switch 
              checked={settings.employeeClockIns}
              onChange={handleChange}
              name="employeeClockIns"
              sx={{
                '& .MuiSwitch-switchBase.Mui-checked': {
                  color: darkMode ? '#90caf9' : '#1976d2',
                },
                '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                  backgroundColor: darkMode ? '#90caf9' : '#1976d2',
                }
              }}
            />
          }
          label={
            <Typography sx={{ color: darkMode ? 'var(--text-primary)' : 'inherit' }}>
              Employee Clock In/Out Alerts
            </Typography>
          }
        />
      </FormGroup>

      <Button 
        variant="contained" 
        onClick={testNotification}
        sx={{ 
          mt: 2,
          backgroundColor: darkMode ? '#90caf9' : '#1976d2',
          '&:hover': {
            backgroundColor: darkMode ? '#64b5f6' : '#1565c0'
          }
        }}
        className={darkMode ? styles.darkButton : ''}
      >
        Send Test Notification
      </Button>
    </Paper>
  );
}

export default AdminSettingsNotifications; 