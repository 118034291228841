import React, { useState } from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  IconButton,
  Typography,
  Grid,
  Card,
  CardContent,
} from '@mui/material';
import { Close as CloseIcon, Preview as PreviewIcon } from '@mui/icons-material';
import FormStyleSection from './sections/FormStyleSection';
import FormPreviewSection from './sections/FormPreviewSection';
import FormBasicSection from './sections/FormBasicSection';
import FormTemplateSection from './components/FormTemplateSection';
import FormFieldsSection from './sections/FormFieldsSection';
import AllTemplatesModal from './components/AllTemplatesModal';

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    borderRadius: 16,
    padding: 0,
    maxWidth: '95vw',
    width: '100%',
    height: '95vh',
    maxHeight: '95vh',
    overflow: 'hidden'
  }
}));

const theme = createTheme({
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: 'rgba(0, 0, 0, 0.23)',
            borderWidth: 1,
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: 'rgba(0, 0, 0, 0.87)',
            borderWidth: 1,
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#2196f3',
            borderWidth: 2,
          }
        }
      }
    },
    MuiTextField: {
      defaultProps: {
        variant: 'outlined',
        fullWidth: true,
      },
      styleOverrides: {
        root: {
          '& .MuiInputBase-root': {
            borderRadius: 8,
          }
        }
      }
    }
  }
});

const StyledSection = styled(Box)(({ theme }) => ({
  backgroundColor: '#ffffff',
  borderRadius: '12px',
  border: '2px solid #e0e0e0',
  padding: theme.spacing(3),
  marginBottom: theme.spacing(3),
  boxShadow: '0 2px 8px rgba(0,0,0,0.08)',
  '&:hover': {
    borderColor: theme.palette.primary.main,
    boxShadow: '0 4px 12px rgba(0,0,0,0.12)',
  },
}));

const SectionTitle = styled(Typography)(({ theme }) => ({
  fontSize: '1.1rem',
  fontWeight: 600,
  color: '#1a237e',
  marginBottom: theme.spacing(2),
  paddingBottom: theme.spacing(1),
  borderBottom: '2px solid #e3f2fd',
}));

const FieldCard = styled(Card)(({ theme }) => ({
  border: '2px solid #e0e0e0',
  borderRadius: '8px',
  marginBottom: theme.spacing(2),
  '&:hover': {
    borderColor: theme.palette.primary.main,
    boxShadow: '0 4px 8px rgba(33, 150, 243, 0.15)',
  },
  position: 'relative',
  overflow: 'visible',
  '&:before': {
    content: '""',
    position: 'absolute',
    left: '-10px',
    top: '50%',
    transform: 'translateY(-50%)',
    width: '4px',
    height: '40%',
    backgroundColor: theme.palette.primary.main,
    borderRadius: '4px',
  },
}));

const TemplateCard = styled(Card)(({ theme, primaryColor, secondaryColor, borderRadius }) => ({
  border: '2px solid #e0e0e0',
  borderRadius: borderRadius || '12px',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  overflow: 'visible',
  '&:before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    height: '6px',
    background: `linear-gradient(90deg, ${primaryColor}, ${secondaryColor || primaryColor}88)`,
    borderRadius: `${borderRadius} ${borderRadius} 0 0`,
  },
  '&:hover': {
    borderColor: primaryColor,
    transform: 'translateY(-4px)',
    boxShadow: `0 6px 16px ${primaryColor}25`,
  },
  transition: 'all 0.3s ease',
}));

const FormCreate = ({ open, onClose }) => {
  const initialFormData = {
    name: "",
    description: "",
    fields: [],
    style: {
      primaryColor: "#2196f3",
      secondaryColor: "#1976d2",
      backgroundColor: "#ffffff",
      borderRadius: "8px",
    }
  };

  const [formData, setFormData] = useState(initialFormData);

  const [templatesModalOpen, setTemplatesModalOpen] = useState(false);

  const handleStyleChange = (field) => (event) => {
    setFormData(prev => ({
      ...prev,
      style: {
        ...prev.style,
        [field]: event.target.value
      }
    }));
  };

  const templates = {
    'contact': {
      name: 'Contact Form',
      description: 'Basic contact information collection form',
      type: 'contact',
      fields: [
        {
          type: 'text',
          label: 'Full Name',
          required: true,
          placeholder: 'Enter your full name',
        },
        {
          type: 'email',
          label: 'Email Address',
          required: true,
          placeholder: 'your@email.com',
        },
        {
          type: 'tel',
          label: 'Phone Number',
          required: false,
          placeholder: '+1 (123) 456-7890',
        },
        {
          type: 'textarea',
          label: 'Message',
          required: true,
          placeholder: 'How can we help you?',
        }
      ]
    },
    'feedback': {
      name: 'Feedback Form',
      description: 'Customer feedback and satisfaction survey',
      type: 'feedback',
      fields: [
        {
          type: 'text',
          label: 'Name',
          required: true,
          placeholder: 'Your name',
        },
        {
          type: 'email',
          label: 'Email',
          required: true,
          placeholder: 'your@email.com',
        },
        {
          type: 'select',
          label: 'How did you hear about us?',
          required: true,
          options: ['Search Engine', 'Social Media', 'Friend', 'Advertisement', 'Other'],
        },
        {
          type: 'rating',
          label: 'Rate your experience',
          required: true,
        },
        {
          type: 'radio',
          label: 'Would you recommend us?',
          required: true,
          options: ['Definitely', 'Maybe', 'Not sure', 'No'],
        },
        {
          type: 'textarea',
          label: 'Your Feedback',
          required: true,
          placeholder: 'Please share your experience with us',
        }
      ]
    },
    'registration': {
      name: 'Event Registration',
      description: 'Event registration and participant information form',
      type: 'registration',
      fields: [
        {
          type: 'text',
          label: 'Full Name',
          required: true,
          placeholder: 'Enter your full name',
        },
        {
          type: 'email',
          label: 'Email Address',
          required: true,
          placeholder: 'your@email.com',
        },
        {
          type: 'tel',
          label: 'Phone Number',
          required: true,
          placeholder: '+1 (123) 456-7890',
        },
        {
          type: 'select',
          label: 'T-Shirt Size',
          required: true,
          options: ['S', 'M', 'L', 'XL', 'XXL'],
        },
        {
          type: 'radio',
          label: 'Meal Preference',
          required: true,
          options: ['Vegetarian', 'Non-Vegetarian', 'Vegan', 'Other'],
        },
        {
          type: 'textarea',
          label: 'Special Requirements',
          required: false,
          placeholder: 'Any dietary restrictions or special needs?',
        },
        {
          type: 'checkbox',
          label: 'I agree to the terms and conditions',
          required: true,
        }
      ]
    },
    'job': {
      name: 'Job Application',
      description: 'Job application and candidate information form',
      type: 'job',
      fields: [
        {
          type: 'text',
          label: 'Full Name',
          required: true,
          placeholder: 'Enter your full name',
        },
        {
          type: 'email',
          label: 'Email Address',
          required: true,
          placeholder: 'your@email.com',
        },
        {
          type: 'tel',
          label: 'Phone Number',
          required: true,
          placeholder: '+1 (123) 456-7890',
        },
        {
          type: 'select',
          label: 'Position Applied For',
          required: true,
          options: ['Software Developer', 'Designer', 'Project Manager', 'Sales Representative', 'Other'],
        },
        {
          type: 'number',
          label: 'Years of Experience',
          required: true,
          placeholder: '0',
        },
        {
          type: 'textarea',
          label: 'Cover Letter',
          required: true,
          placeholder: 'Tell us why you would be a good fit for this position',
        },
        {
          type: 'textarea',
          label: 'Additional Information',
          required: false,
          placeholder: 'Any other relevant information you would like to share',
        }
      ]
    },
    'contact': {
      name: "Contact Form",
      description: "Standard contact form with essential fields",
      fields: [
        { type: "text", label: "Full Name", required: true },
        { type: "email", label: "Email Address", required: true },
        { type: "tel", label: "Phone Number" },
        { type: "textarea", label: "Message", required: true }
      ]
    },
    'feedback': {
      name: "Customer Feedback",
      description: "Collect detailed customer feedback with ratings",
      fields: [
        { type: "text", label: "Name", required: true },
        { type: "email", label: "Email", required: true },
        { type: "rating", label: "Overall Satisfaction", required: true },
        { type: "select", label: "Product/Service Used", required: true, 
          options: ["Product A", "Product B", "Service X", "Service Y"] },
        { type: "textarea", label: "Your Feedback", required: true }
      ]
    },
    'appointment': {
      name: "Appointment Request",
      description: "Schedule appointments and consultations",
      fields: [
        { type: "text", label: "Client Name", required: true },
        { type: "email", label: "Email", required: true },
        { type: "tel", label: "Phone", required: true },
        { type: "select", label: "Service Type", required: true,
          options: ["Consultation", "Follow-up", "General Appointment"] },
        { type: "textarea", label: "Additional Notes" }
      ]
    },
    'survey': {
      name: "Quick Survey",
      description: "Short survey with multiple choice questions",
      fields: [
        { type: "text", label: "Respondent Name" },
        { type: "radio", label: "Age Group", required: true,
          options: ["18-24", "25-34", "35-44", "45+"] },
        { type: "checkbox", label: "I agree to share my responses", required: true },
        { type: "textarea", label: "Additional Comments" }
      ]
    }
  };

  const handleTemplateSelect = (templateKey) => {
    const selectedTemplate = templates[templateKey];
    if (selectedTemplate) {
      setFormData(prev => ({
        ...selectedTemplate,
        style: prev.style // Preserve current styling
      }));
    }
  };

  const handleBasicChange = (field) => (event) => {
    setFormData(prev => ({
      ...prev,
      [field]: event.target.value
    }));
  };

  const handleFieldsChange = (newFields) => {
    setFormData(prev => ({
      ...prev,
      fields: newFields || []
    }));
  };

  const handleViewAllTemplates = () => {
    setTemplatesModalOpen(true);
  };

  return (
    <ThemeProvider theme={theme}>
      <StyledDialog open={open} onClose={onClose} maxWidth="xl" fullWidth>
        <DialogTitle 
          sx={{ 
            p: 3, 
            m: 0, 
            bgcolor: 'background.paper',
            borderBottom: 1,
            borderColor: 'divider',
            boxShadow: '0 1px 3px rgba(0,0,0,0.1)'
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="h6">Create New Form</Typography>
            <IconButton onClick={onClose} size="small">
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>

        <DialogContent 
          sx={{ 
            p: 3,
            height: 'calc(100% - 130px)',
            overflow: 'hidden',
            bgcolor: '#f8faff', // Lighter blue-tinted background
          }}
        >
          <Grid container spacing={3} sx={{ height: '100%' }}>
            <Grid 
              item 
              xs={12} 
              md={6} 
              sx={{ 
                height: '100%', 
                overflow: 'auto',
                pr: 2,
                '&::-webkit-scrollbar': {
                  width: '12px',
                  background: '#f0f0f0',
                  borderRadius: '6px',
                },
                '&::-webkit-scrollbar-thumb': {
                  backgroundColor: '#bdbdbd',
                  borderRadius: '6px',
                  border: '3px solid #f0f0f0',
                  '&:hover': {
                    backgroundColor: '#9e9e9e',
                  },
                },
              }}
            >
              <StyledSection>
                <Box sx={{ 
                  display: 'flex', 
                  justifyContent: 'space-between', 
                  alignItems: 'center',
                  mb: 2 
                }}>
                  <Typography variant="h6">Choose a Template</Typography>
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={handleViewAllTemplates}
                    sx={{
                      borderRadius: '20px',
                      textTransform: 'none',
                      borderColor: formData.style.primaryColor,
                      color: formData.style.primaryColor,
                      '&:hover': {
                        borderColor: formData.style.primaryColor,
                        backgroundColor: `${formData.style.primaryColor}0a`,
                      },
                    }}
                  >
                    View All Templates
                  </Button>
                </Box>
                <Grid container spacing={2}>
                  {Object.entries(templates).map(([key, template]) => (
                    <Grid item xs={12} sm={6} key={key}>
                      <TemplateCard 
                        primaryColor={formData.style.primaryColor}
                        secondaryColor={formData.style.secondaryColor}
                        borderRadius={formData.style.borderRadius}
                      >
                        <CardContent 
                          sx={{ 
                            p: 3, 
                            flex: 1,
                            backgroundColor: formData.style.backgroundColor 
                          }}
                        >
                          <Box sx={{ 
                            display: 'flex', 
                            justifyContent: 'space-between', 
                            alignItems: 'flex-start',
                            mb: 2 
                          }}>
                            <Typography 
                              variant="h6" 
                              component="h3"
                              sx={{ 
                                fontWeight: 600,
                                color: formData.style.primaryColor,
                                fontSize: '1.1rem'
                              }}
                            >
                              {template.name}
                            </Typography>
                            <IconButton 
                              onClick={() => handleTemplateSelect(key)}
                              sx={{
                                bgcolor: formData.style.primaryColor,
                                color: 'white',
                                '&:hover': {
                                  bgcolor: formData.style.secondaryColor || formData.style.primaryColor,
                                },
                              }}
                            >
                              <PreviewIcon />
                            </IconButton>
                          </Box>
                          
                          <Typography 
                            variant="body2" 
                            color="text.secondary"
                            sx={{ mb: 2 }}
                          >
                            {template.description}
                          </Typography>

                          <Box sx={{ 
                            p: 2, 
                            bgcolor: '#f5f5f5', 
                            borderRadius: '8px',
                            border: '1px dashed #bdbdbd'
                          }}>
                            <Typography variant="subtitle2" sx={{ mb: 1, color: '#666' }}>
                              Fields Preview:
                            </Typography>
                            <Box component="ul" sx={{ m: 0, pl: 2 }}>
                              {template.fields.slice(0, 3).map((field, idx) => (
                                <Typography 
                                  key={idx} 
                                  component="li" 
                                  variant="caption"
                                  sx={{ color: '#666' }}
                                >
                                  {field.label} {field.required && '*'}
                                </Typography>
                              ))}
                              {template.fields.length > 3 && (
                                <Typography variant="caption" sx={{ color: '#666', fontStyle: 'italic' }}>
                                  +{template.fields.length - 3} more fields...
                                </Typography>
                              )}
                            </Box>
                          </Box>
                        </CardContent>
                      </TemplateCard>
                    </Grid>
                  ))}
                </Grid>
              </StyledSection>

              <StyledSection>
                <SectionTitle>Basic Information</SectionTitle>
                <FormBasicSection 
                  formData={formData} 
                  onBasicChange={handleBasicChange}
                />
              </StyledSection>

              <StyledSection>
                <SectionTitle>Form Style</SectionTitle>
                <FormStyleSection 
                  formData={formData} 
                  onStyleChange={handleStyleChange} 
                />
              </StyledSection>

              <StyledSection>
                <SectionTitle>Form Fields</SectionTitle>
                <FormFieldsSection 
                  formData={formData} 
                  onFieldsChange={handleFieldsChange}
                />
              </StyledSection>
            </Grid>

            <Grid 
              item 
              xs={12} 
              md={6} 
              sx={{ 
                height: '100%', 
                overflow: 'auto',
                bgcolor: formData.style.backgroundColor,
                borderRadius: formData.style.borderRadius,
                border: '2px solid #e0e0e0',
                boxShadow: '0 4px 12px rgba(0,0,0,0.08)',
                position: 'relative',
                '&:before': {
                  content: '"Preview"',
                  position: 'absolute',
                  top: '-12px',
                  left: '20px',
                  padding: '4px 12px',
                  backgroundColor: formData.style.primaryColor,
                  color: '#ffffff',
                  borderRadius: formData.style.borderRadius,
                  fontSize: '0.875rem',
                  fontWeight: 600,
                  boxShadow: `0 2px 4px ${formData.style.primaryColor}4D`,
                },
                '&::-webkit-scrollbar': {
                  width: '12px',
                  background: '#f0f0f0',
                  borderRadius: '6px',
                },
                '&::-webkit-scrollbar-thumb': {
                  backgroundColor: '#bdbdbd',
                  borderRadius: '6px',
                  border: '3px solid #f0f0f0',
                  '&:hover': {
                    backgroundColor: '#9e9e9e',
                  },
                },
              }}
            >
              <Box sx={{ p: 4, pt: 5 }}>
                <FormPreviewSection 
                  formData={formData} 
                />
              </Box>
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions 
          sx={{ 
            p: 3, 
            borderTop: '2px solid #e0e0e0',
            bgcolor: 'background.paper',
            boxShadow: '0 -2px 4px rgba(0,0,0,0.05)',
          }}
        >
          <Button 
            onClick={onClose} 
            variant="outlined"
            sx={{
              borderRadius: '8px',
              borderWidth: '2px',
              textTransform: 'none',
              px: 3,
            }}
          >
            Cancel
          </Button>
          <Button 
            variant="contained"
            onClick={onClose}
            sx={{
              borderRadius: '8px',
              textTransform: 'none',
              background: 'linear-gradient(45deg, #2196f3 30%, #21cbf3 90%)',
              boxShadow: '0 3px 5px 2px rgba(33, 203, 243, .3)',
              px: 3,
              ml: 2,
            }}
          >
            Create Form
          </Button>
        </DialogActions>
      </StyledDialog>

      <AllTemplatesModal
        open={templatesModalOpen}
        onClose={() => setTemplatesModalOpen(false)}
        onTemplateSelect={handleTemplateSelect}
        formData={formData}
      />
    </ThemeProvider>
  );
};

export default FormCreate;