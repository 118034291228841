import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, Paper, Typography, Button, Snackbar, Alert, CircularProgress } from '@mui/material';
import SavedItemsManager from './SavedItemsManager';
import { getEstimate, updateEstimate, getItems } from '../services/api';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const AdminEditEstimate = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [estimate, setEstimate] = useState(null);
  const [savedItems, setSavedItems] = useState([]);
  const [showSuccess, setShowSuccess] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  console.log('AdminEditEstimate rendered with ID:', id);

  useEffect(() => {
    const fetchData = async () => {
      console.log('Fetching data for estimate:', id);
      try {
        setLoading(true);
        // Get estimate data
        const response = await getEstimate(id);
        console.log('API Response:', response);
        const data = response.data;
        
        console.log('Fetched estimate data:', data);
        
        // Format the estimate data
        const formattedEstimate = {
          id: data.id,
          ...data,
          business_details: data.business_details || {},
          customer_details: data.customer_details || {},
          items: data.items || [],
          project_images: data.project_images || [],
          project_name: data.project_name || '',
          service_address: data.service_address || '',
          service_city: data.service_city || '',
          service_state: data.service_state || '',
          service_zip: data.service_zip || '',
        };

        console.log('Formatted estimate:', formattedEstimate);
        setEstimate(formattedEstimate);

        // Get saved items
        const items = await getItems();
        setSavedItems(items);
        
      } catch (err) {
        console.error('Error loading data:', err);
        setError('Failed to load estimate data');
      } finally {
        setLoading(false);
      }
    };

    if (id) {
      fetchData();
    }
  }, [id]);

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ p: 3, textAlign: 'center' }}>
        <Typography color="error">{error}</Typography>
      </Box>
    );
  }

  if (!estimate) {
    return (
      <Box sx={{ p: 3, textAlign: 'center' }}>
        <Typography>No estimate found</Typography>
      </Box>
    );
  }

  const handleAddToEstimate = (newItem, index = null) => {
    setEstimate(prev => ({
      ...prev,
      items: index !== null
        ? prev.items.map((item, i) => i === index ? newItem : item)
        : [...prev.items, newItem]
    }));
  };

  const handleRemoveItem = (index) => {
    setEstimate(prev => ({
      ...prev,
      items: prev.items.filter((_, i) => i !== index)
    }));
  };

  const handleSave = async () => {
    try {
      // Format the estimate data before sending
      const formattedEstimate = {
        ...estimate,
        // Match all backend field expectations
        estimateNumber: estimate.estimate_number,
        issueDate: estimate.issue_date,
        expirationDate: estimate.expiration_date,
        projectName: estimate.project_name,
        serviceAddress: estimate.service_address,
        serviceCity: estimate.service_city,
        serviceState: estimate.service_state,
        serviceZip: estimate.service_zip,
        useClientAddress: estimate.use_client_address,
        customerDetails: estimate.customer_details,
        businessDetails: estimate.business_details,
        scopeOfWork: estimate.scope_of_work,
        projectImages: estimate.project_images,
        internalNotes: estimate.internal_notes,
        items: estimate.items.map(item => ({
          ...item,
          unit_price: item.unitPrice || item.unit_price,
          quantity: Number(item.quantity)
        }))
      };

      console.log('Sending formatted estimate:', formattedEstimate); // Debug log
      await updateEstimate(id, formattedEstimate);
      setShowSuccess(true);
      setTimeout(() => {
        navigate(`/admin/estimates/${id}`);
      }, 1500);
    } catch (err) {
      console.error('Error saving estimate:', err);
      setError('Failed to save changes');
    }
  };

  return (
    <Box sx={{ p: 3 }}>
      <Box sx={{ 
        display: 'flex', 
        alignItems: 'center',
        mb: 2,
        borderBottom: '1px solid #eee',
        pb: 2
      }}>
        <Button
          startIcon={<ArrowBackIcon />}
          onClick={() => navigate(`/admin/estimates/${id}`)}
          sx={{ 
            color: 'text.secondary',
            '&:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.04)'
            }
          }}
        >
          Back to Estimate
        </Button>
      </Box>

      <Paper sx={{ p: 3, mb: 3 }}>
        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'flex-start',
          mb: 3 
        }}>
          <Box sx={{ flex: 1, mr: 2 }}>
            <Typography variant="h5" sx={{ mb: 1 }}>Edit Estimate</Typography>
            <Typography variant="subtitle1" color="text.secondary" sx={{ mb: 2 }}>
              Estimate #{estimate.estimate_number}
            </Typography>
            <Box sx={{ mb: 2 }}>
              <Typography variant="subtitle1" sx={{ mb: 1 }}>Project Name</Typography>
              <input
                type="text"
                value={estimate.project_name || ''}
                onChange={(e) => setEstimate(prev => ({
                  ...prev,
                  project_name: e.target.value
                }))}
                style={{
                  width: '300px', // Set max width
                  padding: '8px 12px',
                  fontSize: '16px',
                  border: '1px solid #ccc',
                  borderRadius: '4px'
                }}
              />
            </Box>
          </Box>
          <Button 
            variant="contained" 
            onClick={handleSave}
            sx={{
              backgroundColor: '#1976d2',
              '&:hover': {
                backgroundColor: '#1565c0'
              }
            }}
          >
            Save Changes
          </Button>
        </Box>

        <SavedItemsManager
          onAddToEstimate={handleAddToEstimate}
          savedItems={savedItems}
          setSavedItems={setSavedItems}
          estimateItems={estimate.items || []}
          onRemoveItem={handleRemoveItem}
        />
      </Paper>

      <Snackbar
        open={showSuccess}
        autoHideDuration={6000}
        onClose={() => setShowSuccess(false)}
      >
        <Alert severity="success">
          Estimate updated successfully
        </Alert>
      </Snackbar>

      <Snackbar
        open={!!error}
        autoHideDuration={6000}
        onClose={() => setError(null)}
      >
        <Alert severity="error">
          {error}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default AdminEditEstimate; 