import React from 'react';
import { 
  Box, 
  Button,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Tooltip
} from '@mui/material';
import {
  MoreVert as MoreVertIcon,
  Email as EmailIcon,
  Phone as PhoneIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Schedule as ScheduleIcon
} from '@mui/icons-material';

const actions = [
  { icon: <EmailIcon />, name: 'Send Email', action: 'email' },
  { icon: <PhoneIcon />, name: 'Call Lead', action: 'call' },
  { icon: <ScheduleIcon />, name: 'Schedule Meeting', action: 'schedule' },
  { icon: <EditIcon />, name: 'Edit Lead', action: 'edit' },
  { icon: <DeleteIcon color="error" />, name: 'Delete Lead', action: 'delete' }
];

const LeadQuickActions = ({ onAction }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleAction = (action) => {
    onAction(action);
    handleClose();
  };

  return (
    <>
      <Tooltip title="Quick Actions">
        <Button
          variant="outlined"
          onClick={handleClick}
          startIcon={<MoreVertIcon />}
          sx={{
            ml: 1,
            textTransform: 'none',
            fontFamily: 'Urbanist, sans-serif',
            borderRadius: '8px'
          }}
        >
          Actions
        </Button>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            mt: 1,
            '& .MuiMenuItem-root': {
              py: 1,
              px: 2,
              fontFamily: 'Urbanist, sans-serif'
            }
          }
        }}
      >
        {actions.map((action) => (
          <MenuItem 
            key={action.action} 
            onClick={() => handleAction(action.action)}
          >
            <ListItemIcon>{action.icon}</ListItemIcon>
            <ListItemText 
              primary={action.name}
              primaryTypographyProps={{
                fontFamily: 'Urbanist, sans-serif'
              }}
            />
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default LeadQuickActions; 
