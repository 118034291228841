import React from 'react';
import { 
  ListItem, 
  ListItemAvatar, 
  ListItemText,
  Avatar, 
  Box, 
  Typography, 
  Chip 
} from '@mui/material';
import { 
  Circle as CircleIcon,
  Email as EmailIcon,
  Phone as PhoneIcon 
} from '@mui/icons-material';

const ContactItem = ({ contact, selected, onClick }) => {
  return (
    <ListItem
      button
      selected={selected}
      onClick={onClick}
      sx={{
        '&.Mui-selected': {
          backgroundColor: 'primary.light',
        }
      }}
    >
      <ListItemAvatar>
        <Avatar sx={{ bgcolor: contact.type === 'email' ? '#1976d2' : '#2e7d32' }}>
          {contact.type === 'email' ? <EmailIcon /> : <PhoneIcon />}
        </Avatar>
      </ListItemAvatar>
      <ListItemText 
        primary={
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Typography sx={{ fontWeight: 600, fontFamily: 'Urbanist, sans-serif' }}>
              {contact.name}
            </Typography>
            <CircleIcon 
              sx={{ 
                fontSize: 8, 
                color: contact.status === 'online' ? 'success.main' : 'grey.400'
              }} 
            />
          </Box>
        }
        secondary={
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Typography variant="body2" noWrap sx={{ flex: 1 }}>
              {contact.lastMessage}
            </Typography>
            {contact.unread > 0 && (
              <Chip 
                label={contact.unread} 
                size="small" 
                color="primary"
                sx={{ height: 20, minWidth: 20 }}
              />
            )}
          </Box>
        }
      />
    </ListItem>
  );
};

export default ContactItem; 