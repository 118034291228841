import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  IconButton,
  Typography,
  Grid,
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { motion } from 'framer-motion';
import BasicInfoSection from './sections/BasicInfoSection';
import ContactDetailsSection from './sections/ContactDetailsSection';
import CompanyInfoSection from './sections/CompanyInfoSection';
import TagsSection from './sections/TagsSection';

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    borderRadius: 12,
    padding: 0,
  }
}));

const StyledSection = styled(motion.div)(({ theme }) => ({
  padding: theme.spacing(3),
  borderRadius: 12,
  border: '1px solid',
  borderColor: theme.palette.divider,
  transition: 'all 0.2s ease-in-out',
  '&:hover': {
    borderColor: theme.palette.primary.main,
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05)',
  },
}));

const ContactCreate = ({ open, onClose }) => {
  const [contactData, setContactData] = useState({
    basicInfo: {
      firstName: '',
      lastName: '',
      title: '',
      status: 'Active'
    },
    contactDetails: {
      email: '',
      phone: '',
      mobile: '',
      preferredContact: 'email'
    },
    companyInfo: {
      company: '',
      position: '',
      industry: ''
    },
    tags: []
  });

  const handleFieldChange = (section, field, value) => {
    setContactData(prev => ({
      ...prev,
      [section]: {
        ...prev[section],
        [field]: value
      }
    }));
  };

  return (
    <StyledDialog 
      open={open} 
      onClose={onClose}
      maxWidth="lg"
      fullWidth
    >
      <DialogTitle sx={{ 
        px: 3,
        py: 2,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderBottom: '1px solid',
        borderColor: 'divider'
      }}>
        <Typography variant="h6">Create New Contact</Typography>
        <IconButton onClick={onClose} size="small">
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent sx={{ p: 3 }}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <StyledSection
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.1 }}
            >
              <BasicInfoSection 
                data={contactData.basicInfo}
                onChange={(field, value) => handleFieldChange('basicInfo', field, value)}
              />
            </StyledSection>
          </Grid>
          <Grid item xs={12} md={6}>
            <StyledSection
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.2 }}
            >
              <ContactDetailsSection 
                data={contactData.contactDetails}
                onChange={(field, value) => handleFieldChange('contactDetails', field, value)}
              />
            </StyledSection>
          </Grid>
          <Grid item xs={12} md={6}>
            <StyledSection
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.3 }}
            >
              <CompanyInfoSection 
                data={contactData.companyInfo}
                onChange={(field, value) => handleFieldChange('companyInfo', field, value)}
              />
            </StyledSection>
          </Grid>
          <Grid item xs={12} md={6}>
            <StyledSection
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.4 }}
            >
              <TagsSection 
                tags={contactData.tags}
                onChange={(newTags) => setContactData(prev => ({ ...prev, tags: newTags }))}
              />
            </StyledSection>
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions sx={{ 
        p: 3, 
        borderTop: '1px solid',
        borderColor: 'divider'
      }}>
        <Button onClick={onClose}>Cancel</Button>
        <Button variant="contained">Create Contact</Button>
      </DialogActions>
    </StyledDialog>
  );
};

export default ContactCreate;