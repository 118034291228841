import React from 'react';
import { Box, Typography, Avatar } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Person as PersonIcon, Headset as HeadsetIcon } from '@mui/icons-material';

const Bubble = styled(Box)(({ theme, isAgent }) => ({
  maxWidth: '100%',
  padding: theme.spacing(1.5, 2),
  borderRadius: '16px',
  backgroundColor: isAgent ? theme.palette.grey[50] : theme.palette.background.paper,
  color: theme.palette.text.primary,
  fontFamily: 'Urbanist, sans-serif',
  boxShadow: '0 2px 4px rgba(0,0,0,0.05)',
  border: `1px solid ${theme.palette.divider}`,
  position: 'relative',
  '&:after': {
    content: '""',
    position: 'absolute',
    bottom: 0,
    [isAgent ? 'right' : 'left']: -8,
    borderStyle: 'solid',
    borderWidth: '8px 8px 0 8px',
    borderColor: `${isAgent ? theme.palette.grey[50] : theme.palette.background.paper} transparent transparent transparent`,
    transform: isAgent ? 'rotate(-45deg)' : 'rotate(45deg)',
    transformOrigin: isAgent ? 'right bottom' : 'left bottom'
  }
}));

const TranscriptBubble = ({ message, isAgent, showAvatar }) => (
  <Box sx={{ 
    display: 'flex', 
    alignItems: 'flex-end', 
    gap: 1.5,
    flexDirection: isAgent ? 'row-reverse' : 'row',
    mb: 1
  }}>
    {showAvatar && (
      <Avatar 
        sx={{ 
          width: 32, 
          height: 32,
          bgcolor: isAgent ? 'primary.main' : 'success.main'
        }}
      >
        {isAgent ? <HeadsetIcon /> : <PersonIcon />}
      </Avatar>
    )}
    <Box sx={{ maxWidth: '70%' }}>
      <Bubble isAgent={isAgent}>
        <Typography variant="subtitle2" sx={{ 
          mb: 0.5, 
          color: isAgent ? 'primary.dark' : 'success.dark',
          fontWeight: 600
        }}>
          {isAgent ? 'Agent' : 'Customer'}
        </Typography>
        <Typography variant="body2">
          {message.message}
        </Typography>
      </Bubble>
      <Typography 
        variant="caption" 
        sx={{ 
          display: 'block',
          mt: 0.5,
          textAlign: isAgent ? 'left' : 'right',
          color: 'text.secondary'
        }}
      >
        {message.timestamp}
      </Typography>
    </Box>
  </Box>
);

export default TranscriptBubble;