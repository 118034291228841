import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getEmployees, generateEmployeeRegistrationLink, updateEmployeeDetails } from '../services/api';
import { 
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, 
  Typography, Button, Box, Grid, IconButton, Tabs, Tab, useTheme,
  Dialog, DialogTitle, DialogContent, DialogActions, TextField
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import PersonIcon from '@mui/icons-material/Person';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import WorkIcon from '@mui/icons-material/Work';
import SearchIcon from '@mui/icons-material/Search';
import GroupIcon from '@mui/icons-material/Group';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import LinkIcon from '@mui/icons-material/Link';
import Timesheets from './Timesheets';
import styles from './Employees.module.scss';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CloseIcon from '@mui/icons-material/Close';
import EmployeeRequests from './EmployeeRequests';

const Employees = () => {
  const [employees, setEmployees] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(0);
  const theme = useTheme();
  const [openLinkModal, setOpenLinkModal] = useState(false);
  const [registrationLink, setRegistrationLink] = useState('');
  const [emailAddress, setEmailAddress] = useState('');
  const [copySuccess, setCopySuccess] = useState(false);

  useEffect(() => {
    fetchEmployees();
  }, []);

  const fetchEmployees = async () => {
    try {
      const data = await getEmployees();
      console.log('Fetched employees data:', data);
      setEmployees(Array.isArray(data.employees) ? data.employees : []);
      setLoading(false);
    } catch (err) {
      console.error('Error fetching employees:', err);
      setError('Failed to fetch employees. Please try again later.');
      setLoading(false);
    }
  };

  const filteredEmployees = Array.isArray(employees) 
    ? employees.filter(employee =>
        employee.name.toLowerCase().includes(searchTerm.toLowerCase())
      )
    : [];

  const headerCells = [
    { icon: <PersonIcon />, label: 'Name', color: '#3498db' },
    { icon: <EmailIcon />, label: 'Email', color: '#e74c3c' },
    { icon: <PhoneIcon />, label: 'Phone', color: '#2ecc71' },
    { icon: <WorkIcon />, label: 'Position', color: '#f39c12' },
    { icon: <LocationOnIcon />, label: 'Address', color: '#9b59b6' },
    { icon: <AttachMoneyIcon />, label: 'Default Rate', color: '#27ae60' },
  ];

  const handleRowClick = (employeeId) => {
    console.log(`Clicking employee with id: ${employeeId}`);
    navigate(`/admin/employees/${employeeId}`);
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleGenerateRegistrationLink = async () => {
    try {
      const response = await generateEmployeeRegistrationLink();
      const link = `${window.location.origin}/public/employee-registration/${response.token}`;
      setRegistrationLink(link);
      setOpenLinkModal(true);
    } catch (error) {
      console.error('Error generating registration link:', error);
      alert('Failed to generate registration link');
    }
  };

  const handleCopyLink = async () => {
    try {
      await navigator.clipboard.writeText(registrationLink);
      setCopySuccess(true);
      setTimeout(() => setCopySuccess(false), 2000);
    } catch (error) {
      console.error('Error copying link:', error);
    }
  };

  const handleEmailLink = async () => {
    try {
      const mailtoLink = `mailto:${emailAddress}?subject=Employee Registration Link&body=Here is your registration link: ${registrationLink}`;
      window.location.href = mailtoLink;
      setEmailAddress('');
    } catch (error) {
      console.error('Error sending email:', error);
    }
  };

  return (
    <div className={styles.pageContainer}>
      <Typography variant="h4" className={styles.pageTitle} mb={4}>
        <GroupIcon className={styles.pageTitleIcon} />
        Team Management
      </Typography>

      <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 3, }}>
        <Tabs 
          value={activeTab} 
          onChange={handleTabChange}
          sx={{
            '& .MuiTab-root': {
              fontSize: '1rem',
              color: 'var(--text-primary)',
              fontWeight: 500,
              textTransform: 'none',
              minWidth: 120,
              '&.Mui-selected': {
                color: theme.palette.primary.main,
              },
            },
            '& .MuiTabs-indicator': {
              height: 3,
              borderRadius: '3px 3px 0 0',
            },
          }}
        >
          <Tab label="Directory" />
          <Tab label="Timesheets" />
          <Tab label="Requests" />
        </Tabs>
      </Box>

      {activeTab === 0 ? (
        <div className={styles.employeesContainer}>
          <Grid container justifyContent="space-between" alignItems="center" mb={4}>
            <Grid item>
              <Box sx={{ display: 'flex', alignItems: 'center', color: 'var(--text-primary)' }}>
                <TextField
                  placeholder="Search employees..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  variant="outlined"
                  size="small"
                  sx={{
                    width: '50px',
                    transition: 'all 0.5s ease',
                    '&:hover, &:focus-within': {
                      width: '300px',
                    },
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '8px',
                      backgroundColor: 'var(--background-secondary)',
                      border: '1px solid var(--text-secondary)',
                      '&:hover': {
                        backgroundColor: 'var(--background-secondary)',
                        borderColor: 'var(--text-secondary)'
                      },
                      '&.Mui-focused': {
                        borderColor: '#2065D1',
                        boxShadow: '0 0 0 2px rgba(32, 101, 209, 0.08)'
                      },
                      '& fieldset': {
                        border: 'none'
                      }
                    }
                  }}
                  InputProps={{
                    startAdornment: (
                      <SearchIcon 
                        sx={{ 
                          color: '#94a3b8', 
                          mr: 1,
                          transition: 'all 0.3s ease',

                        }} 
                      />
                    ),
                  }}
                />
              </Box>
            </Grid>
            <Grid item>
              <Box sx={{ display: 'flex', gap: 2 }}>
                <Button
                  variant="outlined"
                  color="primary"
                  startIcon={<LinkIcon />}
                  onClick={handleGenerateRegistrationLink}
                  sx={{
                    borderRadius: '8px',
                    textTransform: 'none',
                    fontFamily: 'Urbanist, sans-serif',
                    fontWeight: 500,
                    px: 3,
                    py: 1,
                    '&:hover': {
                      backgroundColor: 'rgba(25, 118, 210, 0.04)',
                    }
                  }}
                >
                  Generate Registration Link
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<AddIcon />}
                  onClick={() => navigate('/admin/create-employee')}
                  sx={{
                    borderRadius: '8px',
                    textTransform: 'none',
                    fontFamily: 'Urbanist, sans-serif',
                    fontWeight: 500,
                    px: 3,
                    py: 1,
                    backgroundColor: '#1976d2',
                    '&:hover': {
                      backgroundColor: '#1565c0',
                    }
                  }}
                >
                  Create New User
                </Button>
              </Box>
            </Grid>
          </Grid>

          {filteredEmployees.length > 0 ? (
            <TableContainer component={Paper} className={styles.tableContainer}>
              <Table>
                <TableHead>
                  <TableRow className={styles.tableHeader}>
                    {headerCells.map((cell, index) => (
                      <TableCell key={index} className={styles.tableCell} align="center">
                        <div className={styles.headerContent}>
                          <span className={styles.headerIcon} style={{ color: cell.color }}>
                            {cell.icon}
                          </span>
                          <span className={styles.headerLabel}>{cell.label}</span>
                        </div>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredEmployees.map((employee) => (
                    <TableRow 
                      key={employee.id} 
                      className={styles.tableRow}
                      onClick={() => handleRowClick(employee.id)}
                      style={{ cursor: 'pointer' }}
                    >
                      <TableCell className={styles.tableCell} align="center" sx={{ color: 'var(--text-primary)'}}>{employee.name}</TableCell>
                      <TableCell className={styles.tableCell} align="center" sx={{ color: 'var(--text-primary)'}}>{employee.email}</TableCell>
                      <TableCell className={styles.tableCell} align="center" sx={{ color: 'var(--text-primary)'}}>{employee.phone}</TableCell>
                      <TableCell className={styles.tableCell} align="center" sx={{ color: 'var(--text-primary)'}}>{employee.position}</TableCell>
                      <TableCell className={styles.tableCell} align="center" sx={{ color: 'var(--text-primary)'}}>{employee.address}</TableCell>
                      <TableCell className={styles.tableCell} align="center" sx={{ color: 'var(--text-primary)'}}>${employee.default_rate}/hr</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Typography>No employees found.</Typography>
          )}
        </div>
      ) : activeTab === 1 ? (
        <Timesheets />
      ) : (
        <EmployeeRequests />
      )}

      <Dialog 
        open={openLinkModal} 
        onClose={() => setOpenLinkModal(false)} 
        maxWidth="sm" 
        fullWidth
        PaperProps={{
          sx: {
            borderRadius: '16px',
            boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
            '.MuiDialogTitle-root': {
              background: '#f8fafc',
              borderBottom: '1px solid #e2e8f0',
              py: 2.5,
            },
            '.MuiDialogContent-root': {
              p: 3,
            },
            '.MuiDialogActions-root': {
              borderTop: '1px solid #e2e8f0',
              px: 3,
              py: 2,
            }
          }
        }}
      >
        <DialogTitle sx={{ 
          fontSize: '1.25rem', 
          fontWeight: 600,
          fontFamily: 'Urbanist, sans-serif',
          color: '#1e293b',
          position: 'relative',
          pr: 6
        }}>
          Registration Link
          <IconButton
            onClick={() => setOpenLinkModal(false)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: '#ef4444',
              '&:hover': {
                backgroundColor: 'rgba(239, 68, 68, 0.1)',
              }
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Box sx={{ mb: 3 }}>
            <Typography variant="body2" sx={{ mb: 1, color: '#64748b', fontWeight: 500 }}>
              Registration Link:
            </Typography>
            <TextField
              value={registrationLink}
              fullWidth
              variant="outlined"
              size="small"
              sx={{
                '& .MuiOutlinedInput-root': {
                  borderRadius: '8px',
                  backgroundColor: '#f8fafc',
                  '&:hover': {
                    backgroundColor: '#f1f5f9'
                  }
                }
              }}
              InputProps={{
                readOnly: true,
                endAdornment: (
                  <Button
                    onClick={handleCopyLink}
                    startIcon={<ContentCopyIcon />}
                    color={copySuccess ? "success" : "primary"}
                    sx={{
                      borderRadius: '6px',
                      textTransform: 'none',
                      fontWeight: 500
                    }}
                  >
                    {copySuccess ? "Copied!" : "Copy"}
                  </Button>
                ),
              }}
            />
          </Box>
          <Box>
            <Typography variant="body2" sx={{ mb: 1, color: '#64748b', fontWeight: 500 }}>
              Send via Email:
            </Typography>
            <TextField
              value={emailAddress}
              onChange={(e) => setEmailAddress(e.target.value)}
              fullWidth
              variant="outlined"
              size="small"
              placeholder="Enter email address"
              type="email"
              sx={{
                '& .MuiOutlinedInput-root': {
                  borderRadius: '8px',
                  backgroundColor: '#f8fafc',
                  '&:hover': {
                    backgroundColor: '#f1f5f9'
                  }
                }
              }}
              InputProps={{
                endAdornment: (
                  <Button
                    onClick={handleEmailLink}
                    startIcon={<EmailIcon />}
                    disabled={!emailAddress}
                    sx={{
                      borderRadius: '6px',
                      textTransform: 'none',
                      fontWeight: 500
                    }}
                  >
                    Send
                  </Button>
                ),
              }}
            />
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default Employees;
