import React, { useState, useEffect } from 'react';
import { Typography, IconButton, Paper, Button } from '@mui/material';
import {
  ArrowBackIos as ArrowBackIosIcon,
  ArrowForwardIos as ArrowForwardIosIcon,
  Add as AddIcon
} from '@mui/icons-material';
import { Calendar as BigCalendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import styles from './Calendar.module.scss';
import { getAllEvents } from '../services/api';
import EventDetails from './EventDetails';
import EventForm from './EventForm';

const localizer = momentLocalizer(moment);

// Helper Functions
const stringToColor = (str) => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  const hue = Math.abs(hash % 360);
  return `hsl(${hue}, 70%, 80%)`; // Pastel colors
};

const formatTime = (date) => {
  return new Date(date).toLocaleTimeString('en-US', {
    hour: 'numeric',
    minute: '2-digit',
    hour12: true
  });
};

const VIEWS = {
  MONTH: 'month',
  WEEK: 'week',
  DAY: 'day'
};

const CalendarComponent = () => {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [view, setView] = useState(VIEWS.MONTH);
  const [isEventDetailsOpen, setIsEventDetailsOpen] = useState(false);
  const [selectedEventId, setSelectedEventId] = useState(null);
  const [isFormOpen, setIsFormOpen] = useState(false);

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        setLoading(true);
        const eventsResponse = await getAllEvents();

        const scheduled = [];

        eventsResponse.forEach((event) => {
          if (event.time_slots && event.time_slots.length > 0) {
            const eventWithDates = {
              ...event,
              start: new Date(event.time_slots[0].start_time),
              end: new Date(event.time_slots[0].end_time),
              project: event.project?.name || 'Unnamed Project',
              title: event.title || 'Untitled Event'
            };
            scheduled.push(eventWithDates);
          }
        });

        setEvents(scheduled);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching events:', err);
        setError('Failed to fetch events. Please try again.');
        setLoading(false);
      }
    };

    fetchEvents();
  }, [currentDate]);

  const handleEventClick = (event) => {
    setSelectedEventId(event.id);
    setIsEventDetailsOpen(true);
  };

  const handleAddEvent = () => {
    setIsFormOpen(true);
  };

  const eventStyleGetter = (event) => {
    return {
      style: {
        backgroundColor: 'transparent',
        border: 'none',
        padding: '0',
        margin: '0',
        position: 'relative',
        zIndex: 1,
        height: 'auto'
      }
    };
  };

  const renderEvent = ({ event }) => (
    <div className={styles.eventWrapper}>
      <div
        className={styles.eventContent}
        style={{
          borderLeft: `5px solid ${stringToColor(event.title)}`,
          boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
          transition: 'transform 0.2s',
          backgroundColor: 'var(--background-secondary)',
          color: 'var(--text-primary)'
        }}
      >
        <div className={styles.eventHeader}>
          {event.emoji && <span className={styles.eventEmoji}>{event.emoji}</span>}
          <span className={styles.eventTitle}>{event.title}</span>
        </div>
        <div className={styles.eventInfo}>
          <div className={styles.projectName}>{event.project}</div>
          <div className={styles.dateTimeInfo}>
            <div className={styles.time}>{formatTime(event.start)} - {formatTime(event.end)}</div>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className={styles.calendarContainer}>
      <div className={`${styles.calendarHeader} ${styles.fadeInElement}`}>
        <div className={styles.headerControls}>
          <IconButton
            onClick={() =>
              setCurrentDate(
                new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1)
              )
            }
            aria-label="Previous Month"
            sx={{ color: 'var(--text-primary)' }}
          >
            <ArrowBackIosIcon />
          </IconButton>
          <Typography variant="h4" className={styles.headerTitle} sx={{ color: 'var(--text-primary)' }}>
            {currentDate.toLocaleString('default', {
              month: 'long',
              year: 'numeric'
            })}
          </Typography>
          <IconButton
            onClick={() =>
              setCurrentDate(
                new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1)
              )
            }
            aria-label="Next Month"
            sx={{ color: 'var(--text-primary)' }}
          >
            <ArrowForwardIosIcon />
          </IconButton>
        </div>
      </div>

      <Paper 
        className={styles.calendarWrapper}
        sx={{ 
          backgroundColor: 'var(--background-paper)',
          '& .rbc-calendar': {
            backgroundColor: 'var(--background-paper)',
            color: 'var(--text-primary)'
          },
          '& .rbc-header': {
            color: 'var(--text-primary)'
          },
          '& .rbc-off-range-bg': {
            backgroundColor: 'var(--background-secondary)'
          },
          '& .rbc-today': {
            backgroundColor: 'var(--background-secondary)'
          },
          '& .rbc-btn-group button': {
            color: 'var(--text-primary)',
            backgroundColor: 'var(--background-primary)',
            border: '1px solid var(--divider)',
            '&:hover': {
              backgroundColor: 'var(--background-secondary)',
              color: 'var(--text-secondary)'
            },
          },
          '& .rbc-toolbar': {
            color: 'var(--text-primary)'
          },
          '& .rbc-toolbar-label': {
            color: 'var(--text-primary)'
          }
        }}
      >
        <BigCalendar
          localizer={localizer}
          events={events}
          startAccessor="start"
          endAccessor="end"
          onSelectEvent={handleEventClick}
          eventPropGetter={eventStyleGetter}
          components={{
            event: renderEvent
          }}
          view={view}
          date={currentDate}
          onView={setView}
          onNavigate={setCurrentDate}
          min={new Date(0, 0, 0, 7, 0, 0)}
          max={new Date(0, 0, 0, 19, 0, 0)}
          dayLayoutAlgorithm="no-overlap"
          views={['month', 'week', 'day']}
          className={styles.calendar}
        />
      </Paper>

      {/* Event Details Modal */}
      <EventDetails
        eventId={selectedEventId}
        open={isEventDetailsOpen}
        onClose={() => setIsEventDetailsOpen(false)}
      />

      {/* Event Form Modal */}
      <EventForm
        open={isFormOpen}
        onClose={() => setIsFormOpen(false)}
        onEventCreated={() => {
          setIsFormOpen(false);
          // Optionally refetch events
        }}
      />
    </div>
  );
};

export default CalendarComponent;
