// src/components/ClientDetails.js
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getClientDetails, updateClient } from '../services/api';
import { 
  Box, Typography, Tabs, Tab, Paper, IconButton, Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Snackbar, Alert
} from '@mui/material';
import Overview from './client/Overview';
import ClientDetailsProject from './client/ClientDetailsProject';
import Invoices from './client/Invoices';
import Documents from './client/Documents';
import ClientSidebar from './client/ClientSidebar';
import styles from './ClientDetails.module.scss';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import Estimates from './client/Estimates';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';

const ClientDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [client, setClient] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState(0);
  const [isEditing, setIsEditing] = useState(false);
  const [editedClient, setEditedClient] = useState(null);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchClientDetails = async () => {
      try {
        const data = await getClientDetails(id);
        setClient(data);
        setError(null);
      } catch (err) {
        console.error('Failed to fetch client details:', err);
        setError(err.message || 'Failed to load client details');
      } finally {
        setLoading(false);
      }
    };

    fetchClientDetails();
  }, [id]);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleEditClick = () => {
    setEditedClient({ ...client });
    setOpenEditDialog(true);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedClient(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSave = async () => {
    try {
      setIsLoading(true);
      await updateClient(id, editedClient);
      
      // Close the dialog
      setOpenEditDialog(false);
      
      // Show success message
      setShowSuccessAlert(true);
      
      // Refresh client data
      const updatedData = await getClientDetails(id);
      setClient(updatedData);
      
    } catch (error) {
      console.error('Failed to update client:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCloseAlert = () => {
    setShowSuccessAlert(false);
  };

  const tabs = [
    { label: 'Overview', component: Overview },
    { label: 'Estimates', component: Estimates },
    { label: 'Projects', component: ClientDetailsProject },
    { label: 'Invoices', component: Invoices },
    { label: 'Documents', component: Documents }
  ];

  const ActiveComponent = tabs[activeTab].component;

  // Add this phone formatting function
  const formatPhoneNumber = (value) => {
    if (!value) return value;
    
    // Remove all non-digits
    const phoneNumber = value.replace(/[^\d]/g, '');
    
    // Get length of the input
    const phoneNumberLength = phoneNumber.length;
    
    // Return if length is less than 4
    if (phoneNumberLength < 4) return phoneNumber;
    
    // Return formatted phone number
    if (phoneNumberLength < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }
    
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
  };

  // Add this handler for phone input
  const handlePhoneChange = (e) => {
    const formattedPhone = formatPhoneNumber(e.target.value);
    setEditedClient(prev => ({
      ...prev,
      phone: formattedPhone
    }));
  };

  if (loading) return <div className={styles.loading}>Loading...</div>;
  if (error) return <div className={styles.error}>{error}</div>;
  if (!client) return <div className={styles.error}>No client found</div>;

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      overflow: 'hidden',
      backgroundColor: 'var(--background-default)',
    }}>
      {/* Back Button */}
      <Box sx={{ 
        p: 2, 
        borderBottom: 1, 
        borderColor: 'var(--divider)',
        backgroundColor: 'var(--background-paper)'
      }}>
        <Button
          startIcon={<ArrowBackIcon />}
          onClick={() => navigate('/admin/clients')}
          sx={{
            color: 'var(--text-primary)',
            textTransform: 'none',
            fontWeight: 500,
            '&:hover': {
              backgroundColor: 'var(--hover-overlay)'
            }
          }}
        >
          Back to Clients
        </Button>
      </Box>

      {/* Main Content Area */}
      <Box sx={{
        display: 'flex',
        flex: 1,
        overflow: 'hidden',
        p: 3,
        gap: 3,
        height: '100%',
        backgroundColor: 'var(--background-default)'
      }}>
        {/* Sidebar */}
        <Box sx={{ 
          width: 300,
          flexShrink: 0
        }}>
          <ClientSidebar 
            client={client} 
            onEditClick={handleEditClick}
          />
        </Box>

        {/* Content Area */}
        <Box sx={{ 
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
          minWidth: 0,
          bgcolor: 'var(--background-paper)',
          borderRadius: '8px',
          border: '1px solid var(--divider)'
        }}>
          {/* Tabs - Modified to remove arrows */}
          <Tabs 
            value={activeTab} 
            onChange={handleTabChange}
            variant="standard"  // Changed from "scrollable"
            sx={{
              borderBottom: 1,
              borderColor: 'var(--divider)',
              '& .MuiTab-root': {
                textTransform: 'none',
                minWidth: 120,
                fontFamily: 'Urbanist, sans-serif',
                color: 'var(--text-primary)',
              },
              '& .Mui-selected': {
                color: 'var(--primary-main)'
              },
              '& .MuiTabs-indicator': {
                backgroundColor: 'var(--primary-main)'
              }
            }}
          >
            {tabs.map((tab, index) => (
              <Tab key={index} label={tab.label} />
            ))}
          </Tabs>

          {/* Tab Content */}
          <Box sx={{
            flex: 1,
            overflow: 'auto',
            minHeight: 0
          }}>
            <ActiveComponent client={client} />
          </Box>
        </Box>
      </Box>

      {/* Add the Edit Dialog */}
      <Dialog 
        open={openEditDialog} 
        onClose={() => setOpenEditDialog(false)}
        maxWidth="sm"
        fullWidth
        PaperProps={{
          sx: {
            backgroundColor: 'var(--background-paper)',
            color: 'var(--text-primary)'
          }
        }}
      >
        <DialogTitle>Edit Client</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            label="Name"
            name="name"
            value={editedClient?.name || ''}
            onChange={handleInputChange}
            margin="normal"
            variant="outlined"
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: 'var(--divider)',
                },
                '&:hover fieldset': {
                  borderColor: 'var(--text-primary)',
                },
                '&.Mui-focused fieldset': {
                  borderColor: 'var(--primary-main)',
                },
                '& input': {
                  color: 'var(--text-primary)',
                }
              },
              '& .MuiInputLabel-root': {
                color: 'var(--text-secondary)',
                '&.Mui-focused': {
                  color: 'var(--primary-main)',
                }
              },
              marginBottom: 2
            }}
          />
          <TextField
            fullWidth
            label="Email"
            name="email"
            type="email"
            value={editedClient?.email || ''}
            onChange={handleInputChange}
            margin="normal"
            variant="outlined"
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: 'var(--divider)',
                },
                '&:hover fieldset': {
                  borderColor: 'var(--text-primary)',
                },
                '&.Mui-focused fieldset': {
                  borderColor: 'var(--primary-main)',
                },
                '& input': {
                  color: 'var(--text-primary)',
                }
              },
              '& .MuiInputLabel-root': {
                color: 'var(--text-secondary)',
                '&.Mui-focused': {
                  color: 'var(--primary-main)',
                }
              },
              marginBottom: 2
            }}
          />
          <TextField
            fullWidth
            label="Phone"
            name="phone"
            value={editedClient?.phone || ''}
            onChange={handlePhoneChange}
            margin="normal"
            variant="outlined"
            placeholder="(555) 555-5555"
            inputProps={{ maxLength: 14 }}
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: 'var(--divider)',
                },
                '&:hover fieldset': {
                  borderColor: 'var(--text-primary)',
                },
                '&.Mui-focused fieldset': {
                  borderColor: 'var(--primary-main)',
                },
                '& input': {
                  color: 'var(--text-primary)',
                }
              },
              '& .MuiInputLabel-root': {
                color: 'var(--text-secondary)',
                '&.Mui-focused': {
                  color: 'var(--primary-main)',
                }
              },
              marginBottom: 2
            }}
          />
          <TextField
            fullWidth
            label="Company"
            name="company"
            value={editedClient?.company || ''}
            onChange={handleInputChange}
            margin="normal"
            variant="outlined"
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: 'var(--divider)',
                },
                '&:hover fieldset': {
                  borderColor: 'var(--text-primary)',
                },
                '&.Mui-focused fieldset': {
                  borderColor: 'var(--primary-main)',
                },
                '& input': {
                  color: 'var(--text-primary)',
                }
              },
              '& .MuiInputLabel-root': {
                color: 'var(--text-secondary)',
                '&.Mui-focused': {
                  color: 'var(--primary-main)',
                }
              },
              marginBottom: 2
            }}
          />
          <TextField
            fullWidth
            label="Address"
            name="address"
            value={editedClient?.address || ''}
            onChange={handleInputChange}
            margin="normal"
            variant="outlined"
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: 'var(--divider)',
                },
                '&:hover fieldset': {
                  borderColor: 'var(--text-primary)',
                },
                '&.Mui-focused fieldset': {
                  borderColor: 'var(--primary-main)',
                },
                '& input': {
                  color: 'var(--text-primary)',
                }
              },
              '& .MuiInputLabel-root': {
                color: 'var(--text-secondary)',
                '&.Mui-focused': {
                  color: 'var(--primary-main)',
                }
              },
              marginBottom: 2
            }}
          />
          <TextField
            fullWidth
            label="Notes"
            name="notes"
            value={editedClient?.notes || ''}
            onChange={handleInputChange}
            margin="normal"
            variant="outlined"
            multiline
            rows={4}
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: 'var(--divider)',
                },
                '&:hover fieldset': {
                  borderColor: 'var(--text-primary)',
                },
                '&.Mui-focused fieldset': {
                  borderColor: 'var(--primary-main)',
                },
                '& input': {
                  color: 'var(--text-primary)',
                }
              },
              '& .MuiInputLabel-root': {
                color: 'var(--text-secondary)',
                '&.Mui-focused': {
                  color: 'var(--primary-main)',
                }
              },
              marginBottom: 2
            }}
          />
        </DialogContent>
        <DialogActions sx={{ padding: '16px 24px' }}>
          <Button 
            onClick={() => setOpenEditDialog(false)} 
            variant="outlined"
            startIcon={<CancelIcon />}
            sx={{ 
              mr: 1,
              borderColor: 'var(--divider)',
              color: 'var(--text-primary)',
              '&:hover': {
                borderColor: 'var(--text-primary)',
                backgroundColor: 'var(--hover-overlay)'
              }
            }}
          >
            Cancel
          </Button>
          <Button 
            onClick={handleSave} 
            variant="contained"
            color="primary" 
            startIcon={<SaveIcon />}
            disabled={isLoading}
          >
            {isLoading ? 'Saving...' : 'Save'}
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar 
        open={showSuccessAlert} 
        autoHideDuration={3000} 
        onClose={handleCloseAlert}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert 
          onClose={handleCloseAlert} 
          severity="success" 
          sx={{ width: '100%' }}
        >
          Client updated successfully!
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default ClientDetails;
