import React from 'react';
import { Grid, Card, CardContent, Typography, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { TrendingUp, ShowChart, MonetizationOn, Speed } from '@mui/icons-material';

const MetricCard = styled(Card)(({ theme }) => ({
  background: 'linear-gradient(135deg, #fff 0%, #f8f9fa 100%)',
  borderRadius: 16,
  transition: 'transform 0.2s ease-in-out',
  '&:hover': {
    transform: 'translateY(-4px)',
  }
}));

const LeadMetricsCards = ({ leads }) => {
  const metrics = [
    {
      title: 'Total Leads',
      value: leads.length,
      icon: <ShowChart sx={{ color: '#2196f3' }} />,
      trend: '+12%'
    },
    {
      title: 'Conversion Rate',
      value: `${((leads.filter(l => l.status === 'Won').length / leads.length) * 100).toFixed(1)}%`,
      icon: <TrendingUp sx={{ color: '#4caf50' }} />,
      trend: '+5.2%'
    },
    {
      title: 'Average Value',
      value: `$${(leads.reduce((sum, lead) => sum + lead.value, 0) / leads.length).toLocaleString()}`,
      icon: <MonetizationOn sx={{ color: '#f57c00' }} />,
      trend: '+8.7%'
    },
    {
      title: 'Response Time',
      value: '2.4h',
      icon: <Speed sx={{ color: '#9c27b0' }} />,
      trend: '-18%'
    }
  ];

  return (
    <Grid container spacing={3} sx={{ mb: 4 }}>
      {metrics.map((metric, index) => (
        <Grid item xs={12} sm={6} md={3} key={index}>
          <MetricCard>
            <CardContent>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                {metric.icon}
                <Typography 
                  variant="caption" 
                  sx={{ 
                    color: metric.trend.startsWith('+') ? 'success.main' : 'error.main',
                    fontWeight: 600 
                  }}
                >
                  {metric.trend}
                </Typography>
              </Box>
              <Typography variant="h4" sx={{ mb: 1, fontWeight: 600 }}>
                {metric.value}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {metric.title}
              </Typography>
            </CardContent>
          </MetricCard>
        </Grid>
      ))}
    </Grid>
  );
};

export default LeadMetricsCards; 