import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  Typography,
  Button,
  Grid,
  Paper,
  Box,
  Snackbar,
  Alert,
  Stepper,
  Step,
  StepLabel,
  Fade,
  IconButton,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Switch
} from '@mui/material';
import { debounce } from 'lodash';
import ClientEstimateForm from './estimate/ClientEstimateForm.tsx';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import styles from './AdminCreateEstimate.module.scss';
import DeleteIcon from '@mui/icons-material/Delete';
import EstimateReview from './estimate/EstimateReview.tsx';
import { createEstimate, createClient, getBusinessDetails, getClients, uploadProjectPhoto, createProject, createEvent, getTermsTemplates } from '../services/api';
import SavedItemsManager from './SavedItemsManager';
import AddIcon from '@mui/icons-material/Add';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import WorkIcon from '@mui/icons-material/Work';
import api from '../services/api';

const steps = [
  'Client Details',
  'Items & Pricing',
  'Additional Details',
  'Project Photos',
  'Review'
];

const initialEstimateState = {
  estimate_number: '',
  project_name: '',
  issue_date: new Date().toISOString().split('T')[0],
  expiration_date: new Date(new Date().setDate(new Date().getDate() + 30)).toISOString().split('T')[0],
  items: [],
  scope_of_work: '',
  notes: '',
  terms: '',
  template: 'default',
  logo: '',
  project_images: [],
  logo_size: null,
  business_details: {
    company_name: '',
    owner_name: '',
    address: '',
    city: '',
    state: '',
    zip_code: '',
    phone: '',
    email: '',
    website: '',
    tax_id: ''
  },
  customer_details: {
    name: '',
    company: '',
    email: '',
    phone: '',
    address: '',
    city: '',
    state: '',
    zipCode: '',
    notes: '',
    isNewClient: true
  },
  status: 'draft',
  admin_signature: null,
  admin_signed_at: null,
  admin_signed_name: null,
  admin_signature_type: null,
  client_signature: null,
  client_signed_at: null,
  client_signed_name: null,
  client_signature_type: null,
  show_additional_info: false,
  service_address: '',
  service_city: '',
  service_state: '',
  service_zip: '',
  use_client_address: true,
};

// Define common input styles
const commonInputStyles = {
  '& .MuiOutlinedInput-root': {
    height: '45px',
    backgroundColor: '#fff',
    '& fieldset': {
      border: '1px solid #ccc',
      borderRadius: '4px',
    },
    '&:hover fieldset': {
      borderColor: '#666',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#2196f3',
    }
  },
  '& .MuiInputLabel-root': {
    color: '#666',
  }
};

// For multiline inputs like notes
const multilineInputStyles = {
  '& .MuiOutlinedInput-root': {
    backgroundColor: '#fff',
    '& fieldset': {
      border: '1px solid #ccc',
      borderRadius: '4px',
    },
    '&:hover fieldset': {
      borderColor: '#666',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#2196f3',
    }
  },
  '& .MuiInputLabel-root': {
    color: '#666',
  }
};

const AdminCreateEstimate = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [activeStep, setActiveStep] = useState(0);
  
  const [showTabbedVersion, setShowTabbedVersion] = useState(true);

  const handleVersionToggle = () => {
    setShowTabbedVersion(!showTabbedVersion);
  };

  console.log('AdminCreateEstimate initializing with state:', state);

  const [estimate, setEstimate] = useState(() => {
    if (state?.estimate) {
      const formattedEstimate = {
        ...initialEstimateState,
        ...state.estimate,
        items: state.estimate.items.map(item => ({
          title: item.title || item.description || '',
          description: item.description || '',
          quantity: Number(item.quantity) || 0,
          unitPrice: Number(item.unit_price || item.unitPrice) || 0,
          tax: Number(item.tax) || 0
        })),
        customer_details: {
          ...initialEstimateState.customer_details,
          ...state.estimate.customer_details,
          isNewClient: false
        },
        business_details: {
          ...initialEstimateState.business_details,
          ...state.estimate.business_details
        },
        project_images: state.estimate.project_images || [],
        scope_of_work: state.estimate.scope_of_work || '',
        notes: state.estimate.notes || '',
        terms: state.estimate.terms || '',
        template: state.estimate.template || 'default'
      };
      
      console.log('Formatted estimate for editing:', formattedEstimate);
      return formattedEstimate;
    }
    return initialEstimateState;
  });

  // Initialize selected client if editing
  const [selectedClient, setSelectedClient] = useState(
    state?.estimate?.customer_details 
      ? {
          id: state.estimate.customer_details.id,
          ...state.estimate.customer_details
        }
      : null
  );

  const [isLoading, setIsLoading] = useState(false);
  const [previewContent, setPreviewContent] = useState('');
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [templates, setTemplates] = useState([]);
  const [clients, setClients] = useState([]);
  const [businessDetails, setBusinessDetails] = useState(null);
  const [nextEstimateNumber, setNextEstimateNumber] = useState('');
  const [addressSuggestions, setAddressSuggestions] = useState([]);
  const [isLoadingAddress, setIsLoadingAddress] = useState(false);
  const [isLoadingPhotos, setIsLoadingPhotos] = useState(false);
  const [photoError, setPhotoError] = useState('');
  const [savedItems, setSavedItems] = useState([]);
  const [projectImages, setProjectImages] = useState([]);

  // Move useState to component level
  const [isDragging, setIsDragging] = useState(false);

  // Add new state for address checkbox
  const [useClientAddress, setUseClientAddress] = useState(true);
  const [serviceAddress, setServiceAddress] = useState({
    address: '',
    city: '',
    state: '',
    zip: ''
  });

  // Update the initial project details state
  const [projectDetails, setProjectDetails] = useState({
    title: '',
    start_date: new Date().toISOString().split('T')[0],
    end_date: '',
    address: estimate.customer_details?.address || '',
    city: estimate.customer_details?.city || '',
    state: estimate.customer_details?.state || '',
    zipCode: estimate.customer_details?.zipCode || ''
  });

  // Add effect to update project address when client details change
  useEffect(() => {
    if (useClientAddress && estimate.customer_details) {
      setProjectDetails(prev => ({
        ...prev,
        address: estimate.customer_details.address || '',
        city: estimate.customer_details.city || '',
        state: estimate.customer_details.state || '',
        zipCode: estimate.customer_details.zipCode || ''
      }));
    }
  }, [estimate.customer_details, useClientAddress]);

  useEffect(() => {
    console.log('Received state:', state);
    console.log('Received estimate:', state?.estimate);
  }, [state]);

  useEffect(() => {
    if (state?.estimate?.customer_details) {
      setSelectedClient({
        id: state.estimate.customer_details.id,
        ...state.estimate.customer_details
      });
    }
  }, [state]);

  useEffect(() => {
    console.log('Initial estimate state:', estimate);
  }, []);

  useEffect(() => {
    console.log('project_images updated:', estimate.project_images);
  }, [estimate.project_images]);

  const handleNext = async () => {
    console.log('HANDLE NEXT TRIGGERED', activeStep);
    if (activeStep === 0 && estimate.customer_details.isNewClient) {
        try {
            console.log('ATTEMPTING TO CREATE CLIENT IN HANDLE NEXT');
            setIsLoading(true);
            
            const clientData = {
                name: estimate.customer_details.name,
                email: estimate.customer_details.email,
                phone: estimate.customer_details.phone,
                address: estimate.customer_details.address,
                city: estimate.customer_details.city,
                state: estimate.customer_details.state,
                zipCode: estimate.customer_details.zipCode,
                company: estimate.customer_details.company || '',
                notes: estimate.customer_details.notes || ''
            };

            console.log('CLIENT DATA:', clientData);
            await handleClientSubmit(clientData);
        } catch (error) {
            console.error('Failed to create client:', error);
            setErrorMessage('Failed to create client. Please try again.');
            setShowError(true);
            return;
        } finally {
            setIsLoading(false);
        }
    }
    setActiveStep((prevStep) => prevStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  const handleCustomerDetailsChange = (field, value) => {
    console.log('Updating customer details:', field, value);
    setEstimate(prev => ({
      ...prev,
      customer_details: {
        ...prev.customer_details,
        [field]: value,
        isNewClient: true
      }
    }));
  };

  const handleInputChange = (field, value) => {
    setEstimate(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const removeItem = (index) => {
    setEstimate(prev => ({
      ...prev,
      items: prev.items.filter((_, i) => i !== index)
    }));
  };

  const handleItemChange = (index, field, value) => {
    setEstimate(prev => ({
      ...prev,
      items: prev.items.map((item, i) => 
        i === index ? { ...item, [field]: value } : item
      )
    }));
  };

  const calculateSubtotal = estimate.items.reduce((sum, item) => 
    sum + (Number(item.quantity) * Number(item.unitPrice)), 0
  );

  const calculateTax = estimate.items.reduce((sum, item) => 
    sum + (Number(item.quantity) * Number(item.unitPrice) * Number(item.tax) / 100), 0
  );

  const calculateTotal = calculateSubtotal + calculateTax;

  // Separate button handler from form submit
  const handleCreateEstimateClick = () => {
    handleSubmit();
  };

  // Remove or simplify handleSubmit since we're using the button handler
  const handleSubmit = async () => {
    try {
        setIsLoading(true);
        console.log("Starting submit with customer details:", estimate.customer_details);
        
        // Create client first if it's a new client
        let clientId;
        if (estimate.customer_details.isNewClient) {
            // Format client data correctly with explicit zipCode
            const clientData = {
                name: estimate.customer_details.name,
                email: estimate.customer_details.email,
                phone: estimate.customer_details.phone,
                address: estimate.customer_details.address,
                city: estimate.customer_details.city,
                state: estimate.customer_details.state,
                zipCode: estimate.customer_details.zipCode, // Explicitly include zipCode
                company: estimate.customer_details.company || '',
                notes: estimate.customer_details.notes || ''
            };

            console.log('Creating new client with data:', clientData); // Debug log
            const newClient = await createClient(clientData);
            console.log('New client created:', newClient);
            clientId = newClient.id;
        } else {
            clientId = selectedClient.id;
        }

        // Now create the estimate with the correct client ID and zipCode
        const estimateData = {
            client_id: clientId,
            estimate_number: nextEstimateNumber,
            issue_date: estimate.issue_date,
            expiration_date: estimate.expiration_date,
            project_name: estimate.project_name,
            items: estimate.items,
            scope_of_work: estimate.scope_of_work || '',
            notes: estimate.notes || '',
            terms: estimate.terms || '',
            template: estimate.template || 'default',
            logo: estimate.logo || '',
            logo_size: estimate.logo_size || 100,
            project_images: estimate.project_images || [],
            business_details: estimate.business_details || {},
            customer_details: {
                ...estimate.customer_details,
                address: estimate.customer_details.address,
                city: estimate.customer_details.city,
                state: estimate.customer_details.state,
                zip_code: estimate.customer_details.zipCode // Ensure zipCode is included
            },
            service_address: useClientAddress ? estimate.customer_details.address : estimate.service_address,
            service_city: useClientAddress ? estimate.customer_details.city : estimate.service_city,
            service_state: useClientAddress ? estimate.customer_details.state : estimate.service_state,
            service_zip: useClientAddress ? estimate.customer_details.zipCode : estimate.service_zip,
            use_client_address: useClientAddress
        };

        console.log('Submitting estimate with data:', estimateData); // Debug log
        const response = await api.post('/estimates', estimateData);
        
        setShowSuccess(true);
        
        setTimeout(() => {
            navigate(`/admin/estimates/${response.data.id}`);
        }, 2000);
    } catch (error) {
        console.error('Failed to create estimate:', error);
        setErrorMessage(error.response?.data?.message || 'Error creating estimate');
        setShowError(true);
    } finally {
        setIsLoading(false);
    }
};

  // Fetch initial data
  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        setIsLoading(true);
        const [businessData, fetchedClients] = await Promise.all([
          getBusinessDetails(),
          getClients()
        ]);

        // Ensure clients is an array and properly formatted
        const formattedClients = Array.isArray(fetchedClients) 
          ? fetchedClients.map(client => ({
              id: client.id,
              name: client.name,
              company: client.company || '',
              email: client.email || '',
              phone: client.phone || '',
              address: client.address || '',
              city: client.city || '',
              state: client.state || '',
              zipCode: client.zipCode || '',
              notes: client.notes || '',
              created_at: client.created_at
            }))
          : [];

        console.log('Formatted clients:', formattedClients);
        setClients(formattedClients);
        setBusinessDetails(businessData);

        // Generate estimate number
        const nextNumber = 'EST-' + new Date().getTime();
        setNextEstimateNumber(nextNumber);

        setEstimate(prev => ({
          ...prev,
          estimate_number: nextNumber,
          business_details: businessData
        }));

      } catch (error) {
        console.error('Error fetching initial data:', error);
        setErrorMessage('Failed to load initial data');
        setShowError(true);
        // Initialize with empty array on error
        setClients([]);
      } finally {
        setIsLoading(false);
      }
    };

    fetchInitialData();
  }, []);

  // Fix handleClientSubmit to properly handle ALL address fields
  const handleClientSubmit = async (clientData) => {
    console.log('HANDLE CLIENT SUBMIT TRIGGERED with full data:', clientData);
    if (!clientData) {
        console.error('No client data received');
        return;
    }

    try {
        const clientPayload = {
            name: clientData.name,
            email: clientData.email,
            phone: clientData.phone,
            address: clientData.address,
            city: clientData.city,
            state: clientData.state,
            zip_code: clientData.zipCode,  // Convert to snake_case when sending to backend
            company: clientData.company || '',
            notes: clientData.notes || ''
        };

        console.log('Creating client with FULL payload:', clientPayload);
        const newClient = await createClient(clientPayload);
        console.log('New client created with address fields:', newClient);

        return newClient;
    } catch (error) {
        console.error('CLIENT CREATION FAILED:', error);
        throw error;
    }
};

  const searchAddress = async (query) => {
    if (!query || query.length < 3) return;
    
    setIsLoadingAddress(true);
    try {
      const response = await fetch(
        `https://nominatim.openstreetmap.org/search?format=json&q=${encodeURIComponent(query)}&limit=5&addressdetails=1&countrycodes=us`,
        {
          headers: {
            'Accept-Language': 'en-US',
            'User-Agent': 'EverProCleaning_EstimateSystem/1.0',
            'Referer': 'https://teams.everprocleaning.com'
          }
        }
      );
      
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      
      const data = await response.json();
      console.log('Raw address data:', data);
      
      // Format the suggestions with proper data extraction
      const formattedSuggestions = data.map(item => {
        console.log('Processing item:', item); // Debug log
        
        return {
          display_name: item.display_name,
          formatted_address: {
            full_address: item.display_name,
            street_number: item.address?.house_number || '',
            street_name: item.address?.road || item.address?.street || '',
            city: item.address?.city || 
                  item.address?.town || 
                  item.address?.village || 
                  item.address?.municipality || '',
            state: item.address?.state || '',
            zip: item.address?.postcode || '',
            county: item.address?.county || '',
            country: item.address?.country || 'United States'
          }
        };
      });
      
      console.log('Formatted suggestions:', formattedSuggestions);
      setAddressSuggestions(formattedSuggestions);
    } catch (error) {
      console.error('Error fetching address suggestions:', error);
      setAddressSuggestions([]);
    } finally {
      setIsLoadingAddress(false);
    }
  };

  // Increase debounce time to avoid rate limiting
  const debouncedSearchAddress = debounce(searchAddress, 1000);

  // Update the handleAddressSelect function to properly handle the data
  const handleAddressSelect = (selectedAddress) => {
    console.log('Selected Address Data:', selectedAddress);
    
    if (!selectedAddress?.formatted_address) {
        console.error('NO FORMATTED ADDRESS');
        return;
    }
    
    const addr = selectedAddress.formatted_address;
    console.log('Parsed Address with ZIP:', addr);
    
    setEstimate(prev => {
        const newCustomerDetails = {
            ...prev.customer_details,
            address: `${addr.street_number} ${addr.street_name}`.trim(),
            city: addr.city,
            state: addr.state,
            zipCode: addr.zip
        };
        console.log('New Customer Details:', newCustomerDetails);
        return {
            ...prev,
            customer_details: newCustomerDetails
        };
    });
  };

  const renderClientDetails = () => (
    <Fade in={activeStep === 0}>
      <Paper className={styles.paper}>
        {/* Project Title Section */}
        <Box sx={{ mb: 4 }}>
          <Typography 
            variant="h6" 
            gutterBottom
            sx={{
              color: '#34495e',
              fontSize: '1.1rem',
              fontWeight: 500,
              mb: 2,
              display: 'flex',
              alignItems: 'center',
              '&::before': {
                content: '""',
                width: '4px',
                height: '24px',
                backgroundColor: '#9b59b6',
                marginRight: '12px',
                borderRadius: '4px'
              }
            }}
          >
            Project Title
          </Typography>
          <TextField
            fullWidth
            label="Project Title"
            value={estimate.project_name}
            onChange={(e) => handleEstimateChange('project_name', e.target.value)}
            placeholder="Enter a title for this project..."
            sx={commonInputStyles}
          />
        </Box>

        {/* Client Form */}
        <ClientEstimateForm 
          onClientSelect={handleClientSelect}
          onClientCreate={handleClientSubmit}
          styles={styles}
          selectedClient={selectedClient}
          initialData={estimate.customer_details}
        />

        {/* Service Address Section */}
        <Box sx={{ mt: 4 }}>
          <Typography 
            variant="h6" 
            gutterBottom
            sx={{
              color: '#34495e',
              fontSize: '1.1rem',
              fontWeight: 500,
              mb: 2,
              display: 'flex',
              alignItems: 'center',
              '&::before': {
                content: '""',
                width: '4px',
                height: '24px',
                backgroundColor: '#3498db',
                marginRight: '12px',
                borderRadius: '4px'
              }
            }}
          >
            Service Address
          </Typography>

          <FormControlLabel
            control={
              <Switch
                checked={useClientAddress}
                onChange={(e) => setUseClientAddress(e.target.checked)}
                color="primary"
              />
            }
            label="Use Client Address"
            sx={{ mb: 2 }}
          />

          {!useClientAddress && (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Address"
                  value={estimate.service_address}
                  onChange={(e) => handleServiceAddressChange('address', e.target.value)}
                  sx={commonInputStyles}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  label="City"
                  value={estimate.service_city}
                  onChange={(e) => handleServiceAddressChange('city', e.target.value)}
                  sx={commonInputStyles}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  label="State"
                  value={estimate.service_state}
                  onChange={(e) => handleServiceAddressChange('state', e.target.value)}
                  sx={commonInputStyles}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  label="ZIP Code"
                  value={estimate.service_zip}
                  onChange={(e) => handleServiceAddressChange('zip', e.target.value)}
                  sx={commonInputStyles}
                />
              </Grid>
            </Grid>
          )}
        </Box>
      </Paper>
    </Fade>
  );

  const renderItemsAndPricing = () => (
    <Fade in={activeStep === 1}>
      <Paper 
        className={styles.paper}
        sx={{
          backgroundColor: '#fff',
          borderRadius: '12px',
          boxShadow: '0 2px 12px rgba(0,0,0,0.08)'
        }}
      >
        <Typography 
          className={styles.sectionTitle}
          sx={{
            fontSize: '1.5rem',
            fontWeight: 600,
            color: '#2c3e50',
            mb: 4,
            borderBottom: '2px solid #eee',
            paddingBottom: '1rem',
          }}
        >
          Items & Pricing
        </Typography>

        <SavedItemsManager
          onAddToEstimate={handleAddToEstimate}
          onRemoveItem={removeItem}
          estimateItems={estimate.items}
          savedItems={savedItems}
          setSavedItems={setSavedItems}
        />
      </Paper>
    </Fade>
  );

  const handleEstimateChange = (field, value) => {
    console.log(`Updating ${field} with:`, value);
    setEstimate(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const renderAdditionalDetails = () => (
    <Fade in={activeStep === 2}>
      <Paper 
        className={styles.paper}
        sx={{
          backgroundColor: '#fff',
          borderRadius: '12px',
          boxShadow: '0 2px 12px rgba(0,0,0,0.08)'
        }}
      >
        <Typography 
          className={styles.sectionTitle}
          sx={{
            fontSize: '1.5rem',
            fontWeight: 600,
            color: '#2c3e50',
            mb: 4,
            borderBottom: '2px solid #eee',
            paddingBottom: '1rem'
          }}
        >
          Additional Details
        </Typography>

        <Box sx={{ mb: 5 }}>
          <Typography 
            variant="h6" 
            gutterBottom
            sx={{
              color: '#34495e',
              fontSize: '1.1rem',
              fontWeight: 500,
              mb: 2,
              display: 'flex',
              alignItems: 'center',
              '&::before': {
                content: '""',
                width: '4px',
                height: '24px',
                backgroundColor: '#3498db',
                marginRight: '12px',
                borderRadius: '4px'
              }
            }}
          >
            Client Message
          </Typography>
          <ReactQuill
            value={estimate.scope_of_work}
            onChange={(content) => handleEstimateChange('scope_of_work', content)}
            placeholder="Message to the client..."
            className={styles.editor}
            modules={{
              toolbar: [
                ['bold', 'italic', 'underline'],
                [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                ['clean']
              ]
            }}
          />
        </Box>

        {/* Notes Section */}
        <Box 
          sx={{ 
            mb: 5,
            '& .ql-toolbar': {
              borderRadius: '8px 8px 0 0',
              border: '1px solid #e0e0e0',
              backgroundColor: '#f8f9fa'
            },
            '& .ql-container': {
              borderRadius: '0 0 8px 8px',
              border: '1px solid #e0e0e0',
              borderTop: 'none',
              minHeight: '150px'
            }
          }}
        >
          <Typography 
            variant="h6" 
            gutterBottom
            sx={{
              color: '#34495e',
              fontSize: '1.1rem',
              fontWeight: 500,
              mb: 2,
              display: 'flex',
              alignItems: 'center',
              '&::before': {
                content: '""',
                width: '4px',
                height: '24px',
                backgroundColor: '#e74c3c',
                marginRight: '12px',
                borderRadius: '4px'
              }
            }}
          >
            Notes
          </Typography>
          <ReactQuill
            value={estimate.notes}
            onChange={(content) => handleEstimateChange('notes', content)}
            placeholder="Add any important notes or special considerations..."
            className={styles.editor}
            modules={{
              toolbar: [
                ['bold', 'italic', 'underline'],
                [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                ['clean']
              ]
            }}
          />
        </Box>

        {/* Terms Section */}
        <Box 
          sx={{ 
            mb: 3,
            '& .ql-toolbar': {
              borderRadius: '8px 8px 0 0',
              border: '1px solid #e0e0e0',
              backgroundColor: '#f8f9fa'
            },
            '& .ql-container': {
              borderRadius: '0 0 8px 8px',
              border: '1px solid #e0e0e0',
              borderTop: 'none',
              minHeight: '150px'
            }
          }}
        >
            <Typography 
              variant="h6" 
              gutterBottom
              sx={{
                color: '#34495e',
                fontSize: '1.1rem',
                fontWeight: 500,
                mb: 2,
                display: 'flex',
                alignItems: 'center',
                '&::before': {
                  content: '""',
                  width: '4px',
                  height: '24px',
                  backgroundColor: '#27ae60',
                  marginRight: '12px',
                  borderRadius: '4px'
                }
              }}
            >
              Terms & Conditions
            </Typography>
            <Box sx={{ mb: 2 }}>
              <TextField
                select
                fullWidth
                label="Select Terms Template"
                value={selectedTermsTemplate?.id || ''}
                onChange={handleTermsTemplateChange}
                sx={commonInputStyles}
              >
                <MenuItem value="">Custom Terms</MenuItem>
                {termsTemplates.map((template) => (
                  <MenuItem key={template.id} value={template.id}>
                    {template.name}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
            <ReactQuill
              value={estimate.terms}
              onChange={(content) => handleEstimateChange('terms', content)}
              placeholder="Specify your terms and conditions..."
              className={styles.editor}
              modules={{
                toolbar: [
                  ['bold', 'italic', 'underline'],
                  [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                  ['clean']
                ]
              }}
            />
        </Box>
      </Paper>
    </Fade>
  );

  const handleFileUpload = async (event) => {
    try {
      const files = Array.from(event.target.files);
      setIsLoading(true);
      
      for (const file of files) {
        const formData = new FormData();
        formData.append('image', file);
        
        const response = await api.post('/upload/estimate-photos', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          }
        });
        
        console.log('Upload response:', response.data); // Debug log
        
        if (response.data?.url) {
          // Update estimate state with new image
          setEstimate(prev => ({
            ...prev,
            project_images: [...(prev.project_images || []), {
              url: response.data.url.replace(process.env.REACT_APP_API_URL, ''), // Store relative URL
              filename: response.data.filename
            }]
          }));
        }
      }
    } catch (error) {
      console.error('Error uploading files:', error);
      setErrorMessage('Failed to upload images');
      setShowError(true);
    } finally {
      setIsLoading(false);
    }
  };

  // Update renderProjectPhotos to ensure project_images exists
  const renderProjectPhotos = () => (
    <Fade in={activeStep === 3}>
      <Paper className={styles.paper}>
        <Typography variant="h6" gutterBottom>
          Project Photos
        </Typography>
        
        <input
          type="file"
          accept="image/*"
          multiple
          onChange={handleFileUpload}
          style={{ display: 'none' }}
          id="project-photo-input"
        />
        
        <label htmlFor="project-photo-input" style={{ width: '100%', display: 'block' }}>
          <Box
            sx={{
              border: '2px dashed #ccc',
              borderRadius: '8px',
              p: 3,
              mb: 3,
              textAlign: 'center',
              backgroundColor: '#fafafa',
              cursor: 'pointer',
              transition: 'all 0.2s ease',
              '&:hover': {
                borderColor: '#2196f3',
                backgroundColor: '#f0f7ff'
              }
            }}
            onDrop={(e) => {
              e.preventDefault();
              const files = Array.from(e.dataTransfer.files);
              handleFileUpload({ target: { files } });
            }}
            onDragOver={(e) => e.preventDefault()}
          >
            <CloudUploadIcon sx={{ fontSize: 48, color: '#666', mb: 1 }} />
            <Typography>
              Drag & drop photos here or click to upload
            </Typography>
          </Box>
        </label>

        <Grid container spacing={2}>
          {(estimate.project_images || []).map((image, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Box sx={{ position: 'relative' }}>
                <img
                  src={`${image.url}`}
                  alt={`Project photo ${index + 1}`}
                  style={{
                    width: '100%',
                    height: '200px',
                    objectFit: 'cover',
                    borderRadius: '8px'
                  }}
                />
                <IconButton
                  onClick={() => {
                    setEstimate(prev => ({
                      ...prev,
                      project_images: prev.project_images.filter((_, i) => i !== index)
                    }));
                  }}
                  sx={{
                    position: 'absolute',
                    top: 8,
                    right: 8,
                    backgroundColor: 'rgba(255,255,255,0.8)',
                    zIndex: 2,
                    '&:hover': {
                      backgroundColor: 'rgba(255,255,255,0.95)',
                    }
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Paper>
    </Fade>
  );

  // Add this function to handle adding items
  const handleAddToEstimate = (newItem, index = null) => {
    if (index !== null) {
      // Handling edit of existing item
      setEstimate(prev => ({
        ...prev,
        items: prev.items.map((item, i) => 
          i === index ? newItem : item
        )
      }));
    } else {
      // Handling new item
      setEstimate(prev => ({
        ...prev,
        items: [...prev.items, newItem]
      }));
    }
  };

  const validateStep = () => {
    if (activeStep === steps.length - 1) {
      return canMoveToStep(activeStep);
    }
    return true;
  };

  const handleClientSelect = (client) => {
    console.log('Selected client with zipCode:', client.zipCode); // Debug log
    setSelectedClient(client);
    setEstimate(prev => {
        const newEstimate = {
            ...prev,
            customer_details: {
                ...prev.customer_details,
                id: client.id,
                name: client.name || '',
                company: client.company || '',
                email: client.email || '',
                phone: client.phone || '',
                address: client.address || '',
                city: client.city || '',
                state: client.state || '',
                zipCode: client.zipCode || '', // Ensure zipCode is set
                notes: client.notes || '',
                isNewClient: false
            }
        };
        console.log('Updated estimate with client details:', newEstimate); // Debug log
        return newEstimate;
    });
  };

  const handleNewClient = () => {
    setSelectedClient(null);
    setEstimate(prev => ({
      ...prev,
      customer_details: {
        ...initialEstimateState.customer_details,
        isNewClient: true
      }
    }));
  };

  const canMoveToStep = (stepIndex) => {
    // Allow moving back to any previous step
    if (stepIndex < activeStep) {
      return true;
    }

    // Validate current step before allowing movement to next step
    let isValid = true;
    let errorMsg = '';

    switch (activeStep) {
      case 0: // Client Details
        if (!projectDetails.title?.trim()) {
          errorMsg = 'Project title is required';
        } else if (!estimate.customer_details.name?.trim()) {
          errorMsg = 'Client name is required';
        } else if (!estimate.customer_details.email?.trim()) {
          errorMsg = 'Client email is required';
        } else if (!estimate.customer_details.phone?.trim()) {
          errorMsg = 'Client phone is required';
        } else if (!estimate.service_address && !useClientAddress) {
          errorMsg = 'Service address is required';
        }
        break;

      case 1: // Items & Pricing
        if (!estimate.items?.length) {
          errorMsg = 'At least one item is required';
        } else if (estimate.items.some(item => !item.title || !item.description || !item.quantity || !item.unitPrice)) {
          errorMsg = 'All item details (title, description, quantity, and price) are required';
        }
        break;

      case 2: // Additional Details
        // Remove this block
        // if (!estimate.scope_of_work?.trim()) {
        //   errorMsg = 'Client message is required';
        // }
        break;

      case 3: // Photos - no validation needed
        break;

      case 4: // Review - validate everything
        if (!projectDetails.title?.trim()) {
          errorMsg = 'Project title is missing';
        } else if (!estimate.customer_details.name?.trim()) {
          errorMsg = 'Client name is missing';
        } else if (!estimate.customer_details.email?.trim()) {
          errorMsg = 'Client email is missing';
        } else if (!estimate.customer_details.phone?.trim()) {
          errorMsg = 'Client phone is missing';
        } else if (!estimate.items?.length) {
          errorMsg = 'No items have been added';
        }
        break;

      default:
        break;
    }

    if (errorMsg) {
      setErrorMessage(errorMsg);
      setShowError(true);
      isValid = false;
    }

    return isValid;
  };

  const handleStepClick = (index) => {
    if (index === steps.length - 1) {
      if (canMoveToStep(index)) {
        setActiveStep(index);
      }
    } else {
      setActiveStep(index);
    }
  };

  // Add this function with the other handlers
  const handleRemoveImage = (index) => {
    const newImages = projectImages.filter((_, i) => i !== index);
    setProjectImages(newImages);
    
    // Update the estimate state as well
    setEstimate(prev => ({
      ...prev,
      project_images: newImages
    }));
  };

  // Add this helper function to calculate total from items
  const calculateTotalValue = (items) => {
    return items.reduce((total, item) => {
      const itemTotal = (item.quantity * item.unitPrice) * (1 + (item.tax || 0) / 100);
      return total + itemTotal;
    }, 0);
  };

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return renderClientDetails();
      case 1:
        return renderItemsAndPricing();
      case 2:
        return renderAdditionalDetails();
      case 3:
        return renderProjectPhotos();
      case 4:
        return (
          <EstimateReview 
            estimate={estimate} 
            styles={{
              paper: styles.paper,
              sectionTitle: styles.sectionTitle,
              reviewSection: styles.reviewSection,
              imageContainer: styles.imageContainer
            }} 
          />
        );
      default:
        return 'Unknown step';
    }
  };

  // Add handler for service address toggle
  const handleServiceAddressToggle = (event) => {
    const useClientAddr = event.target.checked;
    setUseClientAddress(useClientAddr);
    
    if (useClientAddr && estimate.customer_details) {
      setEstimate(prev => ({
        ...prev,
        service_address: estimate.customer_details.address || '',
        service_city: estimate.customer_details.city || '',
        service_state: estimate.customer_details.state || '',
        service_zip: estimate.customer_details.zipCode || ''
      }));
    }
  };

  // Add handler for service address changes
  const handleServiceAddressChange = (field, value) => {
    setEstimate(prev => ({
      ...prev,
      [`service_${field}`]: value
    }));
  };

  // Add these handlers near your other handlers
  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setIsDragging(false);
  };

  const handleDrop = async (e) => {
    e.preventDefault();
    setIsDragging(false);
    
    const files = Array.from(e.dataTransfer.files).filter(file => 
      file.type.startsWith('image/')
    );
    
    if (files.length > 0) {
      const event = { target: { files } };
      handleFileUpload(event);
    }
  };

  // Add near the top with other state declarations
  const [termsTemplates, setTermsTemplates] = useState([]);

  // Add this to your existing useEffect or create a new one
  useEffect(() => {
    const fetchTermsTemplates = async () => {
      try {
        const response = await getTermsTemplates();
        setTermsTemplates(response.data);
      } catch (error) {
        console.error('Error fetching terms templates:', error);
      }
    };

    fetchTermsTemplates();
  }, []);

  // Add this handler
  const handleTermsTemplateSelect = (event) => {
    const selectedTemplate = termsTemplates.find(t => t.id === event.target.value);
    if (selectedTemplate) {
      setEstimate(prev => ({
        ...prev,
        terms: selectedTemplate.content
      }));
    }
  };

  const [selectedTermsTemplate, setSelectedTermsTemplate] = useState(null);

  const handleTermsTemplateChange = (event) => {
    const selectedTemplate = termsTemplates.find(template => template.id === event.target.value);
    setSelectedTermsTemplate(selectedTemplate);
    if (selectedTemplate) {
      setEstimate(prev => ({
        ...prev,
        terms: selectedTemplate.content
      }));
    }
  };

  return (
    <Box className={styles.container}>
      {/* Log the current estimate state */}
      {console.log('Current estimate in render:', estimate)}
      
      <Box className={styles.documentHeader}>
        <Typography className={styles.title}>
          {state?.estimate ? 'Edit Estimate' : 'Create Estimate'}
        </Typography>
        
        <Button
          variant="outlined"
          color="primary"
          onClick={() => {
            console.log('Simple View button clicked');
            navigate('/admin/estimates/create-simple', { replace: true });
          }}
          sx={{
            ml: 2,
            borderColor: '#2196f3',
            color: '#2196f3',
            '&:hover': {
              borderColor: '#1976d2',
              backgroundColor: 'rgba(33, 150, 243, 0.04)'
            }
          }}
        >
          Page View
        </Button>
      </Box>

      {showTabbedVersion ? (
        <>
          <Stepper activeStep={activeStep} className={styles.stepper}>
            {steps.map((label, index) => (
              <Step 
                key={label}
                onClick={() => handleStepClick(index)}
                style={{ cursor: 'pointer' }}
              >
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          {getStepContent(activeStep)}
        </>
      ) : (
        // Non-tabbed version - all content on one page
        <Box>
          {steps.map((_, index) => (
            <Box key={index} mb={4}>
              {getStepContent(index)}
            </Box>
          ))}
        </Box>
      )}
      
      <div className={styles.buttonContainer}>
        <Button
          disabled={activeStep === 0}
          onClick={handleBack}
          type="button"
        >
          Back
        </Button>
        {activeStep === steps.length - 1 ? (
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            type="button"
            disabled={isLoading}
          >
            {isLoading ? 'Creating...' : 'Create Estimate'}
          </Button>
        ) : (
          <Button
            variant="contained"
            onClick={handleNext}
            type="button"
          >
            Next
          </Button>
        )}
      </div>

      <Snackbar
        open={showSuccess}
        autoHideDuration={6000}
        onClose={() => setShowSuccess(false)}
      >
        <Alert severity="success">Estimate created successfully!</Alert>
      </Snackbar>

      <Snackbar
        open={showError}
        autoHideDuration={6000}
        onClose={() => setShowError(false)}
      >
        <Alert severity="error">{errorMessage}</Alert>
      </Snackbar>
    </Box>
  );
};

export default AdminCreateEstimate;



