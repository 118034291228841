import React, { useState } from 'react';
import { 
  Box,
  Paper, 
  Typography, 
  List, 
  ListItem, 
  ListItemText, 
  ListItemAvatar, 
  Avatar, 
  Chip, 
  IconButton,
  Fab,
  Tooltip
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { 
  Phone as PhoneIcon,
  CallMade as CallMadeIcon,
  CallReceived as CallReceivedIcon,
  VoiceChat as VoiceChatIcon,
  Call as CallIcon,
  Dialpad as DialpadIcon,
  Chat as ChatIcon
} from '@mui/icons-material';
import ContactsSidebar from './ContactsSidebar';
import CallDialer from './CallDialer';
import CallTranscript from './CallTranscript';

const StyledContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2),
  height: '100%',
  flex: 1
}));

const StyledListItem = styled(ListItem)(({ theme }) => ({
  marginBottom: theme.spacing(1),
  borderRadius: theme.shape.borderRadius,
  transition: 'all 0.2s ease-in-out',
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
    transform: 'translateX(4px)'
  }
}));

const CallsView = ({ selectedContact, setSelectedContact }) => {
  const [isDialerOpen, setIsDialerOpen] = useState(false);
  const [isTranscriptOpen, setIsTranscriptOpen] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [selectedCall, setSelectedCall] = useState(null);
  
  const mockCalls = [
    {
      id: 1,
      type: 'outbound',
      status: 'completed',
      duration: '5:23',
      date: '2024-03-10',
      time: '14:30',
      contact: 'John Doe',
      notes: 'Discussed project timeline',
      hasTranscript: true,
      transcript: [
        {
          id: 1,
          speaker: 'agent',
          message: "Hello, thank you for calling. How may I assist you today?",
          timestamp: '00:00'
        },
        {
          id: 2,
          speaker: 'customer',
          message: "Hi, I'm calling about my recent service request.",
          timestamp: '00:05'
        }
      ]
    }
  ];

  const handleDialerOpen = () => setIsDialerOpen(true);
  const handleDialerClose = () => {
    setIsDialerOpen(false);
    setPhoneNumber('');
  };

  const handleTranscriptOpen = (call) => {
    setSelectedCall(call);
    setIsTranscriptOpen(true);
  };

  const handleTranscriptClose = () => {
    setIsTranscriptOpen(false);
    setSelectedCall(null);
  };

  const handleCall = () => {
    console.log('Calling:', phoneNumber);
    handleDialerClose();
  };

  return (
    <StyledContainer>
      <ContactsSidebar 
        contacts={[]}
        selectedContact={selectedContact}
        setSelectedContact={setSelectedContact}
        communicationType="calls"
      />
      
      <Paper sx={{ 
        flex: 1,
        p: 3, 
        height: 'calc(100vh - 280px)', 
        borderRadius: 2,
        border: '1px solid',
        borderColor: 'divider',
        position: 'relative',
        overflow: 'hidden'
      }}>
        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'space-between', 
          mb: 3,
          borderBottom: 1,
          borderColor: 'divider',
          pb: 2
        }}>
          <Typography variant="h6" sx={{ 
            display: 'flex', 
            alignItems: 'center', 
            gap: 1,
            fontFamily: 'Urbanist, sans-serif'
          }}>
            <PhoneIcon />
            Call History
          </Typography>
          <Box>
            <Tooltip title="Open dialer">
              <IconButton color="primary" onClick={handleDialerOpen}>
                <DialpadIcon />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
        
        <List sx={{ overflowY: 'auto', height: 'calc(100% - 64px)' }}>
          {mockCalls.map((call) => (
            <StyledListItem key={call.id}>
              <ListItemAvatar>
                <Avatar sx={{ 
                  bgcolor: call.type === 'outbound' ? 'success.main' : 'primary.main',
                  boxShadow: 1
                }}>
                  {call.type === 'outbound' ? <CallMadeIcon /> : <CallReceivedIcon />}
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <Typography variant="subtitle1" sx={{ fontFamily: 'Urbanist, sans-serif' }}>
                      {call.contact}
                    </Typography>
                    <Chip 
                      label={call.duration} 
                      size="small" 
                      color={call.status === 'completed' ? 'success' : 'default'}
                      sx={{ fontFamily: 'Urbanist, sans-serif' }}
                    />
                    {call.hasTranscript && (
                      <Tooltip title="View transcript">
                        <IconButton 
                          size="small" 
                          color="primary" 
                          onClick={() => handleTranscriptOpen(call)}
                          sx={{ 
                            '&:hover': { 
                              backgroundColor: 'primary.lighter',
                              transform: 'scale(1.1)'
                            }
                          }}
                        >
                          <ChatIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                  </Box>
                }
                secondary={
                  <Box sx={{ mt: 0.5 }}>
                    <Typography variant="body2" color="text.secondary">
                      {call.notes}
                    </Typography>
                    <Typography 
                      variant="caption" 
                      color="text.secondary"
                      sx={{ display: 'block', mt: 0.5 }}
                    >
                      {`${call.date} at ${call.time}`}
                    </Typography>
                  </Box>
                }
              />
            </StyledListItem>
          ))}
        </List>

        <Fab 
          color="primary" 
          sx={{ 
            position: 'absolute', 
            bottom: 16, 
            right: 16,
            boxShadow: 3,
            '&:hover': {
              transform: 'scale(1.1)'
            }
          }}
          onClick={handleDialerOpen}
        >
          <CallIcon />
        </Fab>

        <CallDialer 
          open={isDialerOpen}
          onClose={handleDialerClose}
          onCall={handleCall}
        />

        <CallTranscript 
          open={isTranscriptOpen}
          onClose={handleTranscriptClose}
          transcript={selectedCall?.transcript}
        />
      </Paper>
    </StyledContainer>
  );
};

export default CallsView;