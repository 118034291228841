import React, { useState } from 'react';
import { SpeedDial, SpeedDialAction } from '@mui/material';
import { 
  Add as AddIcon,
  Person as PersonIcon,
  Business as BusinessIcon,
  Assignment as AssignmentIcon,
  RequestQuote as RequestQuoteIcon
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '../ThemeContext';

const CreateButton = () => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const { darkMode } = useTheme();

  const actions = [
    { 
      icon: <AssignmentIcon sx={{ color: darkMode ? '#90caf9' : '#2196f3' }} />,
      name: 'Create Project', 
      route: '/admin/projects/create' 
    },
    { 
      icon: <RequestQuoteIcon sx={{ color: darkMode ? '#66bb6a' : '#4caf50' }} />,
      name: 'Create Estimate', 
      route: '/admin/estimates/create' 
    },
    { 
      icon: <PersonIcon sx={{ color: darkMode ? '#ffb74d' : '#ff9800' }} />,
      name: 'Create Employee', 
      route: '/admin/create-employee' 
    },
    { 
      icon: <BusinessIcon sx={{ color: darkMode ? '#ef5350' : '#f44336' }} />,
      name: 'Create Client', 
      route: '/admin/create-client' 
    }
  ];

  return (
    <SpeedDial
      ariaLabel="Create Menu"
      sx={{
        position: 'fixed',
        bottom: 16,
        right: 16,
        zIndex: 1000,
      }}
      icon={<AddIcon sx={{ color: darkMode ? 'var(--text-primary)' : '#1a2027' }} />}
      open={open}
      onClick={() => setOpen(!open)}
      onOpen={() => {}}
      onClose={() => {}}
      direction="up"
      FabProps={{
        sx: {
          bgcolor: darkMode ? 'var(--background-paper)' : 'white',
          '&:hover': {
            bgcolor: darkMode ? 'var(--background-hover)' : 'rgba(0, 0, 0, 0.04)',
          },
          border: darkMode ? '1px solid var(--border-color)' : 'none',
          boxShadow: darkMode ? '0 4px 20px rgba(0,0,0,0.3)' : '0 4px 20px rgba(0,0,0,0.15)',
        }
      }}
    >
      {actions.map((action) => (
        <SpeedDialAction
          key={action.name}
          icon={action.icon}
          tooltipTitle={action.name}
          tooltipOpen
          onClick={() => navigate(action.route)}
          FabProps={{
            sx: {
              bgcolor: darkMode ? 'var(--background-paper)' : 'white',
              '&:hover': {
                bgcolor: darkMode ? 'var(--background-hover)' : 'rgba(0, 0, 0, 0.04)',
              },
              border: darkMode ? '1px solid var(--border-color)' : 'none',
              boxShadow: darkMode ? '0 4px 20px rgba(0,0,0,0.3)' : '0 4px 20px rgba(0,0,0,0.15)',
            }
          }}
          sx={{
            '& .MuiSpeedDialAction-staticTooltipLabel': {
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              maxWidth: '120px',
              backgroundColor: darkMode ? 'var(--background-paper)' : 'white',
              color: darkMode ? 'var(--text-primary)' : '#1a2027',
              border: darkMode 
                ? '1px solid var(--border-color)' 
                : '1px solid rgba(0, 0, 0, 0.12)',
              fontSize: '0.8rem',
              padding: '4px 8px',
              borderRadius: '4px',
              boxShadow: darkMode 
                ? '0 2px 10px rgba(0,0,0,0.3)' 
                : '0 2px 10px rgba(0,0,0,0.1)',
            }
          }}
        />
      ))}
    </SpeedDial>
  );
};

export default CreateButton;
