import React from 'react';
import { Document, Page, Text, View, Image, StyleSheet } from '@react-pdf/renderer';
import { formatDate, formatDateTime } from '../utils/dateUtils';
import hardcodedLogo from './Updated logo (12).png';

// Create styles
const styles = StyleSheet.create({
  page: {
    padding: 30,
    fontSize: 12,
  },
  header: {
    flexDirection: 'column',
    marginBottom: 20,
  },
  logo: {
    width: 120,
    marginBottom: 10,
  },
  headerRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 20,
  },
  headerLeft: {
    flexDirection: 'column',
  },
  headerRight: {
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  title: {
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 5,
  },
  estimateId: {
    fontSize: 12,
    color: '#666',
  },
  divider: {
    borderBottomWidth: 1,
    borderBottomColor: '#eee',
    marginVertical: 10,
  },
  addressGrid: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 20,
  },
  addressColumn: {
    width: '48%',
  },
  addressTitle: {
    fontSize: 10,
    fontWeight: 'bold',
    marginBottom: 5,
    color: '#2c3e50',
  },
  addressText: {
    fontSize: 9,
    marginBottom: 3,
  },
  table: {
    flexDirection: 'column',
    marginTop: 20,
    borderWidth: 1,
    borderColor: '#eee',
  },
  tableHeader: {
    flexDirection: 'row',
    backgroundColor: '#f8f9ff',
    borderBottomWidth: 1,
    borderBottomColor: '#eee',
    padding: 8,
  },
  tableRow: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: '#eee',
    padding: 8,
  },
  tableCol1: { width: '40%' },
  tableCol2: { width: '20%', textAlign: 'right' },
  tableCol3: { width: '20%', textAlign: 'right' },
  tableCol4: { width: '20%', textAlign: 'right' },
  itemTitle: {
    fontSize: 10,
    fontWeight: 'bold',
    marginBottom: 3,
  },
  itemDescription: {
    fontSize: 9,
    color: '#666',
  },
  totalsSection: {
    width: '40%',
    marginLeft: 'auto',
    marginTop: 20,
  },
  totalRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: 5,
    borderBottomWidth: 1,
    borderBottomColor: '#eee',
  },
  totalRowBold: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: 5,
    borderTopWidth: 2,
    borderTopColor: '#eee',
    fontWeight: 'bold',
  },
  signatureSection: {
    marginTop: 30,
    padding: 15,
    backgroundColor: '#f8f9fa',
    borderRadius: 4,
  },
  termsSection: {
    marginTop: 30,
    padding: 15,
  },
  sectionTitle: {
    fontSize: 18,
    fontWeight: 'bold',
    marginBottom: 5,
  },
  termsContainer: {
    padding: 10,
    borderWidth: 1,
  },
  termsText: {
    fontSize: 9,
  },
  photo: {
    width: '100%',
    height: '100%',
  },
});

// Format phone number utility
const formatPhoneNumber = (phoneNumberString) => {
  if (!phoneNumberString) return '';
  const cleaned = phoneNumberString.toString().replace(/[^0-9]/g, '');
  const last10Digits = cleaned.slice(-10);
  if (last10Digits.length === 10) {
    return `(${last10Digits.slice(0,3)}) ${last10Digits.slice(3,6)}-${last10Digits.slice(6)}`;
  }
  return phoneNumberString;
};

// Helper function to clean HTML content
const cleanHtmlContent = (html) => {
  if (!html) return '';
  return html
    .replace(/<p>/g, '')  // Remove opening p tags
    .replace(/<\/p>/g, '') // Remove closing p tags
    .replace(/<br\s*\/?>/g, '\n') // Convert br to newlines
    .replace(/&nbsp;/g, ' ') // Convert HTML spaces
    .replace(/<[^>]*>/g, '') // Remove any other HTML tags
    .trim(); // Remove extra whitespace
};

// Main PDF Document component
const PDFTemplate = ({ estimate }) => {
  if (!estimate) return null;

  const safeItems = (estimate.items || []).map(item => ({
    title: item.title || '',
    description: item.description || '',
    quantity: Number(item.quantity) || 0,
    unitPrice: Number(item.unit_price || item.unitPrice) || 0,
    tax: Number(item.tax) || 0
  }));

  const calculateSubtotal = () => safeItems.reduce((sum, item) => 
    sum + (item.quantity * item.unitPrice), 0);

  const calculateTax = () => safeItems.reduce((sum, item) => 
    sum + (item.quantity * item.unitPrice * item.tax / 100), 0);

  const calculateTotal = () => calculateSubtotal() + calculateTax();

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.header}>
          {/* Logo */}
          <Image 
            src={hardcodedLogo} 
            style={styles.logo}
          />

          {/* Header Info */}
          <View style={styles.headerRow}>
            <View style={styles.headerLeft}>
              <Text style={styles.title}>Estimate</Text>
              <Text style={styles.estimateId}>ID #{estimate?.estimate_number || 'N/A'}</Text>
            </View>
            <View style={styles.headerRight}>
              <Text>Issue Date: {formatDate(estimate?.issue_date)}</Text>
              <Text>Valid Until: {formatDate(estimate?.expiration_date)}</Text>
            </View>
          </View>

          <View style={styles.divider} />

          {/* Address Grid */}
          <View style={styles.addressGrid}>
            {/* Bill From */}
            <View style={styles.addressColumn}>
              <Text style={styles.addressTitle}>BILL FROM</Text>
              <Text style={styles.addressText}>{estimate.business_details.company_name}</Text>
              <Text style={styles.addressText}>{estimate.business_details.address}</Text>
              <Text style={styles.addressText}>
                {estimate.business_details.city}, {estimate.business_details.state} {estimate.business_details.zip_code}
              </Text>
              <Text style={styles.addressText}>Phone: {formatPhoneNumber(estimate.business_details.phone)}</Text>
              <Text style={styles.addressText}>Email: {estimate.business_details.email}</Text>
            </View>

            {/* Bill To */}
            <View style={styles.addressColumn}>
              <Text style={styles.addressTitle}>BILL TO</Text>
              <Text style={styles.addressText}>{estimate.customer_details.name}</Text>
              <Text style={styles.addressText}>{estimate.customer_details.address}</Text>
              <Text style={styles.addressText}>
                {estimate.customer_details.city}, {estimate.customer_details.state} {estimate.customer_details.zip_code}
              </Text>
              <Text style={styles.addressText}>Phone: {formatPhoneNumber(estimate.customer_details.phone)}</Text>
              <Text style={styles.addressText}>Email: {estimate.customer_details.email}</Text>

              <Text style={[styles.addressTitle, { marginTop: 10 }]}>SERVICE ADDRESS</Text>
              <Text style={styles.addressText}>
                {[
                  estimate.service_address,
                  estimate.service_city,
                  estimate.service_state,
                  estimate.service_zip
                ].filter(Boolean).join(', ')}
              </Text>
            </View>
          </View>

          {/* Items Table */}
          <View style={styles.table}>
            <View style={styles.tableHeader}>
              <Text style={styles.tableCol1}>Item</Text>
              <Text style={styles.tableCol2}>Quantity</Text>
              <Text style={styles.tableCol3}>Unit Price</Text>
              <Text style={styles.tableCol4}>Total</Text>
            </View>

            {safeItems.map((item, index) => {
              const itemTotal = item.quantity * item.unitPrice * (1 + item.tax / 100);
              return (
                <View key={index} style={styles.tableRow}>
                  <View style={styles.tableCol1}>
                    <Text style={styles.itemTitle}>{item.title}</Text>
                    <Text style={styles.itemDescription}>{item.description}</Text>
                  </View>
                  <Text style={styles.tableCol2}>{item.quantity}</Text>
                  <Text style={styles.tableCol3}>${item.unitPrice.toFixed(2)}</Text>
                  <Text style={styles.tableCol4}>${itemTotal.toFixed(2)}</Text>
                </View>
              );
            })}
          </View>

          {/* Totals Section */}
          <View style={styles.totalsSection}>
            <View style={styles.totalRow}>
              <Text>Subtotal:</Text>
              <Text>${calculateSubtotal().toFixed(2)}</Text>
            </View>
            <View style={styles.totalRow}>
              <Text>Tax:</Text>
              <Text>${calculateTax().toFixed(2)}</Text>
            </View>
            <View style={styles.totalRowBold}>
              <Text>Total:</Text>
              <Text>${calculateTotal().toFixed(2)}</Text>
            </View>
          </View>

          {/* Signature Section */}
          <View style={styles.signatureSection}>
            <Text style={{ marginBottom: 10 }}>Customer Signature:</Text>
            {estimate?.client_signature ? (
              <>
                <Image 
                  src={estimate.client_signature} 
                  style={{ width: 200, marginBottom: 10 }} 
                />
                <Text>Signed: {formatDateTime(estimate.client_signed_at)}</Text>
                <Text>Name: {estimate.customer_details.name}</Text>
              </>
            ) : (
              <>
                <Text>Name: {estimate.customer_details.name}</Text>
                <Text>Date: _________________</Text>
              </>
            )}
          </View>
        </View>
      </Page>
      {/* Second Page - Terms (only show if terms exist) */}
      {estimate?.terms && cleanHtmlContent(estimate.terms) && (
        <Page size="A4" style={styles.page}>
          <View style={styles.termsSection}>
            <Text style={styles.sectionTitle}>Terms & Conditions</Text>
            <View style={styles.termsContainer}>
              <Text style={styles.termsText}>
                {cleanHtmlContent(estimate.terms)}
              </Text>
            </View>
          </View>
        </Page>
      )}

    </Document>

  );
};

export default PDFTemplate;