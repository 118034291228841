import React from 'react';
import {
  Box,
  Typography,
  ToggleButtonGroup,
  ToggleButton,
  Collapse,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import {
  Smartphone as MobileIcon,
  Tablet as TabletIcon,
  Laptop as DesktopIcon,
} from '@mui/icons-material';

const ResponsiveControls = ({ section, onUpdate }) => {
  const [selectedDevice, setSelectedDevice] = React.useState('desktop');

  const handleDeviceChange = (_, newDevice) => {
    if (newDevice) setSelectedDevice(newDevice);
  };

  const handleSettingChange = (property, value) => {
    onUpdate({
      ...section,
      responsive: {
        ...section.responsive,
        [selectedDevice]: {
          ...section.responsive?.[selectedDevice],
          [property]: value,
        },
      },
    });
  };

  return (
    <Box sx={{ p: 2, width: 300 }}>
      <Typography variant="subtitle2" gutterBottom>
        Responsive Settings
      </Typography>

      <ToggleButtonGroup
        value={selectedDevice}
        exclusive
        onChange={handleDeviceChange}
        size="small"
        sx={{ mb: 2, width: '100%' }}
      >
        <ToggleButton value="mobile" sx={{ flex: 1 }}>
          <MobileIcon />
        </ToggleButton>
        <ToggleButton value="tablet" sx={{ flex: 1 }}>
          <TabletIcon />
        </ToggleButton>
        <ToggleButton value="desktop" sx={{ flex: 1 }}>
          <DesktopIcon />
        </ToggleButton>
      </ToggleButtonGroup>

      <FormControl fullWidth size="small" sx={{ mb: 2 }}>
        <InputLabel>Visibility</InputLabel>
        <Select
          value={section.responsive?.[selectedDevice]?.visibility || 'visible'}
          onChange={(e) => handleSettingChange('visibility', e.target.value)}
          label="Visibility"
        >
          <MenuItem value="visible">Visible</MenuItem>
          <MenuItem value="hidden">Hidden</MenuItem>
        </Select>
      </FormControl>

      <FormControl fullWidth size="small">
        <InputLabel>Alignment</InputLabel>
        <Select
          value={section.responsive?.[selectedDevice]?.alignment || 'left'}
          onChange={(e) => handleSettingChange('alignment', e.target.value)}
          label="Alignment"
        >
          <MenuItem value="left">Left</MenuItem>
          <MenuItem value="center">Center</MenuItem>
          <MenuItem value="right">Right</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
};

export default ResponsiveControls; 