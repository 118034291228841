import React, { memo, useCallback, useState, useEffect } from 'react';
import { Autocomplete, TextField, CircularProgress, Box, Typography } from '@mui/material';
import debounce from 'lodash/debounce';

const AddressAutocomplete = memo(({ 
  value, 
  onChange,
  variant = "filled",
  label = "Address"
}) => {
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [inputValue, setInputValue] = useState(value || '');

  useEffect(() => {
    setInputValue(value || '');
  }, [value]);

  const fetchAddresses = useCallback(
    debounce(async (query) => {
      if (!query || query.length < 3) {
        setOptions([]);
        return;
      }

      setLoading(true);
      try {
        const response = await fetch(
          `https://nominatim.openstreetmap.org/search?format=json&q=${encodeURIComponent(query)}&countrycodes=us&limit=5&addressdetails=1`,
          {
            headers: {
              'Accept-Language': 'en-US',
              'User-Agent': 'EverProCleaning_EstimateSystem/1.0',
              'Referer': 'https://teams.everprocleaning.com'
            }
          }
        );
        
        if (!response.ok) throw new Error('Address search failed');
        const data = await response.json();
        
        const formattedAddresses = data.map(item => {
          const streetAddress = [item.address?.house_number, item.address?.road].filter(Boolean).join(' ');
          const cityStateZip = [
            item.address?.city || item.address?.town || '',
            item.address?.state || '',
            item.address?.postcode || ''
          ].filter(Boolean).join(', ');
          
          return {
            display: `${streetAddress}, ${cityStateZip}`,
            details: {
              street_number: item.address?.house_number || '',
              street_name: item.address?.road || '',
              city: item.address?.city || item.address?.town || '',
              state: item.address?.state || '',
              zip: item.address?.postcode || ''
            }
          };
        });

        console.log('Formatted addresses:', formattedAddresses);
        setOptions(formattedAddresses);
      } catch (error) {
        console.error('Address search error:', error);
        setOptions([]);
      } finally {
        setLoading(false);
      }
    }, 300),
    []
  );

  return (
    <Autocomplete
      freeSolo
      value={value}
      inputValue={inputValue}
      options={options}
      getOptionLabel={(option) => {
        if (typeof option === 'string') return option;
        return option.display || '';
      }}
      onInputChange={(_, newValue) => {
        setInputValue(newValue);
        fetchAddresses(newValue);
      }}
      onChange={(_, newValue) => {
        if (!newValue) {
          onChange('');
          return;
        }
        
        if (typeof newValue === 'string') {
          onChange(newValue);
        } else {
          const fullAddress = newValue.display;
          onChange(fullAddress, newValue.details);
        }
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant={variant}
          fullWidth
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading && <CircularProgress color="inherit" size={20} />}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      renderOption={(props, option) => (
        <Box component="li" {...props}>
          <Typography>{option.display}</Typography>
        </Box>
      )}
    />
  );
});

export default AddressAutocomplete;
