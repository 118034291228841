import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Typography, 
  CircularProgress, 
  Button,
  Alert,
  Paper,
  Divider,
  Link,
  Container,
  IconButton,
  Tooltip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import { useParams } from 'react-router-dom';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider, DateCalendar } from '@mui/x-date-pickers';
import api from '../services/api';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import { format, parse } from 'date-fns';
import PropertyMap from './PropertyMap';

const TIME_SLOTS = [
  '9:00 AM',
  '10:00 AM',
  '11:00 AM',
  '12:00 PM',
  '1:00 PM',
  '2:00 PM',
  '3:00 PM',
  '4:00 PM',
  '5:00 PM'
];

const EstimateBooking = () => {
  const { uuid } = useParams();
  const [estimate, setEstimate] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);
  const [bookingSuccess, setBookingSuccess] = useState(false);
  const [isBooking, setIsBooking] = useState(false);

  useEffect(() => {
    const fetchEstimate = async () => {
      try {
        if (!uuid) {
          setError('Invalid booking link');
          setLoading(false);
          return;
        }

        const response = await api.get(`/estimates/booking/${uuid}`);
        console.log('Full estimate data:', response.data);
        console.log('Logo path:', response.data.data?.logo);
        
        if (response.data.success && response.data.data) {
          setEstimate(response.data.data);
          console.log('Estimate state after set:', response.data.data.logo);
        } else {
          throw new Error(response.data.message || 'Failed to load estimate');
        }
      } catch (err) {
        console.error('Error fetching estimate:', err);
        setError('Failed to load estimate details');
      } finally {
        setLoading(false);
      }
    };

    fetchEstimate();
  }, [uuid]);

  const handleBooking = async () => {
    try {
      setIsBooking(true);
      setError(null);
      
      const totalValue = estimate?.items?.reduce((sum, item) => 
        sum + (parseFloat(item.quantity) * parseFloat(item.unit_price)), 0
      ) || 0;

      console.log('Line items:', estimate?.items);
      console.log('Calculated total:', totalValue);
      
      const dateStr = format(selectedDate, 'yyyy-MM-dd');
      const parsedTime = parse(selectedTime, 'h:mm a', new Date());
      const timeStr = format(parsedTime, 'HH:mm:ss');
      
      const startTimeStr = `${dateStr}T${timeStr}.000Z`;
      const startTime = new Date(startTimeStr);
      const endTime = new Date(startTime.getTime() + (2 * 60 * 60 * 1000));

      const requestData = {
        start_time: startTime.toISOString(),
        end_time: endTime.toISOString(),
        estimate_id: uuid,
        quantity: 1,
        total_value: totalValue
      };

      console.log('Request payload:', JSON.stringify(requestData, null, 2));

      const response = await api.post(`/estimates/booking/${uuid}/confirm`, requestData);

      if (response.data.success) {
        setBookingSuccess(true);
      } else {
        throw new Error(response.data.message || 'Booking failed');
      }
    } catch (err) {
      console.error('Booking error details:', {
        message: err.message,
        response: err.response?.data,
        status: err.response?.status
      });
      setError(err.response?.data?.message || err.message || 'Failed to book appointment');
    } finally {
      setIsBooking(false);
    }
  };

  const handleCall = () => {
    if (estimate?.business_details?.phone) {
      window.location.href = `tel:${estimate.business_details.phone}`;
    }
  };

  const handleEmail = () => {
    if (estimate?.business_details?.email) {
      window.location.href = `mailto:${estimate.business_details.email}`;
    }
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', p: 4 }}>
        <CircularProgress />
      </Box>
    );
  }
  
  if (error) {
    return <Alert severity="error" sx={{ m: 2 }}>{error}</Alert>;
  }

  return (
    <Container maxWidth="lg">
      <Box sx={{ 
        minHeight: '100vh', 
        display: 'flex', 
        flexDirection: 'column',
        py: 4,
        position: 'relative'
      }}>
        <Paper elevation={3} sx={{ p: 4, mb: 4 }}>
          <Typography variant="h4" gutterBottom align="center">
            Schedule Your Project
          </Typography>
          
          <Divider sx={{ my: 3 }} />
          
          {/* Business Details with Contact Buttons */}
          <Box sx={{ mb: 4, textAlign: 'center' }}>
            <Typography variant="h6" gutterBottom>
              {estimate?.business_details?.company_name}
            </Typography>
            <Typography color="text.secondary">
              {estimate?.business_details?.address}
            </Typography>
            <Typography color="text.secondary" gutterBottom>
              {estimate?.business_details?.city}, {estimate?.business_details?.state} {estimate?.business_details?.zip}
            </Typography>
            
            {/* Contact Buttons */}
            <Box sx={{ 
              display: 'flex', 
              justifyContent: 'center',
              gap: 2,
              mt: 2
            }}>
              <Tooltip title={estimate?.business_details?.phone || 'Call Us'}>
                <Button
                  onClick={handleCall}
                  variant="outlined"
                  color="primary"
                  startIcon={<PhoneIcon />}
                  sx={{ 
                    borderRadius: 2,
                    px: 3
                  }}
                >
                  Call
                </Button>
              </Tooltip>
              <Tooltip title={estimate?.business_details?.email || 'Email Us'}>
                <Button
                  onClick={handleEmail}
                  variant="outlined"
                  color="primary"
                  startIcon={<EmailIcon />}
                  sx={{ 
                    borderRadius: 2,
                    px: 3
                  }}
                >
                  Email
                </Button>
              </Tooltip>
            </Box>
          </Box>

          {/* Project Details with Map */}
          <Box sx={{ 
            mb: 4, 
            display: 'flex', 
            gap: 4,
            flexDirection: { xs: 'column', md: 'row' }  // Stack on mobile, side-by-side on desktop
          }}>
            {/* Left side - Details */}
            <Box sx={{ flex: 1 }}>
              <Typography variant="h5" gutterBottom>
                {estimate?.project_name || 'Project Details'}
              </Typography>
              <Typography color="text.secondary" gutterBottom>
                Estimate #{estimate?.estimate_number}
              </Typography>
              
              {/* Service Address */}
              <Box sx={{ mt: 2, mb: 3 }}>
                <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
                  Service Location
                </Typography>
                <Typography color="text.secondary">
                  {estimate?.service_address}
                </Typography>
                <Typography color="text.secondary">
                  {estimate?.service_city}, {estimate?.service_state} {estimate?.service_zip}
                </Typography>
              </Box>

              {estimate?.scope_of_work && (
                <Typography variant="body2" sx={{ mt: 2 }}>
                  {estimate.scope_of_work}
                </Typography>
              )}
            </Box>

            {/* Right side - Map */}
            <Box sx={{ 
              flex: 1,
              minHeight: 300,  // Match the map height
              '& > div': { height: '100%' }  // Make the map fill the container
            }}>
              <PropertyMap 
                address={`${estimate?.service_address}, ${estimate?.service_city}, ${estimate?.service_state} ${estimate?.service_zip}`}
              />
            </Box>
          </Box>

          {/* After Project Details, add Line Items */}
          <Box sx={{ mb: 4 }}>
            <Typography variant="h5" gutterBottom>
              Line Items
            </Typography>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Item</TableCell>
                    <TableCell align="right">Quantity</TableCell>
                    <TableCell align="right">Price</TableCell>
                    <TableCell align="right">Total</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {estimate?.items?.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        <Typography variant="body1" sx={{ fontWeight: 500 }}>
                          {item.title}
                        </Typography>
                        {item.description && (
                          <Typography variant="body2" color="text.secondary" sx={{ mt: 0.5 }}>
                            {item.description}
                          </Typography>
                        )}
                      </TableCell>
                      <TableCell align="right">
                        {item.quantity}
                      </TableCell>
                      <TableCell align="right">
                        ${parseFloat(item.unit_price).toLocaleString('en-US', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2
                        })}
                      </TableCell>
                      <TableCell align="right">
                        ${(parseFloat(item.quantity) * parseFloat(item.unit_price)).toLocaleString('en-US', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2
                        })}
                      </TableCell>
                    </TableRow>
                  ))}
                  <TableRow>
                    <TableCell colSpan={3} align="right" sx={{ 
                      fontWeight: 'bold',
                      fontSize: '1.1rem'
                    }}>
                      Total
                    </TableCell>
                    <TableCell align="right" sx={{ 
                      fontWeight: 'bold',
                      fontSize: '1.1rem'
                    }}>
                      ${estimate?.items?.reduce((sum, item) => 
                        sum + (parseFloat(item.quantity) * parseFloat(item.unit_price)), 0
                      ).toLocaleString('en-US', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                      })}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>

          <Divider sx={{ my: 3 }} />

          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Box sx={{ 
              display: 'flex', 
              flexDirection: { xs: 'column', md: 'row' },
              gap: 4,
              mb: 4,
              width: '100%',
            }}>
              {/* Calendar - 50% width */}
              <Box sx={{ 
                width: '50%',
                '& .MuiDateCalendar-root': {
                  width: '100%',
                  maxWidth: 'none',
                  backgroundColor: 'white',
                  border: '1px solid',
                  borderColor: 'grey.300',
                  borderRadius: 1,
                  '& .MuiPickersCalendarHeader-root': {
                    paddingLeft: 2,
                    paddingRight: 2,
                    marginTop: 1,
                    marginBottom: 2,
                    '& .MuiPickersCalendarHeader-label': {
                      fontSize: '1.2rem'
                    }
                  },
                  '& .MuiDayCalendar-weekDayLabel': {
                    fontSize: '0.9rem',
                    color: 'primary.main',
                    width: 36,
                    height: 36
                  },
                  '& .MuiPickersDay-root': {
                    fontSize: '1rem',
                    width: 36,
                    height: 36,
                    '&.Mui-selected': {
                      backgroundColor: 'primary.main',
                      color: 'white'
                    }
                  }
                }
              }}>
                <DateCalendar 
                  value={selectedDate}
                  onChange={setSelectedDate}
                  minDate={new Date()}
                  views={['day']}
                />
              </Box>

              {/* Time Selection - 50% width */}
              <Box sx={{ 
                width: '50%',
                display: 'flex',
                flexDirection: 'column',
                gap: 1
              }}>
                {TIME_SLOTS.map((time) => (
                  <Button
                    key={time}
                    variant={selectedTime === time ? "contained" : "outlined"}
                    onClick={() => setSelectedTime(time)}
                    fullWidth
                    sx={{
                      py: 1.5,
                      borderRadius: 1,
                      justifyContent: 'center',
                      border: '1px solid',
                      borderColor: 'grey.300',
                      backgroundColor: selectedTime === time ? 'primary.main' : 'transparent',
                      color: selectedTime === time ? 'white' : 'inherit',
                      '&:hover': {
                        backgroundColor: selectedTime === time ? 'primary.dark' : 'grey.50'
                      }
                    }}
                  >
                    {time}
                  </Button>
                ))}
              </Box>
            </Box>
          </LocalizationProvider>

          {bookingSuccess ? (
            <Alert severity="success" sx={{ mt: 2 }}>
              Your project has been scheduled successfully! We'll see you on{' '}
              {selectedDate ? format(selectedDate, 'MMMM do, yyyy') : ''} at{' '}
              {selectedTime || ''}
            </Alert>
          ) : (
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                variant="contained"
                onClick={handleBooking}
                disabled={!selectedDate || !selectedTime || isBooking}
                startIcon={isBooking ? <CircularProgress size={20} color="inherit" /> : <EventAvailableIcon />}
                sx={{ 
                  mt: 2,
                  py: 1.5,
                  px: 4,
                  fontSize: '1rem',
                  backgroundColor: '#4CAF50',
                  width: 'auto',
                  minWidth: '200px',
                  '&:hover': {
                    backgroundColor: '#43A047'
                  },
                  '&:disabled': {
                    backgroundColor: '#81C784'
                  }
                }}
              >
                Schedule
              </Button>
            </Box>
          )}
        </Paper>

        {/* Powered by ServTrackr - Updated Footer */}
        <Box sx={{ 
          position: 'fixed',
          bottom: 0,
          left: 0,
          right: 0,
          bgcolor: '#f4f4f4',
          color: 'grey.800',
          py: 2,
          textAlign: 'center',
          zIndex: 1000,
          borderTop: 1,
          borderColor: 'grey.300'
        }}>
          <Typography variant="body2">
            Powered by{' '}
            <Link 
              href="https://servtrackr.com" 
              target="_blank"
              rel="noopener"
              sx={{ 
                color: 'grey.800',
                textDecoration: 'none',
                '&:hover': {
                  textDecoration: 'underline'
                }
              }}
            >
              ServTrackr
            </Link>
          </Typography>
        </Box>
      </Box>
    </Container>
  );
};

export default EstimateBooking;