export const commonStyles = {
  textField: {
    '& .MuiInputLabel-root': {
      color: 'var(--text-secondary)'
    },
    '& .MuiInputBase-input': {
      color: 'var(--text-primary)',
      backgroundColor: 'var(--background-secondary)',
      borderRadius: '4px'
    },
    '& .MuiOutlinedInput-root': {
      backgroundColor: 'var(--background-secondary)',
      '& fieldset': {
        borderColor: 'var(--divider)'
      },
      '&:hover fieldset': {
        borderColor: 'var(--primary-main)'
      }
    }
  },
  select: {
    color: 'var(--text-primary)',
    backgroundColor: 'var(--background-secondary)',
    '& .MuiSelect-icon': {
      color: 'var(--text-primary)'
    }
  },
  card: {
    backgroundColor: 'var(--background-paper)',
    border: '1px solid var(--divider)',
    borderRadius: '8px',
    '&:hover': {
      borderColor: 'var(--primary-main)',
      boxShadow: '0 4px 12px rgba(0,0,0,0.1)'
    },
    transition: 'all 0.2s ease-in-out'
  }
};

export const calculationTypeFields = {
  fixed: [],
  area: [
    { name: 'length', label: 'Length (ft)', type: 'number' },
    { name: 'width', label: 'Width (ft)', type: 'number' }
  ],
  hourly: [
    { name: 'hours', label: 'Hours', type: 'number' }
  ],
  unit: [
    { name: 'units', label: 'Units', type: 'number' }
  ]
};

export const serviceTypes = [
  { value: 'cleaning', label: 'Cleaning Services' },
  { value: 'plumbing', label: 'Plumbing' },
  { value: 'electrical', label: 'Electrical' },
  { value: 'hvac', label: 'HVAC' },
  { value: 'landscaping', label: 'Landscaping' }
];

export const calculationTypes = [
  { value: 'fixed', label: 'Fixed Rate' },
  { value: 'area', label: 'Square Footage' },
  { value: 'hourly', label: 'Hourly Rate' },
  { value: 'unit', label: 'Per Unit' }
];

export const calculateArea = (fieldValues) => {
  if (!fieldValues) return 0;
  const length = parseFloat(fieldValues.length) || 0;
  const width = parseFloat(fieldValues.width) || 0;
  return length * width;
};

export const calculateTotalArea = (rule) => {
  if (!rule || !rule.fieldValues) return 0;
  
  const quantity = parseFloat(rule.fieldValues.quantity) || 0;
  
  if (rule.manualArea) {
    const squareFootagePerUnit = parseFloat(rule.fieldValues.squareFootagePerUnit) || 0;
    return squareFootagePerUnit * quantity;
  } else {
    return calculateArea(rule.fieldValues) * quantity;
  }
};

export const calculatePrice = (rule, fieldValues) => {
  if (!rule || !rule.calculationType) return 0;

  const baseRate = parseFloat(rule.baseRate) || 0;

  switch (rule.calculationType) {
    case 'area':
      const totalArea = calculateTotalArea({ ...rule, fieldValues });
      return totalArea * baseRate;

    case 'fixed':
      return baseRate;

    case 'hourly':
      const hours = parseFloat(fieldValues.hours) || 0;
      return hours * baseRate;

    case 'unit':
      const units = parseFloat(fieldValues.units) || 0;
      return units * baseRate;

    default:
      return 0;
  }
};