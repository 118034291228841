import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Paper, 
  Typography, 
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Chip,
  CircularProgress
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import { getRecurringJobs } from '../../../services/api';
import SearchIcon from '@mui/icons-material/Search';
import styles from '../../../components/Employees.module.scss';

const RecurringJobsView = ({ onBack }) => {
  const [jobs, setJobs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchJobs = async () => {
      try {
        setLoading(true);
        const response = await getRecurringJobs();
        console.log('Raw recurring jobs data:', response);
        
        if (!Array.isArray(response)) {
          throw new Error('Invalid response format');
        }
        
        // Format the jobs data
        const formattedJobs = response.map(event => {
          try {
            const timeSlot = event.time_slots?.[0];
            const recurringSettings = event.recurring_settings ? 
              JSON.parse(event.recurring_settings) : {};
            
            return {
              id: event.id,
              client: event.project?.client?.name || 'N/A',
              service: event.project?.name || 'N/A',
              frequency: recurringSettings.frequency || 'N/A',
              nextDate: timeSlot?.start_time ? 
                new Date(timeSlot.start_time).toLocaleDateString() : 'Unscheduled',
              revenue: event.project?.total_value || 0,
              project: {
                id: event.project?.id
              }
            };
          } catch (err) {
            console.error('Error formatting job:', err);
            return null;
          }
        }).filter(Boolean); // Remove any null entries

        console.log('Formatted recurring jobs:', formattedJobs);
        setJobs(formattedJobs);
      } catch (err) {
        console.error('Error fetching recurring jobs:', err);
        setError('Failed to load recurring jobs');
      } finally {
        setLoading(false);
      }
    };

    fetchJobs();
  }, []);

  const handleRowClick = (projectId) => {
    navigate(`/admin/projects/${projectId}`);
  };

  const formatCurrency = (value) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD'
    }).format(value);
  };

  // Filter jobs based on search term
  const filteredJobs = jobs.filter(job => 
    job.client.toLowerCase().includes(searchTerm.toLowerCase()) ||
    job.service.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <Box>
      <Box sx={{ mb: 4, display: 'flex', alignItems: 'center' }}>
        <IconButton onClick={onBack} sx={{ mr: 2 }}>
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h5">Recurring Jobs</Typography>
      </Box>

      {/* Search Bar */}
      <div className={styles.searchContainer} style={{ marginBottom: '24px' }}>
        <div className={styles.searchIcon}>
          <SearchIcon />
        </div>
        <input
          type="text"
          placeholder="Search jobs..."
          className={styles.searchInput}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>

      <Paper elevation={0} sx={{ p: 3, border: '1px solid #e0e0e0' }}>
        <TableContainer>
          {loading ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
              <CircularProgress />
            </Box>
          ) : error ? (
            <Typography color="error" align="center">{error}</Typography>
          ) : (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Client</TableCell>
                  <TableCell>Service</TableCell>
                  <TableCell>Frequency</TableCell>
                  <TableCell>Next Date</TableCell>
                  <TableCell align="right">Revenue</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredJobs.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={5} align="center">
                      <Typography color="textSecondary">
                        {loading ? 'Loading...' : 'No recurring jobs found'}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ) : (
                  filteredJobs.map((job) => (
                    <TableRow 
                      key={job.id}
                      onClick={() => handleRowClick(job.project.id)}
                      sx={{ 
                        '&:hover': { 
                          backgroundColor: 'rgba(0, 0, 0, 0.04)',
                          cursor: 'pointer' 
                        } 
                      }}
                    >
                      <TableCell>{job.client}</TableCell>
                      <TableCell>{job.service}</TableCell>
                      <TableCell>
                        {typeof job.frequency === 'object' 
                          ? `${job.frequency.interval} ${job.frequency.unit}` 
                          : job.frequency}
                      </TableCell>
                      <TableCell>{job.nextDate}</TableCell>
                      <TableCell align="right">{formatCurrency(job.revenue)}</TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          )}
        </TableContainer>
      </Paper>
    </Box>
  );
};

export default RecurringJobsView;