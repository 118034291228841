import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

const ConversionChart = ({ estimates, height = 300 }) => {
  const prepareData = () => {
    // Group estimates by month
    const monthlyData = estimates.reduce((acc, est) => {
      const date = new Date(est.issue_date);
      const monthKey = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}`;
      
      if (!acc[monthKey]) {
        acc[monthKey] = { total: 0, converted: 0 };
      }
      
      acc[monthKey].total += 1;
      if (est.client_signature) {
        acc[monthKey].converted += 1;
      }
      
      return acc;
    }, {});

    // Convert to array and calculate rates
    return Object.entries(monthlyData)
      .map(([month, data]) => ({
        month,
        conversionRate: (data.converted / data.total * 100).toFixed(1)
      }))
      .sort((a, b) => a.month.localeCompare(b.month));
  };

  const data = prepareData();

  return (
    <ResponsiveContainer width="100%" height={height}>
      <LineChart data={data} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
        <defs>
          <linearGradient id="colorConversion" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#2196f3" stopOpacity={0.8}/>
            <stop offset="95%" stopColor="#2196f3" stopOpacity={0.1}/>
          </linearGradient>
        </defs>
        <CartesianGrid strokeDasharray="3 3" stroke="#eee" />
        <XAxis dataKey="month" stroke="#666" />
        <YAxis unit="%" stroke="#666" />
        <Tooltip 
          formatter={(value) => [`${value}%`, 'Conversion Rate']}
          contentStyle={{
            background: 'rgba(255, 255, 255, 0.9)',
            border: 'none',
            borderRadius: 8,
            boxShadow: '0 4px 12px rgba(0,0,0,0.1)'
          }}
        />
        <Line 
          type="monotone" 
          dataKey="conversionRate" 
          stroke="#2196f3" 
          strokeWidth={3}
          dot={{ fill: '#2196f3', strokeWidth: 2 }}
          activeDot={{ r: 8 }}
          fillOpacity={1}
          fill="url(#colorConversion)"
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default ConversionChart; 