import React from 'react';
import {
  Box,
  Card,
  Grid,
  Typography,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';

const PerformanceMetric = ({ title, value, target, progress }) => (
  <Card sx={{ p: 3, height: '100%', borderRadius: 2 }}>
    <Typography color="text.secondary" variant="overline">
      {title}
    </Typography>
    <Typography variant="h4" sx={{ my: 1, fontWeight: 600 }}>
      {value}
    </Typography>
    <Box sx={{ mt: 2 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
        <Typography variant="caption" color="text.secondary">
          Progress to Target
        </Typography>
        <Typography variant="caption" color="text.secondary">
          {progress}% of {target}
        </Typography>
      </Box>
      <LinearProgress
        variant="determinate"
        value={progress}
        sx={{
          height: 6,
          borderRadius: 3,
          backgroundColor: 'grey.100',
          '& .MuiLinearProgress-bar': {
            borderRadius: 3,
            backgroundImage: 'linear-gradient(45deg, #2196f3 30%, #21cbf3 90%)',
          }
        }}
      />
    </Box>
  </Card>
);

const CampaignPerformance = () => {
  const performanceData = [
    { date: '2024-03-01', leads: 45, conversions: 12, revenue: 2400 },
    { date: '2024-03-02', leads: 52, conversions: 15, revenue: 3100 },
    { date: '2024-03-03', leads: 48, conversions: 14, revenue: 2800 },
    { date: '2024-03-04', leads: 70, conversions: 21, revenue: 4200 },
    { date: '2024-03-05', leads: 61, conversions: 19, revenue: 3800 },
  ];

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h5" sx={{ mb: 4, fontWeight: 600 }}>
        Campaign Performance
      </Typography>

      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <PerformanceMetric
            title="TOTAL LEADS"
            value="2,456"
            target="3,000"
            progress={82}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <PerformanceMetric
            title="CONVERSION RATE"
            value="24.8%"
            target="30%"
            progress={83}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <PerformanceMetric
            title="REVENUE GENERATED"
            value="$45,789"
            target="$60,000"
            progress={76}
          />
        </Grid>

        <Grid item xs={12}>
          <Card sx={{ p: 3, borderRadius: 2 }}>
            <Typography variant="h6" sx={{ mb: 3 }}>
              Performance Trends
            </Typography>
            <Box sx={{ height: 400 }}>
              <ResponsiveContainer width="100%" height="100%">
                <LineChart data={performanceData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="date" />
                  <YAxis yAxisId="left" />
                  <YAxis yAxisId="right" orientation="right" />
                  <Tooltip />
                  <Legend />
                  <Line
                    yAxisId="left"
                    type="monotone"
                    dataKey="leads"
                    stroke="#2196f3"
                    activeDot={{ r: 8 }}
                  />
                  <Line
                    yAxisId="left"
                    type="monotone"
                    dataKey="conversions"
                    stroke="#4caf50"
                    activeDot={{ r: 8 }}
                  />
                  <Line
                    yAxisId="right"
                    type="monotone"
                    dataKey="revenue"
                    stroke="#ff9800"
                    activeDot={{ r: 8 }}
                  />
                </LineChart>
              </ResponsiveContainer>
            </Box>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CampaignPerformance; 