import React from 'react';
import { ResponsiveContainer, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts';

const EstimateValueChart = ({ estimates, height = 300 }) => {
  const prepareData = () => {
    // Group estimates by month and calculate total values
    const monthlyData = estimates.reduce((acc, est) => {
      const date = new Date(est.issue_date);
      const monthKey = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}`;
      
      if (!acc[monthKey]) {
        acc[monthKey] = { totalValue: 0 };
      }
      
      const items = Array.isArray(est.items) ? est.items : [];
      const estimateValue = items.reduce((sum, item) => {
        return sum + (Number(item.quantity) || 0) * (Number(item.unit_price) || 0);
      }, 0);
      
      acc[monthKey].totalValue += estimateValue;
      return acc;
    }, {});

    return Object.entries(monthlyData)
      .map(([month, data]) => ({
        month,
        value: Number(data.totalValue.toFixed(2))
      }))
      .sort((a, b) => a.month.localeCompare(b.month));
  };

  const data = prepareData();

  return (
    <ResponsiveContainer width="100%" height={height}>
      <BarChart data={data} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
        <defs>
          <linearGradient id="colorValue" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#4caf50" stopOpacity={0.8}/>
            <stop offset="95%" stopColor="#4caf50" stopOpacity={0.1}/>
          </linearGradient>
        </defs>
        <CartesianGrid strokeDasharray="3 3" stroke="#eee" />
        <XAxis dataKey="month" stroke="#666" />
        <YAxis 
          tickFormatter={(value) => `$${value}`}
          stroke="#666"
        />
        <Tooltip 
          formatter={(value) => [`$${value}`, 'Total Value']}
          contentStyle={{
            background: 'rgba(255, 255, 255, 0.9)',
            border: 'none',
            borderRadius: 8,
            boxShadow: '0 4px 12px rgba(0,0,0,0.1)'
          }}
        />
        <Bar dataKey="value" fill="url(#colorValue)" />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default EstimateValueChart; 