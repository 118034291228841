import React, { useState } from 'react';
import { Grid, Typography, Paper, Box, Button, Chip, TextField, Alert, Link, IconButton } from '@mui/material';
import GavelIcon from '@mui/icons-material/Gavel';
import WorkIcon from '@mui/icons-material/Work';
import SecurityIcon from '@mui/icons-material/Security';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CloseIcon from '@mui/icons-material/Close';
import styles from './AdminSettings.module.scss';
import { useTheme } from '@mui/material/styles';

function AdminSettingsCompliance() {
  const { darkMode } = useTheme();
  const [showBanner, setShowBanner] = useState(true);

  const [workersComp, setWorkersComp] = useState({
    status: 'Active',
    expirationDate: '2023-12-31',
    coverageAmount: 1000000,
  });

  const [generalLiability, setGeneralLiability] = useState({
    status: 'Pending',
    expirationDate: '2023-12-31',
    coverageAmount: 2000000,
  });

  const inputStyles = {
    '& .MuiInputLabel-root': {
      color: darkMode ? 'var(--text-secondary)' : 'inherit'
    },
    '& .MuiOutlinedInput-root': {
      backgroundColor: darkMode ? 'var(--input-background)' : 'inherit',
      color: darkMode ? 'var(--text-primary)' : 'inherit',
      '& fieldset': {
        borderColor: darkMode ? 'var(--border-color)' : 'inherit'
      },
      '&:hover fieldset': {
        borderColor: darkMode ? 'var(--text-secondary)' : 'inherit'
      },
      '&.Mui-focused fieldset': {
        borderColor: darkMode ? '#90caf9' : '#1976d2'
      }
    },
    '& .MuiInputBase-input': {
      color: darkMode ? 'var(--text-primary)' : 'inherit'
    }
  };

  const handleUploadDocument = (type) => {
    console.log(`Uploading ${type} document`);
    // Implement document upload logic here
  };

  const handleViewDetails = (type) => {
    console.log(`Viewing ${type} details`);
    // Implement view details logic here
  };

  const handleInputChange = (type, field, value) => {
    if (type === 'Workers Comp') {
      setWorkersComp({ ...workersComp, [field]: value });
    } else {
      setGeneralLiability({ ...generalLiability, [field]: value });
    }
  };

  const handleApplyNow = (type) => {
    console.log(`Applying for ${type} insurance`);
    // Implement apply now logic here
  };

  const InsuranceDetails = ({ type, data, onInputChange }) => (
    <Box className={styles.insuranceDetails}>
      <Box display="flex" alignItems="center" mb={2}>
        <Typography 
          variant="subtitle1" 
          mr={2} 
          sx={{ 
            color: darkMode ? 'var(--text-primary)' : 'inherit',
            fontWeight: 500 
          }}
        >
          Status:
        </Typography>
        <Chip 
          label={data.status} 
          color={data.status === 'Active' ? 'success' : 'warning'} 
          size="small"
          sx={{
            backgroundColor: data.status === 'Active' 
              ? darkMode ? 'var(--success-dark)' : undefined
              : darkMode ? 'var(--warning-dark)' : undefined,
            color: darkMode ? 'var(--text-primary)' : undefined,
          }}
        />
      </Box>
      <Box display="flex" alignItems="center" mb={2}>
        <CalendarTodayIcon 
          className={styles.inlineIcon} 
          sx={{ 
            color: darkMode ? '#90caf9' : '#1976d2',
            marginRight: '8px'
          }} 
        />
        <TextField
          label="Expiration Date"
          type="date"
          value={data.expirationDate}
          onChange={(e) => onInputChange(type, 'expirationDate', e.target.value)}
          InputLabelProps={{ shrink: true }}
          size="small"
          sx={inputStyles}
        />
      </Box>
      <Box display="flex" alignItems="center" mb={2}>
        <AttachMoneyIcon 
          className={styles.inlineIcon} 
          sx={{ 
            color: darkMode ? '#90caf9' : '#1976d2',
            marginRight: '8px'
          }} 
        />
        <TextField
          label="Coverage Amount"
          type="number"
          value={data.coverageAmount}
          onChange={(e) => onInputChange(type, 'coverageAmount', e.target.value)}
          InputProps={{ 
            startAdornment: '$',
            sx: {
              '& .MuiTypography-root': {
                color: darkMode ? 'var(--text-primary)' : 'inherit'
              }
            }
          }}
          size="small"
          sx={inputStyles}
        />
      </Box>
      <Box display="flex" gap={1} mt={2}>
        <Button
          variant="outlined"
          startIcon={<VisibilityIcon />}
          onClick={() => handleViewDetails(type)}
          sx={{
            borderColor: darkMode ? 'var(--border-color)' : undefined,
            color: darkMode ? 'var(--text-primary)' : undefined,
            '&:hover': {
              borderColor: darkMode ? 'var(--text-secondary)' : undefined,
              backgroundColor: darkMode ? 'rgba(144, 202, 249, 0.08)' : undefined
            }
          }}
        >
          View Details
        </Button>
        <Button
          variant="contained"
          startIcon={<AddCircleOutlineIcon />}
          onClick={() => handleUploadDocument(type)}
          sx={{
            backgroundColor: darkMode ? '#90caf9' : '#1976d2',
            color: darkMode ? 'var(--background-paper)' : '#fff',
            '&:hover': {
              backgroundColor: darkMode ? '#64b5f6' : '#1565c0'
            }
          }}
        >
          Upload Document
        </Button>
      </Box>
    </Box>
  );

  const handleCloseBanner = () => {
    setShowBanner(false);
  };

  return (
    <Paper 
      className={`${styles.tabPanel} ${darkMode ? styles.darkMode : ''}`}
      sx={{
        backgroundColor: darkMode ? 'var(--background-paper)' : '#fff',
      }}
    >
      {showBanner && (
        <Alert 
          icon={<span role="img" aria-label="celebration">🎉</span>}
          severity="info" 
          className={styles.banner}
          sx={{
            backgroundColor: darkMode ? 'var(--alert-background)' : undefined,
            color: darkMode ? 'var(--text-primary)' : undefined,
            '& .MuiAlert-icon': {
              color: darkMode ? 'var(--text-primary)' : undefined,
            }
          }}
          action={
            <Box display="flex" alignItems="center">
              <Link 
                href="#" 
                underline="always" 
                sx={{ color: darkMode ? 'var(--text-primary)' : 'inherit' }}
              >
                See More
              </Link>
              <IconButton
                aria-label="close"
                size="small"
                onClick={handleCloseBanner}
                sx={{ color: darkMode ? 'var(--text-primary)' : 'inherit' }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            </Box>
          }
        >
          See how ServTracker can help you with your business compliance today!
        </Alert>
      )}
      <Box display="flex" alignItems="center" mb={3} mt={3}>
        <GavelIcon 
          className={styles.headerIcon}
          sx={{ 
            color: darkMode ? '#90caf9' : '#1976d2',
            fontSize: '2rem',
            marginRight: '12px'
          }}
        />
        <Typography 
          variant="h5" 
          component="h2" 
          sx={{ 
            color: darkMode ? 'var(--text-primary)' : 'inherit',
            fontWeight: 600,
            fontSize: '1.5rem',
          }}
        >
          Compliance
        </Typography>
      </Box>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Box className={styles.complianceSection}>
            <Typography 
              variant="h6" 
              gutterBottom 
              className={styles.complianceHeader}
              sx={{
                color: darkMode ? 'var(--text-secondary)' : 'inherit',
                display: 'flex',
                alignItems: 'center',
                gap: '8px',
              }}
            >
              <WorkIcon sx={{ color: darkMode ? 'var(--text-primary)' : '#1976d2' }} />
              Workers' Compensation Insurance
            </Typography>
            <Typography 
              variant="body2" 
              paragraph
              sx={{ color: darkMode ? 'var(--text-secondary)' : 'inherit' }}
            >
              Workers' Compensation Insurance provides coverage for work-related injuries and illnesses. It's required by law in most states for businesses with employees.
            </Typography>
            <InsuranceDetails 
              type="Workers Comp"
              data={workersComp}
              onInputChange={handleInputChange}
            />
            <Box className={styles.buttonGroup}>
              <Button
                variant="contained"
                color="primary"
                startIcon={<AddCircleOutlineIcon />}
                onClick={() => handleApplyNow('Workers Comp')}
                className={styles.applyButton}
              >
                Apply Now
              </Button>
              <Button
                variant="outlined"
                color="primary"
                startIcon={<VisibilityIcon />}
                onClick={() => handleViewDetails('Workers Comp')}
                className={styles.complianceButton}
              >
                View Details
              </Button>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box className={styles.complianceSection}>
            <Typography 
              variant="h6" 
              gutterBottom 
              className={styles.complianceHeader}
              sx={{
                color: darkMode ? 'var(--text-primary)' : 'inherit',
                display: 'flex',
                alignItems: 'center',
                gap: '8px',
              }}
            >
              <SecurityIcon sx={{ color: darkMode ? '#90caf9' : '#1976d2' }} />
              General Liability Insurance
            </Typography>
            <Typography 
              variant="body2" 
              paragraph
              sx={{ color: darkMode ? 'var(--text-secondary)' : 'inherit' }}
            >
              General Liability Insurance protects your business from financial loss should you be liable for property damage or personal and advertising injury caused by your services, business operations or employees.
            </Typography>
            <InsuranceDetails 
              type="General Liability"
              data={generalLiability}
              onInputChange={handleInputChange}
            />
            <Box className={styles.buttonGroup}>
              <Button
                variant="contained"
                color="primary"
                startIcon={<AddCircleOutlineIcon />}
                onClick={() => handleApplyNow('General Liability')}
                className={styles.applyButton}
              >
                Apply Now
              </Button>
              <Button
                variant="outlined"
                color="primary"
                startIcon={<VisibilityIcon />}
                onClick={() => handleViewDetails('General Liability')}
                className={styles.complianceButton}
              >
                View Details
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
}

export default AdminSettingsCompliance;
