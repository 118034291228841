import React from 'react';
import {
  Box,
  Card,
  Typography,
  Chip,
  IconButton,
} from '@mui/material';
import {
  MoreVert as MoreIcon,
  Email as EmailIcon,
  Phone as PhoneIcon,
  Business as BusinessIcon,
} from '@mui/icons-material';

const ContactCard = ({ contact, onMenuClick }) => (
  <Card sx={{
    height: '100%',
    borderRadius: 2,
    transition: 'transform 0.2s ease-in-out',
    '&:hover': {
      transform: 'translateY(-4px)',
      boxShadow: (theme) => theme.shadows[4],
    },
  }}>
    <Box sx={{ p: 3 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', mb: 2 }}>
        <Box>
          <Typography variant="h6" sx={{ mb: 1 }}>
            {contact.name}
          </Typography>
          <Box sx={{ display: 'flex', gap: 1 }}>
            <Chip
              size="small"
              label={contact.status}
              color={contact.status === 'Active' ? 'success' : 'default'}
            />
            {contact.tags.map((tag) => (
              <Chip key={tag} size="small" label={tag} />
            ))}
          </Box>
        </Box>
        <IconButton size="small" onClick={(e) => onMenuClick(e, contact)}>
          <MoreIcon />
        </IconButton>
      </Box>

      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, mt: 3 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <EmailIcon fontSize="small" color="action" />
          <Typography variant="body2">{contact.email}</Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <PhoneIcon fontSize="small" color="action" />
          <Typography variant="body2">{contact.phone}</Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <BusinessIcon fontSize="small" color="action" />
          <Typography variant="body2">{contact.company}</Typography>
        </Box>
      </Box>

      <Box sx={{ mt: 2, pt: 2, borderTop: '1px solid', borderColor: 'divider' }}>
        <Typography variant="caption" color="text.secondary">
          Last Contact
        </Typography>
        <Typography variant="body2">
          {contact.lastContact}
        </Typography>
      </Box>
    </Box>
  </Card>
);

export default ContactCard; 