import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Typography, Paper, CircularProgress, Button, Avatar, Tooltip, Tabs, Tab, IconButton } from '@mui/material';
import EventIcon from '@mui/icons-material/Event';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PeopleIcon from '@mui/icons-material/People';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import { getEmployeeEventDetails, getProjectPhotos, uploadProjectPhoto, getEmployeeTimesheets, clockInToEvent, clockOutFromEvent } from '../services/api';
import styles from './EmployeeEventDetails.module.scss';
import moment from 'moment';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import EventNoteIcon from '@mui/icons-material/EventNote';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ProjectPhotos from './ProjectPhotos';
import { CSSTransition } from 'react-transition-group';
import { formatDateTime } from '../utils/dateUtils';
import NotesIcon from '@mui/icons-material/Notes';

const avatarColors = ['#1976d2', '#388e3c', '#d32f2f', '#7b1fa2', '#c2185b', '#0288d1', '#00796b', '#fbc02d', '#f57c00', '#455a64'];

const formatTime = (seconds) => {
  if (isNaN(seconds) || seconds < 0) return '00:00:00';
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const secs = seconds % 60;
  return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
};

const EmployeeEventDetails = () => {
  const { eventId } = useParams();
  const navigate = useNavigate();
  const [event, setEvent] = useState(null);
  const [projectPhotos, setProjectPhotos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [tabValue, setTabValue] = useState(0);
  const [uploading, setUploading] = useState(false);
  const [timesheets, setTimesheets] = useState([]);
  const [fadeIn, setFadeIn] = useState(true);
  const [projectPhotosLoading, setProjectPhotosLoading] = useState(true);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [clockedIn, setClockedIn] = useState(false);
  const [currentEventId, setCurrentEventId] = useState(null);
  const [elapsedTime, setElapsedTime] = useState(0);
  const [clockInTime, setClockInTime] = useState(null);

  useEffect(() => {
    if (eventId) {
      const fetchEventDetails = async () => {
        try {
          setLoading(true);
          const [eventData, timesheetsData] = await Promise.all([
            getEmployeeEventDetails(eventId),
            getEmployeeTimesheets()
          ]);
          console.log('Event data:', eventData);
          console.log('Timesheets data:', timesheetsData);
          setEvent(eventData);
          if (eventData.project && eventData.project.id) {
            const photos = await getProjectPhotos(eventData.project.id);
            console.log('Project photos:', photos);
            setProjectPhotos(photos);
          }
          // Filter timesheets for the specific event
          const filteredTimesheets = timesheetsData.timesheets.filter(
            timesheet => timesheet.event.id === parseInt(eventId)
          );
          console.log('Filtered timesheets:', filteredTimesheets);
          setTimesheets(filteredTimesheets);
          setError(null);
        } catch (err) {
          console.error('Error fetching event details:', err);
          setError('Failed to fetch event details. Please try again later.');
        } finally {
          setLoading(false);
        }
      };

      fetchEventDetails();
    }
  }, [eventId]); // Only re-run the effect if eventId changes

  useEffect(() => {
    const storedClockInTime = localStorage.getItem('clockInTime');
    const storedEventId = localStorage.getItem('clockedInEventId');
    
    if (storedClockInTime && storedEventId) {
      const clockInTimeValue = parseInt(storedClockInTime, 10);
      if (!isNaN(clockInTimeValue)) {
        setClockInTime(clockInTimeValue);
        setClockedIn(true);
        setCurrentEventId(storedEventId);
      }
    }
  }, []);

  useEffect(() => {
    console.log('Timer effect triggered. Clocked in:', clockedIn, 'Clock-in time:', clockInTime);
    let intervalId;
    if (clockedIn && clockInTime) {
      console.log('Starting timer with clock-in time:', new Date(clockInTime).toISOString());
      intervalId = setInterval(() => {
        const now = Date.now();
        const elapsed = Math.floor((now - clockInTime) / 1000);
        console.log('Elapsed time:', elapsed, 'seconds');
        setElapsedTime(elapsed);
      }, 1000);
    } else {
      console.log('Timer not started or stopped.');
    }
    return () => {
      if (intervalId) {
        console.log('Clearing timer interval');
        clearInterval(intervalId);
      }
    };
  }, [clockedIn, clockInTime]);

  const handleTabChange = (event, newValue) => {
    setFadeIn(false);
    setTimeout(() => {
      setTabValue(newValue);
      setFadeIn(true);
    }, 500); // Increased timeout to match the new transition duration
  };

  const handlePhotoUpload = async (e) => {
    const file = e.target.files[0];
    if (!file || !event || !event.project) return;

    try {
      setUploading(true);
      const formData = new FormData();
      formData.append('photo', file);
      
      await uploadProjectPhoto(event.project.id, formData);
      
      // Refresh project photos
      const updatedPhotos = await getProjectPhotos(event.project.id);
      setProjectPhotos(updatedPhotos);
    } catch (error) {
      console.error('Error uploading photo:', error);
      // You might want to show an error message to the user here
    } finally {
      setUploading(false);
    }
  };

  const handleClockIn = async () => {
    try {
      const response = await clockInToEvent(eventId);
      console.log('Clock in response:', response);
      
      const currentTime = Date.now();
      localStorage.setItem('clockInTime', currentTime.toString());
      localStorage.setItem('clockedInEventId', eventId);

      setClockInTime(currentTime);
      setClockedIn(true);
      setCurrentEventId(eventId);
      setElapsedTime(0);
    } catch (err) {
      console.error('Error clocking in:', err);
    }
  };

  const handleClockOut = async () => {
    try {
      const response = await clockOutFromEvent();
      console.log('Clock out response:', response);

      localStorage.removeItem('clockInTime');
      localStorage.removeItem('clockedInEventId');
      
      setClockInTime(null);
      setClockedIn(false);
      setCurrentEventId(null);
      setElapsedTime(0);
    } catch (err) {
      console.error('Error clocking out:', err);
    }
  };

  if (loading) {
    return (
      <div className={styles.loadingContainer}>
        <CircularProgress />
      </div>
    );
  }

  if (error) {
    return (
      <div className={styles.errorContainer}>
        <Typography className={styles.errorMessage}>{error}</Typography>
      </div>
    );
  }

  if (!event) {
    return (
      <div className={styles.errorContainer}>
        <Typography className={styles.errorMessage}>Event not found.</Typography>
      </div>
    );
  }

  return (
    <Paper elevation={3} className={styles.eventDetailsContainer}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '16px' }}>
        <Button
          startIcon={<ArrowBackIcon />}
          onClick={() => navigate(-1)}
          className={styles.backButton}
        >
          Back
        </Button>
        
        <div style={{ display: 'flex', gap: '8px' }}>
          {event.addresses && event.addresses.length > 0 && (
            <Button
              variant="outlined"
              startIcon={<LocationOnIcon />}
              onClick={() => window.open(`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(event.addresses[0])}`, '_blank')}
              sx={{ 
                borderColor: '#666',
                color: '#666',
                '&:hover': {
                  borderColor: '#333',
                  backgroundColor: 'rgba(0, 0, 0, 0.04)',
                },
                textTransform: 'none',
                borderRadius: '8px',
                padding: '8px 16px',
              }}
            >
              Navigate
            </Button>
          )}
          
          {clockedIn && currentEventId === eventId ? (
            <Button
              variant="contained"
              startIcon={<AccessTimeIcon />}
              onClick={handleClockOut}
              sx={{
                backgroundColor: '#dc3545', // Red color for clock out
                '&:hover': {
                  backgroundColor: '#c82333', // Darker red on hover
                },
                textTransform: 'none',
                borderRadius: '8px',
                padding: '8px 16px',
                boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
              }}
            >
              Clock Out
            </Button>
          ) : (
            <Button
              variant="contained"
              startIcon={<AccessTimeIcon />}
              onClick={handleClockIn}
              sx={{
                backgroundColor: '#28a745', // Green color for clock in
                '&:hover': {
                  backgroundColor: '#218838', // Darker green on hover
                },
                textTransform: 'none',
                borderRadius: '8px',
                padding: '8px 16px',
                boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
              }}
            >
              Clock In
            </Button>
          )}
        </div>
      </div>

      <Typography variant="h4" className={styles.eventTitle}>
        <EventIcon className={styles.titleIcon} />
        {event.title}
      </Typography>
      {clockedIn && currentEventId === eventId && (
        <Paper 
          elevation={0} 
          className={styles.activeTimerContainer}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: 2,
            padding: '12px 24px',
            margin: '16px auto',
            backgroundColor: '#f5f5f5',
            borderRadius: '12px',
            maxWidth: 'fit-content',
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            <AccessTimeIcon sx={{ color: '#666' }} />
            <Typography 
              variant="h5" 
              sx={{ 
                fontFamily: 'Urbanist, sans-serif',
                fontWeight: 500,
                color: '#666'
              }}
            >
              Active Timer:
            </Typography>
          </div>
          <Typography 
            variant="h5" 
            sx={{ 
              fontFamily: 'Urbanist, sans-serif',
              fontWeight: 600,
              color: '#333'
            }}
          >
            {formatTime(elapsedTime)}
          </Typography>
        </Paper>
      )}
      
      <Tabs 
        value={tabValue} 
        onChange={handleTabChange} 
        className={styles.tabs}
        variant="fullWidth"
        centered
      >
        <Tab 
          label="Details" 
          icon={<EventIcon />} 
          wrapped
        />
        <Tab 
          label="Photos" 
          icon={<PhotoLibraryIcon />} 
          wrapped
        />
        <Tab 
          label="Time" 
          icon={<AccessTimeIcon />} 
          wrapped
        />
        <Tab 
          label="Internal Notes" 
          icon={<NotesIcon />} 
          wrapped
        />
      </Tabs>

      <CSSTransition
        in={fadeIn}
        timeout={500}
        classNames={{
          enter: styles.fadeEnter,
          enterActive: styles.fadeEnterActive,
          exit: styles.fadeExit,
          exitActive: styles.fadeExitActive,
        }}
        unmountOnExit
      >
        <div className={`${styles.tabContent} ${fadeIn ? styles.fadeIn : ''}`}>
          {tabValue === 0 && (
            <div>
              <div className={styles.detailItem}>
                <AccessTimeIcon className={`${styles.detailIcon} ${styles.timeIcon}`} />
                <Typography>
                  {event.time_slots && event.time_slots.length > 0
                    ? `${moment(event.time_slots[0].start_time).format('MMMM D, YYYY h:mm A')} - ${moment(event.time_slots[0].end_time).format('h:mm A')}`
                    : 'Time not specified'}
                </Typography>
              </div>
              {event.addresses && event.addresses.length > 0 && (
                <div className={styles.detailItem}>
                  <LocationOnIcon className={`${styles.detailIcon} ${styles.locationIcon}`} />
                  <div className={styles.addressContainer}>
                    {event.addresses.map((address, index) => (
                      <div key={index} style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                        <Typography className={styles.addressText}>{address}</Typography>
                      </div>
                    ))}
                  </div>
                </div>
              )}
              {event.employees && event.employees.length > 0 && (
                <div className={styles.detailItem}>
                  <PeopleIcon className={`${styles.detailIcon} ${styles.peopleIcon}`} />
                  <div className={styles.employeesContainer}>
                    <Typography variant="subtitle1">Assigned Employees:</Typography>
                    <div className={styles.avatarGroup}>
                      {event.employees.map((employee, index) => (
                        <Tooltip key={employee.id} title={employee.name} arrow>
                          <Avatar 
                            className={styles.employeeAvatar}
                            style={{ 
                              backgroundColor: avatarColors[index % avatarColors.length]
                            }}
                          >
                            {employee.name.charAt(0)}
                          </Avatar>
                        </Tooltip>
                      ))}
                    </div>
                  </div>
                </div>
              )}
              {event.description && (
                <div className={styles.descriptionContainer}>
                  <Typography variant="h6">Description:</Typography>
                  <Typography sx={{ whiteSpace: 'pre-wrap' }}>
                    {event.description}
                  </Typography>
                </div>
              )}
            </div>
          )}

          {tabValue === 1 && (
            <div>
              <ProjectPhotos 
                projectId={event.project.id} 
                onLoadComplete={() => {
                  setProjectPhotosLoading(false);
                  setFadeIn(true);
                }}
              />
            </div>
          )}

          {tabValue === 2 && (
            <div className={styles.timesheetsContainer} style={{ padding: '16px' }}>
              <Typography variant="h6">Timesheets for this Event</Typography>
              {timesheets.length > 0 ? (
                <div className={styles.tableWrapper}>
                  <TableContainer component={Paper} className={`${styles.tableWrapper} ${styles.tableContainer}`}>
                    <Table className={styles.table}>
                      <TableHead style={{ position: 'sticky', top: 0, background: 'white', zIndex: 1 }}>
                        <TableRow>
                          {[
                            { icon: <AccessTimeIcon />, label: 'Clock In', color: '#3498db' },
                            { icon: <EventNoteIcon />, label: 'Clock Out', color: '#e74c3c' },
                            { icon: <AttachMoneyIcon />, label: 'Payment Type', color: '#2ecc71' },
                            { icon: <CalendarTodayIcon />, label: 'Rate/Percentage', color: '#1abc9c' },
                            { icon: <HourglassEmptyIcon />, label: 'Hours Worked', color: '#f39c12' },
                            { icon: <CheckCircleOutlineIcon />, label: 'Calculated Payment', color: '#27ae60' },
                          ].map((cell, index) => (
                            <TableCell key={index} align="center" className={styles.tableCell}>
                              <div className={styles.headerContent}>
                                <div className={styles.headerIcon} style={{ backgroundColor: cell.color }}>
                                  {React.cloneElement(cell.icon, { style: { color: 'white' } })}
                                </div>
                                <span className={styles.headerLabel}>{cell.label}</span>
                              </div>
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {timesheets.map((timesheet) => (
                          <TableRow key={timesheet.id}>
                            <TableCell className={styles.tableCell} align="center">{formatDateTime(timesheet.clock_in)}</TableCell>
                            <TableCell className={styles.tableCell} align="center">
                              {timesheet.clock_out ? formatDateTime(timesheet.clock_out) : 'Not clocked out'}
                            </TableCell>
                            <TableCell className={styles.tableCell} align="center">{timesheet.payment_type}</TableCell>
                            <TableCell className={styles.tableCell} align="center">
                              {timesheet.payment_type === 'fixed' 
                                ? `$${timesheet.rate || 0}`
                                : timesheet.percentage 
                                  ? `${timesheet.percentage}%` 
                                  : `$${timesheet.rate || 0}/hr`}
                            </TableCell>
                            <TableCell className={styles.tableCell} align="center">{timesheet.hours_worked.toFixed(2)}</TableCell>
                            <TableCell className={styles.tableCell} align="center">
                              ${timesheet.payment_type === 'fixed' 
                                ? (timesheet.rate || 0).toFixed(2)
                                : (timesheet.calculated_payment || 0).toFixed(2)}
                            </TableCell>
                          </TableRow>
                        ))}
                        <TableRow className={styles.totalRow}>
                          <TableCell className={styles.tableCell} colSpan={4}></TableCell>
                          <TableCell className={styles.tableCell}>
                            <div className={styles.totalCell}>
                              <div className={styles.totalIconWrapper} style={{ backgroundColor: '#f39c12' }}>
                                <HourglassEmptyIcon className={styles.totalIcon} />
                              </div>
                              <strong className={styles.totalText}>{timesheets.reduce((sum, t) => sum + t.hours_worked, 0).toFixed(2)}</strong>
                            </div>
                          </TableCell>
                          <TableCell className={styles.tableCell}>
                            <div className={styles.totalCell}>
                              <div className={styles.totalIconWrapper} style={{ backgroundColor: '#27ae60' }}>
                                <AttachMoneyIcon className={styles.totalIcon} />
                              </div>
                              <strong className={styles.totalText}>${timesheets.reduce((sum, t) => sum + t.calculated_payment, 0).toFixed(2)}</strong>
                            </div>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              ) : (
                <Typography>No timesheets available for this event.</Typography>
              )}
            </div>
          )}

          {tabValue === 3 && (
            <div className={styles.notesContainer}>
              <Typography variant="h6" gutterBottom>
                Internal Notes
              </Typography>
              {event.project && event.project.scope_of_work ? (
                <Paper 
                  elevation={0}
                  sx={{ 
                    backgroundColor: '#f5f5f5',
                    padding: '16px',
                    borderRadius: '8px',
                    whiteSpace: 'pre-wrap'
                  }}
                >
                  <Typography>
                    {event.project.scope_of_work}
                  </Typography>
                </Paper>
              ) : (
                <Typography color="textSecondary">
                  No internal notes available.
                </Typography>
              )}
            </div>
          )}
        </div>
      </CSSTransition>
    </Paper>
  );
};

export default EmployeeEventDetails;
