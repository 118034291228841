import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Paper, 
  Typography, 
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Chip,
  Avatar,
  AvatarGroup,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Button,
  Tooltip
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EventIcon from '@mui/icons-material/Event';
import FilterListIcon from '@mui/icons-material/FilterList';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { getAllEvents, getAllProjects, getProjectDetails } from '../../../services/api';
import { format } from 'date-fns';
import EventDetails from '../../EventDetails';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import styles from '../../../components/Employees.module.scss';

const VisitsView = ({ onBack }) => {
  // State for filters
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [status, setStatus] = useState('all');
  const [client, setClient] = useState('all');
  const [team, setTeam] = useState('all');
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [showEventDetails, setShowEventDetails] = useState(false);
  const [projects, setProjects] = useState([]);
  const [clients, setClients] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredEvents, setFilteredEvents] = useState([]);
  const [teamMembers, setTeamMembers] = useState([]);
  const [isSearchExpanded, setIsSearchExpanded] = useState(false);

  // Helper function to safely format dates
  const safeFormatDate = (dateString, formatString) => {
    try {
      if (!dateString) return 'N/A';
      const date = new Date(dateString);
      if (isNaN(date.getTime())) return 'Invalid Date';
      return format(date, formatString);
    } catch (error) {
      console.error('Date formatting error:', error);
      return 'Invalid Date';
    }
  };

  // Fetch projects and extract unique clients
  useEffect(() => {
    const fetchProjectsAndClients = async () => {
      try {
        const projectsData = await getAllProjects();
        setProjects(projectsData);
        
        // Extract unique clients from projects
        const uniqueClients = Array.from(new Set(
          projectsData
            .filter(project => project.client)
            .map(project => JSON.stringify(project.client))
        )).map(str => JSON.parse(str));

        setClients(uniqueClients);
      } catch (error) {
        console.error('Error fetching projects and clients:', error);
      }
    };

    fetchProjectsAndClients();
  }, []);

  // Helper function to check if an event is scheduled
  const isEventScheduled = (timeSlots) => {
    return timeSlots && timeSlots.length > 0 && timeSlots[0]?.start_time;
  };

  // Modified event fetching to handle unscheduled events
  useEffect(() => {
    const fetchEvents = async () => {
      try {
        setLoading(true);
        const filters = {
          startDate: startDate?.toISOString(),
          endDate: endDate?.toISOString()
        };
        const eventsData = await getAllEvents(filters);
        
        // Map events with project information
        const formattedEvents = eventsData.map(event => {
          const project = projects.find(p => p.id === event.project_id);
          const scheduled = isEventScheduled(event.time_slots);

          return {
            id: event.id,
            date: scheduled 
              ? safeFormatDate(event.time_slots[0].start_time, 'yyyy-MM-dd') 
              : 'Unscheduled',
            startTime: scheduled 
              ? safeFormatDate(event.time_slots[0].start_time, 'hh:mm a') 
              : '',
            endTime: scheduled 
              ? safeFormatDate(event.time_slots[0].end_time, 'hh:mm a') 
              : '',
            timeDisplay: scheduled 
              ? `${safeFormatDate(event.time_slots[0].start_time, 'hh:mm a')} - ${safeFormatDate(event.time_slots[0].end_time, 'hh:mm a')}` 
              : 'Unscheduled',
            client: project?.client?.name || 'N/A',
            project: {
              id: project?.id,
              title: project?.name || 'N/A',
              type: event.description || 'N/A'
            },
            location: {
              address: event.addresses?.[0] || 'No address provided',
              coordinates: ''
            },
            team: (event.employees || []).map(emp => ({
              id: emp.id || Math.random(),
              name: emp.name || 'Unknown',
              avatar: (emp.name || 'U').charAt(0),
              role: emp.role || 'Team Member'
            })),
            status: scheduled ? determineEventStatus(event) : 'Unscheduled',
            duration: scheduled ? calculateDuration(event.time_slots[0]) : 'N/A',
            notes: event.description || 'No notes provided',
            customFields: {
              checklistCompleted: false
            },
            value: event.value || 0
          };
        });

        setEvents(formattedEvents);
        setError(null);
      } catch (err) {
        console.error('Error fetching events:', err);
        setError('Failed to load events');
      } finally {
        setLoading(false);
      }
    };

    if (projects.length > 0) {
      fetchEvents();
    }
  }, [startDate, endDate, status, client, team, projects]);

  // Updated helper functions
  const determineEventStatus = (event) => {
    try {
      const now = new Date();
      const startTime = event.time_slots?.[0]?.start_time ? new Date(event.time_slots[0].start_time) : null;
      const endTime = event.time_slots?.[0]?.end_time ? new Date(event.time_slots[0].end_time) : null;

      if (!startTime || !endTime) return 'Unknown';
      if (endTime < now) return 'Completed';
      if (startTime > now) return 'Pending';
      return 'In Progress';
    } catch (error) {
      console.error('Error determining event status:', error);
      return 'Unknown';
    }
  };

  const calculateDuration = (timeSlot) => {
    try {
      if (!timeSlot?.start_time || !timeSlot?.end_time) return 'N/A';
      const start = new Date(timeSlot.start_time);
      const end = new Date(timeSlot.end_time);
      if (isNaN(start.getTime()) || isNaN(end.getTime())) return 'N/A';
      const hours = Math.round((end - start) / (1000 * 60 * 60) * 10) / 10;
      return `${hours} hours`;
    } catch (error) {
      console.error('Error calculating duration:', error);
      return 'N/A';
    }
  };

  const handleExport = () => {
    // Implement export functionality
    console.log('Exporting data...');
  };

  const handleRowClick = (event) => {
    setSelectedEvent(event.rawEvent);
    setShowEventDetails(true);
  };

  const handleEventUpdated = (updatedEvent) => {
    // Refresh the events list after an update
    setShowEventDetails(false);
    const fetchEvents = async () => {
      try {
        setLoading(true);
        const filters = {
          startDate: startDate?.toISOString(),
          endDate: endDate?.toISOString()
        };
        const eventsData = await getAllEvents(filters);
        console.log('Raw events data:', eventsData);

        // Transform events data with project details
        const formattedEvents = await Promise.all(eventsData.map(async event => {
          let projectDetails = null;
          if (event.project_id) {
            try {
              projectDetails = await getProjectDetails(event.project_id);
            } catch (err) {
              console.error(`Error fetching project details for project ${event.project_id}:`, err);
            }
          }

          const firstTimeSlot = event.time_slots?.[0] || {};
          
          return {
            id: event.id,
            date: safeFormatDate(firstTimeSlot.start_time, 'yyyy-MM-dd') || 'No date',
            startTime: safeFormatDate(firstTimeSlot.start_time, 'hh:mm a') || 'No time',
            endTime: safeFormatDate(firstTimeSlot.end_time, 'hh:mm a') || 'No time',
            client: projectDetails?.client?.name || 'N/A',
            project: {
              id: event.project_id,
              title: projectDetails?.title || event.title || 'Untitled Project',
              client: projectDetails?.client || null
            },
            location: {
              address: event.addresses?.[0] || 'No address provided',
              coordinates: ''
            },
            team: (event.employees || []).map(emp => ({
              id: emp.id || Math.random(),
              name: emp.name || 'Unknown',
              avatar: (emp.name || 'U').charAt(0),
              role: emp.role || 'Team Member'
            })),
            status: determineEventStatus(event),
            duration: calculateDuration(firstTimeSlot),
            notes: event.description || 'No notes provided',
            customFields: {
              checklistCompleted: false
            },
            value: event.value || 0,
            rawEvent: event // Store the original event data
          };
        }));

        setEvents(formattedEvents);
        setError(null);
      } catch (err) {
        console.error('Error fetching events:', err);
        setError('Failed to load events');
      } finally {
        setLoading(false);
      }
    };
    fetchEvents();
  };

  // Update the client select options
  const clientOptions = [
    <MenuItem value="all" key="all">All Clients</MenuItem>,
    ...clients.map(client => (
      <MenuItem value={client.id} key={client.id}>
        {client.name}
      </MenuItem>
    ))
  ];

  // Fetch team members
  useEffect(() => {
    const fetchTeamMembers = async () => {
      try {
        const uniqueMembers = new Set();
        events.forEach(event => {
          event.team.forEach(member => {
            uniqueMembers.add(JSON.stringify({
              id: member.id,
              name: member.name
            }));
          });
        });
        setTeamMembers(Array.from(uniqueMembers).map(str => JSON.parse(str)));
      } catch (error) {
        console.error('Error extracting team members:', error);
      }
    };

    fetchTeamMembers();
  }, [events]);

  // Update the team options
  const teamOptions = [
    <MenuItem value="all" key="all">All Team Members</MenuItem>,
    ...teamMembers.map(member => (
      <MenuItem value={member.id} key={member.id}>
        {member.name}
      </MenuItem>
    ))
  ];

  // Apply filters and search
  useEffect(() => {
    let filtered = [...events];

    // Apply date range filter
    if (startDate || endDate) {
      filtered = filtered.filter(event => {
        if (event.date === 'Unscheduled') return true;
        const eventDate = new Date(event.date);
        const start = startDate ? new Date(startDate) : null;
        const end = endDate ? new Date(endDate) : null;
        
        if (start && end) {
          return eventDate >= start && eventDate <= end;
        } else if (start) {
          return eventDate >= start;
        } else if (end) {
          return eventDate <= end;
        }
        return true;
      });
    }

    // Apply status filter
    if (status !== 'all') {
      if (status === 'unscheduled') {
        filtered = filtered.filter(event => event.date === 'Unscheduled');
      } else {
        filtered = filtered.filter(event => event.status.toLowerCase() === status.toLowerCase());
      }
    }

    // Apply client filter
    if (client !== 'all') {
      filtered = filtered.filter(event => event.project.client?.id === client);
    }

    // Apply team filter
    if (team !== 'all') {
      filtered = filtered.filter(event => 
        event.team.some(member => member.id === team)
      );
    }

    // Apply search
    if (searchTerm) {
      const search = searchTerm.toLowerCase();
      filtered = filtered.filter(event =>
        event.project.title.toLowerCase().includes(search) ||
        event.client.toLowerCase().includes(search) ||
        event.location.address.toLowerCase().includes(search) ||
        event.team.some(member => member.name.toLowerCase().includes(search)) ||
        event.notes.toLowerCase().includes(search)
      );
    }

    setFilteredEvents(filtered);
  }, [events, startDate, endDate, status, client, team, searchTerm]);

  // Status options with counts
  const getStatusCount = (statusType) => {
    if (statusType === 'unscheduled') {
      return events.filter(event => event.date === 'Unscheduled').length;
    }
    return events.filter(event => event.status.toLowerCase() === statusType.toLowerCase()).length;
  };

  const statusOptions = [
    <MenuItem value="all" key="all">All Status</MenuItem>,
    <MenuItem value="unscheduled" key="unscheduled">
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
        <Typography>Unscheduled</Typography>
        <Chip 
          label={getStatusCount('unscheduled')}
          size="small"
          color="default"
          sx={{ ml: 1, height: 20, fontSize: '0.75rem' }}
        />
      </Box>
    </MenuItem>,
    <MenuItem value="completed" key="completed">
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
        <Typography>Completed</Typography>
        <Chip 
          label={getStatusCount('completed')}
          size="small"
          color="success"
          sx={{ ml: 1, height: 20, fontSize: '0.75rem' }}
        />
      </Box>
    </MenuItem>,
    <MenuItem value="pending" key="pending">
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
        <Typography>Pending</Typography>
        <Chip 
          label={getStatusCount('pending')}
          size="small"
          color="warning"
          sx={{ ml: 1, height: 20, fontSize: '0.75rem' }}
        />
      </Box>
    </MenuItem>,
    <MenuItem value="cancelled" key="cancelled">
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
        <Typography>Cancelled</Typography>
        <Chip 
          label={getStatusCount('cancelled')}
          size="small"
          color="error"
          sx={{ ml: 1, height: 20, fontSize: '0.75rem' }}
        />
      </Box>
    </MenuItem>
  ];

  return (
    <Box>
      {/* Header */}
      <Box sx={{ mb: 4, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <IconButton onClick={onBack} sx={{ mr: 2 }}>
            <ArrowBackIcon />
          </IconButton>
          <EventIcon sx={{ mr: 2, color: '#34495e' }} />
          <Typography variant="h5">Visit Reports</Typography>
        </Box>
        <Button
          variant="contained"
          startIcon={<FileDownloadIcon />}
          onClick={handleExport}
          sx={{ textTransform: 'none' }}
        >
          Export
        </Button>
      </Box>

      {/* Search with margin */}
      <div className={styles.searchContainer} style={{ marginBottom: '24px' }}>
        <div className={styles.searchIcon}>
          <SearchIcon />
        </div>
        <input
          type="text"
          placeholder="Search events..."
          className={styles.searchInput}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>

      {/* Existing Filters */}
      <Grid container spacing={2} alignItems="center" mb={2}>
        <Grid item xs={12} md={3}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              value={startDate}
              onChange={(newValue) => setStartDate(newValue)}
              format="MM/DD/YYYY"
              slotProps={{
                textField: {
                  placeholder: "Start Date",
                  fullWidth: true,
                  variant: "outlined",
                  size: "small",
                  sx: {
                    backgroundColor: 'white',
                    '& .MuiOutlinedInput-root': {
                      border: '1px solid #ccc',
                      borderRadius: '4px',
                      '&:hover': {
                        border: '1px solid #999',
                      },
                      '&.Mui-focused': {
                        border: '1px solid #1976d2',
                        '& .MuiOutlinedInput-notchedOutline': {
                          border: 'none',
                        },
                      },
                      '& .MuiOutlinedInput-notchedOutline': {
                        border: 'none',
                      },
                    },
                  }
                },
                inputAdornment: {
                  position: 'end'
                }
              }}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} md={3}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              value={endDate}
              onChange={(newValue) => setEndDate(newValue)}
              format="MM/DD/YYYY"
              slotProps={{
                textField: {
                  placeholder: "End Date",
                  fullWidth: true,
                  variant: "outlined",
                  size: "small",
                  sx: {
                    backgroundColor: 'white',
                    '& .MuiOutlinedInput-root': {
                      border: '1px solid #ccc',
                      borderRadius: '4px',
                      '&:hover': {
                        border: '1px solid #999',
                      },
                      '&.Mui-focused': {
                        border: '1px solid #1976d2',
                        '& .MuiOutlinedInput-notchedOutline': {
                          border: 'none',
                        },
                      },
                      '& .MuiOutlinedInput-notchedOutline': {
                        border: 'none',
                      },
                    },
                  }
                },
                inputAdornment: {
                  position: 'end'
                }
              }}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} md={2}>
          <Select
            value={status}
            displayEmpty
            size="small"
            onChange={(e) => setStatus(e.target.value)}
            sx={{
              width: '100%',
              backgroundColor: 'white',
              '& .MuiOutlinedInput-notchedOutline': {
                border: 'none'
              },
              border: '1px solid #ccc',
              borderRadius: '4px',
              '&:hover': {
                border: '1px solid #999'
              },
              '&.Mui-focused': {
                border: '1px solid #1976d2'
              }
            }}
          >
            {statusOptions}
          </Select>
        </Grid>
        <Grid item xs={12} md={2}>
          <Select
            value={client}
            displayEmpty
            size="small"
            onChange={(e) => setClient(e.target.value)}
            sx={{
              width: '100%',
              backgroundColor: 'white',
              '& .MuiOutlinedInput-notchedOutline': {
                border: 'none'
              },
              border: '1px solid #ccc',
              borderRadius: '4px',
              '&:hover': {
                border: '1px solid #999'
              },
              '&.Mui-focused': {
                border: '1px solid #1976d2'
              }
            }}
          >
            {clientOptions}
          </Select>
        </Grid>
        <Grid item xs={12} md={2}>
          <Select
            value={team}
            displayEmpty
            size="small"
            onChange={(e) => setTeam(e.target.value)}
            sx={{
              width: '100%',
              backgroundColor: 'white',
              '& .MuiOutlinedInput-notchedOutline': {
                border: 'none'
              },
              border: '1px solid #ccc',
              borderRadius: '4px',
              '&:hover': {
                border: '1px solid #999'
              },
              '&.Mui-focused': {
                border: '1px solid #1976d2'
              }
            }}
          >
            {teamOptions}
          </Select>
        </Grid>
      </Grid>

      {/* Data Table */}
      <Paper elevation={0} sx={{ p: 3 }}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Date & Time</TableCell>
                <TableCell>Client & Project</TableCell>
                <TableCell>Location</TableCell>
                <TableCell>Team</TableCell>
                <TableCell>Duration</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Notes</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredEvents.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={7} align="center">
                    <Typography color="textSecondary">
                      {loading ? 'Loading...' : 'No events found'}
                    </Typography>
                  </TableCell>
                </TableRow>
              ) : (
                filteredEvents.map((row) => (
                  <TableRow 
                    key={row.id}
                    onClick={() => handleRowClick(row)}
                    sx={{ 
                      cursor: 'pointer',
                      '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.04)' }
                    }}
                  >
                    <TableCell>
                      <Typography>{row.date}</Typography>
                      {row.date !== 'Unscheduled' && (
                        <Typography variant="caption" color="textSecondary">
                          {row.timeDisplay}
                        </Typography>
                      )}
                    </TableCell>
                    <TableCell>
                      <Typography>{row.client}</Typography>
                      <Typography variant="caption" color="textSecondary">
                        {row.project.title} - {row.project.type}
                      </Typography>
                    </TableCell>
                    <TableCell>{row.location.address}</TableCell>
                    <TableCell>
                      <AvatarGroup max={3}>
                        {row.team.map((member) => (
                          <Avatar 
                            key={member.id} 
                            alt={member.name}
                            sx={{ width: 24, height: 24 }}
                          >
                            {member.avatar}
                          </Avatar>
                        ))}
                      </AvatarGroup>
                    </TableCell>
                    <TableCell>{row.duration}</TableCell>
                    <TableCell>
                      <Chip 
                        label={row.status}
                        color={row.status === 'Unscheduled' ? 'default' : 
                               row.status === 'Completed' ? 'success' : 
                               row.status === 'In Progress' ? 'warning' : 
                               'primary'}
                        size="small"
                      />
                    </TableCell>
                    <TableCell>{row.notes}</TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>

      {/* Event Details Dialog */}
      <EventDetails
        eventId={selectedEvent?.id}
        open={showEventDetails}
        onClose={() => setShowEventDetails(false)}
        onEventUpdated={handleEventUpdated}
      />
    </Box>
  );
};

export default VisitsView; 