import React, { useEffect } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from './AuthContext';

const PrivateRoute = ({ children, allowedRoles }) => {
  const { user, isAuthenticated } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    console.log('PrivateRoute mount - Auth state:', {
      user,
      isAuthenticated,
      localStorage: {
        token: localStorage.getItem('token'),
        userRole: localStorage.getItem('userRole')
      }
    });
  }, [user, isAuthenticated]);

  if (!isAuthenticated) {
    console.log('Not authenticated, redirecting to login');
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  if (!allowedRoles.includes(user.role)) {
    console.log('Unauthorized role, redirecting to home');
    return <Navigate to="/" replace />;
  }

  return children;
};

export default PrivateRoute;
