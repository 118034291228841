import React from 'react';
import { Paper, Typography } from '@mui/material';

const ReportCard = ({ title, description, onClick, darkMode }) => (
  <Paper 
    elevation={0}
    onClick={onClick}
    sx={{
      p: 2.5,
      cursor: 'pointer',
      borderRadius: 1,
      backgroundColor: darkMode ? 'var(--background-paper)' : '#ffffff',
      border: `1px solid ${darkMode ? 'var(--border-color)' : '#e0e0e0'}`,
      transition: 'all 0.3s ease',
      '&:hover': {
        backgroundColor: darkMode ? 'var(--background-hover)' : '#f8f9fa',
        borderColor: darkMode ? 'var(--primary-main)' : '#90caf9',
        transform: 'translateY(-2px)',
        boxShadow: darkMode 
          ? '0 4px 20px rgba(0,0,0,0.3)' 
          : '0 4px 20px rgba(0,0,0,0.1)',
      }
    }}
  >
    <Typography 
      variant="subtitle1" 
      sx={{ 
        fontWeight: 500,
        mb: 1,
        color: darkMode ? 'var(--text-primary)' : '#1976d2'
      }}
    >
      {title}
    </Typography>
    <Typography 
      variant="body2" 
      sx={{
        color: darkMode ? 'var(--text-secondary)' : '#f4f4f4'
      }}
    >
      {description}
    </Typography>
  </Paper>
);

export default ReportCard; 