import React, { useState, useEffect } from 'react';
import { TextField, Button, Grid, Typography, Paper, Select, MenuItem, FormControl, InputLabel, Chip } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { useNavigate, useParams } from 'react-router-dom';
import { createEvent, getEmployees } from '../services/api'; // Assume you have these API functions
import { formatDateTime } from '../utils/dateUtils';

const CreateEventForm = () => {
  const navigate = useNavigate();
  const { projectId } = useParams(); // Get projectId from URL
  const [event, setEvent] = useState({
    title: '',
    description: '',
    start_time: null,
    end_time: null,
    project_id: projectId,
    employees: []
  });
  const [employees, setEmployees] = useState([]);

  const inputStyle = {
    height: '45px',
    width: '100%',
    padding: '8px 12px',
    fontSize: '0.95rem',
    border: '1px solid #ccc',
    borderRadius: '4px',
    backgroundColor: '#fff',
    fontFamily: 'inherit',
    '&:hover': {
      borderColor: '#666'
    },
    '& .MuiOutlinedInput-root': {
      height: '45px',
      '& fieldset': {
        border: '1px solid #ccc',
      },
      '&:hover fieldset': {
        borderColor: '#666',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#2196f3',
        borderWidth: '1px',
      }
    },
    '& .MuiOutlinedInput-input': {
      padding: '8px 12px',
      fontSize: '0.95rem',
      fontFamily: 'inherit'
    }
  };

  useEffect(() => {
    const fetchEmployees = async () => {
      try {
        const response = await getEmployees();
        setEmployees(response.data);
      } catch (error) {
        console.error('Failed to fetch employees:', error);
      }
    };
    fetchEmployees();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEvent(prevEvent => ({
      ...prevEvent,
      [name]: value
    }));
  };

  const handleDateChange = (name) => (date) => {
    console.log(`${name} selected:`, {
      original: date,
      isoString: date?.toISOString(),
      localString: date?.toLocaleString()
    });
    setEvent(prevEvent => ({
      ...prevEvent,
      [name]: date
    }));
  };

  const handleEmployeeChange = (e) => {
    setEvent(prevEvent => ({
      ...prevEvent,
      employees: e.target.value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formattedEvent = {
        ...event,
        start_time: new Date(event.start_time).toISOString().replace('Z', ''),
        end_time: new Date(event.end_time).toISOString().replace('Z', ''),
      };
      console.log('Submitting event:', {
        original: {
          start: event.start_time,
          end: event.end_time,
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
        },
        formatted: {
          start: formattedEvent.start_time,
          end: formattedEvent.end_time
        }
      });
      await createEvent(formattedEvent);
      navigate(`/projects/${projectId}`);
    } catch (error) {
      console.error('Failed to create event:', error);
    }
  };

  return (
    <Paper style={{ padding: '20px', maxWidth: '500px', margin: '0 auto' }}>
      <Typography variant="h5" gutterBottom>
        Create New Event
      </Typography>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Event Title"
              name="title"
              value={event.title}
              onChange={handleChange}
              required
              variant="outlined"
              sx={inputStyle}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Description"
              name="description"
              value={event.description}
              onChange={handleChange}
              multiline
              rows={4}
              variant="filled"
            />
          </Grid>
          <Grid item xs={6}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DateTimePicker
                label="Start Time"
                value={event.start_time}
                onChange={handleDateChange('start_time')}
                sx={{ width: '100%' }}
                slotProps={{
                  textField: {
                    variant: "outlined",
                    sx: inputStyle
                  }
                }}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={6}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DateTimePicker
                label="End Time"
                value={event.end_time}
                onChange={handleDateChange('end_time')}
                renderInput={(params) => <TextField {...params} fullWidth variant="filled" />}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth variant="filled">
              <InputLabel>Assign Employees</InputLabel>
              <Select
                multiple
                value={event.employees}
                onChange={handleEmployeeChange}
                renderValue={(selected) => (
                  <div style={{ display: 'flex', flexWrap: 'wrap', gap: '5px' }}>
                    {selected.map((value) => (
                      <Chip 
                        key={value} 
                        label={employees.find(emp => emp.id === value)?.name}
                      />
                    ))}
                  </div>
                )}
              >
                {employees.map((employee) => (
                  <MenuItem key={employee.id} value={employee.id}>
                    {employee.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Button type="submit" variant="contained" color="primary" fullWidth>
              Create Event
            </Button>
          </Grid>
        </Grid>
      </form>
    </Paper>
  );
};

export default CreateEventForm;
