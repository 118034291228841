import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  Typography, 
  Box, 
  Grid,
  Paper,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Chip
} from '@mui/material';
import {
  ArrowForward,
  Description,
  Receipt,
  NotificationsActive,
  CheckCircle,
  Warning,
  Timeline,
  Edit,
  Payment,
  Engineering,
  Schedule,
  Person,
  Build
} from '@mui/icons-material';
import styles from './AdminDashboard.module.scss';
import FinancialMetricsGraph from './FinancialMetricsGraph';
import WelcomeMessage from './WelcomeMessage';
import { 
  getAdminDashboard,
  getEstimates as getAllEstimates,
  getInvoices 
} from '../services/api';


function AdminDashboard() {
  const [recentEstimates, setRecentEstimates] = useState([]);
  const [recentInvoices, setRecentInvoices] = useState([]);
  const [showActivity, setShowActivity] = useState(false);
  const navigate = useNavigate();

  // Combine notification and activity items into a single config object
  const dashboardItems = {
    notifications: [
      {
        type: 'success',
        message: 'New estimate approved by client',
        time: '2 hours ago',
        details: 'Project: Kitchen Remodel',
        icon: 'success'
      },
      // ... other notification items
    ],
    activities: [
      {
        type: 'edit',
        message: 'Project details updated',
        time: '1 hour ago',
        details: 'Bathroom Renovation - Timeline adjusted',
        icon: 'edit'
      },
      // ... other activity items
    ]
  };

  // Simplified helper functions
  const formatters = {
    currency: (amount) => new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(amount),
    date: (dateString) => new Date(dateString).toLocaleDateString('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric'
    }),
    calculateTotal: (items, type) => {
      if (!items?.length) return 0;
      return items.reduce((sum, item) => {
        if (type === 'estimate') {
          const quantity = Number(item.quantity) || 1;
          const unitPrice = Number(item.unitPrice) || 0;
          return sum + (quantity * unitPrice);
        }
        return sum + (Number(item.total) || 0);
      }, 0);
    }
  };

  // Simplified icon and style helpers
  const getItemStyles = (type) => ({
    icon: {
      success: <CheckCircle />,
      warning: <Warning />,
      edit: <Edit />,
      payment: <Payment />,
      work: <Engineering />,
      schedule: <Schedule />,
      default: <Build />
    }[type] || <Build />,
    color: {
      success: 'success.main',
      warning: 'warning.main',
      edit: 'info.main',
      payment: 'success.main',
      work: 'primary.main',
      schedule: 'secondary.main'
    }[type] || 'action.active',
    background: `rgba(${
      {
        success: '46, 160, 67',
        warning: '255, 171, 0',
        edit: '3, 169, 244',
        payment: '76, 175, 80',
        work: '156, 39, 176',
        schedule: '233, 30, 99'
      }[type] || '158, 158, 158'
    }, 0.1)`
  });

  useEffect(() => {
    const fetchDashboardData = async () => {
      try {
        const [estimatesData, invoicesData] = await Promise.all([
          getAllEstimates(),
          getInvoices()
        ]);

        const sortAndSlice = (data) => data
          .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
          .slice(0, 5);

        setRecentEstimates(sortAndSlice(estimatesData));
        setRecentInvoices(sortAndSlice(invoicesData));
      } catch (error) {
        console.error('Error fetching dashboard data:', error);
      }
    };

    fetchDashboardData();
  }, []);

  // Render recent item (estimate or invoice)
  const renderRecentItem = (item, type) => (
    <Box 
      key={item.id} 
      className={styles.recentItem}
      onClick={() => navigate(`/admin/${type}s/${item.id}`)}
    >
      <Box className={styles.recentInfo}>
        {type === 'estimate' ? <Description className={styles.recentIcon} /> : <Receipt className={styles.recentIcon} />}
        <Box>
          <Typography className={styles.recentTitle}>
            {item.customer_details?.company || item.customer_details?.name || 'Unnamed Client'}
          </Typography>
          <Typography className={styles.recentDate}>
            {formatters.date(item.created_at)}
          </Typography>
        </Box>
      </Box>
      <Box>
        <Typography className={type === 'estimate' ? styles.estimateNumber : styles.invoiceNumber}>
          {type === 'estimate' ? item.estimate_number : item.invoice_number}
        </Typography>
        <Typography className={styles.recentAmount}>
          {formatters.currency(formatters.calculateTotal(item.items, type))}
        </Typography>
      </Box>
    </Box>
  );

  return (
    <div className={styles.dashboardContainer}>
      <Grid container spacing={3}>
        {/* Welcome Message - Full Width */}
        <Grid item xs={12} sx={{ mb: -3 }}>
          <WelcomeMessage />
        </Grid>

        {/* Graph and Notifications Row */}
        <Grid item xs={12} lg={8.5}>
          <FinancialMetricsGraph />
        </Grid>
        
        <Grid item xs={12} lg={3.5}>
          <Paper 
            sx={{ 
              height: '525px',
              p: 2,
              borderRadius: '16px',
              background: 'rgba(255, 255, 255, 0.7)',
              backdropFilter: 'blur(10px)',
              boxShadow: '0 4px 20px rgba(0, 0, 0, 0.05)',
              border: '1px solid rgba(255, 255, 255, 0.3)',
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            {/* Header */}
            <Box 
              sx={{ 
                display: 'flex', 
                justifyContent: 'space-between',
                alignItems: 'center',
                mb: 2
              }}
            >
              <Typography variant="h6" sx={{ fontSize: '1rem', fontWeight: 500 }}>
                {showActivity ? 'Recent Activity' : 'Recent Notifications'}
              </Typography>
              <Button
                size="small"
                onClick={() => setShowActivity(!showActivity)}
                sx={{
                  fontSize: '0.8rem',
                  color: 'text.secondary',
                  '&:hover': { background: 'rgba(0, 0, 0, 0.04)' }
                }}
              >
                Show {showActivity ? 'Notifications' : 'Activity'}
              </Button>
            </Box>

            {/* Scrollable List */}
            <Box 
              sx={{ 
                overflowY: 'auto',
                flex: 1,
                '&::-webkit-scrollbar': {
                  width: '4px',
                },
                '&::-webkit-scrollbar-track': {
                  background: 'transparent',
                },
                '&::-webkit-scrollbar-thumb': {
                  background: 'rgba(0, 0, 0, 0.1)',
                  borderRadius: '4px',
                }
              }}
            >
              <List sx={{ p: 0 }}>
                {(showActivity ? dashboardItems.activities : dashboardItems.notifications).map((item, index) => (
                  <ListItem 
                    key={index} 
                    sx={{ 
                      p: 1.5,
                      mb: 1,
                      borderRadius: '12px',
                      background: 'rgba(255, 255, 255, 0.5)',
                      transition: 'all 0.2s ease',
                      '&:hover': {
                        background: 'rgba(255, 255, 255, 0.8)',
                        transform: 'translateY(-1px)',
                        cursor: 'pointer'
                      }
                    }}
                  >
                    <ListItemIcon sx={{ minWidth: '36px' }}>
                      <Box
                        sx={{
                          width: '28px',
                          height: '28px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          borderRadius: '8px',
                          background: getItemStyles(item.type).background,
                        }}
                      >
                        {getItemStyles(item.type).icon}
                      </Box>
                    </ListItemIcon>
                    <ListItemText 
                      primary={
                        <Typography 
                          variant="body2" 
                          sx={{ 
                            fontWeight: 500,
                            mb: 0.5,
                            color: 'text.primary'
                          }}
                        >
                          {item.message}
                        </Typography>
                      }
                      secondary={
                        <Box>
                          <Typography 
                            variant="caption" 
                            sx={{ 
                              display: 'block',
                              color: 'text.secondary',
                              mb: 0.5
                            }}
                          >
                            {item.details}
                          </Typography>
                          <Typography 
                            variant="caption" 
                            sx={{ 
                              color: 'text.disabled',
                              fontSize: '0.7rem'
                            }}
                          >
                            {item.time}
                          </Typography>
                        </Box>
                      }
                    />
                  </ListItem>
                ))}
              </List>
            </Box>
          </Paper>
        </Grid>

        {/* Estimates and Invoices Row */}
        <Grid item xs={12} md={6}>
          <Paper className={styles.recentCard}>
            <Box className={styles.cardHeader}>
              <Typography variant="h6">Recent Estimates</Typography>
              <Button 
                endIcon={<ArrowForward />}
                className={styles.showAllButton}
                onClick={() => navigate('/admin/estimates')}
              >
                View All
              </Button>
            </Box>
            <Box className={styles.recentList}>
              {recentEstimates.map(estimate => renderRecentItem(estimate, 'estimate'))}
            </Box>
          </Paper>
        </Grid>

        <Grid item xs={12} md={6}>
          <Paper className={styles.recentCard}>
            <Box className={styles.cardHeader}>
              <Typography variant="h6">Recent Invoices</Typography>
              <Button 
                endIcon={<ArrowForward />}
                className={styles.showAllButton}
                onClick={() => navigate('/admin/invoices')}
              >
                View All
              </Button>
            </Box>
            <Box className={styles.recentList}>
              {recentInvoices.map(invoice => renderRecentItem(invoice, 'invoice'))}
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}

export default AdminDashboard;

 