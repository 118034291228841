import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import AccountBoxOutlinedIcon from '@mui/icons-material/AccountBoxOutlined';
import EventAvailableOutlinedIcon from '@mui/icons-material/EventAvailableOutlined';
import React from 'react';

export const NAV_ITEMS = [
  { 
    icon: <HomeOutlinedIcon />, 
    text: 'Dashboard', 
    path: '/employee' 
  },
  { 
    icon: <CalendarMonthOutlinedIcon />, 
    text: 'Schedule', 
    path: '/employee/schedule' 
  },
  { 
    icon: <AccessTimeOutlinedIcon />, 
    text: 'Timesheets', 
    path: '/employee/timesheets' 
  },
  { 
    icon: <EventAvailableOutlinedIcon />, 
    text: 'Availability', 
    path: '/employee/availability' 
  },
  { 
    icon: <AccountBoxOutlinedIcon />, 
    text: 'Profile', 
    path: '/employee/profile' 
  },
]; 