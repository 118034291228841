import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  Box, 
  Typography, 
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Chip,
  Stack,
  IconButton,
  Tooltip
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import ClearIcon from '@mui/icons-material/Clear';
import { getClientEstimates } from '../../services/api';

const Estimates = ({ client }) => {
  const navigate = useNavigate();
  const [estimates, setEstimates] = useState([]);
  const [loading, setLoading] = useState(true);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  useEffect(() => {
    const fetchEstimates = async () => {
      try {
        if (client?.id) {
          const data = await getClientEstimates(client.id);
          setEstimates(data);
        }
      } catch (error) {
        console.error('Error fetching estimates:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchEstimates();
  }, [client]);

  const handleClearDates = () => {
    setStartDate(null);
    setEndDate(null);
  };

  const filteredEstimates = estimates.filter(estimate => {
    const estimateDate = new Date(estimate.issue_date);
    
    if (startDate && endDate) {
      return estimateDate >= startDate && estimateDate <= endDate;
    }
    if (startDate) {
      return estimateDate >= startDate;
    }
    if (endDate) {
      return estimateDate <= endDate;
    }
    return true;
  });

  const calculateTotal = (items) => {
    if (!items || !Array.isArray(items)) return 0;
    return items.reduce((sum, item) => {
      const quantity = Number(item.quantity) || 0;
      const price = Number(item.unitPrice) || 0;
      return sum + (quantity * price);
    }, 0);
  };

  const getStatusColor = (status) => {
    switch (status?.toLowerCase()) {
      case 'draft':
        return '#9CA3AF';
      case 'pending_client':
        return '#F59E0B';
      case 'completed':
        return '#10B981';
      default:
        return '#9CA3AF';
    }
  };

  const formatStatus = (status) => {
    if (!status) return 'Draft';
    return status.split('_').map(word => 
      word.charAt(0).toUpperCase() + word.slice(1)
    ).join(' ');
  };

  const handleRowClick = (estimateId) => {
    navigate(`/admin/estimates/${estimateId}`);
  };

  return (
    <Box sx={{ p: 3, backgroundColor: 'var(--background-default)' }}>
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center',
        mb: 3 
      }}>
        <Typography variant="h6" sx={{ fontWeight: 600, color: 'var(--text-primary)' }}>
          Estimates
        </Typography>
        <Stack direction="row" spacing={2} alignItems="center">
          <DatePicker
            label="Start date"
            value={startDate}
            onChange={setStartDate}
            maxDate={endDate}
            slotProps={{
              textField: {
                size: "small",
                sx: { 
                  width: 170,
                  '& .MuiInputBase-root': {
                    color: 'var(--text-primary)',
                    backgroundColor: 'var(--background-paper)',
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'var(--divider)',
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'var(--primary-main)',
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'var(--primary-main)',
                    }
                  },
                  '& .MuiInputLabel-root': {
                    color: 'var(--text-secondary)',
                    '&.Mui-focused': {
                      color: 'var(--primary-main)',
                    }
                  },
                  '& .MuiIconButton-root': {
                    color: 'var(--text-primary)',
                  }
                }
              },
              popper: {
                sx: {
                  '& .MuiPaper-root': {
                    backgroundColor: 'var(--background-paper)',
                    color: 'var(--text-primary)',
                    '& .MuiPickersDay-root': {
                      color: 'var(--text-primary)',
                      '&:hover': {
                        backgroundColor: 'var(--hover-overlay)',
                      },
                      '&.Mui-selected': {
                        backgroundColor: 'var(--primary-main)',
                        color: 'var(--primary-contrast)',
                        '&:hover': {
                          backgroundColor: 'var(--primary-dark)',
                        }
                      }
                    }
                  }
                }
              }
            }}
          />
          <DatePicker
            label="End date"
            value={endDate}
            onChange={setEndDate}
            minDate={startDate}
            slotProps={{
              textField: {
                size: "small",
                sx: { 
                  width: 170,
                  '& .MuiInputBase-root': {
                    color: 'var(--text-primary)',
                    backgroundColor: 'var(--background-paper)',
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'var(--divider)',
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'var(--primary-main)',
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'var(--primary-main)',
                    }
                  },
                  '& .MuiInputLabel-root': {
                    color: 'var(--text-secondary)',
                    '&.Mui-focused': {
                      color: 'var(--primary-main)',
                    }
                  },
                  '& .MuiIconButton-root': {
                    color: 'var(--text-primary)',
                  }
                }
              },
              popper: {
                sx: {
                  '& .MuiPaper-root': {
                    backgroundColor: 'var(--background-paper)',
                    color: 'var(--text-primary)',
                    '& .MuiPickersDay-root': {
                      color: 'var(--text-primary)',
                      '&:hover': {
                        backgroundColor: 'var(--hover-overlay)',
                      },
                      '&.Mui-selected': {
                        backgroundColor: 'var(--primary-main)',
                        color: 'var(--primary-contrast)',
                        '&:hover': {
                          backgroundColor: 'var(--primary-dark)',
                        }
                      }
                    }
                  }
                }
              }
            }}
          />
          {(startDate || endDate) && (
            <Tooltip title="Clear dates">
              <IconButton 
                onClick={handleClearDates}
                size="small"
                sx={{ 
                  ml: 1,
                  bgcolor: 'var(--hover-overlay)',
                  '&:hover': {
                    bgcolor: 'var(--hover-overlay)'
                  }
                }}
              >
                <ClearIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          )}
        </Stack>
      </Box>

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ color: 'var(--text-primary)', borderBottom: '1px solid var(--divider)' }}>
                Estimate #
              </TableCell>
              <TableCell sx={{ color: 'var(--text-primary)', borderBottom: '1px solid var(--divider)' }}>
                Date
              </TableCell>
              <TableCell sx={{ color: 'var(--text-primary)', borderBottom: '1px solid var(--divider)' }}>
                Project
              </TableCell>
              <TableCell sx={{ color: 'var(--text-primary)', borderBottom: '1px solid var(--divider)' }} align="right">
                Amount
              </TableCell>
              <TableCell sx={{ color: 'var(--text-primary)', borderBottom: '1px solid var(--divider)' }}>
                Status
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={5} align="center">Loading...</TableCell>
              </TableRow>
            ) : filteredEstimates.length === 0 ? (
              <TableRow>
                <TableCell colSpan={5} align="center">No estimates found</TableCell>
              </TableRow>
            ) : (
              filteredEstimates.map((estimate) => (
                <TableRow 
                  key={estimate.id}
                  hover
                  onClick={() => handleRowClick(estimate.id)}
                  sx={{ 
                    '&:last-child td, &:last-child th': { border: 0 },
                    cursor: 'pointer',
                    '&:hover': {
                      backgroundColor: 'var(--hover-overlay)'
                    },
                    '& .MuiTableCell-root': {
                      color: 'var(--text-primary)',
                      borderBottom: '1px solid var(--divider)'
                    }
                  }}
                >
                  <TableCell>{estimate.estimate_number}</TableCell>
                  <TableCell>
                    {estimate.issue_date ? new Date(estimate.issue_date).toLocaleDateString() : '-'}
                  </TableCell>
                  <TableCell>{estimate.project_name || '-'}</TableCell>
                  <TableCell align="right">
                    ${calculateTotal(estimate.items).toLocaleString('en-US', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2
                    })}
                  </TableCell>
                  <TableCell>
                    <Chip 
                      label={formatStatus(estimate.status)}
                      sx={{
                        backgroundColor: `${getStatusColor(estimate.status)}20`,
                        color: getStatusColor(estimate.status),
                        fontWeight: 500,
                        fontSize: '0.75rem'
                      }}
                    />
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default Estimates; 