import React, { useState, useEffect } from 'react';
import { Paper, Typography, CircularProgress, Box } from '@mui/material';
import { Map, Marker } from 'pigeon-maps';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import styles from './PropertyMap.module.scss';
import { getCoordinatesFromAddress } from '../services/api';

const PropertyMap = ({ address }) => {
  const [coordinates, setCoordinates] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCoordinates = async () => {
      if (!address) {
        setLoading(false);
        return;
      }

      try {
        setLoading(true);
        const coords = await getCoordinatesFromAddress(address);
        setCoordinates({
          lat: coords[0],
          lng: coords[1]
        });
      } catch (err) {
        console.error('Error fetching coordinates:', err);
        setError('Failed to load map location');
      } finally {
        setLoading(false);
      }
    };

    fetchCoordinates();
  }, [address]);

  const containerStyles = {
    height: '200px',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '16px',
    overflow: 'hidden'
  };

  const mapStyles = {
    flex: 1,
    overflow: 'hidden',
    borderRadius: '16px',
    pointerEvents: 'none' // Disable all mouse interactions
  };

  if (loading) {
    return (
      <Paper sx={containerStyles}>
        <Box sx={{ p: 2, display: 'flex', alignItems: 'center', gap: 1 }}>
          <CircularProgress size={24} />
          <Typography variant="body2" color="textSecondary">
            Loading map...
          </Typography>
        </Box>
      </Paper>
    );
  }

  if (error || !coordinates) {
    return (
      <Paper sx={containerStyles}>
        <Box sx={{ p: 2, display: 'flex', alignItems: 'center', gap: 1 }}>
          <LocationOnIcon color="error" />
          <Typography color="error" variant="body2">
            {error || 'Unable to load map location'}
          </Typography>
        </Box>
      </Paper>
    );
  }

  return (
    <Paper sx={containerStyles}>
      <Map
        height={300}
        defaultCenter={[coordinates.lat, coordinates.lng]}
        defaultZoom={15}
        attribution={false}
        style={mapStyles}
        onClick={() => {}} // Disable click events
        onBoundsChanged={() => {}} // Disable pan/zoom
        twoFingerDrag={false} // Disable two finger drag on mobile
        zoomSnap={false} // Disable zoom snapping
        mouseEvents={false} // Disable mouse events
        touchEvents={false} // Disable touch events
      >
        <Marker 
          width={32}
          color="#2196f3"
          anchor={[coordinates.lat, coordinates.lng]} 
        />
      </Map>
    </Paper>
  );
};

export default PropertyMap; 