import React, { useState } from 'react';
import {
  Box,
  Grid,
  Typography,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Pause as PauseIcon,
  PlayArrow as PlayIcon,
  ContentCopy as DuplicateIcon,
} from '@mui/icons-material';
import CampaignCard from './CampaignCard';
import CampaignCreate from './CampaignCreate';
import CampaignAnalytics from './CampaignAnalytics';
import CampaignTemplates from './CampaignTemplates';
import CampaignNavigation from './CampaignNavigation';
import CampaignReports from './CampaignReports';
import CampaignAudiences from './CampaignAudiences';
import CampaignPerformance from './CampaignPerformance';
import CampaignAssets from './CampaignAssets';
import CampaignSettings from './CampaignSettings';

const CampaignsOverview = () => {
  const [view, setView] = useState('overview');
  const [selectedCampaign, setSelectedCampaign] = useState(null);
  const [menuAnchor, setMenuAnchor] = useState(null);
  const [showCreateDialog, setShowCreateDialog] = useState(false);

  const campaigns = [
    {
      id: 1,
      name: 'Spring Promotion',
      type: 'email',
      description: 'Email campaign for spring season deals',
      status: 'Active',
      audience: 1500,
      progress: 65,
      startDate: '2024-03-01',
      endDate: '2024-03-31'
    },
    {
      id: 2,
      name: 'Follow-up Sequence',
      type: 'whatsapp',
      description: 'WhatsApp follow-up for recent leads',
      status: 'Scheduled',
      audience: 750,
      progress: 0,
      startDate: '2024-04-01',
      endDate: '2024-04-15'
    },
    {
      id: 3,
      name: 'Customer Survey',
      type: 'sms',
      description: 'SMS survey for customer feedback',
      status: 'Draft',
      audience: 2000,
      progress: 0,
      startDate: 'Not set',
      endDate: 'Not set'
    },
    {
      id: 4,
      name: 'Sales Outreach',
      type: 'call',
      description: 'Cold calling campaign for new leads',
      status: 'Active',
      audience: 500,
      progress: 30,
      startDate: '2024-03-15',
      endDate: '2024-04-15'
    }
  ];

  const handleMenuClick = (event, campaign) => {
    setMenuAnchor(event.currentTarget);
    setSelectedCampaign(campaign);
  };

  const handleMenuClose = () => {
    setMenuAnchor(null);
    setSelectedCampaign(null);
  };

  const handleCampaignAction = (action) => {
    console.log(`${action} campaign:`, selectedCampaign);
    handleMenuClose();
  };

  const renderContent = () => {
    switch (view) {
      case 'overview':
        return (
          <Grid container spacing={3}>
            {campaigns.map(campaign => (
              <Grid item xs={12} md={6} lg={4} key={campaign.id}>
                <CampaignCard 
                  campaign={campaign} 
                  onMenuClick={handleMenuClick}
                />
              </Grid>
            ))}
          </Grid>
        );
      case 'templates':
        return <CampaignTemplates />;
      case 'analytics':
        return <CampaignAnalytics campaignId={selectedCampaign?.id} />;
      case 'audiences':
        return <CampaignAudiences />;
      case 'reports':
        return <CampaignReports />;
      case 'performance':
        return <CampaignPerformance />;
      case 'assets':
        return <CampaignAssets />;
      case 'settings':
        return <CampaignSettings />;
      default:
        return null;
    }
  };

  return (
    <Box sx={{ p: 3 }}>
      {/* Header Section */}
      <Box sx={{ 
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        mb: 4
      }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <Typography variant="h5" sx={{ fontWeight: 600 }}>
            Marketing Campaigns
          </Typography>
        </Box>
        <CampaignNavigation
          currentView={view}
          onViewChange={setView}
          onCreateClick={() => setShowCreateDialog(true)}
        />
      </Box>

      {/* Content Section */}
      {renderContent()}

      {/* Campaign Actions Menu */}
      <Menu
        anchorEl={menuAnchor}
        open={Boolean(menuAnchor)}
        onClose={handleMenuClose}
        PaperProps={{
          sx: { width: 200, borderRadius: 2 }
        }}
      >
        <MenuItem onClick={() => handleCampaignAction('edit')}>
          <ListItemIcon>
            <EditIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Edit</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => handleCampaignAction('duplicate')}>
          <ListItemIcon>
            <DuplicateIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Duplicate</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => handleCampaignAction(selectedCampaign?.status === 'Active' ? 'pause' : 'resume')}>
          <ListItemIcon>
            {selectedCampaign?.status === 'Active' ? 
              <PauseIcon fontSize="small" /> : 
              <PlayIcon fontSize="small" />
            }
          </ListItemIcon>
          <ListItemText>
            {selectedCampaign?.status === 'Active' ? 'Pause' : 'Resume'}
          </ListItemText>
        </MenuItem>
        <MenuItem 
          onClick={() => handleCampaignAction('delete')}
          sx={{ color: 'error.main' }}
        >
          <ListItemIcon>
            <DeleteIcon fontSize="small" color="error" />
          </ListItemIcon>
          <ListItemText>Delete</ListItemText>
        </MenuItem>
      </Menu>

      {/* Create Campaign Dialog */}
      <CampaignCreate
        open={showCreateDialog}
        onClose={() => setShowCreateDialog(false)}
      />
    </Box>
  );
};

export default CampaignsOverview; 