import React from 'react';
import { Box, Typography } from '@mui/material';
import ClientCalendar from './ClientCalendar';
import styles from './Overview.module.scss';

const Overview = ({ client }) => {
  return (
    <Box className={styles.overviewContainer} sx={{ 
      backgroundColor: 'var(--background-default)',
      height: '100%',
      p: 3
    }}>
      <Box className={styles.scheduleSection} sx={{
        backgroundColor: 'var(--background-default)',
        borderRadius: '8px',
        height: '100%'
      }}>
        <Typography 
          className={styles.sectionTitle}
          sx={{ 
            color: 'var(--text-primary)',
            fontWeight: 500,
            mb: 2
          }}
        >
          Schedule
        </Typography>
        <Box className={styles.calendarWrapper} sx={{
          height: 'calc(100% - 40px)',
          backgroundColor: 'var(--background-default)'
        }}>
          <ClientCalendar clientId={client?.id} />
        </Box>
      </Box>
    </Box>
  );
};

export default Overview; 