import React, { useState, useEffect, useCallback } from 'react';
import {
  Typography, Grid, TextField, Button, Box,
  Autocomplete, CircularProgress, Alert,
  MenuItem, Dialog, DialogTitle, DialogContent,
  DialogActions, IconButton, FormControlLabel, Switch
} from '@mui/material';

// 2. Service and utility imports
import { getClients, createClient, searchClients } from '../../services/api';
import debounce from 'lodash/debounce';
import { test } from './test';
import MyLocationIcon from '@mui/icons-material/MyLocation';

// 3. All interfaces (unchanged)
interface Props {
  onClientSelect: (client: Client) => void;
  onClientCreate: (client: Client) => void;
  styles: {
    paper: string;
    sectionTitle: string;
    formSection: string;
    inputUnderline: string;
  };
  selectedClient: Client | null;
  initialData: {
    name: string;
    company: string;
    email: string;
    phone: string;
    address: string;
    city: string;
    state: string;
    zipCode: string;
    notes: string;
    isNewClient: boolean;
  };
}

interface AddressSuggestion {
  formatted_address: {
    street_number: string;
    street_name: string;
    city: string;
    state: string;
    zip: string;
  };
}

interface NominatimAddress {
  house_number?: string;
  road?: string;
  city?: string;
  town?: string;
  village?: string;
  state?: string;
  postcode?: string;
  [key: string]: string | undefined;
}

interface NominatimResult {
  display_name: string;
  address: NominatimAddress;
  [key: string]: any;
}

interface ClientFormData {
  id: number;
  name: string;
  company: string;
  email: string;
  phone: string;
  address: string;
  city: string;
  state: string;
  zipCode: string;
  notes: string;
  created_at: string;
  isNewClient: boolean;
}

interface Client {
  id: number;
  name: string;
  company: string;
  email: string;
  phone: string;
  address: string;
  city: string;
  state: string;
  zipCode: string;
  zip?: string;
  notes: string;
  isNewClient: boolean;
  created_at?: string;
}

interface AutocompleteOption extends Client {
  address?: {
    house_number?: string;
    road?: string;
    city?: string;
    town?: string;
    village?: string;
    state?: string;
    postcode?: string;
  };
}

const US_STATES = [
  ['Alabama', 'AL'], ['Alaska', 'AK'], ['Arizona', 'AZ'], ['Arkansas', 'AR'],
  ['California', 'CA'], ['Colorado', 'CO'], ['Connecticut', 'CT'], ['Delaware', 'DE'],
  ['Florida', 'FL'], ['Georgia', 'GA'], ['Hawaii', 'HI'], ['Idaho', 'ID'],
  ['Illinois', 'IL'], ['Indiana', 'IN'], ['Iowa', 'IA'], ['Kansas', 'KS'],
  ['Kentucky', 'KY'], ['Louisiana', 'LA'], ['Maine', 'ME'], ['Maryland', 'MD'],
  ['Massachusetts', 'MA'], ['Michigan', 'MI'], ['Minnesota', 'MN'], ['Mississippi', 'MS'],
  ['Missouri', 'MO'], ['Montana', 'MT'], ['Nebraska', 'NE'], ['Nevada', 'NV'],
  ['New Hampshire', 'NH'], ['New Jersey', 'NJ'], ['New Mexico', 'NM'], ['New York', 'NY'],
  ['North Carolina', 'NC'], ['North Dakota', 'ND'], ['Ohio', 'OH'], ['Oklahoma', 'OK'],
  ['Oregon', 'OR'], ['Pennsylvania', 'PA'], ['Rhode Island', 'RI'], ['South Carolina', 'SC'],
  ['South Dakota', 'SD'], ['Tennessee', 'TN'], ['Texas', 'TX'], ['Utah', 'UT'],
  ['Vermont', 'VT'], ['Virginia', 'VA'], ['Washington', 'WA'], ['West Virginia', 'WV'],
  ['Wisconsin', 'WI'], ['Wyoming', 'WY']
] as const;

const CACHE_KEY = 'clientsCache';
const CACHE_DURATION = 5 * 60 * 1000; // 5 minutes in milliseconds

const getCache = () => {
  const cached = localStorage.getItem(CACHE_KEY);
  if (!cached) return null;
  
  const { data, timestamp } = JSON.parse(cached);
  if (Date.now() - timestamp > CACHE_DURATION) {
    localStorage.removeItem(CACHE_KEY);
    return null;
  }
  return data;
};

const setCache = (data: Client[]) => {
  localStorage.setItem(CACHE_KEY, JSON.stringify({
    data,
    timestamp: Date.now()
  }));
};

// Helper function stays outside component
const createCompleteFormData = (partialData: Partial<Client>): ClientFormData => {
  return {
    id: partialData.id || 0,
    name: partialData.name || '',
    company: partialData.company || '',
    email: partialData.email || '',
    phone: partialData.phone || '',
    address: partialData.address || '',
    city: partialData.city || '',
    state: partialData.state || '',
    zipCode: partialData.zipCode || '',
    notes: partialData.notes || '',
    created_at: partialData.created_at || new Date().toISOString(),
    isNewClient: partialData.id === 0 || partialData.isNewClient === true
  };
};

const parseAddress = (fullAddress: string) => {
  console.log('Parsing address:', fullAddress);
  if (!fullAddress) return { streetAddress: '', city: '', state: '', zip: '' };

  // Remove any extra spaces and split by commas
  const parts = fullAddress.split(',').map(part => part.trim());
  console.log('Address parts:', parts);
  
  let streetAddress = parts[0] || '';
  let city = '';
  let state = '';
  let zip = '';

  // Handle different address formats
  if (parts.length > 1) {
    city = parts[1] || '';
  }
  
  if (parts.length > 2) {
    const lastPart = parts[parts.length - 1].trim();
    console.log('Processing last part:', lastPart);
    
    // Try to match state and zip pattern (e.g., "CA 12345" or "CA 12345-6789")
    const stateZipMatch = lastPart.match(/([A-Z]{2})\s+(\d{5}(?:-\d{4})?)/i);
    console.log('State/ZIP match:', stateZipMatch);
    
    if (stateZipMatch) {
      state = stateZipMatch[1];
      zip = stateZipMatch[2];
    } else {
      // If no match, split by space and try to identify state and zip
      const pieces = lastPart.split(/\s+/);
      console.log('Last part pieces:', pieces);
      
      // Look for state first (2 letter code)
      const stateMatch = pieces.find(piece => 
        US_STATES.some(([_, abbr]) => abbr === piece.toUpperCase())
      );
      
      if (stateMatch) {
        state = stateMatch.toUpperCase();
        // Look for zip after state
        const zipMatch = pieces.find(piece => /^\d{5}(-\d{4})?$/.test(piece));
        if (zipMatch) zip = zipMatch;
      } else {
        // Try to find state by full name
        const statePiece = pieces.find(piece => 
          US_STATES.some(([name, _]) => 
            name.toLowerCase() === piece.toLowerCase()
          )
        );
        
        if (statePiece) {
          const stateEntry = US_STATES.find(([name, _]) => 
            name.toLowerCase() === statePiece.toLowerCase()
          );
          if (stateEntry) state = stateEntry[1];
        }
      }
    }
  }

  console.log('Found state:', state);
  console.log('Found zip:', zip);

  const result = { 
    streetAddress: streetAddress.trim(),
    city: city.trim(),
    state: state.trim(),
    zip: zip.trim()
  };
  
  console.log('Final parsed address:', result);
  return result;
};

const ClientEstimateForm: React.FC<Props> = ({ onClientSelect, onClientCreate, styles, selectedClient, initialData }) => {
  // 1. Client Management States
  const [clients, setClients] = useState<Client[]>([]);
  const [searchResults, setSearchResults] = useState<Client[]>([]);
  const [searchInput, setSearchInput] = useState('');
  const [manualClientInput, setManualClientInput] = useState('');
  const [isNewClient, setIsNewClient] = useState(true);
  const [newClient, setNewClient] = useState<ClientFormData>(() => 
    createCompleteFormData({
      id: 0,
      name: '',
      company: '',
      email: '',
      phone: '',
      address: '',
      city: '',
      state: '',
      zipCode: '',
      notes: '',
      created_at: new Date().toISOString(),
      isNewClient: true
    })
  );

  // 2. Address Management States
  const [addressSuggestions, setAddressSuggestions] = useState<NominatimResult[]>([]);
  const [displayAddress, setDisplayAddress] = useState('');
  const [formattedAddress, setFormattedAddress] = useState('');
  const [isUsingSelectedAddress, setIsUsingSelectedAddress] = useState(false);

  // 3. UI States
  const [loading, setLoading] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [isLoadingAddress, setIsLoadingAddress] = useState(false);
  const [locationLoading, setLocationLoading] = useState(false);
  const [error, setError] = useState('');
  const [validationErrors, setValidationErrors] = useState<{[key: string]: string}>({});

  // 1. Initial Client Data Loading
  useEffect(() => {
    const fetchClients = async () => {
      setLoading(true);
      try {
        const cachedData = getCache();
        if (cachedData) {
          setClients(cachedData);
          setLoading(false);
          return;
        }

        const response = await getClients();
        const clientsData = response.clients || response.data || [];
        const formattedClients = clientsData.map((client: any) => ({
          id: client.id,
          name: client.name || '',
          company: client.company || '',
          email: client.email || '',
          phone: formatPhoneNumber(client.phone || ''),
          address: client.address || '',
          city: client.city || '',
          state: client.state || '',
          zipCode: client.zipCode || '',
          notes: client.notes || '',
          created_at: client.created_at || new Date().toISOString(),
          isNewClient: false
        }));
        
        setClients(formattedClients);
        setCache(formattedClients);
      } catch (err) {
        setError(err instanceof Error ? err.message : 'Failed to fetch clients');
      } finally {
        setLoading(false);
      }
    };
    fetchClients();
  }, []);

  // 2. Selected Client Updates
  useEffect(() => {
    if (selectedClient) {
      setNewClient(prev => ({
        ...prev,
        id: selectedClient.id,
        name: selectedClient.name || '',
        company: selectedClient.company || '',
        email: selectedClient.email || '',
        phone: selectedClient.phone || '',
        address: selectedClient.address || '',
        city: selectedClient.city || '',
        state: selectedClient.state || '',
        zipCode: selectedClient.zipCode || selectedClient.zip || '',
        notes: selectedClient.notes || '',
        isNewClient: false
      }));

      setSearchInput(selectedClient.name || '');

      const address = [
        selectedClient.address,
        selectedClient.city,
        selectedClient.state,
        selectedClient.zipCode || selectedClient.zip
      ].filter(Boolean).join(', ');
      
      setDisplayAddress(selectedClient.address || '');
      setFormattedAddress(address);
    }
  }, [selectedClient]);

  // 3. Debug Logging
  useEffect(() => {
    console.log('Current Form State:', {
      newClient,
      displayAddress,
      validationErrors
    });
  }, [newClient, displayAddress, validationErrors]);

  // 1. Client Selection and Search
  const handleClientSelect = async (client) => {
    if (!client) return;
    console.log('Initial client data:', client);

    setSearchInput(client.name);
    
    const formData = {
      ...createCompleteFormData(client),
      id: client.id,
      name: client.name,
      company: client.company || '',
      email: client.email || '',
      phone: client.phone || '',
      address: client.address || '',
      city: client.city || '',
      state: client.state || '',
      zipCode: client.zipCode || client.zip || '',
      notes: client.notes || '',
      isNewClient: false
    };

    console.log('Client state before parsing:', client.state);
    
    if (client.address && (!client.city || !client.state || !client.zipCode)) {
      const addressParts = parseAddress(client.address);
      console.log('Parsed address parts:', addressParts);
      
      formData.state = client.state || addressParts.state || formData.state;
      formData.zipCode = client.zipCode || client.zip || addressParts.zipCode || '';
    }

    console.log('Final form data:', formData);
    setNewClient(formData);
    setIsNewClient(false);
    onClientSelect(formData);
  };

  // 2. Form Field Updates
  const handleFormChange = (field: string, value: string) => {
    const updatedClient = {
      ...newClient,
      [field]: value,
      isNewClient: true
    };
    
    setNewClient(updatedClient);

    onClientSelect({
      ...updatedClient,
      customer_details: {
        name: updatedClient.name || '',
        company: updatedClient.company || '',
        email: updatedClient.email || '',
        phone: updatedClient.phone || '',
        address: updatedClient.address || '',
        city: updatedClient.city || '',
        state: updatedClient.state || '',
        zip: updatedClient.zipCode || '',
        notes: updatedClient.notes || '',
        isNewClient: true
      },
      service_address: updatedClient.address || '',
      service_city: updatedClient.city || '',
      service_state: updatedClient.state || '',
      service_zip: updatedClient.zipCode || ''
    });
  };

  // 1. Address formatting utility
  const formatAddressData = (rawAddress) => {
    console.log('Formatting address data:', rawAddress);
    return {
      streetAddress: `${rawAddress.house_number || ''} ${rawAddress.road || ''}`.trim(),
      city: rawAddress.city || rawAddress.town || rawAddress.municipality || '',
      state: rawAddress.state || '',
      zipCode: rawAddress.postcode || ''
    };
  };

  // 2. Address Search and Selection
  const fetchAddressSuggestions = async (input: string) => {
    if (!input || input.length < 3) {
      setAddressSuggestions([]);
      return;
    }
    
    setIsLoadingAddress(true);
    try {
      console.log('Fetching address for input:', input);
      const response = await fetch(
        `https://nominatim.openstreetmap.org/search?format=json&q=${encodeURIComponent(input)}&countrycodes=us&limit=5&addressdetails=1`,
        {
          headers: {
            'Accept-Language': 'en-US',
            'User-Agent': 'EverProCleaning_EstimateSystem/1.0',
            'Referer': 'https://teams.everprocleaning.com'
          }
        }
      );
      
      if (!response.ok) throw new Error('Address search failed');
      const data = await response.json();
      console.log('Raw API response:', data);
      
      const formattedAddresses = data.map(item => ({
        display_name: [
          item.address?.house_number,
          item.address?.road,
          item.address?.city || item.address?.town || item.address?.municipality,
          item.address?.state,
          item.address?.postcode
        ].filter(Boolean).join(' '),
        address: item.address
      }));

      console.log('Formatted addresses:', formattedAddresses);
      setAddressSuggestions(formattedAddresses);
    } catch (error) {
      console.error('Error fetching addresses:', error);
      setAddressSuggestions([]);
    } finally {
      setIsLoadingAddress(false);
    }
  };

  const debouncedSearchAddress = debounce(fetchAddressSuggestions, 300);

  const handleAddressSelect = (selectedAddress) => {
    console.log('Raw selected address:', selectedAddress);
    
    const address = selectedAddress.address || {};
    console.log('Address components:', address);

    const formattedAddress = {
      address: [address.house_number, address.road].filter(Boolean).join(' '),
      city: address.city || address.town || address.municipality || '',
      state: address.state || '',
      zipCode: address.postcode || ''
    };

    console.log('Formatted address:', formattedAddress);

    const updatedClient = {
      ...newClient,
      address: formattedAddress.address,
      city: formattedAddress.city,
      state: formattedAddress.state,
      zipCode: formattedAddress.zipCode,
      isNewClient: true
    };
    
    setNewClient(updatedClient);
    setDisplayAddress(formattedAddress.address);
    setIsUsingSelectedAddress(false);

    onClientSelect({
      ...updatedClient,
      customer_details: {
        ...updatedClient,
        address: formattedAddress.address,
        city: formattedAddress.city,
        state: formattedAddress.state,
        zip: formattedAddress.zipCode
      }
    });
  };

  // 3. Utility Functions
  const formatPhoneNumber = (value: string) => {
    const phone = value.replace(/\D/g, '');
    if (phone.length < 4) return phone;
    if (phone.length < 7) return `(${phone.slice(0, 3)}) ${phone.slice(3)}`;
    return `(${phone.slice(0, 3)}) ${phone.slice(3, 6)}-${phone.slice(6, 10)}`;
  };

  const getCurrentLocation = async () => {
    setLocationLoading(true);
    try {
      const position = await new Promise<GeolocationPosition>((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject);
      });

      const { latitude, longitude } = position.coords;
      const response = await fetch(
        `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}&addressdetails=1`,
        {
          headers: {
            'Accept': 'application/json'
          }
        }
      );
      
      const data = await response.json();
      handleAddressSelect(data);
    } catch (error) {
      setError('Failed to get current location');
    } finally {
      setLocationLoading(false);
    }
  };

  // 1. Validation Functions
  const validateZipCode = (zipCode: string) => {
    return /^\d{5}(-\d{4})?$/.test(zipCode);
  };

  const validateForm = () => {
    const errors: {[key: string]: string} = {};
    
    if (!newClient.name.trim()) errors.name = 'Name is required';
    if (!newClient.email.trim()) errors.email = 'Email is required';
    if (newClient.email && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(newClient.email)) {
      errors.email = 'Invalid email format';
    }
    if (!newClient.phone.trim()) errors.phone = 'Phone is required';
    if (newClient.phone && newClient.phone.replace(/\D/g, '').length !== 10) {
      errors.phone = 'Invalid phone number';
    }
    if (newClient.zipCode && !validateZipCode(newClient.zipCode)) {
      errors.zipCode = 'Invalid ZIP code';
    }
    
    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  // 2. Search and Create Utility
  const searchAndCreateClient = async (searchValue: string) => {
    setIsSearching(true);
    try {
      if (searchValue.length >= 2) {
        const results = await searchClients(searchValue);
        setSearchResults(results);
      } else {
        setSearchResults([]);
      }
    } catch (error) {
      console.error('Error searching clients:', error);
    } finally {
      setIsSearching(false);
    }
  };

  // 3. Style Definitions
  const commonStyles = {
    height: '45px',
    width: '100%',
    fontSize: '0.95rem'
  };

  const inputStyle = {
    ...commonStyles,
    border: '1px solid #ccc',
    borderRadius: '4px',
    padding: '8px',
    fontFamily: 'inherit',
    '&:hover': {
      borderColor: '#666'
    }
  };

  const buttonStyle = {
    ...commonStyles,
    textTransform: 'none',
    boxShadow: '0 1px 3px rgba(0,0,0,0.1)',
  };

  const locationIcon = (
    <IconButton size="small" onClick={getCurrentLocation} disabled={locationLoading}>
      <MyLocationIcon aria-label="Get current location" />
    </IconButton>
  );

  // 3. Client Creation
  const handleCreateClient = async () => {
    if (!validateForm()) return;
    
    setLoading(true);
    setError('');
    
    try {
      const phoneNumber = newClient.phone.replace(/\D/g, '');
      
      const clientData = {
        name: newClient.name.trim(),
        company: newClient.company.trim(),
        email: newClient.email.trim() || `temp_${Date.now()}@placeholder.com`,
        phone: phoneNumber,
        address: `${newClient.address.trim()}, ${newClient.city.trim()}, ${newClient.state.trim()} ${newClient.zipCode.trim()}`,
        city: newClient.city.trim(),
        state: newClient.state.trim(),
        zipCode: newClient.zipCode.trim(),
        notes: newClient.notes.trim(),
        isNewClient: true,
        service_address: newClient.address.trim(),
        service_city: newClient.city.trim(),
        service_state: newClient.state.trim(),
        service_zip: newClient.zipCode.trim()
      };

      console.log('Creating new client with data:', clientData);
      const response = await createClient(clientData);
      
      if (response && response.data) {
        const createdClient = {
          ...response.data,
          company: response.data.company_name || response.data.company,
          address: response.data.street_address || response.data.address,
          city: response.data.city_name || response.data.city,
          zipCode: response.data.zip_code || response.data.zipCode,
          isNewClient: false
        };

        const updatedClients = [...clients, createdClient];
        setClients(updatedClients);
        setCache(updatedClients);

        onClientCreate(createdClient);
        onClientSelect(createdClient);

        setNewClient({
          id: 0,
          name: '',
          company: '',
          email: '',
          phone: '',
          address: '',
          city: '',
          state: '',
          zipCode: '',
          notes: '',
          created_at: new Date().toISOString(),
          isNewClient: true
        });
      }
    } catch (err) {
      console.error('Error creating client:', err);
      setError(err.response?.data?.message || 'Failed to create client');
    } finally {
      setLoading(false);
    }
  };

  // Render Component
  return (
    <Box className={styles.formSection}>
      <Typography variant="h6" className={styles.sectionTitle}>
        Client Information
      </Typography>

      {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}

      <Grid container spacing={3}>
        {/* Client Search/Create Field */}
        <Grid item xs={12}>
          <Autocomplete
            fullWidth
            options={clients}
            loading={loading}
            value={clients.find(c => c.id === newClient.id) || null}
            inputValue={searchInput}
            freeSolo
            getOptionLabel={(option) => {
              if (typeof option === 'string') return option;
              return option.name || '';
            }}
            onInputChange={(_, newValue) => {
              console.log('Input changed to:', newValue);
              setSearchInput(newValue);
              handleFormChange('name', newValue);
            }}
            onChange={(event, selectedOption) => {
              if (selectedOption && typeof selectedOption === 'object' && 'id' in selectedOption) {
                console.log('Selected client in dropdown:', selectedOption);
                const client = selectedOption as Client;
                setSearchInput(client.name);
                setManualClientInput(client.name);
                handleClientSelect(client);
              } else if (typeof selectedOption === 'string') {
                setSearchInput(selectedOption);
                setManualClientInput(selectedOption);
                handleFormChange('name', selectedOption);
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Search or create new client"
                error={!!validationErrors.name}
                helperText={validationErrors.name}
                InputProps={{
                  ...params.InputProps,
                  style: inputStyle
                }}
              />
            )}
            renderOption={(props, option) => {
              if (typeof option === 'string') return <li {...props}>{option}</li>;
              return (
                <li {...props}>
                  <div style={{ padding: '4px 0' }}>
                    <div style={{ fontWeight: 'bold' }}>{option.name}</div>
                    {option.company && <div style={{ fontSize: '0.8em', color: '#666' }}>{option.company}</div>}
                    {option.email && <div style={{ fontSize: '0.8em', color: '#666' }}>{option.email}</div>}
                    {option.phone && <div style={{ fontSize: '0.8em', color: '#666' }}>{option.phone}</div>}
                    {option.address && <div style={{ fontSize: '0.8em', color: '#666' }}>{option.address}</div>}
                  </div>
                </li>
              );
            }}
          />
        </Grid>

        {/* Company Field */}
        <Grid item xs={12}>
          <input
            type="text"
            value={newClient.company}
            onChange={(e) => handleFormChange('company', e.target.value)}
            placeholder="Company"
            style={inputStyle}
          />
        </Grid>

        {/* Email and Phone Fields */}
        <Grid item xs={12} md={6}>
          <input
            type="email"
            value={newClient.email}
            onChange={(e) => handleFormChange('email', e.target.value)}
            placeholder="Email"
            style={inputStyle}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <input
            type="tel"
            value={newClient.phone}
            onChange={(e) => {
              const formatted = formatPhoneNumber(e.target.value);
              handleFormChange('phone', formatted);
            }}
            placeholder="Phone"
            style={inputStyle}
          />
        </Grid>

        {/* Address Autocomplete */}
        <Grid item xs={12}>
          <Autocomplete
            fullWidth
            options={addressSuggestions}
            loading={isLoadingAddress}
            value={null}
            inputValue={displayAddress}
            freeSolo
            getOptionLabel={(option) => {
              if (typeof option === 'string') return option;
              return option.display_name || '';
            }}
            onInputChange={(_, value) => {
              setDisplayAddress(value);
              if (!isUsingSelectedAddress) {
                handleFormChange('address', value);
                debouncedSearchAddress(value);
              }
            }}
            onChange={(_, value) => {
              setIsUsingSelectedAddress(true);
              if (value && typeof value !== 'string') {
                handleAddressSelect(value);
              }
              setTimeout(() => setIsUsingSelectedAddress(false), 100);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Street Address"
                InputProps={{
                  ...params.InputProps,
                  style: inputStyle,
                  endAdornment: (
                    <>
                      {isLoadingAddress && <CircularProgress size={20} />}
                      {params.InputProps.endAdornment}
                      {locationIcon}
                    </>
                  )
                }}
              />
            )}
          />
        </Grid>

        {/* City, State, ZIP Fields */}
        <Grid item xs={12} md={4}>
          <input
            type="text"
            value={newClient.city}
            onChange={(e) => handleFormChange('city', e.target.value)}
            placeholder="City"
            style={inputStyle}
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <input
            type="text"
            value={newClient.state}
            onChange={(e) => handleFormChange('state', e.target.value)}
            placeholder="State"
            style={inputStyle}
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <input
            type="text"
            value={newClient.zipCode}
            onChange={(e) => handleFormChange('zipCode', e.target.value)}
            placeholder="ZIP Code"
            style={inputStyle}
          />
        </Grid>

        {/* Notes Field */}
        <Grid item xs={12}>
          <textarea
            value={newClient.notes}
            onChange={(e) => handleFormChange('notes', e.target.value)}
            placeholder="Notes"
            style={{
              ...inputStyle,
              minHeight: '45px',
              height: '45px',
              resize: 'vertical'
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default ClientEstimateForm;
