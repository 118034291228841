import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  Box, 
  Typography, 
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Chip,
  Stack,
  IconButton,
  Tooltip
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import ClearIcon from '@mui/icons-material/Clear';

const ClientDetailsProject = ({ client }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [projects, setProjects] = useState([]);
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  useEffect(() => {
    if (client && client.projects) {
      const formattedProjects = client.projects.map(project => {
        const totalValue = parseFloat(project.total_value || 0);
        const laborCost = parseFloat(project.total_labor_cost || 0);
        const totalHours = project.total_hours ? 
          (parseFloat(project.total_hours) / 3600).toFixed(2) : '0.00';

        console.log('Project Debug:', {
          projectId: project.id,
          projectName: project.name,
          rawTotalValue: project.total_value,
          rawLaborCost: project.total_labor_cost,
          rawTotalHours: project.total_hours,
          totalValue,
          laborCost,
          totalHours
        });

        return {
          ...project,
          totalValue,
          formattedValue: totalValue.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD'
          }),
          laborCost,
          formattedLaborCost: laborCost.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD'
          }),
          totalHours
        };
      });

      setProjects(formattedProjects);
      setFilteredProjects(formattedProjects);
      setLoading(false);
    }
  }, [client]);

  useEffect(() => {
    filterProjects();
  }, [startDate, endDate, projects]);

  const filterProjects = () => {
    let filtered = [...projects];

    if (startDate || endDate) {
      filtered = filtered.filter(project => {
        const projectDate = new Date(project.start_time || project.created_at);
        if (startDate && endDate) {
          return projectDate >= startDate && projectDate <= endDate;
        }
        if (startDate) {
          return projectDate >= startDate;
        }
        if (endDate) {
          return projectDate <= endDate;
        }
        return true;
      });
    }

    setFilteredProjects(filtered);
  };

  const handleClearDates = () => {
    setStartDate(null);
    setEndDate(null);
  };

  const getStatusColor = (status) => {
    switch (status?.toLowerCase()) {
      case 'pending_scheduling':
        return '#E65100';
      case 'scheduled':
        return '#1565C0';
      case 'in_progress':
        return '#2E7D32';
      case 'pending_invoicing':
        return '#7B1FA2';
      case 'completed':
        return '#303F9F';
      default:
        return '#9CA3AF';
    }
  };

  const formatStatus = (status) => {
    if (!status) return 'Pending Scheduling';
    return status.split('_').map(word => 
      word.charAt(0).toUpperCase() + word.slice(1)
    ).join(' ');
  };

  return (
    <Box sx={{ p: 3, backgroundColor: 'var(--background-default)' }}>
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center',
        mb: 3 
      }}>
        <Typography variant="h6" sx={{ fontWeight: 600, color: 'var(--text-primary)' }}>
          Projects
        </Typography>
        <Stack direction="row" spacing={2} alignItems="center">
          <DatePicker
            label="Start date"
            value={startDate}
            onChange={setStartDate}
            maxDate={endDate}
            slotProps={{
              textField: {
                size: "small",
                sx: { 
                  width: 170,
                  '& .MuiInputBase-root': {
                    color: 'var(--text-primary)',
                    backgroundColor: 'var(--background-paper)',
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'var(--divider)',
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'var(--primary-main)',
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'var(--primary-main)',
                    }
                  },
                  '& .MuiInputLabel-root': {
                    color: 'var(--text-secondary)',
                    '&.Mui-focused': {
                      color: 'var(--primary-main)',
                    }
                  },
                  '& .MuiIconButton-root': {
                    color: 'var(--text-primary)',
                  }
                },
                '& .MuiInputLabel-root': {
                  color: 'var(--text-secondary)',
                  '&.Mui-focused': {
                    color: 'var(--primary-main)',
                  }
                },
                '& .MuiIconButton-root': {
                  color: 'var(--text-primary)',
                }
              }
            }}
          />
          <DatePicker
            label="End date"
            value={endDate}
            onChange={setEndDate}
            minDate={startDate}
            slotProps={{
              textField: {
                size: "small",
                sx: { 
                  width: 170,
                  '& .MuiInputBase-root': {
                    color: 'var(--text-primary)',
                    backgroundColor: 'var(--background-paper)',
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'var(--divider)',
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'var(--primary-main)',
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'var(--primary-main)',
                    }
                  },
                  '& .MuiInputLabel-root': {
                    color: 'var(--text-secondary)',
                    '&.Mui-focused': {
                      color: 'var(--primary-main)',
                    }
                  },
                  '& .MuiIconButton-root': {
                    color: 'var(--text-primary)',
                  }
                }
              }
            }}
          />
          {(startDate || endDate) && (
            <Tooltip title="Clear dates">
              <IconButton 
                onClick={handleClearDates}
                size="small"
                sx={{ 
                  ml: 1,
                  bgcolor: 'var(--hover-overlay)',
                  '&:hover': {
                    bgcolor: 'var(--hover-overlay)'
                  }
                }}
              >
                <ClearIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          )}
        </Stack>
      </Box>

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {['Project Name', 'Status', 'Total Value', 'Labor Cost', 'Hours', 'Address'].map((header) => (
                <TableCell 
                  key={header} 
                  align={['Total Value', 'Labor Cost', 'Hours'].includes(header) ? 'right' : 'left'}
                  sx={{ 
                    color: 'var(--text-primary)',
                    borderBottom: '1px solid var(--divider)'
                  }}
                >
                  {header}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={6} align="center">Loading...</TableCell>
              </TableRow>
            ) : filteredProjects.length === 0 ? (
              <TableRow>
                <TableCell colSpan={6} align="center">No projects found</TableCell>
              </TableRow>
            ) : (
              filteredProjects.map((project) => (
                <TableRow 
                  key={project.id}
                  hover
                  onClick={() => navigate(`/admin/projects/${project.id}`)}
                  sx={{ 
                    '&:last-child td, &:last-child th': { border: 0 },
                    cursor: 'pointer',
                    '&:hover': {
                      backgroundColor: 'var(--hover-overlay)'
                    },
                    '& .MuiTableCell-root': {
                      color: 'var(--text-primary)',
                      borderBottom: '1px solid var(--divider)'
                    }
                  }}
                >
                  <TableCell>
                    <Typography variant="subtitle2">{project.name}</Typography>
                    {project.description && (
                      <Typography variant="body2" color="textSecondary">
                        {project.description}
                      </Typography>
                    )}
                  </TableCell>
                  <TableCell>
                    <Chip 
                      label={formatStatus(project.status)}
                      sx={{
                        backgroundColor: `${getStatusColor(project.status)}20`,
                        color: getStatusColor(project.status),
                        fontWeight: 500,
                        fontSize: '0.75rem'
                      }}
                    />
                  </TableCell>
                  <TableCell align="right">{project.formattedValue}</TableCell>
                  <TableCell align="right">{project.formattedLaborCost}</TableCell>
                  <TableCell align="right">{project.totalHours}</TableCell>
                  <TableCell>{project.address}</TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default ClientDetailsProject;