import React, { useState } from 'react';
import {
  Box,
  Typography,
  Button,
  Grid,
  Paper,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Chip,
  Alert,
} from '@mui/material';
import {
  Add,
  Upload,
  Folder,
  Description,
  Delete,
  Edit,
  Close,
} from '@mui/icons-material';

const AdminDocuments = () => {
  const [documents, setDocuments] = useState([]);
  const [openUploadDialog, setOpenUploadDialog] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [documentDetails, setDocumentDetails] = useState({
    title: '',
    description: '',
    category: '',
    requiredFields: [],
  });
  const [newField, setNewField] = useState('');

  const handleFileSelect = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleAddField = () => {
    if (newField.trim()) {
      setDocumentDetails(prev => ({
        ...prev,
        requiredFields: [...prev.requiredFields, newField.trim()]
      }));
      setNewField('');
    }
  };

  const handleRemoveField = (fieldToRemove) => {
    setDocumentDetails(prev => ({
      ...prev,
      requiredFields: prev.requiredFields.filter(field => field !== fieldToRemove)
    }));
  };

  const handleUpload = () => {
    // This will be implemented later with API functionality
    const newDocument = {
      id: Date.now(),
      ...documentDetails,
      fileName: selectedFile?.name,
      uploadDate: new Date().toISOString(),
      status: 'Active',
    };
    
    setDocuments(prev => [...prev, newDocument]);
    handleCloseUploadDialog();
  };

  const handleCloseUploadDialog = () => {
    setOpenUploadDialog(false);
    setSelectedFile(null);
    setDocumentDetails({
      title: '',
      description: '',
      category: '',
      requiredFields: [],
    });
  };

  return (
    <Box sx={{ p: 3 }}>
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center', 
        mb: 3 
      }}>
        <Typography variant="h4" sx={{ fontWeight: 600, fontFamily: 'Urbanist' }}>
          Document Management
        </Typography>
        <Button
          variant="contained"
          startIcon={<Add />}
          onClick={() => setOpenUploadDialog(true)}
          sx={{
            backgroundColor: 'var(--primary-main)',
            '&:hover': { backgroundColor: 'var(--primary-dark)' },
          }}
        >
          Upload Document
        </Button>
      </Box>

      <Grid container spacing={3}>
        {documents.map((doc) => (
          <Grid item xs={12} sm={6} md={4} key={doc.id}>
            <Paper
              elevation={0}
              sx={{
                p: 2,
                border: '1px solid var(--divider)',
                borderRadius: '8px',
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <Description sx={{ color: 'var(--primary-main)', mr: 1 }} />
                <Typography variant="h6" sx={{ flex: 1 }}>
                  {doc.title}
                </Typography>
                <IconButton size="small">
                  <Edit />
                </IconButton>
                <IconButton size="small" color="error">
                  <Delete />
                </IconButton>
              </Box>
              <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                {doc.description}
              </Typography>
              <Box sx={{ mb: 2 }}>
                <Typography variant="caption" color="text.secondary">
                  Required Fields:
                </Typography>
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mt: 1 }}>
                  {doc.requiredFields.map((field, index) => (
                    <Chip
                      key={index}
                      label={field}
                      size="small"
                      sx={{ backgroundColor: 'var(--background-paper)' }}
                    />
                  ))}
                </Box>
              </Box>
              <Typography variant="caption" color="text.secondary">
                Uploaded on {new Date(doc.uploadDate).toLocaleDateString()}
              </Typography>
            </Paper>
          </Grid>
        ))}
      </Grid>

      <Dialog
        open={openUploadDialog}
        onClose={handleCloseUploadDialog}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle sx={{ 
          borderBottom: '1px solid var(--divider)',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}>
          Upload New Document
          <IconButton onClick={handleCloseUploadDialog} size="small">
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ pt: 3 }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2.5 }}>
            <TextField
              label="Document Title"
              fullWidth
              value={documentDetails.title}
              onChange={(e) => setDocumentDetails(prev => ({ ...prev, title: e.target.value }))}
            />
            
            <TextField
              label="Description"
              fullWidth
              multiline
              rows={3}
              value={documentDetails.description}
              onChange={(e) => setDocumentDetails(prev => ({ ...prev, description: e.target.value }))}
            />

            <FormControl fullWidth>
              <InputLabel>Category</InputLabel>
              <Select
                value={documentDetails.category}
                label="Category"
                onChange={(e) => setDocumentDetails(prev => ({ ...prev, category: e.target.value }))}
              >
                <MenuItem value="contracts">Contracts</MenuItem>
                <MenuItem value="forms">Forms</MenuItem>
                <MenuItem value="templates">Templates</MenuItem>
                <MenuItem value="other">Other</MenuItem>
              </Select>
            </FormControl>

            <Box>
              <Typography variant="subtitle2" sx={{ mb: 1 }}>
                Required Fields
              </Typography>
              <Box sx={{ display: 'flex', gap: 1, mb: 2 }}>
                <TextField
                  size="small"
                  placeholder="Add required field"
                  value={newField}
                  onChange={(e) => setNewField(e.target.value)}
                  sx={{ flex: 1 }}
                />
                <Button
                  variant="outlined"
                  onClick={handleAddField}
                >
                  Add
                </Button>
              </Box>
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                {documentDetails.requiredFields.map((field, index) => (
                  <Chip
                    key={index}
                    label={field}
                    onDelete={() => handleRemoveField(field)}
                    size="small"
                  />
                ))}
              </Box>
            </Box>

            <Box sx={{ 
              border: '2px dashed var(--divider)',
              borderRadius: '8px',
              p: 3,
              textAlign: 'center'
            }}>
              <input
                type="file"
                id="document-upload"
                hidden
                onChange={handleFileSelect}
              />
              <label htmlFor="document-upload">
                <Button
                  component="span"
                  startIcon={<Upload />}
                  variant="outlined"
                >
                  Select File
                </Button>
              </label>
              {selectedFile && (
                <Typography variant="body2" sx={{ mt: 1 }}>
                  Selected: {selectedFile.name}
                </Typography>
              )}
            </Box>
          </Box>
        </DialogContent>
        <DialogActions sx={{ p: 2, borderTop: '1px solid var(--divider)' }}>
          <Button onClick={handleCloseUploadDialog}>Cancel</Button>
          <Button
            variant="contained"
            onClick={handleUpload}
            disabled={!selectedFile || !documentDetails.title}
          >
            Upload
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default AdminDocuments; 