import React, { useState } from 'react';
import {
  Box,
  Paper,
  Typography,
  TextField,
  Button,
  Grid,
  MenuItem,
  CircularProgress
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const AdminCreateLead = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [lead, setLead] = useState({
    name: '',
    email: '',
    phone: '',
    source: '',
    status: 'New',
    value: '',
    notes: ''
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    // TODO: Implement API call to create lead
    navigate('/admin/leads');
  };

  return (
    <Box sx={{ p: 3 }}>
      <Button
        startIcon={<ArrowBackIcon />}
        onClick={() => navigate('/admin/leads')}
        sx={{ mb: 3 }}
      >
        Back to Leads
      </Button>

      <Paper sx={{ p: 3, borderRadius: 2 }}>
        <Typography variant="h5" sx={{ mb: 3, fontFamily: 'Urbanist, sans-serif' }}>
          Create New Lead
        </Typography>
        <form onSubmit={handleSubmit}>
          {/* Form fields */}
          <Box sx={{ mt: 3 }}>
            <Button
              type="submit"
              variant="contained"
              disabled={loading}
              sx={{ mr: 2 }}
            >
              {loading ? <CircularProgress size={24} /> : 'Create Lead'}
            </Button>
          </Box>
        </form>
      </Paper>
    </Box>
  );
};

export default AdminCreateLead; 