import React from 'react';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import ContactsSidebar from './ContactsSidebar';
import ChatWindow from './ChatWindow';

const StyledContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2),
  height: '100%'
}));

const WhatsAppView = ({ selectedContact, setSelectedContact }) => {
  return (
    <StyledContainer>
      <ContactsSidebar 
        contacts={[]}
        selectedContact={selectedContact}
        setSelectedContact={setSelectedContact}
        communicationType="whatsapp"
      />
      <ChatWindow 
        selectedContact={selectedContact}
        communicationType="whatsapp"
      />
    </StyledContainer>
  );
};

export default WhatsAppView;