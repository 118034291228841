import React, { useState } from 'react';
import { Box, Tabs, Tab, Paper } from '@mui/material';
import { 
  Email as EmailIcon, 
  Phone as PhoneIcon, 
  Sms as SmsIcon,
  WhatsApp as WhatsAppIcon 
} from '@mui/icons-material';
import ContactsSidebar from './conversations/ContactsSidebar';
import ChatWindow from './conversations/ChatWindow';
import EmailView from './conversations/EmailView';
import CallsView from './conversations/CallsView';
import WhatsAppView from './conversations/WhatsAppView';

const LeadConversations = ({ contacts: initialContacts }) => {
  const [selectedContact, setSelectedContact] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [communicationType, setCommunicationType] = useState('sms');

  const renderCommunicationView = () => {
    switch(communicationType) {
      case 'sms':
        return (
          <Box sx={{ display: 'flex', gap: 2, flex: 1 }}>
            <ContactsSidebar 
              contacts={initialContacts}
              selectedContact={selectedContact}
              setSelectedContact={setSelectedContact}
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
              communicationType={communicationType}
            />
            <ChatWindow 
              selectedContact={selectedContact}
              communicationType={communicationType}
            />
          </Box>
        );
      case 'email':
        return (
          <EmailView 
            selectedContact={selectedContact}
            setSelectedContact={setSelectedContact}
          />
        );
      case 'calls':
        return (
          <CallsView 
            selectedContact={selectedContact}
            setSelectedContact={setSelectedContact}
          />
        );
      case 'whatsapp':
        return (
          <WhatsAppView 
            selectedContact={selectedContact}
            setSelectedContact={setSelectedContact}
          />
        );
      default:
        return null;
    }
  };

  const handleTabChange = (event, newValue) => {
    setCommunicationType(newValue);
    setSelectedContact(null);
  };

  return (
    <Box sx={{ height: '100%', display: 'flex' }}>
      <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
        {renderCommunicationView()}
      </Box>

      <Paper sx={{ 
        width: 80, 
        borderLeft: 1, 
        borderColor: 'divider',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        pt: 2,
      }}>
        <Tabs 
          value={communicationType}
          onChange={handleTabChange}
          orientation="vertical"
          sx={{ 
            height: '100%',
            '& .MuiTab-root': {
              minWidth: 'unset',
              width: 80,
              minHeight: 80,
              textTransform: 'none',
              fontFamily: 'Urbanist, sans-serif',
              fontSize: '0.75rem'
            }
          }}
        >
          <Tab icon={<SmsIcon />} label="SMS" value="sms" />
          <Tab icon={<EmailIcon />} label="Email" value="email" />
          <Tab icon={<PhoneIcon />} label="Calls" value="calls" />
          <Tab icon={<WhatsAppIcon />} label="WhatsApp" value="whatsapp" />
        </Tabs>
      </Paper>
    </Box>
  );
};

export default LeadConversations;