import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  IconButton,
  Typography,
  Paper,
  Grid,
  Modal,
} from '@mui/material';
import {
  Add as AddIcon,
  Delete as DeleteIcon,
  DragIndicator as DragIcon,
  Save as SaveIcon,
  Undo as UndoIcon,
  Redo as RedoIcon,
  Visibility as PreviewIcon,
  Close as CloseIcon,
  Edit as EditIcon,
} from '@mui/icons-material';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { styled } from '@mui/material/styles';
import ColorPicker from './components/ColorPicker';
import SectionEditor from './components/SectionEditor';
import Navbar from './components/Navbar';
import SectionTemplates from './components/SectionTemplates';
import SectionRenderer from './components/SectionRenderer';
import PreviewModal from './components/PreviewModal';
import SaveModal from './components/SaveModal';

const StyledDragHandle = styled(Box)(({ theme }) => ({
  cursor: 'grab',
  padding: '8px',
  display: 'flex',
  alignItems: 'center',
  color: theme.palette.grey[500],
  '&:hover': {
    color: theme.palette.primary.main,
  },
}));

const StyledSection = styled(Paper)(({ theme }) => ({
  position: 'relative',
  marginBottom: '10px',
  borderRadius: '8px',
  transition: 'all 0.2s ease',
  '&:hover': {
    boxShadow: theme.shadows[3],
    '& .section-controls': {
      opacity: 1,
    },
  },
}));

const WebsiteCreate = () => {
  const [sections, setSections] = useState([]);
  const [previewMode, setPreviewMode] = useState(false);
  const [selectedSection, setSelectedSection] = useState(null);
  const [showEditor, setShowEditor] = useState(false);
  const [showSaveModal, setShowSaveModal] = useState(false);
  const [settings, setSettings] = useState({
    title: 'My Website',
    description: '',
    primaryColor: '#1976d2',
    backgroundColor: '#ffffff',
    fontFamily: 'Roboto',
    containerWidth: 'wide',
  });

  // Add history state
  const [historyState, setHistoryState] = useState({
    past: [],
    present: sections,
    future: []
  });

  // Define section templates with proper structure
  const elements = [
    {
      type: 'header',
      name: 'Header',
      icon: <AddIcon />,
      defaultContent: {
        title: 'Welcome to Our Website',
        subtitle: 'Create something amazing today',
        buttonText: 'Get Started',
        backgroundColor: '#ffffff',
        textColor: '#000000',
      }
    },
    {
      type: 'textBlock',
      name: 'Text Block',
      icon: <AddIcon />,
      defaultContent: {
        text: 'Enter your content here...',
        fontSize: '16px',
        alignment: 'left',
      }
    },
    {
      type: 'image',
      name: 'Image',
      icon: <AddIcon />,
      defaultContent: {
        url: 'https://via.placeholder.com/600x400',
        alt: 'Image description',
        caption: 'Image caption',
      }
    },
    {
      type: 'button',
      name: 'Button',
      icon: <AddIcon />,
      defaultContent: {
        text: 'Click Me',
        url: '#',
        style: 'primary',
      }
    },
    {
      type: 'form',
      name: 'Form',
      icon: <AddIcon />,
      defaultContent: {
        fields: [],
        submitText: 'Submit',
      }
    },
    {
      type: 'gallery',
      name: 'Gallery',
      icon: <AddIcon />,
      defaultContent: {
        images: [],
        layout: 'grid',
      }
    },
  ];

  // Add section function
  const addSection = (elementType) => {
    const template = elements.find(el => el.type === elementType);
    if (!template) return;

    const newSection = {
      id: `section-${Date.now()}`,
      type: template.type,
      content: { ...template.defaultContent },
      styles: {
        padding: '20px',
        margin: '0px',
        backgroundColor: '#ffffff',
      },
    };
    
    setSections(prev => [...prev, newSection]);
  };

  const handleSave = () => {
    setShowSaveModal(false);

  };

  // Render section content
  const renderSection = (section) => {
    switch (section.type) {
      case 'header':
        return (
          <Box sx={{ textAlign: 'center', py: 4 }}>
            <Typography variant="h2" sx={{ color: section.content.textColor }}>
              {section.content.title}
            </Typography>
            <Typography variant="h5" sx={{ mt: 2, color: section.content.textColor }}>
              {section.content.subtitle}
            </Typography>
            {section.content.buttonText && (
              <Button variant="contained" sx={{ mt: 3 }}>
                {section.content.buttonText}
              </Button>
            )}
          </Box>
        );

      case 'textBlock':
        return (
          <Typography 
            sx={{ 
              fontSize: section.content.fontSize,
              textAlign: section.content.alignment,
              py: 2,
            }}
          >
            {section.content.text}
          </Typography>
        );

      case 'image':
        return (
          <Box sx={{ textAlign: 'center', py: 2 }}>
            <img 
              src={section.content.url} 
              alt={section.content.alt}
              style={{ maxWidth: '100%', height: 'auto' }}
            />
            {section.content.caption && (
              <Typography variant="caption" sx={{ mt: 1, display: 'block' }}>
                {section.content.caption}
              </Typography>
            )}
          </Box>
        );

      default:
        return (
          <Typography>
            {section.type} section
          </Typography>
        );
    }
  };

  // Add handleDragEnd function
  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const items = Array.from(sections);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setSections(items);
  };

  // Add deleteSection function
  const deleteSection = (sectionId) => {
    setSections(sections.filter(section => section.id !== sectionId));
  };

  // Add settings handler
  const handleSettingsChange = (newSettings) => {
    setSettings(newSettings);
  };

  // Add undo function
  const undo = () => {
    const { past, present, future } = historyState;
    if (past.length === 0) return;

    const previous = past[past.length - 1];
    const newPast = past.slice(0, past.length - 1);

    setHistoryState({
      past: newPast,
      present: previous,
      future: [present, ...future]
    });
    setSections(previous);
  };

  // Add redo function
  const redo = () => {
    const { past, present, future } = historyState;
    if (future.length === 0) return;

    const next = future[0];
    const newFuture = future.slice(1);

    setHistoryState({
      past: [...past, present],
      present: next,
      future: newFuture
    });
    setSections(next);
  };

  // Update history when sections change
  useEffect(() => {
    setHistoryState(prev => ({
      past: [...prev.past, prev.present],
      present: sections,
      future: []
    }));
  }, [sections]);

  const handleSectionUpdate = (updatedSection) => {
    setSections(prev => 
      prev.map(section => 
        section.id === updatedSection.id ? updatedSection : section
      )
    );
  };

  const handleAddSection = (template) => {
    const newSection = {
      id: `section-${Date.now()}`,
      type: template.type,
      content: { ...template.defaultContent },
      styles: {
        padding: '20px',
        margin: '0px',
        backgroundColor: '#ffffff',
      },
    };
    
    setSections([...sections, newSection]);
  };

  // Add new handlers
  const handleDuplicateSection = (sectionId) => {
    const sectionToDuplicate = sections.find(s => s.id === sectionId);
    if (sectionToDuplicate) {
      const newSection = {
        ...sectionToDuplicate,
        id: `section-${Date.now()}`,
      };
      setSections([...sections, newSection]);
    }
  };

  const handleDeleteSection = (sectionId) => {
    setSections(sections.filter(s => s.id !== sectionId));
  };

  return (
    <Box sx={{ height: '100vh', display: 'flex', flexDirection: 'column' }}>
      <Navbar
        onPreview={() => setPreviewMode(true)}
        onSave={() => setShowSaveModal(true)}
        onUndo={undo}
        onRedo={redo}
        canUndo={historyState.past.length > 0}
        canRedo={historyState.future.length > 0}
        settings={settings}
        onSettingsChange={handleSettingsChange}
      />

      <Box sx={{ display: 'flex', flex: 1, overflow: 'hidden' }}>
        {/* Sidebar */}
        <Box sx={{
          width: 300,
          borderRight: '1px solid #e0e0e0',
          backgroundColor: '#fff',
          p: 2,
          overflowY: 'auto',
        }}>
          <Typography variant="h6" gutterBottom>
            Add Sections
          </Typography>
          <SectionTemplates onSelectTemplate={handleAddSection} />
        </Box>

        {/* Main Content */}
        <Box sx={{ flex: 1, p: 2, overflowY: 'auto', backgroundColor: '#f5f5f5' }}>
          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="sections">
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {sections.map((section, index) => (
                    <Draggable 
                      key={section.id} 
                      draggableId={section.id} 
                      index={index}
                    >
                      {(provided) => (
                        <Box
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                        >
                          <SectionRenderer
                            section={section}
                            onUpdate={handleSectionUpdate}
                            isPreview={false}
                            dragHandleProps={provided.dragHandleProps}
                            onDelete={handleDeleteSection}
                            onDuplicate={handleDuplicateSection}
                          />
                        </Box>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </Box>
      </Box>

      {/* Modals */}
      <PreviewModal
        open={previewMode}
        onClose={() => setPreviewMode(false)}
        sections={sections}
        settings={settings}
      />
      <SaveModal
        open={showSaveModal}
        onClose={() => setShowSaveModal(false)}
        onSave={handleSave}
        website={{ name: settings.title }}
      />
    </Box>
  );
};

export default WebsiteCreate;
