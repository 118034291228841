import React, { useState, useRef, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import SignatureCanvas from 'react-signature-canvas';
import {
  Box,
  Button,
  Dialog,
  Typography,
  Paper,
  CircularProgress,
  TextField,
  Tabs,
  Tab,
  Zoom,
  Fade
} from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import DrawIcon from '@mui/icons-material/Draw';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import DefaultTemplate from '../templates/DefaultTemplate';
import ModernTemplate from '../templates/ModernTemplate';
import ClassicTemplate from '../templates/ClassicTemplate';
import { PDFDownloadLink } from '@react-pdf/renderer';
import PDFTemplate from '../templates/PDFTemplate';
import { getPublicEstimate, getBusinessDetails } from '../services/api';
import api from '../services/api';
import { formatDateTime, formatDate } from '../utils/dateUtils';

const PublicEstimateView = () => {
  const { uuid } = useParams();
  console.log('COMPONENT MOUNTED, UUID:', uuid);
  const [estimate, setEstimate] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showSignDialog, setShowSignDialog] = useState(false);
  const [signatureType, setSignatureType] = useState('draw'); // 'draw' or 'type'
  const [typedName, setTypedName] = useState('');
  const signatureRef = useRef();
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  useEffect(() => {
    console.log('EFFECT TRIGGERED, UUID:', uuid);
    
    const fetchData = async () => {
      try {
        setLoading(true);
        const [estimateResponse, businessResponse] = await Promise.all([
          getPublicEstimate(uuid),
          getBusinessDetails()
        ]);

        console.log('Raw estimate:', estimateResponse.data);
        console.log('Raw business:', businessResponse);

        const formattedEstimate = {
          ...estimateResponse.data,
          created_at: formatDateTime(estimateResponse.data.created_at),
          updated_at: formatDateTime(estimateResponse.data.updated_at),
          expiration_date: formatDate(estimateResponse.data.expiration_date),
          business_details: {
            address: businessResponse.address || '',
            city: businessResponse.city || '',
            company_name: businessResponse.company_name || '',
            email: businessResponse.email || '',
            logo: businessResponse.logo || null,
            owner_name: businessResponse.owner_name || '',
            phone: businessResponse.phone || '',
            state: businessResponse.state || '',
            zip_code: businessResponse.zip_code || ''
          },
          customer_details: estimateResponse.data.customer_details || {},
          items: estimateResponse.data.items || [],
          project_images: estimateResponse.data.project_images || [],
          ...(estimateResponse.data.scope_of_work?.trim() && { 
            scope_of_work: estimateResponse.data.scope_of_work 
          }),
          ...(estimateResponse.data.notes?.trim() && { 
            notes: estimateResponse.data.notes 
          }),
          ...(estimateResponse.data.terms?.trim() && { 
            terms: estimateResponse.data.terms 
          }),
          service_address: estimateResponse.data.service_address || '',
          service_city: estimateResponse.data.service_city || '',
          service_state: estimateResponse.data.service_state || '',
          service_zip: estimateResponse.data.service_zip || '',
          use_client_address: estimateResponse.data.use_client_address ?? true,
          showAdditionalInfo: true
        };
        
        console.log('Formatted estimate:', formattedEstimate);
        setEstimate(formattedEstimate);
      } catch (err) {
        console.error('FETCH ERROR:', err);
        setError(err.message);
      } finally {
        console.log('SETTING LOADING FALSE');
        setLoading(false);
      }
    };

    fetchData();
  }, [uuid]);

  console.log('RENDER STATE:', { loading, error, estimate, uuid });

  useEffect(() => {
    // Preload the font
    const link = document.createElement('link');
    link.href = 'https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap';
    link.rel = 'stylesheet';
    document.head.appendChild(link);
    
    const style = document.createElement('style');
    style.textContent = `
      @font-face {
        font-family: 'Great Vibes';
        src: url('https://fonts.gstatic.com/s/greatvibes/v14/RWmMoKWR9v4ksMfaWd_JN9XFiaQ.woff2') format('woff2');
        font-weight: 400;
        font-style: normal;
      }
    `;
    document.head.appendChild(style);
    
    return () => {
      document.head.removeChild(link);
      document.head.removeChild(style);
    };
  }, []);

  const renderEstimateTemplate = () => {
    if (!estimate) return null;

    return <DefaultTemplate estimate={estimate} />;
  };

  const generateTypedSignature = async (name) => {
    // Create a temporary div to verify font loading
    const testDiv = document.createElement('div');
    testDiv.style.fontFamily = 'Great Vibes';
    testDiv.style.opacity = '0';
    testDiv.textContent = name;
    document.body.appendChild(testDiv);

    // Force font loading with multiple weights
    try {
      await Promise.all([
        document.fonts.load('400 48px "Great Vibes"'),
        document.fonts.load('normal 48px "Great Vibes"'),
        document.fonts.load('48px "Great Vibes"')
      ]);
      
      console.log('Font loaded successfully');
    } catch (err) {
      console.error('Font loading error:', err);
    }

    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    
    // Set high DPI for better quality
    const scale = window.devicePixelRatio || 1;
    canvas.width = 500 * scale;
    canvas.height = 200 * scale;
    canvas.style.width = '500px';
    canvas.style.height = '200px';
    
    // Scale for high DPI
    ctx.scale(scale, scale);
    
    // Set white background
    ctx.fillStyle = 'white';
    ctx.fillRect(0, 0, canvas.width, canvas.height);
    
    // Set text properties
    ctx.fillStyle = 'black';
    ctx.font = '48px "Great Vibes"';
    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle';
    
    // Add shadow for better appearance
    ctx.shadowColor = 'rgba(0, 0, 0, 0.2)';
    ctx.shadowBlur = 2;
    ctx.shadowOffsetY = 2;
    
    // Draw the signature
    ctx.fillText(name, canvas.width/(2*scale), canvas.height/(2*scale));
    
    // Clean up
    document.body.removeChild(testDiv);
    
    return canvas.toDataURL('image/png', 1.0);
  };

  const handleSign = async () => {
    try {
      let signatureData;
      let signedName = typedName;
      
      if (signatureType === 'draw') {
        if (!signatureRef.current || signatureRef.current.isEmpty()) {
          alert('Please provide a signature');
          return;
        }
        signatureData = signatureRef.current.toDataURL('image/png');
      } else {
        if (!typedName.trim()) {
          alert('Please type your name');
          return;
        }
        signatureData = await generateTypedSignature(typedName);
      }

      console.log('Sending signature data to:', `/api/public/estimates/${uuid}/client-sign`);

      // Use the api service instead of fetch
      const response = await api.post(`/public/estimates/${uuid}/client-sign`, { 
        client_signature: signatureData,
        client_signed_at: new Date().toISOString(),
        client_signed_name: signedName,
        client_signature_type: signatureType
      });

      console.log('Signature submission response:', response);
      
      if (response.data) {
        setEstimate(response.data);
        setShowSignDialog(false);
        setShowSuccessModal(true);
      }
      
    } catch (error) {
      console.error('Error signing estimate:', error);
      alert(`Failed to save signature: ${error.message}`);
    }
  };

  const renderPDFDownload = () => {
    console.log('Rendering PDF with estimate:', estimate);  // Debug log
    
    if (!estimate) return null;

    return (
      <PDFDownloadLink
        document={<PDFTemplate estimate={estimate} />}
        fileName={`estimate-${estimate?.estimate_number || 'draft'}.pdf`}
      >
        {({ blob, url, loading, error }) => {
          // Debug logs
          if (error) console.error('PDF generation error:', error);
          if (loading) console.log('PDF is generating...');
          
          return (
            <Button
              variant="contained"
              sx={{
                backgroundColor: '#394450',
                borderRadius: '50px',
                padding: '12px 24px',
                transition: 'all 0.3s ease',
                '&:hover': {
                  backgroundColor: '#616161',
                  transform: 'translateY(-2px)',
                  boxShadow: '0 4px 8px rgba(0,0,0,0.2)'
                }
              }}
              disabled={loading}
              startIcon={loading ? <CircularProgress size={20} /> : <FileDownloadIcon />}
            >
              {loading ? 'Generating PDF...' : 'Download PDF'}
            </Button>
          );
        }}
      </PDFDownloadLink>
    );
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ p: 3, textAlign: 'center' }}>
        <Typography color="error">{error}</Typography>
      </Box>
    );
  }

  if (!estimate) {
    return (
      <Box sx={{ p: 3, textAlign: 'center' }}>
        <Typography>No estimate found</Typography>
      </Box>
    );
  }

  console.log('Current estimate state:', estimate);
  console.log('Business details:', estimate?.business_details);
  console.log('Phone:', estimate?.business_details?.phone);
  console.log('Email:', estimate?.business_details?.email);

  return (
    <Box 
      sx={{
        minHeight: '100vh',
        backgroundColor: '#f5f5f5',
        padding: '2rem 1rem',
        position: 'relative'
      }}
    >
      <Box
        sx={{
          maxWidth: '1000px',
          margin: '0 auto',
          backgroundColor: 'white',
          borderRadius: '8px',
          boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
          overflow: 'hidden',
          marginBottom: '3rem'
        }}
      >
        <Box
          sx={{
            backgroundColor: '#424242',
            color: 'white',
            padding: '1rem 2rem',
            borderTopLeftRadius: '8px',
            borderTopRightRadius: '8px'
          }}
        >
          <Typography variant="h5">
            Estimate #{estimate?.estimate_number}
          </Typography>
        </Box>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            gap: 2,
            padding: '1rem',
            flexWrap: 'wrap',
            borderBottom: '1px solid #eee',
            backgroundColor: '#f8f9fa'
          }}
        >
          {estimate && estimate.business_details && estimate.business_details.phone && (
            <Button
              variant="outlined"
              startIcon={<PhoneIcon />}
              href={`tel:${estimate.business_details.phone}`}
              sx={{
                borderRadius: '50px',
                padding: '8px 24px',
                borderColor: '#424242',
                color: '#424242',
                '&:hover': {
                  borderColor: '#616161',
                  backgroundColor: 'rgba(66, 66, 66, 0.04)',
                  transform: 'translateY(-2px)',
                  boxShadow: '0 4px 8px rgba(0,0,0,0.1)'
                },
                transition: 'all 0.3s ease'
              }}
            >
              Call {estimate.business_details.company_name || 'Us'}
            </Button>
          )}
          
          {estimate && estimate.business_details && estimate.business_details.email && (
            <Button
              variant="outlined"
              startIcon={<EmailIcon />}
              href={`mailto:${estimate.business_details.email}`}
              sx={{
                borderRadius: '50px',
                padding: '8px 24px',
                borderColor: '#424242',
                color: '#424242',
                '&:hover': {
                  borderColor: '#616161',
                  backgroundColor: 'rgba(66, 66, 66, 0.04)',
                  transform: 'translateY(-2px)',
                  boxShadow: '0 4px 8px rgba(0,0,0,0.1)'
                },
                transition: 'all 0.3s ease'
              }}
            >
              Email {estimate.business_details.company_name || 'Us'}
            </Button>
          )}
        </Box>

        <Box
          sx={{
            padding: '2rem',
            '@media (max-width: 600px)': {
              padding: '1rem'
            }
          }}
        >
          <div id="estimate-template">
            {estimate && (
              <DefaultTemplate 
                estimate={estimate}
              />
            )}
          </div> 
        </Box>

        <Box 
          sx={{
            display: 'flex',
            justifyContent: 'center',
            padding: '2rem',
            borderTop: '1px solid #eee',
            gap: 2,
            flexWrap: 'wrap'
          }}
        >
          {estimate?.client_signature ? (
            <Fade in={true}>
              <Box
                sx={{
                  display: 'flex',
                  gap: 2,
                  alignItems: 'center',
                  flexWrap: 'wrap',
                  justifyContent: 'center'
                }}
              >
                <Zoom in={true}>
                  <Paper
                    elevation={3}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 1,
                      backgroundColor: '#2E7D32',
                      color: 'white',
                      padding: '12px 24px',
                      borderRadius: '50px',
                      transition: 'all 0.3s ease',
                      '&:hover': {
                        transform: 'translateY(-2px)',
                        boxShadow: '0 4px 8px rgba(46, 125, 50, 0.3)'
                      }
                    }}
                  >
                    <CheckCircleOutlineIcon 
                      sx={{ 
                        fontSize: 24,
                        color: 'white',
                        animation: 'pulse 2s infinite',
                        '@keyframes pulse': {
                          '0%': {
                            transform: 'scale(1)',
                            opacity: 1
                          },
                          '50%': {
                            transform: 'scale(1.1)',
                            opacity: 0.8
                          },
                          '100%': {
                            transform: 'scale(1)',
                            opacity: 1
                          }
                        }
                      }} 
                    />
                    <Typography 
                      variant="subtitle1"
                      sx={{
                        color: 'white',
                        fontWeight: 500,
                        letterSpacing: '0.5px'
                      }}
                    >
                      Estimate has been signed successfully
                    </Typography>
                  </Paper>
                </Zoom>

                <Box sx={{ display: 'flex', gap: 2, justifyContent: 'center' }}>
                  {renderPDFDownload()}
                </Box>
              </Box>
            </Fade>
          ) : (
            <Zoom in={true}>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: '#424242',
                  borderRadius: '50px',
                  padding: '12px 24px',
                  transition: 'all 0.3s ease',
                  '&:hover': {
                    backgroundColor: '#616161',
                    transform: 'translateY(-2px)',
                    boxShadow: '0 4px 8px rgba(0,0,0,0.2)'
                  }
                }}
                onClick={() => setShowSignDialog(true)}
                startIcon={<DrawIcon />}
              >
                Sign Estimate
              </Button>
            </Zoom>
          )}
        </Box>
      </Box>

      <Box
        sx={{
          position: 'fixed',
          bottom: 0,
          left: 0,
          width: '100%',
          backgroundColor: '#f5f5f5',
          padding: '0.5rem',
          textAlign: 'center',
          borderTop: '1px solid #e0e0e0'
        }}
      >
        <Typography 
          variant="body2" 
          sx={{ 
            color: '#666',
            fontSize: '0.875rem',
            '& a': {
              color: '#424242',
              textDecoration: 'none',
              fontWeight: 500,
              '&:hover': {
                textDecoration: 'underline'
              }
            }
          }}
        >
          Powered by <a href="https://servtrackr.com" target="_blank" rel="noopener noreferrer">ServTrackr</a>
        </Typography>
      </Box>

      <Dialog 
        open={showSignDialog} 
        onClose={() => setShowSignDialog(false)}
        maxWidth="md"
        fullWidth
        PaperProps={{
          sx: {
            borderRadius: '8px'
          }
        }}
      >
        <Box p={3}>
          <Typography variant="h6" gutterBottom>
            Sign Estimate
          </Typography>
          
          <Tabs
            value={signatureType}
            onChange={(e, newValue) => setSignatureType(newValue)}
            centered
            sx={{ 
              mb: 2,
              '& .MuiTabs-indicator': {
                backgroundColor: '#424242'
              },
              '& .Mui-selected': {
                color: '#424242'
              }
            }}
          >
            <Tab value="draw" label="Draw Signature" />
            <Tab value="type" label="Type Signature" />
          </Tabs>

          {signatureType === 'draw' ? (
            <Paper 
              elevation={3} 
              sx={{ 
                border: '1px solid #ccc', 
                marginBottom: 20, 
                background: '#fff',
                width: '100%',
                height: '200px',
                position: 'relative'
              }}
            >
              <SignatureCanvas
                ref={signatureRef}
                canvasProps={{
                  className: 'signature-canvas',
                  style: {
                    width: '100%',
                    height: '100%',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    border: '1px solid #ccc'
                  }
                }}
                backgroundColor="white"
              />
            </Paper>
          ) : (
            <Box sx={{ mb: 3 }}>
              <TextField
                fullWidth
                label="Type your name"
                value={typedName}
                onChange={(e) => setTypedName(e.target.value)}
                sx={{ mb: 2 }}
              />
              <Paper 
                elevation={3} 
                style={{ 
                  border: '1px solid #ccc', 
                  padding: '20px', 
                  marginTop: '10px',
                  minHeight: '100px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  background: '#fff'
                }}
              >
                <Typography 
                  style={{ 
                    fontFamily: '"Great Vibes", cursive',
                    fontSize: '48px',
                    fontWeight: 400,
                    lineHeight: 1.2,
                    color: '#000'
                  }}
                >
                  {typedName}
                </Typography>
              </Paper>
            </Box>
          )}

          <Box display="flex" justifyContent="space-between" mt={2}>
            {signatureType === 'draw' && (
              <Button 
                onClick={() => signatureRef.current.clear()}
                variant="outlined"
                sx={{
                  color: '#424242',
                  borderColor: '#424242',
                  '&:hover': {
                    borderColor: '#616161'
                  }
                }}
              >
                Clear
              </Button>
            )}
            <Button
              variant="contained"
              sx={{
                backgroundColor: '#424242',
                '&:hover': {
                  backgroundColor: '#616161'
                }
              }}
              onClick={handleSign}
              disabled={signatureType === 'type' && !typedName.trim()}
            >
              Submit Signature
            </Button>
          </Box>
        </Box>
      </Dialog>

      <Dialog
        open={showSuccessModal}
        onClose={() => setShowSuccessModal(false)}
        maxWidth="sm"
        fullWidth
        PaperProps={{
          sx: {
            borderRadius: '12px',
            padding: '24px'
          }
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
            p: 3
          }}
        >
          <CheckCircleOutlineIcon
            sx={{
              fontSize: 80,
              color: '#2E7D32',
              mb: 2,
              animation: 'bounce 1s ease-in-out',
              '@keyframes bounce': {
                '0%, 20%, 50%, 80%, 100%': {
                  transform: 'translateY(0)'
                },
                '40%': {
                  transform: 'translateY(-20px)'
                },
                '60%': {
                  transform: 'translateY(-10px)'
                }
              }
            }}
          />
          <Typography
            variant="h5"
            sx={{
              fontWeight: 600,
              color: '#2E7D32',
              mb: 2
            }}
          >
            Estimate Signed Successfully!
          </Typography>
          <Typography
            variant="body1"
            sx={{
              color: '#666',
              mb: 3,
              maxWidth: '80%'
            }}
          >
            Thank you for signing the estimate. You can now download a copy for your records.
          </Typography>
          <Box
            sx={{
              display: 'flex',
              gap: 2,
              justifyContent: 'center',
              width: '100%'
            }}
          >
            {renderPDFDownload()}
            <Button
              variant="outlined"
              onClick={() => setShowSuccessModal(false)}
              sx={{
                color: '#1976d2',
                borderColor: '#1976d2',
                '&:hover': {
                  borderColor: '#1565c0',
                  backgroundColor: 'rgba(25, 118, 210, 0.04)'
                },
                borderRadius: '8px',
                textTransform: 'none',
                fontSize: '1rem',
                padding: '8px 16px'
              }}
            >
              Close
            </Button>
          </Box>
        </Box>
      </Dialog>
    </Box>
  );
};

export default PublicEstimateView;
