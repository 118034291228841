import React, { useState, useEffect } from 'react';
import { getPropertyDetails } from '../services/api';
import styles from './PropertyDetails.module.scss';
// Import icons
import BedIcon from '@mui/icons-material/SingleBed';
import BathIcon from '@mui/icons-material/Bathtub';
import SquareFootIcon from '@mui/icons-material/SquareFoot';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import HomeIcon from '@mui/icons-material/Home';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';

const PropertyDetails = ({ address, city, state, zipCode }) => {
  const [propertyData, setPropertyData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [imageError, setImageError] = useState(false);
  
  // Format full address for display and API
  const fullAddress = React.useMemo(() => {
    const parts = [
      address,
      city,
      state,
      zipCode
    ].filter(Boolean).join(', ');
    return parts || address; // Fallback to address if parts are missing
  }, [address, city, state, zipCode]);

  useEffect(() => {
    let isMounted = true;

    const fetchPropertyDetails = async () => {
      // Only fetch if we have a properly formatted address
      if (!fullAddress || typeof fullAddress !== 'string') {
        setLoading(false);
        return;
      }

      // Clean up the address format
      const cleanAddress = fullAddress.replace(/\s+/g, ' ').trim();
      if (!cleanAddress) {
        setLoading(false);
        return;
      }

      try {
        setLoading(true);
        setError(null);
        setImageError(false);
        
        console.log('Fetching property details for address:', cleanAddress);
        const response = await getPropertyDetails(cleanAddress);
        console.log('API response:', response);

        if (isMounted && response) {
          if (response.error) {
            console.log('Property API error:', response.error);
            setError(response.error);
            return;
          }

          // Transform the data with fallbacks
          const transformedData = {
            bedrooms: response.beds || 0,
            bathrooms: response.baths || 0,
            square_footage: response.sqFt || 0,
            price: parseFloat(response.price || response.predictedValue || 0),
            year_built: response.yearBuilt || null,
            lot_size: response.lotSize || 0,
            property_type: response.propertyType || 'Not Available',
            image_url: response.primaryImageUrl || '',
            additional_images: response.photoUrls || []
          };

          console.log('Transformed property data:', transformedData);
          setPropertyData(transformedData);
        }
      } catch (err) {
        console.error('Error in fetchPropertyDetails:', err);
        if (isMounted) {
          setError('Unable to fetch property details');
        }
      } finally {
        if (isMounted) {
          setLoading(false);
        }
      }
    };

    // Debounce the API call to prevent too many requests
    const timeoutId = setTimeout(() => {
      fetchPropertyDetails();
    }, 500);

    return () => {
      isMounted = false;
      clearTimeout(timeoutId);
    };
  }, [fullAddress]); // Changed dependency to fullAddress

  const shouldShowDetails = propertyData && Object.keys(propertyData).length > 0;

  const formatPrice = (price) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(price);
  };

  const handleImageError = () => {
    console.log('Image failed to load');
    setImageError(true);
  };

  return (
    <div className={styles.propertyDetails}>
      <div className={styles.serviceAddressHeader}>
        <h2 style={{ 
          color: '#2d3748',
          marginBottom: '16px',
          fontSize: '1.25rem',
          fontWeight: 600,
          display: 'flex',
          alignItems: 'center',
          gap: '4px'
        }}>
          <HomeIcon sx={{ color: '#4a5568' }} />
          Service Address
        </h2>
      </div>

      {loading && <div className={styles.loading}>Loading property details...</div>}
      
      {fullAddress && (
        <div className={styles.addressHeader}>
          <LocationOnIcon className={styles.iconRed} />
          <h2 className={styles.addressText}>{fullAddress}</h2>
        </div>
      )}

      {shouldShowDetails && propertyData && (
        <div className={styles.content}>
          {propertyData.image_url && !imageError && (
            <div className={styles.imageContainer}>
              <img 
                src={propertyData.image_url} 
                alt="Property" 
                className={styles.propertyImage}
                onError={handleImageError}
              />
              {propertyData.price > 0 && (
                <div className={styles.priceOverlay}>
                  <span>{formatPrice(propertyData.price)}</span>
                </div>
              )}
            </div>
          )}
          
          <div className={styles.detailsContainer}>
            {propertyData.price > 0 && (
              <div className={styles.detail}>
                <AttachMoneyIcon className={styles.iconGreen} />
                <span className={styles.label}>Value:</span>
                <span className={styles.value}>{formatPrice(propertyData.price)}</span>
              </div>
            )}
            {propertyData.bedrooms > 0 && (
              <div className={styles.detail}>
                <BedIcon className={styles.iconBlue} />
                <span className={styles.label}>Bedrooms:</span>
                <span className={styles.value}>{propertyData.bedrooms}</span>
              </div>
            )}
            {propertyData.bathrooms > 0 && (
              <div className={styles.detail}>
                <BathIcon className={styles.iconGreen} />
                <span className={styles.label}>Bathrooms:</span>
                <span className={styles.value}>{propertyData.bathrooms}</span>
              </div>
            )}
            {propertyData.square_footage > 0 && (
              <div className={styles.detail}>
                <SquareFootIcon className={styles.iconOrange} />
                <span className={styles.label}>Square Footage:</span>
                <span className={styles.value}>{propertyData.square_footage.toLocaleString()} sq ft</span>
              </div>
            )}
            {propertyData.year_built && (
              <div className={styles.detail}>
                <CalendarTodayIcon className={styles.iconPurple} />
                <span className={styles.label}>Year Built:</span>
                <span className={styles.value}>{propertyData.year_built}</span>
              </div>
            )}
            {propertyData.property_type && (
              <div className={styles.detail}>
                <HomeIcon className={styles.iconRed} />
                <span className={styles.label}>Type:</span>
                <span className={styles.value}>{propertyData.property_type}</span>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

PropertyDetails.defaultProps = {
  city: '',
  state: '',
  zipCode: ''
};

export default PropertyDetails;