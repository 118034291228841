// Helper to get user's timezone
const getUserTimeZone = () => Intl.DateTimeFormat().resolvedOptions().timeZone;

export const formatDate = (date) => {
    if (!date) return 'N/A';
    
    try {
        // Convert string to Date object and adjust for timezone
        const dateObj = typeof date === 'string' ? new Date(date + 'Z') : date;
        
        if (!(dateObj instanceof Date) || isNaN(dateObj)) return 'N/A';

        return dateObj.toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            timeZone: getUserTimeZone()
        });
    } catch (error) {
        console.error('Date formatting error:', error);
        return 'N/A';
    }
};

export const formatDateTime = (date) => {
    if (!date) return 'N/A';
    
    try {
        // Convert string to Date object and adjust for timezone
        // Adding 'Z' to treat the date as UTC if it's not already
        const dateObj = typeof date === 'string' ? new Date(date + 'Z') : date;
        
        if (!(dateObj instanceof Date) || isNaN(dateObj)) return 'N/A';

        // Convert UTC to local time
        const localDate = new Date(dateObj.getTime());

        return localDate.toLocaleString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            hour12: true,
            timeZone: getUserTimeZone()
        });
    } catch (error) {
        console.error('Date formatting error:', error);
        return 'N/A';
    }
};

export const getStartOfDay = (date) => {
    const localDate = new Date(date + 'Z');
    localDate.setHours(0, 0, 0, 0);
    return localDate;
};

export const getEndOfDay = (date) => {
    const localDate = new Date(date + 'Z');
    localDate.setHours(23, 59, 59, 999);
    return localDate;
};