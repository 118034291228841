import React, { useState } from 'react';
import {
  Box,
  Grid,
  Typography,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Button,
} from '@mui/material';
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  ContentCopy as DuplicateIcon,
  Visibility as ViewIcon,
  Share as ShareIcon,
  Add as AddIcon,
} from '@mui/icons-material';
import FormCard from './FormCard';
import FormCreate from './FormCreate';
import FormDetails from './FormDetails';

const FormsOverview = () => {
  const [showCreateDialog, setShowCreateDialog] = useState(false);
  const [menuAnchor, setMenuAnchor] = useState(null);
  const [selectedForm, setSelectedForm] = useState(null);
  const [view, setView] = useState('grid');
  const [showDetails, setShowDetails] = useState(false);

  // Mock forms data
  const forms = [
    {
      id: 1,
      name: 'Customer Feedback Form',
      type: 'feedback',
      description: 'Collect customer feedback about our services',
      submissions: 276,
      submissionRate: 85,
      fields: [
        { id: 1, label: 'Full Name', type: 'text', required: true },
        { id: 2, label: 'Email', type: 'email', required: true },
        { id: 3, label: 'Rating', type: 'select', required: true },
        { id: 4, label: 'Comments', type: 'textarea', required: false }
      ],
      lastModified: '2024-03-15'
    },
    {
      id: 2,
      name: 'Contact Request Form',
      type: 'contact',
      description: 'General contact form for inquiries',
      submissions: 154,
      submissionRate: 92,
      fields: [
        { id: 1, label: 'Name', type: 'text', required: true },
        { id: 2, label: 'Email', type: 'email', required: true },
        { id: 3, label: 'Subject', type: 'text', required: true },
        { id: 4, label: 'Message', type: 'textarea', required: true }
      ],
      lastModified: '2024-03-14'
    },
    {
      id: 3,
      name: 'Event Registration',
      type: 'registration',
      description: 'Registration form for upcoming events',
      submissions: 89,
      submissionRate: 78,
      fields: [
        { id: 1, label: 'Full Name', type: 'text', required: true },
        { id: 2, label: 'Email', type: 'email', required: true },
        { id: 3, label: 'Event Date', type: 'date', required: true },
        { id: 4, label: 'Number of Attendees', type: 'number', required: true }
      ],
      lastModified: '2024-03-13'
    }
  ];

  const handleMenuOpen = (event, form) => {
    setMenuAnchor(event.currentTarget);
    setSelectedForm(form);
  };

  const handleMenuClose = () => {
    setMenuAnchor(null);
    setSelectedForm(null);
  };

  const handleFormAction = (action) => {
    handleMenuClose();
    switch (action) {
      case 'view':
        setShowDetails(true);
        break;
      case 'edit':
        console.log('Edit form:', selectedForm);
        break;
      case 'duplicate':
        console.log('Duplicate form:', selectedForm);
        break;
      case 'delete':
        console.log('Delete form:', selectedForm);
        break;
      default:
        break;
    }
  };

  return (
    <Box sx={{ p: 3 }}>
      {!showDetails ? (
        <>
          <Box sx={{ 
            display: 'flex', 
            justifyContent: 'space-between',
            alignItems: 'center',
            mb: 4
          }}>
            <Typography variant="h5" sx={{ fontWeight: 600 }}>
              Forms
            </Typography>
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              onClick={() => setShowCreateDialog(true)}
              sx={{
                borderRadius: 2,
                textTransform: 'none',
                background: 'linear-gradient(45deg, #2196f3 30%, #21cbf3 90%)',
              }}
            >
              Create Form
            </Button>
          </Box>

          <Grid container spacing={3}>
            {forms.map(form => (
              <Grid item xs={12} md={6} lg={4} key={form.id}>
                <FormCard 
                  form={form}
                  onMenuClick={handleMenuOpen}
                  onView={() => handleFormAction('view')}
                />
              </Grid>
            ))}
          </Grid>
        </>
      ) : (
        <FormDetails form={selectedForm} onBack={() => setShowDetails(false)} />
      )}

      <FormCreate 
        open={showCreateDialog}
        onClose={() => setShowCreateDialog(false)}
      />

      <Menu
        anchorEl={menuAnchor}
        open={Boolean(menuAnchor)}
        onClose={handleMenuClose}
        PaperProps={{
          sx: { width: 200, borderRadius: 2 }
        }}
      >
        <MenuItem onClick={() => handleFormAction('edit')}>
          <ListItemIcon>
            <EditIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Edit</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => handleFormAction('duplicate')}>
          <ListItemIcon>
            <DuplicateIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Duplicate</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => handleFormAction('share')}>
          <ListItemIcon>
            <ShareIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Share</ListItemText>
        </MenuItem>
        <MenuItem 
          onClick={() => handleFormAction('delete')}
          sx={{ color: 'error.main' }}
        >
          <ListItemIcon>
            <DeleteIcon fontSize="small" color="error" />
          </ListItemIcon>
          <ListItemText>Delete</ListItemText>
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default FormsOverview;