import React from 'react';
import { 
  Box, 
  Paper, 
  Typography, 
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Chip
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const ClientReengagementView = ({ onBack }) => {
  const data = [
    {
      client: 'XYZ Inc',
      lastService: '2023-03-15',
      totalSpent: 15000,
      lastContact: '2023-06-01',
      status: 'Inactive',
      priority: 'High'
    },
    // Add more sample data
  ];

  return (
    <Box>
      <Box sx={{ mb: 4, display: 'flex', alignItems: 'center' }}>
        <IconButton onClick={onBack} sx={{ mr: 2 }}>
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h5">Client Re-engagement</Typography>
      </Box>

      <Paper elevation={0} sx={{ p: 3, border: '1px solid #e0e0e0' }}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Client</TableCell>
                <TableCell>Last Service</TableCell>
                <TableCell align="right">Total Spent</TableCell>
                <TableCell>Last Contact</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Priority</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>{row.client}</TableCell>
                  <TableCell>{row.lastService}</TableCell>
                  <TableCell align="right">${row.totalSpent}</TableCell>
                  <TableCell>{row.lastContact}</TableCell>
                  <TableCell>
                    <Chip 
                      label={row.status} 
                      color="error" 
                      size="small" 
                    />
                  </TableCell>
                  <TableCell>
                    <Chip 
                      label={row.priority} 
                      color="warning" 
                      size="small" 
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
};

export default ClientReengagementView; 