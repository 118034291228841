import React from 'react';
import { Grid, Paper, Typography, Divider, Box } from '@mui/material';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import ReceiptIcon from '@mui/icons-material/Receipt';
import TimerIcon from '@mui/icons-material/Timer';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import ReportCard from '../ReportCard';
import { useTheme } from '../../../ThemeContext';

const FinancialReports = ({ onNavigate }) => {
  const { darkMode } = useTheme();
  
  return (
    <Paper elevation={0} sx={{ p: 3, height: '100%' }}>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
        <AttachMoneyIcon sx={{ mr: 1, color: darkMode ? '#fff' : '#34495e' }} />
        <Typography variant="h6" sx={{ color: darkMode ? '#fff' : '#34495e' }}>
          Financial reports
        </Typography>
      </Box>
      <Divider sx={{ mb: 3 }} />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <ReportCard
            title="Projected income"
            description="Projected income from invoices awaiting payment"
            onClick={() => onNavigate('projected-income')}
          />
        </Grid>
        <Grid item xs={12}>
          <ReportCard
            title="Transaction list"
            description="All transactions from invoices, payments & deposits"
            onClick={() => onNavigate('transactions')}
          />
        </Grid>
        <Grid item xs={12}>
          <ReportCard
            title="Aged receivables"
            description="Invoices that are late by 30, 60, and 90+ days"
            onClick={() => onNavigate('aged-receivables')}
          />
        </Grid>
        <Grid item xs={12}>
          <ReportCard
            title="Bad debt"
            description="All invoices marked as bad debt"
            onClick={() => onNavigate('bad-debt')}
          />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default FinancialReports; 