import React from 'react';
import { 
  Box, 
  Typography, 
  IconButton,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Timesheets from '../../Timesheets'; // Import the Timesheets component

const TimesheetsView = ({ onBack }) => {
  return (
    <Box>
      <Box sx={{ mb: 4, display: 'flex', alignItems: 'center' }}>
        <IconButton onClick={onBack} sx={{ mr: 2 }}>
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h5">Timesheets</Typography>
      </Box>

      {/* Render the Timesheets component */}
      <Timesheets />
    </Box>
  );
};

export default TimesheetsView; 
