import React from 'react';
import { Paper, Typography, Box } from '@mui/material';

const MetricCard = ({ title, value, subtitle }) => (
  <Paper elevation={0} sx={{ p: 3, height: '100%' }}>
    <Typography variant="h6" color="textSecondary" gutterBottom>
      {title}
    </Typography>
    <Typography variant="h4" sx={{ mb: 1 }}>
      {value}
    </Typography>
    <Typography variant="body2" color="textSecondary">
      {subtitle}
    </Typography>
  </Paper>
);

export default MetricCard; 