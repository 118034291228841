
import React from 'react';
import { 
  TextField, 
  InputAdornment, 
  IconButton, 
  Tooltip 
} from '@mui/material';
import { 
  Send as SendIcon,
  AttachFile as AttachFileIcon 
} from '@mui/icons-material';

const MessageInput = ({ message, setMessage, onSend }) => {
  return (
    <TextField
      fullWidth
      value={message}
      onChange={(e) => setMessage(e.target.value)}
      placeholder="Type a message..."
      variant="outlined"
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <Tooltip title="Attach file">
              <IconButton size="small">
                <AttachFileIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Send message">
              <IconButton 
                size="small" 
                color="primary"
                onClick={onSend}
                disabled={!message.trim()}
              >
                <SendIcon />
              </IconButton>
            </Tooltip>
          </InputAdornment>
        )
      }}
      sx={{
        '& .MuiOutlinedInput-root': {
          borderRadius: 3
        }
      }}
    />
  );
};

export default MessageInput; 