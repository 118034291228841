import React from 'react';
import { Paper, Typography } from '@mui/material';
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from 'recharts';

const data = [
  { name: 'Mon', total: 24, billable: 20 },
  { name: 'Tue', total: 22, billable: 18 },
  { name: 'Wed', total: 28, billable: 25 },
  { name: 'Thu', total: 26, billable: 21 },
  { name: 'Fri', total: 20, billable: 17 },
];

const TimeTrackingChart = () => (
  <Paper elevation={0} sx={{ p: 3, height: '100%', border: '1px solid #e0e0e0', borderRadius: 1 }}>
    <Typography variant="h6" gutterBottom sx={{ color: '#34495e' }}>
      Time Tracking
    </Typography>
    <ResponsiveContainer width="100%" height={400}>
      <AreaChart data={data}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Area type="monotone" dataKey="total" stackId="1" stroke="#8884d8" fill="#8884d8" />
        <Area type="monotone" dataKey="billable" stackId="1" stroke="#82ca9d" fill="#82ca9d" />
      </AreaChart>
    </ResponsiveContainer>
  </Paper>
);

export default TimeTrackingChart; 