import React from 'react';
import { Grid, TextField, Typography, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import PaymentIcon from '@mui/icons-material/Payment';
import styles from './AdminSettings.module.scss';
import { useTheme } from '../ThemeContext';

function AdminSettingsPayment() {
  const { darkMode } = useTheme();

  const inputStyles = {
    '& .MuiInputLabel-root': {
      color: darkMode ? 'var(--text-secondary)' : 'inherit'
    },
    '& .MuiOutlinedInput-root': {
      backgroundColor: darkMode ? 'var(--input-background)' : 'inherit',
      color: darkMode ? 'var(--text-primary)' : 'inherit',
      '& fieldset': {
        borderColor: darkMode ? 'var(--border-color)' : 'inherit'
      },
      '&:hover fieldset': {
        borderColor: darkMode ? 'var(--text-secondary)' : 'inherit'
      },
      '&.Mui-focused fieldset': {
        borderColor: darkMode ? '#90caf9' : '#1976d2'
      }
    },
    '& .MuiInputBase-input': {
      color: darkMode ? 'var(--text-primary)' : 'inherit'
    }
  };

  return (
    <div className={`${styles.settingsSection} ${darkMode ? styles.darkMode : ''}`}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography 
            variant="h6" 
            className={`${styles.sectionTitle} ${darkMode ? styles.darkMode : ''}`}
          >
            <PaymentIcon className={`${styles.sectionIcon} ${darkMode ? styles.darkMode : ''}`} />
            Payment Settings
          </Typography>
        </Grid>

        <Grid item xs={12} md={6}>
          <FormControl 
            fullWidth 
            className={`${styles.formGroup} ${darkMode ? styles.darkMode : ''}`}
          >
            <InputLabel sx={inputStyles['& .MuiInputLabel-root']}>
              Default Currency
            </InputLabel>
            <Select
              defaultValue="USD"
              label="Default Currency"
              sx={{
                ...inputStyles['& .MuiOutlinedInput-root'],
                '& .MuiSvgIcon-root': {
                  color: darkMode ? 'var(--text-secondary)' : 'inherit'
                }
              }}
            >
              <MenuItem value="USD">USD ($)</MenuItem>
              <MenuItem value="EUR">EUR (€)</MenuItem>
              <MenuItem value="GBP">GBP (£)</MenuItem>
              <MenuItem value="CAD">CAD ($)</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label="Default Tax Rate (%)"
            type="number"
            variant="outlined"
            className={`${styles.formGroup} ${darkMode ? styles.darkMode : ''}`}
            sx={inputStyles}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Payment Terms"
            variant="outlined"
            multiline
            rows={3}
            className={`${styles.formGroup} ${darkMode ? styles.darkMode : ''}`}
            sx={inputStyles}
          />
        </Grid>
      </Grid>
    </div>
  );
}

export default AdminSettingsPayment;
