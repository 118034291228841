import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  Typography, 
  Paper, 
  Button, 
  Box,
  TextField,
  MenuItem,
  ListSubheader,
  CircularProgress,
  Fade,
  Grow
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { createEmployeeRequest } from '../services/api';
import moment from 'moment';

const NewRequest = () => {
  const navigate = useNavigate();
  const [submitLoading, setSubmitLoading] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [requestForm, setRequestForm] = useState({
    type: '',
    details: '',
    date: moment().format('YYYY-MM-DD'),
  });

  const requestTypes = [
    // Time Off
    { value: 'vacation', label: 'Vacation Time' },
    { value: 'sick_leave', label: 'Sick Leave' },
    { value: 'personal_day', label: 'Personal Day' },
    { value: 'medical_appointment', label: 'Medical Appointment' },
    { value: 'family_emergency', label: 'Family Emergency' },
    { value: 'bereavement', label: 'Bereavement Leave' },
    
    // Schedule Changes
    { value: 'schedule_change', label: 'Schedule Change' },
    { value: 'shift_swap', label: 'Shift Swap Request' },
    { value: 'leave_early', label: 'Leave Early Request' },
    { value: 'arrive_late', label: 'Late Arrival Notice' },
    { value: 'overtime_request', label: 'Overtime Request' },
    
    // Administrative
    { value: 'training_request', label: 'Training/Course Request' },
    { value: 'equipment_request', label: 'Equipment Request' },
    { value: 'remote_work', label: 'Remote Work Request' },
    { value: 'travel_request', label: 'Travel Request' },
    
    // Other
    { value: 'jury_duty', label: 'Jury Duty' },
    { value: 'military_leave', label: 'Military Leave' },
    { value: 'other', label: 'Other Request' }
  ];

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setRequestForm(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setSubmitLoading(true);
      await createEmployeeRequest(requestForm);
      setShowSuccess(true);
      setTimeout(() => {
        navigate('../availability');
      }, 1500); // Redirect after showing success animation
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setSubmitLoading(false);
    }
  };

  return (
    <Grow in={true}>
      <Paper 
        elevation={4}
        sx={{ 
          p: 4, 
          maxWidth: 800, 
          mx: 'auto', 
          mt: 4,
          borderRadius: '16px',
          backgroundColor: '#ffffff',
          position: 'relative',
          overflow: 'hidden'
        }}
      >
        {/* Success Overlay */}
        <Fade in={showSuccess}>
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: 'rgba(255, 255, 255, 0.95)',
              zIndex: 10,
            }}
          >
            <CheckCircleOutlineIcon 
              color="success" 
              sx={{ 
                fontSize: 64,
                mb: 2,
                animation: 'bounce 0.5s ease'
              }} 
            />
            <Typography variant="h5" color="success.main">
              Request Submitted Successfully!
            </Typography>
          </Box>
        </Fade>

        {/* Header */}
        <Box sx={{ 
          mb: 4, 
          display: 'flex', 
          alignItems: 'center',
          borderBottom: '1px solid #e0e0e0',
          pb: 2,
          position: 'relative'
        }}>
          <Button 
            startIcon={<ArrowBackIcon />}
            onClick={() => navigate('../availability')}
            sx={{
              color: 'text.secondary',
              '&:hover': {
                backgroundColor: 'action.hover'
              }
            }}
          >
            Back to Requests
          </Button>
          <Typography 
            variant="h4" 
            sx={{ 
              fontWeight: 600,
              position: 'absolute',
              right: 0,
              color: '#1a2027'
            }}
          >
            New Request
          </Typography>
        </Box>

        {/* Form */}
        <Box 
          component="form" 
          onSubmit={handleSubmit} 
          sx={{ 
            display: 'flex', 
            flexDirection: 'column', 
            gap: 4 
          }}
        >
          {/* Request Type Field */}
          <Box>
            <Typography 
              variant="subtitle1" 
              sx={{ 
                mb: 2, 
                fontWeight: 500,
                color: 'text.primary' 
              }}
            >
              Request Type
            </Typography>
            <TextField
              select
              fullWidth
              name="type"
              value={requestForm.type}
              onChange={handleInputChange}
              required
              sx={{
                '& .MuiOutlinedInput-root': {
                  borderRadius: '8px',
                  backgroundColor: '#f8fafc',
                  '&:hover': {
                    backgroundColor: '#f1f5f9',
                  }
                }
              }}
            >
              <ListSubheader>Time Off</ListSubheader>
              {requestTypes.slice(0, 6).map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}

              <ListSubheader>Schedule Changes</ListSubheader>
              {requestTypes.slice(6, 11).map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}

              <ListSubheader>Administrative</ListSubheader>
              {requestTypes.slice(11, 15).map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}

              <ListSubheader>Other</ListSubheader>
              {requestTypes.slice(15).map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Box>

          {/* Date Field */}
          <Box>
            <Typography 
              variant="subtitle1" 
              sx={{ 
                mb: 2, 
                fontWeight: 500,
                color: 'text.primary'
              }}
            >
              Date
            </Typography>
            <TextField
              type="date"
              fullWidth
              name="date"
              value={requestForm.date}
              onChange={handleInputChange}
              required
              sx={{
                '& .MuiOutlinedInput-root': {
                  borderRadius: '8px',
                  backgroundColor: '#f8fafc',
                  '&:hover': {
                    backgroundColor: '#f1f5f9',
                  }
                }
              }}
            />
          </Box>

          {/* Details Field */}
          <Box>
            <Typography 
              variant="subtitle1" 
              sx={{ 
                mb: 2, 
                fontWeight: 500,
                color: 'text.primary'
              }}
            >
              Details
            </Typography>
            <TextField
              multiline
              rows={4}
              fullWidth
              name="details"
              value={requestForm.details}
              onChange={handleInputChange}
              placeholder="Please provide details about your request..."
              required
              sx={{
                '& .MuiOutlinedInput-root': {
                  borderRadius: '8px',
                  backgroundColor: '#f8fafc',
                  '&:hover': {
                    backgroundColor: '#f1f5f9',
                  }
                }
              }}
            />
          </Box>

          {/* Action Buttons */}
          <Box sx={{ 
            display: 'flex', 
            gap: 2, 
            justifyContent: 'flex-end', 
            mt: 2,
            borderTop: '1px solid #e0e0e0',
            pt: 3
          }}>
            <Button 
              variant="outlined" 
              onClick={() => navigate('../availability')}
              sx={{
                borderRadius: '8px',
                px: 3,
                py: 1,
                borderColor: '#e0e0e0',
                color: 'text.secondary',
                '&:hover': {
                  borderColor: '#bdbdbd',
                  backgroundColor: '#f5f5f5'
                }
              }}
            >
              Cancel
            </Button>
            <Button 
              variant="contained" 
              type="submit"
              disabled={submitLoading || !requestForm.type || !requestForm.details}
              sx={{
                borderRadius: '8px',
                px: 4,
                py: 1,
                backgroundColor: 'primary.main',
                '&:hover': {
                  backgroundColor: 'primary.dark'
                }
              }}
            >
              {submitLoading ? (
                <>
                  <CircularProgress size={20} sx={{ mr: 1 }} color="inherit" />
                  <span>Submitting...</span>
                </>
              ) : (
                'Submit Request'
              )}
            </Button>
          </Box>
        </Box>
      </Paper>
    </Grow>
  );
};

// Add this CSS to your styles
const styles = `
@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}
`;

// Add style tag to document
const styleSheet = document.createElement("style");
styleSheet.innerText = styles;
document.head.appendChild(styleSheet);

export default NewRequest; 