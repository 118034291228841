import React from 'react';
import { Paper, Typography } from '@mui/material';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from 'recharts';

const monthlyData = [
  { name: 'Jan', revenue: 4000, expenses: 2400 },
  { name: 'Feb', revenue: 3000, expenses: 1398 },
  { name: 'Mar', revenue: 2000, expenses: 9800 },
  { name: 'Apr', revenue: 2780, expenses: 3908 },
  { name: 'May', revenue: 1890, expenses: 4800 },
  { name: 'Jun', revenue: 2390, expenses: 3800 },
];

const MonthlyPerformanceChart = () => (
  <Paper 
    elevation={0} 
    sx={{ 
      p: 3,
      border: '1px solid #e0e0e0',
      borderRadius: 1
    }}
  >
    <Typography variant="h6" gutterBottom sx={{ color: '#34495e' }}>
      Monthly Performance
    </Typography>
    <ResponsiveContainer width="100%" height={400}>
      <BarChart data={monthlyData}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="revenue" fill="#2196f3" />
        <Bar dataKey="expenses" fill="#f44336" />
      </BarChart>
    </ResponsiveContainer>
  </Paper>
);

export default MonthlyPerformanceChart; 