import React, { useState, useEffect, useCallback, useRef } from 'react';
import { getProjectPhotos, uploadProjectPhoto } from '../services/api';
import { useNavigate } from 'react-router-dom';
import styles from './ProjectPhotos.module.scss';

const UploadIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
    <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
    <polyline points="17 8 12 3 7 8"></polyline>
    <line x1="12" y1="3" x2="12" y2="15"></line>
  </svg>
);

const ProjectPhotos = ({ projectId }) => {
  const navigate = useNavigate();
  const [photos, setPhotos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [isDragging, setIsDragging] = useState(false);
  const [uploadProgress, setUploadProgress] = useState({});
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [currentPhotoIndex, setCurrentPhotoIndex] = useState(0);
  const [isZoomed, setIsZoomed] = useState(false);
  const fileInputRef = useRef(null);

  const getPhotoUrl = useCallback((filename) => {
    if (!filename) return '';
    return `${process.env.REACT_APP_API_URL}${filename}`;
  }, []);

  useEffect(() => {
    fetchPhotos();
  }, [projectId]);

  const fetchPhotos = async () => {
    try {
      setLoading(true);
      setError(null);
      const response = await getProjectPhotos(projectId);
      
      if (!response || !response.photos) {
        throw new Error('Invalid response format');
      }
      
      setPhotos(response.photos);
    } catch (err) {
      console.error('Error fetching project photos:', err);
      setError('Failed to load photos. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleFileSelect = async (event) => {
    const files = Array.from(event.target.files || []).filter(file => file.type.startsWith('image/'));
    if (files.length > 0) {
      setSelectedFiles(files);
      // Automatically start upload
      await handlePhotoUpload(files);
    }
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
    
    const files = Array.from(e.dataTransfer.files)
      .filter(file => file.type.startsWith('image/'));
    
    if (files.length > 0) {
      setSelectedFiles(files);
      // Automatically start upload
      await handlePhotoUpload(files);
    }
  };

  const handlePhotoUpload = async (filesToUpload = selectedFiles) => {
    if (filesToUpload.length === 0) return;

    for (const file of filesToUpload) {
      const formData = new FormData();
      formData.append('file', file);
      
      try {
        setUploadProgress(prev => ({...prev, [file.name]: 0}));
        
        await uploadProjectPhoto(projectId, formData, (progressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setUploadProgress(prev => ({
            ...prev, 
            [file.name]: percentCompleted
          }));
        });

      } catch (err) {
        console.error(`Error uploading ${file.name}:`, err);
        setError(`Failed to upload ${file.name}`);
      }
    }

    // Refresh the photos list
    await fetchPhotos();
    // Clear selected files and progress
    setSelectedFiles([]);
    setUploadProgress({});
  };

  const removeSelectedFile = (fileName) => {
    setSelectedFiles(prevFiles => prevFiles.filter(file => file.name !== fileName));
  };

  const openLightbox = (index) => {
    setCurrentPhotoIndex(index);
    setLightboxOpen(true);
  };

  const closeLightbox = () => {
    setLightboxOpen(false);
    setIsZoomed(false);
  };

  const nextPhoto = useCallback(() => {
    setCurrentPhotoIndex((prevIndex) => (prevIndex + 1) % photos.length);
    setIsZoomed(false);
  }, [photos.length]);

  const prevPhoto = useCallback(() => {
    setCurrentPhotoIndex((prevIndex) => (prevIndex - 1 + photos.length) % photos.length);
    setIsZoomed(false);
  }, [photos.length]);

  const toggleZoom = () => {
    setIsZoomed(!isZoomed);
  };

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (!lightboxOpen) return;
      switch (e.key) {
        case 'ArrowRight':
          nextPhoto();
          break;
        case 'ArrowLeft':
          prevPhoto();
          break;
        case 'Escape':
          closeLightbox();
          break;
        default:
          break;
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [lightboxOpen, nextPhoto, prevPhoto]);

  const handleUploadClick = () => {
    console.log('Upload button clicked');
    console.log('Selected files:', selectedFiles);
    handlePhotoUpload();
  };

  if (loading) return <div>Loading photos...</div>;
  if (error) return <div>{error}</div>;

  const formatDateTime = (dateString) => {
    if (!dateString) {
      console.log('No date string provided');
      return '';
    }
    
    console.log('Raw date string:', dateString); // Debug log
    
    try {
      const date = new Date(dateString);
      
      // Check if date is valid
      if (isNaN(date.getTime())) {
        console.log('Invalid date object created');
        return dateString;
      }

      return new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: '2-digit',
        hour12: true
      }).format(date);
      
    } catch (error) {
      console.error('Error formatting date:', error);
      return dateString;
    }
  };

  const openFileDialog = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <div className={styles.projectPhotos}>
      {error && (
        <div className={styles.error}>
          <p>{error}</p>
          <button onClick={() => setError(null)}>Dismiss</button>
        </div>
      )}
      
      <div className={styles.content}>
        <div className={styles.uploadSection}>
          <div 
            className={`${styles.dropZone} ${isDragging ? styles.dragging : ''}`}
            onDragEnter={handleDragEnter}
            onDragLeave={handleDragLeave}
            onDragOver={handleDragOver}
            onDrop={handleDrop}
          >
            <input
              type="file"
              ref={fileInputRef}
              onChange={handleFileSelect}
              accept="image/*"
              multiple
              style={{ display: 'none' }}
            />
            <button 
              onClick={() => fileInputRef.current?.click()}
              className={styles.uploadButton}
              type="button"
            >
              <UploadIcon />
              <span>Click to select or drag and drop photos here</span>
            </button>
          </div>
        </div>

        <div className={styles.photoGrid}>
          {photos.map((photo, index) => (
            <div 
              key={photo.id || index} 
              className={styles.photoItem}
              onClick={() => openLightbox(index)}
            >
              <img 
                src={getPhotoUrl(photo.file_path)}
                alt={`Project photo ${index + 1}`}
                loading="lazy"
              />
              <div className={styles.photoTimestamp}>
                {photo.uploaded_at ? formatDateTime(photo.uploaded_at) : 'No date available'}
              </div>
            </div>
          ))}
        </div>
      </div>

      {lightboxOpen && (
        <div className={styles.lightbox} onClick={closeLightbox}>
          <div className={styles.lightboxContent} onClick={(e) => e.stopPropagation()}>
            <img
              src={getPhotoUrl(photos[currentPhotoIndex].file_path)}
              alt={`Project photo ${currentPhotoIndex + 1}`}
              className={`${styles.lightboxImage} ${isZoomed ? styles.zoomed : ''}`}
              onClick={toggleZoom}
            />
            <div className={styles.lightboxControls}>
              <button onClick={prevPhoto} className={styles.lightboxButton}>
                ←
              </button>
              <div className={styles.photoInfo}>
                <span>{currentPhotoIndex + 1} / {photos.length}</span>
                <span className={styles.timestamp}>
                  {formatDateTime(photos[currentPhotoIndex].uploaded_at)}
                </span>
              </div>
              <button onClick={nextPhoto} className={styles.lightboxButton}>
                →
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProjectPhotos;
