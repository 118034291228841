import React from 'react';
import { Grid, Paper, Typography, Divider, Box } from '@mui/material';
import AssignmentIcon from '@mui/icons-material/Assignment';
import ReportCard from '../ReportCard';
import { useTheme } from '../../../ThemeContext';

const EstimateReports = ({ onNavigate }) => {
  const { darkMode } = useTheme();

  return (
    <Paper elevation={0} sx={{ p: 3, height: '100%' }}>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
        <AssignmentIcon sx={{ mr: 1, color: darkMode ? '#fff' : '#34495e' }} />
        <Typography variant="h6" sx={{ color: darkMode ? '#fff' : '#34495e' }}>
          Estimate reports
        </Typography>
      </Box>
      <Divider sx={{ mb: 3 }} />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <ReportCard
            title="Pending estimates"
            description="View all estimates awaiting client approval"
            onClick={() => onNavigate('pending-estimates')}
          />
        </Grid>
        <Grid item xs={12}>
          <ReportCard
            title="Conversion rate"
            description="Track estimate to job conversion metrics"
            onClick={() => onNavigate('estimate-conversion')}
          />
        </Grid>
        <Grid item xs={12}>
          <ReportCard
            title="Estimate history"
            description="Historical record of all estimates"
            onClick={() => onNavigate('estimate-history')}
          />
        </Grid>
        <Grid item xs={12}>
          <ReportCard
            title="Estimate analytics"
            description="Analysis of estimate performance and trends"
            onClick={() => onNavigate('estimate-analytics')}
          />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default EstimateReports; 