import React from 'react';
import { Box, IconButton, Drawer, Typography, Button, Divider } from '@mui/material';
import {
  Campaign as CampaignIcon,
  Assessment as AnalyticsIcon,
  Article as TemplatesIcon,
  Menu as MenuIcon,
  Close as CloseIcon,
  Settings as SettingsIcon,
  Group as AudienceIcon,
  Timeline as ReportsIcon,
  AutoGraph as PerformanceIcon,
  Folder as ResourcesIcon,
} from '@mui/icons-material';

const NavItem = ({ icon: Icon, label, active, onClick }) => (
  <Box
    onClick={onClick}
    sx={{
      display: 'flex',
      alignItems: 'center',
      gap: 2,
      py: 2,
      px: 3,
      cursor: 'pointer',
      borderRadius: 2,
      backgroundColor: active ? 'primary.main' : 'transparent',
      color: active ? 'white' : 'text.secondary',
      transition: 'all 0.2s ease',
      '&:hover': {
        backgroundColor: active ? 'primary.dark' : 'action.hover',
      }
    }}
  >
    <Icon sx={{ fontSize: 22 }} />
    <Typography sx={{ fontWeight: 500, fontSize: '0.95rem' }}>{label}</Typography>
  </Box>
);

const CampaignNavigation = ({ currentView, onViewChange, onCreateClick }) => {
  const [open, setOpen] = React.useState(false);

  const toggleDrawer = () => {
    setOpen(!open);
  };

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <IconButton
          onClick={toggleDrawer}
          size="large"
          sx={{
            color: 'grey.700',
            '&:hover': {
              backgroundColor: 'grey.100',
            },
          }}
        >
          <MenuIcon />
        </IconButton>
      </Box>

      <Drawer
        anchor="right"
        open={open}
        onClose={toggleDrawer}
        PaperProps={{
          sx: {
            width: 320,
            borderRadius: '0 12px 12px 0',
            mt: '64px',
            height: 'calc(100% - 64px)',
            backgroundColor: '#ffffff',
            boxShadow: '4px 0 20px rgba(0, 0, 0, 0.08)',
          }
        }}
      >
        <Box sx={{ 
          p: 3,
          display: 'flex', 
          flexDirection: 'column',
          height: '100%'
        }}>
          {/* Header */}
          <Box sx={{ 
            display: 'flex', 
            justifyContent: 'space-between', 
            alignItems: 'center',
            mb: 3
          }}>
            <Typography variant="h6" sx={{ fontWeight: 600, color: 'grey.900' }}>
              Menu
            </Typography>
            <IconButton onClick={toggleDrawer} size="small">
              <CloseIcon />
            </IconButton>
          </Box>

          {/* Create Campaign Button */}
          <Button
            variant="contained"
            startIcon={<CampaignIcon />}
            onClick={() => {
              onCreateClick();
              toggleDrawer();
            }}
            sx={{
              py: 1.5,
              mb: 3,
              borderRadius: 2,
              textTransform: 'none',
              background: 'linear-gradient(45deg, #2196f3 30%, #21cbf3 90%)',
              boxShadow: '0 3px 5px 2px rgba(33, 203, 243, .3)',
              '&:hover': {
                background: 'linear-gradient(45deg, #1976d2 30%, #21cbf3 90%)',
              }
            }}
          >
            Create New Campaign
          </Button>

          <Divider sx={{ mb: 2 }} />

          {/* Navigation Items */}
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
            {/* Main Navigation */}
            <Typography variant="overline" color="text.secondary" sx={{ px: 3, mt: 2 }}>
              Main
            </Typography>
            <NavItem
              icon={CampaignIcon}
              label="Overview"
              active={currentView === 'overview'}
              onClick={() => {
                onViewChange('overview');
                toggleDrawer();
              }}
            />
            <NavItem
              icon={TemplatesIcon}
              label="Templates"
              active={currentView === 'templates'}
              onClick={() => {
                onViewChange('templates');
                toggleDrawer();
              }}
            />
            <NavItem
              icon={AnalyticsIcon}
              label="Analytics"
              active={currentView === 'analytics'}
              onClick={() => {
                onViewChange('analytics');
                toggleDrawer();
              }}
            />

            {/* Management */}
            <Typography variant="overline" color="text.secondary" sx={{ px: 3, mt: 3 }}>
              Management
            </Typography>
            <NavItem
              icon={AudienceIcon}
              label="Audiences"
              active={currentView === 'audiences'}
              onClick={() => {
                onViewChange('audiences');
                toggleDrawer();
              }}
            />
            <NavItem
              icon={ReportsIcon}
              label="Reports"
              active={currentView === 'reports'}
              onClick={() => {
                onViewChange('reports');
                toggleDrawer();
              }}
            />
            <NavItem
              icon={PerformanceIcon}
              label="Performance"
              active={currentView === 'performance'}
              onClick={() => {
                onViewChange('performance');
                toggleDrawer();
              }}
            />

            {/* Resources */}
            <Typography variant="overline" color="text.secondary" sx={{ px: 3, mt: 3 }}>
              Resources
            </Typography>
            <NavItem
              icon={ResourcesIcon}
              label="Assets"
              active={currentView === 'assets'}
              onClick={() => {
                onViewChange('assets');
                toggleDrawer();
              }}
            />
            <NavItem
              icon={SettingsIcon}
              label="Settings"
              active={currentView === 'settings'}
              onClick={() => {
                onViewChange('settings');
                toggleDrawer();
              }}
            />
          </Box>
        </Box>
      </Drawer>
    </>
  );
};

export default CampaignNavigation;