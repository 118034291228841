import React, { useState, useEffect } from 'react';
import {
  Box,
  Paper,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Chip,
  Grid,
  Card,
  CardContent,
  FormControl,
  Select,
  MenuItem,
  TextField,
  InputAdornment,
  IconButton,
  Tooltip,
  CircularProgress,
  Divider,
  Tabs,
  Tab
} from '@mui/material';
import {
  Add as AddIcon,
  Search as SearchIcon,
  TrendingUp,
  Source,
  Assessment,
  CalendarToday,
  FilterList as FilterListIcon,
  MoreVert as MoreVertIcon,
  ShowChart,
  MonetizationOn,
  Speed as SpeedIcon,
  AccountTree as AccountTreeIcon,
  ViewList as ViewListIcon,
  Chat as ChatIcon,
  Email as EmailIcon,
  Phone as PhoneIcon,
  WhatsApp as WhatsAppIcon,
  Message as MessageIcon,
  ViewKanban as KanbanIcon,
  FormatListBulleted as ListIcon,
  Campaign as CampaignIcon,
  Forum as ConversationsIcon,
  Contacts as ContactsIcon,
  ImportExport as ImportExportIcon,
  Description as FormIcon,
  Language as WebsiteIcon,
  Construction as ConstructionIcon
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import LeadsPipeline from './leads/LeadsPipeline';
import AdminLeadsListPanel from './leads/AdminLeadsListPanel';
import LeadQuickActions from './leads/LeadQuickActions';
import LeadConversations from './leads/LeadConversations';
import CampaignsOverview from './leads/campaigns/CampaignsOverview';
import ContactsOverview from './leads/contacts/ContactsOverview';
import FormsOverview from './leads/forms/FormsOverview';
import WebsiteOverview from './leads/website/WebsiteOverview';

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  '& .MuiTableCell-head': {
    fontWeight: 600,
    backgroundColor: theme.palette.background.default,
    fontFamily: 'Urbanist, sans-serif',
    fontSize: '0.95rem',
    color: theme.palette.text.secondary,
    borderBottom: `2px solid ${theme.palette.divider}`,
    padding: '16px 24px',
  },
  '& .MuiTableCell-body': {
    fontFamily: 'Urbanist, sans-serif',
    fontSize: '0.9rem',
    padding: '16px 24px',
  },
  '& .MuiTableRow-root:hover': {
    backgroundColor: 'rgba(25, 118, 210, 0.04)',
    cursor: 'pointer',
  }
}));

const StyledCard = styled(Card)(({ theme }) => ({
  height: '100%',
  transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
  background: theme.palette.background.paper,
  border: '1px solid',
  borderColor: theme.palette.divider,
  '&:hover': {
    transform: 'translateY(-4px)',
    boxShadow: '0 12px 24px rgba(0,0,0,0.1)',
    borderColor: theme.palette.primary.main,
  }
}));

const StyledChip = styled(Chip)(({ theme, status }) => ({
  fontFamily: 'Urbanist, sans-serif',
  fontWeight: 500,
  fontSize: '0.8rem',
  height: '24px',
  backgroundColor: 
    status === 'New' ? '#e3f2fd' :
    status === 'In Progress' ? '#fff3e0' :
    status === 'Qualified' ? '#e8f5e9' :
    status === 'Lost' ? '#ffebee' :
    status === 'Won' ? '#e8f5e9' : '#f5f5f5',
  color:
    status === 'New' ? '#1976d2' :
    status === 'In Progress' ? '#f57c00' :
    status === 'Qualified' ? '#2e7d32' :
    status === 'Lost' ? '#c62828' :
    status === 'Won' ? '#2e7d32' : '#616161',
  '&:hover': {
    opacity: 0.9
  }
}));

const MetricCard = ({ icon: Icon, title, value, trend, color }) => (
  <Card sx={{ 
    height: '100%',
    background: `linear-gradient(135deg, ${color}15, ${color}05)`,
    border: '1px solid',
    borderColor: `${color}30`,
  }}>
    <CardContent>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
        <Typography variant="h6" sx={{ 
          color: color,
          fontFamily: 'Urbanist, sans-serif',
          fontSize: '1.1rem',
          fontWeight: 600,
          display: 'flex',
          alignItems: 'center',
          gap: 1
        }}>
          <Icon sx={{ fontSize: '1.5rem' }} />
          {title}
        </Typography>
        {trend && (
          <Chip
            label={`${trend > 0 ? '+' : ''}${trend}%`}
            size="small"
            sx={{
              backgroundColor: trend > 0 ? '#e8f5e9' : '#ffebee',
              color: trend > 0 ? '#2e7d32' : '#c62828',
              fontWeight: 600
            }}
          />
        )}
      </Box>
      <Typography variant="h4" sx={{ 
        fontFamily: 'Urbanist, sans-serif',
        fontWeight: 700,
        color: theme => theme.palette.text.primary
      }}>
        {value}
      </Typography>
    </CardContent>
  </Card>
);

const AdminLeads = () => {
  const navigate = useNavigate();
  const [leads, setLeads] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [sourceFilter, setSourceFilter] = useState('All');
  const [statusFilter, setStatusFilter] = useState('All');
  const [loading, setLoading] = useState(true);
  const [view, setView] = useState('list');

  const sources = ['All', 'Website', 'Referral', 'Direct', 'Social Media'];
  const statuses = ['All', 'New', 'In Progress', 'Qualified', 'Lost', 'Won'];

  useEffect(() => {
    // TODO: Replace with actual API call
    setLeads([
      { id: 1, name: 'John Doe', source: 'Website', status: 'New', value: 5000, date: '2024-03-15' },
      { id: 2, name: 'Jane Smith', source: 'Referral', status: 'In Progress', value: 7500, date: '2024-03-14' },
      { id: 3, name: 'Bob Johnson', source: 'Direct', status: 'Qualified', value: 12000, date: '2024-03-13' },
    ]);
    setLoading(false);
  }, []);

  const filteredLeads = leads.filter(lead => {
    const matchesSearch = lead.name.toLowerCase().includes(searchTerm.toLowerCase());
    const matchesSource = sourceFilter === 'All' || lead.source === sourceFilter;
    const matchesStatus = statusFilter === 'All' || lead.status === statusFilter;
    return matchesSearch && matchesSource && matchesStatus;
  });

  const totalLeadValue = filteredLeads.reduce((sum, lead) => sum + lead.value, 0);
  const averageLeadValue = filteredLeads.length > 0 ? totalLeadValue / filteredLeads.length : 0;

  const conversionRate = leads.length > 0 
    ? (leads.filter(l => l.status === 'Won').length / leads.length * 100).toFixed(1)
    : 0;

  const recentLeads = leads
    .sort((a, b) => new Date(b.date) - new Date(a.date))
    .slice(0, 5);

  const handleDragEnd = (result) => {
    if (!result.destination) return;
    
    const { source, destination, draggableId } = result;
    const lead = filteredLeads.find(l => l.id.toString() === draggableId);
    
    if (lead) {
      // Update lead status based on destination droppableId
      const newStatus = destination.droppableId.charAt(0).toUpperCase() + 
                       destination.droppableId.slice(1);
      
      // TODO: Implement API call to update lead status
      console.log(`Moving lead ${lead.id} from ${source.droppableId} to ${destination.droppableId}`);
      
      // Update local state
      setLeads(prevLeads => 
        prevLeads.map(l => 
          l.id === lead.id ? { ...l, status: newStatus } : l
        )
      );
    }
  };

  const handleQuickAction = (action) => {
    // Implement quick action logic here
  };

  return (
    <Box sx={{ p: 3 }}>
      <Box 
        sx={{ 
          backgroundColor: 'var(--background-paper)',
          color: 'var(--text-primary)',
          p: 2,
          mb: 3,
          borderRadius: '8px',
          display: 'flex',
          alignItems: 'center',
          gap: 2,
          border: '1px solid var(--warning-main)',
        }}
      >
        <ConstructionIcon sx={{ color: 'orange' }} />
        <Typography sx={{ 
          fontFamily: 'Urbanist',
          fontWeight: 500,
        }}>
          This feature is currently under development. Some functionality may be limited.
        </Typography>
      </Box>

      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center', 
        mb: 3 
      }}>
        <Typography variant="h5" sx={{ 
          fontFamily: 'Urbanist, sans-serif', 
          fontWeight: 600 
        }}>
          Leads Management
        </Typography>
        <Box sx={{ display: 'flex', gap: 1 }}>
          <LeadQuickActions onAction={handleQuickAction} />
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => navigate('/admin/leads/create')}
            sx={{
              textTransform: 'none',
              fontFamily: 'Urbanist, sans-serif',
              borderRadius: '8px'
            }}
          >
            Add New Lead
          </Button>
        </Box>
      </Box>

      <Tabs 
        value={view} 
        onChange={(e, newValue) => setView(newValue)}
        sx={{ 
          mb: 3,
          borderBottom: 1, 
          borderColor: 'divider',
          '& .MuiTab-root': {
            fontFamily: 'Urbanist, sans-serif',
            textTransform: 'none',
            fontSize: '0.95rem',
            minHeight: 48,
            gap: 1
          }
        }}
      >
        <Tab 
          value="list" 
          label="List View" 
          icon={<ListIcon />} 
          iconPosition="start"
        />
        <Tab 
          value="pipeline" 
          label="Pipeline View" 
          icon={<KanbanIcon />} 
          iconPosition="start"
        />
        <Tab 
          value="conversations" 
          label="Conversations" 
          icon={<ConversationsIcon />} 
          iconPosition="start"
        />
        <Tab 
          value="campaigns" 
          label="Campaigns" 
          icon={<CampaignIcon />} 
          iconPosition="start"
        />
        <Tab 
          value="contacts" 
          label="Contacts" 
          icon={<ContactsIcon />} 
          iconPosition="start"
        />
        <Tab 
          value="forms" 
          label="Forms" 
          icon={<FormIcon />} 
          iconPosition="start"
        />
        <Tab 
          value="website" 
          label="Website" 
          icon={<WebsiteIcon />} 
          iconPosition="start"
        />
      </Tabs>

      {/* Existing metric cards */}
      
      {view === 'pipeline' ? (
        <LeadsPipeline 
          leads={filteredLeads}
          onDragEnd={handleDragEnd}
        />
      ) : view === 'conversations' ? (
        <Box sx={{ height: 'calc(100vh - 200px)', p: 2 }}>
          <LeadConversations 
            contacts={[
              { 
                id: 1, 
                name: 'John Doe', 
                lastMessage: "Thanks for the follow-up", 
                status: 'online',
                unread: 2,
                time: '10:30 AM',
                type: 'email',
                messages: [
                  { 
                    id: 1, 
                    text: "Hi, I'm interested in your services", 
                    sent: false, 
                    time: '10:30 AM' 
                  },
                  { 
                    id: 2, 
                    text: "Great! I'd be happy to help. What specific services are you looking for?", 
                    sent: true, 
                    time: '10:32 AM'
                  }
                ]
              },
              { 
                id: 2, 
                name: 'Alice Smith', 
                lastMessage: "I'll review the proposal", 
                status: 'offline',
                unread: 0,
                time: 'Yesterday',
                type: 'phone',
                messages: [
                  { 
                    id: 1, 
                    text: "Hello, I'd like to discuss the pricing options", 
                    sent: false, 
                    time: '2:30 PM'
                  },
                  { 
                    id: 2, 
                    text: "Of course! Our premium package includes...", 
                    sent: true, 
                    time: '2:35 PM'
                  }
                ]
              }
            ]}
          />
        </Box>
      ) : view === 'campaigns' ? (
        <Box sx={{ height: 'calc(100vh - 200px)' }}>
          <CampaignsOverview />
        </Box>
      ) : view === 'contacts' ? (
        <Box sx={{ height: 'calc(100vh - 200px)' }}>
          <ContactsOverview />
        </Box>
      ) : view === 'forms' ? (
        <Box sx={{ height: 'calc(100vh - 200px)' }}>
          <FormsOverview />
        </Box>
      ) : view === 'website' ? (
        <Box sx={{ height: 'calc(100vh - 200px)', p: 2 }}>
          <WebsiteOverview />
        </Box>
      ) : (
        <AdminLeadsListPanel
          leads={filteredLeads}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          sourceFilter={sourceFilter}
          setSourceFilter={setSourceFilter}
          statusFilter={statusFilter}
          setStatusFilter={setStatusFilter}
          onLeadClick={(id) => navigate(`/admin/leads/${id}`)}
        />
      )}
    </Box>
  );
};

export default AdminLeads; 