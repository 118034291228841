import React from 'react';
import {
  Box,
  Grid,
  Card,
  CardContent,
  Typography,
  IconButton,
  Button,
  Chip,
} from '@mui/material';
import {
  ContentCopy as DuplicateIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Add as AddIcon,
} from '@mui/icons-material';

const TemplateCard = ({ template, onEdit, onDuplicate, onDelete }) => (
  <Card sx={{ 
    height: '100%',
    borderRadius: 2,
    transition: 'transform 0.2s ease-in-out',
    '&:hover': {
      transform: 'translateY(-4px)',
    }
  }}>
    <CardContent>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
        <Chip 
          label={template.type}
          size="small"
          sx={{ 
            backgroundColor: 
              template.type === 'Email' ? 'primary.light' :
              template.type === 'SMS' ? 'secondary.light' :
              'info.light',
            color: 
              template.type === 'Email' ? 'primary.dark' :
              template.type === 'SMS' ? 'secondary.dark' :
              'info.dark',
          }}
        />
        <Box>
          <IconButton size="small" onClick={() => onEdit(template)}>
            <EditIcon fontSize="small" />
          </IconButton>
          <IconButton size="small" onClick={() => onDuplicate(template)}>
            <DuplicateIcon fontSize="small" />
          </IconButton>
          <IconButton size="small" onClick={() => onDelete(template)}>
            <DeleteIcon fontSize="small" />
          </IconButton>
        </Box>
      </Box>
      
      <Typography variant="h6" sx={{ mb: 1 }}>
        {template.name}
      </Typography>
      <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
        {template.description}
      </Typography>
      
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
        <Typography variant="caption" color="text.secondary">
          Last modified: {template.lastModified}
        </Typography>
        <Typography variant="caption" color="text.secondary">
          Used: {template.usageCount} times
        </Typography>
      </Box>
    </CardContent>
  </Card>
);

const CampaignTemplates = () => {
  // Mock data - replace with real data from your API
  const templates = [
    {
      id: 1,
      name: 'Welcome Email',
      type: 'Email',
      description: 'Initial welcome message for new subscribers',
      lastModified: '2024-03-15',
      usageCount: 245,
    },
    {
      id: 2,
      name: 'Follow-up SMS',
      type: 'SMS',
      description: 'Follow-up message for interested leads',
      lastModified: '2024-03-14',
      usageCount: 156,
    },
    // Add more templates...
  ];

  const handleEdit = (template) => {
    console.log('Edit template:', template);
  };

  const handleDuplicate = (template) => {
    console.log('Duplicate template:', template);
  };

  const handleDelete = (template) => {
    console.log('Delete template:', template);
  };

  return (
    <Box sx={{ p: 3 }}>
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'space-between',
        alignItems: 'center',
        mb: 3
      }}>
        <Typography variant="h6">Campaign Templates</Typography>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          sx={{
            borderRadius: 2,
            textTransform: 'none',
            background: 'linear-gradient(45deg, #2196f3 30%, #21cbf3 90%)',
          }}
        >
          Create Template
        </Button>
      </Box>

      <Grid container spacing={3}>
        {templates.map((template) => (
          <Grid item xs={12} md={4} key={template.id}>
            <TemplateCard
              template={template}
              onEdit={handleEdit}
              onDuplicate={handleDuplicate}
              onDelete={handleDelete}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default CampaignTemplates; 