import React, { useState } from 'react';
import { Paper } from '@mui/material';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';

const localizer = momentLocalizer(moment);

const AvailabilityCalendar = ({ availabilityDates, onDateClick, readOnly = false }) => {
  const [currentDate, setCurrentDate] = useState(new Date());

  // Convert availabilityDates to events format
  const events = availabilityDates.map(date => ({
    title: 'Available',
    start: new Date(date.start_time),
    end: new Date(date.end_time || date.start_time),
    allDay: true
  }));

  // Convert availabilityDates to a Set for O(1) lookup
  const availableDatesSet = new Set(
    availabilityDates.map(date => 
      moment(date.start_time).format('YYYY-MM-DD')
    )
  );

  // Custom day styling to show available/unavailable states
  const dayPropGetter = date => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const dateStr = moment(date).format('YYYY-MM-DD');
    
    if (date < today) {
      return {
        style: {
          backgroundColor: 'var(--background-paper)',
          cursor: 'not-allowed',
          color: 'var(--text-disabled)',
        },
      };
    }

    // Highlight available dates
    if (availableDatesSet.has(dateStr)) {
      return {
        style: {
          backgroundColor: '#1b5e20', // Darker green for dark mode
          cursor: readOnly ? 'default' : 'pointer',
          borderRadius: '4px',
          color: '#fff',
        },
      };
    }

    return {
      style: {
        cursor: readOnly ? 'default' : 'pointer',
        backgroundColor: 'var(--background-default)',
        color: 'var(--text-primary)',
      },
    };
  };

  const handleSelectSlot = (slotInfo) => {
    if (readOnly) return;

    const selectedDate = moment(slotInfo.start).format('YYYY-MM-DD');
    const isCurrentlyAvailable = availableDatesSet.has(selectedDate);

    onDateClick({
      date: selectedDate,
      is_available: !isCurrentlyAvailable
    });
  };

  return (
    <Paper elevation={2} sx={{ 
      p: 2, 
      height: '600px',
      backgroundColor: 'var(--background-secondary)',
      '& .rbc-calendar': {
        backgroundColor: 'var(--background-secondary)',
        color: 'var(--text-primary)',
      },
      '& .rbc-month-view': {
        backgroundColor: 'var(--background-default)',
        border: '1px solid var(--divider)',
      },
      '& .rbc-header': {
        padding: '8px',
        fontWeight: 'bold',
        color: 'var(--text-primary)',
        backgroundColor: 'var(--background-paper)',
        borderBottom: '1px solid var(--divider)',
      },
      '& .rbc-month-row': {
        borderLeft: '1px solid var(--divider)',
        borderRight: '1px solid var(--divider)',
      },
      '& .rbc-date-cell': {
        color: 'var(--text-primary)',
        padding: '4px',
      },
      '& .rbc-off-range-bg': {
        backgroundColor: 'var(--background-paper)',
      },
      '& .rbc-off-range': {
        color: 'var(--text-disabled)',
      },
      '& .rbc-today': {
        backgroundColor: 'var(--background-paper)',
      },
      '& .rbc-toolbar button': {
        color: 'var(--text-primary)',
        borderColor: 'var(--divider)',
        '&:hover': {
          backgroundColor: 'var(--background-paper)',
          borderColor: 'var(--divider)',
        },
        '&.rbc-active': {
          backgroundColor: 'var(--primary-main)',
          color: 'white',
          '&:hover': {
            backgroundColor: 'var(--primary-dark)',
          }
        }
      },
      '& .rbc-toolbar-label': {
        color: 'var(--text-primary)',
        fontWeight: 500,
      }
    }}>
      <Calendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        defaultDate={currentDate}
        onNavigate={date => setCurrentDate(date)}
        selectable={!readOnly}
        onSelectSlot={handleSelectSlot}
        dayPropGetter={dayPropGetter}
        views={['month']}
        defaultView="month"
        toolbar={true}
        popup
        style={{ height: '100%' }}
      />
    </Paper>
  );
};

export default AvailabilityCalendar; 