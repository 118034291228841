import React from 'react';
import {
  Grid,
  Paper,
  Typography,
  Box,
  Tooltip,
} from '@mui/material';
import {
  Title as HeaderIcon,
  ViewCarousel as HeroIcon,
  ViewModule as FeaturesIcon,
  People as TeamIcon,
  Star as TestimonialsIcon,
  Email as ContactIcon,
  Image as GalleryIcon,
  Article as ContentIcon,
} from '@mui/icons-material';

const templates = [
  {
    type: 'hero',
    name: 'Hero Section',
    icon: <HeroIcon />,
    defaultContent: {
      title: 'Welcome to Our Website',
      subtitle: 'Create something amazing today',
      buttonText: 'Get Started',
      backgroundColor: '#f8f9fa',
      textColor: '#000000',
      imageUrl: 'https://source.unsplash.com/random/1200x600',
    }
  },
  {
    type: 'features',
    name: 'Features',
    icon: <FeaturesIcon />,
    defaultContent: {
      title: 'Our Features',
      features: [
        { title: 'Feature 1', description: 'Description of feature 1', icon: '🚀' },
        { title: 'Feature 2', description: 'Description of feature 2', icon: '⚡' },
        { title: 'Feature 3', description: 'Description of feature 3', icon: '💡' },
      ]
    }
  },
  {
    type: 'content',
    name: 'Content Block',
    icon: <ContentIcon />,
    defaultContent: {
      title: 'About Us',
      content: 'Write your content here...',
      alignment: 'left',
      imagePosition: 'right',
      imageUrl: 'https://source.unsplash.com/random/600x400',
    }
  },
  {
    type: 'team',
    name: 'Team',
    icon: <TeamIcon />,
    defaultContent: {
      title: 'Our Team',
      subtitle: 'Meet our amazing team members',
      members: [
        { name: 'John Doe', role: 'CEO', image: 'https://source.unsplash.com/random/150x150' },
        { name: 'Jane Smith', role: 'Designer', image: 'https://source.unsplash.com/random/150x150' },
        { name: 'Mike Johnson', role: 'Developer', image: 'https://source.unsplash.com/random/150x150' },
      ]
    }
  },
  {
    type: 'testimonials',
    name: 'Testimonials',
    icon: <TestimonialsIcon />,
    defaultContent: {
      title: 'What Our Clients Say',
      testimonials: [
        { author: 'John Doe', text: 'Amazing service!', rating: 5 },
        { author: 'Jane Smith', text: 'Great experience!', rating: 5 },
        { author: 'Mike Johnson', text: 'Highly recommended!', rating: 5 },
      ]
    }
  },
  {
    type: 'gallery',
    name: 'Gallery',
    icon: <GalleryIcon />,
    defaultContent: {
      title: 'Our Gallery',
      images: [
        { url: 'https://source.unsplash.com/random/400x300', caption: 'Image 1' },
        { url: 'https://source.unsplash.com/random/400x300', caption: 'Image 2' },
        { url: 'https://source.unsplash.com/random/400x300', caption: 'Image 3' },
      ]
    }
  },
  {
    type: 'contact',
    name: 'Contact',
    icon: <ContactIcon />,
    defaultContent: {
      title: 'Contact Us',
      subtitle: 'Get in touch with us',
      email: 'contact@example.com',
      phone: '(123) 456-7890',
      address: '123 Main St, City, Country',
      showForm: true,
    }
  },
];

const SectionTemplates = ({ onSelectTemplate }) => {
  return (
    <Grid container spacing={2}>
      {templates.map((template) => (
        <Grid item xs={6} sm={4} key={template.type}>
          <Tooltip title={`Add ${template.name} section`} placement="top">
            <Paper
              sx={{
                p: 2,
                cursor: 'pointer',
                transition: 'all 0.2s',
                '&:hover': {
                  transform: 'translateY(-2px)',
                  boxShadow: 3,
                  bgcolor: 'action.hover',
                },
              }}
              onClick={() => onSelectTemplate(template)}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  gap: 1,
                }}
              >
                {template.icon}
                <Typography variant="subtitle2" align="center">
                  {template.name}
                </Typography>
              </Box>
            </Paper>
          </Tooltip>
        </Grid>
      ))}
    </Grid>
  );
};

export default SectionTemplates; 