import React from 'react';
import {
  Box,
  Typography,
  Button,
  IconButton,
  Grid,
  Card,
  CardContent,
  TextField,
  Select,
  MenuItem,
  FormControlLabel,
  Switch,
  Tooltip,
} from '@mui/material';
import {
  DragHandle as DragIcon,
  Delete as DeleteIcon,
  Add as AddIcon,
} from '@mui/icons-material';

const FormFieldsSection = ({ formData, onFieldsChange }) => {
  if (!formData || !Array.isArray(formData.fields)) {
    return (
      <Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
          <Typography variant="h6">Form Fields</Typography>
          <Button
            startIcon={<AddIcon />}
            variant="contained"
            onClick={() => onFieldsChange([])}
            sx={{ borderRadius: 2 }}
          >
            Add Field
          </Button>
        </Box>
        <Box sx={{ textAlign: 'center', py: 4 }}>
          <Typography color="text.secondary">
            No fields added yet. Click "Add Field" to start building your form.
          </Typography>
        </Box>
      </Box>
    );
  }

  const fieldTypes = [
    { value: 'text', label: 'Text Input' },
    { value: 'email', label: 'Email Input' },
    { value: 'tel', label: 'Phone Input' },
    { value: 'textarea', label: 'Text Area' },
    { value: 'number', label: 'Number Input' },
    { value: 'date', label: 'Date Input' },
    { value: 'radio', label: 'Radio Group' },
    { value: 'checkbox', label: 'Checkbox' },
    { value: 'select', label: 'Dropdown' },
    { value: 'rating', label: 'Rating' },
  ];

  const addNewField = () => {
    const newField = {
      type: 'text',
      label: 'New Field',
      required: false,
      placeholder: '',
      options: [],
    };

    onFieldsChange([...(formData.fields || []), newField]);
  };

  const updateField = (index, key, value) => {
    const updatedFields = [...formData.fields];
    updatedFields[index] = {
      ...updatedFields[index],
      [key]: value,
    };
    onFieldsChange(updatedFields);
  };

  const removeField = (index) => {
    const updatedFields = formData.fields.filter((_, idx) => idx !== index);
    onFieldsChange(updatedFields);
  };

  const moveField = (fromIndex, toIndex) => {
    const updatedFields = [...formData.fields];
    const [movedField] = updatedFields.splice(fromIndex, 1);
    updatedFields.splice(toIndex, 0, movedField);
    onFieldsChange(updatedFields);
  };

  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Typography variant="h6">Form Fields</Typography>
        <Button
          startIcon={<AddIcon />}
          variant="contained"
          onClick={addNewField}
          sx={{ borderRadius: 2 }}
        >
          Add Field
        </Button>
      </Box>

      {formData.fields.map((field, index) => (
        <Card key={index} sx={{ mb: 2, borderRadius: 2 }}>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                <Tooltip title="Drag to reorder">
                  <IconButton size="small">
                    <DragIcon />
                  </IconButton>
                </Tooltip>
                <Typography variant="subtitle1" sx={{ flex: 1, ml: 1 }}>
                  Field {index + 1}
                </Typography>
                <Tooltip title="Remove field">
                  <IconButton size="small" onClick={() => removeField(index)} color="error">
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Field Label"
                  value={field.label}
                  onChange={(e) => updateField(index, 'label', e.target.value)}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Select
                  fullWidth
                  value={field.type}
                  onChange={(e) => updateField(index, 'type', e.target.value)}
                >
                  {fieldTypes.map((type) => (
                    <MenuItem key={type.value} value={type.value}>
                      {type.label}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Placeholder"
                  value={field.placeholder || ''}
                  onChange={(e) => updateField(index, 'placeholder', e.target.value)}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={field.required || false}
                      onChange={(e) => updateField(index, 'required', e.target.checked)}
                    />
                  }
                  label="Required"
                />
              </Grid>

              {(field.type === 'radio' || field.type === 'select') && (
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Options (comma-separated)"
                    value={field.options?.join(', ') || ''}
                    onChange={(e) => updateField(index, 'options', e.target.value.split(',').map(opt => opt.trim()))}
                    helperText="Enter options separated by commas"
                  />
                </Grid>
              )}
            </Grid>
          </CardContent>
        </Card>
      ))}

      {formData.fields.length === 0 && (
        <Box sx={{ textAlign: 'center', py: 4 }}>
          <Typography color="text.secondary">
            No fields added yet. Click "Add Field" to start building your form.
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default FormFieldsSection; 