import React from 'react';
import {
  Box,
  Paper,
  Typography,
  Slider,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import {
  FormatAlignLeft,
  FormatAlignCenter,
  FormatAlignRight,
  FormatBold,
  FormatItalic,
} from '@mui/icons-material';
import ColorPicker from './ColorPicker';

const SectionStyleControls = ({ section, onUpdate }) => {
  const handleStyleChange = (property, value) => {
    onUpdate({
      ...section,
      styles: {
        ...section.styles,
        [property]: value,
      },
    });
  };

  return (
    <Paper
      sx={{
        position: 'absolute',
        right: -320,
        top: 0,
        width: 300,
        p: 2,
        zIndex: 1000,
        boxShadow: 3,
      }}
    >
      <Typography variant="subtitle2" gutterBottom>
        Style Controls
      </Typography>

      <Stack spacing={2}>
        {/* Spacing Controls */}
        <Box>
          <Typography variant="caption">Padding</Typography>
          <Slider
            value={parseInt(section.styles?.padding) || 20}
            onChange={(_, value) => handleStyleChange('padding', `${value}px`)}
            min={0}
            max={100}
            valueLabelDisplay="auto"
          />
        </Box>

        {/* Background Controls */}
        <Box>
          <Typography variant="caption">Background</Typography>
          <ColorPicker
            color={section.styles?.backgroundColor || '#ffffff'}
            onChange={(color) => handleStyleChange('backgroundColor', color)}
          />
        </Box>

        {/* Text Controls */}
        <FormControl size="small">
          <InputLabel>Font Size</InputLabel>
          <Select
            value={section.styles?.fontSize || '16px'}
            onChange={(e) => handleStyleChange('fontSize', e.target.value)}
            label="Font Size"
          >
            <MenuItem value="12px">Small</MenuItem>
            <MenuItem value="16px">Medium</MenuItem>
            <MenuItem value="20px">Large</MenuItem>
            <MenuItem value="24px">Extra Large</MenuItem>
          </Select>
        </FormControl>

        {/* Alignment Controls */}
        <Box>
          <Typography variant="caption" display="block" gutterBottom>
            Alignment
          </Typography>
          <ToggleButtonGroup
            value={section.styles?.textAlign || 'left'}
            exclusive
            onChange={(_, value) => handleStyleChange('textAlign', value)}
            size="small"
          >
            <ToggleButton value="left">
              <FormatAlignLeft />
            </ToggleButton>
            <ToggleButton value="center">
              <FormatAlignCenter />
            </ToggleButton>
            <ToggleButton value="right">
              <FormatAlignRight />
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>

        {/* Text Style Controls */}
        <Box>
          <Typography variant="caption" display="block" gutterBottom>
            Text Style
          </Typography>
          <ToggleButtonGroup
            value={section.styles?.fontWeight === 'bold' ? ['bold'] : []}
            onChange={(_, value) => handleStyleChange('fontWeight', value.includes('bold') ? 'bold' : 'normal')}
            size="small"
          >
            <ToggleButton value="bold">
              <FormatBold />
            </ToggleButton>
            <ToggleButton value="italic">
              <FormatItalic />
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>
      </Stack>
    </Paper>
  );
};

export default SectionStyleControls; 