import React from 'react';
import { Grid, Paper, Typography, Divider, Box } from '@mui/material';
import WorkIcon from '@mui/icons-material/Work';
import EventIcon from '@mui/icons-material/Event';
import RepeatIcon from '@mui/icons-material/Repeat';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ReportCard from '../ReportCard';
import { useTheme } from '../../../ThemeContext';

const WorkReports = ({ onNavigate }) => {
  const { darkMode } = useTheme();

  return (
    <Paper elevation={0} sx={{ p: 3, height: '100%' }}>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
        <WorkIcon sx={{ mr: 1, color: darkMode ? '#fff' : '#34495e' }} />
        <Typography variant="h6" sx={{ color: darkMode ? '#fff' : '#34495e' }}>
          Work reports
        </Typography>
      </Box>
      <Divider sx={{ mb: 3 }} />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <ReportCard
            title="Visits"
            description="Visits report with additional custom field data"
            onClick={() => onNavigate('visits')}
          />
        </Grid>
        <Grid item xs={12}>
          <ReportCard
            title="One-off jobs"
            description="One-off job report with additional client and job data"
            onClick={() => onNavigate('one-off-jobs')}
          />
        </Grid>
        <Grid item xs={12}>
          <ReportCard
            title="Recurring jobs"
            description="Recurring jobs report with additional client data"
            onClick={() => onNavigate('recurring-jobs')}
          />
        </Grid>
        <Grid item xs={12}>
          <ReportCard
            title="Timesheets"
            description="All time tracked for the team"
            onClick={() => onNavigate('timesheets')}
          />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default WorkReports; 