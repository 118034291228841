import React, { useState, useEffect, useCallback } from 'react';
import { 
  Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, 
  CircularProgress, Select, MenuItem, FormControl, Box, Grid, Card, CardContent,
  TextField, Button, Menu, Chip, IconButton, ListItemIcon, ListItemText, Dialog, DialogTitle, DialogContent, DialogActions
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { 
  Description as DescriptionIcon, AttachMoney as AttachMoneyIcon, Business as BusinessIcon,
  CalendarToday as CalendarTodayIcon, CheckCircleOutline as CheckCircleOutlineIcon, HourglassEmpty as HourglassEmptyIcon,
  Add as AddIcon, FilterList as FilterListIcon, Search as SearchIcon, KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
  MoneyOff as MoneyOffIcon,
  AccessTime as AccessTimeIcon, Person as PersonIcon, MoreVert as MoreVertIcon, Delete as DeleteIcon, ContentCopy as ContentCopyIcon,
  Close as CloseIcon, DeleteOutline as DeleteOutlineIcon, 
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import styles from './AdminEstimates.module.scss';
import { getEstimates, getBusinessDetails, duplicateEstimate, deleteEstimate } from '../services/api';


const AdminEstimates = () => {
  const navigate = useNavigate();

  const [estimates, setEstimates] = useState([]);
  const [summary, setSummary] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [clientFilter, setClientFilter] = useState('All');
  const [statusFilter, setStatusFilter] = useState('All');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [clients, setClients] = useState(['All']);
  const [statuses] = useState(['All', 'Draft', 'Pending Approval', 'Approved', 'Converted']);
  const [estimate, setEstimate] = useState({
    businessDetails: {},
    customerDetails: {},
    estimateNumber: '',
    issueDate: new Date(),
    validUntil: new Date(new Date().setDate(new Date().getDate() + 30)),
    items: [],
    notes: '',
    logo: null,
    logoSize: 100,
    scopeOfWork: '',
    pictures: [],
    template: 'default',
  });
  const [activeFilter, setActiveFilter] = useState('all');
  const [anchorEl, setAnchorEl] = useState(null);
  const [sort, setSort] = useState('newest');
  const [filterAnchorEl, setFilterAnchorEl] = useState(null);
  const [filterMenuOpen, setFilterMenuOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCustomer, setSelectedCustomer] = useState('All Customers');
  const [filter, setFilter] = useState('all');
  const [pendingEstimates, setPendingEstimates] = useState([]);
  const [draftEstimates, setDraftEstimates] = useState([]);
  const [pendingValue, setPendingValue] = useState(0);
  const [draftValue, setDraftValue] = useState(0);
  const [totalEstimatesValue, setTotalEstimatesValue] = useState(0);
  const [averageResponseTime, setAverageResponseTime] = useState(0);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [selectedEstimateId, setSelectedEstimateId] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [successAction, setSuccessAction] = useState('');
  const [customerFilter, setCustomerFilter] = useState('All');
  const [projectFilter, setProjectFilter] = useState('All');
  const [customerAnchorEl, setCustomerAnchorEl] = useState(null);
  const [projectAnchorEl, setProjectAnchorEl] = useState(null);
  const [statusAnchorEl, setStatusAnchorEl] = useState(null);
  const [projectSort, setProjectSort] = useState(null); // null, 'asc', or 'desc'
  const [customerSort, setCustomerSort] = useState(null); // null, 'asc', or 'desc'
  const [statusSort, setStatusSort] = useState(null); // null, 'draft', 'pending', 'approved', 'converted'

  const fetchBusinessDetails = useCallback(async () => {
    console.log('Fetching business details...');
    try {
      const details = await getBusinessDetails();
      console.log('Fetched business details:', details);
      setEstimate(prevEstimate => ({
        ...prevEstimate,
        businessDetails: details
      }));
    } catch (error) {
      console.error('Error fetching business details:', error);
      // Fallback to empty object if fetch fails
      setEstimate(prevEstimate => ({
        ...prevEstimate,
        businessDetails: {}
      }));
    }
  }, []);

  useEffect(() => {
    fetchBusinessDetails();
  }, [fetchBusinessDetails]);

  const fetchEstimates = useCallback(async () => {
    try {
      setLoading(true);
      const data = await getEstimates();
      console.log('Fetched estimates:', data);
      if (data && Array.isArray(data)) {
        setEstimates(data);
        const uniqueClients = [...new Set(data.map(est => 
          est.client?.company || 
          est.customerDetails?.company || 
          est.customer_details?.company ||
          est.customer?.company
        ).filter(Boolean))];
        setClients(['All', ...uniqueClients]);
      } else {
        console.log('Invalid data structure');
        setEstimates([]);
        setClients(['All']);
      }
      setError(null);
    } catch (err) {
      console.error('Error fetching estimates:', err);
      setError('Failed to fetch estimates. Please try again later.');
      setEstimates([]);
      setClients(['All']);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchEstimates();
  }, [fetchEstimates]);

  useEffect(() => {
    if (estimates.length > 0) {
      const sorted = [...estimates].sort((a, b) => 
        new Date(b.issue_date) - new Date(a.issue_date)
      );
      setEstimates(sorted);
    }
  }, [estimates.length]);

  const calculateEstimateTotal = (items) => {
    if (!Array.isArray(items)) return 0;
    
    return items.reduce((sum, item) => {
      const quantity = Number(item.quantity) || 0;
      const unitPrice = Number(item.unit_price || item.unitPrice) || 0;
      const tax = Number(item.tax) || 0;
      
      const itemTotal = quantity * unitPrice * (1 + tax/100);
      return sum + itemTotal;
    }, 0);
  };

  const filteredEstimates = estimates.filter(estimate => {
    const clientMatch = customerFilter === 'All' || getClientName(estimate) === customerFilter;
    const projectMatch = projectFilter === 'All' || estimate.project_name === projectFilter;
    const statusMatch = statusFilter === 'All' || estimate.status === statusFilter;
    const dateMatch = (!startDate || new Date(estimate.issue_date) >= startDate) &&
                     (!endDate || new Date(estimate.issue_date) <= endDate);
    
    // Add search term filtering
    const searchLower = searchTerm.toLowerCase();
    const searchMatch = searchTerm === '' || 
      estimate.project_name?.toLowerCase().includes(searchLower) ||
      getClientName(estimate).toLowerCase().includes(searchLower) ||
      estimate.estimate_number?.toString().includes(searchLower);

    return clientMatch && projectMatch && statusMatch && dateMatch && searchMatch;
  });

  const totalEstimateValue = filteredEstimates.reduce((sum, estimate) => 
    sum + calculateEstimateTotal(estimate.items), 0);
    
  const averageEstimateValue = filteredEstimates.length > 0 
    ? totalEstimateValue / filteredEstimates.length 
    : 0;

  const formatCurrency = (value) => {
    const numValue = parseFloat(value);
    return isNaN(numValue) ? '0.00' : numValue.toFixed(2);
  };

  const getClientName = (estimate) => {
    // First try to get company name
    const companyName = estimate.client?.company || 
        estimate.customerDetails?.company ||
        estimate.customer_details?.company ||
        estimate.customer?.company;

    // If no company name, try to get client/customer name
    const clientName = estimate.client?.name || 
        estimate.customerDetails?.name ||
        estimate.customer_details?.name ||
        estimate.customer?.name;

    // Return company name if it exists, otherwise return client name, or 'N/A' as last resort
    return companyName || clientName || 'N/A';
  };

  const handleFilterClick = (event) => {
    setFilterAnchorEl(event.currentTarget);
    setFilterMenuOpen(true);
  };

  useEffect(() => {
    if (estimates && Array.isArray(estimates)) {
      setPendingEstimates(estimates.filter(est => est.status === 'Pending'));
      setDraftEstimates(estimates.filter(est => est.status === 'Draft'));
    }
  }, [estimates]);

  useEffect(() => {
    if (estimates && Array.isArray(estimates)) {
      // Calculate pending value
      const pendingTotal = estimates
        .filter(est => est.status === 'Pending')
        .reduce((sum, est) => sum + calculateTotal(est), 0);
      setPendingValue(pendingTotal);

      // Calculate draft value
      const draftTotal = estimates
        .filter(est => est.status === 'Draft')
        .reduce((sum, est) => sum + calculateTotal(est), 0);
      setDraftValue(draftTotal);

      // Calculate total estimates value
      const total = estimates.reduce((sum, est) => sum + calculateTotal(est), 0);
      setTotalEstimatesValue(total);

      // Calculate average response time (example calculation)
      const avgTime = calculateAverageResponseTime(estimates);
      setAverageResponseTime(avgTime);
    }
  }, [estimates]);

  const calculateTotal = (estimate) => {
    if (!estimate.items || !Array.isArray(estimate.items)) return 0;
    return estimate.items.reduce((sum, item) => sum + (Number(item.total) || 0), 0);
  };

  const calculateAverageResponseTime = (estimates) => {
    const respondedEstimates = estimates.filter(est => est.response_date);
    if (!respondedEstimates.length) return 0;

    const totalDays = respondedEstimates.reduce((sum, est) => {
      const created = new Date(est.created_at);
      const responded = new Date(est.response_date);
      const days = Math.floor((responded - created) / (1000 * 60 * 60 * 24));
      return sum + days;
    }, 0);

    return Math.round(totalDays / respondedEstimates.length);
  };

  const summaryCards = (
    <Grid container spacing={3} className={styles.summaryContainer}>
      <Grid item xs={12} sm={6} md={3}>
        <div className={styles.summaryCard}>
          <div className={styles.cardContent}>
            <div className={styles.iconWrapper} style={{ color: '#FF4842' }}>
              <AccessTimeIcon />
            </div>
            <div className={styles.cardInfo}>
              <h3 className={styles.cardValue}>${formatCurrency(pendingValue)}</h3>
              <p className={styles.cardLabel}>Pending amount</p>
            </div>
          </div>
        </div>
      </Grid>
      
      <Grid item xs={12} sm={6} md={3}>
        <div className={styles.summaryCard}>
          <div className={styles.cardContent}>
            <div className={styles.iconWrapper} style={{ color: '#54D62C' }}>
              <DescriptionIcon />
            </div>
            <div className={styles.cardInfo}>
              <h3 className={styles.cardValue}>${formatCurrency(draftValue)}</h3>
              <p className={styles.cardLabel}>Draft totals</p>
            </div>
          </div>
        </div>
      </Grid>

      <Grid item xs={12} sm={6} md={3}>
        <div className={styles.summaryCard}>
          <div className={styles.cardContent}>
            <div className={styles.iconWrapper} style={{ color: '#FFC107' }}>
              <MoneyOffIcon />
            </div>
            <div className={styles.cardInfo}>
              <h3 className={styles.cardValue}>${formatCurrency(totalEstimatesValue)}</h3>
              <p className={styles.cardLabel}>Total estimates</p>
            </div>
          </div>
        </div>
      </Grid>

      <Grid item xs={12} sm={6} md={3}>
        <div className={styles.summaryCard}>
          <div className={styles.cardContent}>
            <div className={styles.iconWrapper} style={{ color: '#00AB55' }}>
              <HourglassEmptyIcon />
            </div>
            <div className={styles.cardInfo}>
              <h3 className={styles.cardValue}>{averageResponseTime} days</h3>
              <p className={styles.cardLabel}>Average response time</p>
            </div>
          </div>
        </div>
      </Grid>
    </Grid>
  );

  const getStatusColor = (status) => {
    if (!status) return 'default';
    
    switch (status.toLowerCase()) {
      case 'approved': return 'success';
      case 'draft': return 'default';
      case 'pending': return 'warning';
      case 'converted': return 'info';
      default: return 'default';
    }
  };

  const formatStatus = (status) => {
    if (!status) return 'Draft';
    
    switch (status.toLowerCase()) {
      case 'draft': return 'Draft';
      case 'pending': return 'Pending Approval';
      case 'approved': return 'Approved';
      case 'converted': return 'Converted';
      default: return status.charAt(0).toUpperCase() + status.slice(1).toLowerCase();
    }
  };

  const handleMenuClick = (event, estimateId) => {
    event.stopPropagation(); // Prevent row click
    setMenuAnchorEl(event.currentTarget);
    setSelectedEstimateId(estimateId);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
    setSelectedEstimateId(null);
  };

  const handleDuplicate = async (event) => {
    event.stopPropagation();
    try {
      const response = await duplicateEstimate(selectedEstimateId);
      fetchEstimates();
      handleMenuClose();
      setSuccessMessage('Estimate duplicated successfully');
      setSuccessAction('duplicate');
      setShowSuccessModal(true);
      
      setTimeout(() => {
        setShowSuccessModal(false);
        navigate(`/admin/estimates/${response.estimate.id}`);
      }, 1500);
    } catch (error) {
      setError('Failed to duplicate estimate');
    }
  };

  const handleDelete = async (event) => {
    event.stopPropagation();
    setShowDeleteModal(true);
  };

  const handleConfirmDelete = async () => {
    try {
      await deleteEstimate(selectedEstimateId);
      fetchEstimates();
      setShowDeleteModal(false);
      handleMenuClose();
      setSuccessMessage('Estimate deleted successfully');
      setSuccessAction('delete');
      setShowSuccessModal(true);
      
      setTimeout(() => {
        setShowSuccessModal(false);
      }, 1500);
    } catch (error) {
      setError('Failed to delete estimate');
    }
  };

  const handleCustomerFilterClick = (event) => {
    setCustomerAnchorEl(event.currentTarget);
  };

  const handleProjectFilterClick = (event) => {
    setProjectAnchorEl(event.currentTarget);
  };

  const handleCustomerFilterClose = (value) => {
    if (value) setCustomerFilter(value);
    setCustomerAnchorEl(null);
  };

  const handleProjectFilterClose = (value) => {
    if (value) setProjectFilter(value);
    setProjectAnchorEl(null);
  };

  const handleStatusClick = (event) => {
    setStatusAnchorEl(event.currentTarget);
  };

  const handleStatusClose = (status) => {
    if (status) setActiveFilter(status);
    setStatusAnchorEl(null);
  };

  const uniqueCustomers = ['All', ...new Set(estimates.map(est => getClientName(est)))].sort((a, b) => {
    if (a === 'All') return -1;  // Keep 'All' at the top
    if (b === 'All') return 1;   // Keep 'All' at the top
    return a.localeCompare(b);   // Sort rest alphabetically
  });

  const uniqueProjects = ['All', ...new Set(estimates.filter(est => est.project_name).map(est => est.project_name))].sort((a, b) => {
    if (a === 'All') return -1;  // Keep 'All' at the top
    if (b === 'All') return 1;   // Keep 'All' at the top
    return a.localeCompare(b);   // Sort rest alphabetically
  });

  if (loading) return <div className={styles.loadingContainer}><CircularProgress /></div>;
  if (error) return <div className={styles.errorContainer}><Typography color="error">{error}</Typography></div>;

  const headerCells = [
    { 
      label: 'PROJECT', 
      align: 'center',
      icon: <BusinessIcon sx={{ color: '#2065D1', marginRight: 1 }} />,
      sortable: true,
      onClick: () => {
        setProjectSort(projectSort === 'asc' ? 'desc' : 'asc');
        setCustomerSort(null);
        setSort(null);
      },
      sortDirection: projectSort
    },
    { 
      label: 'CUSTOMER', 
      align: 'center',
      icon: <PersonIcon sx={{ color: '#FF4842', marginRight: 1 }} />,
      sortable: true,
      onClick: () => {
        setCustomerSort(customerSort === 'asc' ? 'desc' : 'asc');
        setProjectSort(null);
        setSort(null);
      },
      sortDirection: customerSort
    },
    { 
      label: 'NUMBER', 
      align: 'center',
      icon: <DescriptionIcon sx={{ color: '#2065D1', marginRight: 1 }} />
    },
    { 
      label: 'ISSUE DATE', 
      align: 'center',
      icon: <CalendarTodayIcon sx={{ color: '#FFC107', marginRight: 1 }} />,
      sortable: true,
      onClick: () => {
        setSort(sort === 'newest' ? 'oldest' : 'newest');
        setProjectSort(null);
        setCustomerSort(null);
      },
      sortDirection: sort
    },
    { 
      label: 'TOTAL', 
      align: 'center',
      icon: <AttachMoneyIcon sx={{ color: '#00AB55', marginRight: 1 }} />
    },
    { 
      label: 'STATUS', 
      align: 'center',
      icon: <CheckCircleOutlineIcon sx={{ color: '#00AB55', marginRight: 1 }} />,
      sortable: true,
      onClick: () => {
        // Cycle through status orders
        switch(statusSort) {
          case null:
            setStatusSort('draft');
            break;
          case 'draft':
            setStatusSort('pending');
            break;
          case 'pending':
            setStatusSort('approved');
            break;
          case 'approved':
            setStatusSort('converted');
            break;
          case 'converted':
            setStatusSort(null);
            break;
          default:
            setStatusSort(null);
        }
        setProjectSort(null);
        setCustomerSort(null);
        setSort(null);
      },
      sortDirection: statusSort
    },
    { 
      label: '', 
      align: 'center',
      icon: null
    },
  ];

  console.log('Rendered estimates:', estimates); // Keep this log for debugging

  const sortedEstimates = [...filteredEstimates].sort((a, b) => {
    // Status sorting
    if (statusSort) {
      const statusOrder = ['draft', 'pending', 'approved', 'converted'];
      const statusA = (a.status || '').toLowerCase();
      const statusB = (b.status || '').toLowerCase();
      
      // Get index from statusOrder array
      const indexA = statusOrder.indexOf(statusA);
      const indexB = statusOrder.indexOf(statusB);
      
      // Sort based on status order
      if (indexA !== indexB) {
        return indexA - indexB;
      }
    }
    
    // Project sorting
    if (projectSort) {
      const projectA = (a.project_name || '').toLowerCase();
      const projectB = (b.project_name || '').toLowerCase();
      if (!projectA && !projectB) return 0;
      if (!projectA) return 1;
      if (!projectB) return -1;
      return projectSort === 'asc' 
        ? projectA.localeCompare(projectB)
        : projectB.localeCompare(projectA);
    }
    
    // Customer sorting
    if (customerSort) {
      const clientA = getClientName(a).toLowerCase();
      const clientB = getClientName(b).toLowerCase();
      return customerSort === 'asc'
        ? clientA.localeCompare(clientB)
        : clientB.localeCompare(clientA);
    }

    // Date sorting
    const dateA = new Date(a.issue_date);
    const dateB = new Date(b.issue_date);
    return sort === 'newest' ? dateB - dateA : dateA - dateB;
  });

  const getStatusArrowColor = (status, direction) => {
    if (!status) return 'text.disabled';
    
    const colors = {
      draft: '#637381',      // gray
      pending: '#fda92d',    // yellow
      approved: '#54d62c',   // green
      converted: '#00b8d9'   // blue
    };

    return colors[status] || 'text.disabled';
  };

  return (
    <div className={styles.estimatesContainer}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={4}>
        <Typography variant="h5" className={styles.title}>Estimates</Typography>
        <div className={styles.buttonGroup}>
          <span className={styles.separator}></span>
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={() => navigate('/admin/estimates/create')}
            className={styles.createButton}
          >
            CREATE ESTIMATE
          </Button>
        </div>
      </Box>

      {summaryCards}

      <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
        <div className={styles.leftSection}>
          <div className={styles.searchContainer}>
            <SearchIcon className={styles.searchIcon} />
            <input
              type="text"
              placeholder="Search estimates..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className={styles.searchInput}
            />
          </div>
        </div>

        <div className={styles.rightSection}>
          <Chip
            label={`Status: ${activeFilter.charAt(0).toUpperCase() + activeFilter.slice(1)}`}
            onClick={handleStatusClick}
            variant="outlined"
            className={styles.filterChip}
            icon={<KeyboardArrowDownIcon style={{ color: '#000000' }} />}
            sx={{ 
              color: '#000000',
              '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.04)'
              }
            }}
          />

          <Menu
            anchorEl={statusAnchorEl}
            open={Boolean(statusAnchorEl)}
            onClose={() => handleStatusClose()}
            PaperProps={{
              elevation: 0,
              sx: {
                maxHeight: 300,
                width: 200,
                overflow: 'auto'
              }
            }}
          >
            <MenuItem 
              onClick={() => handleStatusClose('all')}
              sx={{ 
                color: '#637381',
                '&.Mui-selected': { bgcolor: 'rgba(99, 115, 129, 0.08)' }
              }}
            >
              All ({estimates.length})
            </MenuItem>
            <MenuItem 
              onClick={() => handleStatusClose('draft')}
              sx={{ 
                color: '#637381',
                '&.Mui-selected': { bgcolor: 'rgba(99, 115, 129, 0.08)' }
              }}
            >
              Drafts ({estimates.filter(e => e.status === 'draft').length})
            </MenuItem>
            <MenuItem 
              onClick={() => handleStatusClose('pending')}
              sx={{ 
                color: '#fda92d',
                '&.Mui-selected': { bgcolor: 'rgba(253, 169, 45, 0.08)' }
              }}
            >
              Pending Approval ({estimates.filter(e => e.status === 'pending').length})
            </MenuItem>
            <MenuItem 
              onClick={() => handleStatusClose('approved')}
              sx={{ 
                color: '#54d62c',
                '&.Mui-selected': { bgcolor: 'rgba(84, 214, 44, 0.08)' }
              }}
            >
              Approved ({estimates.filter(e => e.status === 'approved').length})
            </MenuItem>
            <MenuItem 
              onClick={() => handleStatusClose('converted')}
              sx={{ 
                color: '#00b8d9',
                '&.Mui-selected': { bgcolor: 'rgba(0, 184, 217, 0.08)' }
              }}
            >
              Converted ({estimates.filter(e => e.status === 'converted').length})
            </MenuItem>
          </Menu>
        </div>
      </Box>

      <TableContainer className={styles.tableContainer}>
        <Table>
          <TableHead>
            <TableRow>
              {headerCells.map((cell, index) => (
                <TableCell 
                  key={index} 
                  align="center"
                  className={styles.tableHeaderCell}
                  onClick={cell.onClick}
                  style={{ cursor: cell.sortable ? 'pointer' : 'default' }}
                >
                  <Box display="flex" alignItems="center" justifyContent="center">
                    {cell.icon}
                    {cell.label}
                    {cell.sortable && (
                      <Box display="flex" flexDirection="column" ml={1} sx={{ lineHeight: 0 }}>
                        <KeyboardArrowUpIcon 
                          sx={{ 
                            fontSize: '1.2rem',
                            color: cell.label === 'STATUS' 
                              ? getStatusArrowColor(statusSort, 'up')
                              : (cell.sortDirection === 'asc' || cell.sortDirection === 'oldest') 
                                ? 'primary.main' 
                                : 'text.disabled'
                          }} 
                        />
                        <KeyboardArrowDownIcon 
                          sx={{ 
                            fontSize: '1.2rem',
                            marginTop: '-8px',
                            color: cell.label === 'STATUS' 
                              ? getStatusArrowColor(statusSort, 'down')
                              : (cell.sortDirection === 'desc' || cell.sortDirection === 'newest') 
                                ? 'primary.main' 
                                : 'text.disabled'
                          }} 
                        />
                      </Box>
                    )}
                  </Box>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedEstimates.map((estimate) => {
              const clientName = getClientName(estimate);
              const projectName = estimate.project_name;

              return (
                <TableRow 
                  key={estimate.id} 
                  className={styles.tableRow} 
                  onClick={() => navigate(`/admin/estimates/${estimate.id}`)}
                  hover
                >
                  <TableCell className={styles.tableCell} align="center">
                    {projectName}
                  </TableCell>
                  <TableCell className={styles.tableCell} align="center">
                    {clientName}
                  </TableCell>
                  <TableCell className={styles.tableCell} align="center">
                    #{estimate.estimate_number}
                  </TableCell>
                  <TableCell className={styles.tableCell} align="center">
                    {new Date(estimate.issue_date).toLocaleDateString()}
                  </TableCell>
                  <TableCell className={styles.tableCell} align="center">
                    ${formatCurrency(calculateEstimateTotal(estimate.items))}
                  </TableCell>
                  <TableCell className={styles.tableCell} align="center">
                    <Chip 
                      label={formatStatus(estimate.status || 'Pending')}
                      color={getStatusColor(estimate.status)}
                      size="small"
                      sx={{ 
                        fontFamily: 'Urbanist, sans-serif',
                        '& .MuiChip-label': {
                          fontFamily: 'Urbanist, sans-serif'
                        }
                      }}
                    />
                  </TableCell>
                  <TableCell className={styles.tableCell} align="center">
                    <IconButton
                      onClick={(e) => handleMenuClick(e, estimate.id)}
                      size="small"
                      sx={{ 
                        '&:hover': { 
                          backgroundColor: 'rgba(0, 0, 0, 0.04)' 
                        } 
                      }}
                    >
                      <MoreVertIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog
        open={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        PaperProps={{
          sx: {
            borderRadius: '12px',
            padding: '12px',
            maxWidth: '400px'
          }
        }}
      >
        <DialogTitle sx={{ 
          pb: 1,
          fontFamily: 'Urbanist, sans-serif',
          fontSize: '1.25rem',
          fontWeight: 600,
          color: '#2c3e50'
        }}>
          Delete Estimate
        </DialogTitle>
        <DialogContent>
          <Typography 
            variant="body1" 
            sx={{ 
              mb: 2,
              fontFamily: 'Urbanist, sans-serif',
              color: '#475569'
            }}
          >
            Are you sure you want to delete this estimate? This action cannot be undone.
          </Typography>
        </DialogContent>
        <DialogActions sx={{ padding: '16px' }}>
          <Button
            onClick={() => setShowDeleteModal(false)}
            sx={{
              fontFamily: 'Urbanist, sans-serif',
              textTransform: 'none',
              color: '#64748b'
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleConfirmDelete}
            variant="contained"
            color="error"
            sx={{
              fontFamily: 'Urbanist, sans-serif',
              textTransform: 'none',
              boxShadow: 'none',
              '&:hover': {
                boxShadow: 'none',
                backgroundColor: '#dc2626'
              }
            }}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog 
        open={showSuccessModal} 
        onClose={() => {}}  // Disable closing on backdrop click
        maxWidth="xs"
        fullWidth
        PaperProps={{
          sx: { borderRadius: '12px' }
        }}
      >
        <Box sx={{ position: 'relative' }}>
          <DialogTitle sx={{ 
            backgroundColor: successAction === 'delete' ? '#e11d48' : '#4caf50', 
            color: 'white',
            textAlign: 'center',
            pb: 2,
            fontFamily: 'Urbanist, sans-serif'
          }}>
            <Box sx={{ 
              display: 'flex', 
              flexDirection: 'column', 
              alignItems: 'center',
              gap: 1
            }}>
              {successAction === 'delete' ? (
                <DeleteOutlineIcon 
                  sx={{ 
                    fontSize: '48px',
                    animation: 'fadeInScale 0.5s ease-in-out',
                    '@keyframes fadeInScale': {
                      '0%': {
                        transform: 'scale(0)',
                        opacity: 0
                      },
                      '50%': {
                        transform: 'scale(1.2)',
                      },
                      '100%': {
                        transform: 'scale(1)',
                        opacity: 1
                      }
                    }
                  }} 
                />
              ) : (
                <ContentCopyIcon 
                  sx={{ 
                    fontSize: '48px',
                    animation: 'fadeInScale 0.5s ease-in-out',
                    '@keyframes fadeInScale': {
                      '0%': {
                        transform: 'scale(0)',
                        opacity: 0
                      },
                      '50%': {
                        transform: 'scale(1.2)',
                      },
                      '100%': {
                        transform: 'scale(1)',
                        opacity: 1
                      }
                    }
                  }} 
                />
              )}
              {successMessage}
            </Box>
          </DialogTitle>
        </Box>
      </Dialog>

      <Menu
        anchorEl={customerAnchorEl}
        open={Boolean(customerAnchorEl)}
        onClose={() => handleCustomerFilterClose()}
        PaperProps={{
          elevation: 0,
          sx: {
            maxHeight: 300,
            width: 200,
            overflow: 'auto'
          }
        }}
      >
        {uniqueCustomers.map((customer) => (
          <MenuItem 
            key={customer}
            onClick={() => handleCustomerFilterClose(customer)}
            selected={customerFilter === customer}
          >
            {customer}
          </MenuItem>
        ))}
      </Menu>

      <Menu
        anchorEl={projectAnchorEl}
        open={Boolean(projectAnchorEl)}
        onClose={() => handleProjectFilterClose()}
        PaperProps={{
          elevation: 0,
          sx: {
            maxHeight: 300,
            width: 200,
            overflow: 'auto'
          }
        }}
      >
        {uniqueProjects.map((project) => (
          <MenuItem 
            key={project}
            onClick={() => handleProjectFilterClose(project)}
            selected={projectFilter === project}
          >
            {project}
          </MenuItem>
        ))}
      </Menu>

      <Menu
        anchorEl={menuAnchorEl}
        open={Boolean(menuAnchorEl)}
        onClose={handleMenuClose}
        PaperProps={{
          sx: {
            mt: 1,
            '& .MuiMenuItem-root': {
              px: 2,
              py: 1,
              borderRadius: 1,
              '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.04)'
              }
            }
          }
        }}
      >
        <MenuItem onClick={handleDuplicate}>
          <ListItemIcon>
            <ContentCopyIcon fontSize="small" sx={{ color: '#2196f3' }} />
          </ListItemIcon>
          Duplicate
        </MenuItem>
        <MenuItem onClick={handleDelete}>
          <ListItemIcon>
            <DeleteOutlineIcon fontSize="small" sx={{ color: '#f44336' }} />
          </ListItemIcon>
          Delete
        </MenuItem>
      </Menu>
    </div>
  );
};

export default AdminEstimates;
  