import React from 'react';
import { Box, Typography, Paper } from '@mui/material';
import ConstructionIcon from '@mui/icons-material/Construction';

const Documents = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        p: 3,
        backgroundColor: 'var(--background-default)'
      }}
    >
      <Paper
        elevation={0}
        sx={{
          p: 4,
          textAlign: 'center',
          borderRadius: 2,
          backgroundColor: 'var(--background-paper)'
        }}
      >
        <ConstructionIcon 
          sx={{ 
            fontSize: 48, 
            color: 'var(--text-secondary)',
            mb: 2
          }} 
        />
        <Typography
          variant="h6"
          sx={{
            color: 'var(--text-primary)',
            fontWeight: 600,
            mb: 1
          }}
        >
          Under Construction
        </Typography>
        <Typography
          sx={{
            color: 'var(--text-secondary)',
            maxWidth: 400
          }}
        >
          This feature is currently being developed. Check back soon for updates!
        </Typography>
      </Paper>
    </Box>
  );
};

export default Documents; 