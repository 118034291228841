import React, { useState } from 'react';
import {
  Box,
  Grid,
  Card,
  Typography,
  Switch,
  TextField,
  Button,
  FormControlLabel,
  Alert,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from '@mui/material';
import {
  Save as SaveIcon,
  Domain as DomainIcon,
  Gavel as ComplianceIcon,
  Notifications as NotificationsIcon,
  Schedule as ScheduleIcon,
  Language as LanguageIcon,
} from '@mui/icons-material';

const SettingSection = ({ title, description, children }) => (
  <Card sx={{ p: 3, borderRadius: 2, mb: 3 }}>
    <Typography variant="h6" sx={{ mb: 1 }}>
      {title}
    </Typography>
    <Typography variant="body2" color="text.secondary" sx={{ mb: 3 }}>
      {description}
    </Typography>
    {children}
  </Card>
);

const CampaignSettings = () => {
  const [settings, setSettings] = useState({
    domains: {
      primaryDomain: 'marketing.example.com',
      trackingDomain: 'track.example.com',
      customDomains: true,
      sslEnabled: true,
    },
    compliance: {
      gdprEnabled: true,
      ccpaEnabled: true,
      unsubscribeHeader: true,
      dataRetention: 90,
      consentTracking: true,
    },
    notifications: {
      email: true,
      push: false,
      slack: true,
      frequency: 'daily',
    },
    localization: {
      timezone: 'UTC',
      language: 'en',
      dateFormat: 'MM/DD/YYYY',
      currency: 'USD',
    }
  });

  const handleSettingChange = (section, setting) => (event) => {
    setSettings(prev => ({
      ...prev,
      [section]: {
        ...prev[section],
        [setting]: event.target.type === 'checkbox' ? event.target.checked : event.target.value
      }
    }));
  };

  const handleSave = () => {
    console.log('Saving settings:', settings);
  };

  return (
    <Box sx={{ p: 3 }}>
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'space-between',
        alignItems: 'center',
        mb: 4
      }}>
        <Typography variant="h5" sx={{ fontWeight: 600 }}>
          Campaign Settings
        </Typography>
        <Button
          variant="contained"
          startIcon={<SaveIcon />}
          onClick={handleSave}
          sx={{
            borderRadius: 2,
            textTransform: 'none',
            background: 'linear-gradient(45deg, #2196f3 30%, #21cbf3 90%)',
          }}
        >
          Save Changes
        </Button>
      </Box>

      <Grid container spacing={3}>
        <Grid item xs={12} md={8}>
          <SettingSection
            title="Domain Settings"
            description="Configure campaign tracking and sending domains"
          >
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Primary Domain"
                  value={settings.domains.primaryDomain}
                  onChange={handleSettingChange('domains', 'primaryDomain')}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Tracking Domain"
                  value={settings.domains.trackingDomain}
                  onChange={handleSettingChange('domains', 'trackingDomain')}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={settings.domains.customDomains}
                      onChange={handleSettingChange('domains', 'customDomains')}
                    />
                  }
                  label="Enable Custom Domains"
                />
                <FormControlLabel
                  control={
                    <Switch
                      checked={settings.domains.sslEnabled}
                      onChange={handleSettingChange('domains', 'sslEnabled')}
                    />
                  }
                  label="Enable SSL"
                />
              </Grid>
            </Grid>
          </SettingSection>

          <SettingSection
            title="Compliance Settings"
            description="Manage data protection and privacy settings"
          >
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={settings.compliance.gdprEnabled}
                      onChange={handleSettingChange('compliance', 'gdprEnabled')}
                    />
                  }
                  label="GDPR Compliance"
                />
                <FormControlLabel
                  control={
                    <Switch
                      checked={settings.compliance.ccpaEnabled}
                      onChange={handleSettingChange('compliance', 'ccpaEnabled')}
                    />
                  }
                  label="CCPA Compliance"
                />
                <FormControlLabel
                  control={
                    <Switch
                      checked={settings.compliance.unsubscribeHeader}
                      onChange={handleSettingChange('compliance', 'unsubscribeHeader')}
                    />
                  }
                  label="Include Unsubscribe Header"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  type="number"
                  label="Data Retention Period (days)"
                  value={settings.compliance.dataRetention}
                  onChange={handleSettingChange('compliance', 'dataRetention')}
                />
              </Grid>
            </Grid>
          </SettingSection>
        </Grid>

        <Grid item xs={12} md={4}>
          <Alert severity="info" sx={{ mb: 3, borderRadius: 2 }}>
            Changes will be applied to all future campaigns
          </Alert>
          
          <SettingSection
            title="Localization"
            description="Configure regional and language settings"
          >
            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel>Timezone</InputLabel>
              <Select
                value={settings.localization.timezone}
                onChange={handleSettingChange('localization', 'timezone')}
                label="Timezone"
              >
                <MenuItem value="UTC">UTC</MenuItem>
                <MenuItem value="EST">EST</MenuItem>
                <MenuItem value="PST">PST</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel>Language</InputLabel>
              <Select
                value={settings.localization.language}
                onChange={handleSettingChange('localization', 'language')}
                label="Language"
              >
                <MenuItem value="en">English</MenuItem>
                <MenuItem value="es">Spanish</MenuItem>
                <MenuItem value="fr">French</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth>
              <InputLabel>Currency</InputLabel>
              <Select
                value={settings.localization.currency}
                onChange={handleSettingChange('localization', 'currency')}
                label="Currency"
              >
                <MenuItem value="USD">USD ($)</MenuItem>
                <MenuItem value="EUR">EUR (€)</MenuItem>
                <MenuItem value="GBP">GBP (£)</MenuItem>
              </Select>
            </FormControl>
          </SettingSection>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CampaignSettings;    