import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getEmployeeDetails, updateEmployeeNotes, updateEmployeeRate, updateEmployeeDetails, getEmployeeAvailability, updateEmployeeAvailability, updateRecurringAvailability } from '../services/api';
import { 
  Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, 
  CircularProgress, Box, Grid, Button, Paper, IconButton, List, ListItem, ListItemIcon, ListItemText,
  Accordion, AccordionSummary, AccordionDetails, TextField, InputAdornment, Avatar, Snackbar, Alert,
  Backdrop, LinearProgress, FormGroup, 
  FormControlLabel, 
  Checkbox, 
  Select, 
  MenuItem, 
  FormControl, 
  InputLabel,
  Card,
  CardContent,
  RadioGroup,
  Radio
} from '@mui/material';
import { 
  Person, ListAlt, Schedule, EventNote, HourglassEmpty, 
  WorkOutline, Event, CheckCircleOutline, Email, Phone, Work, Home,
  ExpandMore, CalendarToday, Description, Business, Search, ArrowBack,
  AttachMoney, AccessTime, Edit, Security
} from '@mui/icons-material';
import styles from './EmployeeDetails.module.scss';
import AvailabilityCalendar from './shared/AvailabilityCalendar';
import RecurringAvailabilityModal from './shared/RecurringAvailabilityModal';

const EmployeeDetails = () => {
  const [employee, setEmployee] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState('details');
  const [searchTerm, setSearchTerm] = useState('');
  const [notes, setNotes] = useState('');  // Add this line
  const [isEditingRate, setIsEditingRate] = useState(false);
  const [editedRate, setEditedRate] = useState('');
  const [rateError, setRateError] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [editedDetails, setEditedDetails] = useState({});
  const [showNotification, setShowNotification] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  const [availabilityDates, setAvailabilityDates] = useState([]);
  const [showRecurringModal, setShowRecurringModal] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [progress, setProgress] = useState({ current: 0, total: 0 }); // Add this for progress tracking
  const [selectedRole, setSelectedRole] = useState('CUSTOM');
  const [permissions, setPermissions] = useState({
    isAdmin: false,
    canViewProjects: false,
    canEditProjects: false,
    canDeleteProjects: false,
    canManageEmployees: false,
    canViewClients: false,
    canEditClients: false,
    canManageInvoices: false,
    canAccessReports: false,
    canManageSettings: false,
    canViewTimesheets: false,
    canEditTimesheets: false,
    canApproveTimesheets: false,
  });
  const [currentAvailableDays, setCurrentAvailableDays] = useState([]);

  const ROLES = {
    ADMIN: {
      label: 'Administrator',
      description: 'Full access to all features and settings',
      permissions: {
        isAdmin: true,
        canViewSchedule: true,
        canEditSchedule: true,
        canManageSchedule: true,
        canViewProjects: true,
        canEditProjects: true,
        canDeleteProjects: true,
        canViewClients: true,
        canEditClients: true,
        canManageClients: true,
        canViewTimesheets: true,
        canEditTimesheets: true,
        canApproveTimesheets: true,
        canViewEstimates: true,
        canCreateEstimates: true,
        canManageEstimates: true,
        canViewInvoices: true,
        canCreateInvoices: true,
        canManageInvoices: true,
      }
    },
    MANAGER: {
      label: 'Manager',
      description: 'Can manage team and projects, but cannot access billing or system settings',
      permissions: {
        isAdmin: false,
        canViewSchedule: true,
        canEditSchedule: true,
        canManageSchedule: true,
        canViewProjects: true,
        canEditProjects: true,
        canDeleteProjects: false,
        canViewClients: true,
        canEditClients: true,
        canManageClients: false,
        canViewTimesheets: true,
        canEditTimesheets: true,
        canApproveTimesheets: true,
        canViewEstimates: true,
        canCreateEstimates: true,
        canManageEstimates: false,
        canViewInvoices: true,
        canCreateInvoices: false,
        canManageInvoices: false,
      }
    },
    EMPLOYEE: {
      label: 'Employee',
      description: 'Basic access to their own schedule and timesheets',
      permissions: {
        isAdmin: false,
        canViewSchedule: true,
        canEditSchedule: false,
        canManageSchedule: false,
        canViewProjects: true,
        canEditProjects: false,
        canDeleteProjects: false,
        canViewClients: false,
        canEditClients: false,
        canManageClients: false,
        canViewTimesheets: true,
        canEditTimesheets: true,
        canApproveTimesheets: false,
        canViewEstimates: false,
        canCreateEstimates: false,
        canManageEstimates: false,
        canViewInvoices: false,
        canCreateInvoices: false,
        canManageInvoices: false,
      }
    },
    CUSTOM: {
      label: 'Custom',
      description: 'Customized permissions',
      permissions: {} // Empty as it will use whatever is currently set
    }
  };

  useEffect(() => {
    const fetchEmployeeDetails = async () => {
      try {
        const data = await getEmployeeDetails(id);
        setEmployee(data);
        setNotes(data.notes || '');  // Add this line
        setLoading(false);
      } catch (error) {
        console.error('Error fetching employee details:', error);
        setError('Failed to fetch employee details');
        setLoading(false);
      }
    };

    fetchEmployeeDetails();
  }, [id]);

  useEffect(() => {
    if (employee?.id) {
      fetchEmployeeAvailability();
    }
  }, [employee?.id]);

  const fetchEmployeeAvailability = async () => {
    if (!employee?.id) return;
    
    try {
      console.log('Fetching availability for employee:', employee.id);
      const data = await getEmployeeAvailability(employee.id);
      console.log('Received availability data:', data);
      setAvailabilityDates(data);
    } catch (error) {
      console.error('Error fetching availability:', error);
    }
  };

  useEffect(() => {
    if (availabilityDates.length > 0) {
      // Get unique days from availability dates
      const uniqueDays = new Set(
        availabilityDates.map(date => new Date(date.start_time).getDay())
      );
      const sortedDays = Array.from(uniqueDays).sort((a, b) => a - b);
      console.log('Current available days:', sortedDays);
      setCurrentAvailableDays(sortedDays);
    } else {
      setCurrentAvailableDays([]);
    }
  }, [availabilityDates]);

  if (loading) return <div className="loading"><CircularProgress /></div>;
  if (error) return <div className="error"><Typography color="error">{error}</Typography></div>;
  if (!employee) return <div className="error"><Typography>No employee data found</Typography></div>;

  const renderDetails = () => (
    <Box sx={{ p: 3, backgroundColor: 'var(--background-paper)', borderRadius: 2, boxShadow: '0 0 10px rgba(0,0,0,0.1)' }}>
      
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center',
        mb: 3,
      }}>
        <Typography 
          variant="h6" 
          gutterBottom 
          sx={{ 
            fontFamily: 'Urbanist, sans-serif',
            color: 'var(--text-primary)',
            fontWeight: 600,
            m: 0
          }}
        >
          Employee Details
        </Typography>
        {!isEditing ? (
          <Button
            variant="outlined"
            startIcon={<Edit />}
            onClick={handleEditDetails}
            sx={{
              borderRadius: '8px',
              textTransform: 'none',
              fontFamily: 'Urbanist, sans-serif'
            }}
          >
            Edit Details
          </Button>
        ) : (
          <Box sx={{ display: 'flex', gap: 1 }}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSaveDetails}
              sx={{
                borderRadius: '8px',
                textTransform: 'none',
                fontFamily: 'Urbanist, sans-serif'
              }}
            >
              Save Changes
            </Button>
            <Button
              variant="outlined"
              color="error"
              onClick={() => setIsEditing(false)}
              sx={{
                borderRadius: '8px',
                textTransform: 'none',
                fontFamily: 'Urbanist, sans-serif'
              }}
            >
              Cancel
            </Button>
          </Box>
        )}
      </Box>

      <Grid container spacing={4}>
        {/* Personal Information */}
        <Grid item xs={12} md={6}>
          <Box sx={{ 
            backgroundColor: 'var(--background-paper)',
            p: 3,
            borderRadius: 2,
            height: '100%',
            border: '1px solid var(--divider)'
          }}>
            <Typography 
              variant="subtitle1" 
              sx={{ 
                mb: 2, 
                fontWeight: 600,
                color: 'var(--text-primary)'
              }}
            >
              Personal Information
            </Typography>
            
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Person sx={{ mr: 2, color: '#4CAF50' }} />
                <Box>
                  <Typography variant="caption" sx={{ color: 'var(--text-secondary)' }}>
                    Full Name
                  </Typography>
                  <Typography variant="body1" sx={{ fontWeight: 500, color: 'var(--text-primary)' }}>
                    {isEditing ? (
                      <TextField
                        fullWidth
                        size="small"
                        value={editedDetails.name}
                        onChange={(e) => handleInputChange('name', e.target.value)}
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            backgroundColor: 'var(--background-secondary)',
                            color: 'var(--text-primary)',
                            '& fieldset': {
                              borderColor: 'var(--divider)'
                            },
                            '&:hover fieldset': {
                              borderColor: 'var(--divider)'
                            },
                            '&.Mui-focused fieldset': {
                              borderColor: 'primary.main'
                            }
                          }
                        }}
                      />
                    ) : (
                      employee.name
                    )}
                  </Typography>
                </Box>
              </Box>

              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Email sx={{ mr: 2, color: '#2196F3' }} />
                <Box>
                  <Typography variant="caption" sx={{ color: '#64748b' }}>
                    Email Address
                  </Typography>
                  <Typography variant="body1" sx={{ fontWeight: 500 }}>
                    {isEditing ? (
                      <TextField
                        fullWidth
                        size="small"
                        value={editedDetails.email}
                        onChange={(e) => handleInputChange('email', e.target.value)}
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            backgroundColor: 'white'
                          }
                        }}
                      />
                    ) : (
                      employee.email
                    )}
                  </Typography>
                </Box>
              </Box>

              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Phone sx={{ mr: 2, color: '#FFC107' }} />
                <Box>
                  <Typography variant="caption" sx={{ color: '#64748b' }}>
                    Phone Number
                  </Typography>
                  <Typography variant="body1" sx={{ fontWeight: 500 }}>
                    {isEditing ? (
                      <TextField
                        fullWidth
                        size="small"
                        value={editedDetails.phone}
                        onChange={(e) => handleInputChange('phone', e.target.value)}
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            backgroundColor: 'white'
                          }
                        }}
                      />
                    ) : (
                      employee.phone
                    )}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Grid>

        {/* Work Information */}
        <Grid item xs={12} md={6}>
          <Box sx={{ 
            backgroundColor: 'var(--background-paper)',
            p: 3,
            borderRadius: 2,
            height: '100%'
          }}>
            <Typography 
              variant="subtitle1" 
              sx={{ 
                mb: 2, 
                fontWeight: 600,
                color: '#475569'
              }}
            >
              Work Information
            </Typography>
            
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Work sx={{ mr: 2, color: '#9C27B0' }} />
                <Box>
                  <Typography variant="caption" sx={{ color: '#64748b' }}>
                    Position
                  </Typography>
                  <Typography variant="body1" sx={{ fontWeight: 500 }}>
                    {isEditing ? (
                      <TextField
                        fullWidth
                        size="small"
                        value={editedDetails.position}
                        onChange={(e) => handleInputChange('position', e.target.value)}
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            backgroundColor: 'white'
                          }
                        }}
                      />
                    ) : (
                      employee.position
                    )}
                  </Typography>
                </Box>
              </Box>

              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <AttachMoney sx={{ mr: 2, color: '#4CAF50' }} />
                <Box>
                  <Typography variant="caption" sx={{ color: '#64748b' }}>
                    Default Rate
                  </Typography>
                  {isEditingRate ? (
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <TextField
                        size="small"
                        value={editedRate}
                        onChange={(e) => setEditedRate(e.target.value)}
                        error={!!rateError}
                        helperText={rateError}
                        InputProps={{
                          startAdornment: <InputAdornment position="start">$</InputAdornment>,
                          endAdornment: <InputAdornment position="end">/hr</InputAdornment>,
                        }}
                        sx={{ width: '150px' }}
                      />
                      <Button 
                        onClick={handleRateUpdate}
                        variant="contained" 
                        size="small"
                        sx={{ mr: 1 }}
                      >
                        Save
                      </Button>
                      <Button 
                        onClick={() => {
                          setIsEditingRate(false);
                          setRateError('');
                        }}
                        variant="outlined" 
                        size="small"
                      >
                        Cancel
                      </Button>
                    </Box>
                  ) : (
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Typography variant="body1">
                        ${employee.default_rate}/hr
                      </Typography>
                      <IconButton 
                        size="small" 
                        onClick={() => {
                          setEditedRate(employee.default_rate.toString());
                          setIsEditingRate(true);
                        }}
                        sx={{ ml: 1 }}
                      >
                        <Edit fontSize="small" />
                      </IconButton>
                    </Box>
                  )}
                </Box>
              </Box>

              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Home sx={{ mr: 2, color: '#E91E63' }} />
                <Box>
                  <Typography variant="caption" sx={{ color: '#64748b' }}>
                    Address
                  </Typography>
                  <Typography variant="body1" sx={{ fontWeight: 500 }}>
                    {isEditing ? (
                      <TextField
                        fullWidth
                        size="small"
                        value={editedDetails.address}
                        onChange={(e) => handleInputChange('address', e.target.value)}
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            backgroundColor: 'white'
                          }
                        }}
                      />
                    ) : (
                      employee.address
                    )}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Grid>

        {/* Notes Section */}
        <Grid item xs={12}>
          <Box sx={{ 
            backgroundColor: 'var(--background-paper)',
            p: 3,
            borderRadius: 2,
            border: '1px solid var(--divider)'
          }}>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
              <EventNote sx={{ mr: 2, color: '#FF5722' }} />
              <Typography 
                variant="subtitle1" 
                sx={{ 
                  fontWeight: 600,
                  color: 'var(--text-primary)'
                }}
              >
                Notes
              </Typography>
            </Box>
            
            <TextField
              multiline
              rows={4}
              fullWidth
              variant="outlined"
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
              sx={{ 
                backgroundColor: 'var(--background-secondary)',
                '& .MuiOutlinedInput-root': {
                  color: 'var(--text-primary)',
                  '& fieldset': {
                    borderColor: 'var(--divider)',
                  },
                  '&:hover fieldset': {
                    borderColor: 'var(--divider)',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: 'primary.main',
                  },
                },
                '& .MuiInputBase-input': {
                  color: 'var(--text-primary)',
                }
              }}
            />
            <Button 
              variant="contained" 
              onClick={handleSaveNotes}
              sx={{ 
                mt: 2,
                backgroundColor: 'primary.main',
                '&:hover': {
                  backgroundColor: 'primary.dark',
                },
                textTransform: 'none',
                fontWeight: 500,
                color: 'white'
              }}
            >
              Save Notes
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );

  const headerCells = [
    { icon: <Schedule style={{ color: '#4CAF50' }} />, label: 'CLOCK IN' },
    { icon: <EventNote style={{ color: '#2196F3' }} />, label: 'CLOCK OUT' },
    { icon: <HourglassEmpty style={{ color: '#FFC107' }} />, label: 'HOURS WORKED' },
    { icon: <WorkOutline style={{ color: '#9C27B0' }} />, label: 'PROJECT' },
    { icon: <Event style={{ color: '#E91E63' }} />, label: 'EVENT' },
    { icon: <CheckCircleOutline style={{ color: '#009688' }} />, label: 'CALCULATED PAYMENT' },
  ];

  const renderTimesheets = () => {
    const validTimesheets = employee.recent_timesheets.filter(timesheet => 
      timesheet.calculated_payment !== null &&
      timesheet.clock_in &&
      timesheet.clock_out &&
      timesheet.hours_worked !== null &&
      timesheet.event &&
      timesheet.event.project_name &&
      timesheet.event.title
    );

    console.log('Filtered timesheet data:', validTimesheets);

    return (
      <div className="timesheets-container">
        <Typography variant="h6" gutterBottom sx={{ fontFamily: 'Urbanist, sans-serif' }}>Recent Timesheets</Typography>
        {validTimesheets.length > 0 ? (
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  {headerCells.map((cell, index) => (
                    <TableCell key={index} align="center" sx={{ color: 'var(--text-primary)', backgroundColor: 'var(--background-paper)' }}>
                      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        {cell.icon}
                        <Typography variant="caption" sx={{ mt: 0.5, fontFamily: 'Urbanist, sans-serif' }}>
                          {cell.label}
                        </Typography>
                      </Box>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {validTimesheets.map((timesheet, index) => (
                  <TableRow key={timesheet.id} className={index % 2 === 0 ? 'table-row even' : 'table-row odd'}>
                    <TableCell className="table-cell" align="center" sx={{ color: 'var(--text-primary)', backgroundColor: 'var(--background-paper)' }}>{new Date(timesheet.clock_in).toLocaleString()}</TableCell>
                    <TableCell className="table-cell" align="center" sx={{ color: 'var(--text-primary)', backgroundColor: 'var(--background-paper)' }}>{new Date(timesheet.clock_out).toLocaleString()}</TableCell>
                    <TableCell className="table-cell" align="center" sx={{ color: 'var(--text-primary)', backgroundColor: 'var(--background-paper)' }}>{timesheet.hours_worked.toFixed(2)}</TableCell>
                    <TableCell className="table-cell" align="center" sx={{ color: 'var(--text-primary)', backgroundColor: 'var(--background-paper)' }}>{timesheet.event.project_name}</TableCell>
                    <TableCell className="table-cell" align="center" sx={{ color: 'var(--text-primary)', backgroundColor: 'var(--background-paper)' }}>{timesheet.event.title}</TableCell>
                    <TableCell className="table-cell" align="center" sx={{ color: 'var(--text-primary)', backgroundColor: 'var(--background-paper)' }}>
                      ${timesheet.calculated_payment.toFixed(2)}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Typography className="no-data" align="center" sx={{ fontFamily: 'Urbanist, sans-serif' }}>
            No valid timesheets
          </Typography>
        )}
      </div>
    );
  };

  const renderEvents = () => {
    if (!employee.assigned_events || employee.assigned_events.length === 0) {
      return (
        <Typography variant="body1" sx={{ fontFamily: 'Urbanist, sans-serif', textAlign: 'center', mt: 2 }}>
          No events assigned to this employee.
        </Typography>
      );
    }

    const currentDate = new Date();
    const upcomingEvents = employee.assigned_events.filter(event => 
      new Date(event.time_slots[event.time_slots.length - 1].end_time) >= currentDate
    );
    const completedEvents = employee.assigned_events.filter(event => 
      new Date(event.time_slots[event.time_slots.length - 1].end_time) < currentDate
    );

    console.log("All assigned events:", employee.assigned_events);
    console.log("Upcoming events:", upcomingEvents);
    console.log("Completed events:", completedEvents);

    const renderEventList = (events, title) => (
      <div>
        <Typography variant="h6" gutterBottom sx={{ fontFamily: 'Urbanist, sans-serif', mb: 2 }}>
          <Event sx={{ verticalAlign: 'middle', mr: 1, color: '#4CAF50' }} />
          {title}
        </Typography>
        <Box sx={{ mb: 2 }}>
          <TextField
            fullWidth
            size="small"
            variant="outlined"
            placeholder={`Search ${title.toLowerCase()}`}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
          />
        </Box>
        {events
          .filter(event => event.title.toLowerCase().includes(searchTerm.toLowerCase()))
          .map((event, index) => {
            console.log(`Event ${index}:`, event);
            
            // Find all timesheets for this event
            const eventTimesheets = employee.recent_timesheets.filter(ts => ts.event && ts.event.id === event.id);
            console.log(`Timesheets for event ${index}:`, eventTimesheets);

            // Calculate total payment and hours
            const totalPayment = eventTimesheets.reduce((sum, ts) => sum + (ts.calculated_payment || 0), 0);
            const totalHours = eventTimesheets.reduce((sum, ts) => sum + (ts.hours_worked || 0), 0);

            return (
              <Accordion key={event.id} sx={{ mb: 2, boxShadow: '0px 2px 4px rgba(0,0,0,0.1)' }}>
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls={`panel${index}a-content`}
                  id={`panel${index}a-header`}
                  sx={{ 
                    backgroundColor: 'rgba(0, 0, 0, 0.03)', 
                    '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.05)' } 
                  }}
                >
                  <Typography sx={{ fontFamily: 'Urbanist, sans-serif', fontWeight: 'medium', display: 'flex', alignItems: 'center' }}>
                    <CalendarToday sx={{ mr: 1, fontSize: 20, color: '#2196F3' }} />
                    {event.title}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ p: 2 }}>
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1.5 }}>
                    <Typography variant="body2" sx={{ fontFamily: 'Urbanist, sans-serif', display: 'flex', alignItems: 'center' }}>
                      <Description sx={{ mr: 1, fontSize: 20, color: '#FFC107' }} />
                      <strong>Description:</strong> {event.description}
                    </Typography>
                    {event.project && (
                      <Typography variant="body2" sx={{ fontFamily: 'Urbanist, sans-serif', display: 'flex', alignItems: 'center' }}>
                        <Business sx={{ mr: 1, fontSize: 20, color: '#9C27B0' }} />
                        <strong>Project:</strong> {event.project.name}
                      </Typography>
                    )}
                    {totalPayment > 0 && (
                      <Typography variant="body2" sx={{ fontFamily: 'Urbanist, sans-serif', display: 'flex', alignItems: 'center' }}>
                        <AttachMoney sx={{ mr: 1, fontSize: 20, color: '#4CAF50' }} />
                        <strong>Total Payment:</strong> ${totalPayment.toFixed(2)}
                      </Typography>
                    )}
                    {totalHours > 0 && (
                      <Typography variant="body2" sx={{ fontFamily: 'Urbanist, sans-serif', display: 'flex', alignItems: 'center' }}>
                        <AccessTime sx={{ mr: 1, fontSize: 20, color: '#FF9800' }} />
                        <strong>Total Hours Worked:</strong> {totalHours.toFixed(2)}
                      </Typography>
                    )}
                    <Typography variant="body2" sx={{ fontFamily: 'Urbanist, sans-serif', mt: 1, mb: 0.5, fontWeight: 'medium' }}>
                      <Schedule sx={{ mr: 1, fontSize: 20, verticalAlign: 'middle', color: '#E91E63' }} />
                      Time Slots:
                    </Typography>
                    <List dense sx={{ pl: 2, mt: -1 }}>
                      {event.time_slots.map((slot, slotIndex) => (
                        <ListItem key={slotIndex} sx={{ py: 0.5 }}>
                          <ListItemIcon sx={{ minWidth: 36 }}>
                            <Schedule fontSize="small" sx={{ color: '#009688' }} />
                          </ListItemIcon>
                          <ListItemText 
                            primary={`${new Date(slot.start_time).toLocaleString()} - ${new Date(slot.end_time).toLocaleString()}`}
                            primaryTypographyProps={{ variant: 'body2', fontFamily: 'Urbanist, sans-serif' }}
                          />
                        </ListItem>
                      ))}
                    </List>
                  </Box>
                </AccordionDetails>
              </Accordion>
            );
          })}
      </div>
    );

    return (
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          {renderEventList(upcomingEvents, "Upcoming Events")}
        </Grid>
        <Grid item xs={12} md={6}>
          {renderEventList(completedEvents, "Completed Events")}
        </Grid>
      </Grid>
    );
  };

  const handleSaveNotes = async () => {
    try {
      await updateEmployeeNotes(id, notes);
      setShowNotification(true);
      setTimeout(() => setShowNotification(false), 3000); // Hide after 3 seconds
    } catch (error) {
      console.error('Error saving notes:', error);
      // You might want to show an error message here
    }
  };

  const handleRateUpdate = async () => {
    try {
      setRateError('');
      const numRate = parseFloat(editedRate);
      
      if (isNaN(numRate) || numRate < 0) {
        setRateError('Please enter a valid rate');
        return;
      }

      await updateEmployeeRate(id, numRate);
      setEmployee(prev => ({
        ...prev,
        default_rate: numRate
      }));
      setIsEditingRate(false);
    } catch (error) {
      setRateError('Failed to update rate');
      console.error('Error updating rate:', error);
    }
  };

  const handleEditDetails = () => {
    setIsEditing(true);
    setEditedDetails({
      name: employee.name,
      email: employee.email,
      phone: employee.phone,
      position: employee.position,
      address: employee.address,
    });
  };

  const handleSaveDetails = async () => {
    try {
      await updateEmployeeDetails(id, editedDetails);
      setEmployee(prev => ({
        ...prev,
        ...editedDetails
      }));
      setIsEditing(false);
    } catch (error) {
      console.error('Error updating employee details:', error);
    }
  };

  const handleInputChange = (field, value) => {
    setEditedDetails(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const handleAvailabilityChange = async (info) => {
    console.log('Received from calendar:', info); // Should have date and is_available

    try {
      // Use the data directly from the calendar
      await updateEmployeeAvailability(
        employee.id,
        info.date,           // Use this directly
        info.is_available    // Use this directly
      );

      // Refresh after successful update
      await fetchEmployeeAvailability();
    } catch (error) {
      console.error('Error updating availability:', error);
    }
  };

  const handleRecurringAvailability = async (data) => {
    try {
      setIsUpdating(true);
      
      if (data.selectedDays.length > 0) {
        await updateRecurringAvailability(
          employee.id,
          data.selectedDays,
          data.startDate,
          data.endDate,
          true
        );
      }

      if (data.removedDays.length > 0) {
        await updateRecurringAvailability(
          employee.id,
          data.removedDays,
          data.startDate,
          data.endDate,
          false
        );
      }

      await fetchEmployeeAvailability();
      setShowRecurringModal(false);
    } catch (error) {
      console.error('Error updating recurring availability:', error);
      setError('Failed to update recurring availability');
    } finally {
      setIsUpdating(false);
    }
  };

  const handleRoleChange = (role) => {
    setSelectedRole(role);
    if (role !== 'CUSTOM') {
      setPermissions(ROLES[role].permissions);
    }
  };

  const handlePermissionChange = (permission) => {
    setPermissions(prev => {
      const newPermissions = {
        ...prev,
        [permission]: !prev[permission]
      };
      // If permissions don't match any predefined role, set to CUSTOM
      const matchingRole = Object.entries(ROLES).find(([role, data]) => 
        JSON.stringify(data.permissions) === JSON.stringify(newPermissions)
      );
      if (!matchingRole) {
        setSelectedRole('CUSTOM');
      }
      return newPermissions;
    });
  };
  const handleSavePermissions = async () => {
    try {
      setIsUpdating(true);
      
      // Will implement API call once backend is ready
      // const response = await updateEmployeePermissions(employee.id, {
      //   role: selectedRole,
      //   permissions: permissions
      // });
  
      // Show success notification
      setShowNotification(true);
      setTimeout(() => setShowNotification(false), 3000);
  
      // Refresh employee details
      const updatedEmployee = await getEmployeeDetails(employee.id);
      setEmployee(updatedEmployee);
      
    } catch (error) {
      console.error('Error saving permissions:', error);
      setError('Failed to update permissions');
    } finally {
      setIsUpdating(false);
    }
  };

  const renderPermissions = () => (
    <Box sx={{ p: 3, backgroundColor: 'white', borderRadius: 2 }}>
      <Typography variant="h6" sx={{ mb: 3, fontWeight: 600 }}>
        Permissions
      </Typography>

      {/* Role Selection */}
      <Box sx={{ mb: 4 }}>
        <Typography variant="h6" sx={{ mb: 2, fontWeight: 600 }}>
          Role
        </Typography>
        <FormControl component="fieldset">
          <RadioGroup
            value={selectedRole}
            onChange={(e) => handleRoleChange(e.target.value)}
          >
            {Object.entries(ROLES).map(([key, role]) => (
              <FormControlLabel
                key={key}
                value={key}
                control={<Radio />}
                label={
                  <Box>
                    <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
                      {role.label}
                    </Typography>
                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                      {role.description}
                    </Typography>
                  </Box>
                }
              />
            ))}
          </RadioGroup>
        </FormControl>
      </Box>

      {/* Grid Layout for Permission Groups */}
      <Grid container spacing={4}>
        {/* Schedule Permissions */}
        <Grid item xs={12} md={6}>
          <Box sx={{ mb: 4 }}>
            <Typography variant="h6" sx={{ mb: 2, fontWeight: 600 }}>
              Schedule
            </Typography>
            <FormGroup>
              <FormControlLabel
                control={<Checkbox checked={permissions.canViewSchedule} onChange={(e) => handlePermissionChange('canViewSchedule', e.target.checked)} />}
                label="View their own schedule"
              />
              <FormControlLabel
                control={<Checkbox checked={permissions.canEditSchedule} onChange={(e) => handlePermissionChange('canEditSchedule', e.target.checked)} />}
                label="Edit their own schedule"
              />
              <FormControlLabel
                control={<Checkbox checked={permissions.canManageSchedule} onChange={(e) => handlePermissionChange('canManageSchedule', e.target.checked)} />}
                label="Manage all schedules"
              />
            </FormGroup>
          </Box>
        </Grid>

        {/* Project Permissions */}
        <Grid item xs={12} md={6}>
          <Box sx={{ mb: 4 }}>
            <Typography variant="h6" sx={{ mb: 2, fontWeight: 600 }}>
              Projects
            </Typography>
            <FormGroup>
              <FormControlLabel
                control={<Checkbox checked={permissions.canViewProjects} onChange={(e) => handlePermissionChange('canViewProjects', e.target.checked)} />}
                label="View projects"
              />
              <FormControlLabel
                control={<Checkbox checked={permissions.canEditProjects} onChange={(e) => handlePermissionChange('canEditProjects', e.target.checked)} />}
                label="Edit projects"
              />
              <FormControlLabel
                control={<Checkbox checked={permissions.canDeleteProjects} onChange={(e) => handlePermissionChange('canDeleteProjects', e.target.checked)} />}
                label="Delete projects"
              />
            </FormGroup>
          </Box>
        </Grid>

        {/* Estimates Permissions */}
        <Grid item xs={12} md={6}>
          <Box sx={{ mb: 4 }}>
            <Typography variant="h6" sx={{ mb: 2, fontWeight: 600 }}>
              Estimates
            </Typography>
            <FormGroup>
              <FormControlLabel
                control={<Checkbox checked={permissions.canViewEstimates} onChange={(e) => handlePermissionChange('canViewEstimates', e.target.checked)} />}
                label="View estimates"
              />
              <FormControlLabel
                control={<Checkbox checked={permissions.canCreateEstimates} onChange={(e) => handlePermissionChange('canCreateEstimates', e.target.checked)} />}
                label="Create estimates"
              />
              <FormControlLabel
                control={<Checkbox checked={permissions.canManageEstimates} onChange={(e) => handlePermissionChange('canManageEstimates', e.target.checked)} />}
                label="Manage all estimates"
              />
            </FormGroup>
          </Box>
        </Grid>

        {/* Invoice Permissions */}
        <Grid item xs={12} md={6}>
          <Box sx={{ mb: 4 }}>
            <Typography variant="h6" sx={{ mb: 2, fontWeight: 600 }}>
              Invoices
            </Typography>
            <FormGroup>
              <FormControlLabel
                control={<Checkbox checked={permissions.canViewInvoices} onChange={(e) => handlePermissionChange('canViewInvoices', e.target.checked)} />}
                label="View invoices"
              />
              <FormControlLabel
                control={<Checkbox checked={permissions.canCreateInvoices} onChange={(e) => handlePermissionChange('canCreateInvoices', e.target.checked)} />}
                label="Create invoices"
              />
              <FormControlLabel
                control={<Checkbox checked={permissions.canManageInvoices} onChange={(e) => handlePermissionChange('canManageInvoices', e.target.checked)} />}
                label="Manage all invoices"
              />
            </FormGroup>
          </Box>
        </Grid>

        {/* Client Permissions */}
        <Grid item xs={12} md={6}>
          <Box sx={{ mb: 4 }}>
            <Typography variant="h6" sx={{ mb: 2, fontWeight: 600 }}>
              Clients
            </Typography>
            <FormGroup>
              <FormControlLabel
                control={<Checkbox checked={permissions.canViewClients} onChange={(e) => handlePermissionChange('canViewClients', e.target.checked)} />}
                label="View clients"
              />
              <FormControlLabel
                control={<Checkbox checked={permissions.canEditClients} onChange={(e) => handlePermissionChange('canEditClients', e.target.checked)} />}
                label="Edit clients"
              />
              <FormControlLabel
                control={<Checkbox checked={permissions.canManageClients} onChange={(e) => handlePermissionChange('canManageClients', e.target.checked)} />}
                label="Manage clients"
              />
            </FormGroup>
          </Box>
        </Grid>

        {/* Timesheet Permissions */}
        <Grid item xs={12} md={6}>
          <Box sx={{ mb: 4 }}>
            <Typography variant="h6" sx={{ mb: 2, fontWeight: 600 }}>
              Timesheets
            </Typography>
            <FormGroup>
              <FormControlLabel
                control={<Checkbox checked={permissions.canViewTimesheets} onChange={(e) => handlePermissionChange('canViewTimesheets', e.target.checked)} />}
                label="View timesheets"
              />
              <FormControlLabel
                control={<Checkbox checked={permissions.canEditTimesheets} onChange={(e) => handlePermissionChange('canEditTimesheets', e.target.checked)} />}
                label="Edit timesheets"
              />
              <FormControlLabel
                control={<Checkbox checked={permissions.canApproveTimesheets} onChange={(e) => handlePermissionChange('canApproveTimesheets', e.target.checked)} />}
                label="Approve timesheets"
              />
            </FormGroup>
          </Box>
        </Grid>
      </Grid>

      {/* Save Button */}
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3 }}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSavePermissions}
        >
          Save Permissions
        </Button>
      </Box>
    </Box>
  );

  const renderAvailability = () => (
    <Box sx={{ p: 3 }}>
      {/* Title and Description Container */}
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center',
        mb: 2 
      }}>
        <Typography variant="h6" sx={{ fontFamily: 'Urbanist, sans-serif' }}>
          Employee Availability Calendar
        </Typography>
        <Typography variant="body2" color="var(--text-secondary)">
          Click on dates to toggle availability
        </Typography>
      </Box>

      {/* Button Container */}
      <Box 
        sx={{ 
          display: 'flex',
          alignItems: 'center',
          mb: 3,
          borderBottom: '1px solid',
          borderColor: 'divider',
          pb: 2
        }}
      >
        <Box sx={{ flex: 1 }}>
          <Typography 
            variant="subtitle1" 
            sx={{ 
              mb: 0.5,
              fontWeight: 500,
              color: 'var(--text-primary)'
            }}
          >
            Recurring Availability
          </Typography>
          <Typography 
            variant="body2" 
            color="var(--text-secondary)"
            sx={{ fontWeight: 400 }}
          >
            Set regular weekly availability pattern
          </Typography>
        </Box>
        <Button 
          variant="outlined"
          onClick={() => setShowRecurringModal(true)}
          startIcon={<Schedule sx={{ fontSize: 20 }} />}
          sx={{ 
            minWidth: 200,
            height: 42,
            borderRadius: 2,
            textTransform: 'none',
            fontSize: '0.95rem',
            fontWeight: 500,
            border: '1px solid',
            borderColor: 'primary.main',
            color: 'primary.main',
            backgroundColor: 'transparent',
            '&:hover': {
              backgroundColor: 'primary.main',
              color: 'white',
              borderColor: 'primary.main',
            },
            transition: 'all 0.2s ease-in-out'
          }}
        >
          Set Weekly Schedule
        </Button>
      </Box>

      {/* Calendar */}
      <AvailabilityCalendar
        availabilityDates={availabilityDates}
        onDateClick={handleAvailabilityChange}
        readOnly={false}
      />

      {/* Modal */}
      <RecurringAvailabilityModal
        open={showRecurringModal}
        onClose={() => setShowRecurringModal(false)}
        onSave={handleRecurringAvailability}
        initialSelectedDays={currentAvailableDays}
        isLoading={isUpdating}
      />
    </Box>
  );

  const LoadingOverlay = () => (
    <Backdrop
      sx={{
        color: '#fff',
        zIndex: (theme) => theme.zIndex.drawer + 1,
        display: 'flex',
        flexDirection: 'column',
        gap: 2
      }}
      open={isUpdating}
    >
      <Box
        sx={{
          bgcolor: 'var(--background-paper)',
          borderRadius: 2,
          p: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: 2,
          maxWidth: 400,
          textAlign: 'center'
        }}
      >
        <CircularProgress 
          size={60}
          thickness={4}
          sx={{ color: 'primary.main' }}
        />
        <Typography variant="h6" color="text.primary">
          Setting Recurring Availability
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Processing {progress.current} of {progress.total} dates...
        </Typography>
        {progress.total > 0 && (
          <Box sx={{ width: '100%', mt: 1 }}>
            <LinearProgress 
              variant="determinate" 
              value={(progress.current / progress.total) * 100} 
            />
          </Box>
        )}
      </Box>
    </Backdrop>
  );

  // Add these styles to your component
  const styles = {
    permissionSection: {
      mb: 4,
      backgroundColor: 'var(--background-paper)',
    },
    
    sectionTitle: {
      fontWeight: 600,
      mb: 2,
    },
    sectionDescription: {
      color: 'text.secondary',
      mb: 3,
    },
    permissionGroup: {
      mb: 3,
    },
    permissionLabel: {
      fontWeight: 500,
    },
    permissionDescription: {
      color: 'text.secondary',
      fontSize: '0.875rem',
    }
  };

  return (
    <div className={`${styles['employee-details']} ${styles['fade-in']}`}>
      <IconButton 
        onClick={() => navigate(-1)} 
        className={styles['back-button']}
        sx={{
          color: 'var(--text-primary)',
        }}
      >
        <ArrowBack />
      </IconButton>

      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mb: 3 }}>
        <Avatar 
          src={employee.avatar_url || undefined} 
          alt={employee.name} 
          sx={{ width: 100, height: 100, mb: 2 }}
        >
          {employee.name ? employee.name.charAt(0) : ''}
        </Avatar>
        <Typography variant="h4" gutterBottom align="center" sx={{ fontFamily: 'Urbanist, sans-serif' }}>
          {employee.name}
        </Typography>
      </Box>

      <Box className="tab-container" sx={{ borderBottom: 1, borderColor: 'divider', mb: 2, display: 'flex' }}>
        <Button 
          onClick={() => setActiveTab('details')} 
          sx={{
            py: 1,
            px: 3,
            color: activeTab === 'details' ? 'var(--text-primary)' : 'var(--text-secondary)',
            borderBottom: activeTab === 'details' ? 2 : 0,
            borderColor: 'primary.main',
            borderRadius: 0,
            fontFamily: 'Urbanist, sans-serif',
            textTransform: 'none',
            minWidth: '120px',
          }}
        >
          <Person sx={{ mr: 1 }} fontSize="small" />
          Details
        </Button>
        <Button 
          onClick={() => setActiveTab('timesheets')} 
          sx={{
            py: 1,
            px: 3,
            color: activeTab === 'timesheets' ? 'var(--text-primary)' : 'var(--text-secondary)',
            borderBottom: activeTab === 'timesheets' ? 2 : 0,
            borderColor: 'primary.main',
            borderRadius: 0,
            fontFamily: 'Urbanist, sans-serif',
            textTransform: 'none',
            minWidth: '120px',
          }}
        >
          <ListAlt sx={{ mr: 1 }} fontSize="small" />
          Timesheets
        </Button>
        <Button 
          onClick={() => setActiveTab('availability')} 
          sx={{
            py: 1,
            px: 3,
            color: activeTab === 'availability' ? 'var(--text-primary)' : 'var(--text-secondary)',
            borderBottom: activeTab === 'availability' ? 2 : 0,
            borderColor: 'primary.main',
            borderRadius: 0,
            fontFamily: 'Urbanist, sans-serif',
            textTransform: 'none',
            minWidth: '120px',
          }}
        >
          <Schedule sx={{ mr: 1 }} fontSize="small" />
          Availability
        </Button>
        <Button 
          onClick={() => setActiveTab('permissions')} 
          sx={{
            py: 1,
            px: 3,
            color: activeTab === 'permissions' ? 'var(--text-primary)' : 'var(--text-secondary)',
            borderBottom: activeTab === 'permissions' ? 2 : 0,
            borderColor: 'primary.main',
            borderRadius: 0,
            fontFamily: 'Urbanist, sans-serif',
            textTransform: 'none',
            minWidth: '120px',
          }}
        >
          <Security sx={{ mr: 1 }} fontSize="small" />
          Permissions
        </Button>
      </Box>

      <div key={activeTab} className={`${styles['tab-content']} ${styles['fade-up']} ${styles['color-primary']}`}>
        {activeTab === 'details' && renderDetails()}
        {activeTab === 'timesheets' && renderTimesheets()}
        {activeTab === 'availability' && renderAvailability()}
        {activeTab === 'permissions' && renderPermissions()}
      </div>

      {showNotification && (
        <Snackbar
          open={showNotification}
          autoHideDuration={3000}
          onClose={() => setShowNotification(false)}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert 
            onClose={() => setShowNotification(false)} 
            severity="success" 
            sx={{ width: '100%' }}
          >
            Notes saved successfully!
          </Alert>
        </Snackbar>
      )}
      <LoadingOverlay />
    </div>
  );
};

export default EmployeeDetails;
