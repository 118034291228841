import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Paper, 
  Typography, 
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Chip,
  Button,
  Grid,
  CircularProgress,
  Alert,
  Modal,
  TextField,
  Autocomplete
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AssignmentIcon from '@mui/icons-material/Assignment';
import SendIcon from '@mui/icons-material/Send';
import { getEstimates } from '../../../services/api';
import { styled } from '@mui/material/styles';
import EstimateTimelineChart from './components/EstimateTimelineChart';

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  '& .MuiTableCell-head': {
    fontWeight: 600,
    backgroundColor: theme.palette.background.default,
    fontFamily: 'Urbanist, sans-serif',
  },
  '& .MuiTableCell-body': {
    fontFamily: 'Urbanist, sans-serif',
  },
  '& .MuiTableRow-root:hover': {
    backgroundColor: theme.palette.action.hover,
  }
}));

const ModalContent = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  backgroundColor: theme.palette.background.paper,
  borderRadius: 8,
  boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
  padding: theme.spacing(4),
  fontFamily: 'Urbanist, sans-serif'
}));

const PendingEstimatesView = ({ onBack }) => {
  const [estimates, setEstimates] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedEstimates, setSelectedEstimates] = useState([]);
  const [reminderMessage, setReminderMessage] = useState('');

  useEffect(() => {
    const fetchEstimates = async () => {
      try {
        setLoading(true);
        const data = await getEstimates();
        const pendingEstimates = data.filter(est => 
          ['pending', 'draft'].includes(est.status?.toLowerCase())
        );
        setEstimates(pendingEstimates);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
    fetchEstimates();
  }, []);

  const calculateDaysOpen = (issueDate) => {
    const diff = new Date() - new Date(issueDate);
    return Math.floor(diff / (1000 * 60 * 60 * 24));
  };

  const calculateEstimateTotal = (items) => {
    if (!Array.isArray(items)) return 0;
    return items.reduce((sum, item) => 
      sum + (Number(item.quantity) || 0) * (Number(item.unit_price) || 0), 0
    );
  };

  const handleSendReminder = async () => {
    try {
      // Add your API call here to send the reminder
      console.log('Sending reminder to:', selectedEstimates);
      console.log('Message:', reminderMessage);
      setModalOpen(false);
      // Add success notification
    } catch (err) {
      setError(err.message);
    }
  };

  const generateDefaultMessage = (estimates) => {
    const totalAmount = estimates.reduce((sum, est) => 
      sum + calculateEstimateTotal(est.items), 0
    );
    
    return `Dear valued client,

We hope this message finds you well. This is a friendly reminder regarding the following pending estimate(s):

${estimates.map(est => `- Estimate #${est.estimate_number}: ${new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD'
}).format(calculateEstimateTotal(est.items))}`).join('\n')}

Total Amount: ${new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD'
}).format(totalAmount)}

Please review and let us know if you have any questions.

Best regards,
[Your Company Name]`;
  };

  const handleModalOpen = () => {
    setReminderMessage(generateDefaultMessage(estimates));
    setModalOpen(true);
  };

  if (loading) return <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}><CircularProgress /></Box>;
  if (error) return <Box sx={{ p: 3 }}><Alert severity="error">{error}</Alert></Box>;

  return (
    <Box sx={{ p: 3 }}>
      <Box sx={{ mb: 4, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <IconButton onClick={onBack} sx={{ mr: 2 }}>
            <ArrowBackIcon />
          </IconButton>
          <AssignmentIcon sx={{ mr: 2, color: '#34495e' }} />
          <Typography variant="h5" sx={{ fontFamily: 'Urbanist, sans-serif', fontWeight: 600 }}>
            Pending Estimates
          </Typography>
        </Box>
        <Button
          variant="contained"
          startIcon={<SendIcon />}
          onClick={handleModalOpen}
          sx={{ 
            textTransform: 'none',
            fontFamily: 'Urbanist, sans-serif'
          }}
        >
          Send Reminder
        </Button>
      </Box>

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper elevation={0} sx={{ p: 3, mb: 3, borderRadius: 2 }}>
            <Box sx={{ height: 300 }}>
              <EstimateTimelineChart estimates={estimates} height={300} />
            </Box>
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Paper elevation={0} sx={{ p: 3, borderRadius: 2 }}>
            <StyledTableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Estimate #</TableCell>
                    <TableCell>Client</TableCell>
                    <TableCell>Date</TableCell>
                    <TableCell align="right">Amount</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell align="right">Days Open</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {estimates.map((estimate) => (
                    <TableRow key={estimate.id} hover>
                      <TableCell>{estimate.estimate_number}</TableCell>
                      <TableCell>{estimate.customer_details?.name || 'N/A'}</TableCell>
                      <TableCell>
                        {new Date(estimate.issue_date).toLocaleDateString('en-US', {
                          year: 'numeric',
                          month: 'short',
                          day: 'numeric'
                        })}
                      </TableCell>
                      <TableCell align="right">
                        {new Intl.NumberFormat('en-US', {
                          style: 'currency',
                          currency: 'USD'
                        }).format(calculateEstimateTotal(estimate.items))}
                      </TableCell>
                      <TableCell>
                        <Chip 
                          label={estimate.status.charAt(0).toUpperCase() + estimate.status.slice(1)}
                          color={estimate.status === 'pending' ? 'warning' : 'default'}
                          size="small"
                          sx={{ minWidth: 80 }}
                        />
                      </TableCell>
                      <TableCell align="right">
                        {calculateDaysOpen(estimate.issue_date)}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </StyledTableContainer>
          </Paper>
        </Grid>
      </Grid>

      <Modal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        aria-labelledby="reminder-modal-title"
      >
        <ModalContent>
          <Typography id="reminder-modal-title" variant="h6" component="h2" sx={{ mb: 3 }}>
            Send Reminder
          </Typography>
          
          <Autocomplete
            multiple
            options={estimates}
            getOptionLabel={(option) => `${option.estimate_number} - ${option.customer_details?.name || 'N/A'}`}
            defaultValue={estimates}
            onChange={(event, newValue) => setSelectedEstimates(newValue)}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select Estimates"
                variant="outlined"
                sx={{ mb: 3 }}
              />
            )}
          />

          <TextField
            fullWidth
            multiline
            rows={8}
            label="Reminder Message"
            value={reminderMessage}
            onChange={(e) => setReminderMessage(e.target.value)}
            sx={{ mb: 3 }}
          />

          <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
            <Button 
              onClick={() => setModalOpen(false)}
              sx={{ 
                textTransform: 'none',
                fontFamily: 'Urbanist, sans-serif'
              }}
            >
              Cancel
            </Button>
            <Button 
              variant="contained"
              startIcon={<SendIcon />}
              onClick={handleSendReminder}
              sx={{ 
                textTransform: 'none',
                fontFamily: 'Urbanist, sans-serif'
              }}
            >
              Send Reminder
            </Button>
          </Box>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default PendingEstimatesView;