import React, { useState } from 'react';
import { 
  Dialog,
  DialogContent,
  IconButton,
  Grid,
  Typography,
  Box,
  TextField,
  Button
} from '@mui/material';
import { 
  Call as CallIcon,
  Backspace as BackspaceIcon,
  Close as CloseIcon
} from '@mui/icons-material';

const DialerButton = ({ number, letters, onClick }) => (
  <IconButton 
    onClick={() => onClick(number)}
    sx={{
      width: 72,
      height: 72,
      borderRadius: 2,
      display: 'flex',
      flexDirection: 'column',
      '&:hover': {
        backgroundColor: 'action.hover'
      }
    }}
  >
    <Typography variant="h5" sx={{ fontFamily: 'Urbanist, sans-serif' }}>
      {number}
    </Typography>
    {letters && (
      <Typography variant="caption" sx={{ fontSize: '0.6rem', color: 'text.secondary' }}>
        {letters}
      </Typography>
    )}
  </IconButton>
);

const CallDialer = ({ open, onClose, onCall }) => {
  const [phoneNumber, setPhoneNumber] = useState('');

  const handleNumberClick = (num) => {
    setPhoneNumber(prev => prev + num);
  };

  const handleBackspace = () => {
    setPhoneNumber(prev => prev.slice(0, -1));
  };

  const handleCall = () => {
    onCall(phoneNumber);
    setPhoneNumber('');
  };

  const dialerButtons = [
    { number: '1', letters: '' },
    { number: '2', letters: 'ABC' },
    { number: '3', letters: 'DEF' },
    { number: '4', letters: 'GHI' },
    { number: '5', letters: 'JKL' },
    { number: '6', letters: 'MNO' },
    { number: '7', letters: 'PQRS' },
    { number: '8', letters: 'TUV' },
    { number: '9', letters: 'WXYZ' },
    { number: '*', letters: '' },
    { number: '0', letters: '+' },
    { number: '#', letters: '' },
  ];

  return (
    <Dialog 
      open={open} 
      onClose={onClose}
      PaperProps={{
        sx: {
          width: 360,
          maxWidth: '100%',
          borderRadius: 3
        }
      }}
    >
      <Box sx={{ 
        p: 2, 
        display: 'flex', 
        justifyContent: 'space-between',
        alignItems: 'center',
        borderBottom: 1,
        borderColor: 'divider'
      }}>
        <Typography variant="h6">Dialer</Typography>
        <IconButton onClick={onClose} size="small">
          <CloseIcon />
        </IconButton>
      </Box>

      <DialogContent sx={{ p: 3 }}>
        <TextField
          fullWidth
          value={phoneNumber}
          onChange={(e) => setPhoneNumber(e.target.value)}
          InputProps={{
            endAdornment: phoneNumber && (
              <IconButton onClick={handleBackspace} size="small">
                <BackspaceIcon />
              </IconButton>
            ),
            sx: { 
              fontSize: '1.5rem',
              textAlign: 'center',
              letterSpacing: 1
            }
          }}
          sx={{ mb: 3 }}
        />

        <Grid container spacing={2} justifyContent="center">
          {dialerButtons.map((btn) => (
            <Grid item key={btn.number}>
              <DialerButton
                number={btn.number}
                letters={btn.letters}
                onClick={handleNumberClick}
              />
            </Grid>
          ))}
        </Grid>

        <Box sx={{ mt: 3, display: 'flex', justifyContent: 'center' }}>
          <Button
            variant="contained"
            color="success"
            startIcon={<CallIcon />}
            onClick={handleCall}
            disabled={!phoneNumber.trim()}
            sx={{ 
              width: 200,
              height: 48,
              borderRadius: 24,
              textTransform: 'none',
              fontFamily: 'Urbanist, sans-serif'
            }}
          >
            Call
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default CallDialer; 