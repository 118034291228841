import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Paper,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Typography
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import ReactQuill from 'react-quill';
import { getTermsTemplates, createTermsTemplate, updateTermsTemplate, deleteTermsTemplate } from '../../services/api';
import styles from './TermsTemplatesManager.module.scss';
import { useTheme } from '../../ThemeContext';

const TermsTemplatesManager = () => {
  const [templates, setTemplates] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [editingTemplate, setEditingTemplate] = useState(null);
  const [currentTemplate, setCurrentTemplate] = useState({ name: '', content: '' });
  const { darkMode } = useTheme();

  useEffect(() => {
    fetchTemplates();
  }, []);

  const fetchTemplates = async () => {
    try {
      const response = await getTermsTemplates();
      setTemplates(response.data);
    } catch (error) {
      console.error('Error fetching templates:', error);
    }
  };

  const handleOpenDialog = (template = null) => {
    setEditingTemplate(template);
    setCurrentTemplate(template ? {
      name: template.name,
      content: template.content
    } : { name: '', content: '' });
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setEditingTemplate(null);
    setCurrentTemplate({ name: '', content: '' });
  };

  const handleSave = async () => {
    try {
      if (editingTemplate) {
        await updateTermsTemplate(editingTemplate.id, currentTemplate);
      } else {
        await createTermsTemplate(currentTemplate);
      }
      fetchTemplates();
      handleCloseDialog();
    } catch (error) {
      console.error('Error saving template:', error);
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this template?')) {
      try {
        await deleteTermsTemplate(id);
        fetchTemplates();
      } catch (error) {
        console.error('Error deleting template:', error);
      }
    }
  };

  return (
    <Box className={`${styles.container} ${darkMode ? styles.darkMode : ''}`}>
      <Typography 
        variant="h4" 
        className={styles.pageTitle}
        sx={{ color: darkMode ? 'var(--text-primary)' : 'inherit', mb: 3 }}
      >
        Terms and Conditions Manager
      </Typography>

      <Box className={styles.header}>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={() => handleOpenDialog()}
          className={darkMode ? styles.darkButton : ''}
        >
          Add Template
        </Button>
      </Box>

      <Paper 
        className={`${styles.templatesList} ${darkMode ? styles.darkMode : ''}`}
        elevation={darkMode ? 2 : 1}
        sx={{
          backgroundColor: darkMode ? 'var(--background-paper)' : '#fff',
          color: darkMode ? 'var(--text-primary)' : 'inherit'
        }}
      >
        <List>
          {templates.map((template) => (
            <ListItem 
              key={template.id} 
              divider 
              className={darkMode ? styles.darkListItem : ''}
              sx={{
                borderColor: darkMode ? 'var(--border-color)' : 'inherit'
              }}
            >
              <ListItemText
                primary={template.name}
                secondary={`Updated: ${new Date(template.updated_at).toLocaleDateString()}`}
                sx={{
                  '& .MuiListItemText-primary': {
                    color: darkMode ? 'var(--text-primary)' : 'inherit'
                  },
                  '& .MuiListItemText-secondary': {
                    color: darkMode ? 'var(--text-secondary)' : 'inherit'
                  }
                }}
              />
              <ListItemSecondaryAction>
                <IconButton 
                  onClick={() => handleOpenDialog(template)}
                  sx={{ color: darkMode ? '#90caf9' : 'inherit' }}
                >
                  <EditIcon />
                </IconButton>
                <IconButton 
                  onClick={() => handleDelete(template.id)}
                  sx={{ color: darkMode ? '#f44336' : 'inherit' }}
                >
                  <DeleteIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </Paper>

      <Dialog 
        open={openDialog} 
        onClose={handleCloseDialog} 
        maxWidth="md" 
        fullWidth
        className={`${styles.dialog} ${darkMode ? styles.darkMode : ''}`}
        PaperProps={{
          sx: {
            backgroundColor: darkMode ? 'var(--background-primary)' : '#fff',
            color: darkMode ? 'var(--text-primary)' : 'inherit'
          }
        }}
      >
        <DialogTitle sx={{ color: darkMode ? 'var(--text-primary)' : 'inherit' }}>
          {editingTemplate ? 'Edit Template' : 'New Template'}
        </DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            label="Template Name"
            value={currentTemplate.name}
            onChange={(e) => setCurrentTemplate(prev => ({ 
              ...prev, 
              name: e.target.value 
            }))}
            sx={{ 
              mb: 2, 
              mt: 1,
              '& .MuiInputLabel-root': {
                color: darkMode ? 'var(--text-secondary)' : 'inherit'
              },
              '& .MuiOutlinedInput-root': {
                backgroundColor: darkMode ? 'var(--input-background)' : 'inherit',
                color: darkMode ? 'var(--text-primary)' : 'inherit',
                '& fieldset': {
                  borderColor: darkMode ? 'var(--border-color)' : 'inherit'
                }
              }
            }}
          />
          <ReactQuill
            value={currentTemplate.content}
            onChange={(content) => setCurrentTemplate(prev => ({ 
              ...prev, 
              content 
            }))}
            modules={{
              toolbar: [
                ['bold', 'italic', 'underline'],
                [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                ['clean']
              ]
            }}
            theme="snow"
            className={darkMode ? styles.darkQuill : ''}
          />
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={handleCloseDialog}
            sx={{ color: darkMode ? 'var(--text-primary)' : 'inherit' }}
          >
            Cancel
          </Button>
          <Button 
            onClick={handleSave} 
            variant="contained" 
            color="primary"
            disabled={!currentTemplate.name || !currentTemplate.content}
            className={darkMode ? styles.darkButton : ''}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default TermsTemplatesManager; 