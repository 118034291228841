import React from 'react';
import { Box, Typography, IconButton, Button } from '@mui/material';
import { 
  Close as CloseIcon,
  AccessTime as AccessTimeIcon,
  CalendarToday as CalendarIcon,
  Headset as HeadsetIcon,
  Article as ArticleIcon 
} from '@mui/icons-material';

const TranscriptHeader = ({ onClose, callDetails, onToggleFullTranscript, showFullTranscript }) => {
  const { date, duration, agent } = callDetails;
  
  return (
    <Box sx={{ 
      p: 2, 
      borderBottom: 1, 
      borderColor: 'divider',
      display: 'flex',
      flexDirection: 'column',
      gap: 2
    }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h6">Call Transcript</Typography>
        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
          <Button
            startIcon={<ArticleIcon />}
            onClick={onToggleFullTranscript}
            size="small"
            color="primary"
          >
            {showFullTranscript ? 'Hide Full Transcript' : 'Show Full Transcript'}
          </Button>
          <IconButton onClick={onClose} size="small">
            <CloseIcon />
          </IconButton>
        </Box>
      </Box>
      
      <Box sx={{ display: 'flex', gap: 4, flexWrap: 'wrap' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <CalendarIcon color="action" fontSize="small" />
          <Typography variant="body2" color="text.secondary">{date}</Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <AccessTimeIcon color="action" fontSize="small" />
          <Typography variant="body2" color="text.secondary">Duration: {duration}</Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <HeadsetIcon color="action" fontSize="small" />
          <Typography variant="body2" color="text.secondary">Agent: {agent}</Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default TranscriptHeader; 