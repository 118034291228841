import React, { useState } from 'react';
import { 
  Box, 
  Paper, 
  FormControl, 
  InputLabel, 
  Select, 
  MenuItem, 
  TextField,
  IconButton,
  Tooltip,
  Stack
} from '@mui/material';
import { GridLayout, Responsive as ResponsiveGridLayout } from 'react-grid-layout';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import DatePicker from '@mui/lab/DatePicker';
import RefreshIcon from '@mui/icons-material/Refresh';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import FullscreenIcon from '@mui/icons-material/Fullscreen';

// Import your chart components
import RevenueChart from './charts/RevenueChart';
import ProjectDistributionChart from './charts/ProjectDistributionChart';
import MonthlyPerformanceChart from './charts/MonthlyPerformanceChart';
import EmployeePerformanceChart from './charts/EmployeePerformanceChart';
import TimeTrackingChart from './charts/TimeTrackingChart';
import ProjectTrendsChart from './charts/ProjectTrendsChart';

const ChartsPanel = () => {
  // State for filters
  const [dateRange, setDateRange] = useState([null, null]);
  const [department, setDepartment] = useState('all');
  const [project, setProject] = useState('all');

  // Initial layout configuration
  const [layout, setLayout] = useState([
    { i: 'revenue', x: 0, y: 0, w: 8, h: 4 },
    { i: 'distribution', x: 8, y: 0, w: 4, h: 4 },
    { i: 'employee', x: 0, y: 4, w: 6, h: 4 },
    { i: 'time', x: 6, y: 4, w: 6, h: 4 },
    { i: 'trends', x: 0, y: 8, w: 6, h: 4 },
    { i: 'performance', x: 6, y: 8, w: 6, h: 4 }
  ]);

  // Handle layout changes
  const handleLayoutChange = (newLayout) => {
    setLayout(newLayout);
  };

  // Chart components map
  const chartComponents = {
    revenue: <RevenueChart />,
    distribution: <ProjectDistributionChart />,
    employee: <EmployeePerformanceChart />,
    time: <TimeTrackingChart />,
    trends: <ProjectTrendsChart />,
    performance: <MonthlyPerformanceChart />
  };

  return (
    <Box sx={{ p: 2.5 }}>
      {/* Filters Section */}
      <Paper 
        elevation={0} 
        sx={{ 
          p: 2, 
          mb: 2.5, 
          display: 'flex', 
          alignItems: 'center',
          gap: 2,
          flexWrap: 'wrap'
        }}
      >
        <DatePicker
          label="Start Date"
          value={dateRange[0]}
          onChange={(newValue) => setDateRange([newValue, dateRange[1]])}
          renderInput={(params) => <TextField {...params} size="small" />}
        />
        <DatePicker
          label="End Date"
          value={dateRange[1]}
          onChange={(newValue) => setDateRange([dateRange[0], newValue])}
          renderInput={(params) => <TextField {...params} size="small" />}
        />
        
        <FormControl size="small" sx={{ minWidth: 200 }}>
          <InputLabel>Department</InputLabel>
          <Select
            value={department}
            label="Department"
            onChange={(e) => setDepartment(e.target.value)}
          >
            <MenuItem value="all">All Departments</MenuItem>
            <MenuItem value="sales">Sales</MenuItem>
            <MenuItem value="operations">Operations</MenuItem>
            <MenuItem value="support">Support</MenuItem>
          </Select>
        </FormControl>

        <FormControl size="small" sx={{ minWidth: 200 }}>
          <InputLabel>Project</InputLabel>
          <Select
            value={project}
            label="Project"
            onChange={(e) => setProject(e.target.value)}
          >
            <MenuItem value="all">All Projects</MenuItem>
            <MenuItem value="active">Active Projects</MenuItem>
            <MenuItem value="completed">Completed Projects</MenuItem>
          </Select>
        </FormControl>

        <Box sx={{ marginLeft: 'auto' }}>
          <Stack direction="row" spacing={1}>
            <Tooltip title="Refresh Data">
              <IconButton size="small">
                <RefreshIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Export Dashboard">
              <IconButton size="small">
                <FileDownloadIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Full Screen">
              <IconButton size="small">
                <FullscreenIcon />
              </IconButton>
            </Tooltip>
          </Stack>
        </Box>
      </Paper>

      {/* Draggable Grid Layout */}
      <ResponsiveGridLayout
        className="layout"
        layouts={{ lg: layout }}
        breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
        cols={{ lg: 12, md: 12, sm: 6, xs: 4, xxs: 2 }}
        rowHeight={100}
        onLayoutChange={handleLayoutChange}
        isDraggable
        isResizable
        margin={[20, 20]}
      >
        {layout.map((item) => (
          <Paper
            key={item.i}
            elevation={0}
            sx={{
              height: '100%',
              backgroundColor: '#ffffff',
              transition: 'all 0.3s ease',
              '&:hover': {
                boxShadow: '0 4px 20px 0 rgba(0,0,0,0.05)',
                borderColor: '#90caf9'
              },
              cursor: 'move'
            }}
          >
            {chartComponents[item.i]}
          </Paper>
        ))}
      </ResponsiveGridLayout>
    </Box>
  );
};

export default ChartsPanel; 