import React from 'react';
import {
  Box,
  Typography,
  Select,
  MenuItem,
  Slider,
  Grid,
} from '@mui/material';

const FormStyleSection = ({ formData, onStyleChange }) => {
  return (
    <Box>
      <Typography variant="h6" sx={{ mb: 2 }}>Form Style</Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Typography variant="subtitle2" gutterBottom>Theme</Typography>
          <Select
            fullWidth
            value={formData.style.theme}
            onChange={onStyleChange('theme')}
          >
            <MenuItem value="light">Light</MenuItem>
            <MenuItem value="dark">Dark</MenuItem>
            <MenuItem value="custom">Custom</MenuItem>
          </Select>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Typography variant="subtitle2" gutterBottom>Font Family</Typography>
          <Select
            fullWidth
            value={formData.style.fontFamily}
            onChange={onStyleChange('fontFamily')}
          >
            <MenuItem value="Roboto">Roboto</MenuItem>
            <MenuItem value="Arial">Arial</MenuItem>
            <MenuItem value="Helvetica">Helvetica</MenuItem>
            <MenuItem value="Inter">Inter</MenuItem>
            <MenuItem value="Poppins">Poppins</MenuItem>
          </Select>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Typography variant="subtitle2" gutterBottom>Primary Color</Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <input
              type="color"
              value={formData.style.primaryColor}
              onChange={(e) => onStyleChange('primaryColor')({ target: { value: e.target.value } })}
              style={{ width: '100%', height: '38px' }}
            />
          </Box>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Typography variant="subtitle2" gutterBottom>Secondary Color</Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <input
              type="color"
              value={formData.style.secondaryColor}
              onChange={(e) => onStyleChange('secondaryColor')({ target: { value: e.target.value } })}
              style={{ width: '100%', height: '38px' }}
            />
          </Box>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Typography variant="subtitle2" gutterBottom>Background Color</Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <input
              type="color"
              value={formData.style.backgroundColor}
              onChange={(e) => onStyleChange('backgroundColor')({ target: { value: e.target.value } })}
              style={{ width: '100%', height: '38px' }}
            />
          </Box>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Typography variant="subtitle2" gutterBottom>Text Color</Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <input
              type="color"
              value={formData.style.textColor}
              onChange={(e) => onStyleChange('textColor')({ target: { value: e.target.value } })}
              style={{ width: '100%', height: '38px' }}
            />
          </Box>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Typography variant="subtitle2" gutterBottom>Font Size</Typography>
          <Slider
            value={formData.style.fontSize}
            onChange={onStyleChange('fontSize')}
            min={12}
            max={24}
            step={1}
            marks
            valueLabelDisplay="auto"
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <Typography variant="subtitle2" gutterBottom>Spacing</Typography>
          <Slider
            value={formData.style.spacing}
            onChange={onStyleChange('spacing')}
            min={8}
            max={32}
            step={4}
            marks
            valueLabelDisplay="auto"
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default FormStyleSection; 