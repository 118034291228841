import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid, Paper, Typography, Divider, TextField, FormControlLabel, Checkbox, Box, Button, CircularProgress, Snackbar, Alert, Switch, RadioGroup, Radio, FormControl, FormLabel } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import NewClientForm from './NewClientForm.tsx';
import AddressAutocomplete from './AddressAutocomplete';
import { createProject, createClient } from '../services/api';
import dayjs from 'dayjs';
import ProjectItemsManager from './ProjectItemsManager';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import styles from './CreateProjectPage.module.scss';

const globalStyles = {
  WebkitTapHighlightColor: 'transparent',
  '*:focus': {
    outline: 'none !important',
    WebkitAppearance: 'none !important',
    boxShadow: 'none !important',
  },
  'input': {
    WebkitAppearance: 'none !important',
    color: 'var(--text-primary) !important',
    '&:focus': {
      outline: 'none !important',
      WebkitAppearance: 'none !important',
      boxShadow: 'none !important',
    }
  }
};

const inputStyle = {
  height: '45px',
  width: '100%',
  fontSize: '0.95rem',
  border: '1px solid var(--divider)',
  borderRadius: '4px',
  padding: '8px 12px',
  fontFamily: 'inherit',
  backgroundColor: 'var(--background-paper)',
  color: 'var(--text-primary)',
  transition: 'border-color 0.2s ease',
  WebkitTapHighlightColor: 'transparent !important',
  WebkitAppearance: 'none !important',
  '&:hover': {
    border: '1px solid var(--text-secondary)',
  },
  '&:focus': {
    border: '1px solid var(--primary-main) !important',
    outline: 'none !important',
    WebkitAppearance: 'none !important',
    boxShadow: 'none !important',
    '-webkit-box-shadow': 'none !important',
    '-moz-box-shadow': 'none !important'
  },
  '-webkit-user-select': 'text'
};

const datePickerStyle = {
  width: '100%',
  '& .MuiOutlinedInput-root': {
    height: '45px',
    width: '100%',
    fontSize: '0.95rem',
    border: '1px solid var(--divider)',
    borderRadius: '4px',
    backgroundColor: 'var(--background-paper)',
    color: 'var(--text-primary)',
    transition: 'border-color 0.2s ease',
    WebkitTapHighlightColor: 'transparent !important',
    WebkitAppearance: 'none !important',
    padding: 0,
    boxShadow: 'none !important',
    '& fieldset': {
      display: 'none'
    },
    '&:hover': {
      border: '1px solid var(--text-secondary)',
      boxShadow: 'none !important',
    },
    '&.Mui-focused': {
      border: '1px solid var(--primary-main) !important',
      outline: 'none !important',
      WebkitAppearance: 'none !important',
      boxShadow: 'none !important',
      backgroundColor: 'var(--background-paper)'
    },
    '& input': {
      height: '45px !important',
      padding: '8px 12px !important',
      fontSize: '0.95rem !important',
      fontFamily: 'inherit !important',
      WebkitAppearance: 'none !important',
      backgroundColor: 'transparent',
      color: 'var(--text-primary)',
      '&:focus': {
        outline: 'none !important',
        WebkitAppearance: 'none !important',
        boxShadow: 'none !important'
      }
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none'
    },
    '& .MuiInputAdornment-root': {
      marginRight: '8px',
      color: 'var(--text-secondary)',
      '& .MuiButtonBase-root': {
        padding: '8px',
        color: 'var(--text-secondary)'
      }
    }
  }
};

const CreateProjectPage = () => {
  const navigate = useNavigate();
  const [selectedClient, setSelectedClient] = useState(null);
  const [canCreateProject, setCanCreateProject] = useState(false);
  const [project, setProject] = useState({
    title: '',
    name: '',
    company: '',
    email: '',
    phone: '',
    serviceAddress: '',
    billingAddress: '',
    notes: '',
    scopeOfWork: '',
    start_time: null,
    end_time: null
  });
  const [isLoading, setIsLoading] = useState(false);
  const [useClientAddress, setUseClientAddress] = useState(true);
  const [showSuccess, setShowSuccess] = useState(false);
  const [error, setError] = useState(null);
  const [showError, setShowError] = useState(false);
  const [scheduleNow, setScheduleNow] = useState(false);
  const [projectItems, setProjectItems] = useState([]);
  const [eventType, setEventType] = useState('single');

  useEffect(() => {
    setCanCreateProject(
      selectedClient !== null && 
      project.title !== ''
    );
  }, [selectedClient, project.title]);

  useEffect(() => {
    if (eventType === 'multi') {
      setScheduleNow(false);
    }
  }, [eventType]);

  const handleClientSelect = (client) => {
    console.log('Client selected/created:', client);
    setSelectedClient(client);
    
    if (client) {
      const clientAddress = [
        client.address,
        client.city,
        `${client.state} ${client.zip_code}`
      ].filter(Boolean).join(', ');
      
      setProject(prev => ({
        ...prev,
        email: client.email || '',
        phone: client.phone || '',
        company: client.company || '',
        billingAddress: clientAddress,
        serviceAddress: useClientAddress ? clientAddress : prev.serviceAddress,
        notes: client.notes || ''
      }));
    } else {
      setProject(prev => ({
        ...prev,
        email: '',
        phone: '',
        company: '',
        billingAddress: '',
        serviceAddress: '',
        notes: ''
      }));
    }
  };

  const handleClientCreate = (client) => {
    console.log('New client created:', client);
    handleClientSelect(client);
  };

  useEffect(() => {
    if (selectedClient && useClientAddress) {
      const clientAddress = selectedClient.address || '';
      
      setProject(prev => ({
        ...prev,
        serviceAddress: clientAddress
      }));
    }
  }, [selectedClient, useClientAddress]);

  const handleUseClientAddress = (event) => {
    const checked = event.target.checked;
    setUseClientAddress(checked);
    
    if (checked && selectedClient) {
      const clientAddress = selectedClient.address || '';
      setProject(prev => ({
        ...prev,
        serviceAddress: clientAddress
      }));
    }
  };

  const handleItemsChange = (items) => {
    console.log('Items before processing:', items);
    const processedItems = items.map(item => ({
      ...item,
      title: item.title || item.name || 'Untitled Event'
    }));
    console.log('Processed items:', processedItems);
    setProjectItems(processedItems);
  };

  const handleEventTypeChange = (e) => {
    const newType = e.target.value;
    setEventType(newType);
    if (newType === 'multi') {
      setScheduleNow(false);
      setProject(prev => ({
        ...prev,
        start_time: null,
        end_time: null
      }));
    } else {
      setProject(prev => ({
        ...prev,
        start_time: null,
        end_time: null
      }));
    }
  };

  useEffect(() => {
    if (scheduleNow && eventType === 'single') {
      setProject(prev => ({
        ...prev,
        start_time: null,
        end_time: null
      }));
    }
  }, [scheduleNow, eventType]);

  const calculateTotalProjectValue = () => {
    return projectItems.reduce((total, item) => {
      const quantity = parseInt(item.quantity) || 0;
      const unitPrice = parseFloat(item.unitPrice) || 0;
      return total + (quantity * unitPrice);
    }, 0);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    let clientToUse = selectedClient;
    
    if (!project.title) {
      setError('Project title is required');
      setShowError(true);
      return;
    }

    setIsLoading(true);
    try {
      if (!clientToUse) {
        const clientData = {
          name: project.name || 'New Client',
          email: project.email || '',
          phone: project.phone || '',
          address: project.billingAddress || '',
          company: project.company || ''
        };
        
        console.log('Creating new client:', clientData);
        const response = await createClient(clientData);
        if (response && response.id) {
          clientToUse = response;
        } else {
          throw new Error('Failed to create client');
        }
      }

      const formattedStartTime = project.start_time ? 
        dayjs(project.start_time).format('YYYY-MM-DDTHH:mm:00') : null;
      const formattedEndTime = project.end_time ? 
        dayjs(project.end_time).format('YYYY-MM-DDTHH:mm:00') : null;

      const projectData = {
        name: project.title,
        client_id: clientToUse.id,
        client_name: clientToUse.name,
        client_email: clientToUse.email,
        client_phone: clientToUse.phone,
        client_company: clientToUse.company,
        description: project.notes || '',
        service_address: project.serviceAddress,
        billing_address: project.billingAddress,
        start_time: formattedStartTime,
        end_time: formattedEndTime,
        total_value: calculateTotalProjectValue(),
        events: projectItems.map(item => ({
          title: item.title || item.name || 'Untitled Event',
          description: item.description || '',
          value: parseFloat(item.unitPrice || 0) * parseInt(item.quantity || 1),
          quantity: parseInt(item.quantity || 1),
          unit_price: parseFloat(item.unitPrice || 0),
          start_time: formattedStartTime,
          end_time: formattedEndTime,
          time_slots: formattedStartTime && formattedEndTime ? [{
            start_time: formattedStartTime,
            end_time: formattedEndTime
          }] : []
        }))
      };

      console.log('Final project data:', JSON.stringify(projectData, null, 2));
      const response = await createProject(projectData);
      
      if (response && response.project_id) {
        setShowSuccess(true);
        setTimeout(() => {
          window.location.href = `/admin/projects/${response.project_id}`;
        }, 1000);
      }
    } catch (error) {
      console.error('Project creation failed:', error);
      setError(error.message || 'Failed to create project');
      setShowError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const pickerStyles = {
    width: '100%',
    '& .MuiPickersPopper-root': {
      '& ::-webkit-scrollbar': {
        display: 'none'
      },
      '-ms-overflow-style': 'none',
      'scrollbar-width': 'none'
    }
  };

  const handleSetNineToFive = () => {
    const selectedDate = project.start_time ? dayjs(project.start_time) : dayjs();
    const startTime = selectedDate.hour(9).minute(0).second(0);
    const endTime = selectedDate.hour(17).minute(0).second(0);
    
    console.log('Setting 9-5 times:', {
      start: startTime.format('YYYY-MM-DDTHH:mm:ss'),
      end: endTime.format('YYYY-MM-DDTHH:mm:ss')
    });
    
    setProject(prev => ({
      ...prev,
      start_time: startTime,
      end_time: endTime
    }));
  };

  const handleDateChange = (field, value) => {
    const validDate = dayjs(value).isValid() ? value : null;
    setProject(prev => ({
      ...prev,
      [field]: validDate,
      ...(field === 'start_time' && !prev.end_time && validDate 
        ? { end_time: dayjs(validDate).add(1, 'hour') }
        : {})
    }));
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box sx={globalStyles}>
        <Box sx={{ 
          bgcolor: '#FFF3CD', 
          color: '#856404', 
          p: 2, 
          textAlign: 'center',
          borderBottom: '1px solid #FFEEBA',
          mb: 2,
          height: '30px',  // Make it taller
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          fontSize: '1.1rem',  // Slightly larger text
        }}>
          Adding address autocomplete with Google Maps API
        </Box>

        <Box sx={{ p: 3, maxWidth: 1400, margin: '0 auto' }}>
          <Typography 
            variant="h4" 
            gutterBottom 
            sx={{ 
              mb: 4,
              fontWeight: 500,
              color: 'var(--text-primary)' 
            }}
          >
            Create New Project
          </Typography>
          
          {/* Project Title */}
          <Paper 
            elevation={0} 
            sx={{ 
              p: 3, 
              mb: 3,
              borderRadius: 2,
              backgroundColor: 'transparent',
              boxShadow: 'none',
              border: 'none'
            }}
          >
            <Typography 
              variant="h6" 
              gutterBottom
              sx={{
                color: 'var(--text-primary)',
                fontSize: '1.1rem',
                fontWeight: 500,
                mb: 2,
                display: 'flex',
                alignItems: 'center',
                '&::before': {
                  content: '""',
                  width: '4px',
                  height: '24px',
                  backgroundColor: '#27ae60', // Green for Client Info
                  marginRight: '12px',
                  borderRadius: '4px'
                }
              }}
            >
              Project Title
            </Typography>
            <input
              type="text"
              value={project.title}
              onChange={(e) => setProject(prev => ({ ...prev, title: e.target.value }))}
              placeholder="Project Title"
              style={inputStyle}
            />
          </Paper>

          {/* Client Information - Now Full Width */}
          <Paper 
            elevation={0} 
            sx={{ 
              p: 3, 
              mb: 3,
              borderRadius: 2,
              backgroundColor: 'transparent',
              boxShadow: 'none',
              border: 'none'
            }}
          >
            <Typography 
              variant="h6" 
              gutterBottom
              sx={{
                color: 'var(--text-primary)',
                fontSize: '1.1rem',
                fontWeight: 500,
                mb: 2,
                display: 'flex',
                alignItems: 'center',
                '&::before': {
                  content: '""',
                  width: '4px',
                  height: '24px',
                  backgroundColor: '#27ae60', // Green for Client Info
                  marginRight: '12px',
                  borderRadius: '4px'
                }
              }}
            >
              Client Information
            </Typography>
            <NewClientForm
              onClientSelect={handleClientSelect}
              onClientCreate={handleClientCreate}
              selectedClient={selectedClient}
            />
          </Paper>

          {/* Location & Schedule */}
          <Paper 
            elevation={0} 
            sx={{ 
              p: 3, 
              mb: 3,
              borderRadius: 2,
              backgroundColor: 'transparent',
              boxShadow: 'none',
              border: 'none'
            }}
          >
            <Typography 
              variant="h6" 
              gutterBottom
              sx={{
                color: 'var(--text-primary)',
                fontSize: '1.1rem',
                fontWeight: 500,
                mb: 2,
                display: 'flex',
                alignItems: 'center',
                '&::before': {
                  content: '""',
                  width: '4px',
                  height: '24px',
                  backgroundColor: '#e74c3c', // Red for Schedule
                  marginRight: '12px',
                  borderRadius: '4px'
                }
              }}
            >
              Location & Schedule
            </Typography>
            <Divider sx={{ my: 2 }} />
            
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
              <FormControlLabel
                control={
                  <Switch 
                    checked={useClientAddress} 
                    onChange={handleUseClientAddress}
                    color="primary"
                  />
                }
                label="Use Client's Address"
              />
            </Box>
            
            {!useClientAddress && (
              <Box sx={{ mb: 3 }}>
                <AddressAutocomplete
                  value={project.serviceAddress}
                  onChange={(address) => setProject(prev => ({ ...prev, serviceAddress: address }))}
                  label="Service Address"
                />
              </Box>
            )}

            <FormControl component="fieldset" sx={{ mb: 3, width: '100%' }}>
              <RadioGroup 
                row 
                value={eventType} 
                onChange={handleEventTypeChange}
                sx={{ justifyContent: 'flex-start' }}
              >
                <FormControlLabel 
                  value="single" 
                  control={<Radio color="primary" />} 
                  label="Single Day Event"
                  sx={{ mr: 4 }}
                />
                <FormControlLabel 
                  value="multi" 
                  control={<Radio color="primary" />} 
                  label="Multi-Day Event" 
                />
              </RadioGroup>
            </FormControl>

            {eventType === 'single' && (
              <>
                <Box sx={{ mb: 3 }}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={!scheduleNow}
                        onChange={(e) => setScheduleNow(!e.target.checked)}
                        color="primary"
                      />
                    }
                    label="Schedule Now"
                  />
                </Box>

                {!scheduleNow && (
                  <>
                    <Box sx={{ 
                      mb: 2, 
                      display: 'flex', 
                      gap: 1.5,
                      justifyContent: 'flex-end',
                      '& .MuiButton-root': {
                        minWidth: '100px',
                        borderRadius: '4px',
                        textTransform: 'none',
                        fontWeight: 500,
                        fontSize: '0.875rem',
                        padding: '6px 16px',
                        boxShadow: 'none',
                        border: '1px solid #ddd',
                        backgroundColor: 'transparent',
                        color: '#666',
                        '&:hover': {
                          backgroundColor: '#f5f5f5',
                          borderColor: '#999'
                        }
                      }
                    }}>
                      <Button 
                        variant="outlined" 
                        onClick={() => {
                          const today = dayjs();
                          setProject(prev => ({
                            ...prev,
                            start_time: today.hour(9).minute(0),
                            end_time: today.hour(17).minute(0)
                          }));
                        }}
                        sx={{
                          color: 'primary.main',
                          borderColor: 'primary.main',
                          backgroundColor: 'primary.lighter',
                          '&:hover': {
                            backgroundColor: 'primary.light',
                            borderColor: 'primary.main',
                          }
                        }}
                      >
                        9 AM - 5 PM
                      </Button>
                      <Button 
                        variant="outlined" 
                        onClick={() => {
                          const today = dayjs();
                          setProject(prev => ({
                            ...prev,
                            start_time: today.hour(9).minute(0),
                            end_time: today.hour(12).minute(0)
                          }));
                        }}
                        sx={{
                          color: 'primary.main',
                          borderColor: 'primary.main',
                          backgroundColor: 'primary.lighter',
                          '&:hover': {
                            backgroundColor: 'primary.light',
                            borderColor: 'primary.main',
                          }
                        }}
                      >
                        9 AM - 12 PM
                      </Button>
                      <Button 
                        variant="outlined" 
                        onClick={() => {
                          const today = dayjs();
                          setProject(prev => ({
                            ...prev,
                            start_time: today.hour(12).minute(0),
                            end_time: today.hour(17).minute(0)
                          }));
                        }}
                        sx={{
                          color: 'primary.main',
                          borderColor: 'primary.main',
                          backgroundColor: 'primary.lighter',
                          '&:hover': {
                            backgroundColor: 'primary.light',
                            borderColor: 'primary.main',
                          }
                        }}
                      >
                        12 PM - 5 PM
                      </Button>
                    </Box>

                    <Grid container spacing={3} sx={{ mt: 0 }}>
                      <Grid item xs={12} md={6}>
                        <DateTimePicker
                          label=""
                          value={project.start_time}
                          onChange={(newValue) => handleDateChange('start_time', newValue)}
                          slotProps={{
                            textField: {
                              variant: "outlined",
                              fullWidth: true,
                              placeholder: "Start Date & Time",
                              sx: datePickerStyle
                            }
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <DateTimePicker
                          label=""
                          value={project.end_time}
                          onChange={(newValue) => handleDateChange('end_time', newValue)}
                          minDateTime={project.start_time}
                          slotProps={{
                            textField: {
                              variant: "outlined",
                              fullWidth: true,
                              placeholder: "End Date & Time",
                              sx: datePickerStyle
                            }
                          }}
                        />
                      </Grid>
                    </Grid>
                  </>
                )}
              </>
            )}
          </Paper>

          {/* Project Items */}
          <Box sx={{ mt: 3 }}>
            <ProjectItemsManager 
              onItemsChange={handleItemsChange}
            />
          </Box>

          {/* Submit Button - Bottom Right */}
          <Box sx={{ 
            display: 'flex', 
            justifyContent: 'flex-end', 
            mt: 4,
            mb: 4
          }}>
            <Button 
              variant="contained"
              disabled={isLoading}
              onClick={handleSubmit}
              sx={{ 
                width: '200px',
                height: '48px',
                boxShadow: 3,
                borderRadius: 2,
                textTransform: 'none',
                fontSize: '1rem',
                fontWeight: 500
              }}
            >
              {isLoading ? <CircularProgress size={24} /> : 'Create Project'}
            </Button>
          </Box>

          {/* Snackbars */}
          <Snackbar
            open={showError}
            autoHideDuration={6000}
            onClose={() => setShowError(false)}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          >
            <Alert 
              onClose={() => setShowError(false)} 
              severity="error"
              sx={{ width: '100%' }}
            >
              {error}
            </Alert>
          </Snackbar>

          <Snackbar
            open={showSuccess}
            autoHideDuration={800}
            onClose={() => setShowSuccess(false)}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            sx={{
              '& .MuiAlert-root': {
                backgroundColor: '#4CAF50',
                color: 'white',
                '& .MuiAlert-icon': {
                  color: 'white'
                }
              }
            }}
          >
            <Alert 
              onClose={() => setShowSuccess(false)} 
              severity="success"
            >
              Project created successfully!
            </Alert>
          </Snackbar>
        </Box>
      </Box>
    </LocalizationProvider>
  );
};

export default CreateProjectPage;
