import React from 'react';
import { Box, FormControl, TextField, styled } from '@mui/material';
import {
  Business,
  Work,
  Category,
  Domain,
} from '@mui/icons-material';

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    borderRadius: 8,
    backgroundColor: theme.palette.background.paper,
    transition: 'all 0.2s ease-in-out',
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
    '&.Mui-focused': {
      backgroundColor: theme.palette.background.paper,
      boxShadow: `0 0 0 2px ${theme.palette.primary.main}25`,
    }
  }
}));

const CompanyInfoSection = ({ data, onChange }) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2.5 }}>
      <StyledTextField
        fullWidth
        label="Company Name"
        value={data.company}
        onChange={(e) => onChange('company', e.target.value)}
        InputProps={{
          startAdornment: <Business sx={{ mr: 1, color: 'action.active' }} />,
        }}
      />

      <StyledTextField
        fullWidth
        label="Position"
        value={data.position}
        onChange={(e) => onChange('position', e.target.value)}
        InputProps={{
          startAdornment: <Work sx={{ mr: 1, color: 'action.active' }} />,
        }}
      />

      <StyledTextField
        fullWidth
        label="Industry"
        value={data.industry}
        onChange={(e) => onChange('industry', e.target.value)}
        InputProps={{
          startAdornment: <Category sx={{ mr: 1, color: 'action.active' }} />,
        }}
      />

      <StyledTextField
        fullWidth
        label="Website"
        value={data.website || ''}
        onChange={(e) => onChange('website', e.target.value)}
        InputProps={{
          startAdornment: <Domain sx={{ mr: 1, color: 'action.active' }} />,
        }}
      />
    </Box>
  );
};

export default CompanyInfoSection; 