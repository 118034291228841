import React, { useState, useCallback, useEffect } from 'react';
import {
  Typography, TextField, Button, Grid, Paper, Box, FormControl, InputLabel, Select, MenuItem,
  IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Divider, Collapse,
  Checkbox, Snackbar, CircularProgress, Alert, Fade, Dialog, DialogContent, FormControlLabel, Switch
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Add as AddIcon, Delete as DeleteIcon, CloudUpload as CloudUploadIcon, Edit as EditIcon, Person as PersonIcon } from '@mui/icons-material';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useNavigate } from 'react-router-dom';
import ReactDOMServer from 'react-dom/server';
import { 
  getBusinessDetails, 
  getClients, 
  getItems, 
  getLogos, 
  uploadLogo, 
  deleteLogo, 
  createItem, 
  deleteItem 
} from '../services/api';
import LogoUploader from './LogoUploader';
import ClientSelector from './ClientSelector';
import InvoiceItemsManager from '../components/InvoiceItemsManager';

import DefaultInvoiceTemplate from '../templates/DefaultInvoiceTemplate';
import ModernInvoiceTemplate from '../templates/ModernInvoiceTemplate';
import ClassicInvoiceTemplate from '../templates/ClassicInvoiceTemplate';
import MinimalistInvoiceTemplate from '../templates/MinimalistInvoiceTemplate';
import ProfessionalInvoiceTemplate from '../templates/ProfessionalInvoiceTemplate';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { createInvoice } from '../services/api';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import InvoiceSavedItems from './invoice/InvoiceSavedItems';
import dayjs from 'dayjs';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const templateOptions = [
  { value: 'default', label: 'Default', component: DefaultInvoiceTemplate },
  { value: 'modern', label: 'Modern', component: ModernInvoiceTemplate },
  { value: 'classic', label: 'Classic', component: ClassicInvoiceTemplate },
];

const formatPhoneNumber = (phoneNumberString) => {
  if (!phoneNumberString) return '';
  const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3];
  }
  return phoneNumberString;
};

const AdminCreateInvoice = () => {
  const navigate = useNavigate();
  const [invoice, setInvoice] = useState({
    invoiceNumber: '',
    issueDate: new Date(),
    dueDate: new Date(new Date().setDate(new Date().getDate() + 30)),
    items: [],
    notes: '',
    termsAndConditions: '',
    template: 'default',
    logo: '',
    logoSize: 100,
    businessDetails: {},
    customerDetails: {
      name: '',
      company: '',
      address: '',
      city: '',
      state: '',
      zipCode: '',
      email: '',
      phone: ''
    },
    includeNotes: false,
    includeTerms: false,
    service_address: {
      address: '',
      city: '',
      state: '',
      zip: ''
    }
  });
  const [selectedClient, setSelectedClient] = useState('');
  const [clients, setClients] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [previewContent, setPreviewContent] = useState('');
  const [openLogoModal, setOpenLogoModal] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState('default');
  const [openClientModal, setOpenClientModal] = useState(false);
  const [showClientDetails, setShowClientDetails] = useState(false);
  const [openSavedItemsModal, setOpenSavedItemsModal] = useState(false);
  const [savedItems, setSavedItems] = useState([]);
  const [openClientSelector, setOpenClientSelector] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [logos, setLogos] = useState([]);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success'
  });
  const [successDialog, setSuccessDialog] = useState(false);
  const [businessDetails, setBusinessDetails] = useState(null);
  const [useServiceAddress, setUseServiceAddress] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const businessData = await getBusinessDetails();
        console.log('Fetched business details:', businessData);
        setBusinessDetails(businessData);

        const [clientsData, itemsData, logosData] = await Promise.all([
          getClients(),
          getItems(),
          getLogos()
        ]);

        setInvoice(prev => ({
          ...prev,
          invoiceNumber: generateInvoiceNumber()
        }));
        
        setClients(clientsData);
        setSavedItems(itemsData);
        setLogos(logosData);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, []);

  const generateInvoiceNumber = () => {
    const date = new Date();
    const year = date.getFullYear().toString().substr(-2);
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const random = Math.floor(Math.random() * 10000).toString().padStart(5, '0');
    return `INV-${year}${month}${day}-${random}`;
  };

  const handleClientSelect = (client) => {
    console.log('Selected client in handleClientSelect:', client); // Debug log
    setSelectedClient(client);
    setInvoice(prevInvoice => ({
      ...prevInvoice,
      customerDetails: {
        name: client.name,
        company: client.company || '',
        address: client.address || '',
        city: client.city || '',
        state: client.state || '',
        zipCode: client.zip_code || '',
        email: client.email || '',
        phone: client.phone || ''
      }
    }));
    handleCloseClientSelector();
  };

  const handleTemplateChange = (event) => {
    setSelectedTemplate(event.target.value);
    setInvoice(prevInvoice => ({
      ...prevInvoice,
      template: event.target.value
    }));
  };

  const handleItemChange = (index, field, value) => {
    console.log('handleItemChange input:', { index, field, value });
    const updatedItems = [...invoice.items];
    
    if (typeof value === 'object' && value !== null && value.fromAutocomplete) {
      console.log('Processing autocomplete value:', value);
      
      const quantity = 1;
      const unitPrice = Number(value.unitPrice);
      const total = quantity * unitPrice;  // Calculate total

      updatedItems[index] = {
        title: value.title,
        description: value.description || '',
        quantity: quantity,
        unitPrice: unitPrice,
        tax: 0,
        total: total,    // Set the total
        value: total     // Set the value
      };

      console.log('Created item from autocomplete:', updatedItems[index]);
    }

    setInvoice(prev => ({
      ...prev,
      items: updatedItems
    }));
  };

  const addItem = () => {
    setInvoice({
      ...invoice,
      items: [...invoice.items, { 
        title: '',
        description: '',
        quantity: 1,
        unitPrice: 0,
        tax: 0,
        total: 0,
        value: 0
      }]
    });
  };

  const removeItem = (index) => {
    setInvoice({
      ...invoice,
      items: invoice.items.filter((_, i) => i !== index)
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    
    try {
      if (!selectedClient?.id) {
        throw new Error('Please select a client');
      }

      const formattedInvoice = {
        client_id: selectedClient.id,
        invoice_number: invoice.invoiceNumber,
        issue_date: dayjs(invoice.issueDate).format('YYYY-MM-DDTHH:mm:ss.SSS'),
        due_date: dayjs(invoice.dueDate).format('YYYY-MM-DDTHH:mm:ss.SSS'),
        items: invoice.items.map(item => ({
          title: item.title || '',
          description: item.description || '',
          quantity: Number(item.quantity) || 1,
          unitPrice: Number(item.unitPrice) || 0,
          tax: Number(item.tax) || 0,
          total: Number(item.unitPrice) || 0,
          value: Number(item.unitPrice) || 0
        })),
        business_details: {
          company_name: businessDetails?.company_name || '',
          owner_name: businessDetails?.owner_name || '',
          address: businessDetails?.address || '',
          city: businessDetails?.city || '',
          state: businessDetails?.state || '',
          zip_code: businessDetails?.zip_code || '',
          email: businessDetails?.email || '',
          phone: businessDetails?.phone || ''
        },
        customer_details: invoice.customerDetails,
        notes: invoice.notes || '',
        terms_and_conditions: invoice.termsAndConditions || '',
        template: invoice.template || 'default',
        logo: invoice.logo || '',
        logo_size: 100,
        include_notes: invoice.includeNotes || false,
        include_terms: invoice.includeTerms || false,
        status: 'pending'
      };

      console.log('Submitting invoice:', formattedInvoice);
      const response = await createInvoice(formattedInvoice);
      
      if (response && response.message === "Invoice created successfully") {
        setSuccessDialog(true);
        setTimeout(() => {
          setSuccessDialog(false);
          navigate(`/admin/invoices/${response.data.id}`);
        }, 2000);
      }
    } catch (error) {
      console.error('Error creating invoice:', error);
      setSnackbar({
        open: true,
        message: error.message || 'Failed to create invoice',
        severity: 'error'
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleOpenLogoModal = () => setOpenLogoModal(true);
  const handleCloseLogoModal = () => setOpenLogoModal(false);
  const handleOpenClientModal = () => setOpenClientModal(true);
  const handleCloseClientModal = () => setOpenClientModal(false);
  const handleOpenSavedItemsModal = () => setOpenSavedItemsModal(true);
  const handleCloseSavedItemsModal = () => setOpenSavedItemsModal(false);

  const handleLogoSelect = (logo) => {
    setInvoice(prevInvoice => ({
      ...prevInvoice,
      logo: `/static/project_files/${logo.filename}`
    }));
    handleCloseLogoModal();
  };

  const handleCustomerDetailsChange = (field, value) => {
    setInvoice(prev => ({
      ...prev,
      customerDetails: {
        ...prev.customerDetails,
        [field]: value
      }
    }));
  };

  const calculateItemTotal = (item) => {
    const subtotal = item.quantity * item.unitPrice;
    const tax = subtotal * (item.tax / 100);
    return (subtotal + tax).toFixed(2);
  };

  const calculateSubtotal = () => {
    return invoice.items.reduce((total, item) => total + (item.quantity * item.unitPrice), 0).toFixed(2);
  };

  const calculateTotalTax = () => {
    return invoice.items.reduce((total, item) => {
      const subtotal = item.quantity * item.unitPrice;
      return total + (subtotal * (item.tax / 100));
    }, 0).toFixed(2);
  };

  const calculateTotal = () => {
    const subtotal = parseFloat(calculateSubtotal());
    const tax = parseFloat(calculateTotalTax());
    return (subtotal + tax).toFixed(2);
  };

  const handleAddSavedItems = (selectedItems) => {
    setInvoice(prev => ({
      ...prev,
      items: [...prev.items, ...selectedItems.map(item => {
        const quantity = parseFloat(item.quantity) || 1;
        const unitPrice = parseFloat(item.unitPrice) || 0;
        const tax = parseFloat(item.tax) || 0;
        const subtotal = quantity * unitPrice;
        const taxAmount = subtotal * (tax / 100);
        const total = subtotal + taxAmount;

        return {
          title: item.title || '',
          description: item.description || '',
          quantity: quantity,
          unitPrice: unitPrice,
          tax: tax,
          total: total,
          value: total
        };
      })]
    }));
    handleCloseSavedItemsModal();
  };

  const renderInvoiceTemplate = () => {
    const formattedItems = invoice.items.map(item => ({
      title: item.title || '',
      description: item.description || '',
      quantity: Number(item.quantity) || 1,
      unitPrice: Number(item.unitPrice) || 0,
      tax: Number(item.tax) || 0,
      total: Number(item.unitPrice) || 0,
      value: Number(item.unitPrice) || 0
    }));

    const templateProps = {
      businessDetails: businessDetails,
      customerDetails: invoice.customerDetails,
      invoiceNumber: invoice.invoiceNumber,
      issueDate: invoice.issueDate,
      dueDate: invoice.dueDate,
      items: formattedItems,
      tax_rate: 0,
      service_address: invoice.service_address || {},
      notes: invoice.notes,
      termsAndConditions: invoice.termsAndConditions,
      includeNotes: invoice.includeNotes,
      includeTerms: invoice.includeTerms,
      logo: invoice.logo
    };

    return <DefaultInvoiceTemplate invoice={templateProps} />;
  };

  const handleOpenClientSelector = () => setOpenClientSelector(true);
  const handleCloseClientSelector = () => setOpenClientSelector(false);

  const toggleClientDetails = () => {
    setShowClientDetails(!showClientDetails);
  };

  const handleLogoUpload = async (file) => {
    try {
      const uploadedLogo = await uploadLogo(file);
      setLogos(prevLogos => [...prevLogos, uploadedLogo]);
    } catch (error) {
      console.error('Error uploading logo:', error);
    }
  };

  const handleLogoDelete = async (logoId) => {
    try {
      await deleteLogo(logoId);
      setLogos(prevLogos => prevLogos.filter(logo => logo.id !== logoId));
    } catch (error) {
      console.error('Error deleting logo:', error);
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Box sx={{ padding: 3 }}>
        <Typography variant="h4" gutterBottom>Create Invoice</Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Paper elevation={3} sx={{ padding: 3 }}>
              <form onSubmit={handleSubmit}>
                <Grid container spacing={2} alignItems="center" sx={{ mb: 3 }}>
                  <Grid item xs={12} sm={6}>
                    <Button
                      fullWidth
                      variant="outlined"
                      color="primary"
                      onClick={handleOpenLogoModal}
                      startIcon={<CloudUploadIcon />}
                    >
                      {invoice.logo ? 'Change Logo' : 'Select Logo'}
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Button
                      fullWidth
                      variant="outlined"
                      onClick={handleOpenClientSelector}
                      startIcon={<PersonIcon />}
                    >
                      {selectedClient ? 'Change Client' : 'Select Client'}
                    </Button>
                  </Grid>
                </Grid>

                {invoice.logo && (
                  <Box sx={{ mb: 3, textAlign: 'center' }}>
                    <img 
                      src={invoice.logo} 
                      alt="Selected Logo" 
                      style={{ maxWidth: '200px', maxHeight: '100px' }} 
                    />
                  </Box>
                )}

                <Box sx={{ mb: 3 }}>
                  <Button
                    fullWidth
                    variant="outlined"
                    onClick={toggleClientDetails}
                    startIcon={<EditIcon />}
                    disabled={!selectedClient}
                  >
                    {showClientDetails ? 'Hide Client Details' : 'Edit Client Details'}
                  </Button>
                </Box>

                <Collapse in={showClientDetails}>
                  <Box sx={{ mb: 3 }}>
                    <Typography variant="h6" gutterBottom>Client Details</Typography>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          label="Client Name"
                          value={invoice.customerDetails.name || ''}
                          onChange={(e) => handleCustomerDetailsChange('name', e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          label="Company"
                          value={invoice.customerDetails.company || ''}
                          onChange={(e) => handleCustomerDetailsChange('company', e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          label="Address"
                          value={invoice.customerDetails.address || ''}
                          onChange={(e) => handleCustomerDetailsChange('address', e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <TextField
                          fullWidth
                          label="City"
                          value={invoice.customerDetails.city || ''}
                          onChange={(e) => handleCustomerDetailsChange('city', e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <TextField
                          fullWidth
                          label="State"
                          value={invoice.customerDetails.state || ''}
                          onChange={(e) => handleCustomerDetailsChange('state', e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <TextField
                          fullWidth
                          label="Zip Code"
                          value={invoice.customerDetails.zipCode || ''}
                          onChange={(e) => handleCustomerDetailsChange('zipCode', e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          label="Email"
                          value={invoice.customerDetails.email || ''}
                          onChange={(e) => handleCustomerDetailsChange('email', e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          label="Phone"
                          value={invoice.customerDetails.phone || ''}
                          onChange={(e) => handleCustomerDetailsChange('phone', e.target.value)}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Collapse>

                <Box sx={{ mt: 2, mb: 2 }}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={useServiceAddress}
                        onChange={(e) => setUseServiceAddress(e.target.checked)}
                      />
                    }
                    label="Add Service Address"
                  />
                </Box>

                <Collapse in={useServiceAddress}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="Service Address"
                        value={invoice.service_address?.address || ''}
                        onChange={(e) => setInvoice(prev => ({
                          ...prev,
                          service_address: {
                            ...prev.service_address,
                            address: e.target.value
                          }
                        }))}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        fullWidth
                        label="Service City"
                        value={invoice.service_address?.city || ''}
                        onChange={(e) => setInvoice(prev => ({
                          ...prev,
                          service_address: {
                            ...prev.service_address,
                            city: e.target.value
                          }
                        }))}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        fullWidth
                        label="Service State"
                        value={invoice.service_address?.state || ''}
                        onChange={(e) => setInvoice(prev => ({
                          ...prev,
                          service_address: {
                            ...prev.service_address,
                            state: e.target.value
                          }
                        }))}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        fullWidth
                        label="Service Zip"
                        value={invoice.service_address?.zip || ''}
                        onChange={(e) => setInvoice(prev => ({
                          ...prev,
                          service_address: {
                            ...prev.service_address,
                            zip: e.target.value
                          }
                        }))}
                      />
                    </Grid>
                  </Grid>
                </Collapse>

                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Invoice Number"
                      value={invoice.invoiceNumber}
                      InputProps={{ readOnly: true }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                      <InputLabel>Template</InputLabel>
                      <Select
                        value={selectedTemplate}
                        onChange={handleTemplateChange}
                        label="Template"
                      >
                        {templateOptions.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <DatePicker
                      label="Issue Date"
                      value={invoice.issueDate}
                      onChange={(newValue) => setInvoice({ ...invoice, issueDate: newValue })}
                      renderInput={(params) => <TextField {...params} fullWidth />}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <DatePicker
                      label="Due Date"
                      value={invoice.dueDate}
                      onChange={(newValue) => setInvoice({ ...invoice, dueDate: newValue })}
                      renderInput={(params) => <TextField {...params} fullWidth />}
                    />
                  </Grid>
                </Grid>

                <Box mt={3}>
                  <Typography variant="h6" gutterBottom>Invoice Items</Typography>
                  <InvoiceItemsManager
                    invoiceItems={invoice.items}
                    onAddToInvoice={(item, index) => {
                      const updatedItems = [...invoice.items];
                      if (index !== undefined) {
                        updatedItems[index] = item;
                      } else {
                        updatedItems.push(item);
                      }
                      setInvoice({ ...invoice, items: updatedItems });
                    }}
                    onRemoveItem={removeItem}
                    savedItems={savedItems}
                    setSavedItems={setSavedItems}
                  />
                </Box>

                <Box mt={3}>
                  <Typography variant="h6" gutterBottom>Summary</Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <Typography>Subtotal: ${calculateSubtotal()}</Typography>
                      <Typography>Tax: ${calculateTotalTax()}</Typography>
                      <Typography variant="h6">Total: ${calculateTotal()}</Typography>
                    </Grid>
                  </Grid>
                </Box>

                <Box mt={3}>
                  <Typography variant="h6" gutterBottom>Additional Information</Typography>
                  <Box display="flex" justifyContent="space-between" mb={2}>
                    <Button
                      variant="outlined"
                      color={invoice.includeNotes ? "secondary" : "primary"}
                      onClick={() => setInvoice({ ...invoice, includeNotes: !invoice.includeNotes, notes: invoice.includeNotes ? '' : invoice.notes })}
                    >
                      {invoice.includeNotes ? "Remove Notes" : "Add Notes"}
                    </Button>
                    <Button
                      variant="outlined"
                      color={invoice.includeTerms ? "secondary" : "primary"}
                      onClick={() => setInvoice({ ...invoice, includeTerms: !invoice.includeTerms, termsAndConditions: invoice.includeTerms ? '' : invoice.termsAndConditions })}
                    >
                      {invoice.includeTerms ? "Remove Terms" : "Add Terms"}
                    </Button>
                  </Box>
                  {invoice.includeNotes && (
                    <ReactQuill
                      value={invoice.notes}
                      onChange={(value) => setInvoice({ ...invoice, notes: value })}
                      placeholder="Add notes..."
                      style={{ marginBottom: '20px' }}
                    />
                  )}
                  {invoice.includeTerms && (
                    <ReactQuill
                      value={invoice.termsAndConditions}
                      onChange={(value) => setInvoice({ ...invoice, termsAndConditions: value })}
                      placeholder="Add terms and conditions..."
                      style={{ marginBottom: '20px' }}
                    />
                  )}
                </Box>

                <Box display="flex" justifyContent="flex-end" mt={3}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isLoading}
                    startIcon={isLoading ? <CircularProgress size={20} color="inherit" /> : null}
                  >
                    {isLoading ? 'Saving...' : 'Save Invoice'}
                  </Button>
                </Box>
              </form>
            </Paper>
          </Grid>

          <Grid item xs={12} md={6}>
            <Paper elevation={3} sx={{ 
              backgroundColor: '#f5f5f5',
              borderRadius: '8px',
              p: 2,
              height: 'fit-content'
            }}>
              <Box sx={{ 
                backgroundColor: '#fff',
                borderRadius: '8px',
                p: 2
              }}>
                <div 
                  className="invoice-details__template" 
                  id="invoice-template"
                  style={{ 
                    width: '100%',
                    maxWidth: '210mm',
                    margin: '0 auto',
                    overflowX: 'auto'
                  }}
                >
                  {renderInvoiceTemplate()}
                </div>
              </Box>
            </Paper>
          </Grid>
        </Grid>

        <LogoUploader
          open={openLogoModal}
          onClose={handleCloseLogoModal}
          onSelectLogo={handleLogoSelect}
          logos={logos}
          onUpload={handleLogoUpload}
          onDelete={handleLogoDelete}
        />
        <InvoiceSavedItems
          open={openSavedItemsModal}
          onClose={handleCloseSavedItemsModal}
          savedItems={savedItems}
          onAddToInvoice={handleAddSavedItems}
        />
        <ClientSelector
          open={openClientSelector}
          onClose={handleCloseClientSelector}
          onClientSelect={handleClientSelect}
        />
        <Snackbar
          open={snackbar.open}
          autoHideDuration={6000}
          onClose={() => setSnackbar({ ...snackbar, open: false })}
        >
          <Alert 
            onClose={() => setSnackbar({ ...snackbar, open: false })} 
            severity={snackbar.severity}
          >
            {snackbar.message}
          </Alert>
        </Snackbar>
        
        <Dialog 
          open={successDialog}
          PaperProps={{
            style: {
              backgroundColor: 'transparent',
              boxShadow: 'none',
            },
          }}
        >
          <DialogContent>
            <Fade in={successDialog}>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                padding={3}
                bgcolor="white"
                borderRadius={2}
                boxShadow={3}
              >
                <CheckCircleIcon 
                  sx={{ 
                    fontSize: 60, 
                    color: 'success.main',
                    animation: 'bounce 0.5s ease infinite',
                    '@keyframes bounce': {
                      '0%, 100%': {
                        transform: 'scale(1)',
                      },
                      '50%': {
                        transform: 'scale(1.1)',
                      },
                    },
                  }} 
                />
                <Typography 
                  variant="h6" 
                  sx={{ mt: 2, color: 'success.main' }}
                >
                  Invoice Created Successfully!
                </Typography>
              </Box>
            </Fade>
          </DialogContent>
        </Dialog>
      </Box>
    </LocalizationProvider>
  );
};

export default AdminCreateInvoice;
