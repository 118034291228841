import React from 'react';
import { Box, TextField, MenuItem, styled, Stack } from '@mui/material';
import {
  Title,
  Description,
  Category,
} from '@mui/icons-material';

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    borderRadius: 8,
    backgroundColor: theme.palette.background.paper,
    transition: 'all 0.2s ease-in-out',
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
    '&.Mui-focused': {
      backgroundColor: theme.palette.background.paper,
      boxShadow: `0 0 0 2px ${theme.palette.primary.main}25`,
    }
  }
}));

const FormBasicSection = ({ formData, onBasicChange }) => {
  return (
    <Stack spacing={2}>
      <TextField
        fullWidth
        label="Form Name"
        value={formData.name}
        onChange={onBasicChange('name')}
        size="small"
      />
      
      <TextField
        fullWidth
        label="Description"
        value={formData.description}
        onChange={onBasicChange('description')}
        multiline
        rows={3}
        size="small"
      />
    </Stack>
  );
};

export default FormBasicSection; 