import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Typography, Paper, Grid, CircularProgress, Button, Snackbar, Alert, Avatar, Tooltip, TextField, Select, MenuItem, InputAdornment } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import { getEmployeeDashboard, clockInToEvent, clockOutFromEvent, getServerTime } from '../services/api';
import styles from './EmployeeDashboard.module.scss';
import VisibilityIcon from '@mui/icons-material/Visibility';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import EventIcon from '@mui/icons-material/Event';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import ScheduleIcon from '@mui/icons-material/Schedule';
import RoomIcon from '@mui/icons-material/Room';
import PersonIcon from '@mui/icons-material/Person';
import SearchIcon from '@mui/icons-material/Search';
import SortIcon from '@mui/icons-material/Sort';
import { differenceInSeconds, parseISO, isValid } from 'date-fns';
import StopIcon from '@mui/icons-material/Stop';
import { useNavigate } from 'react-router-dom';

const avatarColors = ['#1976d2', '#388e3c', '#d32f2f', '#7b1fa2', '#c2185b', '#0288d1', '#00796b', '#fbc02d', '#f57c00', '#455a64'];

// Define formatTime function outside and before the component
const formatTime = (seconds) => {
  if (isNaN(seconds) || seconds < 0) return '00:00:00';
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const secs = seconds % 60;
  return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
};

const EmployeeDashboard = React.memo(() => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [dashboardData, setDashboardData] = useState({
    employee: null,
    assignedEvents: [],
    recentTimesheets: [],
    clockedIn: false,
    currentEvent: null,
    clockInTime: null
  });
  const [elapsedTime, setElapsedTime] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  const [confirmClockIn, setConfirmClockIn] = useState({ open: false, eventId: null });
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortBy, setSortBy] = useState('upcoming');
  const navigate = useNavigate();

  const fetchDashboardData = useCallback(async () => {
    try {
      setLoading(true);
      const data = await getEmployeeDashboard();
      console.log('Dashboard data received:', data);
      setDashboardData(prevData => ({
        ...data,
        clockInTime: prevData.clockInTime,
        clockedIn: prevData.clockedIn,
        currentEvent: prevData.currentEvent
      }));
      setError(null);
    } catch (error) {
      console.error('Error fetching dashboard data:', error);
      setError('Failed to fetch dashboard data. Please try again.');
    } finally {
      setLoading(false);
    }
  }, []);

  const handleClockIn = useCallback(async (eventId) => {
    try {
      const response = await clockInToEvent(eventId);
      console.log('Clock in response:', response);
      
      const clockInTime = Date.now();
      localStorage.setItem('clockInTime', clockInTime.toString());
      localStorage.setItem('clockedInEventId', eventId);

      setDashboardData(prevData => {
        const updatedData = {
          ...prevData,
          clockedIn: true,
          currentEvent: { id: eventId },
          clockInTime: clockInTime
        };
        console.log('Updated dashboard data after clock in:', updatedData);
        return updatedData;
      });

      setElapsedTime(0);
      setSnackbar({ open: true, message: 'Successfully clocked in', severity: 'success' });
    } catch (err) {
      console.error('Error clocking in:', err);
      setSnackbar({ open: true, message: `Failed to clock in: ${err.message}`, severity: 'error' });
    }
  }, []);

  const handleClockOut = useCallback(async () => {
    console.log('Attempting to clock out...');
    try {
      const response = await clockOutFromEvent();
      console.log('Clock out response:', response);

      localStorage.removeItem('clockInTime');
      localStorage.removeItem('clockedInEventId');
      
      setDashboardData(prevData => {
        const updatedData = {
          ...prevData,
          clockedIn: false,
          currentEvent: null,
          clockInTime: null
        };
        console.log('Updated dashboard data after clock out:', updatedData);
        return updatedData;
      });
      
      setElapsedTime(0);
      setSnackbar({ open: true, message: 'Successfully clocked out', severity: 'success' });
    } catch (err) {
      console.error('Error clocking out:', err);
      setSnackbar({ open: true, message: `Failed to clock out: ${err.message}`, severity: 'error' });
    }
  }, []);

  useEffect(() => {
    console.log("Component mounted");
    fetchDashboardData();
    
    // Scroll to top when component mounts
    window.scrollTo(0, 0);

    const storedClockInTime = localStorage.getItem('clockInTime');
    const storedEventId = localStorage.getItem('clockedInEventId');
    
    if (storedClockInTime && storedEventId) {
      const clockInTime = parseInt(storedClockInTime, 10);
      if (!isNaN(clockInTime)) {
        setDashboardData(prevData => {
          const updatedData = {
            ...prevData,
            clockedIn: true,
            currentEvent: { id: storedEventId },
            clockInTime: clockInTime
          };
          console.log('Updated dashboard data from localStorage:', updatedData);
          return updatedData;
        });
      }
    }

    // Set isLoaded to true after a short delay to trigger the animation
    const timer = setTimeout(() => setIsLoaded(true), 100);
    return () => clearTimeout(timer);
  }, [fetchDashboardData]);

  useEffect(() => {
    console.log('Timer effect triggered. Clocked in:', dashboardData.clockedIn, 'Clock-in time:', dashboardData.clockInTime);
    let intervalId;
    if (dashboardData.clockedIn && dashboardData.clockInTime) {
      console.log('Starting timer with clock-in time:', new Date(dashboardData.clockInTime).toISOString());
      intervalId = setInterval(() => {
        const now = Date.now();
        const elapsed = Math.floor((now - dashboardData.clockInTime) / 1000);
        console.log('Elapsed time:', elapsed, 'seconds');
        setElapsedTime(elapsed);
      }, 1000);
    } else {
      console.log('Timer not started or stopped.');
    }
    return () => {
      if (intervalId) {
        console.log('Clearing timer interval');
        clearInterval(intervalId);
      }
    };
  }, [dashboardData.clockedIn, dashboardData.clockInTime]);

  const formattedTime = useMemo(() => formatTime(elapsedTime), [elapsedTime]);

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSortChange = (event) => {
    setSortBy(event.target.value);
  };

  const handleViewEventDetails = (eventId) => {
    navigate(`/employee/events/${eventId}`);
  };

  const filteredAndSortedEvents = dashboardData.assignedEvents
    .filter(event => 
      event.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      (event.addresses && event.addresses[0] && event.addresses[0].toLowerCase().includes(searchTerm.toLowerCase()))
    )
    .sort((a, b) => {
      const now = new Date();
      const aStartTime = new Date(a.time_slots[0].start_time);
      const bStartTime = new Date(b.time_slots[0].start_time);
      
      switch (sortBy) {
        case 'upcoming':
          if (aStartTime < now && bStartTime < now) return bStartTime - aStartTime; // Both past, show most recent first
          if (aStartTime < now) return 1; // A is past, B is upcoming
          if (bStartTime < now) return -1; // B is past, A is upcoming
          return aStartTime - bStartTime; // Both upcoming, show soonest first
        case 'title':
          return a.title.localeCompare(b.title);
        case 'location':
          return (a.addresses && a.addresses[0] ? a.addresses[0] : '').localeCompare(b.addresses && b.addresses[0] ? b.addresses[0] : '');
        default:
          return 0;
      }
    });

  if (loading) return <CircularProgress />;
  if (error) return <Typography color="error">{error}</Typography>;

  return (
    <div className={`${styles.dashboard} employee-dashboard ${isLoaded ? styles.loaded : ''}`}>
      {dashboardData.clockedIn && (
        <Paper className={styles.activeTimerContainer}>
          <Typography variant="h5">ACTIVE TIMER</Typography>
          <div className={styles.activeTimerContent}>
            <AccessTimeIcon />
            <Typography variant="h4">{formattedTime}</Typography>
          </div>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleClockOut}
            className={`${styles.clockOutButton} ${styles.animatedButton}`}
            startIcon={<StopIcon />}
          >
            Clock Out
          </Button>
        </Paper>
      )}
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className={styles.paper}>
            <div className={styles.searchAndSort}>
              <div className={styles.searchBarContainer}>
                <TextField
                  placeholder="Search events"
                  variant="outlined"
                  value={searchTerm}
                  onChange={handleSearchChange}
                  className={styles.searchBar}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment
                        position="start"
                        style={{ marginLeft: '-5px', display: 'flex', alignItems: 'center' }}
                      >
                        <SearchIcon style={{ color: '#757575' }} />
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <Select
                value={sortBy}
                onChange={handleSortChange}
                displayEmpty
                className={styles.sortSelect}
                startAdornment={<SortIcon className={styles.sortIcon} />}
              >
                <MenuItem value="upcoming">Upcoming First</MenuItem>
                <MenuItem value="title">Sort by Title</MenuItem>
                <MenuItem value="location">Sort by Location</MenuItem>
              </Select>
            </div>
            {filteredAndSortedEvents.length > 0 ? (
              filteredAndSortedEvents.map((event) => (
                <div key={event.id} className={styles.eventItem}>
                  <Typography variant="h6" className={styles.eventTitle}>
                    <EventIcon className={`${styles.eventIcon} ${styles.eventTitleIcon}`} />
                    {event.title}
                  </Typography>
                  <div className={styles.eventDetails}>
                    <div className={styles.eventDetail}>
                      <CalendarTodayIcon className={`${styles.eventIcon} ${styles.dateIcon}`} />
                      <Typography variant="body2">
                        {event.time_slots && event.time_slots[0] ? new Date(event.time_slots[0].start_time).toLocaleDateString() : 'Not specified'}
                      </Typography>
                    </div>
                    <div className={styles.eventDetail}>
                      <AccessTimeIcon className={`${styles.eventIcon} ${styles.timeIcon}`} />
                      <Typography variant="body2">
                        {event.time_slots && event.time_slots[0] ? 
                          `${new Date(event.time_slots[0].start_time).toLocaleTimeString()} - ${new Date(event.time_slots[0].end_time).toLocaleTimeString()}` : 
                          'Not specified'}
                      </Typography>
                    </div>
                    <div className={styles.eventDetail}>
                      <LocationOnIcon className={`${styles.eventIcon} ${styles.addressIcon}`} />
                      <Typography variant="body2">
                        {event.addresses && event.addresses[0] ? event.addresses[0] : 'Not specified'}
                      </Typography>
                    </div>
                  </div>
                  <div className={styles.eventActions}>
                    <div className={styles.actionButtons}>
                      {dashboardData.clockedIn && dashboardData.currentEvent?.id === event.id ? (
                        <Button 
                          variant="contained" 
                          onClick={handleClockOut}
                          className={`${styles.clockOutButton} ${styles.animatedButton}`}
                          startIcon={<AccessTimeIcon />}
                        >
                          Clock Out
                        </Button>
                      ) : (
                        <Button 
                          variant="contained" 
                          onClick={() => handleClockIn(event.id)}
                          className={`${styles.clockInButton} ${styles.animatedButton}`}
                          startIcon={<AccessTimeIcon />}
                        >
                          Clock In
                        </Button>
                      )}
                      <Button 
                        variant="outlined" 
                        onClick={() => handleViewEventDetails(event.id)}
                        className={styles.viewDetailsButton}
                        startIcon={<VisibilityIcon />}
                      >
                        View Details
                      </Button>
                    </div>
                    <div className={styles.assignedEmployees}>
                      {event.employees && event.employees.length > 0 && (
                        <Tooltip title={
                          <React.Fragment>
                            <Typography color="inherit">Assigned Employees:</Typography>
                            <ul>
                              {event.employees.map((employee) => (
                                <li key={employee.id}>{employee.name}</li>
                              ))}
                            </ul>
                          </React.Fragment>
                        } arrow>
                          <div className={styles.avatarGroup}>
                            {event.employees.slice(0, 3).map((employee, index) => (
                              <Avatar 
                                key={employee.id} 
                                className={styles.employeeAvatar}
                                sx={{ 
                                  bgcolor: avatarColors[index % avatarColors.length],
                                  color: 'white'
                                }}
                              >
                                <PersonIcon />
                              </Avatar>
                            ))}
                            {event.employees.length > 3 && (
                              <Avatar 
                                className={styles.employeeAvatar}
                                sx={{ 
                                  bgcolor: avatarColors[3 % avatarColors.length],
                                  color: 'white'
                                }}
                              >
                                +{event.employees.length - 3}
                              </Avatar>
                            )}
                          </div>
                        </Tooltip>
                      )}
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <Typography className={styles.noEvents}>No matching events found</Typography>
            )}
          </Paper>
        </Grid>
      </Grid>
      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </div>
  );
});

export default EmployeeDashboard;