import React from 'react';
import { Box, Typography, Popover } from '@mui/material';
import { SketchPicker } from 'react-color';

const ColorPicker = ({ color, onChange, label }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <Box>
      {label && (
        <Typography variant="body2" sx={{ mb: 1 }}>
          {label}
        </Typography>
      )}
      <Box
        onClick={handleClick}
        sx={{
          width: '36px',
          height: '36px',
          borderRadius: '4px',
          border: '2px solid #e0e0e0',
          backgroundColor: color,
          cursor: 'pointer',
          '&:hover': {
            borderColor: '#90caf9',
          },
        }}
      />
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <SketchPicker
          color={color}
          onChange={(color) => onChange(color.hex)}
        />
      </Popover>
    </Box>
  );
};

export default ColorPicker; 