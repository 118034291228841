import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getClients } from '../services/api';
import { 
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, 
  Typography, Button, Box, Grid, IconButton, Chip, Avatar,
  TextField, InputAdornment, Tooltip, CircularProgress, TablePagination
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import BusinessIcon from '@mui/icons-material/Business';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import EditIcon from '@mui/icons-material/Edit';
import SearchIcon from '@mui/icons-material/Search';
import GroupIcon from '@mui/icons-material/Group';
import FolderIcon from '@mui/icons-material/Folder';
import PersonIcon from '@mui/icons-material/Person';
import styles from './Clients.module.scss';
import { useTheme } from '@mui/material/styles';

const Clients = () => {
  const theme= useTheme();
  const darkMode = theme.palette.mode === 'dark';
  const [clients, setClients] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [totalClients, setTotalClients] = useState(0);
  const [filteredTotal, setFilteredTotal] = useState(0);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
      setPage(0);
    }, 300);

    return () => clearTimeout(timer);
  }, [searchTerm]);

  useEffect(() => {
    fetchClients(page + 1, rowsPerPage);
  }, [page, rowsPerPage, debouncedSearchTerm]);

  const fetchClients = async (pageNum = 1, limit = rowsPerPage) => {
    try {
      setLoading(true);
      const data = await getClients(pageNum, debouncedSearchTerm, limit);
      setClients(data.clients);
      setTotalClients(data.total);
      setFilteredTotal(data.total);
    } catch (err) {
      console.error('Error fetching clients:', err);
      setError('Failed to fetch clients. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  const paginatedClients = clients;

  const headerCells = [
    { icon: <BusinessIcon sx={{ color: '#3498db' }} />, label: 'Client Info' },
    { icon: <EmailIcon sx={{ color: '#e74c3c' }} />, label: 'Email' },
    { icon: <PhoneIcon sx={{ color: '#2ecc71' }} />, label: 'Phone' },
    { icon: <LocationOnIcon sx={{ color: '#9b59b6' }} />, label: 'Address' },
    { icon: <FolderIcon sx={{ color: '#2ecc71' }} />, label: 'Projects' },
  ];

  const handleRowClick = (clientId) => {
    navigate(`/admin/clients/${clientId}`);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0);
    fetchClients(1, newRowsPerPage);
  };

  if (loading) return (
    <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
      <CircularProgress />
    </Box>
  );

  if (error) return (
    <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
      <Typography color="error">{error}</Typography>
    </Box>
  );

  return (
    <div className={styles.pageContainer}>
      <Box mb={4} display="flex" alignItems="center" justifyContent="space-between">
        <Typography variant="h4" className={styles.pageTitle}>
          <GroupIcon className={styles.pageTitleIcon} />
          Client Management
        </Typography>
      </Box>

      <Paper elevation={0} className={styles.contentPaper}>
        <Grid container justifyContent="space-between" alignItems="center" sx={{ p: 2, pb: 3 }}>
          <Grid item>
            <Box className={styles.searchContainer}>
              <SearchIcon className={styles.searchIcon} />
              <input
                type="text"
                className={styles.searchInput}
                placeholder="Search clients..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </Box>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              className={styles.addButton}
              startIcon={<AddIcon />}
              onClick={() => navigate('/admin/create-client')}
            >
              Create Client
            </Button>
          </Grid>
        </Grid>
        <TableContainer className={styles.tableContainer}>
          <Table>
            <TableHead>
              <TableRow className={styles.tableHeader}>
                {headerCells.map((cell, index) => (
                  <TableCell 
                    key={index} 
                    className={`${styles.tableCell} ${styles.headerCell}`}
                    sx={{
                      borderBottom: '1px solid var(--divider)',
                      color: 'var(--text-primary)'
                    }}
                  >
                    <Box display="flex" alignItems="center" gap={1}>
                      {cell.icon}
                      <Typography variant="subtitle2" className={styles.headerLabel}>
                        {cell.label}
                      </Typography>
                    </Box>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedClients.map((client) => (
                <TableRow 
                  key={client.id} 
                  className={styles.tableRow}
                  onClick={() => handleRowClick(client.id)}
                  hover
                  sx={{
                    '& .MuiTableCell-root': {
                      borderBottom: '1px solid var(--divider)',
                      color: 'var(--text-primary)'
                    }
                  }}
                >
                  <TableCell className={styles.tableCell}>
                    <Box display="flex" flexDirection="column">
                      <Typography variant="subtitle1" fontWeight="medium">
                        {client.name}
                      </Typography>
                      {client.company && client.company.trim() !== '' && (
                        <Typography 
                          variant="body2" 
                          sx={{ color: 'var(--text-secondary)' }}
                        >
                          {client.company}
                        </Typography>
                      )}
                    </Box>
                  </TableCell>
                  <TableCell className={styles.tableCell}>
                    <Box display="flex" alignItems="center" gap={1}>
                      <EmailIcon fontSize="small" className={styles.tableIcon} />
                      <Typography>{client.email || 'No email provided'}</Typography>
                    </Box>
                  </TableCell>
                  <TableCell className={styles.tableCell}>
                    <Box display="flex" alignItems="center" gap={1}>
                      <PhoneIcon fontSize="small" className={styles.tableIcon} />
                      <Typography>{client.phone || 'No phone provided'}</Typography>
                    </Box>
                  </TableCell>
                  <TableCell className={styles.tableCell}>
                    <Box display="flex" alignItems="center" gap={1}>
                      <LocationOnIcon fontSize="small" className={styles.tableIcon} />
                      <Typography>{client.address || 'No address provided'}</Typography>
                    </Box>
                  </TableCell>
                  <TableCell className={styles.tableCell}>
                    <Chip 
                      label={`${client.projects.length} Projects`}
                      color={client.projects.length > 0 ? "primary" : "default"}
                      size="small"
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          component="div"
          count={filteredTotal}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[25, 50, 100, 250]}
          sx={{
            '.MuiTablePagination-select': {
              paddingTop: '0.5rem',
              color: 'var(--text-primary)',
            },
            '.MuiTablePagination-selectLabel': {
              color: 'var(--text-primary)',
            },
            '.MuiTablePagination-displayedRows': {
              color: 'var(--text-primary)',
            },
            '.MuiTablePagination-actions': {
              color: 'var(--text-primary)',
              '& .MuiIconButton-root': {
                color: 'var(--text-primary)',
              },
              '& .Mui-disabled': {
                color: 'var(--text-disabled)',
              }
            },
            '.MuiSelect-icon': {
              color: 'var(--text-primary)',
            }
          }}
        />

        {clients.length === 0 && (
          <Box textAlign="center" py={4}>
            <Typography variant="h6" color="textSecondary">
              No clients found
            </Typography>
            <Typography variant="body2" color="textSecondary">
              Try adjusting your search or create a new client
            </Typography>
          </Box>
        )}
      </Paper>
    </div>
  );
};

export default Clients;
