import React, { useState } from 'react';
import {
  AppBar,
  Toolbar,
  IconButton,
  Button,
  Typography,
  Menu,
  MenuItem,
  Box,
  Divider,
  TextField,
  Tooltip,
} from '@mui/material';
import {
  Undo as UndoIcon,
  Redo as RedoIcon,
  Save as SaveIcon,
  Visibility as PreviewIcon,
  Settings as SettingsIcon,
  Palette as PaletteIcon,
  DeviceHub as LayoutIcon,
  Code as CodeIcon,
  Image as ImageIcon,
  TextFields as TextIcon,
} from '@mui/icons-material';
import ColorPicker from './ColorPicker';

const Navbar = ({ 
  onPreview, 
  onSave, 
  onUndo, 
  onRedo, 
  canUndo, 
  canRedo,
  settings,
  onSettingsChange 
}) => {
  const [settingsMenu, setSettingsMenu] = useState(null);
  const [styleMenu, setStyleMenu] = useState(null);
  const [layoutMenu, setLayoutMenu] = useState(null);

  const handleSettingsChange = (key, value) => {
    onSettingsChange({ ...settings, [key]: value });
  };

  return (
    <AppBar position="static" color="default" elevation={1}>
      <Toolbar sx={{ gap: 1 }}>
        <Typography variant="h6" sx={{ flexGrow: 1 }}>
          Website Builder
        </Typography>

        {/* History Controls */}
        <Tooltip title="Undo">
          <IconButton onClick={onUndo} disabled={!canUndo}>
            <UndoIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Redo">
          <IconButton onClick={onRedo} disabled={!canRedo}>
            <RedoIcon />
          </IconButton>
        </Tooltip>

        <Divider orientation="vertical" flexItem sx={{ mx: 1 }} />

        {/* Style Controls */}
        <Tooltip title="Style Settings">
          <IconButton onClick={(e) => setStyleMenu(e.currentTarget)}>
            <PaletteIcon />
          </IconButton>
        </Tooltip>
        <Menu
          anchorEl={styleMenu}
          open={Boolean(styleMenu)}
          onClose={() => setStyleMenu(null)}
        >
          <Box sx={{ p: 2, width: 300 }}>
            <Typography variant="subtitle2" gutterBottom>
              Colors
            </Typography>
            <Box sx={{ mb: 2 }}>
              <ColorPicker
                label="Primary Color"
                color={settings?.primaryColor || '#1976d2'}
                onChange={(color) => handleSettingsChange('primaryColor', color)}
              />
            </Box>
            <Box sx={{ mb: 2 }}>
              <ColorPicker
                label="Background Color"
                color={settings?.backgroundColor || '#ffffff'}
                onChange={(color) => handleSettingsChange('backgroundColor', color)}
              />
            </Box>
            <Typography variant="subtitle2" gutterBottom>
              Typography
            </Typography>
            <TextField
              select
              fullWidth
              size="small"
              label="Font Family"
              value={settings?.fontFamily || 'Roboto'}
              onChange={(e) => handleSettingsChange('fontFamily', e.target.value)}
            >
              <MenuItem value="Roboto">Roboto</MenuItem>
              <MenuItem value="Open Sans">Open Sans</MenuItem>
              <MenuItem value="Lato">Lato</MenuItem>
              <MenuItem value="Montserrat">Montserrat</MenuItem>
            </TextField>
          </Box>
        </Menu>

        {/* Layout Controls */}
        <Tooltip title="Layout Settings">
          <IconButton onClick={(e) => setLayoutMenu(e.currentTarget)}>
            <LayoutIcon />
          </IconButton>
        </Tooltip>
        <Menu
          anchorEl={layoutMenu}
          open={Boolean(layoutMenu)}
          onClose={() => setLayoutMenu(null)}
        >
          <Box sx={{ p: 2, width: 300 }}>
            <TextField
              select
              fullWidth
              size="small"
              label="Container Width"
              value={settings?.containerWidth || 'wide'}
              onChange={(e) => handleSettingsChange('containerWidth', e.target.value)}
            >
              <MenuItem value="narrow">Narrow</MenuItem>
              <MenuItem value="medium">Medium</MenuItem>
              <MenuItem value="wide">Wide</MenuItem>
              <MenuItem value="full">Full Width</MenuItem>
            </TextField>
          </Box>
        </Menu>

        {/* General Settings */}
        <Tooltip title="Settings">
          <IconButton onClick={(e) => setSettingsMenu(e.currentTarget)}>
            <SettingsIcon />
          </IconButton>
        </Tooltip>
        <Menu
          anchorEl={settingsMenu}
          open={Boolean(settingsMenu)}
          onClose={() => setSettingsMenu(null)}
        >
          <Box sx={{ p: 2, width: 300 }}>
            <TextField
              fullWidth
              size="small"
              label="Website Title"
              value={settings?.title || ''}
              onChange={(e) => handleSettingsChange('title', e.target.value)}
              sx={{ mb: 2 }}
            />
            <TextField
              fullWidth
              size="small"
              label="Meta Description"
              multiline
              rows={2}
              value={settings?.description || ''}
              onChange={(e) => handleSettingsChange('description', e.target.value)}
            />
          </Box>
        </Menu>

        <Divider orientation="vertical" flexItem sx={{ mx: 1 }} />

        {/* Preview & Save */}
        <Tooltip title="Preview">
          <Button
            startIcon={<PreviewIcon />}
            onClick={onPreview}
            variant="outlined"
            sx={{ mr: 1 }}
          >
            Preview
          </Button>
        </Tooltip>
        
        <Tooltip title="Save">
          <Button
            startIcon={<SaveIcon />}
            onClick={onSave}
            variant="contained"
            color="primary"
          >
            Save
          </Button>
        </Tooltip>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar; 