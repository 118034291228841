import React, { useState, useEffect, useRef } from 'react';
import { 
  Box, 
  TextField, 
  Popper, 
  Paper, 
  List, 
  ListItem, 
  ListItemText,
  Typography,
  InputAdornment,
  CircularProgress,
  Fade,
  Divider,
  Chip,
  IconButton,
  Tooltip
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import FolderIcon from '@mui/icons-material/Folder';
import EventIcon from '@mui/icons-material/Event';
import PersonIcon from '@mui/icons-material/Person';
import ReceiptIcon from '@mui/icons-material/Receipt';
import BusinessIcon from '@mui/icons-material/Business';
import BoltIcon from '@mui/icons-material/Bolt';
import { useNavigate } from 'react-router-dom';
import debounce from 'lodash/debounce';
import { searchGlobal, vannaSearch, getVannaSuggestions } from '../services/api';
import VannaResults from './VannaResults';

const SearchBar = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [isVannaMode, setIsVannaMode] = useState(false);
  const [vannaResults, setVannaResults] = useState(null);
  const [vannaSuggestions, setVannaSuggestions] = useState([]);
  const navigate = useNavigate();
  const inputRef = useRef(null);

  const debouncedSearch = useRef(
    debounce(async (query) => {
      if (query.length < 2) {
        setResults([]);
        setVannaResults(null);
        return;
      }
      
      try {
        setLoading(true);
        if (isVannaMode) {
          const vannaSuggs = await getVannaSuggestions(query);
          setVannaSuggestions(vannaSuggs);
        } else {
          const searchResults = await searchGlobal(query);
          setResults(searchResults);
        }
      } catch (error) {
        console.error('Search error:', error);
        setResults([]);
      } finally {
        setLoading(false);
      }
    }, 300)
  ).current;

  const groupResults = (results) => {
    return results.reduce((acc, item) => {
      if (!acc[item.type]) {
        acc[item.type] = [];
      }
      acc[item.type].push(item);
      return acc;
    }, {});
  };

  const getIconForType = (type) => {
    switch (type) {
      case 'project':
        return <FolderIcon fontSize="small" />;
      case 'event':
        return <EventIcon fontSize="small" />;
      case 'client':
        return <BusinessIcon fontSize="small" />;
      case 'employee':
        return <PersonIcon fontSize="small" />;
      case 'invoice':
        return <ReceiptIcon fontSize="small" />;
      default:
        return null;
    }
  };

  const getGroupTitle = (type) => {
    switch (type) {
      case 'project':
        return 'Projects';
      case 'event':
        return 'Events';
      case 'client':
        return 'Clients';
      case 'employee':
        return 'Employees';
      case 'invoice':
        return 'Invoices';
      default:
        return type.charAt(0).toUpperCase() + type.slice(1) + 's';
    }
  };

  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === 'Escape') {
        event.preventDefault();
        setIsVisible(prev => !prev);
        
        if (!isVisible) {
          // Force focus after a short delay to ensure the input is ready
          setTimeout(() => {
            if (inputRef.current) {
              inputRef.current.focus();
              // Programmatically click into the input
              const input = inputRef.current.querySelector('input');
              if (input) {
                input.click();
                input.focus();
              }
              setIsExpanded(true);
            }
          }, 50);
        } else {
          setSearchTerm('');
          setResults([]);
          setIsExpanded(false);
          inputRef.current?.blur();
        }
      }
    };

    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [isVisible]);

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
    setAnchorEl(inputRef.current);
    setIsExpanded(value.length > 0);
    debouncedSearch(value);
  };

  const handleResultClick = (link) => {
    navigate(link);
    setSearchTerm('');
    setResults([]);
    setIsExpanded(false);
  };

  const handleFocus = () => {
    setIsExpanded(true);
  };

  const handleBlur = () => {
    if (!searchTerm) {
      setIsExpanded(false);
    }
  };

  const handleVannaSearch = async (query) => {
    if (!query || query.length < 2) return;
    
    try {
      setLoading(true);
      const result = await vannaSearch(query);
      setVannaResults(result);
    } catch (error) {
      console.error('Vanna search error:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleSearchSubmit = async (e) => {
    if (e.key === 'Enter' && isVannaMode) {
      await handleVannaSearch(searchTerm);
    }
  };

  const groupedResults = groupResults(results);

  return (
    <Box 
      sx={{ 
        position: 'relative',
        opacity: isVisible ? 1 : 0,
        visibility: isVisible ? 'visible' : 'hidden',
        transition: 'opacity 0.2s ease-in-out, visibility 0.2s ease-in-out',
        transform: isVisible ? 'translateY(0)' : 'translateY(-10px)',
        zIndex: 1000,
        pointerEvents: 'auto',
      }}
    >
      <TextField
        ref={inputRef}
        value={searchTerm}
        onChange={handleSearchChange}
        onFocus={() => {
          handleFocus();
          setIsVisible(true);
        }}
        onBlur={handleBlur}
        placeholder="Search projects, events, clients... (Press ESC to toggle)"
        variant="outlined"
        size="small"
        sx={{
          width: isExpanded ? '800px' : '600px',
          transition: 'width 0.3s ease-in-out',
          '& .MuiOutlinedInput-root': {
            backgroundColor: 'white',
            borderRadius: '12px',
            height: '44px',
            transition: 'all 0.2s ease-in-out',
            border: '2px solid transparent',
            boxShadow: '0 2px 8px rgba(0,0,0,0.08)',
            
            '&:hover': {
              backgroundColor: '#f8f9fa',
              boxShadow: '0 4px 12px rgba(0,0,0,0.12)',
            },
            
            '&.Mui-focused': {
              borderColor: 'primary.main',
              boxShadow: '0 4px 12px rgba(0,0,0,0.12)',
              backgroundColor: 'white',
            },
            
            '& fieldset': {
              borderWidth: '1px',
              borderColor: 'rgba(0, 0, 0, 0.12)',
            },
            
            '&:hover fieldset': {
              borderColor: 'rgba(0, 0, 0, 0.23)',
            },
            
            '&.Mui-focused fieldset': {
              borderWidth: '1px',
              borderColor: 'primary.main',
            },
          },
          '& .MuiInputBase-input': {
            padding: '12px 14px',
            fontSize: '0.95rem',
          },
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon 
                color="action" 
                sx={{ 
                  ml: 1,
                  color: 'text.secondary',
                  transition: 'color 0.2s',
                  '.Mui-focused &': {
                    color: 'primary.main',
                  }
                }} 
              />
            </InputAdornment>
          ),
          endAdornment: (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {loading && (
                <CircularProgress 
                  size={20} 
                  sx={{ mr: 1, color: 'primary.main' }} 
                />
              )}
              <Tooltip title={`${isVannaMode ? 'AI Search Mode' : 'Regular Search Mode'}`}>
                <IconButton
                  onClick={() => setIsVannaMode(!isVannaMode)}
                  sx={{
                    color: isVannaMode ? 'primary.main' : 'action.active',
                    '&:hover': {
                      color: 'primary.main',
                    },
                  }}
                >
                  <BoltIcon />
                </IconButton>
              </Tooltip>
            </Box>
          ),
        }}
        onKeyPress={handleSearchSubmit}
      />

      <Popper
        open={Boolean(results.length) && isVisible}
        anchorEl={anchorEl}
        placement="bottom-start"
        transition
        modifiers={[
          {
            name: 'offset',
            options: {
              offset: [0, 8],
            },
          },
        ]}
        sx={{ zIndex: 1100 }}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={200}>
            <Paper
              sx={{
                width: isExpanded ? '800px' : '600px',
                maxHeight: '600px',
                overflow: 'auto',
                mt: 1,
                borderRadius: '12px',
                boxShadow: '0 4px 20px rgba(0,0,0,0.15)',
                border: '1px solid rgba(0,0,0,0.08)',
                transition: 'width 0.3s ease-in-out',
                '&::-webkit-scrollbar': {
                  width: '8px',
                },
                '&::-webkit-scrollbar-track': {
                  background: '#f1f1f1',
                  borderRadius: '4px',
                },
                '&::-webkit-scrollbar-thumb': {
                  background: '#888',
                  borderRadius: '4px',
                  '&:hover': {
                    background: '#666',
                  },
                },
              }}
            >
              <List sx={{ py: 0 }}>
                {Object.entries(groupedResults).map(([type, items], index) => (
                  <React.Fragment key={type}>
                    {index > 0 && <Divider sx={{ my: 0.5 }} />}
                    <ListItem 
                      sx={{ 
                        py: 1, 
                        px: 2,
                        bgcolor: 'grey.50',
                        borderBottom: '1px solid rgba(0,0,0,0.05)'
                      }}
                    >
                      <Typography 
                        variant="subtitle2" 
                        color="text.secondary"
                        sx={{ 
                          fontWeight: 600,
                          textTransform: 'uppercase',
                          fontSize: '0.75rem',
                          letterSpacing: '0.5px'
                        }}
                      >
                        {getGroupTitle(type)}
                      </Typography>
                    </ListItem>
                    {items.map((result) => (
                      <ListItem
                        key={`${result.type}-${result.id}`}
                        onClick={() => handleResultClick(result.link)}
                        sx={{
                          py: 2,
                          px: 2,
                          cursor: 'pointer',
                          transition: 'all 0.2s',
                          '&:hover': {
                            backgroundColor: 'action.hover',
                          },
                        }}
                      >
                        <Box sx={{ display: 'flex', alignItems: 'flex-start', width: '100%' }}>
                          <InputAdornment position="start" sx={{ mr: 2, mt: 0.5 }}>
                            {getIconForType(result.type)}
                          </InputAdornment>
                          <Box sx={{ flex: 1 }}>
                            <Typography variant="subtitle1" sx={{ fontWeight: 500, mb: 0.5 }}>
                              {result.title}
                            </Typography>
                            <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
                              {result.subtitle}
                            </Typography>
                            {result.type === 'project' && (
                              <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
                                <Chip 
                                  size="small" 
                                  label={result.status} 
                                  color={result.status === 'Active' ? 'success' : 'default'}
                                />
                              </Box>
                            )}
                            {result.type === 'event' && (
                              <Typography variant="caption" color="text.secondary">
                                {result.date}
                              </Typography>
                            )}
                            {result.type === 'invoice' && (
                              <Typography variant="caption" color="text.secondary">
                                Amount: ${result.amount}
                              </Typography>
                            )}
                          </Box>
                        </Box>
                      </ListItem>
                    ))}
                  </React.Fragment>
                ))}
              </List>
            </Paper>
          </Fade>
        )}
      </Popper>

      {isVannaMode && (vannaResults || loading) && (
        <VannaResults 
          results={vannaResults?.results} 
          sqlQuery={vannaResults?.sql_query}
          loading={loading}
          width={isExpanded ? '800px' : '600px'}
        />
      )}
    </Box>
  );
};

export default SearchBar; 