import React, { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Grid,
  TextField,
  Autocomplete,
  CircularProgress,
  Button,
  IconButton,
  Typography,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  AutocompleteRenderOptionState
} from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import debounce from 'lodash/debounce';
import { getClients, createClient, searchClients } from '../services/api';

// US States array reference from ClientEstimateForm.tsx
const US_STATES = [
  ['Alabama', 'AL'], ['Alaska', 'AK'], ['Arizona', 'AZ'], ['Arkansas', 'AR'],
  ['California', 'CA'], ['Colorado', 'CO'], ['Connecticut', 'CT'], ['Delaware', 'DE'],
  ['Florida', 'FL'], ['Georgia', 'GA'], ['Hawaii', 'HI'], ['Idaho', 'ID'],
  ['Illinois', 'IL'], ['Indiana', 'IN'], ['Iowa', 'IA'], ['Kansas', 'KS'],
  ['Kentucky', 'KY'], ['Louisiana', 'LA'], ['Maine', 'ME'], ['Maryland', 'MD'],
  ['Massachusetts', 'MA'], ['Michigan', 'MI'], ['Minnesota', 'MN'], ['Mississippi', 'MS'],
  ['Missouri', 'MO'], ['Montana', 'MT'], ['Nebraska', 'NE'], ['Nevada', 'NV'],
  ['New Hampshire', 'NH'], ['New Jersey', 'NJ'], ['New Mexico', 'NM'], ['New York', 'NY'],
  ['North Carolina', 'NC'], ['North Dakota', 'ND'], ['Ohio', 'OH'], ['Oklahoma', 'OK'],
  ['Oregon', 'OR'], ['Pennsylvania', 'PA'], ['Rhode Island', 'RI'], ['South Carolina', 'SC'],
  ['South Dakota', 'SD'], ['Tennessee', 'TN'], ['Texas', 'TX'], ['Utah', 'UT'],
  ['Vermont', 'VT'], ['Virginia', 'VA'], ['Washington', 'WA'], ['West Virginia', 'WV'],
  ['Wisconsin', 'WI'], ['Wyoming', 'WY']
] as const;

interface NominatimResult {
  display_name: string;
  address: {
    house_number?: string;
    road?: string;
    city?: string;
    town?: string;
    state?: string;
    postcode?: string;
    municipality?: string;
  };
}

interface GeolocationPosition {
  coords: {
    latitude: number;
    longitude: number;
  };
}

interface Client {
  id: number | null;
  name: string;
  email: string;
  phone: string;
  address: string;
  city: string;
  state: string;
  zip_code: string;
  company: string;
  notes: string;
  created_at?: string;
  updated_at?: string;
}

interface Props {
  onClientSelect: (client: Client | null) => void;
  onClientCreate: (client: Client) => void;
  selectedClient: Client | null;
}

interface FormData {
  name: string;
  email: string;
  phone: string;
  address: string;
  city: string;
  state: string;
  zipCode: string;
  company: string;
  notes: string;
}

interface AutocompleteOption {
  id: number;
  name: string;
  email: string;
  phone: string;
  company?: string;
}

const globalStyles = {
  WebkitTapHighlightColor: 'transparent',
  '*:focus': {
    outline: 'none !important',
    WebkitAppearance: 'none !important',
    boxShadow: 'none !important',
  },
  'input': {
    WebkitAppearance: 'none !important',
    '&:focus': {
      outline: 'none !important',
      WebkitAppearance: 'none !important',
      boxShadow: 'none !important',
    }
  }
};



const NewClientForm: React.FC<Props> = ({ onClientSelect, onClientCreate, selectedClient }) => {
  const [formData, setFormData] = useState<FormData>({
    name: '',
    company: '',
    email: '',
    phone: '',
    address: '',
    city: '',
    state: '',
    zipCode: '',
    notes: ''
  });
  
  const [addressSuggestions, setAddressSuggestions] = useState<NominatimResult[]>([]);
  const [isLoadingAddress, setIsLoadingAddress] = useState(false);
  const [error, setError] = useState('');
  const [locationLoading, setLocationLoading] = useState(false);
  const [isSearchModalOpen, setIsSearchModalOpen] = useState(false);
  const [searchResults, setSearchResults] = useState<Client[]>([]);
  const [isSearching, setIsSearching] = useState(false);
  const [searchInput, setSearchInput] = useState('');
  const [isNewClient, setIsNewClient] = useState(true);
  const [canCreateClient, setCanCreateClient] = useState(false);
  const [displayAddress, setDisplayAddress] = useState('');
  const [isUsingSelectedAddress, setIsUsingSelectedAddress] = useState(false);
  const [formattedAddress, setFormattedAddress] = useState('');

  const inputStyle = {
    height: '45px',
    width: '100%',
    fontSize: '0.95rem',
    border: '1px solid var(--divider)',
    borderRadius: '4px',
    padding: '8px 12px',
    fontFamily: 'inherit',
    backgroundColor: 'var(--background-paper)',
    transition: 'border-color 0.2s ease',
    WebkitTapHighlightColor: 'transparent !important',
    WebkitAppearance: 'none !important',
    '&:hover': {
      border: '1px solid #999',
    },
    '&:focus': {
      border: '1px solid #2196f3 !important',
      outline: 'none !important',
      WebkitAppearance: 'none !important',
      boxShadow: 'none !important',
      '-webkit-box-shadow': 'none !important',
      '-moz-box-shadow': 'none !important'
    },
    '-webkit-user-select': 'text'
  };

  const autocompleteStyle = {
    '& .MuiAutocomplete-input': {
      height: '45px',
      padding: '8px 12px !important',
      fontSize: '0.95rem',
      border: '1px solid #ddd',
      borderRadius: '4px',
      backgroundColor: '#fff',
      transition: 'border-color 0.2s ease',
      WebkitTapHighlightColor: 'transparent',
      '&:hover': {
        border: '1px solid #999',
      },
      '&:focus': {
        border: '1px solid #2196f3',
        outline: 'none',
        WebkitAppearance: 'none',
        boxShadow: 'none'
      }
    }
  };

  const addressInputContainerStyle = {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    '& .MuiCircularProgress-root': {
      position: 'absolute',
      right: '40px',
    },
    '& .MuiIconButton-root': {
      position: 'absolute',
      right: '8px',
    }
  };

  // Get current location and fetch address
  const getCurrentLocation = async () => {
    setLocationLoading(true);
    try {
      const position = await new Promise<GeolocationPosition>((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject);
      });

      const { latitude, longitude } = position.coords;
      const response = await fetch(
        `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}&addressdetails=1`,
        {
          headers: {
            'Accept': 'application/json'
          }
        }
      );
      
      const data = await response.json();
      handleAddressSelect(data);
    } catch (error) {
      setError('Failed to get current location');
    } finally {
      setLocationLoading(false);
    }
  };

  const handleAddressSelect = (selected: NominatimResult | null) => {
    if (!selected?.address) return;

    const addr = selected.address;
    const stateMatch = addr.state 
      ? US_STATES.find(([fullName]) => 
          fullName.toLowerCase() === addr.state?.toLowerCase()
        ) 
      : null;

    const newAddress = `${addr.house_number || ''} ${addr.road || ''}`.trim();
    const city = addr.city || addr.town || addr.municipality || '';
    const state = stateMatch?.[1] || '';
    const zipCode = addr.postcode || '';

    // Update form data
    const updatedFormData = {
      ...formData,
      address: newAddress,
      city: city,
      state: state,
      zipCode: zipCode
    };
    setFormData(updatedFormData);

    // Create formatted address for display
    const formattedAddr = [
      newAddress,
      city,
      state && zipCode ? `${state} ${zipCode}` : ''
    ].filter(Boolean).join(', ');

    // Notify parent with complete client data
    const tempClientData: Client = {
      id: null,
      name: updatedFormData.name,
      email: updatedFormData.email,
      phone: updatedFormData.phone.replace(/\D/g, ''),
      address: newAddress,
      city: city,
      state: state,
      zip_code: zipCode,
      company: updatedFormData.company,
      notes: updatedFormData.notes
    };
    
    console.log('Sending to parent:', tempClientData); // Debug log
    onClientSelect(tempClientData);

    setFormattedAddress(formattedAddr);
    setDisplayAddress(formattedAddr);
  };

  const fetchAddressSuggestions = async (input: string) => {
    if (!input || input.length < 3) {
      setAddressSuggestions([]);
      return;
    }
    
    setIsLoadingAddress(true);
    try {
      const response = await fetch(
        `https://nominatim.openstreetmap.org/search?format=json&q=${encodeURIComponent(input)}&countrycodes=us&limit=5&addressdetails=1`,
        {
          headers: {
            'Accept-Language': 'en-US',
            'User-Agent': 'EverProCleaning_EstimateSystem/1.0',
            'Referer': 'https://teams.everprocleaning.com'
          }
        }
      );
      
      if (!response.ok) throw new Error('Address search failed');
      const data = await response.json();
      
      // Improved address formatting
      const formattedAddresses = data.map(item => ({
        display_name: item.display_name, // Keep original display name
        address: {
          house_number: item.address?.house_number || '',
          road: item.address?.road || '',
          city: item.address?.city || item.address?.town || item.address?.municipality || '',
          state: item.address?.state || '',
          postcode: item.address?.postcode || ''
        }
      }));

      setAddressSuggestions(formattedAddresses);
    } catch (error) {
      console.error('Error fetching addresses:', error);
      setAddressSuggestions([]);
    } finally {
      setIsLoadingAddress(false);
    }
  };

  const debouncedSearchAddress = useCallback(
    debounce((value: string) => {
      console.log('Debounced search called with:', value);
      fetchAddressSuggestions(value);
    }, 300),
    []
  );

  const formatPhoneNumber = (value: string) => {
    const phone = value.replace(/\D/g, '');
    if (phone.length < 4) return phone;
    if (phone.length < 7) return `(${phone.slice(0, 3)}) ${phone.slice(3)}`;
    return `(${phone.slice(0, 3)}) ${phone.slice(3, 6)}-${phone.slice(6, 10)}`;
  };

  const commonInputStyles = {
    '& .MuiFilledInput-root': {
      backgroundColor: 'white',
      transition: 'background-color 0.2s',
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.02)',
      },
      '&.Mui-focused': {
        backgroundColor: 'white',
      },
    },
    '& .MuiInputLabel-root': {
      color: '#666',
    },
    '& .MuiInputAdornment-root': {
      color: '#666',
    },
  };

  const searchAndCreateClient = async (searchValue: string) => {
    setIsSearching(true);
    try {
      if (searchValue.length >= 2) {
        const results = await searchClients(searchValue);
        // Ensure we have all required fields in the search results
        const formattedResults = results.map((client: Client) => ({
          id: client.id,
          name: client.name || '',
          email: client.email || '',
          phone: client.phone || '',
          company: client.company || '',
          address: client.address || '',
          city: client.city || '',
          state: client.state || '',
          zip_code: client.zip_code || '',
          notes: client.notes || ''
        }));
        console.log('Formatted search results:', formattedResults);
        setSearchResults(formattedResults);
      } else {
        setSearchResults([]);
      }
    } catch (error) {
      console.error('Error searching clients:', error);
    } finally {
      setIsSearching(false);
    }
  };

  const handleClientSelect = (client: Client) => {
    console.log('Client selected:', client);
    onClientSelect(client);
    
    // Format the full address for display with proper commas
    const formattedAddress = [
      client.address,
      client.city,
      `${client.state} ${client.zip_code}`
    ].filter(Boolean).join(', ');
    
    setFormData({
      name: client.name || '',
      company: client.company || '',
      email: client.email || '',
      phone: formatPhoneNumber(client.phone || ''),
      address: client.address || '',
      city: client.city || '',
      state: client.state || '',
      zipCode: client.zip_code || '',
      notes: client.notes || ''
    });

    // Update address suggestions with properly formatted address
    if (client.address) {
      setAddressSuggestions([{
        display_name: formattedAddress,
        address: {
          house_number: '',
          road: client.address,
          city: client.city || '',
          state: client.state || '',
          postcode: client.zip_code || ''
        }
      }]);
    }
  };

  // Add useEffect to sync form data with parent when typing
  useEffect(() => {
    if (isNewClient && formData.name) {
      console.log('Syncing form data with parent:', formData);
      const tempClientData: Client = {
        id: null,
        name: formData.name,
        email: formData.email,
        phone: formData.phone.replace(/\D/g, ''),
        address: formData.address,
        city: formData.city,
        state: formData.state,
        zip_code: formData.zipCode,
        company: formData.company,
        notes: formData.notes
      };
      onClientSelect(tempClientData);
    }
  }, [formData]); // Add dependency array

  // Update handleFormChange to sync with parent immediately
  const handleFormChange = (field: string, value: string) => {
    console.log(`Form field ${field} changed to:`, value);
    
    const updatedFormData = {
      ...formData,
      [field]: value
    };
    setFormData(updatedFormData);

    // If we're creating a new client, update parent
    if (isNewClient) {
      const tempClientData: Client = {
        id: null,
        name: updatedFormData.name,
        email: updatedFormData.email,
        phone: updatedFormData.phone.replace(/\D/g, ''),
        address: updatedFormData.address,
        city: updatedFormData.city,
        state: updatedFormData.state,
        zip_code: updatedFormData.zipCode,
        company: updatedFormData.company,
        notes: updatedFormData.notes
      };
      console.log('Updating parent with temp client:', tempClientData);
      onClientSelect(tempClientData);
    }
  };

  // Add debug useEffect
  useEffect(() => {
    console.log('Form data updated:', formData);
    console.log('Is new client:', isNewClient);
  }, [formData, isNewClient]);

  // Add a separate function for explicit client creation
  const createNewClientFromForm = async () => {
    if (!formData.name || !formData.email || !formData.phone) {
      setError('Name, email, and phone are required');
      return null;
    }

    try {
      const newClientData = {
        name: formData.name,
        email: formData.email,
        phone: formData.phone.replace(/\D/g, ''),
        address: formData.address,
        city: formData.city,
        state: formData.state,
        zip_code: formData.zipCode,
        company: formData.company,
        notes: formData.notes
      };

      console.log('Creating new client:', newClientData);
      const createdClient = await createClient(newClientData);
      console.log('Created client:', createdClient);
      
      if (createdClient) {
        setIsNewClient(false);
        onClientSelect(createdClient);
        setError('');
        return createdClient;
      }
    } catch (error) {
      console.error('Error creating client:', error);
      setError('Failed to create client');
      return null;
    }
  };

  return (
    <Box sx={{ 
      m: 0,  // Remove all margins
      p: 0,  // Remove all padding
      '& .MuiGrid-root': {
        m: 0  // Remove Grid margins
      }
    }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
        </Grid>

        <Grid item xs={12}>
          <Autocomplete
            fullWidth
            freeSolo
            options={searchResults}
            loading={isSearching}
            inputValue={searchInput}
            onInputChange={(_, newValue) => {
              console.log('Input changed to:', newValue);
              setSearchInput(newValue);
              setFormData(prev => ({ ...prev, name: newValue }));
              
              const tempClientData: Client = {
                id: null,
                name: newValue,
                email: formData.email,
                phone: formData.phone,
                address: formData.address,
                city: formData.city,
                state: formData.state,
                zip_code: formData.zipCode,
                company: formData.company,
                notes: formData.notes
              };
              onClientSelect(tempClientData);
              
              searchAndCreateClient(newValue);
            }}
            onChange={(_, value) => {
              console.log('Selection changed to:', value);
              if (value && typeof value === 'object') {
                handleClientSelect(value as Client);
                setIsNewClient(false);
              } else {
                setIsNewClient(true);
              }
            }}
            getOptionLabel={(option) => {
              if (typeof option === 'string') return option;
              return option.name || '';
            }}
            renderOption={(props, option) => {
              if (typeof option === 'string') return <li {...props}>{option}</li>;
              return (
                <li {...props}>
                  <div style={{ padding: '4px 0' }}>
                    <div style={{ fontWeight: 'bold' }}>{option.name}</div>
                    {option.company && <div style={{ fontSize: '0.8em', color: '#666' }}>{option.company}</div>}
                    {option.email && <div style={{ fontSize: '0.8em', color: '#666' }}>{option.email}</div>}
                    {option.phone && <div style={{ fontSize: '0.8em', color: '#666' }}>{option.phone}</div>}
                    {option.address && <div style={{ fontSize: '0.8em', color: '#666' }}>{option.address}</div>}
                  </div>
                </li>
              );
            }}
            renderInput={(params) => (
              <div ref={params.InputProps.ref}>
                <input
                  {...params.inputProps}
                  type="text"
                  style={inputStyle}
                  placeholder="Search existing clients or enter new client name"
                />
              </div>
            )}
            ListboxProps={{
              sx: {
                maxHeight: '400px',
                '& .MuiAutocomplete-option': {
                  padding: '8px 16px',
                }
              }
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <input
            type="text"
            value={formData.company}
            onChange={(e) => handleFormChange('company', e.target.value)}
            placeholder="Company"
            style={inputStyle}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <input
            type="email"
            value={formData.email}
            onChange={(e) => handleFormChange('email', e.target.value)}
            placeholder="Email"
            style={inputStyle}
            required
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <input
            type="tel"
            value={formData.phone}
            onChange={(e) => {
              const formatted = formatPhoneNumber(e.target.value);
              handleFormChange('phone', formatted);
            }}
            placeholder="Phone"
            style={inputStyle}
            required
          />
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <input
              type="text"
              value={formData.address}
              onChange={(e) => handleFormChange('address', e.target.value)}
              placeholder="Street Address"
              style={inputStyle}
            />
          </Grid>
          
          <Grid item xs={12} md={4}>
            <input
              type="text"
              value={formData.city}
              onChange={(e) => handleFormChange('city', e.target.value)}
              placeholder="City"
              style={inputStyle}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <input
              type="text"
              value={formData.state}
              onChange={(e) => handleFormChange('state', e.target.value)}
              placeholder="State"
              style={inputStyle}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <input
              type="text"
              value={formData.zipCode}
              onChange={(e) => handleFormChange('zipCode', e.target.value)}
              placeholder="ZIP Code"
              style={inputStyle}
            />
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <textarea
            value={formData.notes}
            onChange={(e) => handleFormChange('notes', e.target.value)}
            placeholder="Notes"
            style={{
              ...inputStyle,
              minHeight: '45px',
              height: '45px',
              resize: 'vertical'
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default NewClientForm; 