import React from 'react';
import { 
  Box, 
  Paper, 
  Typography, 
  List, 
  ListItem, 
  ListItemText, 
  ListItemAvatar, 
  Avatar,
  Divider,
  IconButton,
  Chip
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EmailIcon from '@mui/icons-material/Email';

const ClientCommunicationsView = ({ onBack }) => {
  const data = [
    {
      date: '2024-03-01',
      time: '09:00 AM',
      client: 'ABC Corp',
      subject: 'Service Confirmation',
      message: 'Confirming your scheduled cleaning service for next week...',
      type: 'Automated',
      status: 'Sent'
    },
    // Add more sample data
  ];

  return (
    <Box>
      <Box sx={{ mb: 4, display: 'flex', alignItems: 'center' }}>
        <IconButton onClick={onBack} sx={{ mr: 2 }}>
          <ArrowBackIcon />
        </IconButton>
        <EmailIcon sx={{ mr: 2, color: '#34495e' }} />
        <Typography variant="h5">Client Communications</Typography>
      </Box>

      <Paper elevation={0} sx={{ p: 3, border: '1px solid #e0e0e0' }}>
        <List>
          {data.map((item, index) => (
            <React.Fragment key={index}>
              <ListItem 
                alignItems="flex-start"
                sx={{
                  '&:hover': {
                    backgroundColor: '#f5f5f5',
                  }
                }}
              >
                <ListItemAvatar>
                  <Avatar sx={{ bgcolor: 'primary.main' }}>
                    <EmailIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      <Typography variant="subtitle1">{item.subject}</Typography>
                      <Box>
                        <Chip 
                          label={item.type} 
                          size="small" 
                          sx={{ mr: 1 }} 
                        />
                        <Chip 
                          label={item.status} 
                          color="success" 
                          size="small" 
                        />
                      </Box>
                    </Box>
                  }
                  secondary={
                    <>
                      <Typography component="span" variant="body2" color="text.primary">
                        {item.client}
                      </Typography>
                      {` — ${item.message}`}
                      <Typography variant="caption" display="block" sx={{ mt: 1 }}>
                        {`${item.date} at ${item.time}`}
                      </Typography>
                    </>
                  }
                />
              </ListItem>
              {index < data.length - 1 && <Divider variant="inset" component="li" />}
            </React.Fragment>
          ))}
        </List>
      </Paper>
    </Box>
  );
};

export default ClientCommunicationsView; 