import React, { useState } from 'react';
import { 
  Grid, 
  Box,
  Tabs,
  Tab,
  Typography,
  Stack
} from '@mui/material';
import AssessmentIcon from '@mui/icons-material/Assessment';
import BarChartIcon from '@mui/icons-material/BarChart';
import PaymentsIcon from '@mui/icons-material/Payments';
import InsightsIcon from '@mui/icons-material/Insights';
import { useTheme } from '../ThemeContext';

// Import panels
import ChartsPanel from './reports/ChartsPanel';
import PayrollPanel from './leads/payroll/PayrollPanel';
import FinancialReports from './reports/financial/FinancialReports';
import WorkReports from './reports/work/WorkReports';
import ClientReports from './reports/client/ClientReports';

// Import payroll views
import RunPayrollView from './leads/payroll/views/RunPayrollView';
import PayrollHistoryView from './leads/payroll/views/PayrollHistoryView';
import EmployeeListView from './leads/payroll/views/EmployeeListView';
import TimeAttendanceView from './leads/payroll/views/TimeAttendanceView';
import TaxFormsView from './leads/payroll/views/TaxFormsView';
import ComplianceReportsView from './leads/payroll/views/ComplianceReportsView';
import PayrollSettingsView from './leads/payroll/views/PayrollSettingsView';
import DeductionsBenefitsView from './leads/payroll/views/DeductionsBenefitsView';

// Import financial views
import ProjectedIncomeView from './reports/financial/ProjectedIncomeView';
import TransactionListView from './reports/financial/TransactionListView';
import AgedReceivablesView from './reports/financial/AgedReceivablesView';
import BadDebtView from './reports/financial/BadDebtView';

// Import work views
import VisitsView from './reports/work/VisitsView';
import OneOffJobsView from './reports/work/OneOffJobsView';
import RecurringJobsView from './reports/work/RecurringJobsView';
import TimesheetsView from './reports/work/TimesheetsView';

// Import client views
import ClientCommunicationsView from './reports/client/ClientCommunicationsView';
import ClientContactView from './reports/client/ClientContactView';
import PropertyListView from './reports/client/PropertyListView';
import ClientReengagementView from './reports/client/ClientReengagementView';

// Import estimate views
import EstimateReports from './reports/estimate/EstimateReports';
import PendingEstimatesView from './reports/estimate/PendingEstimatesView';
import EstimateConversionView from './reports/estimate/EstimateConversionView';
import EstimateHistoryView from './reports/estimate/EstimateHistoryView';
import EstimateAnalyticsView from './reports/estimate/EstimateAnalyticsView';

const Payroll = () => {
  const [currentTab, setCurrentTab] = useState(0);
  const [currentView, setCurrentView] = useState(null);
  const { darkMode } = useTheme();

  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
    setCurrentView(null); // Reset view when changing tabs
  };

  const handleNavigate = (view) => {
    setCurrentView(view);
  };

  const handleBack = () => {
    setCurrentView(null);
  };

  const renderPayrollContent = () => {
    switch (currentView) {
      case 'run-payroll':
        return <RunPayrollView onBack={handleBack} />;
      case 'payroll-history':
        return <PayrollHistoryView onBack={handleBack} />;
      case 'employee-list':
        return <EmployeeListView onBack={handleBack} />;
      case 'time-attendance':
        return <TimeAttendanceView onBack={handleBack} />;
      case 'tax-forms':
        return <TaxFormsView onBack={handleBack} />;
      case 'compliance-reports':
        return <ComplianceReportsView onBack={handleBack} />;
      case 'payroll-settings':
        return <PayrollSettingsView onBack={handleBack} />;
      case 'deductions-benefits':
        return <DeductionsBenefitsView onBack={handleBack} />;
      default:
        return <PayrollPanel onNavigate={handleNavigate} />;
    }
  };

  const renderReportsContent = () => {
    switch (currentView) {
      // Financial Reports Views
      case 'projected-income':
        return <ProjectedIncomeView onBack={handleBack} />;
      case 'transactions':
        return <TransactionListView onBack={handleBack} />;
      case 'aged-receivables':
        return <AgedReceivablesView onBack={handleBack} />;
      case 'bad-debt':
        return <BadDebtView onBack={handleBack} />;

      // Work Reports Views
      case 'visits':
        return <VisitsView onBack={handleBack} />;
      case 'one-off-jobs':
        return <OneOffJobsView onBack={handleBack} />;
      case 'recurring-jobs':
        return <RecurringJobsView onBack={handleBack} />;
      case 'timesheets':
        return <TimesheetsView onBack={handleBack} />;

      // Client Reports Views
      case 'client-communications':
        return <ClientCommunicationsView onBack={handleBack} />;
      case 'client-contact':
        return <ClientContactView onBack={handleBack} />;
      case 'property-list':
        return <PropertyListView onBack={handleBack} />;
      case 'client-reengagement':
        return <ClientReengagementView onBack={handleBack} />;

      // Estimate Reports Views
      case 'pending-estimates':
        return <PendingEstimatesView onBack={handleBack} />;
      case 'estimate-conversion':
        return <EstimateConversionView onBack={handleBack} />;
      case 'estimate-history':
        return <EstimateHistoryView onBack={handleBack} />;
      case 'estimate-analytics':
        return <EstimateAnalyticsView onBack={handleBack} />;

      default:
        return (
          <Grid container spacing={4}>
            <Grid item xs={12} md={3}>
              <FinancialReports onNavigate={handleNavigate} />
            </Grid>
            <Grid item xs={12} md={3}>
              <WorkReports onNavigate={handleNavigate} />
            </Grid>
            <Grid item xs={12} md={3}>
              <ClientReports onNavigate={handleNavigate} />
            </Grid>
            <Grid item xs={12} md={3}>
              <EstimateReports onNavigate={handleNavigate} />
            </Grid>
          </Grid>
        );
    }
  };

  const renderContent = () => {
    switch (currentTab) {
      case 0: // Reports tab
        return renderReportsContent();
      case 1: // Charts tab
        return <ChartsPanel />;
      case 2: // Payroll tab
        return renderPayrollContent();
      default:
        return null;
    }
  };

  return (
    <Box sx={{ 
      p: 4, 
      backgroundColor: darkMode ? 'var(--background-default)' : '#f5f5f5', 
      minHeight: '100vh',
      color: darkMode ? 'var(--text-primary)' : 'inherit'
    }}>
      <Stack 
        direction="row" 
        alignItems="center" 
        spacing={2} 
        sx={{ mb: 4 }}
      >
        <InsightsIcon 
          sx={{ 
            fontSize: 32,
            color: darkMode ? 'var(--primary-main)' : 'primary.main'
          }} 
        />
        <Typography 
          variant="h4" 
          sx={{ 
            fontWeight: 600,
            color: darkMode ? 'var(--text-primary)' : 'inherit'
          }}
        >
          Reports
        </Typography>
      </Stack>

      <Box sx={{ 
        borderBottom: 1, 
        borderColor: darkMode ? 'var(--border-color)' : 'divider', 
        mb: 4 
      }}>
        <Tabs 
          value={currentTab} 
          onChange={handleTabChange}
          sx={{
            '& .MuiTab-root': {
              textTransform: 'none',
              fontSize: '1rem',
              fontWeight: 500,
              minHeight: 48,
              color: darkMode ? 'var(--text-secondary)' : 'text.secondary',
              '&.Mui-selected': {
                color: darkMode ? 'var(--primary-main)' : 'primary.main'
              }
            },
            '& .MuiTabs-indicator': {
              backgroundColor: darkMode ? 'var(--primary-main)' : 'primary.main'
            }
          }}
        >
          <Tab 
            icon={<AssessmentIcon />} 
            iconPosition="start" 
            label="Reports" 
          />
          <Tab 
            icon={<BarChartIcon />} 
            iconPosition="start" 
            label="Charts" 
          />
          <Tab 
            icon={<PaymentsIcon />} 
            iconPosition="start" 
            label="Payroll" 
          />
        </Tabs>
      </Box>

      <Box sx={{
        '& .MuiPaper-root': {
          backgroundColor: darkMode ? 'var(--background-paper)' : '#ffffff',
          borderRadius: 2,
          border: darkMode ? '1px solid var(--border-color)' : 'none',
          boxShadow: darkMode 
            ? '0 4px 20px rgba(0,0,0,0.3)' 
            : '0 4px 20px rgba(0,0,0,0.05)',
          transition: 'all 0.3s ease',
          '&:hover': {
            boxShadow: darkMode 
              ? '0 4px 25px rgba(0,0,0,0.4)' 
              : '0 4px 25px rgba(0,0,0,0.1)',
          }
        },
        '& .MuiTableCell-root': {
          borderColor: darkMode ? 'var(--border-color)' : 'inherit',
          color: darkMode ? 'var(--text-primary)' : '#f4f4f4'
        },
        '& .MuiTableHead-root .MuiTableCell-root': {
          backgroundColor: darkMode ? 'var(--background-paper)' : '#f5f5f5',
          color: darkMode ? 'var(--text-primary)' : 'inherit'
        },
        '& .MuiTableRow-root:hover': {
          backgroundColor: darkMode ? 'var(--background-hover)' : 'rgba(0, 0, 0, 0.04)'
        },
        '& .MuiChip-root': {
          backgroundColor: darkMode ? 'var(--background-paper)' : undefined,
          borderColor: darkMode ? 'var(--border-color)' : undefined
        }
      }}>
        {renderContent()}
      </Box>
    </Box>
  );
};

export default Payroll;
