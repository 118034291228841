import React, { useState, useEffect } from 'react';
import { 
  Box, Button, Typography, CircularProgress, Menu, MenuItem, 
  ListItemIcon, ListItemText, Snackbar, Alert, Paper, Grid,
  IconButton, Divider, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions,
  FormControl,
  InputLabel,
  Select,
  TextField,
  InputAdornment,
} from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  ArrowBack as ArrowBackIcon,
  AccessTime as AccessTimeIcon,
  Person as PersonIcon,
  Work as WorkIcon,
  Event as EventIcon,
  AttachMoney as AttachMoneyIcon,
  HourglassEmpty as HourglassEmptyIcon
} from '@mui/icons-material';
import { getTimesheet, deleteTimesheet, updateTimesheet } from '../services/api';

const TimesheetDetails = () => {
  console.log('TimesheetDetails component rendering');
  const { id } = useParams();
  const navigate = useNavigate();
  
  // Add this debug log
  console.log('Current URL:', window.location.pathname);
  console.log('Timesheet ID from params:', id);

  const [timesheet, setTimesheet] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [anchorEl, setAnchorEl] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [editForm, setEditForm] = useState({
    payment_type: '',
    rate: '',
    calculated_payment: '',
  });

  useEffect(() => {
    if (timesheet) {
      setEditForm({
        payment_type: timesheet.payment_type || '',
        rate: timesheet.rate || '',
        calculated_payment: timesheet.calculated_payment || '',
      });
    }
  }, [timesheet]);

  const handleDeleteClick = () => {
    setDeleteDialogOpen(true);
  };

  const handleEditClick = () => {
    setEditDialogOpen(true);
  };

  const handleDelete = async () => {
    try {
      setLoading(true);
      await deleteTimesheet(id);
      setSnackbar({
        open: true,
        message: 'Timesheet deleted successfully',
        severity: 'success'
      });
      setDeleteDialogOpen(false);
      setTimeout(() => {
        navigate('/admin/timesheets');
      }, 1500);
    } catch (err) {
      console.error('Error deleting timesheet:', err);
      setSnackbar({
        open: true,
        message: err.response?.data?.error || 'Failed to delete timesheet',
        severity: 'error'
      });
    } finally {
      setLoading(false);
    }
  };

  const handleEditSubmit = async () => {
    try {
      setLoading(true);
      const updatedData = await updateTimesheet(id, {
        payment_type: editForm.payment_type,
        rate: editForm.rate ? parseFloat(editForm.rate) : null,
        calculated_payment: editForm.calculated_payment ? parseFloat(editForm.calculated_payment) : null,
      });
      
      // Update the local timesheet data with the response
      setTimesheet(prev => ({
        ...prev,
        payment_type: updatedData.timesheet.payment_type,
        rate: updatedData.timesheet.rate,
        calculated_payment: updatedData.timesheet.calculated_payment,
      }));
      
      setSnackbar({
        open: true,
        message: 'Timesheet payment updated successfully',
        severity: 'success'
      });
      setEditDialogOpen(false);
    } catch (err) {
      console.error('Error updating timesheet:', err);
      setSnackbar({
        open: true,
        message: err.response?.data?.error || 'Failed to update timesheet',
        severity: 'error'
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    console.log('TimesheetDetails useEffect running with ID:', id);
    const fetchTimesheet = async () => {
      if (!id) {
        console.error('No timesheet ID provided');
        return;
      }

      try {
        setLoading(true);
        console.log('Fetching timesheet data for ID:', id);
        const data = await getTimesheet(id);
        console.log('Received timesheet data:', data);
        setTimesheet(data);
      } catch (err) {
        console.error('Error fetching timesheet:', err);
        setError(err.message || 'Failed to load timesheet');
      } finally {
        setLoading(false);
      }
    };

    fetchTimesheet();
  }, [id]);

  // Add this debug log before render
  console.log('Rendering TimesheetDetails with state:', { timesheet, loading, error, id });

  // Format description for better display
  const formatDescription = (description) => {
    return description.split('\n').map((line, index) => (
      <Typography key={index} paragraph>
        {line}
      </Typography>
    ));
  };

  // Add payment type options
  const paymentTypes = [
    { value: 'hourly', label: 'Hourly' },
    { value: 'fixed', label: 'Fixed Rate' },
    { value: 'percentage', label: 'Percentage' },
  ];

  // Add custom styles
  const styles = {
    mainContainer: {
      p: 4,
      maxWidth: '1400px',
      margin: '0 auto'
    },
    mainPaper: {
      p: 4,
      borderRadius: 2,
      boxShadow: '0 2px 8px rgba(0,0,0,0.1)'
    },
    headerBox: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      mb: 3
    },
    headerTitle: {
      fontWeight: 600,
      color: '#2c3e50'
    },
    actionButton: {
      borderRadius: 2,
      textTransform: 'none',
      px: 2
    },
    infoCard: {
      p: 3,
      height: '100%',
      borderRadius: 2,
      boxShadow: '0 2px 4px rgba(0,0,0,0.05)',
      transition: 'transform 0.2s ease, box-shadow 0.2s ease',
      '&:hover': {
        transform: 'translateY(-2px)',
        boxShadow: '0 4px 8px rgba(0,0,0,0.1)'
      }
    },
    cardHeader: {
      display: 'flex',
      alignItems: 'center',
      gap: 1.5,
      mb: 2,
      pb: 1,
      borderBottom: '1px solid #eee'
    },
    cardTitle: {
      fontWeight: 500,
      color: '#2c3e50'
    },
    cardContent: {
      ml: 2
    },
    detailRow: {
      display: 'flex',
      gap: 1,
      mb: 1,
      '& strong': {
        color: '#34495e',
        minWidth: '140px'
      }
    },
    editDialog: {
      '& .MuiDialog-paper': {
        borderRadius: 2,
        maxWidth: '800px'
      }
    },
    dialogTitle: {
      borderBottom: '1px solid #eee',
      mb: 2
    },
    dialogContent: {
      p: 3
    },
    dialogActions: {
      px: 3,
      py: 2,
      borderTop: '1px solid #eee'
    }
  };

  if (loading) return (
    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "50vh" }}>
      <CircularProgress />
    </Box>
  );
  
  if (error) return (
    <Box sx={styles.mainContainer}>
      <Alert severity="error" sx={{ borderRadius: 2 }}>{error}</Alert>
    </Box>
  );
  
  if (!timesheet) return (
    <Box sx={styles.mainContainer}>
      <Alert severity="info" sx={{ borderRadius: 2 }}>Timesheet not found</Alert>
    </Box>
  );

  return (
    <Box sx={styles.mainContainer}>
      <Paper sx={styles.mainPaper}>
        <Grid container spacing={3}>
          {/* Header with Actions */}
          <Grid item xs={12}>
            <Box sx={styles.headerBox}>
              <Typography variant="h5" sx={styles.headerTitle}>
                Timesheet Details
              </Typography>
              <Box>
                <Button
                  startIcon={<ArrowBackIcon />}
                  onClick={() => navigate('/admin/timesheets')}
                  variant="outlined"
                  sx={{ ...styles.actionButton, mr: 1 }}
                >
                  Back
                </Button>
                <Button
                  startIcon={<EditIcon />}
                  onClick={handleEditClick}
                  variant="outlined"
                  color="primary"
                  sx={{ ...styles.actionButton, mr: 1 }}
                >
                  Edit
                </Button>
                <Button
                  startIcon={<DeleteIcon />}
                  onClick={handleDeleteClick}
                  variant="outlined"
                  color="error"
                  disabled={loading}
                  sx={styles.actionButton}
                >
                  {loading ? 'Deleting...' : 'Delete'}
                </Button>
              </Box>
            </Box>
            <Divider sx={{ mb: 3 }} />
          </Grid>

          {/* Employee Info Card */}
          <Grid item xs={12} md={6}>
            <Paper sx={styles.infoCard}>
              <Box sx={styles.cardHeader}>
                <PersonIcon color="primary" />
                <Typography variant="h6" sx={styles.cardTitle}>
                  Employee Details
                </Typography>
              </Box>
              <Box sx={styles.cardContent}>
                <Box sx={styles.detailRow}>
                  <strong>Name:</strong>
                  <Typography>{timesheet.employee.name}</Typography>
                </Box>
                <Box sx={styles.detailRow}>
                  <strong>Email:</strong>
                  <Typography>{timesheet.employee.email}</Typography>
                </Box>
                <Box sx={styles.detailRow}>
                  <strong>ID:</strong>
                  <Typography>{timesheet.employee.id}</Typography>
                </Box>
              </Box>
            </Paper>
          </Grid>

          {/* Time Info Card */}
          <Grid item xs={12} md={6}>
            <Paper sx={styles.infoCard}>
              <Box sx={styles.cardHeader}>
                <AccessTimeIcon color="primary" />
                <Typography variant="h6" sx={styles.cardTitle}>
                  Time Details
                </Typography>
              </Box>
              <Box sx={styles.cardContent}>
                <Box sx={styles.detailRow}>
                  <strong>Clock In:</strong>
                  <Typography>{new Date(timesheet.clock_in).toLocaleString()}</Typography>
                </Box>
                <Box sx={styles.detailRow}>
                  <strong>Clock Out:</strong>
                  <Typography>
                    {timesheet.clock_out ? new Date(timesheet.clock_out).toLocaleString() : 'Not clocked out'}
                  </Typography>
                </Box>
                <Box sx={styles.detailRow}>
                  <strong>Hours Worked:</strong>
                  <Typography>{timesheet.hours_worked?.toFixed(2) || 'N/A'}</Typography>
                </Box>
              </Box>
            </Paper>
          </Grid>

          {/* Payment Info Card */}
          <Grid item xs={12} md={6}>
            <Paper sx={styles.infoCard}>
              <Box sx={styles.cardHeader}>
                <AttachMoneyIcon color="primary" />
                <Typography variant="h6" sx={styles.cardTitle}>
                  Payment Details
                </Typography>
              </Box>
              <Box sx={styles.cardContent}>
                <Box sx={styles.detailRow}>
                  <strong>Payment Type:</strong>
                  <Typography>{timesheet.payment_type || 'Not set'}</Typography>
                </Box>
                <Box sx={styles.detailRow}>
                  <strong>Rate:</strong>
                  <Typography>{timesheet.rate ? `$${timesheet.rate}` : 'Not set'}</Typography>
                </Box>
                <Box sx={styles.detailRow}>
                  <strong>Calculated Payment:</strong>
                  <Typography>${timesheet.calculated_payment?.toFixed(2) || '0.00'}</Typography>
                </Box>
              </Box>
            </Paper>
          </Grid>

          {/* Event Info Card */}
          <Grid item xs={12}>
            <Paper elevation={2} sx={{ p: 2 }}>
              <Box display="flex" alignItems="center" gap={1} mb={2}>
                <EventIcon color="primary" />
                <Typography variant="h6">Event Details</Typography>
              </Box>
              <Box ml={4}>
                <Typography><strong>Title:</strong> {timesheet.event.title}</Typography>
                <Typography><strong>Project ID:</strong> {timesheet.event.project_id}</Typography>
                <Typography><strong>Event ID:</strong> {timesheet.event.id}</Typography>
                <Box mt={2}>
                  <Typography variant="subtitle1"><strong>Description:</strong></Typography>
                  <Box ml={2} mt={1}>
                    {formatDescription(timesheet.event.description)}
                  </Box>
                </Box>
              </Box>
            </Paper>
          </Grid>
        </Grid>

        {/* Edit Dialog with improved styling */}
        <Dialog
          open={editDialogOpen}
          onClose={() => setEditDialogOpen(false)}
          maxWidth="md"
          fullWidth
          sx={styles.editDialog}
        >
          <DialogTitle sx={styles.dialogTitle}>
            Edit Payment Details
          </DialogTitle>
          <DialogContent sx={styles.dialogContent}>
            <Grid container spacing={2} alignItems="center">
              {/* Payment Type Dropdown */}
              <Grid item xs={12} md={3}>
                <FormControl fullWidth>
                  <InputLabel>Payment Type</InputLabel>
                  <Select
                    value={editForm.payment_type || ''}
                    onChange={(e) => setEditForm({ 
                      ...editForm, 
                      payment_type: e.target.value 
                    })}
                    label="Payment Type"
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {paymentTypes.map((type) => (
                      <MenuItem key={type.value} value={type.value}>
                        {type.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              {/* Rate */}
              <Grid item xs={12} md={3}>
                <TextField
                  fullWidth
                  label="Rate"
                  type="number"
                  value={editForm.rate || ''}
                  onChange={(e) => setEditForm({ ...editForm, rate: e.target.value })}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  }}
                />
              </Grid>

              {/* Hours Worked (Read-only) */}
              <Grid item xs={12} md={3}>
                <TextField
                  fullWidth
                  label="Hours Worked"
                  value={timesheet?.hours_worked?.toFixed(2) || '0.00'}
                  InputProps={{ readOnly: true }}
                />
              </Grid>

              {/* Calculated Payment */}
              <Grid item xs={12} md={3}>
                <TextField
                  fullWidth
                  label="Calculated Payment"
                  type="number"
                  value={editForm.calculated_payment || ''}
                  onChange={(e) => setEditForm({ 
                    ...editForm, 
                    calculated_payment: e.target.value 
                  })}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  }}
                />
              </Grid>

              {/* Additional Info (Read-only) */}
              <Grid item xs={12}>
                <Typography variant="subtitle2" color="textSecondary" sx={{ mt: 2 }}>
                  Employee: {timesheet?.employee?.name} | 
                  Clock In: {new Date(timesheet?.clock_in).toLocaleString()} | 
                  Clock Out: {timesheet?.clock_out ? new Date(timesheet?.clock_out).toLocaleString() : 'Not clocked out'}
                </Typography>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions sx={styles.dialogActions}>
            <Button 
              onClick={() => setEditDialogOpen(false)}
              sx={styles.actionButton}
            >
              Cancel
            </Button>
            <Button 
              onClick={handleEditSubmit}
              variant="contained"
              color="primary"
              disabled={loading}
              sx={styles.actionButton}
            >
              {loading ? 'Saving...' : 'Save Changes'}
            </Button>
          </DialogActions>
        </Dialog>

        {/* Snackbar with improved styling */}
        <Snackbar
          open={snackbar.open}
          autoHideDuration={6000}
          onClose={() => setSnackbar(prev => ({ ...prev, open: false }))}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        >
          <Alert 
            severity={snackbar.severity} 
            onClose={() => setSnackbar(prev => ({ ...prev, open: false }))}
            sx={{ borderRadius: 2 }}
          >
            {snackbar.message}
          </Alert>
        </Snackbar>
      </Paper>
    </Box>
  );
};

export default TimesheetDetails; 