import React from 'react';
import {
  Box,
  Card,
  Grid,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';
import {
  Download as DownloadIcon,
  TrendingUp as TrendingUpIcon,
  TrendingDown as TrendingDownIcon,
} from '@mui/icons-material';
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';

const StatCard = ({ title, value, change, changeType }) => (
  <Card sx={{ p: 3, borderRadius: 2 }}>
    <Typography color="text.secondary" variant="overline">
      {title}
    </Typography>
    <Typography variant="h4" sx={{ my: 1, fontWeight: 600 }}>
      {value}
    </Typography>
    <Box sx={{ 
      display: 'flex', 
      alignItems: 'center', 
      gap: 1,
      color: changeType === 'increase' ? 'success.main' : 'error.main'
    }}>
      {changeType === 'increase' ? 
        <TrendingUpIcon fontSize="small" /> : 
        <TrendingDownIcon fontSize="small" />
      }
      <Typography variant="body2">
        {change}% from last month
      </Typography>
    </Box>
  </Card>
);

const CampaignReports = () => {
  // Mock data - replace with API calls
  const performanceData = [
    { date: '2024-03-01', campaigns: 45, engagement: 68, conversion: 24 },
    { date: '2024-03-02', campaigns: 52, engagement: 72, conversion: 28 },
    // Add more data points...
  ];

  const recentCampaigns = [
    {
      id: 1,
      name: 'Spring Sale 2024',
      type: 'Email',
      sent: 15000,
      opened: 8250,
      converted: 1875,
      revenue: '$12,450',
    },
    // Add more campaigns...
  ];

  return (
    <Box sx={{ p: 3 }}>
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'space-between',
        alignItems: 'center',
        mb: 4
      }}>
        <Typography variant="h5" sx={{ fontWeight: 600 }}>
          Campaign Reports
        </Typography>
        <Button
          variant="contained"
          startIcon={<DownloadIcon />}
          sx={{
            borderRadius: 2,
            textTransform: 'none',
            background: 'linear-gradient(45deg, #2196f3 30%, #21cbf3 90%)',
          }}
        >
          Download Reports
        </Button>
      </Box>

      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <StatCard
            title="Total Campaigns"
            value={performanceData.length}
            change={0}
            changeType="increase"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <StatCard
            title="Total Engagement"
            value={performanceData.reduce((total, data) => total + data.engagement, 0)}
            change={0}
            changeType="increase"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <StatCard
            title="Total Conversion"
            value={performanceData.reduce((total, data) => total + data.conversion, 0)}
            change={0}
            changeType="increase"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <StatCard
            title="Total Revenue"
            value={recentCampaigns.reduce((total, campaign) => total + campaign.revenue, 0)}
            change={0}
            changeType="increase"
          />
        </Grid>
      </Grid>

      <Box sx={{ mt: 4 }}>
        <Typography variant="h6" sx={{ mb: 2 }}>
          Recent Campaigns
        </Typography>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>Sent</TableCell>
                <TableCell>Opened</TableCell>
                <TableCell>Converted</TableCell>
                <TableCell>Revenue</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {recentCampaigns.map((campaign) => (
                <TableRow key={campaign.id}>
                  <TableCell>{campaign.name}</TableCell>
                  <TableCell>{campaign.type}</TableCell>
                  <TableCell>{campaign.sent}</TableCell>
                  <TableCell>{campaign.opened}</TableCell>
                  <TableCell>{campaign.converted}</TableCell>
                  <TableCell>{campaign.revenue}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

export default CampaignReports; 