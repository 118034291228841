import React, { useState, useEffect } from 'react';
import { 
  Box, Dialog, DialogTitle, DialogContent, DialogActions, 
  Button, IconButton 
} from '@mui/material';
import ActiveItemsList from './items/ActiveItemsList';
import SavedItemsModal from './items/SavedItemsModal';
import { createItem, getItems, deleteItem } from '../services/api';
import TotalSection from './items/TotalSection';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CloseIcon from '@mui/icons-material/Close';

const InvoiceItemsManager = ({ 
  onAddToInvoice, 
  savedItems, 
  setSavedItems, 
  invoiceItems, 
  onRemoveItem 
}) => {
  const [unsavedItems, setUnsavedItems] = useState([]);
  const [isManageModalOpen, setIsManageModalOpen] = useState(false);
  const [itemToEdit, setItemToEdit] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  useEffect(() => {
    const fetchItems = async () => {
      try {
        const fetchedItems = await getItems();
        setSavedItems(fetchedItems);
      } catch (error) {
        console.error('Error fetching items:', error);
      }
    };
    fetchItems();
  }, [setSavedItems]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (!invoiceItems || invoiceItems.length === 0) {
        handleAddItem();
      }
    }, 100);
    
    return () => clearTimeout(timer);
  }, [invoiceItems]);

  const handleEditItem = (index, field, value) => {
    const updatedItem = {
      ...invoiceItems[index],
      [field]: value,
      isNew: true,
      fromAutocomplete: false,
      selected: false
    };
    
    if (updatedItem.id) {
      updatedItem.isNew = true;
      updatedItem.fromAutocomplete = false;
      updatedItem.selected = false;
      delete updatedItem.id;
    }
    
    onAddToInvoice(updatedItem, index);
  };

  const handleAddItem = () => {
    const newItem = {
      title: '',
      description: '',
      quantity: '',
      unitPrice: '',
      isNew: true
    };
    onAddToInvoice(newItem);
    setUnsavedItems(prev => [...prev, invoiceItems.length]);
  };

  const handleAutocompleteSelect = (index, selectedItem) => {
    const updatedItem = {
      ...selectedItem,
      title: selectedItem.title || '',
      description: selectedItem.description || '',
      unitPrice: selectedItem.unitPrice || 0,
      quantity: selectedItem.quantity || 1,
      tax: selectedItem.tax || 0,
      isNew: false,
      fromAutocomplete: true,
      selected: true,
      id: selectedItem.id
    };
    
    onAddToInvoice(updatedItem, index);
  };

  const handleDirectSave = async (index) => {
    try {
      const itemToSave = invoiceItems[index];
      
      if (!itemToSave.title || !itemToSave.unitPrice) {
        alert('Title and price are required');
        return;
      }

      const itemData = {
        title: itemToSave.title,
        description: itemToSave.description || '',
        unitPrice: Number(itemToSave.unitPrice) || 0,
        quantity: Number(itemToSave.quantity) || 1
      };

      const savedItem = await createItem(itemData);

      if (savedItem) {
        setSavedItems(prev => [...prev, savedItem]);
        onAddToInvoice({ ...itemToSave, isNew: false }, index);
        setShowSuccessModal(true);
      }
    } catch (error) {
      console.error('Error saving item:', error);
      alert('Failed to save item: ' + error.message);
    }
  };

  return (
    <Box sx={{ p: 3 }}>
      <Box sx={{ 
        display: 'flex', 
        gap: 2, 
        mb: 3,
        px: 3,
        py: 2,
        borderBottom: '2px solid #e0e0e0',
        '& > div': {
          color: '#1a2027',
          fontWeight: 600,
          fontSize: '1.1rem',
          textTransform: 'uppercase',
          letterSpacing: '0.8px'
        }
      }}>
        <div style={{ flex: '1 1 50%' }}>Title/Description</div>
        <div style={{ width: '100px', textAlign: 'right', paddingRight: '14px', marginRight: '70px' }}>Qty</div>
        <div style={{ width: '100px', textAlign: 'right', paddingRight: '14px', marginRight: '60px' }}>Price</div>
        <div style={{ width: '100px', textAlign: 'right', paddingRight: '14px', marginRight: '40px' }}>Total</div>
      </Box>

      <ActiveItemsList
        items={invoiceItems}
        onEdit={handleEditItem}
        onAddItem={handleAddItem}
        onDelete={onRemoveItem}
        onSaveItem={handleDirectSave}
        savedItems={savedItems}
        onAutocompleteSelect={handleAutocompleteSelect}
        setIsManageModalOpen={setIsManageModalOpen}
      />

      <TotalSection items={invoiceItems} />

      <Dialog 
        open={showSuccessModal} 
        onClose={() => setShowSuccessModal(false)}
        maxWidth="xs"
        fullWidth
      >
        <Box sx={{ position: 'relative' }}>
          <IconButton
            onClick={() => setShowSuccessModal(false)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: 'white',
              '&:hover': {
                backgroundColor: 'rgba(255, 255, 255, 0.1)'
              }
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogTitle sx={{ 
            backgroundColor: '#4caf50', 
            color: 'white',
            textAlign: 'center',
            pb: 2
          }}>
            <Box sx={{ 
              display: 'flex', 
              flexDirection: 'column', 
              alignItems: 'center',
              gap: 1
            }}>
              <CheckCircleOutlineIcon sx={{ fontSize: '48px' }} />
              Success
            </Box>
          </DialogTitle>
          <DialogContent sx={{ 
            mt: 2, 
            textAlign: 'center',
            color: '#666'
          }}>
            Item has been saved successfully.
          </DialogContent>
        </Box>
      </Dialog>
    </Box>
  );
};

export default InvoiceItemsManager; 