import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Box,
  Paper,
  Typography,
  Button,
  Grid,
  Chip,
  CircularProgress,
  Alert
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const AdminLeadDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [lead, setLead] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // TODO: Implement API call to fetch lead details
    setLoading(false);
    setLead({
      id: 1,
      name: 'John Doe',
      email: 'john@example.com',
      phone: '(555) 123-4567',
      source: 'Website',
      status: 'New',
      value: 5000,
      notes: 'Interested in full service package',
      date: '2024-03-15'
    });
  }, [id]);

  if (loading) return <CircularProgress />;
  if (error) return <Alert severity="error">{error}</Alert>;
  if (!lead) return <Alert severity="info">No lead found</Alert>;

  return (
    <Box sx={{ p: 3 }}>
      <Button
        startIcon={<ArrowBackIcon />}
        onClick={() => navigate('/admin/leads')}
        sx={{ mb: 3 }}
      >
        Back to Leads
      </Button>

      <Paper sx={{ p: 3, borderRadius: 2 }}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Paper sx={{ 
              p: 3, 
              height: '100%',
              borderRadius: 2,
              boxShadow: '0 2px 12px rgba(0,0,0,0.08)'
            }}>
              <Typography variant="h6" sx={{ 
                mb: 3,
                fontFamily: 'Urbanist, sans-serif',
                color: '#1976d2',
                borderBottom: '2px solid #e3f2fd',
                pb: 1
              }}>
                Contact Information
              </Typography>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                <Box>
                  <Typography variant="body2" color="text.secondary">Name</Typography>
                  <Typography sx={{ fontFamily: 'Urbanist, sans-serif', fontWeight: 500 }}>
                    {lead.name}
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="body2" color="text.secondary">Email</Typography>
                  <Typography sx={{ fontFamily: 'Urbanist, sans-serif', fontWeight: 500 }}>
                    {lead.email}
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="body2" color="text.secondary">Phone</Typography>
                  <Typography sx={{ fontFamily: 'Urbanist, sans-serif', fontWeight: 500 }}>
                    {lead.phone}
                  </Typography>
                </Box>
              </Box>
            </Paper>
          </Grid>
          {/* Lead information */}
        </Grid>
      </Paper>
    </Box>
  );
};

export default AdminLeadDetails; 