import React, { useState } from 'react';
import {
  Box,
  Grid,
  Card,
  Typography,
  IconButton,
  Button,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  LinearProgress,
} from '@mui/material';
import {
  CloudUpload as UploadIcon,
  MoreVert as MoreIcon,
  Delete as DeleteIcon,
  Download as DownloadIcon,
  Edit as EditIcon,
  Image as ImageIcon,
  Description as DocumentIcon,
  VideoLibrary as VideoIcon,
} from '@mui/icons-material';

const AssetCard = ({ asset, onMenuClick }) => {
  const getIcon = (type) => {
    switch (type) {
      case 'image':
        return <ImageIcon />;
      case 'document':
        return <DocumentIcon />;
      case 'video':
        return <VideoIcon />;
      default:
        return <DocumentIcon />;
    }
  };

  return (
    <Card sx={{ 
      p: 2,
      borderRadius: 2,
      transition: 'transform 0.2s ease-in-out',
      '&:hover': {
        transform: 'translateY(-4px)',
        boxShadow: (theme) => theme.shadows[4],
      }
    }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
        {getIcon(asset.type)}
        <IconButton size="small" onClick={(e) => onMenuClick(e, asset)}>
          <MoreIcon />
        </IconButton>
      </Box>
      <Typography variant="subtitle1" sx={{ mb: 1 }}>
        {asset.name}
      </Typography>
      <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
        {asset.size}
      </Typography>
      <Box sx={{ mt: 2 }}>
        <Typography variant="caption" color="text.secondary">
          Usage in campaigns
        </Typography>
        <LinearProgress 
          variant="determinate" 
          value={asset.usage} 
          sx={{
            mt: 1,
            height: 6,
            borderRadius: 3,
            backgroundColor: 'grey.100',
            '& .MuiLinearProgress-bar': {
              borderRadius: 3,
              backgroundImage: 'linear-gradient(45deg, #2196f3 30%, #21cbf3 90%)',
            }
          }}
        />
      </Box>
    </Card>
  );
};

const CampaignAssets = () => {
  const [menuAnchor, setMenuAnchor] = useState(null);
  const [selectedAsset, setSelectedAsset] = useState(null);

  const assets = [
    {
      id: 1,
      name: 'Product Catalog.pdf',
      type: 'document',
      size: '2.4 MB',
      usage: 75,
    },
    {
      id: 2,
      name: 'Banner Image.jpg',
      type: 'image',
      size: '850 KB',
      usage: 45,
    },
    {
      id: 3,
      name: 'Promo Video.mp4',
      type: 'video',
      size: '12.8 MB',
      usage: 30,
    },
  ];

  const handleMenuClick = (event, asset) => {
    setMenuAnchor(event.currentTarget);
    setSelectedAsset(asset);
  };

  const handleMenuClose = () => {
    setMenuAnchor(null);
    setSelectedAsset(null);
  };

  return (
    <Box sx={{ p: 3 }}>
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'space-between',
        alignItems: 'center',
        mb: 4
      }}>
        <Typography variant="h5" sx={{ fontWeight: 600 }}>
          Campaign Assets
        </Typography>
        <Button
          variant="contained"
          startIcon={<UploadIcon />}
          sx={{
            borderRadius: 2,
            textTransform: 'none',
            background: 'linear-gradient(45deg, #2196f3 30%, #21cbf3 90%)',
          }}
        >
          Upload Asset
        </Button>
      </Box>

      <Grid container spacing={3}>
        {assets.map((asset) => (
          <Grid item xs={12} sm={6} md={4} key={asset.id}>
            <AssetCard
              asset={asset}
              onMenuClick={handleMenuClick}
            />
          </Grid>
        ))}
      </Grid>

      <Menu
        anchorEl={menuAnchor}
        open={Boolean(menuAnchor)}
        onClose={handleMenuClose}
        PaperProps={{
          sx: { width: 200, borderRadius: 2 }
        }}
      >
        <MenuItem onClick={handleMenuClose}>
          <ListItemIcon>
            <DownloadIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Download</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleMenuClose}>
          <ListItemIcon>
            <EditIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Rename</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleMenuClose} sx={{ color: 'error.main' }}>
          <ListItemIcon>
            <DeleteIcon fontSize="small" color="error" />
          </ListItemIcon>
          <ListItemText>Delete</ListItemText>
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default CampaignAssets; 