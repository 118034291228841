import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Typography,
  Button,
  Grid,
  Paper,
  Box,
  Container,
  CircularProgress,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  FormControlLabel,
  Checkbox,
  MenuItem,
  Snackbar,
  Alert,
  Switch,
  Stepper,
  Step,
  StepLabel
} from '@mui/material';
import ClientEstimateForm from './estimate/ClientEstimateForm.tsx';
import SavedItemsManager from './SavedItemsManager';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import DeleteIcon from '@mui/icons-material/Delete';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { createEstimate, createClient, uploadProjectPhoto, getItems, getTermsTemplates, getBusinessDetails, uploadEstimatePhoto } from '../services/api';
import styles from './AdminCreateEstimate.module.scss';
import EstimateReview from './estimate/EstimateReview.tsx';
import PropertyDetails from './PropertyDetails';
import { keyframes } from '@mui/system';

const API_BASE_URL = process.env.REACT_APP_API_URL;
const PROJECT_ID = 8008135;

const checkmarkAnimation = keyframes`
  0% {
    transform: scale(0);
    opacity: 0;
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
`;

// Move generateEstimateNumber outside useEffect
const generateEstimateNumber = () => {
  const date = new Date();
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const random = Math.floor(Math.random() * 1000).toString().padStart(3, '0');
  return `EST-${year}${month}${day}-${random}`;
};

// Update the initial state to include a generated estimate number
const initialEstimateState = {
  estimate_number: generateEstimateNumber(),  // Set initial estimate number
  project_name: '',
  issue_date: new Date().toISOString().split('T')[0],
  expiration_date: new Date(new Date().setDate(new Date().getDate() + 30)).toISOString().split('T')[0],
  items: [],
  scope_of_work: '',
  notes: '',
  terms: '',
  template: 'default',
  project_images: [],
  use_client_address: true,
  service_address: '',
  service_city: '',
  service_state: '',
  service_zip: '',
  customer_details: {
    name: '',
    company: '',
    email: '',
    phone: '',
    address: '',
    city: '',
    state: '',
    zip: '',
    notes: '',
    isNewClient: true
  },
  business_details: {
    name: '',
    address: '',
    city: '',
    state: '',
    zip: '',
    phone: '',
    email: ''
  },
  internal_notes: '',  // Add this line
};

// Add common styles
const commonInputStyles = {
  '& .MuiOutlinedInput-root': {
    height: '45px',
    backgroundColor: '#fff',
    '& fieldset': {
      border: '1px solid #ccc',
      borderRadius: '4px',
    },
    '&:hover fieldset': {
      borderColor: '#666',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#2196f3',
    }
  },
  '& .MuiInputLabel-root': {
    color: '#666',
  },
  '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: '#2196f3',
    borderWidth: '1px',
  },
  '& .MuiOutlinedInput-input:focus': {
    outline: 'none'
  }
};

const multilineInputStyles = {
  '& .MuiOutlinedInput-root': {
    backgroundColor: '#fff',
    '& fieldset': {
      border: '1px solid #ccc',
      borderRadius: '4px',
    },
    '&:hover fieldset': {
      borderColor: '#666',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#2196f3',
    }
  },
  '& .MuiInputLabel-root': {
    color: '#666',
  }
};

const steps = [
  'Client Details',
  'Items & Pricing',
  'Additional Details',
  'Project Photos',
  'Review'
];

// Add this style object at the top with other styles
const inputStyle = {
  height: '45px',
  width: '100%',
  fontSize: '0.95rem',
  border: '1px solid #ccc',
  borderRadius: '4px',
  padding: '8px',
  fontFamily: 'inherit',
  outline: 'none',
  '&:focus': {
    border: '1px solid #2196f3',
    boxShadow: '0 0 0 1px #2196f3'
  }
};

const buttonStyles = {
  '&:focus': {
    outline: 'none',
    boxShadow: 'none'
  }
};

const quillStyles = {
  '& .ql-toolbar': {
    borderRadius: '8px 8px 0 0',
    border: '1px solid #e0e0e0',
    backgroundColor: '#f8f9fa',
    outline: 'none'
  },
  '& .ql-container': {
    borderRadius: '0 0 8px 8px',
    border: '1px solid #e0e0e0',
    borderTop: 'none',
    minHeight: '150px',
    outline: 'none'
  },
  '& .ql-editor:focus': {
    outline: 'none'
  }
};

const AdminCreateEstimateNoTabs = () => {
  const navigate = useNavigate();
  const [savedItems, setSavedItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedClient, setSelectedClient] = useState(null);
  const [estimate, setEstimate] = useState({
    ...initialEstimateState,
    use_client_address: true
  });
  const [isDragging, setIsDragging] = useState(false);
  const [showPropertyDetails, setShowPropertyDetails] = useState(false);
  const [termsTemplates, setTermsTemplates] = useState([]);
  const [selectedTermsTemplate, setSelectedTermsTemplate] = useState(null);
  const [showSuccess, setShowSuccess] = useState(false);
  const [error, setError] = useState(null);
  const [isLoadingPhotos, setIsLoadingPhotos] = useState(false);
  const [photoError, setPhotoError] = useState('');
  const [showTabbedVersion, setShowTabbedVersion] = useState(true);
  const [activeStep, setActiveStep] = useState(0);
  const [projectDetails, setProjectDetails] = useState({
    title: '',
    start_date: new Date().toISOString().split('T')[0],
    end_date: '',
    address: estimate.customer_details?.address || '',
    city: estimate.customer_details?.city || '',
    state: estimate.customer_details?.state || '',
    zipCode: estimate.customer_details?.zipCode || ''
  });
  const [formState, setFormState] = useState({
    selectedClient: null,
    estimate: initialEstimateState,
    activeStep: 0
  });

  console.log('Current selectedClient:', selectedClient);
  console.log('Current estimate:', estimate);
  console.log('Current formState:', formState);

  useEffect(() => {
    const loadSavedItems = async () => {
      try {
        const items = await getItems();
        setSavedItems(items);
      } catch (error) {
        console.error('Error loading saved items:', error);
      }
    };
    loadSavedItems();
  }, []);

  useEffect(() => {
    const fetchTermsTemplates = async () => {
      try {
        const response = await getTermsTemplates();
        setTermsTemplates(response.data);
      } catch (error) {
        console.error('Error fetching terms templates:', error);
      }
    };
    fetchTermsTemplates();
  }, []);

  useEffect(() => {
    const fetchBusinessDetails = async () => {
      try {
        const businessData = await getBusinessDetails();
        if (businessData) {
          setEstimate(prev => ({
            ...prev,
            business_details: {
              company_name: businessData.company_name || '',
              address: businessData.address || '',
              city: businessData.city || '',
              state: businessData.state || '',
              zip_code: businessData.zip_code || '',
              phone: businessData.phone || '',
              email: businessData.email || ''
            }
          }));
        }
      } catch (error) {
        console.error('Error fetching business details:', error);
      }
    };

    fetchBusinessDetails();
  }, []);

  useEffect(() => {
    if (estimate.use_client_address && estimate.customer_details) {
      setEstimate(prev => ({
        ...prev,
        service_address: estimate.customer_details.address || '',
        service_city: estimate.customer_details.city || '',
        service_state: estimate.customer_details.state || '',
        service_zip: estimate.customer_details.zip || estimate.customer_details.zipCode || ''
      }));
    }
  }, [estimate.use_client_address, estimate.customer_details]);

  useEffect(() => {
    // Cleanup function runs when component unmounts
    return () => {
      sessionStorage.removeItem('estimateFormState');
      localStorage.removeItem('clientsCache');
    };
  }, []);

  const handleClientSelect = (client) => {
    console.log('Selected client:', client);
    setSelectedClient(client);
    
    // Update form data with client details
    setEstimate(prevData => ({
      ...prevData,
      customer_details: {
        name: client.name || '',
        company: client.company || '',
        email: client.email || '',
        phone: client.phone || '',
        address: client.address || '',
        city: client.city || '',
        state: client.state || '',
        zip: client.zipCode || client.zip || '',
        notes: client.notes || ''
      }
    }));
  };

  const handleClientCreate = async (clientData) => {
    try {
      // If we're just updating the form (not saving to DB yet)
      if (clientData.isNewClient) {
        handleClientSelect(clientData);
        return;
      }

      // Format client data for backend
      const formattedClientData = {
        name: clientData.name,
        email: clientData.email,
        phone: clientData.phone,
        company: clientData.company,
        address: clientData.address,
        city: clientData.city,
        state: clientData.state,
        zipCode: clientData.zipCode,
        notes: clientData.notes || ''
      };

      // Create client in database
      const response = await createClient(formattedClientData);
      const newClient = response.data;

      // Update estimate state with new client data
      handleClientSelect({
        ...newClient,
        isNewClient: false,
        customer_details: {
          ...newClient,
          zip: newClient.zipCode, // Ensure zip field is set
          isNewClient: false
        }
      });

    } catch (error) {
      console.error('Error creating client:', error);
      setError(error.response?.data?.message || 'Failed to create client');
    }
  };

  const handleAddItem = (item, index = null) => {
    setEstimate(prev => {
      const updatedItems = [...prev.items];
      const newItem = {
        title: item.title || '',
        description: item.description || '',
        quantity: Number(item.quantity) || 0,
        unit_price: Number(item.unitPrice || item.unit_price) || 0,
        unitPrice: Number(item.unitPrice || item.unit_price) || 0,
        total: (Number(item.quantity) || 0) * (Number(item.unitPrice || item.unit_price) || 0),
        // Keep ALL existing flags
        ...item,  // This preserves all flags from the original item
        id: item.id,
        fromAutocomplete: item.fromAutocomplete,
        saved: item.saved,
        isNew: item.isNew,
        isEdited: item.isEdited,
        hideLibraryBanner: item.hideLibraryBanner,
        isFromLibrary: item.isFromLibrary,
        isSavedItem: item.isSavedItem
      };

      if (index !== null) {
        updatedItems[index] = newItem;
      } else {
        updatedItems.push(newItem);
      }
      
      return {
        ...prev,
        items: updatedItems
      };
    });
  };

  const handleRemoveItem = (index) => {
    setEstimate(prev => ({
      ...prev,
      items: prev.items.filter((_, i) => i !== index)
    }));
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setIsDragging(false);
  };

  const handleDrop = async (e) => {
    e.preventDefault();
    setIsDragging(false);
    
    const files = Array.from(e.dataTransfer.files).filter(file => 
      file.type.startsWith('image/')
    );
    
    if (files.length > 0) {
      const event = { target: { files } };
      await handleFileUpload(event);
    }
  };

  const handleFileUpload = async (event) => {
    try {
      const files = Array.from(event.target.files || []);
      if (files.length === 0) return;

      setIsLoadingPhotos(true);
      setPhotoError('');
      
      for (const file of files) {
        const formData = new FormData();
        formData.append('image', file);
        
        const response = await uploadEstimatePhoto(formData);
        
        if (response?.filename) {
          const newImage = {
            filename: response.filename,
            url: response.url
          };

          setEstimate(prev => ({
            ...prev,
            project_images: [...(prev.project_images || []), newImage]
          }));
        }
      }
    } catch (error) {
      console.error('Error uploading files:', error);
      setPhotoError('Failed to upload images');
    } finally {
      setIsLoadingPhotos(false);
    }
  };

  const handleRemoveImage = (index) => {
    setEstimate(prev => ({
      ...prev,
      project_images: prev.project_images.filter((_, i) => i !== index)
    }));
  };

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      setError(null);

      if (!estimate.project_name) {
        setError('Project name is required');
        return;
      }

      if (!selectedClient) {
        setError('Please select or create a client');
        return;
      }

      const newEstimateNumber = generateEstimateNumber();
      
      // Prepare service address data
      let serviceAddressData = {};
      if (estimate.use_client_address && estimate.customer_details) {
        serviceAddressData = {
          service_address: estimate.customer_details.address || '',
          service_city: estimate.customer_details.city || '',
          service_state: estimate.customer_details.state || '',
          service_zip: estimate.customer_details.zip || estimate.customer_details.zipCode || ''
        };
      } else {
        serviceAddressData = {
          service_address: estimate.service_address || '',
          service_city: estimate.service_city || '',
          service_state: estimate.service_state || '',
          service_zip: estimate.service_zip || ''
        };
      }

      // Create estimate with service address
      const response = await createEstimate({
        ...estimate,
        ...serviceAddressData,
        estimate_number: newEstimateNumber,
        client_id: selectedClient.id
      });

      const estimateId = response.id;
      console.log('Found Estimate ID:', estimateId);

      if (estimateId) {
        // Clear the form state and clients cache before redirecting
        sessionStorage.removeItem('estimateFormState');
        sessionStorage.removeItem('clientsCache');
        window.location.href = `/admin/estimates/${estimateId}`;
      } else {
        setError('Could not get estimate ID from response');
      }

    } catch (error) {
      console.error('Error creating estimate:', error);
      setError(error.response?.data?.message || error.message || 'Failed to create estimate');
    } finally {
      setIsLoading(false);
    }
  };

  const handleServiceAddressChange = (field, value) => {
    setEstimate(prev => ({
      ...prev,
      [`service_${field}`]: value
    }));

    if (field === 'address' || field === 'city' || field === 'state' || field === 'zip') {
      const updatedEstimate = {
        ...estimate,
        [`service_${field}`]: value
      };
      
      const hasCompleteAddress = 
        updatedEstimate.service_address && 
        updatedEstimate.service_city && 
        updatedEstimate.service_state && 
        updatedEstimate.service_zip;

      setShowPropertyDetails(hasCompleteAddress);
    }
  };

  const handleUseClientAddress = (event) => {
    const useClientAddr = event.target.checked;
    setEstimate(prev => {
      if (useClientAddr && prev.customer_details) {
        return {
          ...prev,
          use_client_address: useClientAddr,
          service_address: prev.customer_details.address || '',
          service_city: prev.customer_details.city || '',
          service_state: prev.customer_details.state || '',
          service_zip: prev.customer_details.zip || prev.customer_details.zipCode || ''
        };
      }
      return {
        ...prev,
        use_client_address: useClientAddr,
        service_address: '',
        service_city: '',
        service_state: '',
        service_zip: ''
      };
    });
  };

  const getFormattedServiceAddress = () => {
    const addr = estimate.use_client_address ? estimate.customer_details : estimate;
    const parts = [
      addr.service_address || addr.address,
      addr.service_city || addr.city,
      addr.service_state || addr.state,
      addr.service_zip || addr.zip || addr.zipCode
    ].filter(Boolean);
    
    return parts.join(', ');
  };

  const handleTermsTemplateChange = (event) => {
    const selectedTemplate = termsTemplates.find(template => template.id === event.target.value);
    setSelectedTermsTemplate(selectedTemplate);
    if (selectedTemplate) {
      setEstimate(prev => ({
        ...prev,
        terms: selectedTemplate.content
      }));
    }
  };

  const renderProjectImage = (image, index) => (
    <Grid item xs={12} sm={6} md={4} key={index}>
      <Box sx={{ 
        position: 'relative', 
        paddingTop: '75%', 
        width: '100%', 
        borderRadius: '8px', 
        overflow: 'hidden', 
        boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
      }}>
        <img
          src={`${image.url}`}
          alt={`Project photo ${index + 1}`}
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            objectFit: 'cover'
          }}
          onError={(e) => {
            console.error('Image load error:', e);
            console.log('Failed URL:', `${process.env.REACT_APP_API_URL}${image.url}`);
          }}
        />
        <IconButton
          onClick={() => handleRemoveImage(index)}
          sx={{
            position: 'absolute',
            top: 8,
            right: 8,
            backgroundColor: 'rgba(255,255,255,0.8)',
            zIndex: 2,
            '&:hover': {
              backgroundColor: 'rgba(255,255,255,0.9)'
            }
          }}
        >
          <DeleteIcon />
        </IconButton>
      </Box>
    </Grid>
  );

  const handleNext = () => {
    setActiveStep((prevStep) => prevStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  const getCurrentStepContent = () => {
    switch (activeStep) {
      case 0:
        return (
          <Paper sx={{ p: 3, mb: 3, backgroundColor: '#fff', borderRadius: '12px', boxShadow: '0 2px 12px rgba(0,0,0,0.08)' }}>
            <ClientEstimateForm
              onClientSelect={handleClientSelect}
              onClientCreate={handleClientCreate}
              styles={styles}
              selectedClient={selectedClient}
              initialData={{
                name: estimate.customer_details.name,
                company: estimate.customer_details.company,
                email: estimate.customer_details.email,
                phone: estimate.customer_details.phone,
                address: estimate.customer_details.address,
                city: estimate.customer_details.city,
                state: estimate.customer_details.state,
                zipCode: estimate.customer_details.zipCode || estimate.customer_details.zip, // Handle both formats
                notes: estimate.customer_details.notes,
                isNewClient: estimate.customer_details.isNewClient
              }}
              key={`client-form-${showTabbedVersion}-${selectedClient?.id}`}
              debug={true}
            />

            {/* Project Name */}
            <Box sx={{ mt: 4, mb: 4 }}>
              <Typography 
                variant="h6" 
                gutterBottom
                sx={{
                  color: '#34495e',
                  fontSize: '1.1rem',
                  fontWeight: 500,
                  mb: 2,
                  display: 'flex',
                  alignItems: 'center',
                  '&::before': {
                    content: '""',
                    width: '4px',
                    height: '24px',
                    backgroundColor: '#3498db',
                    marginRight: '12px',
                    borderRadius: '4px'
                  }
                }}
              >
                Project Name
              </Typography>
              <input
                type="text"
                value={estimate.project_name}
                onChange={(e) => handleInputChange('project_name', e.target.value)}
                placeholder="Enter the project name"
                style={inputStyle}
              />
            </Box>

            {/* Service Address Section */}
            <Box sx={{ mb: 4 }}>
              <Typography 
                variant="h6" 
                gutterBottom
                sx={{
                  color: '#34495e',
                  fontSize: '1.1rem',
                  fontWeight: 500,
                  mb: 2,
                  display: 'flex',
                  alignItems: 'center',
                  '&::before': {
                    content: '""',
                    width: '4px',
                    height: '24px',
                    backgroundColor: '#3498db',
                    marginRight: '12px',
                    borderRadius: '4px'
                  }
                }}
              >
                Service Info
              </Typography>

              <FormControlLabel
                control={
                  <Switch
                    checked={estimate.use_client_address}
                    onChange={handleUseClientAddress}
                    color="primary"
                    sx={{
                      '& .MuiSwitch-switchBase.Mui-checked': {
                        color: '#2196f3',
                      },
                      '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                        backgroundColor: '#2196f3',
                      },
                    }}
                  />
                }
                label={
                  <Typography sx={{ color: '#666', fontSize: '0.95rem' }}>
                    Use Client's Address
                  </Typography>
                }
                sx={{ 
                  mb: 2,
                  ml: 0,
                  '& .MuiFormControlLabel-label': {
                    fontWeight: 500
                  }
                }}
              />

              {!estimate.use_client_address && (
                <input
                  type="text"
                  value={estimate.service_address}
                  onChange={(e) => handleServiceAddressChange('address', e.target.value)}
                  placeholder="Enter complete service address"
                  style={inputStyle}
                />
              )}
            </Box>
          </Paper>
        );
      case 1:
        return (
          <Paper sx={{ p: 3, mb: 3, backgroundColor: '#fff', borderRadius: '12px', boxShadow: '0 2px 12px rgba(0,0,0,0.08)' }}>
            <Typography variant="h6" gutterBottom>
              Items & Pricing
            </Typography>
            <SavedItemsManager
              onAddToEstimate={handleAddItem}
              onRemoveItem={handleRemoveItem}
              estimateItems={estimate.items}
              savedItems={savedItems}
              setSavedItems={setSavedItems}
            />
          </Paper>
        );
      case 2:
        return (
          <Paper sx={{ p: 3, mb: 3, backgroundColor: '#fff', borderRadius: '12px', boxShadow: '0 2px 12px rgba(0,0,0,0.08)' }}>
            <Typography variant="h6" gutterBottom>
              Additional Details
            </Typography>
            
            {/* Client Message Section */}
            <Box sx={{ mb: 5 }}>
              <Typography 
                variant="h6" 
                gutterBottom
                sx={{
                  color: '#34495e',
                  fontSize: '1.1rem',
                  fontWeight: 500,
                  mb: 2,
                  display: 'flex',
                  alignItems: 'center',
                  '&::before': {
                    content: '""',
                    width: '4px',
                    height: '24px',
                    backgroundColor: '#3498db',
                    marginRight: '12px',
                    borderRadius: '4px'
                  }
                }}
              >
                Client Message
              </Typography>
              <ReactQuill
                value={estimate.scope_of_work}
                onChange={(content) => handleInputChange('scope_of_work', content)}
                placeholder="Message to the client..."
                modules={{
                  toolbar: [
                    ['bold', 'italic', 'underline'],
                    [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                    ['clean']
                  ]
                }}
              />
            </Box>

            {/* Terms Section */}
            <Box 
              sx={{ 
                mb: 3,
                cursor: 'text',
                '& .ql-toolbar': {
                  borderRadius: '8px 8px 0 0',
                  border: '1px solid #e0e0e0',
                  backgroundColor: '#f8f9fa'
                },
                '& .ql-container': {
                  borderRadius: '0 0 8px 8px',
                  border: '1px solid #e0e0e0',
                  borderTop: 'none',
                  minHeight: '150px'
                }
              }}
              onClick={(e) => {
                const termsEditor = e.currentTarget.querySelector('.ql-editor');
                if (termsEditor) {
                  termsEditor.focus();
                }
              }}
            >
              <Typography 
                variant="h6" 
                gutterBottom
                sx={{
                  color: '#34495e',
                  fontSize: '1.1rem',
                  fontWeight: 500,
                  mb: 2,
                  display: 'flex',
                  alignItems: 'center',
                  '&::before': {
                    content: '""',
                    width: '4px',
                    height: '24px',
                    backgroundColor: '#27ae60',
                    marginRight: '12px',
                    borderRadius: '4px'
                  }
                }}
              >
                Terms & Conditions
              </Typography>
              <Box sx={{ mb: 2 }}>
                <TextField
                  select
                  fullWidth
                  label="Select Terms Template"
                  value={selectedTermsTemplate?.id || ''}
                  onChange={handleTermsTemplateChange}
                  sx={commonInputStyles}
                >
                  <MenuItem value="">Custom Terms</MenuItem>
                  {termsTemplates.map((template) => (
                    <MenuItem key={template.id} value={template.id}>
                      {template.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Box>
              <ReactQuill
                value={estimate.terms}
                onChange={(content) => handleInputChange('terms', content)}
                placeholder="Specify your terms and conditions..."
                modules={{
                  toolbar: [
                    ['bold', 'italic', 'underline'],
                    [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                    ['clean']
                  ]
                }}
              />
            </Box>
          </Paper>
        );
      case 3:
        return (
          <Paper sx={{ p: 3, mb: 3, backgroundColor: '#fff', borderRadius: '12px', boxShadow: '0 2px 12px rgba(0,0,0,0.08)' }}>
            <Typography variant="h6" gutterBottom>
              Project Photos
            </Typography>
            <Box
              sx={{
                border: '2px dashed #ccc',
                borderRadius: '8px',
                p: 3,
                mb: 3,
                textAlign: 'center',
                backgroundColor: isDragging ? '#f0f7ff' : '#fafafa',
                cursor: 'pointer'
              }}
              onDragOver={handleDragOver}
              onDragLeave={handleDragLeave}
              onDrop={handleDrop}
            >
              <input
                type="file"
                accept="image/*"
                multiple
                onChange={handleFileUpload}
                style={{ display: 'none' }}
                id="project-photo-input"
              />
              <label htmlFor="project-photo-input">
                <CloudUploadIcon sx={{ fontSize: 48, color: '#666', mb: 1 }} />
                <Typography>
                  Drag & drop photos here or click to upload
                </Typography>
              </label>
            </Box>
            <Grid container spacing={2}>
              {estimate.project_images.map((image, index) => (
                <Grid item xs={12} sm={6} md={4} key={index}>
                  {renderProjectImage(image, index)}
                </Grid>
              ))}
            </Grid>
          </Paper>
        );
      case 4:
        return (
          <Paper sx={{ p: 3, mb: 3, backgroundColor: '#fff', borderRadius: '12px', boxShadow: '0 2px 12px rgba(0,0,0,0.08)' }}>
            <Typography variant="h6" gutterBottom>
              Review
            </Typography>
            <EstimateReview 
              estimate={estimate} 
              styles={styles}
            />
          </Paper>
        );
      default:
        return null;
    }
  };

  const handleInputChange = (field, value) => {
    setEstimate(prev => ({
      ...prev,
      [field]: value
    }));

    // If we're updating project name, also update project details
    if (field === 'project_name') {
      setProjectDetails(prev => ({
        ...prev,
        title: value
      }));
    }

    // Log the change for debugging
    console.log(`Updated ${field}:`, value);
  };

  const handleStepClick = (index) => {
    // Remove the restriction, allow clicking any tab
    setActiveStep(index);
  };

  // Add this function near your other handlers
  const handleServiceAddressInput = (e) => {
    const fullAddress = e.target.value;
    const commaIndex = fullAddress.indexOf(',');
    
    if (commaIndex !== -1) {
      // Split at the comma
      const streetAddress = fullAddress.substring(0, commaIndex).trim();
      const remainder = fullAddress.substring(commaIndex + 1).trim();
      
      // Update the street address field
      handleServiceAddressChange('address', streetAddress);
      
      // If there's content after the comma, move focus to city field
      if (remainder) {
        handleServiceAddressChange('city', remainder);
        // Find and focus the city input
        const cityInput = document.querySelector('input[name="service-city"]');
        if (cityInput) {
          cityInput.focus();
        }
      }
    } else {
      // No comma, just update the street address
      handleServiceAddressChange('address', fullAddress);
    }
  };

  // Add this new function to handle address updates
  const handleServiceAddressUpdate = (addressData) => {
    setEstimate(prev => ({
      ...prev,
      service_address: addressData.address || '',
      service_city: addressData.city || '',
      service_state: addressData.state || '',
      service_zip: addressData.zipCode || addressData.zip || '',
      use_client_address: false // Disable auto-sync when manually editing
    }));
  };

  const handleViewChange = (e) => {
    const isTabbed = e.target.checked;
    
    console.log('Before view change - selectedClient:', selectedClient);
    console.log('Before view change - estimate:', estimate);
    
    // Create a snapshot of the current state
    const stateSnapshot = {
      selectedClient: selectedClient,  // Store the entire selectedClient object without modification
      estimate: {
        ...estimate,
        customer_details: selectedClient ? selectedClient : estimate.customer_details
      },
      activeStep
    };
    
    // Store in both state and sessionStorage
    setFormState(stateSnapshot);
    sessionStorage.setItem('estimateFormState', JSON.stringify(stateSnapshot));
    
    setShowTabbedVersion(isTabbed);
    
    // Immediately restore state if switching to full view
    if (!isTabbed) {
      setSelectedClient(stateSnapshot.selectedClient);
      setEstimate(stateSnapshot.estimate);
      setActiveStep(stateSnapshot.activeStep);
    }
  };

  // Add this effect to handle view changes
  useEffect(() => {
    console.log('View change effect - selectedClient:', selectedClient);
    console.log('View change effect - parsedState:', JSON.parse(sessionStorage.getItem('estimateFormState')));
    
    if (!showTabbedVersion) {
      const savedState = sessionStorage.getItem('estimateFormState');
      if (savedState) {
        const parsedState = JSON.parse(savedState);
        console.log('Restoring saved state:', parsedState);
        
        setSelectedClient(parsedState.selectedClient);
        setEstimate(parsedState.estimate);
        setActiveStep(parsedState.activeStep);
      }
    } else {
      // Add this else block to handle tabbed view restoration
      const savedState = sessionStorage.getItem('estimateFormState');
      if (savedState) {
        const parsedState = JSON.parse(savedState);
        setSelectedClient(parsedState.selectedClient);
        setEstimate(parsedState.estimate);
        setActiveStep(parsedState.activeStep);
      }
    }
  }, [showTabbedVersion]);

  return (
    <Container maxWidth="lg">
      <Box sx={{ py: 4 }}>
        {/* Header Section */}
        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'center',
          mb: 4 
        }}>
          <Typography variant="h4" 
            sx={{
              color: '#2c3e50',
              fontWeight: 600
            }}
          >
            Create New Estimate
          </Typography>

          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <FormControlLabel
              control={
                <Switch
                  checked={showTabbedVersion}
                  onChange={handleViewChange}
                  color="primary"
                />
              }
              label={showTabbedVersion ? "Tabbed View" : "Tabbed View"}
              sx={{
                '& .MuiFormControlLabel-label': {
                  color: '#666',
                  fontSize: '0.9rem'
                }
              }}
            />
          </Box>
        </Box>

        {showTabbedVersion ? (
          <>
            <Stepper activeStep={activeStep} sx={{ pt: 3, pb: 5 }}>
              {steps.map((label, index) => (
                <Step 
                  key={label}
                  onClick={() => handleStepClick(index)}
                  sx={{ 
                    cursor: 'pointer',
                    pointerEvents: 'auto',
                    '& *': { 
                      pointerEvents: 'auto !important',
                      cursor: 'pointer !important'
                    },
                    '& .MuiStepLabel-root': {
                      pointerEvents: 'auto !important',
                      cursor: 'pointer !important',
                      '&:hover': {
                        '& .MuiStepLabel-label': {
                          color: '#2196f3',
                          cursor: 'pointer !important'
                        },
                        '& .MuiStepIcon-root': {
                          color: '#2196f3',
                          cursor: 'pointer !important'
                        }
                      }
                    }
                  }}
                >
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            
            {/* Content section separate from Stepper */}
            <Paper 
              sx={{ 
                p: 3, 
                mb: 3, 
                backgroundColor: '#fff', 
                borderRadius: '12px', 
                boxShadow: '0 2px 12px rgba(0,0,0,0.08)'
              }}
            >
              {getCurrentStepContent()}
            </Paper>
          </>
        ) : (
          <>
            {/* Client Details Section with Project Name and Service Address */}
            <Paper sx={{ p: 3, mb: 3, backgroundColor: '#fff', borderRadius: '12px', boxShadow: '0 2px 12px rgba(0,0,0,0.08)' }}>
              <ClientEstimateForm
                onClientSelect={handleClientSelect}
                onClientCreate={handleClientCreate}
                styles={styles}
                selectedClient={selectedClient}
                initialData={estimate.customer_details}
                key={`client-form-${showTabbedVersion}-${selectedClient?.id}`}
                debug={true}
              />

              {/* Project Name */}
              <Box sx={{ mt: 4, mb: 4 }}>
                <Typography 
                  variant="h6" 
                  gutterBottom
                  sx={{
                    color: '#34495e',
                    fontSize: '1.1rem',
                    fontWeight: 500,
                    mb: 2,
                    display: 'flex',
                    alignItems: 'center',
                    '&::before': {
                      content: '""',
                      width: '4px',
                      height: '24px',
                      backgroundColor: '#3498db',
                      marginRight: '12px',
                      borderRadius: '4px'
                    }
                  }}
                >
                  Project Name
                </Typography>
                <input
                  type="text"
                  value={estimate.project_name}
                  onChange={(e) => handleInputChange('project_name', e.target.value)}
                  placeholder="Enter the project name"
                  style={inputStyle}
                />
              </Box>

              {/* Service Address Section */}
              <Box sx={{ mb: 4 }}>
                <Typography 
                  variant="h6" 
                  gutterBottom
                  sx={{
                    color: '#34495e',
                    fontSize: '1.1rem',
                    fontWeight: 500,
                    mb: 2,
                    display: 'flex',
                    alignItems: 'center',
                    '&::before': {
                      content: '""',
                      width: '4px',
                      height: '24px',
                      backgroundColor: '#3498db',
                      marginRight: '12px',
                      borderRadius: '4px'
                    }
                  }}
                >
                  Service Location
                </Typography>

                <FormControlLabel
                  control={
                    <Switch
                      checked={estimate.use_client_address}
                      onChange={handleUseClientAddress}
                      color="primary"
                      sx={{
                        '& .MuiSwitch-switchBase.Mui-checked': {
                          color: '#2196f3',
                        },
                        '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                          backgroundColor: '#2196f3',
                        },
                      }}
                    />
                  }
                  label={
                    <Typography sx={{ color: '#666', fontSize: '0.95rem' }}>
                      Use Client's Address
                    </Typography>
                  }
                  sx={{ 
                    mb: 2,
                    ml: 0,
                    '& .MuiFormControlLabel-label': {
                      fontWeight: 500
                    }
                  }}
                />

                {!estimate.use_client_address && (
                  <input
                    type="text"
                    value={estimate.service_address}
                    onChange={(e) => handleServiceAddressChange('address', e.target.value)}
                    placeholder="Enter complete service address"
                    style={inputStyle}
                  />
                )}
              </Box>
            </Paper>

            {/* Items & Pricing Section */}
            <Paper sx={{ p: 3, mb: 3, backgroundColor: '#fff', borderRadius: '12px', boxShadow: '0 2px 12px rgba(0,0,0,0.08)' }}>
              <Typography variant="h6" gutterBottom>
                Items & Pricing
              </Typography>
              <SavedItemsManager
                onAddToEstimate={handleAddItem}
                onRemoveItem={handleRemoveItem}
                estimateItems={estimate.items}
                savedItems={savedItems}
                setSavedItems={setSavedItems}
              />
            </Paper>

            {/* Additional Details Section */}
            <Paper sx={{ p: 3, mb: 3, backgroundColor: '#fff', borderRadius: '12px', boxShadow: '0 2px 12px rgba(0,0,0,0.08)' }}>
              <Typography variant="h6" gutterBottom>
                Additional Details
              </Typography>

              {/* Client Message Section */}
              <Box sx={{ mb: 5 }}>
                <Typography 
                  variant="h6" 
                  gutterBottom
                  sx={{
                    color: '#34495e',
                    fontSize: '1.1rem',
                    fontWeight: 500,
                    mb: 2,
                    display: 'flex',
                    alignItems: 'center',
                    '&::before': {
                      content: '""',
                      width: '4px',
                      height: '24px',
                      backgroundColor: '#3498db',
                      marginRight: '12px',
                      borderRadius: '4px'
                    }
                  }}
                >
                  Client Message
                </Typography>
                <ReactQuill
                  value={estimate.scope_of_work}
                  onChange={(content) => handleInputChange('scope_of_work', content)}
                  placeholder="Message to the client..."
                  modules={{
                    toolbar: [
                      ['bold', 'italic', 'underline'],
                      [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                      ['clean']
                    ]
                  }}
                />
              </Box>

              {/* Terms Section */}
              <Box 
                sx={{ 
                  mb: 3,
                  cursor: 'text',
                  '& .ql-toolbar': {
                    borderRadius: '8px 8px 0 0',
                    border: '1px solid #e0e0e0',
                    backgroundColor: '#f8f9fa'
                  },
                  '& .ql-container': {
                    borderRadius: '0 0 8px 8px',
                    border: '1px solid #e0e0e0',
                    borderTop: 'none',
                    minHeight: '150px'
                  }
                }}
                onClick={(e) => {
                  const termsEditor = e.currentTarget.querySelector('.ql-editor');
                  if (termsEditor) {
                    termsEditor.focus();
                  }
                }}
              >
                <Typography 
                  variant="h6" 
                  gutterBottom
                  sx={{
                    color: '#34495e',
                    fontSize: '1.1rem',
                    fontWeight: 500,
                    mb: 2,
                    display: 'flex',
                    alignItems: 'center',
                    '&::before': {
                      content: '""',
                      width: '4px',
                      height: '24px',
                      backgroundColor: '#27ae60',
                      marginRight: '12px',
                      borderRadius: '4px'
                    }
                  }}
                >
                  Terms & Conditions
                </Typography>
                <Box sx={{ mb: 2 }}>
                  <TextField
                    select
                    fullWidth
                    label="Select Terms Template"
                    value={selectedTermsTemplate?.id || ''}
                    onChange={handleTermsTemplateChange}
                    sx={commonInputStyles}
                  >
                    <MenuItem value="">Custom Terms</MenuItem>
                    {termsTemplates.map((template) => (
                      <MenuItem key={template.id} value={template.id}>
                        {template.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Box>
                <ReactQuill
                  value={estimate.terms}
                  onChange={(content) => handleInputChange('terms', content)}
                  placeholder="Specify your terms and conditions..."
                  modules={{
                    toolbar: [
                      ['bold', 'italic', 'underline'],
                      [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                      ['clean']
                    ]
                  }}
                />
              </Box>
            </Paper>

            {/* Project Photos Section */}
            <Paper sx={{ p: 3, mb: 3, backgroundColor: '#fff', borderRadius: '12px', boxShadow: '0 2px 12px rgba(0,0,0,0.08)' }}>
              <Typography variant="h6" gutterBottom>
                Project Photos
              </Typography>
              <Box
                sx={{
                  border: '2px dashed #ccc',
                  borderRadius: '8px',
                  p: 3,
                  mb: 3,
                  textAlign: 'center',
                  backgroundColor: isDragging ? '#f0f7ff' : '#fafafa',
                  cursor: 'pointer'
                }}
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}
              >
                <input
                  type="file"
                  accept="image/*"
                  multiple
                  onChange={handleFileUpload}
                  style={{ display: 'none' }}
                  id="project-photo-input"
                />
                <label htmlFor="project-photo-input">
                  <CloudUploadIcon sx={{ fontSize: 48, color: '#666', mb: 1 }} />
                  <Typography>
                    Drag & drop photos here or click to upload
                  </Typography>
                </label>
              </Box>
              <Grid container spacing={2}>
                {estimate.project_images.map((image, index) => (
                  <Grid item xs={12} sm={6} md={4} key={index}>
                    {renderProjectImage(image, index)}
                  </Grid>
                ))}
              </Grid>
            </Paper>

            {/* Review Section */}
            <Paper sx={{ p: 3, mb: 3, backgroundColor: '#fff', borderRadius: '12px', boxShadow: '0 2px 12px rgba(0,0,0,0.08)' }}>
              <Typography variant="h6" gutterBottom>
                Review
              </Typography>
              <EstimateReview 
                estimate={estimate} 
                styles={styles}
              />
            </Paper>
          </>
        )}

        {/* Navigation Buttons - Show at bottom for both views */}
        <Box sx={{ 
          mt: 3, 
          display: 'flex', 
          justifyContent: 'space-between',
          backgroundColor: 'white',
          padding: '16px',
          borderRadius: '8px',
          boxShadow: '0 2px 12px rgba(0,0,0,0.08)'
        }}>
          {showTabbedVersion ? (
            <>
              <Button
                variant="outlined"
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ 
                  mr: 1,
                  height: '45px',
                  textTransform: 'none',
                  fontSize: '0.95rem',
                  fontFamily: 'Urbanist',
                  boxShadow: '0 1px 3px rgba(0,0,0,0.1)',
                  borderColor: '#ccc',
                  color: '#666',
                  '&:hover': {
                    borderColor: '#666',
                    backgroundColor: '#f5f5f5'
                  }
                }}
              >
                Back
              </Button>
              {activeStep === steps.length - 1 ? (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                  disabled={isLoading}
                  startIcon={isLoading && <CircularProgress size={20} color="inherit" />}
                  sx={{
                    height: '45px',
                    textTransform: 'none',
                    fontSize: '0.95rem',
                    fontFamily: 'Urbanist',
                    boxShadow: '0 1px 3px rgba(0,0,0,0.1)',
                    backgroundColor: '#2196f3',
                    '&:hover': {
                      backgroundColor: '#1976d2'
                    }
                  }}
                >
                  {isLoading ? 'Creating...' : 'Create Estimate'}
                </Button>
              ) : (
                <Button
                  variant="contained"
                  onClick={handleNext}
                  sx={{
                    height: '45px',
                    textTransform: 'none',
                    fontSize: '0.95rem',
                    fontFamily: 'Urbanist',
                    boxShadow: '0 1px 3px rgba(0,0,0,0.1)',
                    backgroundColor: '#2196f3',
                    '&:hover': {
                      backgroundColor: '#1976d2'
                    }
                  }}
                >
                  Next
                </Button>
              )}
            </>
          ) : (
            <>
              <Button
                variant="outlined"
                color="primary"
                disabled={isLoading}
                onClick={() => navigate('/admin/estimates')}
                sx={{
                  height: '45px',
                  width: '120px',
                  textTransform: 'none',
                  fontSize: '0.95rem',
                  fontFamily: 'Urbanist',
                  boxShadow: '0 1px 3px rgba(0,0,0,0.1)',
                  borderColor: '#ccc',
                  color: '#666',
                  '&:hover': {
                    borderColor: '#666',
                    backgroundColor: '#f5f5f5'
                  }
                }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                disabled={isLoading}
                onClick={handleSubmit}
                startIcon={isLoading && <CircularProgress size={20} color="inherit" />}
                sx={{
                  height: '45px',
                  width: '160px',
                  textTransform: 'none',
                  fontSize: '0.95rem',
                  fontFamily: 'Urbanist',
                  boxShadow: '0 1px 3px rgba(0,0,0,0.1)',
                  '&:hover': {
                    filter: 'brightness(0.95)'
                  }
                }}
              >
                {isLoading ? 'Creating...' : 'Create Estimate'}
              </Button>
            </>
          )}
        </Box>

        {/* Snackbars */}
        <Snackbar
          open={showSuccess}
          autoHideDuration={1500}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          onClose={() => setShowSuccess(false)}
        >
          <Alert severity="success">
            Estimate Created Successfully!
          </Alert>
        </Snackbar>

        <Snackbar
          open={!!error}
          autoHideDuration={6000}
          onClose={() => setError(null)}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert 
            onClose={() => setError(null)} 
            severity="error"
          >
            {error}
          </Alert>
        </Snackbar>
      </Box>
    </Container>
  );
};

export default AdminCreateEstimateNoTabs;