import React, { createContext, useState, useContext, useEffect, useCallback } from 'react';

// Create the context
const AuthContext = createContext({
  user: null,
  login: () => {},
  logout: () => {},
  isAuthenticated: false,
  isAuthorized: (allowedRoles) => false,
});

export const allowedRoles = ['admin', 'employee', 'client'];

// Create a provider component
export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    try {
      const token = localStorage.getItem('token');
      const userRole = localStorage.getItem('userRole');
      
      console.log('Auth initialization - Token exists:', !!token, 'Role:', userRole);
      
      if (token && userRole) {
        const userData = {
          role: userRole,
          token: token
        };
        setUser(userData);
        console.log('User state successfully set:', userData);
      }
    } catch (error) {
      console.error('Error during auth initialization:', error);
    } finally {
      setLoading(false);
    }
  }, []);

  const login = useCallback((userData) => {
    console.log('Login called with:', userData);
    setUser(userData);
    localStorage.setItem('token', userData.token);
    localStorage.setItem('userRole', userData.role);
  }, []);

  const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('userRole');
    setUser(null);
  };

  if (loading) {
    return null; // or a loading spinner
  }

  return (
    <AuthContext.Provider value={{ 
      user, 
      login, 
      logout, 
      isAuthenticated: !!user, 
      isAuthorized: (allowedRoles) => !!user && allowedRoles.includes(user.role)
    }}>
      {children}
    </AuthContext.Provider>
  );
};

// Create a custom hook to use the auth context
export const useAuth = () => useContext(AuthContext);
