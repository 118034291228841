import React, { useState, useEffect } from 'react';
import { Typography, Box, Paper, Alert } from '@mui/material';
import { ComposedChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Area } from 'recharts';
import { getFinancialMetrics } from '../services/api';
import CustomTooltip from './graphs/CustomTooltip';
import TimeRangeSelector from './graphs/TimeRangeSelector';
import MetricSelector from './graphs/MetricSelector';
import styles from './FinancialMetricsGraph.module.scss';

// Helper functions
const formatCurrency = (amount) => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(amount);
};

const filterDataByTimeRange = (data, timeRange = 'month') => {
  if (!data || !Array.isArray(data)) return [];
  
  const now = new Date();
  const ranges = {
    week: 7,
    month: 30,
    quarter: 90,
    year: 365,
    all: Infinity
  };

  const daysToSubtract = ranges[timeRange] || 30;
  const cutoffDate = new Date(now.setDate(now.getDate() - daysToSubtract));

  return data.filter(item => new Date(item.date) >= cutoffDate);
};

const FinancialMetricsGraph = () => {
  const [financialData, setFinancialData] = useState(null);
  const [activeMetric, setActiveMetric] = useState('estimates');
  const [timeRange, setTimeRange] = useState('month');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchFinancialData();
  }, [timeRange]);

  const fetchFinancialData = async () => {
    try {
      console.log('Fetching financial data...');
      const response = await getFinancialMetrics();
      console.log('Raw response:', response);

      const calculateCumulative = (data) => {
        if (!Array.isArray(data)) return [];
        
        let sum = 0;
        return data.map(item => {
          // For projects
          if ('total_value' in item) {
            const value = Number(item.total_value || 0);
            sum += value;
            return {
              date: item.start_time?.split('T')[0] || 
                    item.created_at?.split('T')[0] || 
                    new Date().toISOString().split('T')[0],
              value: sum,
              name: item.name // Optional: for debugging
            };
          }
          
          // For pre-calculated values (estimates/invoices)
          if ('value' in item) {
            sum += Number(item.value || 0);
            return {
              date: item.date,
              value: sum
            };
          }
          
          // Fallback for items calculation
          const itemTotal = item.items?.reduce((total, lineItem) => {
            return total + (Number(lineItem.unitPrice || 0) * Number(lineItem.quantity || 1));
          }, 0) || 0;

          sum += itemTotal;
          return {
            date: item.issue_date?.split('T')[0] || item.date,
            value: sum
          };
        });
      };

      // Make sure we're getting the raw projects data
      const transformedData = {
        estimates: calculateCumulative(response.estimates),
        invoices: calculateCumulative(response.invoices),
        projects: calculateCumulative(Array.isArray(response.projects) ? response.projects : [])
      };

      console.log('Projects data:', response.projects); // Debug log
      console.log('Transformed data:', transformedData);
      setFinancialData(transformedData);
      setError(null);
    } catch (error) {
      console.error('Detailed error:', error);
      setError('Failed to load financial metrics');
    } finally {
      setLoading(false);
    }
  };

  if (loading) return <Paper className={styles.graphCard}><Typography>Loading financial metrics...</Typography></Paper>;
  if (error) return <Paper className={styles.graphCard}><Alert severity="error">{error}</Alert></Paper>;

  const currentData = filterDataByTimeRange(financialData?.[activeMetric] || []);
  const hasData = currentData && currentData.length > 0;
  const totalAmount = hasData ? currentData[currentData.length - 1]?.value || 0 : 0;

  return (
    <Paper className={styles.graphCard}>
      <Box className={styles.cardHeader}>
        <Box>
          <Typography variant="h6" className={styles.title}>
            {activeMetric.charAt(0).toUpperCase() + activeMetric.slice(1)} Overview
          </Typography>
          <Typography variant="h3" className={styles.totalAmount}>
            {formatCurrency(totalAmount)}
          </Typography>
          <TimeRangeSelector timeRange={timeRange} setTimeRange={setTimeRange} />
        </Box>
        <MetricSelector 
          activeMetric={activeMetric} 
          setActiveMetric={setActiveMetric} 
          financialData={financialData} 
        />
      </Box>
      
      <Box className={styles.chartContainer}>
        <ResponsiveContainer width="100%" height={400}>
          <ComposedChart data={currentData}>
            <defs>
              <linearGradient id="colorValue" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#1976d2" stopOpacity={0.1}/>
                <stop offset="95%" stopColor="#1976d2" stopOpacity={0}/>
              </linearGradient>
            </defs>
            <CartesianGrid strokeDasharray="3 3" vertical={false} stroke="#f0f0f0" />
            <XAxis 
              dataKey="date" 
              tickLine={false}
              axisLine={false}
              tick={{ fill: '#666', fontSize: 12 }}
            />
            <YAxis 
              tickFormatter={(value) => formatCurrency(value)}
              tickLine={false}
              axisLine={false}
              tick={{ fill: '#666', fontSize: 12 }}
            />
            <Tooltip content={<CustomTooltip />} />
            <Area
              type="monotone"
              dataKey="value"
              stroke="#1976d2"
              strokeWidth={2}
              fillOpacity={1}
              fill="url(#colorValue)"
            />
            <Line 
              type="monotone"
              dataKey="value"
              stroke="#1976d2"
              strokeWidth={2}
              dot={false}
            />
          </ComposedChart>
        </ResponsiveContainer>
      </Box>
    </Paper>
  );
};

export default FinancialMetricsGraph; 