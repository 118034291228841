import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Box,
  FormControlLabel,
  Checkbox,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import styles from './RecurringEventForm.module.scss';

const RecurringEventForm = ({ open, onClose, onSubmit }) => {
  const [recurringSettings, setRecurringSettings] = useState({
    frequency: 'weekly', // weekly, daily, monthly
    interval: 1,         // every X days/weeks/months
    daysOfWeek: [],     // [0,1,2,3,4,5,6] where 0 is Sunday
    endDate: '',        // When to stop recurring
    endAfterOccurrences: '', // Alternative: stop after X occurrences
  });

  const handleChange = (field) => (event) => {
    setRecurringSettings({
      ...recurringSettings,
      [field]: event.target.value,
    });
  };

  const handleDayToggle = (day) => {
    const newDays = recurringSettings.daysOfWeek.includes(day)
      ? recurringSettings.daysOfWeek.filter(d => d !== day)
      : [...recurringSettings.daysOfWeek, day];
    
    setRecurringSettings({
      ...recurringSettings,
      daysOfWeek: newDays,
    });
  };

  const handleSubmit = () => {
    onSubmit(recurringSettings);
    onClose();
  };

  const daysOfWeek = [
    { value: 0, label: 'Sunday' },
    { value: 1, label: 'Monday' },
    { value: 2, label: 'Tuesday' },
    { value: 3, label: 'Wednesday' },
    { value: 4, label: 'Thursday' },
    { value: 5, label: 'Friday' },
    { value: 6, label: 'Saturday' },
  ];

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle className={styles.dialogTitle}>
        Set Recurring Schedule
        <IconButton
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: '#FF4842'
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={styles.dialogContent}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 2 }}>
          <FormControl fullWidth>
            <TextField
              select
              label="Frequency"
              value={recurringSettings.frequency}
              onChange={handleChange('frequency')}
              variant="filled"
              className={styles.textField}
            >
              <MenuItem value="daily">Daily</MenuItem>
              <MenuItem value="weekly">Weekly</MenuItem>
              <MenuItem value="monthly">Monthly</MenuItem>
            </TextField>
          </FormControl>

          <TextField
            type="number"
            label="Repeat every"
            value={recurringSettings.interval}
            onChange={handleChange('interval')}
            InputProps={{ inputProps: { min: 1 } }}
            fullWidth
            variant="filled"
            className={styles.textField}
          />

          {recurringSettings.frequency === 'weekly' && (
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
              <InputLabel>Repeat on</InputLabel>
              {daysOfWeek.map((day) => (
                <FormControlLabel
                  key={day.value}
                  control={
                    <Checkbox
                      checked={recurringSettings.daysOfWeek.includes(day.value)}
                      onChange={() => handleDayToggle(day.value)}
                    />
                  }
                  label={day.label}
                />
              ))}
            </Box>
          )}

          <TextField
            type="date"
            label="End Date"
            value={recurringSettings.endDate}
            onChange={handleChange('endDate')}
            InputLabelProps={{ shrink: true }}
            fullWidth
            variant="filled"
            className={styles.textField}
          />

          <TextField
            type="number"
            label="End after occurrences"
            value={recurringSettings.endAfterOccurrences}
            onChange={handleChange('endAfterOccurrences')}
            InputProps={{ inputProps: { min: 1 } }}
            fullWidth
            variant="filled"
            className={styles.textField}
          />
        </Box>
      </DialogContent>
      <DialogActions className={styles.formActions}>
        <Button 
          onClick={onClose}
          sx={{
            color: 'text.secondary',
            borderRadius: '8px',
            textTransform: 'none',
            fontFamily: 'Urbanist, sans-serif',
            '&:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.04)'
            }
          }}
        >
          Cancel
        </Button>
        <Button 
          onClick={handleSubmit}
          variant="contained"
          sx={{
            backgroundColor: '#2065D1',
            borderRadius: '8px',
            textTransform: 'none',
            fontFamily: 'Urbanist, sans-serif',
            boxShadow: 'none',
            '&:hover': {
              backgroundColor: '#1C4FB8',
              boxShadow: 'none'
            }
          }}
        >
          Set Schedule
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RecurringEventForm;
