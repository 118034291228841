import React, { useState } from 'react';
import { 
  TextField, 
  Button, 
  Typography, 
  Box, 
  Alert,
  Container,
  Paper,
  Grid,
  CircularProgress,
  Checkbox,
  FormControlLabel,
  Link
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import styles from './PublicEmployeeRegistration.module.scss';
import { Person, Email, Phone, Home, Lock, LockReset } from '@mui/icons-material';
import { InputAdornment } from '@mui/material';
import WelcomeBanner from './WelcomeBanner';
import { registerEmployee } from '../services/api';

const PublicEmployeeRegistration = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    address: '',
    password: '',
    confirmPassword: ''
  });
  const [acceptedTerms, setAcceptedTerms] = useState(false);

  const textFieldSx = {
    '& .MuiOutlinedInput-root': {
      borderBottom: '1px solid #2c93ee',
      '& fieldset': { border: 'none' },
      '&:hover': {
        borderBottom: '1px solid #2065D1',
      },
      '&.Mui-focused': {
        borderBottom: '2px solid #2065D1',
      }
    },
    '& .MuiInputAdornment-root': {
      marginRight: '8px',
      '& .MuiSvgIcon-root': {
        color: '#7f8c8d'
      }
    }
  };

  const formatPhoneNumber = (phoneNumber) => {
    if (!phoneNumber) return '';
    const cleaned = phoneNumber.replace(/\D/g, '');
    const match = cleaned.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/);
    if (!match) return phoneNumber;
    const [, area, prefix, line] = match;
    let formatted = '';
    if (area) formatted += `(${area}`;
    if (prefix) formatted += `) ${prefix}`;
    if (line) formatted += `-${line}`;
    return formatted;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (formData.password !== formData.confirmPassword) {
      setError('Passwords do not match');
      return;
    }

    try {
      setLoading(true);
      setError('');
      
      const submissionData = {
        ...formData,
        name: `${formData.firstName} ${formData.lastName}`,
        registrationToken: token
      };
      
      delete submissionData.firstName;
      delete submissionData.lastName;
      
      await registerEmployee(submissionData);
      
      setSuccess(true);
      setTimeout(() => {
        navigate('/login');
      }, 3000);
    } catch (err) {
      setError(err.response?.data?.message || 'Failed to register. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleTermsChange = (event) => {
    setAcceptedTerms(event.target.checked);
  };

  if (success) {
    return (
      <Container maxWidth="sm" className={styles.successContainer}>
        <Paper elevation={3} className={styles.successPaper}>
          <Typography variant="h5" gutterBottom>
            Registration Successful!
          </Typography>
          <Typography>
            You will be redirected to the login page shortly...
          </Typography>
          <CircularProgress className={styles.successProgress} />
        </Paper>
      </Container>
    );
  }

  return (
    <>
      <WelcomeBanner />
      <Container maxWidth="md" className={styles.registrationContainer}>
        <Paper elevation={3} className={styles.formWrapper}>
          <Typography variant="h4" gutterBottom className={styles.title}>
            Employee Registration
          </Typography>
          
          <form onSubmit={handleSubmit} className={styles.form}>
            <Grid container spacing={3}>
              {/* Personal Information */}
              <Grid item xs={12}>
                <Typography variant="subtitle2" className={styles.sectionTitle}>
                  Personal Information
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="firstName"
                  label="First Name"
                  value={formData.firstName}
                  onChange={handleChange}
                  required
                  fullWidth
                  sx={textFieldSx}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Person />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="lastName"
                  label="Last Name"
                  value={formData.lastName}
                  onChange={handleChange}
                  required
                  fullWidth
                  sx={textFieldSx}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Person />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="email"
                  label="Email"
                  type="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                  fullWidth
                  sx={textFieldSx}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Email />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="phone"
                  label="Phone"
                  value={formatPhoneNumber(formData.phone)}
                  onChange={handleChange}
                  required
                  fullWidth
                  sx={textFieldSx}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Phone />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="address"
                  label="Address"
                  value={formData.address}
                  onChange={handleChange}
                  required
                  fullWidth
                  sx={textFieldSx}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Home />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              {/* Password Section */}
              <Grid item xs={12}>
                <TextField
                  name="password"
                  label="Password"
                  type="password"
                  value={formData.password}
                  onChange={handleChange}
                  required
                  fullWidth
                  sx={textFieldSx}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Lock />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="confirmPassword"
                  label="Confirm Password"
                  type="password"
                  value={formData.confirmPassword}
                  onChange={handleChange}
                  required
                  fullWidth
                  sx={textFieldSx}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LockReset />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>

            {error && (
              <Alert severity="error" className={styles.alert}>
                {error}
              </Alert>
            )}

            <Box sx={{ mt: 3, mb: 2, display: 'flex', alignItems: 'center' }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={acceptedTerms}
                    onChange={handleTermsChange}
                    color="primary"
                    size="small"
                  />
                }
                label={
                  <Typography variant="body2">
                    I agree to the{' '}
                    <Link href="/terms" target="_blank" color="primary">
                      Terms and Conditions
                    </Link>{' '}
                    and{' '}
                    <Link href="/privacy" target="_blank" color="primary">
                      Privacy Policy
                    </Link>
                  </Typography>
                }
              />
            </Box>

            <Button
              type="submit"
              variant="contained"
              fullWidth
              disabled={loading || !acceptedTerms}
              className={styles.submitButton}
            >
              {loading ? <CircularProgress size={24} /> : 'Complete Registration'}
            </Button>
          </form>
        </Paper>
      </Container>
    </>
  );
};

export default PublicEmployeeRegistration; 