import React from 'react';
import { Paper, Typography } from '@mui/material';
import {
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  Radar,
  Legend,
  ResponsiveContainer
} from 'recharts';

const data = [
  { subject: 'Efficiency', A: 120, B: 110, fullMark: 150 },
  { subject: 'Speed', A: 98, B: 130, fullMark: 150 },
  { subject: 'Quality', A: 86, B: 130, fullMark: 150 },
  { subject: 'Accuracy', A: 99, B: 100, fullMark: 150 },
  { subject: 'Reliability', A: 85, B: 90, fullMark: 150 },
];

const EmployeePerformanceChart = () => (
  <Paper elevation={0} sx={{ p: 3, height: '100%', border: '1px solid #e0e0e0', borderRadius: 1 }}>
    <Typography variant="h6" gutterBottom sx={{ color: '#34495e' }}>
      Employee Performance
    </Typography>
    <ResponsiveContainer width="100%" height={400}>
      <RadarChart data={data}>
        <PolarGrid />
        <PolarAngleAxis dataKey="subject" />
        <PolarRadiusAxis />
        <Radar name="Team A" dataKey="A" stroke="#8884d8" fill="#8884d8" fillOpacity={0.6} />
        <Radar name="Team B" dataKey="B" stroke="#82ca9d" fill="#82ca9d" fillOpacity={0.6} />
        <Legend />
      </RadarChart>
    </ResponsiveContainer>
  </Paper>
);

export default EmployeePerformanceChart; 