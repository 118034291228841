import React from 'react';
import { 
  Box, 
  Paper, 
  Typography, 
  IconButton,
  Grid,
  Button,
  Stepper,
  Step,
  StepLabel
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PaymentsIcon from '@mui/icons-material/Payments';

const RunPayrollView = ({ onBack }) => {
  const steps = ['Review Hours', 'Calculate Pay', 'Review & Submit'];

  return (
    <Box>
      <Box sx={{ mb: 4, display: 'flex', alignItems: 'center' }}>
        <IconButton onClick={onBack} sx={{ mr: 2 }}>
          <ArrowBackIcon />
        </IconButton>
        <PaymentsIcon sx={{ mr: 2, color: '#34495e' }} />
        <Typography variant="h5">Run Payroll</Typography>
      </Box>

      <Paper elevation={0} sx={{ p: 3, mb: 3 }}>
        <Stepper activeStep={0}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Paper>

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper elevation={0} sx={{ p: 3 }}>
            <Typography variant="h6" gutterBottom>
              Current Pay Period
            </Typography>
            {/* Add payroll processing form */}
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default RunPayrollView; 