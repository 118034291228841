import React from 'react';
import { Box, Typography } from '@mui/material';
import styles from './WelcomeBanner.module.scss';

const WelcomeBanner = () => {
  return (
    <Box className={styles.welcomeBanner}>
      <Box className={styles.bannerContent}>
        <span role="img" aria-label="celebration">🎊</span>
        <Typography variant="h6" component="span">
          Welcome to ServTrackr!
        </Typography>
        <span role="img" aria-label="celebration">🎊</span>
      </Box>
    </Box>
  );
};

export default WelcomeBanner; 