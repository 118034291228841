import React from 'react';
import { Box, Typography, Divider } from '@mui/material';

const TotalSection = ({ items }) => {
  const calculateSubtotal = () => {
    return items.reduce((sum, item) => {
      const quantity = Number(item.quantity) || 0;
      const price = Number(item.unitPrice) || 0;
      return sum + (quantity * price);
    }, 0);
  };

  const calculateTax = (subtotal) => {
    return items.reduce((sum, item) => {
      const quantity = Number(item.quantity) || 0;
      const price = Number(item.unitPrice) || 0;
      const itemSubtotal = quantity * price;
      const itemTaxRate = Number(item.tax) || 0;
      return sum + (itemSubtotal * (itemTaxRate / 100));
    }, 0);
  };

  const subtotal = calculateSubtotal();
  const tax = calculateTax(subtotal);
  const total = subtotal + tax;

  const TotalLine = ({ label, amount, isTotal }) => (
    <Box sx={{ 
      display: 'flex', 
      justifyContent: 'space-between',
      alignItems: 'center',
      py: 1,
      ...(isTotal && {
        pt: 2,
        pb: 1
      })
    }}>
      <Typography sx={{ 
        color: '#1a2027',
        fontWeight: isTotal ? 600 : 400,
        fontSize: isTotal ? '1.1rem' : '1rem'
      }}>
        {label}
      </Typography>
      <Typography sx={{ 
        color: '#1a2027',
        fontWeight: isTotal ? 600 : 400,
        fontSize: isTotal ? '1.1rem' : '1rem'
      }}>
        ${amount.toFixed(2)}
      </Typography>
    </Box>
  );

  return (
    <Box sx={{ 
      width: '300px',
      mt: 4,
      mb: 2,
      ml: 'auto',
      pr: 3
    }}>
      <TotalLine label="Subtotal" amount={subtotal} />
      <TotalLine label="Tax" amount={tax} />
      <Divider sx={{ my: 1 }} />
      <TotalLine label="Total" amount={total} isTotal={true} />
    </Box>
  );
};

export default TotalSection; 