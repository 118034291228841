import React from 'react';
import { 
  Box, 
  Paper, 
  Typography, 
  Grid, 
  Card, 
  CardContent, 
  IconButton, 
  Chip, 
  Tooltip,
  LinearProgress
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import {
  MoreVert as MoreVertIcon,
  AccessTime as AccessTimeIcon,
  MonetizationOn as MonetizationOnIcon,
  Email as EmailIcon,
  Phone as PhoneIcon
} from '@mui/icons-material';

const PipelineColumn = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  background: theme.palette.background.default,
  borderRadius: 12,
  minHeight: 600,
  display: 'flex',
  flexDirection: 'column',
  gap: 16,
  border: `1px solid ${theme.palette.divider}`,
  '&:hover': {
    borderColor: theme.palette.primary.main,
    boxShadow: '0 4px 12px rgba(0,0,0,0.05)'
  },
  transition: 'all 0.2s ease'
}));

const LeadCard = styled(Card)(({ theme }) => ({
  padding: theme.spacing(2),
  cursor: 'grab',
  borderRadius: 8,
  background: theme.palette.background.paper,
  '&:hover': {
    boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
    transform: 'translateY(-2px)',
  },
  transition: 'all 0.2s ease',
  border: '1px solid',
  borderColor: theme.palette.divider
}));

const StageHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: theme.spacing(2),
  padding: theme.spacing(1),
  borderBottom: `2px solid ${theme.palette.divider}`
}));

const ValueChip = styled(Chip)(({ theme }) => ({
  backgroundColor: theme.palette.success.light,
  color: theme.palette.success.dark,
  fontWeight: 600,
  '& .MuiChip-icon': {
    color: theme.palette.success.dark
  }
}));

const LeadsPipeline = ({ leads, onDragEnd }) => {
  const pipelineStages = {
    new: {
      title: 'New Leads',
      color: '#2196f3',
      leads: leads.filter(lead => lead.status === 'New')
    },
    contacted: {
      title: 'Contacted',
      color: '#ff9800',
      leads: leads.filter(lead => lead.status === 'Contacted')
    },
    qualified: {
      title: 'Qualified',
      color: '#4caf50',
      leads: leads.filter(lead => lead.status === 'Qualified')
    },
    proposal: {
      title: 'Proposal',
      color: '#9c27b0',
      leads: leads.filter(lead => lead.status === 'Proposal')
    },
    negotiation: {
      title: 'Negotiation',
      color: '#f44336',
      leads: leads.filter(lead => lead.status === 'Negotiation')
    },
    closed: {
      title: 'Won',
      color: '#2e7d32',
      leads: leads.filter(lead => lead.status === 'Won')
    }
  };

  const getStageTotal = (leads) => {
    return leads.reduce((sum, lead) => sum + lead.value, 0);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Grid container spacing={2}>
        {Object.entries(pipelineStages).map(([stage, { title, color, leads: stageLeads }]) => (
          <Grid item xs={12} md={2} key={stage}>
            <PipelineColumn>
              <StageHeader>
                <Typography variant="h6" sx={{ 
                  fontFamily: 'Urbanist, sans-serif',
                  fontWeight: 600,
                  color: color
                }}>
                  {title}
                </Typography>
                <Chip 
                  size="small"
                  label={stageLeads.length}
                  sx={{ 
                    backgroundColor: `${color}15`,
                    color: color,
                    fontWeight: 600
                  }}
                />
              </StageHeader>
              
              <Typography variant="body2" sx={{ mb: 2, color: 'text.secondary' }}>
                Total Value: ${getStageTotal(stageLeads).toLocaleString()}
              </Typography>

              <Droppable droppableId={stage}>
                {(provided, snapshot) => (
                  <Box
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    sx={{
                      minHeight: 400,
                      backgroundColor: snapshot.isDraggingOver ? 'action.hover' : 'transparent',
                      transition: 'background-color 0.2s ease',
                      borderRadius: 1
                    }}
                  >
                    {stageLeads.map((lead, index) => (
                      <Draggable key={lead.id} draggableId={lead.id.toString()} index={index}>
                        {(provided, snapshot) => (
                          <LeadCard
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            sx={{
                              mb: 2,
                              transform: snapshot.isDragging ? 'rotate(3deg)' : 'none'
                            }}
                          >
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                              <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
                                {lead.name}
                              </Typography>
                              <IconButton size="small">
                                <MoreVertIcon />
                              </IconButton>
                            </Box>
                            
                            <ValueChip 
                              icon={<MonetizationOnIcon />}
                              label={`$${lead.value.toLocaleString()}`}
                              size="small"
                              sx={{ mb: 1 }}
                            />
                            
                            <Box sx={{ display: 'flex', gap: 1, mt: 2 }}>
                              <Tooltip title="Send email">
                                <IconButton size="small" sx={{ p: 0.5 }}>
                                  <EmailIcon fontSize="small" />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title="Call lead">
                                <IconButton size="small" sx={{ p: 0.5 }}>
                                  <PhoneIcon fontSize="small" />
                                </IconButton>
                              </Tooltip>
                            </Box>
                          </LeadCard>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </Box>
                )}
              </Droppable>
            </PipelineColumn>
          </Grid>
        ))}
      </Grid>
    </DragDropContext>
  );
};

export default LeadsPipeline; 