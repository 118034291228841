import React, { useState } from 'react';
import { Box, TextField, InputAdornment } from '@mui/material';
import { Search as SearchIcon } from '@mui/icons-material';

const SearchContacts = ({ onSearch }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <Box
      onMouseEnter={() => setIsExpanded(true)}
      onMouseLeave={() => setIsExpanded(false)}
      sx={{
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <TextField
        placeholder="Search contacts..."
        onChange={(e) => onSearch(e.target.value)}
        sx={{
          width: isExpanded ? 250 : 40,
          transition: 'width 0.3s ease-in-out',
          position: 'absolute',
          left: 0,
          '& .MuiOutlinedInput-root': {
            borderRadius: 20,
            backgroundColor: 'background.paper',
            pr: 2,
            pl: 2,
            height: 40,
          },
          '& .MuiOutlinedInput-input': {
            opacity: isExpanded ? 1 : 0,
            transition: 'opacity 0.2s ease-in-out',
            padding: '8px 0',
          },
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon color="action" sx={{ fontSize: 20 }} />
            </InputAdornment>
          ),
        }}
      />
    </Box>
  );
};

export default SearchContacts; 