import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Paper, 
  Typography, 
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Chip,
  CircularProgress,
  Alert,
  Tooltip,
  Grid
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import HistoryIcon from '@mui/icons-material/History';
import SearchIcon from '@mui/icons-material/Search';
import { getEstimates } from '../../../services/api';
import { styled } from '@mui/material/styles';
import styles from './EstimateHistory.module.scss';

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  '& .MuiTableCell-head': {
    fontWeight: 600,
    backgroundColor: theme.palette.background.default,
    fontFamily: 'Urbanist, sans-serif',
    color: theme.palette.text.primary,
  },
  '& .MuiTableCell-body': {
    fontFamily: 'Urbanist, sans-serif',
    color: theme.palette.text.secondary,
  },
  '& .MuiTableRow-root:hover': {
    backgroundColor: theme.palette.action.hover,
  }
}));

const calculateEstimateTotal = (items) => {
  if (!Array.isArray(items)) return 0;
  return items.reduce((sum, item) => {
    return sum + (Number(item.quantity) || 0) * (Number(item.unit_price) || 0);
  }, 0);
};

const getStatusColor = (status) => {
  const statusColors = {
    approved: 'success',
    pending: 'warning',
    draft: 'info',
    default: 'default'
  };
  return statusColors[status] || statusColors.default;
};

const EstimateHistoryView = ({ onBack }) => {
  const [estimates, setEstimates] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    const fetchEstimates = async () => {
      try {
        setLoading(true);
        const data = await getEstimates();
        setEstimates(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
    fetchEstimates();
  }, []);

  const filteredEstimates = estimates.filter(estimate => {
    const searchLower = searchTerm.toLowerCase();
    return (
      estimate.estimate_number?.toLowerCase().includes(searchLower) ||
      estimate.customer_details?.name?.toLowerCase().includes(searchLower) ||
      estimate.customer_details?.company?.toLowerCase().includes(searchLower)
    );
  });

  if (loading) return <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}><CircularProgress /></Box>;
  if (error) return <Box sx={{ p: 3 }}><Alert severity="error">Error loading estimates: {error}</Alert></Box>;

  return (
    <div className={styles.pageContainer}>
      <Box sx={{ mb: 4, display: 'flex', alignItems: 'center' }}>
        <IconButton onClick={onBack} sx={{ mr: 2 }}>
          <ArrowBackIcon />
        </IconButton>
        <HistoryIcon sx={{ mr: 2, color: 'primary.main' }} />
        <Typography variant="h5" sx={{ fontFamily: 'Urbanist, sans-serif', fontWeight: 600 }}>
          Estimate History
        </Typography>
      </Box>

      <Paper elevation={0} sx={{ p: 3, borderRadius: 2 }}>
        <Grid container justifyContent="space-between" alignItems="center" mb={4}>
          <Grid item>
            <Box className={styles.searchContainer}>
              <SearchIcon className={styles.searchIcon} />
              <input
                type="text"
                placeholder="Search Estimates"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className={styles.searchInput}
              />
            </Box>
          </Grid>
        </Grid>

        <StyledTableContainer>
          <Table>
            <TableHead>
              <TableRow className={styles.tableHeader}>
                <TableCell>Estimate #</TableCell>
                <TableCell>Client</TableCell>
                <TableCell>Issue Date</TableCell>
                <TableCell align="right">Amount</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredEstimates.map((estimate) => (
                <TableRow key={estimate.id} hover className={styles.tableRow}>
                  <TableCell>{estimate.estimate_number}</TableCell>
                  <TableCell>
                    <Tooltip title={estimate.customer_details?.company || ''}>
                      <span>{estimate.customer_details?.name || 'N/A'}</span>
                    </Tooltip>
                  </TableCell>
                  <TableCell>
                    {new Date(estimate.issue_date).toLocaleDateString('en-US', {
                      year: 'numeric',
                      month: 'short',
                      day: 'numeric'
                    })}
                  </TableCell>
                  <TableCell align="right">
                    {new Intl.NumberFormat('en-US', {
                      style: 'currency',
                      currency: 'USD'
                    }).format(calculateEstimateTotal(estimate.items))}
                  </TableCell>
                  <TableCell>
                    <Chip 
                      label={estimate.status.charAt(0).toUpperCase() + estimate.status.slice(1)} 
                      color={getStatusColor(estimate.status)} 
                      size="small"
                      sx={{ 
                        minWidth: 80,
                        fontWeight: 500
                      }}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </StyledTableContainer>
      </Paper>
    </div>
  );
};

export default EstimateHistoryView;