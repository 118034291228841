import React from 'react';
import { Grid, Typography, FormControl, InputLabel, Select, MenuItem, FormControlLabel, Switch } from '@mui/material';
import AppSettingsAltIcon from '@mui/icons-material/AppSettingsAlt';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { useTheme } from '../ThemeContext';
import styles from './AdminSettings.module.scss';

const timeZones = [
  'UTC-12:00', 'UTC-11:00', 'UTC-10:00', 'UTC-09:00', 'UTC-08:00', 'UTC-07:00', 'UTC-06:00', 'UTC-05:00', 
  'UTC-04:00', 'UTC-03:00', 'UTC-02:00', 'UTC-01:00', 'UTC+00:00', 'UTC+01:00', 'UTC+02:00', 'UTC+03:00', 
  'UTC+04:00', 'UTC+05:00', 'UTC+06:00', 'UTC+07:00', 'UTC+08:00', 'UTC+09:00', 'UTC+10:00', 'UTC+11:00', 
  'UTC+12:00', 'UTC+13:00', 'UTC+14:00'
];

function AdminSettingsAppSettings() {
  const { darkMode, toggleDarkMode } = useTheme();

  const inputStyles = {
    '& .MuiInputLabel-root': {
      color: darkMode ? 'var(--text-secondary)' : 'inherit'
    },
    '& .MuiOutlinedInput-root': {
      backgroundColor: darkMode ? 'var(--input-background)' : 'inherit',
      color: darkMode ? 'var(--text-primary)' : 'inherit',
      '& fieldset': {
        borderColor: darkMode ? 'var(--border-color)' : 'inherit'
      },
      '&:hover fieldset': {
        borderColor: darkMode ? 'var(--text-secondary)' : 'inherit'
      },
      '&.Mui-focused fieldset': {
        borderColor: darkMode ? '#90caf9' : '#1976d2'
      }
    },
    '& .MuiInputBase-input': {
      color: darkMode ? 'var(--text-primary)' : 'inherit'
    }
  };

  return (
    <div className={`${styles.settingsSection} ${darkMode ? styles.darkMode : ''}`}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography 
            variant="h6" 
            className={`${styles.sectionTitle} ${darkMode ? styles.darkMode : ''}`}
          >
            <AppSettingsAltIcon className={`${styles.sectionIcon} ${darkMode ? styles.darkMode : ''}`} />
            Application Settings
          </Typography>
        </Grid>

        <Grid item xs={12} md={6}>
          <FormControl 
            fullWidth 
            className={`${styles.formGroup} ${darkMode ? styles.darkMode : ''}`}
          >
            <InputLabel sx={inputStyles['& .MuiInputLabel-root']}>Theme</InputLabel>
            <Select
              value={darkMode ? "dark" : "light"}
              onChange={(e) => toggleDarkMode(e.target.value === "dark")}
              label="Theme"
              sx={{
                ...inputStyles['& .MuiOutlinedInput-root'],
                '& .MuiSvgIcon-root': {
                  color: darkMode ? 'var(--text-secondary)' : 'inherit'
                }
              }}
            >
              <MenuItem value="light">Light</MenuItem>
              <MenuItem value="dark">Dark</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} md={6}>
          <FormControl 
            fullWidth 
            className={`${styles.formGroup} ${darkMode ? styles.darkMode : ''}`}
          >
            <InputLabel sx={inputStyles['& .MuiInputLabel-root']}>Time Zone</InputLabel>
            <Select
              defaultValue="UTC+00:00"
              label="Time Zone"
              sx={{
                ...inputStyles['& .MuiOutlinedInput-root'],
                '& .MuiSvgIcon-root': {
                  color: darkMode ? 'var(--text-secondary)' : 'inherit'
                }
              }}
            >
              {timeZones.map((zone) => (
                <MenuItem key={zone} value={zone}>{zone}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Switch 
                checked={true}
                onChange={() => {}}
                className={styles.switch}
                sx={{
                  '& .MuiSwitch-track': {
                    backgroundColor: darkMode ? 'var(--text-disabled)' : 'inherit'
                  },
                  '& .Mui-checked': {
                    '& + .MuiSwitch-track': {
                      backgroundColor: darkMode ? '#90caf9 !important' : 'inherit'
                    }
                  }
                }}
              />
            }
            label="Enable Notifications"
            className={styles.switchLabel}
            sx={{
              color: darkMode ? 'var(--text-primary)' : 'inherit'
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
}

export default AdminSettingsAppSettings;