import React from 'react';
import {
  Card,
  CardContent,
  Typography,
  Box,
  IconButton,
  Chip,
  LinearProgress,
} from '@mui/material';
import {
  MoreVert as MoreIcon,
  Email as EmailIcon,
  Sms as SmsIcon,
  Phone as PhoneIcon,
  WhatsApp as WhatsAppIcon,
} from '@mui/icons-material';

const CampaignCard = ({ campaign, onMenuClick }) => {
  const getTypeIcon = (type) => {
    switch (type.toLowerCase()) {
      case 'email':
        return <EmailIcon fontSize="small" />;
      case 'sms':
        return <SmsIcon fontSize="small" />;
      case 'call':
        return <PhoneIcon fontSize="small" />;
      case 'whatsapp':
        return <WhatsAppIcon fontSize="small" />;
      default:
        return <EmailIcon fontSize="small" />;
    }
  };

  const getStatusColor = (status) => {
    switch (status.toLowerCase()) {
      case 'active':
        return {
          bg: 'success.light',
          color: 'success.dark'
        };
      case 'scheduled':
        return {
          bg: 'info.light',
          color: 'info.dark'
        };
      case 'draft':
        return {
          bg: 'grey.200',
          color: 'grey.700'
        };
      case 'paused':
        return {
          bg: 'warning.light',
          color: 'warning.dark'
        };
      default:
        return {
          bg: 'grey.200',
          color: 'grey.700'
        };
    }
  };

  return (
    <Card
      sx={{
        height: '100%',
        borderRadius: 2,
        transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
        '&:hover': {
          transform: 'translateY(-4px)',
          boxShadow: (theme) => theme.shadows[4],
        },
      }}
    >
      <CardContent>
        {/* Header */}
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Chip
              icon={getTypeIcon(campaign.type)}
              label={campaign.type}
              size="small"
              sx={{
                backgroundColor: 'grey.100',
                '& .MuiChip-icon': { color: 'primary.main' },
              }}
            />
            <Chip
              label={campaign.status}
              size="small"
              sx={{
                backgroundColor: getStatusColor(campaign.status).bg,
                color: getStatusColor(campaign.status).color,
              }}
            />
          </Box>
          <IconButton 
            size="small" 
            onClick={(event) => onMenuClick(event, campaign)}
            sx={{ ml: 1 }}
          >
            <MoreIcon />
          </IconButton>
        </Box>

        {/* Content */}
        <Typography variant="h6" sx={{ mb: 1, fontWeight: 600 }}>
          {campaign.name}
        </Typography>
        <Typography 
          variant="body2" 
          color="text.secondary" 
          sx={{ mb: 2, minHeight: '40px' }}
        >
          {campaign.description}
        </Typography>

        {/* Progress */}
        <Box sx={{ mb: 2 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
            <Typography variant="caption" color="text.secondary">
              Progress
            </Typography>
            <Typography variant="caption" color="text.secondary">
              {campaign.progress}%
            </Typography>
          </Box>
          <LinearProgress
            variant="determinate"
            value={campaign.progress}
            sx={{
              height: 6,
              borderRadius: 3,
              backgroundColor: 'grey.100',
              '& .MuiLinearProgress-bar': {
                borderRadius: 3,
                backgroundImage: 'linear-gradient(45deg, #2196f3 30%, #21cbf3 90%)',
              }
            }}
          />
        </Box>

        {/* Footer */}
        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'space-between',
          mt: 2,
          pt: 2,
          borderTop: '1px solid',
          borderColor: 'grey.100'
        }}>
          <Box>
            <Typography variant="caption" color="text.secondary">
              Start Date
            </Typography>
            <Typography variant="body2">
              {campaign.startDate}
            </Typography>
          </Box>
          <Box sx={{ textAlign: 'right' }}>
            <Typography variant="caption" color="text.secondary">
              Audience
            </Typography>
            <Typography variant="body2">
              {campaign.audience.toLocaleString()} contacts
            </Typography>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default CampaignCard; 