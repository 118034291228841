import React, { useState } from 'react';
import { 
  Box, 
  Paper, 
  TextField, 
  Button, 
  IconButton, 
  Typography 
} from '@mui/material';
import { 
  Close as CloseIcon,
  Send as SendIcon,
  AttachFile as AttachFileIcon
} from '@mui/icons-material';
import { styled } from '@mui/material/styles';

const StyledComposeContainer = styled(Paper)(({ theme }) => ({
  flex: 2,
  height: '100%',
  borderRadius: 12,
  display: 'flex',
  flexDirection: 'column',
  border: `1px solid ${theme.palette.divider}`,
  backgroundColor: theme.palette.background.default
}));

const EmailCompose = ({ onClose }) => {
  const [subject, setSubject] = useState('');
  const [content, setContent] = useState('');

  const handleSend = () => {
    // Add email sending logic
    onClose();
  };

  return (
    <StyledComposeContainer>
      <Box sx={{ 
        p: 2, 
        borderBottom: 1, 
        borderColor: 'divider',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
      }}>
        <Typography variant="h6">New Message</Typography>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Box sx={{ p: 2, display: 'flex', flexDirection: 'column', gap: 2, flex: 1 }}>
        <TextField
          fullWidth
          placeholder="Subject"
          value={subject}
          onChange={(e) => setSubject(e.target.value)}
          variant="outlined"
          size="small"
        />
        <TextField
          fullWidth
          multiline
          rows={15}
          placeholder="Write your message here..."
          value={content}
          onChange={(e) => setContent(e.target.value)}
          variant="outlined"
          sx={{ flex: 1 }}
        />
      </Box>
      <Box sx={{ 
        p: 2, 
        borderTop: 1, 
        borderColor: 'divider',
        display: 'flex',
        justifyContent: 'space-between'
      }}>
        <IconButton>
          <AttachFileIcon />
        </IconButton>
        <Button
          variant="contained"
          endIcon={<SendIcon />}
          onClick={handleSend}
          sx={{ 
            textTransform: 'none',
            fontFamily: 'Urbanist, sans-serif'
          }}
        >
          Send
        </Button>
      </Box>
    </StyledComposeContainer>
  );
};

export default EmailCompose; 