import React, { useState, useEffect } from 'react';
import { Box, Button, Typography, CircularProgress, Menu, MenuItem, ListItemIcon, ListItemText, Snackbar, Alert, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Checkbox, Chip, IconButton, Grid, Avatar, Divider, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import DefaultTemplate from '../templates/DefaultTemplate';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import EditIcon from '@mui/icons-material/Edit';
import ShareIcon from '@mui/icons-material/Share';
import DownloadIcon from '@mui/icons-material/Download';
import EmailIcon from '@mui/icons-material/Email';
import WorkIcon from '@mui/icons-material/Work';
import PersonIcon from '@mui/icons-material/Person';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CancelIcon from '@mui/icons-material/Cancel';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ReceiptIcon from '@mui/icons-material/Receipt';
import PhoneIcon from '@mui/icons-material/Phone';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import AssignmentIcon from '@mui/icons-material/Assignment';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { getEstimate, createProject, createEvent, getPublicEstimate, convertEstimateToProject } from '../services/api';
import api from '../services/api';
import PropertyDetails from './PropertyDetails';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import PreviewIcon from '@mui/icons-material/Preview';
import TagsSection from './TagsSection';
import BuildIcon from '@mui/icons-material/Build';
import html2pdf from 'html2pdf.js';
import { PDFDownloadLink } from '@react-pdf/renderer';
import PDFTemplate from '../templates/PDFTemplate';
import CloseIcon from '@mui/icons-material/Close';
import { formatDateTime } from '../utils/dateUtils';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

const getPrice = (item) => {
  return Number(item?.unit_price || item?.unitPrice || 0);
};

const getQuantity = (item) => {
  return Number(item?.quantity || 0);
};

const calculateItemTotal = (item) => {
  const price = getPrice(item);
  const quantity = getQuantity(item);
  return (price * quantity);
};

const getDisplayAddress = (estimate) => {
  if (estimate?.service_address) {
    return {
      address: estimate.service_address,
      city: estimate.service_city,
      state: estimate.service_state,
      zip: estimate.service_zip
    };
  }
  return {
    address: estimate?.customer_details?.address,
    city: estimate?.customer_details?.city,
    state: estimate?.customer_details?.state,
    zip: estimate?.customer_details?.zip_code
  };
};

const calculateTotal = (estimate) => {
  return estimate?.items?.reduce((total, item) => {
    const itemTotal = item.total || (Number(item.unitPrice || 0) * Number(item.quantity || 0));
    return total + itemTotal;
  }, 0) || 0;
};

const AdminEstimateDetails = () => {
  const menuItemStyle = {
    py: 1.5,
    px: 2,
    mx: 1,
    borderRadius: '8px',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
    '& .MuiListItemIcon-root': {
      minWidth: '40px'
    }
  };

  const { id } = useParams();
  const navigate = useNavigate();
  const [estimate, setEstimate] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');
  const [showSuccess, setShowSuccess] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [showTimeSlotModal, setShowTimeSlotModal] = useState(false);
  const [convertedEvents, setConvertedEvents] = useState([]);
  const [timeSlots, setTimeSlots] = useState([]);
  const [showItemSelectionModal, setShowItemSelectionModal] = useState(false);
  const [selectedGroups, setSelectedGroups] = useState({});
  const [groupCounter, setGroupCounter] = useState(1);
  const [includedItems, setIncludedItems] = useState([]);
  const [internalNotes, setInternalNotes] = useState('');
  const [editingNoteIndex, setEditingNoteIndex] = useState(null);
  const [editingNoteContent, setEditingNoteContent] = useState('');
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [showSignatureModal, setShowSignatureModal] = useState(false);
  const [signatureLink, setSignatureLink] = useState('');
  const [isConverting, setIsConverting] = useState(false);
  const [showProjectTypeModal, setShowProjectTypeModal] = useState(false);
  const [projectType, setProjectType] = useState('single_day');
  const [conversionTimeSlot, setConversionTimeSlot] = useState({
    start_time: '',
    end_time: ''
  });
  const [scheduleNow, setScheduleNow] = useState(true);
  const [showUnsignedWarning, setShowUnsignedWarning] = useState(false);

  const handleClose = () => setAnchorEl(null);

  const handleShare = async () => {
    let publicLink = '';
    
    try {
      console.log('Starting share process...');
      const response = await api.post(`/estimates/${id}/generate-link`);
      console.log('API Response:', response.data);
      
      const publicUuid = response.data.public_uuid;
      if (!publicUuid) {
        throw new Error('No public UUID received');
      }

      publicLink = `${window.location.origin}/public/estimate/${publicUuid}`;
      console.log('Link to copy:', publicLink);

      // Try multiple clipboard methods
      try {
        // Method 1: Clipboard API
        await navigator.clipboard.writeText(publicLink);
      } catch (clipboardError) {
        console.log('Clipboard API failed, trying fallback method');
        
        // Method 2: execCommand with input element
        const input = document.createElement('input');
        input.value = publicLink;
        document.body.appendChild(input);
        input.select();
        input.setSelectionRange(0, 99999);
        
        const success = document.execCommand('copy');
        document.body.removeChild(input);
        
        if (!success) {
          throw new Error('Clipboard copy failed');
        }
      }

      console.log('Copy successful');
      setSuccessMessage('Link copied to clipboard! ⚡');
      setShowSuccess(true);
      handleClose();
      
    } catch (error) {
      console.error('Share error:', error);
      if (publicLink) {
        const userChoice = window.confirm(
          'Failed to copy automatically. Click OK to copy the link manually:\n' + publicLink
        );
        if (userChoice) {
          const input = document.createElement('input');
          input.value = publicLink;
          document.body.appendChild(input);
          input.select();
          input.setSelectionRange(0, 99999);
          try {
            document.execCommand('copy');
            setSuccessMessage('Link copied to clipboard! ⚡');
            setShowSuccess(true);
          } catch (e) {
            alert(`Please copy this link manually:\n${publicLink}`);
          }
          document.body.removeChild(input);
        }
      }
      setError('Failed to copy to clipboard');
    }
  };

  const handleGenerateBookingLink = async () => {
    try {
      const response = await api.post(`/estimates/${id}/generate-booking-link`);
      
      if (!response.data.booking_uuid) {
        throw new Error('No booking UUID received');
      }

      const bookingLink = `${window.location.origin}/estimate/book/${response.data.booking_uuid}`;
      
      // Create temporary input element
      const tempInput = document.createElement('input');
      tempInput.style.position = 'absolute';
      tempInput.style.left = '-9999px';
      tempInput.value = bookingLink;
      document.body.appendChild(tempInput);

      // Select and copy
      tempInput.select();
      tempInput.setSelectionRange(0, 99999); // For mobile devices
      
      try {
        document.execCommand('copy');
        setSuccessMessage('Booking link copied to clipboard! ⚡');
        setShowSuccess(true);
      } catch (err) {
        console.error('Fallback copy failed:', err);
        setError('Failed to copy link');
      } finally {
        document.body.removeChild(tempInput);
      }
      
      handleClose();
    } catch (error) {
      console.error('Error generating booking link:', error);
      setError('Failed to generate booking link');
    }
  };

  const handleDownloadClick = () => {
    setAnchorEl(null);
  };

  const handleEmailClient = () => {
    // Your email logic
    handleClose();
  };

  const handleConvertToProject = () => {
    if (!estimate?.client_signature) {
      setShowUnsignedWarning(true);
    } else {
      setShowItemSelectionModal(true);
    }
    handleClose();
  };

  const handleProjectTypeSubmit = async () => {
    try {
      setIsConverting(true);

      // Format the scope of work to include internal notes
      let combinedScope = estimate.scope_of_work || '';
      if (estimate.internal_notes) {
        try {
          const notes = JSON.parse(estimate.internal_notes);
          if (Array.isArray(notes)) {
            const formattedNotes = notes.map(note => 
              `- ${note.content}`
            ).join('\n');
            combinedScope = combinedScope ? 
              `${combinedScope}\n\nInternal Notes:\n${formattedNotes}` : 
              `Internal Notes:\n${formattedNotes}`;
          }
        } catch (e) {
          combinedScope = combinedScope ? 
            `${combinedScope}\n\nInternal Notes:\n${estimate.internal_notes}` : 
            `Internal Notes:\n${estimate.internal_notes}`;
        }
      }

      // Process grouped items
      let processedItems = [];
      if (Object.keys(selectedGroups).length > 0) {
        // Handle grouped items
        Object.entries(selectedGroups).forEach(([groupId, items]) => {
          // Calculate total value for the group
          const groupTotal = items.reduce((sum, item) => {
            const itemTotal = item.total || (Number(item.unitPrice || 0) * Number(item.quantity || 0));
            return sum + itemTotal;
          }, 0);

          // Combine item titles for the event title
          const combinedTitle = items.map(item => item.title).join(' + ');

          // Combine items in each group into a single item
          const combinedItem = {
            title: combinedTitle,
            description: items.map(item => `${item.title}: ${item.description || 'No description'}`).join('\n'),
            total: groupTotal,
            quantity: 1,
            unitPrice: groupTotal,
            isGroup: true,
            originalItems: items
          };
          processedItems.push(combinedItem);
        });
      }

      // Add ungrouped items
      const ungroupedItems = estimate.items.filter(item => 
        includedItems.includes(item.title) && 
        !Object.values(selectedGroups).flat().some(groupItem => 
          groupItem.title === item.title
        )
      );
      processedItems = [...processedItems, ...ungroupedItems];

      const conversionData = {
        project_type: projectType,
        items: processedItems, // Send modified items array instead of original estimate.items
        schedule_now: scheduleNow,
        scope_of_work: combinedScope,
        time_slots: (projectType === 'single_day' && scheduleNow) ? {
          start_time: conversionTimeSlot.start_time,
          end_time: conversionTimeSlot.end_time
        } : null
      };

      console.log('Converting estimate with data:', conversionData);
      const response = await convertEstimateToProject(estimate.id, conversionData);

      if (response.success) {
        setSuccessMessage('Estimate converted to project successfully!');
        setShowSuccess(true);
        navigate(`/admin/projects/${response.project_id}`);
      }
    } catch (error) {
      console.error('Error converting to project:', error);
      setError(error.message || 'Failed to convert estimate to project');
    } finally {
      setIsConverting(false);
      setShowProjectTypeModal(false);
    }
  };

  const handleItemSelection = (item) => {
    setIncludedItems(prev => {
      const isIncluded = prev.includes(item.title);
      if (isIncluded) {
        // Remove item from any group it's in
        const updatedGroups = { ...selectedGroups };
        Object.keys(updatedGroups).forEach(groupId => {
          updatedGroups[groupId] = updatedGroups[groupId].filter(i => i.title !== item.title);
        });
        setSelectedGroups(updatedGroups);
        return prev.filter(i => i !== item.title);
      }
      return [...prev, item.title];
    });
  };

  const handleGroupItems = (items) => {
    const groupId = `group-${groupCounter}`;
    setSelectedGroups(prev => ({
      ...prev,
      [groupId]: items
    }));
    setGroupCounter(prev => prev + 1);
  };

  const handleRemoveFromGroup = (groupId, itemTitle) => {
    setSelectedGroups(prev => ({
      ...prev,
      [groupId]: prev[groupId].filter(item => item.title !== itemTitle)
    }));
  };

  const handleRemoveGroup = (groupId) => {
    setSelectedGroups(prev => {
      const { [groupId]: removed, ...rest } = prev;
      return rest;
    });
  };

  const handleItemSelectionConfirm = () => {
    setShowItemSelectionModal(false);
    setShowProjectTypeModal(true);
  };

  const handleTimeSlotSubmit = async () => {
    try {
      console.log('Selected items:', includedItems);
      console.log('Time slots before submission:', timeSlots);

      // Format time slots for API
      const formattedTimeSlots = {};
      convertedEvents.forEach((event, index) => {
        // Only include if we have both start and end times
        if (timeSlots[index] && timeSlots[index].start_time && timeSlots[index].end_time) {
          formattedTimeSlots[event.title] = {
            start_time: new Date(timeSlots[index].start_time).toISOString(),
            end_time: new Date(timeSlots[index].end_time).toISOString()
          };
        }
      });

      console.log('Formatted time slots:', formattedTimeSlots);

      const response = await convertEstimateToProject(estimate.id, {
        selected_items: includedItems,
        time_slots: formattedTimeSlots
      });

      if (response.success) {
        setSuccessMessage('Project created successfully with scheduled events! ⚡');
        setShowSuccess(true);
        navigate(`/admin/projects/${response.project_id}`);
      }

      setShowTimeSlotModal(false);

    } catch (error) {
      console.error('Error converting to project:', error);
      setError(error.message || 'Failed to convert estimate to project');
    }
  };

  const handleTimeSlotChange = (field, value) => {
    // Store the raw datetime-local value without any conversion
    setConversionTimeSlot(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const handleClientNavigation = () => {
    if (estimate?.customer_details?.id) {
      navigate(`/admin/clients/${estimate.customer_details.id}`);
    }
    handleClose();
  };

  const handleNotesChange = async (event) => {
    const newNotes = event.target.value;
    setInternalNotes(newNotes);
    try {
      await api.patch(`/estimates/${id}`, { notes: newNotes });
    } catch (error) {
      console.error('Error saving notes:', error);
      setError('Failed to save notes');
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (!id) return;
      
      try {
        setLoading(true);
        const response = await getEstimate(id);
        const data = response.data;
        
        console.log('Raw estimate data:', data);
        console.log('Project name from data:', data.project_name);

        const formattedEstimate = {
          id: data.id,
          ...data,
          business_details: data.business_details || {},
          customer_details: data.customer_details || {},
          items: data.items || [],
          project_images: data.project_images || [],
          project_name: data.project_name || '',
          ...(data.scope_of_work?.trim() && { scope_of_work: data.scope_of_work }),
          ...(data.notes?.trim() && { notes: data.notes }),
          ...(data.terms?.trim() && { terms: data.terms }),
          service_address: data.service_address || '',
          service_city: data.service_city || '',
          service_state: data.service_state || '',
          service_zip: data.service_zip || '',
          use_client_address: data.use_client_address ?? true,
          showAdditionalInfo: true
        };

        console.log('Formatted estimate:', formattedEstimate);
        
        setEstimate(formattedEstimate);
        setError(null);
      } catch (err) {
        console.error('FETCH ERROR:', err);
        setError(err.message || 'Failed to fetch estimate');
        setEstimate(null);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [id]);

  useEffect(() => {
    console.log('Current State:', {
      loading,
      error,
      estimate: estimate ? 'Has Data' : 'No Data',
      id
    });
  }, [loading, error, estimate, id]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (estimate) {
      // Remove this line as we don't want to set the input value
      // setInternalNotes(estimate.internal_notes || '');
    }
  }, [estimate]);

  const refreshEstimate = async () => {
    if (!id) return;
    
    try {
      setLoading(true);
      const response = await getEstimate(id);
      const data = response.data;
      
      const formattedEstimate = {
        id: data.id,
        ...data,
        business_details: data.business_details || {},
        customer_details: data.customer_details || {},
        items: data.items || [],
        project_images: data.project_images || [],
        project_name: data.project_name || '',
        ...(data.scope_of_work?.trim() && { scope_of_work: data.scope_of_work }),
        ...(data.notes?.trim() && { notes: data.notes }),
        ...(data.terms?.trim() && { terms: data.terms }),
        service_address: data.service_address || '',
        service_city: data.service_city || '',
        service_state: data.service_state || '',
        service_zip: data.service_zip || '',
        use_client_address: data.use_client_address ?? true,
        showAdditionalInfo: true
      };
      
      setEstimate(formattedEstimate);
      setError(null);
    } catch (err) {
      console.error('REFRESH ERROR:', err);
      setError(err.message || 'Failed to refresh estimate');
    } finally {
      setLoading(false);
    }
  };

  const handleSaveInternalNotes = async () => {
    if (!internalNotes.trim() || !id) return;
    
    try {
      let currentNotes = [];
      
      if (estimate.internal_notes) {
        try {
          currentNotes = JSON.parse(estimate.internal_notes);
          if (!Array.isArray(currentNotes)) {
            currentNotes = [{
              content: estimate.internal_notes,
              timestamp: new Date().toISOString(),
              user: 'Legacy'
            }];
          }
        } catch (e) {
          if (estimate.internal_notes.trim()) {
            currentNotes = [{
              content: estimate.internal_notes,
              timestamp: new Date().toISOString(),
              user: 'Legacy'
            }];
          }
        }
      }

      currentNotes.push({
        content: internalNotes,
        timestamp: new Date().toISOString(),
        user: 'Admin'
      });

      await api.patch(`/estimates/${id}/internal-notes`, {
        internal_notes: JSON.stringify(currentNotes)
      });
      
      setInternalNotes('');
      setSuccessMessage('Note added successfully');
      setShowSuccess(true);
      
      await refreshEstimate();
      
    } catch (error) {
      console.error('Error saving internal notes:', error);
      setError('Failed to save note');
    }
  };

  const handleEditNote = async (index, updatedContent) => {
    try {
      let currentNotes = JSON.parse(estimate.internal_notes);
      if (!Array.isArray(currentNotes)) {
        currentNotes = [currentNotes];
      }
      
      // Update the specific note's content while preserving timestamp and user
      currentNotes[currentNotes.length - 1 - index] = {
        ...currentNotes[currentNotes.length - 1 - index],
        content: updatedContent,
        edited: true,
        editedAt: new Date().toISOString()
      };

      const response = await api.patch(`/estimates/${id}/internal-notes`, {
        internal_notes: JSON.stringify(currentNotes)
      });
      
      setEstimate(prev => ({
        ...prev,
        internal_notes: JSON.stringify(response.data.internal_notes)
      }));
      
      setEditingNoteIndex(null);
      setEditingNoteContent('');
      setSuccessMessage('Note updated successfully');
      setShowSuccess(true);
    } catch (error) {
      console.error('Error updating note:', error);
      setError('Failed to update note');
    }
  };

  const handlePreviewView = () => {
    setShowPreviewModal(true);
    handleClose();
  };

  const handleCollectSignature = async () => {
    setShowSignatureModal(true);
    handleClose();
    
    try {
      console.log('Starting signature link generation...');
      const response = await api.post(`/estimates/${id}/generate-link`);
      console.log('API Response:', response.data);
      
      const publicUuid = response.data.public_uuid;
      if (!publicUuid) {
        throw new Error('No public UUID received');
      }

      const link = `${window.location.origin}/public/estimate/${publicUuid}`;
      setSignatureLink(link);
      
    } catch (error) {
      console.error('Signature link generation error:', error);
      setError('Failed to generate signature link');
      setShowSignatureModal(false);
    }
  };

  const handleCopyLink = async () => {
    try {
      await navigator.clipboard.writeText(signatureLink);
      setSuccessMessage('Link copied to clipboard! ⚡');
      setShowSuccess(true);
      setShowSignatureModal(false);
    } catch (error) {
      console.error('Copy error:', error);
      setError('Failed to copy link');
    }
  };

  const handleOpenLink = () => {
    window.open(signatureLink, '_blank');
    setShowSignatureModal(false);
  };

  const handleEditEstimate = () => {
    handleClose();
    if (estimate?.id) {
      const editPath = `/admin/estimates/edit/${estimate.id}`;
      console.log('Navigating to:', editPath);
      navigate(editPath);
    } else {
      console.error('No estimate ID found');
      setError('Cannot edit estimate: ID not found');
    }
  };

  const renderPDFDownload = () => {
    // Force PDF regeneration by using a key
    const timestamp = new Date().getTime();
    
    return (
      <PDFDownloadLink
        key={timestamp}  // Add this to force regeneration
        document={
          <PDFTemplate 
            estimate={estimate} 
            key={timestamp}  // Add this too
          />
        }
        fileName={`estimate-${estimate?.estimate_number || 'draft'}.pdf`}
        style={{ textDecoration: 'none', color: 'inherit' }}
      >
        {({ blob, url, loading, error }) => {
          console.log('PDF Generation status:', { loading, error }); // Debug log
          return loading ? 'Generating PDF...' : 'Download PDF';
        }}
      </PDFDownloadLink>
    );
  };

  const handleUnsignedWarningResponse = (proceed) => {
    setShowUnsignedWarning(false);
    if (proceed) {
      setShowItemSelectionModal(true);
    }
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ p: 3, textAlign: 'center' }}>
        <Typography color="error">{error}</Typography>
      </Box>
    );
  }

  if (!estimate) {
    return (
      <Box sx={{ p: 3, textAlign: 'center' }}>
        <Typography>No estimate found</Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ 
      maxWidth: '1200px', 
      margin: '0 auto',
      position: 'relative',
      overflow: 'hidden'
    }}>
      <Box sx={{ 
        display: 'flex', 
        alignItems: 'center',
        p: 2,
        borderBottom: '1px solid #eee'
      }}>
        <Button
          startIcon={<ArrowBackIcon />}
          onClick={() => navigate('/admin/estimates')}
          sx={{ 
            color: 'text.secondary',
            '&:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.04)'
            }
          }}
        >
          Back to Estimates
        </Button>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 2 }}>
        <Button
          variant="contained"
          onClick={(event) => setAnchorEl(event.currentTarget)}
          endIcon={<KeyboardArrowDownIcon />}
          sx={{
            backgroundColor: '#1976d2',
            '&:hover': {
              backgroundColor: '#1565c0'
            },
            boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
            borderRadius: '8px',
            textTransform: 'none',
            fontSize: '1rem'
          }}
        >
          Actions ⚡
        </Button>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          PaperProps={{
            sx: {
              mt: 1,
              boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
              borderRadius: '8px'
            }
          }}
        >
          <MenuItem 
            onClick={handleEditEstimate}
            sx={{ 
              color: 'primary.main',
              gap: 1,
              py: 1.5,
              '&:hover': {
                backgroundColor: 'rgba(25, 118, 210, 0.08)'
              }
            }}
          >
            <EditIcon fontSize="small" />
            Edit Estimate
          </MenuItem>

          <Divider />

          <MenuItem onClick={handlePreviewView} sx={menuItemStyle}>
            <ListItemIcon><VisibilityIcon /></ListItemIcon>
            <ListItemText>See Client View</ListItemText>
          </MenuItem>
          <MenuItem 
            onClick={() => {
              console.log('Download clicked, estimate data:', estimate);
              handleClose();
            }}
            sx={menuItemStyle}
          >
            <ListItemIcon>
              <DownloadIcon />
            </ListItemIcon>
            <PDFDownloadLink
              document={<PDFTemplate estimate={estimate} />}
              fileName={`estimate-${estimate.estimate_number}.pdf`}
              style={{ textDecoration: 'none', color: 'inherit' }}
            >
              {({ loading }) => loading ? 'Generating PDF...' : 'Download PDF'}
            </PDFDownloadLink>
          </MenuItem>
          <MenuItem onClick={handleGenerateBookingLink} sx={menuItemStyle}>
            <ListItemIcon><CalendarMonthIcon /></ListItemIcon>
            <ListItemText>Generate Booking Link</ListItemText>
          </MenuItem>
          <MenuItem onClick={handleEmailClient} sx={menuItemStyle}>
            <ListItemIcon><EmailIcon /></ListItemIcon>
            <ListItemText>Email Client</ListItemText>
          </MenuItem>
          <MenuItem 
            onClick={handleConvertToProject}
            sx={menuItemStyle}
          >
            <ListItemIcon>
              <WorkIcon />
            </ListItemIcon>
            <ListItemText>Convert to Project</ListItemText>
          </MenuItem>
          <MenuItem onClick={handleClientNavigation} sx={menuItemStyle}>
            <ListItemIcon><PersonIcon /></ListItemIcon>
            <ListItemText>View Client</ListItemText>
          </MenuItem>
          <MenuItem onClick={handleCollectSignature} sx={menuItemStyle}>
            <ListItemIcon><ShareIcon /></ListItemIcon>
            <ListItemText>Collect Signature</ListItemText>
          </MenuItem>
        </Menu>
      </Box>

      <Grid container spacing={3} sx={{ p: 2 }}>
        {/* First row with customer info and property details */}
        <Grid item xs={12} md={4}>
          <Box sx={{ 
            p: 3,
            backgroundColor: '#fff',
            borderRadius: '12px',
            boxShadow: '0 2px 8px rgba(0,0,0,0.05)',
          }}>
            {/* Header with Avatar */}
            <Box sx={{ 
              display: 'flex', 
              alignItems: 'center',
              mb: 3,
              gap: 2
            }}>
              <Avatar 
                sx={{ 
                  width: 56, 
                  height: 56, 
                  bgcolor: '#3498db',
                  fontSize: '1.5rem',
                  fontWeight: 500
                }}
              >
                {estimate?.customer_details?.name?.split(' ').map(n => n[0]).join('').toUpperCase() || '?'}
              </Avatar>
              <Box>
                <Typography variant="h6" sx={{ 
                  color: '#2c3e50',
                  fontWeight: 600,
                  mb: 0.5
                }}>
                  {estimate?.customer_details?.name || 'No Name'}
                </Typography>
                <Typography variant="body2" sx={{ 
                  color: 'text.secondary',
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1
                }}>
                  <ReceiptIcon sx={{ fontSize: '1rem' }} />
                  Estimate #{estimate?.estimate_number}
                </Typography>
                <Typography variant="body2" sx={{ 
                  color: 'text.secondary',
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1
                }}>
                  <BuildIcon sx={{ fontSize: '1rem' }} />
                  Project Name: {estimate?.project_name}
                </Typography>
                <Typography variant="body2" sx={{ 
                  color: estimate?.client_signature ? '#2ecc71' : '#e74c3c',
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1
                }}>
                  {estimate?.client_signature ? 'Signed by Client' : 'Not Signed by Client'}
                </Typography>
                {estimate?.client_signed_at && (
                  <Typography variant="body2" sx={{ 
                    color: 'text.secondary',
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1
                  }}>
                    Signed at: {new Date(estimate.client_signed_at).toLocaleString()}
                  </Typography>
                )}
              </Box>
            </Box>



            {/* Contact Details */}
            <Box sx={{ 
              display: 'flex',
              flexDirection: 'column',
              gap: 2
            }}>
              {/* Status and Total Value Section */}
              <Box sx={{ 
                display: 'flex',
                gap: 2,
              }}>
                {/* Status */}
                <Box sx={{ 
                  display: 'flex',
                  alignItems: 'center',
                  gap: 2,
                  p: 2,
                  backgroundColor: '#f8f9fa',
                  borderRadius: '8px',
                  flex: 1,
                  '&:hover': {
                    backgroundColor: '#f0f2f5'
                  }
                }}>
                  <AssignmentIcon sx={{ 
                    color: estimate?.status === 'approved' ? '#2ecc71' : 
                           estimate?.status === 'pending' ? '#f1c40f' : 
                           estimate?.status === 'rejected' ? '#e74c3c' : '#95a5a6'
                  }} />
                  <Box>
                    <Typography variant="caption" color="text.secondary">
                      Status
                    </Typography>
                    <Typography variant="body2" sx={{ 
                      textTransform: 'capitalize',
                      fontWeight: 500,
                      color: estimate?.status === 'approved' ? '#2ecc71' : 
                             estimate?.status === 'pending' ? '#f1c40f' : 
                             estimate?.status === 'rejected' ? '#e74c3c' : '#95a5a6'
                    }}>
                      {estimate?.status || 'Unknown'}
                    </Typography>
                  </Box>
                </Box>

                {/* Total Value */}
                <Box sx={{ 
                  display: 'flex',
                  alignItems: 'center',
                  gap: 2,
                  p: 2,
                  backgroundColor: '#f8f9fa',
                  borderRadius: '8px',
                  flex: 1,
                  '&:hover': {
                    backgroundColor: '#f0f2f5'
                  }
                }}>
                  <AttachMoneyIcon sx={{ color: '#27ae60' }} />
                  <Box>
                    <Typography variant="caption" color="text.secondary">
                      Total Value
                    </Typography>
                    <Typography variant="body2" sx={{ 
                      fontWeight: 600,
                      color: '#27ae60'
                    }}>
                      ${calculateTotal(estimate).toLocaleString('en-US', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                      })}
                    </Typography>
                  </Box>
                </Box>
              </Box>

              {/* Phone - Moved above email */}
              <Box sx={{ 
                display: 'flex',
                alignItems: 'center',
                gap: 2,
                p: 2,
                backgroundColor: '#f8f9fa',
                borderRadius: '8px',
                '&:hover': {
                  backgroundColor: '#f0f2f5'
                }
              }}>
                <PhoneIcon sx={{ color: '#2ecc71' }} />
                <Box>
                  <Typography variant="caption" color="text.secondary">
                    Phone
                  </Typography>
                  <Typography variant="body2">
                    {estimate?.customer_details?.phone || 'No phone provided'}
                  </Typography>
                </Box>
              </Box>

              {/* Email - Moved below phone */}
              <Box sx={{ 
                display: 'flex',
                alignItems: 'center',
                gap: 2,
                p: 2,
                backgroundColor: '#f8f9fa',
                borderRadius: '8px',
                '&:hover': {
                  backgroundColor: '#f0f2f5'
                }
              }}>
                <EmailIcon sx={{ color: '#3498db' }} />
                <Box>
                  <Typography variant="caption" color="text.secondary">
                    Email
                  </Typography>
                  <Typography variant="body2">
                    {estimate?.customer_details?.email || 'No email provided'}
                  </Typography>
                </Box>
              </Box>

              {/* Address */}
              <Box sx={{ 
                display: 'flex',
                alignItems: 'flex-start',
                gap: 2,
                p: 2,
                backgroundColor: '#f8f9fa',
                borderRadius: '8px',
                '&:hover': {
                  backgroundColor: '#f0f2f5'
                }
              }}>
                <LocationOnIcon sx={{ color: '#2ecc71' }} />
                <Box>
                  <Typography variant="caption" color="text.secondary">
                    Address
                  </Typography>
                  <Typography variant="body2">
                    {estimate?.customer_details?.address || 'No address provided'}
                    {estimate?.customer_details?.city && `, ${estimate.customer_details.city}`}
                    {estimate?.customer_details?.state && `, ${estimate.customer_details.state}`}
                    {estimate?.customer_details?.zipCode && ` ${estimate.customer_details.zipCode}`}
                  </Typography>
                </Box>
              </Box>
                  {/* Add TagsSection here */}
                  <TagsSection 
                    entityType="estimates"
                    entityId={estimate?.id}
                  />
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} md={8}>
          <PropertyDetails 
            address={estimate?.service_address}
            city={estimate?.service_city}
            state={estimate?.service_state}
            zip={estimate?.service_zip}
          />
        </Grid>

        {/* Full-width items section */}
        <Grid item xs={12}>
          <Box sx={{ 
            mt: 4,
            p: 3,
            backgroundColor: '#fff',
            borderRadius: '12px',
            boxShadow: '0 1px 3px rgba(0,0,0,0.05)',
          }}>
            <Typography variant="h6" sx={{ 
              color: '#2d3748',
              mb: 3,
              fontWeight: 600 
            }}>
              Estimate Items
            </Typography>
            
            {estimate?.items?.map((item, index) => (
              <Box
                key={index}
                sx={{
                  p: 2,
                  mb: 2,
                  backgroundColor: '#f8f9fa',
                  borderRadius: '8px',
                  '&:hover': {
                    backgroundColor: '#f0f2f5'
                  }
                }}
              >
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12} sm={6}>
                    <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
                      {item.title}
                    </Typography>
                    <Typography 
                      variant="body2" 
                      color="text.secondary" 
                      sx={{ 
                        mt: 0.5,
                        whiteSpace: 'pre-wrap',
                        paddingRight: '15px',
                        maxWidth: '600px',
                        lineHeight: '1.6'
                      }}
                    >
                      {item.description && item.description.split('\n').map((line, i) => (
                        <React.Fragment key={i}>
                          {line}
                          {i !== item.description.split('\n').length - 1 && <br />}
                        </React.Fragment>
                      ))}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Box sx={{ 
                      display: 'flex', 
                      justifyContent: 'flex-end',
                      alignItems: 'center',
                      gap: 2 
                    }}>
                      <Typography variant="body2" color="text.secondary">
                        ${getPrice(item).toFixed(2)} × {getQuantity(item)}
                      </Typography>
                      <Typography variant="subtitle1" sx={{ 
                        fontWeight: 600,
                        color: '#2c5282'
                      }}>
                        ${calculateItemTotal(item).toFixed(2)}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            ))}

            {/* Total Section */}
            <Box sx={{ 
              mt: 3,
              pt: 3,
              borderTop: '1px solid #eee',
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              gap: 2
            }}>
              <Typography variant="h6" color="text.secondary">
                Total:
              </Typography>
              <Typography variant="h6" sx={{ 
                fontWeight: 600,
                color: '#2c5282'
              }}>
                ${calculateTotal(estimate).toFixed(2)}
              </Typography>
            </Box>
          </Box>
        </Grid>

        {/* Full-width notes section */}
        <Grid item xs={12}>
          <Box sx={{ 
            mt: 4,
            mb: 4,
            p: 3,
            backgroundColor: '#f4f4f4',
            borderRadius: '12px',
            boxShadow: '0 1px 3px rgba(0,0,0,0.05)',
          }}>
            <Typography variant="h6" sx={{ 
              color: '#2d3748',
              mb: 1,
              fontWeight: 600 
            }}>
              Internal notes
            </Typography>
            
            {/* Input and Button Row */}
            <Box sx={{ 
              display: 'flex', 
              gap: 2,
              alignItems: 'flex-start',
              mb: 3
            }}>
              <TextField
                multiline
                fullWidth
                rows={4}
                placeholder="Add a new note..."
                value={internalNotes}
                onChange={(e) => setInternalNotes(e.target.value)}
                variant="outlined"
                sx={{
                  flex: 1,
                  '& .MuiOutlinedInput-root': {
                    backgroundColor: '#ffffff',
                  }
                }}
              />
              <Button
                variant="contained"
                onClick={handleSaveInternalNotes}
                disabled={!internalNotes.trim()}
                sx={{
                  height: '56px',
                  minWidth: '120px',
                  backgroundColor: '#3182ce',
                  '&:hover': {
                    backgroundColor: '#2c5282'
                  }
                }}
              >
                Add Note
              </Button>
            </Box>

            {/* Display Notes History */}
            {estimate?.internal_notes && (
              <Box sx={{ 
                mt: 3,
                display: 'flex',
                flexDirection: 'column',
                gap: 2
              }}>
                {(() => {
                  try {
                    let notes = [];
                    try {
                      notes = JSON.parse(estimate.internal_notes);
                      if (!Array.isArray(notes)) {
                        notes = [notes];
                      }
                    } catch (e) {
                      notes = [{
                        content: estimate.internal_notes,
                        timestamp: new Date().toISOString(),
                        user: 'Legacy'
                      }];
                    }

                    return notes.reverse().map((note, index) => (
                      <Box
                        key={index}
                        sx={{
                          p: 3,
                          backgroundColor: '#ffffff',
                          borderRadius: '8px',
                          border: '1px solid #e2e8f0',
                          boxShadow: '0 1px 2px rgba(0, 0, 0, 0.05)',
                          '&:hover': {
                            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'
                          }
                        }}
                      >
                        {editingNoteIndex === index ? (
                          // Edit mode
                          <Box sx={{ width: '100%' }}>
                            <TextField
                              multiline
                              fullWidth
                              rows={4}
                              value={editingNoteContent}
                              onChange={(e) => setEditingNoteContent(e.target.value)}
                              variant="outlined"
                              sx={{ mb: 2 }}
                            />
                            <Box sx={{ display: 'flex', gap: 1, justifyContent: 'flex-end' }}>
                              <Button
                                size="small"
                                onClick={() => {
                                  setEditingNoteIndex(null);
                                  setEditingNoteContent('');
                                }}
                              >
                                Cancel
                              </Button>
                              <Button
                                variant="contained"
                                size="small"
                                onClick={() => handleEditNote(index, editingNoteContent)}
                                disabled={!editingNoteContent.trim()}
                              >
                                Save
                              </Button>
                            </Box>
                          </Box>
                        ) : (
                          // Display mode
                          <>
                            <Typography 
                              variant="body1" 
                              sx={{ 
                                whiteSpace: 'pre-wrap',
                                mb: 2,
                                color: '#2d3748',
                                cursor: 'pointer',
                                '&:hover': {
                                  backgroundColor: 'rgba(0, 0, 0, 0.02)'
                                }
                              }}
                              onClick={() => {
                                setEditingNoteIndex(index);
                                setEditingNoteContent(note.content);
                              }}
                            >
                              {note.content}
                            </Typography>
                            <Typography 
                              variant="caption" 
                              sx={{ 
                                color: '#718096',
                                display: 'block',
                                fontSize: '0.875rem'
                              }}
                            >
                              Added by {note.user} on {new Date(note.timestamp).toLocaleString()}
                              {note.edited && (
                                <span style={{ marginLeft: '8px', fontStyle: 'italic' }}>
                                  (edited on {new Date(note.editedAt).toLocaleString()})
                                </span>
                              )}
                            </Typography>
                          </>
                        )}
                      </Box>
                    ));
                  } catch (e) {
                    console.error('Error displaying notes:', e);
                    return (
                      <Box sx={{
                        p: 2,
                        backgroundColor: '#ffffff',
                        borderRadius: '8px',
                        border: '1px solid #e2e8f0'
                      }}>
                        <Typography variant="body2">
                          {estimate.internal_notes}
                        </Typography>
                      </Box>
                    );
                  }
                })()}
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>

      <Snackbar
        open={showSuccess}
        autoHideDuration={6000}
        onClose={() => setShowSuccess(false)}
      >
        <Alert onClose={() => setShowSuccess(false)} severity="success">
          {successMessage}
        </Alert>
      </Snackbar>

      <Dialog 
        open={showItemSelectionModal} 
        onClose={() => setShowItemSelectionModal(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          Select Items to Include
          <IconButton
            aria-label="close"
            onClick={() => setShowItemSelectionModal(false)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Box sx={{ mt: 2 }}>
            {estimate?.items?.map((item, index) => (
              <FormControlLabel
                key={index}
                control={
                  <Checkbox
                    checked={includedItems.includes(item.title)}
                    onChange={() => handleItemSelection(item)}
                  />
                }
                label={
                  <Box>
                    <Typography variant="subtitle1">{item.title}</Typography>
                    <Typography variant="body2" color="text.secondary">
                      {item.description}
                    </Typography>
                  </Box>
                }
              />
            ))}

            {/* Groups Display */}
            {Object.entries(selectedGroups).map(([groupId, items]) => (
              <Box key={groupId} sx={{ mt: 2, p: 2, border: '1px solid #e0e0e0', borderRadius: 1 }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
                  <Typography variant="subtitle1">Group {groupId.split('-')[1]}</Typography>
                  <IconButton onClick={() => handleRemoveGroup(groupId)} size="small">
                    <CancelIcon />
                  </IconButton>
                </Box>
                {items.map((item, index) => (
                  <Chip
                    key={index}
                    label={item.title}
                    onDelete={() => handleRemoveFromGroup(groupId, item.title)}
                    sx={{ m: 0.5 }}
                  />
                ))}
              </Box>
            ))}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={() => {
              const selectedItems = estimate.items.filter(item => 
                includedItems.includes(item.title)
              );
              handleGroupItems(selectedItems);
            }}
            disabled={includedItems.length === 0}
          >
            Group Selected
          </Button>
          <Button onClick={() => setShowItemSelectionModal(false)}>Cancel</Button>
          <Button 
            onClick={handleItemSelectionConfirm}
            variant="contained"
            disabled={includedItems.length === 0}
          >
            Continue
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={showTimeSlotModal}
        onClose={() => setShowTimeSlotModal(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Schedule Selected Items</DialogTitle>
        <DialogContent>
          {convertedEvents.map((event, index) => (
            <Box key={index} sx={{ mb: 3 }}>
              <Typography variant="subtitle1" sx={{ mb: 1 }}>
                {event.title}
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    label="Start Time"
                    type="datetime-local"
                    fullWidth
                    value={conversionTimeSlot.start_time}
                    onChange={(e) => handleTimeSlotChange('start_time', e.target.value)}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="End Time"
                    type="datetime-local"
                    fullWidth
                    value={conversionTimeSlot.end_time}
                    onChange={(e) => handleTimeSlotChange('end_time', e.target.value)}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
              </Grid>
            </Box>
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowTimeSlotModal(false)}>Cancel</Button>
          <Button 
            variant="contained" 
            onClick={handleTimeSlotSubmit}
            disabled={!timeSlots.every(slot => slot?.start_time && slot?.end_time)}
          >
            Schedule and Create Project
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog 
        open={showPreviewModal} 
        onClose={() => setShowPreviewModal(false)}
        maxWidth="lg"
        fullWidth
        PaperProps={{
          sx: {
            borderRadius: 2,
            height: '90vh',
            maxHeight: '90vh',
            display: 'flex',
            flexDirection: 'column'
          }
        }}
      >
        <DialogTitle sx={{ 
          borderBottom: '1px solid #edf2f7',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          py: 2,
          px: 3,
          backgroundColor: '#f8fafc'
        }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <VisibilityIcon sx={{ color: '#4a5568' }} />
            <Typography variant="h6" sx={{ fontWeight: 600 }}>
              Client Preview
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', gap: 2 }}>
            <Button
              startIcon={<DownloadIcon />}
              onClick={renderPDFDownload}
              sx={{
                backgroundColor: '#3182ce',
                color: 'white',
                '&:hover': {
                  backgroundColor: '#2c5282'
                }
              }}
            >
              Download PDF
            </Button>
            <IconButton
              onClick={() => setShowPreviewModal(false)}
              size="small"
              sx={{ 
                color: '#718096',
                '&:hover': { 
                  color: '#2d3748',
                  backgroundColor: '#f7fafc'
                }
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>

        <DialogContent sx={{ 
          p: 0, 
          flex: 1,
          overflow: 'auto',
          backgroundColor: '#f7fafc'
        }}>
          <Box sx={{ 
            maxWidth: '1000px', 
            margin: '0 auto',
            p: 4,
            backgroundColor: 'white',
            borderRadius: 2,
            boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1)',
            my: 4
          }}>
            <DefaultTemplate 
              estimate={estimate}
              className="default-template"
            />
          </Box>
        </DialogContent>
      </Dialog>

      <Dialog 
        open={showSignatureModal} 
        onClose={() => setShowSignatureModal(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle sx={{ 
          pb: 1,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}>
          Collect Signature
          <IconButton
            onClick={() => setShowSignatureModal(false)}
            size="small"
          >
            <CancelIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1" sx={{ mb: 3 }}>
            Share this estimate with your client to collect their signature. Choose how you'd like to share:
          </Typography>
          <Box sx={{ 
            display: 'flex', 
            gap: 2,
            justifyContent: 'center'
          }}>
            <Button
              variant="contained"
              onClick={handleCopyLink}
              startIcon={<ContentCopyIcon />}
              sx={{
                flex: 1,
                py: 2,
                backgroundColor: '#3182ce',
                '&:hover': {
                  backgroundColor: '#2c5282'
                }
              }}
            >
              Copy Link
            </Button>
            <Button
              variant="contained"
              onClick={handleOpenLink}
              startIcon={<OpenInNewIcon />}
              sx={{
                flex: 1,
                py: 2,
                backgroundColor: '#38a169',
                '&:hover': {
                  backgroundColor: '#2f855a'
                }
              }}
            >
              Open Link
            </Button>
          </Box>
        </DialogContent>
      </Dialog>

      <Dialog 
        open={showProjectTypeModal} 
        onClose={() => setShowProjectTypeModal(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Convert to Project</DialogTitle>
        <DialogContent>
          <Box sx={{ mt: 2 }}>
            <Typography variant="subtitle1" gutterBottom>
              Project Type
            </Typography>
            <RadioGroup
              value={projectType}
              onChange={(e) => setProjectType(e.target.value)}
            >
              <FormControlLabel 
                value="single_day" 
                control={<Radio />} 
                label="Single Day Project" 
              />
              <FormControlLabel 
                value="multi_day" 
                control={<Radio />} 
                label="Multi-Day Project" 
              />
            </RadioGroup>

            {projectType === 'single_day' && (
              <Box sx={{ mt: 3 }}>
                <Typography variant="subtitle1" gutterBottom>
                  Scheduling
                </Typography>
                <RadioGroup
                  value={scheduleNow}
                  onChange={(e) => setScheduleNow(e.target.value === 'true')}
                >
                  <FormControlLabel 
                    value={true}
                    control={<Radio />} 
                    label="Schedule Now" 
                  />
                  <FormControlLabel 
                    value={false}
                    control={<Radio />} 
                    label="Schedule Later" 
                  />
                </RadioGroup>

                {scheduleNow && (
                  <Box sx={{ mt: 2 }}>
                    <Typography variant="subtitle2" gutterBottom>
                      Project Time
                    </Typography>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <TextField
                          label="Start Time"
                          type="datetime-local"
                          fullWidth
                          value={conversionTimeSlot.start_time}
                          onChange={(e) => handleTimeSlotChange('start_time', e.target.value)}
                          InputLabelProps={{ shrink: true }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          label="End Time"
                          type="datetime-local"
                          fullWidth
                          value={conversionTimeSlot.end_time}
                          onChange={(e) => handleTimeSlotChange('end_time', e.target.value)}
                          InputLabelProps={{ shrink: true }}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                )}
              </Box>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowProjectTypeModal(false)}>Cancel</Button>
          <Button 
            onClick={handleProjectTypeSubmit}
            variant="contained"
            disabled={
              isConverting || 
              (projectType === 'single_day' && scheduleNow && 
               (!conversionTimeSlot.start_time || !conversionTimeSlot.end_time))
            }
          >
            {isConverting ? 'Converting...' : 'Convert to Project'}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={showUnsignedWarning}
        onClose={() => setShowUnsignedWarning(false)}
        maxWidth="sm"
        fullWidth
        PaperProps={{
          sx: {
            borderRadius: 2,
          }
        }}
      >
        <DialogTitle sx={{ 
          display: 'flex', 
          alignItems: 'center', 
          gap: 1,
          color: '#ef4444',
          fontFamily: 'Urbanist, sans-serif'
        }}>
          <WarningAmberIcon sx={{ color: '#ef4444' }} />
          Unsigned Estimate
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1" sx={{ 
            mb: 2,
            color: '#ef4444',
            fontFamily: 'Urbanist, sans-serif'
          }}>
            This estimate has not been signed by the client. Converting an unsigned estimate to a project may cause issues with:
          </Typography>
          <Box sx={{ pl: 2, mb: 2 }}>
            <Typography variant="body1" component="div" sx={{ 
              color: '#ef4444',
              fontFamily: 'Urbanist, sans-serif'
            }}>
              • Client approval documentation
            </Typography>
            <Typography variant="body1" component="div" sx={{ 
              color: '#ef4444',
              fontFamily: 'Urbanist, sans-serif'
            }}>
              • Payment collection
            </Typography>
            <Typography variant="body1" component="div" sx={{ 
              color: '#ef4444',
              fontFamily: 'Urbanist, sans-serif'
            }}>
              • Legal protection
            </Typography>
          </Box>
          <Typography variant="body1" sx={{ 
            color: '#ef4444',
            fontFamily: 'Urbanist, sans-serif'
          }}>
            Are you sure you want to proceed with the conversion?
          </Typography>
        </DialogContent>
        <DialogActions sx={{ p: 2.5, gap: 1 }}>
          <Button 
            onClick={() => handleUnsignedWarningResponse(false)}
            variant="outlined"
            sx={{
              fontFamily: 'Urbanist, sans-serif',
              color: '#ef4444',
              borderColor: '#ef4444',
              '&:hover': {
                borderColor: '#dc2626',
                backgroundColor: 'rgba(239, 68, 68, 0.04)',
              }
            }}
          >
            Cancel
          </Button>
          <Button 
            onClick={() => handleUnsignedWarningResponse(true)}
            variant="contained"
            sx={{
              fontFamily: 'Urbanist, sans-serif',
              backgroundColor: '#ef4444',
              '&:hover': {
                backgroundColor: '#dc2626',
              }
            }}
          >
            Convert Anyway
          </Button>
        </DialogActions>
      </Dialog>

      {/* Always render the template but hide it when not needed */}
      <Box sx={{ position: 'absolute', left: '-9999px', top: 0 }}>
        <DefaultTemplate 
          estimate={estimate}
          className="default-template"
        />
      </Box>
    </Box>
  );
};

export default AdminEstimateDetails;
