import React from 'react';
import { Box, Chip } from '@mui/material';
import styles from './MetricSelector.module.scss';

const MetricSelector = ({ activeMetric, setActiveMetric, financialData }) => (
  <Box display="flex" gap={1}>
    {['estimates', 'projects', 'invoices'].map((metric) => (
      <Chip
        key={metric}
        label={`${metric.charAt(0).toUpperCase() + metric.slice(1)} (${financialData?.[metric]?.length || 0})`}
        onClick={() => setActiveMetric(metric)}
        variant={activeMetric === metric ? 'filled' : 'outlined'}
        className={`${styles.filterChip} ${activeMetric === metric ? styles.activeChip : ''}`}
      />
    ))}
  </Box>
);

export default MetricSelector; 