import React, { useState, useEffect } from 'react';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Chip,
  IconButton,
  Tooltip,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  TextField,
  Snackbar,
  Alert,
} from '@mui/material';
import {
  CheckCircle as ApproveIcon,
  Cancel as RejectIcon,
  AccessTime as PendingIcon,
  Close as CloseIcon,
  Info as InfoIcon,
} from '@mui/icons-material';
import { getEmployeeRequests, updateRequestStatus } from '../services/api';

const formatRequestType = (type) => {
  return type
    .split('_')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

const EmployeeRequests = () => {
  const [requests, setRequests] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [responseNote, setResponseNote] = useState('');
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });

  useEffect(() => {
    fetchRequests();
  }, []);

  const fetchRequests = async () => {
    try {
      const data = await getEmployeeRequests();
      setRequests(data.requests);
      setLoading(false);
    } catch (err) {
      setError('Failed to fetch requests');
      setLoading(false);
      setSnackbar({
        open: true,
        message: 'Failed to fetch requests',
        severity: 'error'
      });
    }
  };

  const handleApprove = async (requestId) => {
    try {
      await updateRequestStatus(requestId, 'approved', responseNote);
      await fetchRequests(); // Refresh the list
      setSelectedRequest(null);
      setResponseNote('');
      setSnackbar({
        open: true,
        message: 'Request approved successfully',
        severity: 'success'
      });
    } catch (err) {
      setSnackbar({
        open: true,
        message: 'Failed to approve request',
        severity: 'error'
      });
    }
  };

  const handleReject = async (requestId) => {
    try {
      await updateRequestStatus(requestId, 'rejected', responseNote);
      await fetchRequests(); // Refresh the list
      setSelectedRequest(null);
      setResponseNote('');
      setSnackbar({
        open: true,
        message: 'Request rejected successfully',
        severity: 'success'
      });
    } catch (err) {
      setSnackbar({
        open: true,
        message: 'Failed to reject request',
        severity: 'error'
      });
    }
  };

  const getStatusChip = (status) => {
    const statusProps = {
      pending: { color: 'warning', icon: <PendingIcon />, label: 'Pending' },
      approved: { color: 'success', icon: <ApproveIcon />, label: 'Approved' },
      rejected: { color: 'error', icon: <RejectIcon />, label: 'Rejected' },
    };

    const { color, icon, label } = statusProps[status] || statusProps.pending;

    return (
      <Chip
        icon={icon}
        label={label}
        color={color}
        size="small"
        sx={{ fontWeight: 500 }}
      />
    );
  };

  const handleViewDetails = (request) => {
    setSelectedRequest(request);
  };

  const handleCloseDetails = () => {
    setSelectedRequest(null);
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const RequestDetailsModal = ({ request, onClose }) => {
    if (!request) return null;

    return (
      <Dialog 
        open={Boolean(request)} 
        onClose={onClose}
        maxWidth="md"
        fullWidth
        PaperProps={{
          sx: {
            borderRadius: '16px',
            boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
            backgroundColor: 'var(--background-paper)',
          }
        }}
      >
        <DialogTitle
          sx={{
            backgroundColor: 'var(--background-paper)',
            borderBottom: '1px solid var(--divider)',
            p: 2.5,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            mb: 2,
            color: 'var(--text-primary)',
          }}
        >
          <Typography variant="h6" component="div" sx={{ fontWeight: 600, color: 'var(--text-primary)' }}>
            Request Details
          </Typography>
          <IconButton
            onClick={onClose}
            size="small"
            sx={{
              color: 'var(--text-primary)',
              '&:hover': {
                backgroundColor: 'var(--hover-overlay)',
              }
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent sx={{ p: 4, pt: 2, backgroundColor: 'var(--background-paper)' }}>
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <Box mb={3}>
                <Typography 
                  variant="subtitle2" 
                  gutterBottom
                  sx={{ mb: 1, color: 'var(--text-primary)' }}
                >
                  Employee
                </Typography>
                <Typography variant="body1" sx={{ fontWeight: 500, color: 'var(--text-primary)' }}>
                  {request.employeeName}
                </Typography>
              </Box>

              <Box mb={3}>
                <Typography 
                  variant="subtitle2" 
                  gutterBottom
                  sx={{ mb: 1, color: 'var(--text-primary)' }}
                >
                  Request Type
                </Typography>
                <Typography variant="body1" sx={{ fontWeight: 500, color: 'var(--text-primary)' }}>
                  {formatRequestType(request.type)}
                </Typography>
              </Box>

              <Box mb={3}>
                <Typography 
                  variant="subtitle2" 
                  color="textSecondary" 
                  gutterBottom
                  sx={{ mb: 1, color: 'var(--text-primary)' }}
                >
                  Status
                </Typography>
                {getStatusChip(request.status)}
              </Box>
            </Grid>

            <Grid item xs={12} md={6}>
              <Box mb={3}>
                <Typography 
                  variant="subtitle2" 
                  color="textSecondary" 
                  gutterBottom
                  sx={{ mb: 1, color: 'var(--text-primary)' }}
                >
                  Request Date
                </Typography>
                <Typography variant="body1" fontWeight={500}>
                  {new Date(request.requestDate).toLocaleDateString()}
                </Typography>
              </Box>

              <Box mb={3}>
                <Typography 
                  variant="subtitle2" 
                  color="textSecondary" 
                  gutterBottom
                  sx={{ mb: 1, color: 'var(--text-primary)' }}
                >
                  Details
                </Typography>
                <Typography variant="body1" sx={{ color: 'var(--text-primary)' }}>
                  {request.details}
                </Typography>
              </Box>
            </Grid>

            {request.status === 'pending' && (
              <Grid item xs={12}>
                <Box sx={{ 
                  mt: 2, 
                  p: 3,
                  backgroundColor: 'var(--background-paper)',
                  borderRadius: '8px',
                  border: '1px solid var(--divider)'
                }}>
                  <Typography 
                    variant="subtitle2" 
                    color="textSecondary" 
                    gutterBottom
                    sx={{ mb: 2, backgroundColor: 'var(--background-paper)', color: 'var(--text-primary)' }}
                  >
                    Response
                  </Typography>
                  <TextField
                    fullWidth
                    multiline
                    rows={3}
                    variant="outlined"
                    placeholder="Add a response note (optional)"
                    value={responseNote}
                    onChange={(e) => setResponseNote(e.target.value)}
                    sx={{ 
                      mb: 2,
                      '& .MuiOutlinedInput-root': {
                        backgroundColor: 'var(--background-secondary)',
                        color: 'var(--text-primary)',
                        '& fieldset': {
                          borderColor: 'var(--divider)'
                        },
                        '&:hover fieldset': {
                          borderColor: 'var(--divider)'
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: 'primary.main'
                        }
                      },
                      '& .MuiInputBase-input': {
                        color: 'var(--text-primary)'
                      },
                      '& .MuiInputBase-input::placeholder': {
                        color: 'var(--text-secondary)',
                        opacity: 0.7
                      }
                    }}
                  />
                  <Box sx={{ display: 'flex', gap: 2 }}>
                    <Button
                      variant="contained"
                      color="success"
                      startIcon={<ApproveIcon />}
                      onClick={() => handleApprove(request.id)}
                      sx={{ color: '#fff' }}
                    >
                      Approve
                    </Button>
                    <Button
                      variant="contained"
                      color="error"
                      startIcon={<RejectIcon />}
                      onClick={() => handleReject(request.id)}
                      sx={{ color: '#fff' }}
                    >
                      Reject
                    </Button>
                  </Box>
                </Box>
              </Grid>
            )}
          </Grid>
        </DialogContent>
      </Dialog>
    );
  };

  if (loading) return <Box p={3} sx={{ color: 'var(--text-primary)' }}>Loading...</Box>;
  if (error) return <Box p={3} sx={{ color: 'var(--text-primary)' }}>{error}</Box>;

  return (
    <Box p={3}>
      <TableContainer component={Paper} sx={{ 
        borderRadius: 2, 
        boxShadow: 2,
        backgroundColor: 'var(--background-secondary)'
      }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ color: 'var(--text-primary)' }}>Employee</TableCell>
              <TableCell sx={{ color: 'var(--text-primary)' }}>Type</TableCell>
              <TableCell sx={{ color: 'var(--text-primary)' }}>Request Date</TableCell>
              <TableCell sx={{ color: 'var(--text-primary)' }}>Details</TableCell>
              <TableCell sx={{ color: 'var(--text-primary)' }}>Status</TableCell>
              <TableCell sx={{ color: 'var(--text-primary)' }} align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {requests.map((request) => (
              <TableRow key={request.id}>
                <TableCell sx={{ color: 'var(--text-primary)' }}>{request.employeeName}</TableCell>
                <TableCell sx={{ color: 'var(--text-primary)' }}>{formatRequestType(request.type)}</TableCell>
                <TableCell sx={{ color: 'var(--text-primary)' }}>{new Date(request.requestDate).toLocaleDateString()}</TableCell>
                <TableCell sx={{ color: 'var(--text-primary)' }}>{request.details}</TableCell>
                <TableCell sx={{ color: 'var(--text-primary)' }}>{getStatusChip(request.status)}</TableCell>
                <TableCell sx={{ color: 'var(--text-primary)' }} align="right">
                  <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
                    <Tooltip title="View Details">
                      <IconButton
                        color="primary"
                        onClick={() => handleViewDetails(request)}
                      >
                        <InfoIcon />
                      </IconButton>
                    </Tooltip>
                    {request.status === 'pending' && (
                      <>
                        <Tooltip title="Approve">
                          <IconButton
                            color="success"
                            onClick={() => handleApprove(request.id)}
                          >
                            <ApproveIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Reject">
                          <IconButton
                            color="error"
                            onClick={() => handleReject(request.id)}
                          >
                            <RejectIcon />
                          </IconButton>
                        </Tooltip>
                      </>
                    )}
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <RequestDetailsModal 
        request={selectedRequest}
        onClose={() => {
          setSelectedRequest(null);
          setResponseNote('');
        }}
      />

      <Snackbar 
        open={snackbar.open} 
        autoHideDuration={6000} 
        onClose={handleCloseSnackbar}
      >
        <Alert 
          onClose={handleCloseSnackbar} 
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default EmployeeRequests; 