import React, { useState } from 'react';
import {
  Box,
  Card,
  Grid,
  Typography,
  LinearProgress,
  Button,
  IconButton,
  Tabs,
  Tab,
} from '@mui/material';
import {
  ArrowBack as ArrowBackIcon,
} from '@mui/icons-material';
import FormPreviewSection from './sections/FormPreviewSection';
import FormFieldsSection from './sections/FormFieldsSection';
import FormStyleSection from './sections/FormStyleSection';
import FormSettingsSection from './sections/FormSettingsSection';

const MetricCard = ({ title, value, subtitle, progress }) => (
  <Card sx={{ p: 3, height: '100%', borderRadius: 2 }}>
    <Typography color="text.secondary" variant="overline">
      {title}
    </Typography>
    <Typography variant="h4" sx={{ my: 1, fontWeight: 600 }}>
      {value}
    </Typography>
    {progress && (
      <Box sx={{ mt: 2 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
          <Typography variant="caption" color="text.secondary">
            {subtitle}
          </Typography>
          <Typography variant="caption" color="text.secondary">
            {progress}%
          </Typography>
        </Box>
        <LinearProgress
          variant="determinate"
          value={progress}
          sx={{
            height: 6,
            borderRadius: 3,
            backgroundColor: 'grey.100',
            '& .MuiLinearProgress-bar': {
              borderRadius: 3,
              backgroundImage: 'linear-gradient(45deg, #2196f3 30%, #21cbf3 90%)',
            }
          }}
        />
      </Box>
    )}
  </Card>
);

const FormDetails = ({ onBack }) => {
  const [activeTab, setActiveTab] = useState('preview');
  const [formData, setFormData] = useState({
    id: 1,
    name: 'Customer Feedback Form',
    type: 'feedback',
    description: 'Collect customer feedback about our services',
    submissions: 276,
    submissionRate: 85,
    fields: [
      { id: 1, label: 'Full Name', type: 'text', required: true },
      { id: 2, label: 'Email', type: 'email', required: true },
      { id: 3, label: 'Rating', type: 'select', required: true },
      { id: 4, label: 'Comments', type: 'textarea', required: false }
    ],
    settings: {
      submitButtonText: 'Submit Feedback',
      successMessage: 'Thank you for your feedback!',
      redirectUrl: '',
      enableRecaptcha: true
    },
    style: {
      theme: 'light',
      primaryColor: '#2196f3',
      borderRadius: 8
    },
    analytics: {
      averageTimeToComplete: '2m 34s',
      conversionRate: 24.8,
      completionRate: 85
    }
  });

  const handleFormChange = (newData) => {
    setFormData(newData);
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case 'preview':
        return <FormPreviewSection formData={formData} />;
      case 'fields':
        return <FormFieldsSection formData={formData} onChange={handleFormChange} />;
      case 'style':
        return <FormStyleSection formData={formData} onChange={handleFormChange} />;
      case 'settings':
        return <FormSettingsSection formData={formData} onChange={handleFormChange} />;
      default:
        return null;
    }
  };

  return (
    <Box sx={{ p: 3 }}>
      <Box sx={{ 
        display: 'flex', 
        alignItems: 'center', 
        gap: 2, 
        mb: 4 
      }}>
        <IconButton 
          onClick={onBack}
          sx={{ 
            color: 'text.secondary',
            '&:hover': {
              backgroundColor: 'action.hover',
            }
          }}
        >
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h5" sx={{ fontWeight: 600 }}>
          {formData.name} - Performance
        </Typography>
      </Box>

      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <MetricCard
            title="TOTAL SUBMISSIONS"
            value={formData.submissions}
            subtitle="Completion Rate"
            progress={formData.analytics.completionRate}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <MetricCard
            title="AVERAGE TIME TO COMPLETE"
            value={formData.analytics.averageTimeToComplete}
            subtitle="Improvement"
            progress={75}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <MetricCard
            title="CONVERSION RATE"
            value={`${formData.analytics.conversionRate}%`}
            subtitle="Goal Progress"
            progress={formData.analytics.conversionRate}
          />
        </Grid>

        <Grid item xs={12}>
          <Card sx={{ borderRadius: 2 }}>
            <Tabs
              value={activeTab}
              onChange={(e, newValue) => setActiveTab(newValue)}
              sx={{ borderBottom: 1, borderColor: 'divider', px: 2 }}
            >
              <Tab value="preview" label="Preview" />
              <Tab value="fields" label="Fields" />
              <Tab value="style" label="Style" />
              <Tab value="settings" label="Settings" />
            </Tabs>
            <Box sx={{ p: 3 }}>
              {renderTabContent()}
            </Box>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default FormDetails; 