import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Typography,
  Box
} from '@mui/material';

const DAYS_OF_WEEK = [
  { name: 'Sunday', value: 0 },
  { name: 'Monday', value: 1 },
  { name: 'Tuesday', value: 2 },
  { name: 'Wednesday', value: 3 },
  { name: 'Thursday', value: 4 },
  { name: 'Friday', value: 5 },
  { name: 'Saturday', value: 6 }
];

const RecurringAvailabilityModal = ({ open, onClose, onSave, initialSelectedDays = [] }) => {
  const [selectedDays, setSelectedDays] = useState(initialSelectedDays);
  const [removedDays, setRemovedDays] = useState([]);

  useEffect(() => {
    if (open) {
      setSelectedDays(initialSelectedDays);
      setRemovedDays([]);
    }
  }, [open, initialSelectedDays]);

  const handleDayToggle = (dayValue) => {
    if (selectedDays.includes(dayValue)) {
      setSelectedDays(prev => prev.filter(d => d !== dayValue));
      setRemovedDays(prev => [...prev, dayValue]);
    } else {
      setSelectedDays(prev => [...prev, dayValue].sort((a, b) => a - b));
      setRemovedDays(prev => prev.filter(d => d !== dayValue));
    }
  };

  const handleSave = () => {
    const today = new Date();
    const endOfYear = new Date(today.getFullYear(), 11, 31);

    onSave({
      selectedDays,
      removedDays,
      startDate: today.toISOString(),
      endDate: endOfYear.toISOString()
    });
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Set Weekly Availability</DialogTitle>
      <DialogContent>
        <Box sx={{ my: 2 }}>
          <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
            Select the days of the week you'll be available.
          </Typography>
          <FormGroup>
            {DAYS_OF_WEEK.map((day) => (
              <FormControlLabel
                key={day.value}
                control={
                  <Checkbox
                    checked={selectedDays.includes(day.value)}
                    onChange={() => handleDayToggle(day.value)}
                  />
                }
                label={day.name}
              />
            ))}
          </FormGroup>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSave} variant="contained" color="primary">
          Save Changes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RecurringAvailabilityModal; 