import React from 'react';
import { Box, Typography, Avatar, IconButton } from '@mui/material';
import { 
  MoreVert as MoreVertIcon,
  Email as EmailIcon,
  Phone as PhoneIcon,
  WhatsApp as WhatsAppIcon,
  Circle as CircleIcon,
  Sms as SmsIcon
} from '@mui/icons-material';

const ChatHeader = ({ contact }) => {
  if (!contact) {
    return (
      <Box sx={{ 
        p: 2, 
        borderBottom: 1, 
        borderColor: 'divider',
        display: 'flex',
        justifyContent: 'flex-end'
      }}>
        <Typography variant="subtitle1" sx={{ fontFamily: 'Urbanist, sans-serif' }}>
          Select a conversation
        </Typography>
      </Box>
    );
  }

  const getIcon = () => {
    switch(contact.type) {
      case 'sms': return <SmsIcon />;
      case 'email': return <EmailIcon />;
      case 'phone': return <PhoneIcon />;
      case 'whatsapp': return <WhatsAppIcon />;
      default: return <SmsIcon />;
    }
  };

  const getColor = () => {
    switch(contact.type) {
      case 'sms': return '#9c27b0';
      case 'email': return '#1976d2';
      case 'phone': return '#2e7d32';
      case 'whatsapp': return '#25D366';
      default: return '#9c27b0';
    }
  };

  return (
    <Box sx={{ 
      p: 2, 
      borderBottom: 1, 
      borderColor: 'divider',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      gap: 2
    }}>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <Box sx={{ textAlign: 'right' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Typography variant="subtitle1" sx={{ fontWeight: 600, fontFamily: 'Urbanist, sans-serif' }}>
              {contact.name}
            </Typography>
            <CircleIcon 
              sx={{ 
                fontSize: 8, 
                color: contact.status === 'online' ? 'success.main' : 'grey.400'
              }} 
            />
          </Box>
          <Typography variant="caption" color="text.secondary">
            {contact.status === 'online' ? 'Online' : 'Last seen recently'}
          </Typography>
        </Box>
        <Avatar sx={{ bgcolor: getColor() }}>
          {getIcon()}
        </Avatar>
      </Box>
      <IconButton>
        <MoreVertIcon />
      </IconButton>
    </Box>
  );
};

export default ChatHeader;