import React, { useState, useEffect } from 'react';
import { 
  Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, 
  CircularProgress, Select, MenuItem, FormControl, Box, Grid, Card, CardContent,
  TextField, Button, InputLabel, Snackbar, Chip, IconButton, Container, Menu
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { 
  Description, AttachMoney, Business, Person, Event,
  CalendarToday, CheckCircleOutline, HourglassEmpty,
  Add as AddIcon, Receipt as ReceiptIcon, MoreVert, AccessTime, MoneyOff, HourglassEmpty as HourglassEmptyIcon
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import styles from './AdminInvoices.module.scss';
import { getInvoices } from '../services/api';
import SearchIcon from '@mui/icons-material/Search';
import FilterListIcon from '@mui/icons-material/FilterList';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import DescriptionIcon from '@mui/icons-material/Description';
import MoneyOffIcon from '@mui/icons-material/MoneyOff';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

const AdminInvoices = () => {
  const navigate = useNavigate();

  const [invoices, setInvoices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [clientFilter, setClientFilter] = useState('All');
  const [statusFilter, setStatusFilter] = useState('All');
  const [timeFilter, setTimeFilter] = useState('All Time');
  const [clients, setClients] = useState(['All']);
  const [statuses] = useState(['All', 'Paid', 'Pending', 'Overdue']);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [totalInvoiceValue, setTotalInvoiceValue] = useState(0);
  const [dueInvoiceValue, setDueInvoiceValue] = useState(0);
  const [averagePaymentTime, setAveragePaymentTime] = useState(8);
  const [scheduledToday, setScheduledToday] = useState(5);
  const [draftedTotal, setDraftedTotal] = useState(0);
  const [unpaidTotal, setUnpaidTotal] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredInvoices, setFilteredInvoices] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [filter, setFilter] = useState('all');
  const [filterMenuOpen, setFilterMenuOpen] = useState(false);
  const [filterAnchorEl, setFilterAnchorEl] = useState(null);
  const [selectedCustomer, setSelectedCustomer] = useState('All Customers');
  const [selectedStatus, setSelectedStatus] = useState('All Statuses');
  const [dateRange, setDateRange] = useState({ from: null, to: null });
  const [activeFilter, setActiveFilter] = useState('all');
  const [unpaidInvoices, setUnpaidInvoices] = useState([]);
  const [unsentInvoices, setUnsentInvoices] = useState([]);
  const [sort, setSort] = useState('newest');

  useEffect(() => {
    if (!searchTerm) {
      setFilteredInvoices(invoices);
      return;
    }

    const searchResults = invoices.filter(invoice => {
      const searchString = searchTerm.toLowerCase();
      const customerName = invoice.customer_details?.name?.toLowerCase() || '';
      const companyName = invoice.customer_details?.company?.toLowerCase() || '';
      const invoiceNumber = invoice.invoice_number?.toLowerCase() || '';
      const total = calculateTotal(invoice).toString();

      return (
        customerName.includes(searchString) ||
        companyName.includes(searchString) ||
        invoiceNumber.includes(searchString) ||
        total.includes(searchString)
      );
    });

    setFilteredInvoices(searchResults);
  }, [searchTerm, invoices]);

  useEffect(() => {
    const fetchInvoices = async () => {
      setLoading(true);
      try {
        console.log('Fetching invoices...');
        const fetchedInvoices = await getInvoices();
        console.log('Fetched invoices:', fetchedInvoices);
        setInvoices(fetchedInvoices);
        setFilteredInvoices(fetchedInvoices);
        const uniqueClients = ['All', ...new Set(fetchedInvoices.map(inv => inv.customer_details?.name || 'Unknown'))];
        setClients(uniqueClients);
        const filteredInvoices = getFilteredInvoices(fetchedInvoices);
        const totalInvoiceValue = calculateTotalInvoiceValue(filteredInvoices);
        const dueInvoiceValue = calculateDueInvoiceValue(filteredInvoices);
        setTotalInvoiceValue(totalInvoiceValue);
        setDueInvoiceValue(dueInvoiceValue);
      } catch (err) {
        console.error('Error fetching invoices:', err);
        setError('Failed to fetch invoices. Please try again later.');
        setSnackbarMessage('Failed to fetch invoices. Please try again later.');
        setSnackbarOpen(true);
      } finally {
        setLoading(false);
      }
    };

    fetchInvoices();
  }, []);

  useEffect(() => {
    if (invoices.length) {
      const unpaid = invoices.filter(inv => inv.status === 'Pending' || inv.status === 'Overdue');
      const unsent = invoices.filter(inv => inv.status === 'Unsent');
      setUnpaidInvoices(unpaid);
      setUnsentInvoices(unsent);
    }
  }, [invoices]);

  const getFilteredInvoices = () => {
    const now = new Date();
    const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
    const startOfQuarter = new Date(now.getFullYear(), Math.floor(now.getMonth() / 3) * 3, 1);
    const startOfYear = new Date(now.getFullYear(), 0, 1);

    return invoices.filter(invoice => {
      const clientMatch = clientFilter === 'All' || invoice.customer_details?.name === clientFilter;
      const statusMatch = statusFilter === 'All' || invoice.status === statusFilter;
      const invDate = new Date(invoice.issueDate);
      let dateMatch = true;

      switch (timeFilter) {
        case 'This Month':
          dateMatch = invDate >= startOfMonth;
          break;
        case 'This Quarter':
          dateMatch = invDate >= startOfQuarter;
          break;
        case 'This Year':
          dateMatch = invDate >= startOfYear;
          break;
        default:
          dateMatch = true;
      }

      const searchMatch = searchQuery === '' || (
        (invoice.invoice_number?.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (invoice.customer_details?.name?.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (invoice.customer_details?.company?.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (calculateTotal(invoice).toString().includes(searchQuery))
      );

      return clientMatch && statusMatch && dateMatch && searchMatch;
    });
  };


  const formatCurrency = (amount) => {
    return Number(amount).toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  const calculateTotal = (invoice) => {
    if (!invoice.items || !Array.isArray(invoice.items)) {
      return 0;
    }
    
    return invoice.items.reduce((sum, item) => {
      const itemTotal = Number(item.total) || 0;
      return sum + itemTotal;
    }, 0);
  };

  const calculateTotalInvoiceValue = (invoices) => {
    return invoices.reduce((sum, invoice) => sum + calculateTotal(invoice), 0);
  };

  const calculateDueInvoiceValue = (invoices) => {
    return invoices
      .filter(invoice => invoice.status === 'Pending' || invoice.status === 'Overdue')
      .reduce((sum, invoice) => sum + calculateTotal(invoice), 0);
  };

  const handleCreateInvoice = () => {
    navigate('/admin/invoices/create');
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleFilterClick = (event) => {
    setFilterAnchorEl(event.currentTarget);
    setFilterMenuOpen(true);
  };

  const handleFilterClose = (value) => {
    if (value) {
      setFilter(value);
    }
    setAnchorEl(null);
  };

  const summaryCards = (
    <Grid container spacing={3} className={styles.summaryContainer}>
      <Grid item xs={12} sm={6} md={3}>
        <div className={styles.summaryCard}>
          <div className={styles.cardContent}>
            <div className={styles.iconWrapper} style={{ color: '#FF4842' }}>
              <AccessTimeIcon />
            </div>
            <div className={styles.cardInfo}>
              <h3 className={styles.cardValue}>${formatCurrency(dueInvoiceValue)}</h3>
              <p className={styles.cardLabel}>Overdue amount</p>
            </div>
          </div>
        </div>
      </Grid>
      
      <Grid item xs={12} sm={6} md={3}>
        <div className={styles.summaryCard}>
          <div className={styles.cardContent}>
            <div className={styles.iconWrapper} style={{ color: '#54D62C' }}>
              <DescriptionIcon />
            </div>
            <div className={styles.cardInfo}>
              <h3 className={styles.cardValue}>${formatCurrency(draftedTotal)}</h3>
              <p className={styles.cardLabel}>Drafted totals</p>
            </div>
          </div>
        </div>
      </Grid>

      <Grid item xs={12} sm={6} md={3}>
        <div className={styles.summaryCard}>
          <div className={styles.cardContent}>
            <div className={styles.iconWrapper} style={{ color: '#FFC107' }}>
              <MoneyOffIcon />
            </div>
            <div className={styles.cardInfo}>
              <h3 className={styles.cardValue}>${formatCurrency(unpaidTotal)}</h3>
              <p className={styles.cardLabel}>Unpaid totals</p>
            </div>
          </div>
        </div>
      </Grid>

      <Grid item xs={12} sm={6} md={3}>
        <div className={styles.summaryCard}>
          <div className={styles.cardContent}>
            <div className={styles.iconWrapper} style={{ color: '#00AB55' }}>
              <HourglassEmptyIcon />
            </div>
            <div className={styles.cardInfo}>
              <h3 className={styles.cardValue}>{averagePaymentTime} days</h3>
              <p className={styles.cardLabel}>Average paid time</p>
            </div>
          </div>
        </div>
      </Grid>
    </Grid>
  );

  const headerCells = [
    { 
      label: 'CUSTOMER', 
      align: 'center',
      icon: <Person sx={{ color: '#FF4842', marginRight: 1 }} />,
      sortable: true,
      onClick: () => {
        setSort(sort === 'customerAsc' ? 'customerDesc' : 'customerAsc');
      }
    },
    { 
      label: 'COMPANY', 
      align: 'center',
      icon: <Business sx={{ color: '#00AB55', marginRight: 1 }} />,
      sortable: true,
      onClick: () => {
        setSort(sort === 'companyAsc' ? 'companyDesc' : 'companyAsc');
      }
    },
    { 
      label: 'NUMBER', 
      align: 'center',
      icon: <Description sx={{ color: '#2065D1', marginRight: 1 }} />
    },
    { 
      label: 'DATE', 
      align: 'center',
      icon: <CalendarToday sx={{ color: '#FFC107', marginRight: 1 }} />,
      sortable: true,
      onClick: () => {
        setSort(sort === 'newest' ? 'oldest' : 'newest');
      },
      sortDirection: sort
    },
    { 
      label: 'TOTAL', 
      align: 'center',
      icon: <AttachMoney sx={{ color: '#00AB55', marginRight: 1 }} />
    },
    { 
      label: 'STATUS', 
      align: 'center',
      icon: <CheckCircleOutline sx={{ color: '#00AB55', marginRight: 1 }} />
    },
    { 
      label: '', 
      align: 'center',
      icon: null
    },
  ];

  const renderTableRows = () => {
    return sortedInvoices.map((invoice) => (
      <TableRow 
        key={invoice.id} 
        className={styles.tableRow}
        onClick={() => navigate(`/admin/invoices/${invoice.id}`)}
        sx={{ 
          '&:last-child td, &:last-child th': { border: 0 },
        }}
      >
        <TableCell align="center">{invoice.customer_details?.name || 'Unknown'}</TableCell>
        <TableCell align="center">{invoice.customer_details?.company || 'N/A'}</TableCell>
        <TableCell align="center">#{invoice.invoice_number || 'N/A'}</TableCell>
        <TableCell align="center">
          {invoice.issue_date ? new Date(invoice.issue_date).toLocaleDateString() : 'N/A'}
        </TableCell>
        <TableCell align="center">
          ${formatCurrency(calculateTotal(invoice))}
        </TableCell>
        <TableCell align="center">
          <Chip 
            label={invoice.status || 'Pending'}
            color={getStatusColor(invoice.status)}
            size="small"
          />
        </TableCell>
        <TableCell align="center">
          <IconButton size="small">
            <MoreVertIcon />
          </IconButton>
        </TableCell>
      </TableRow>
    ));
  };

  const getStatusColor = (status) => {
    if (!status) return 'default';
    
    switch (status.toLowerCase()) {
      case 'paid': return 'success';
      case 'draft': return 'default';
      case 'overdue': return 'error';
      default: return 'primary';
    }
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const sortedInvoices = [...filteredInvoices].sort((a, b) => {
    switch (sort) {
      case 'customerAsc':
        return (a.customer_details?.name || '').localeCompare(b.customer_details?.name || '');
      case 'customerDesc':
        return (b.customer_details?.name || '').localeCompare(a.customer_details?.name || '');
      case 'companyAsc':
        return (a.customer_details?.company || '').localeCompare(b.customer_details?.company || '');
      case 'companyDesc':
        return (b.customer_details?.company || '').localeCompare(a.customer_details?.company || '');
      case 'newest':
        return new Date(b.issue_date) - new Date(a.issue_date);
      case 'oldest':
        return new Date(a.issue_date) - new Date(b.issue_date);
      default:
        return 0;
    }
  });

  if (loading) return <div className={styles.loadingContainer}><CircularProgress /></div>;
  if (error) return <div className={styles.errorContainer}><Typography color="error">{error}</Typography></div>;

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Container maxWidth="xl" className={styles.mainContainer}>
        <div className={styles.invoicesContainer}>
          <Box display="flex" justifyContent="space-between" alignItems="center" mb={4}>
            <Typography variant="h5" className={styles.title}>Invoices</Typography>
            <div className={styles.createButton}>
              <span className={styles.separator}></span>
              <Button
                variant="contained"
                color="primary"
                startIcon={<AddIcon />}
                onClick={handleCreateInvoice}
                className={styles.createButton}
              >
                CREATE INVOICE
              </Button>
            </div>
          </Box>

          {summaryCards}

          <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
            <div className={styles.leftSection}>
              <div className={styles.searchContainer}>
                <SearchIcon className={styles.searchIcon} />
                <input
                  type="text"
                  placeholder="Search invoices..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className={styles.searchInput}
                />
              </div>
            </div>

            <div className={styles.rightSection}>
              <div className={styles.filterChips}>
                <Box display="flex" alignItems="center" gap={1}>
                  <Chip
                    label={`All (${invoices.length})`}
                    onClick={() => setActiveFilter('all')}
                    variant={activeFilter === 'all' ? 'filled' : 'outlined'}
                    className={`${styles.filterChip} ${activeFilter === 'all' ? styles.activeChip : ''}`}
                  />
                  <Chip
                    label={`Unpaid (${unpaidInvoices.length})`}
                    onClick={() => setActiveFilter('unpaid')}
                    variant={activeFilter === 'unpaid' ? 'filled' : 'outlined'}
                    className={`${styles.filterChip} ${activeFilter === 'unpaid' ? styles.activeChip : ''}`}
                  />
                  <Chip
                    label={`Unsent (${unsentInvoices.length})`}
                    onClick={() => setActiveFilter('unsent')}
                    variant={activeFilter === 'unsent' ? 'filled' : 'outlined'}
                    className={`${styles.filterChip} ${activeFilter === 'unsent' ? styles.activeChip : ''}`}
                  />
                </Box>
              </div>
            </div>
          </Box>

          <TableContainer className={styles.tableContainer}>
            <Table>
              <TableHead>
                <TableRow>
                  {headerCells.map((cell, index) => (
                    <TableCell 
                      key={index} 
                      align="center"
                      className={styles.tableHeaderCell}
                      onClick={cell.onClick}
                      style={{ cursor: cell.sortable ? 'pointer' : 'default' }}
                    >
                      <Box display="flex" alignItems="center" justifyContent="center">
                        {cell.icon}
                        {cell.label}
                        {cell.sortable && (
                          <Box display="flex" flexDirection="column" ml={1} sx={{ lineHeight: 0 }}>
                            <KeyboardArrowUpIcon 
                              sx={{ 
                                fontSize: '1.2rem',
                                color: (sort === 'customerAsc' && cell.label === 'CUSTOMER') || 
                                       (sort === 'companyAsc' && cell.label === 'COMPANY') || 
                                       (sort === 'oldest' && cell.label === 'DATE') 
                                    ? 'primary.main' 
                                    : 'text.disabled'
                              }} 
                            />
                            <KeyboardArrowDownIcon 
                              sx={{ 
                                fontSize: '1.2rem',
                                marginTop: '-8px',
                                color: (sort === 'customerDesc' && cell.label === 'CUSTOMER') || 
                                       (sort === 'companyDesc' && cell.label === 'COMPANY') || 
                                       (sort === 'newest' && cell.label === 'DATE')
                                    ? 'primary.main' 
                                    : 'text.disabled'
                              }} 
                            />
                          </Box>
                        )}
                      </Box>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {renderTableRows()}
              </TableBody>
            </Table>
          </TableContainer>
         </div>
      </Container>
    </LocalizationProvider>
  );
};

export default AdminInvoices;
