import React from 'react';
import { Box, Typography, Paper } from '@mui/material';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';

const ThinkingAnimation = () => {
  return (
    <Paper
      sx={{
        backgroundColor: 'white',
        width: '100%',
        borderRadius: '12px'
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          p: 4,
          textAlign: 'center',
          backgroundColor: 'white'
        }}
      >
        <Box
          sx={{
            position: 'relative',
            animation: 'pulse 2s infinite'
          }}
        >
          <AutoAwesomeIcon
            sx={{
              fontSize: 40,
              color: 'primary.main',
              animation: 'spin 4s linear infinite'
            }}
          />
        </Box>
        <Typography
          variant="h6"
          sx={{
            mt: 2,
            color: 'text.secondary',
            fontWeight: 500
          }}
        >
          Thinking...
        </Typography>
        <Box
          sx={{
            display: 'flex',
            gap: 1,
            mt: 1
          }}
        >
          {[0, 1, 2].map((i) => (
            <Box
              key={i}
              sx={{
                width: 8,
                height: 8,
                borderRadius: '50%',
                backgroundColor: 'primary.main',
                animation: `bounce 1.4s infinite ${i * 0.2}s`
              }}
            />
          ))}
        </Box>
        <style>
          {`
            @keyframes pulse {
              0% { transform: scale(1); }
              50% { transform: scale(1.1); }
              100% { transform: scale(1); }
            }
            @keyframes spin {
              0% { transform: rotate(0deg); }
              100% { transform: rotate(360deg); }
            }
            @keyframes bounce {
              0%, 80%, 100% { transform: translateY(0); }
              40% { transform: translateY(-10px); }
            }
          `}
        </style>
      </Box>
    </Paper>
  );
};

export default ThinkingAnimation; 