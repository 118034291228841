import React, { useState } from 'react';
import { 
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Box,
  IconButton,
  Typography
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';

const SaveModal = ({ open, onClose, onSave, website }) => {
  const [websiteName, setWebsiteName] = useState(website?.name || '');
  const [description, setDescription] = useState(website?.description || '');

  const handleSave = () => {
    onSave({
      name: websiteName,
      description: description
    });
  };

  return (
    <Dialog 
      open={open} 
      onClose={onClose}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>
        Save Website
        <IconButton
          onClick={onClose}
          sx={{ position: 'absolute', right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box sx={{ pt: 2 }}>
          <Typography variant="subtitle2" gutterBottom>
            Please provide website details
          </Typography>
          <TextField
            autoFocus
            fullWidth
            label="Website Name"
            value={websiteName}
            onChange={(e) => setWebsiteName(e.target.value)}
            margin="normal"
            required
          />
          <TextField
            fullWidth
            label="Description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            margin="normal"
            multiline
            rows={3}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button 
          onClick={handleSave}
          variant="contained" 
          color="primary"
          disabled={!websiteName.trim()}
        >
          Save Website
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SaveModal;