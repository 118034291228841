import React, { useState } from 'react';
import {
  Box,
  Chip,
  FormControl,
  InputAdornment,
  TextField,
  styled,
  Typography,
} from '@mui/material';
import { LocalOffer as TagIcon } from '@mui/icons-material';

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    borderRadius: 8,
    backgroundColor: theme.palette.background.paper,
    transition: 'all 0.2s ease-in-out',
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
    '&.Mui-focused': {
      backgroundColor: theme.palette.background.paper,
      boxShadow: `0 0 0 2px ${theme.palette.primary.main}25`,
    }
  }
}));

const StyledChip = styled(Chip)(({ theme }) => ({
  borderRadius: 16,
  backgroundColor: theme.palette.primary.light,
  color: theme.palette.primary.dark,
  transition: 'all 0.2s ease-in-out',
  '&:hover': {
    transform: 'translateY(-1px)',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  '& .MuiChip-deleteIcon': {
    color: 'inherit',
    '&:hover': {
      color: 'inherit',
      opacity: 0.8,
    }
  }
}));

const TagsSection = ({ tags = [], onChange }) => {
  const [newTag, setNewTag] = useState('');

  const handleAddTag = () => {
    if (newTag.trim() && !tags.includes(newTag.trim())) {
      onChange([...tags, newTag.trim()]);
      setNewTag('');
    }
  };

  const handleDeleteTag = (tagToDelete) => {
    onChange(tags.filter((tag) => tag !== tagToDelete));
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleAddTag();
    }
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2.5 }}>
      <FormControl fullWidth>
        <StyledTextField
          fullWidth
          placeholder="Type a tag and press Enter"
          value={newTag}
          onChange={(e) => setNewTag(e.target.value)}
          onKeyPress={handleKeyPress}
          InputProps={{
            startAdornment: <TagIcon sx={{ mr: 1, color: 'action.active' }} />,
          }}
        />
      </FormControl>

      {tags.length > 0 && (
        <Box sx={{ 
          display: 'flex', 
          flexWrap: 'wrap', 
          gap: 1,
          p: 2,
          border: '1px solid',
          borderColor: 'divider',
          borderRadius: 2,
          minHeight: 50,
          backgroundColor: 'background.paper',
          boxShadow: '0 1px 3px 0 rgb(0 0 0 / 0.1)',
        }}>
          {tags.map((tag) => (
            <StyledChip
              key={tag}
              label={tag}
              onDelete={() => handleDeleteTag(tag)}
            />
          ))}
        </Box>
      )}

      {!tags.length && (
        <Typography 
          variant="body2" 
          color="text.secondary"
          sx={{ 
            textAlign: 'center',
            py: 3,
            border: '1px dashed',
            borderColor: 'divider',
            borderRadius: 2,
            backgroundColor: 'grey.50',
          }}
        >
          No tags added yet
        </Typography>
      )}
    </Box>
  );
};

export default TagsSection;