import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Paper, 
  Typography, 
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Chip,
  Grid,
  CircularProgress,
  Alert,
  Tab,
  Tabs
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import { getEstimates } from '../../../services/api';
import ConversionChart from './components/ConversionChart';
import EstimateValueChart from './components/EstimateValueChart';
import { styled } from '@mui/material/styles';

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  '& .MuiTableCell-head': {
    fontWeight: 600,
    backgroundColor: theme.palette.background.default,
    fontFamily: 'Urbanist, sans-serif',
  },
  '& .MuiTableCell-body': {
    fontFamily: 'Urbanist, sans-serif',
  }
}));

const EstimateConversionView = ({ onBack }) => {
  const [estimates, setEstimates] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
    const fetchEstimates = async () => {
      try {
        setLoading(true);
        const data = await getEstimates();
        setEstimates(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
    fetchEstimates();
  }, []);

  const prepareMonthlyData = () => {
    const monthlyData = estimates.reduce((acc, est) => {
      const date = new Date(est.issue_date);
      const monthKey = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}`;
      
      if (!acc[monthKey]) {
        acc[monthKey] = {
          period: new Date(date.getFullYear(), date.getMonth(), 1).toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'long'
          }),
          estimates: 0,
          converted: 0,
          value: 0
        };
      }
      
      acc[monthKey].estimates += 1;
      if (est.client_signature) {
        acc[monthKey].converted += 1;
        const items = Array.isArray(est.items) ? est.items : [];
        acc[monthKey].value += items.reduce((sum, item) => {
          return sum + (Number(item.quantity) || 0) * (Number(item.unit_price) || 0);
        }, 0);
      }
      
      return acc;
    }, {});

    return Object.values(monthlyData)
      .map(data => ({
        ...data,
        rate: `${((data.converted / data.estimates) * 100).toFixed(1)}%`,
        trend: data.converted > (data.estimates * 0.5) ? 'Increasing' : 'Decreasing'
      }))
      .sort((a, b) => new Date(b.period) - new Date(a.period));
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  if (loading) return <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}><CircularProgress /></Box>;
  if (error) return <Box sx={{ p: 3 }}><Alert severity="error">{error}</Alert></Box>;

  const conversionData = prepareMonthlyData();

  return (
    <Box sx={{ p: 3 }}>
      <Box sx={{ mb: 4, display: 'flex', alignItems: 'center' }}>
        <IconButton onClick={onBack} sx={{ mr: 2 }}>
          <ArrowBackIcon />
        </IconButton>
        <TrendingUpIcon sx={{ mr: 2, color: 'primary.main' }} />
        <Typography variant="h5" sx={{ fontFamily: 'Urbanist, sans-serif', fontWeight: 600 }}>
          Estimate Conversion Rate
        </Typography>
      </Box>

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper elevation={0} sx={{ p: 3, mb: 3, borderRadius: 2 }}>
            <Tabs 
              value={activeTab} 
              onChange={handleTabChange}
              sx={{ 
                mb: 3,
                '& .MuiTab-root': { 
                  fontFamily: 'Urbanist, sans-serif',
                  textTransform: 'none'
                }
              }}
            >
              <Tab label="Conversion Rate" />
              <Tab label="Value Distribution" />
            </Tabs>
            <Box sx={{ height: 300 }}>
              {activeTab === 0 ? (
                <ConversionChart estimates={estimates} height={300} />
              ) : (
                <EstimateValueChart estimates={estimates} height={300} />
              )}
            </Box>
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Paper elevation={0} sx={{ p: 3, mb: 3, borderRadius: 2 }}>
            <StyledTableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Period</TableCell>
                    <TableCell align="right">Total Estimates</TableCell>
                    <TableCell align="right">Converted</TableCell>
                    <TableCell align="right">Rate</TableCell>
                    <TableCell align="right">Value</TableCell>
                    <TableCell>Trend</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {conversionData.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell>{row.period}</TableCell>
                      <TableCell align="right">{row.estimates}</TableCell>
                      <TableCell align="right">{row.converted}</TableCell>
                      <TableCell align="right">{row.rate}</TableCell>
                      <TableCell align="right">${row.value.toFixed(2)}</TableCell>
                      <TableCell>
                        <Chip 
                          label={row.trend} 
                          color={row.trend === 'Increasing' ? 'success' : 'warning'} 
                          size="small" 
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </StyledTableContainer>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default EstimateConversionView; 