import React from 'react';
import { styled } from '@mui/material/styles';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Typography,
  Grid,
  Box,
  Card,
  CardContent,
  Tabs,
  Tab,
} from '@mui/material';
import { Close as CloseIcon, Preview as PreviewIcon } from '@mui/icons-material';

const TemplateCard = styled(Card)(({ theme, primaryColor = '#2196f3' }) => ({
  border: '2px solid #e0e0e0',
  borderRadius: '12px',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  overflow: 'visible',
  '&:before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    height: '6px',
    background: `linear-gradient(90deg, ${primaryColor}, ${primaryColor}88)`,
    borderRadius: '12px 12px 0 0',
  },
  '&:hover': {
    borderColor: primaryColor,
    transform: 'translateY(-4px)',
    boxShadow: `0 6px 16px ${primaryColor}25`,
  },
  transition: 'all 0.3s ease',
}));

const allTemplates = {
  business: {
    contact: {
      name: "Contact Form",
      description: "Standard contact form with essential fields",
      category: "Business",
      fields: [/* ... existing fields ... */]
    },
    feedback: {
      name: "Customer Feedback",
      description: "Collect detailed customer feedback with ratings",
      category: "Business",
      fields: [/* ... existing fields ... */]
    },
    quote: {
      name: "Quote Request",
      description: "Detailed form for quote requests",
      category: "Business",
      fields: [
        { type: "text", label: "Company Name", required: true },
        { type: "text", label: "Contact Person", required: true },
        { type: "email", label: "Business Email", required: true },
        { type: "tel", label: "Phone Number", required: true },
        { type: "select", label: "Industry", required: true,
          options: ["Technology", "Manufacturing", "Retail", "Services", "Other"] },
        { type: "textarea", label: "Project Details", required: true },
        { type: "select", label: "Budget Range", required: true,
          options: ["$1,000-$5,000", "$5,000-$10,000", "$10,000-$50,000", "$50,000+"] }
      ]
    }
  },
  events: {
    registration: {
      name: "Event Registration",
      description: "Complete event registration form",
      category: "Events",
      fields: [
        { type: "text", label: "Attendee Name", required: true },
        { type: "email", label: "Email", required: true },
        { type: "tel", label: "Phone", required: true },
        { type: "select", label: "Ticket Type", required: true,
          options: ["Standard", "VIP", "Group", "Student"] },
        { type: "checkbox", label: "Dietary Restrictions",
          options: ["Vegetarian", "Vegan", "Gluten-Free", "None"] },
        { type: "textarea", label: "Special Requirements" }
      ]
    },
    rsvp: {
      name: "RSVP Form",
      description: "Simple RSVP form for events",
      category: "Events",
      fields: [/* ... fields ... */]
    }
  },
  surveys: {
    satisfaction: {
      name: "Customer Satisfaction",
      description: "Comprehensive customer satisfaction survey",
      category: "Surveys",
      fields: [/* ... fields ... */]
    },
    feedback: {
      name: "Product Feedback",
      description: "Detailed product feedback form",
      category: "Surveys",
      fields: [/* ... fields ... */]
    }
  }
};

const AllTemplatesModal = ({ open, onClose, onTemplateSelect, formData }) => {
  const [category, setCategory] = React.useState('business');

  const handleCategoryChange = (event, newValue) => {
    setCategory(newValue);
  };

  return (
    <Dialog 
      open={open} 
      onClose={onClose}
      maxWidth="lg"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: formData.style.borderRadius,
          backgroundColor: formData.style.backgroundColor,
          maxHeight: '90vh'
        }
      }}
    >
      <DialogTitle sx={{ 
        p: 2,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderBottom: '1px solid #e0e0e0'
      }}>
        <Typography variant="h6">All Templates</Typography>
        <IconButton onClick={onClose} size="small">
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs 
          value={category} 
          onChange={handleCategoryChange}
          sx={{ px: 2 }}
        >
          <Tab label="Business" value="business" />
          <Tab label="Events" value="events" />
          <Tab label="Surveys" value="surveys" />
        </Tabs>
      </Box>

      <DialogContent sx={{ p: 3 }}>
        <Grid container spacing={2}>
          {Object.entries(allTemplates[category] || {}).map(([key, template]) => (
            <Grid item xs={12} sm={6} md={4} key={key}>
              <TemplateCard 
                primaryColor={formData.style.primaryColor}
                secondaryColor={formData.style.secondaryColor}
                borderRadius={formData.style.borderRadius}
              >
                <CardContent sx={{ 
                  p: 3,
                  backgroundColor: formData.style.backgroundColor 
                }}>
                  {/* Template card content similar to main view */}
                  <Box sx={{ 
                    display: 'flex', 
                    justifyContent: 'space-between', 
                    alignItems: 'flex-start',
                    mb: 2 
                  }}>
                    <Typography variant="h6" sx={{ 
                      fontWeight: 600,
                      color: formData.style.primaryColor,
                      fontSize: '1.1rem'
                    }}>
                      {template.name}
                    </Typography>
                    <IconButton 
                      onClick={() => {
                        onTemplateSelect(key);
                        onClose();
                      }}
                      sx={{
                        bgcolor: formData.style.primaryColor,
                        color: 'white',
                        '&:hover': {
                          bgcolor: formData.style.secondaryColor || formData.style.primaryColor,
                        },
                      }}
                    >
                      <PreviewIcon />
                    </IconButton>
                  </Box>
                  
                  <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                    {template.description}
                  </Typography>

                  <Box sx={{ 
                    p: 2, 
                    bgcolor: '#f5f5f5', 
                    borderRadius: '8px',
                    border: '1px dashed #bdbdbd'
                  }}>
                    <Typography variant="subtitle2" sx={{ mb: 1, color: '#666' }}>
                      Fields Preview:
                    </Typography>
                    <Box component="ul" sx={{ m: 0, pl: 2 }}>
                      {template.fields.slice(0, 3).map((field, idx) => (
                        <Typography 
                          key={idx} 
                          component="li" 
                          variant="caption"
                          sx={{ color: '#666' }}
                        >
                          {field.label} {field.required && '*'}
                        </Typography>
                      ))}
                      {template.fields.length > 3 && (
                        <Typography variant="caption" sx={{ color: '#666', fontStyle: 'italic' }}>
                          +{template.fields.length - 3} more fields...
                        </Typography>
                      )}
                    </Box>
                  </Box>
                </CardContent>
              </TemplateCard>
            </Grid>
          ))}
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default AllTemplatesModal; 