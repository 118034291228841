import React, { useState } from 'react';
import { Box, Tabs, Tab, Paper, Typography, Fade } from '@mui/material';
import BusinessIcon from '@mui/icons-material/Business';
import PaymentIcon from '@mui/icons-material/Payment';
import DescriptionIcon from '@mui/icons-material/Description';
import SupportIcon from '@mui/icons-material/Support';
import AppSettingsAltIcon from '@mui/icons-material/AppSettingsAlt';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import GavelIcon from '@mui/icons-material/Gavel';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import InventoryIcon from '@mui/icons-material/Inventory';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { Link } from 'react-router-dom';
import { useTheme } from '../ThemeContext';
import styles from './AdminSettings.module.scss';

// Import components
import AdminSettingsBusinessDetails from './AdminSettingsBusinessDetails';
import AdminSettingsPayment from './AdminSettingsPayment';
import TermsTemplatesManager from './settings/TermsTemplatesManager';
import AdminSettingsSupport from './AdminSettingsSupport';
import AdminSettingsAppSettings from './AdminSettingsAppSettings';
import AdminSettingsMyMoney from './AdminSettingsMyMoney';
import AdminSettingsCompliance from './AdminSettingsCompliance';
import AdminSettingsPayroll from './AdminSettingsPayroll';
import SavedItemsManagerSettings from './settings/SavedItemsManagerSettings';
import AdminSettingsNotifications from './AdminSettingsNotifications';

function AdminSettings() {
  const [activeTab, setActiveTab] = useState(0);
  const { darkMode } = useTheme();

  const menuItems = [
    { icon: <BusinessIcon />, label: "Business Profile", component: AdminSettingsBusinessDetails },
    { icon: <PaymentIcon />, label: "Payment", component: AdminSettingsPayment },
    { icon: <DescriptionIcon />, label: "Terms & Conditions", component: TermsTemplatesManager },
    { icon: <SupportIcon />, label: "Support", component: AdminSettingsSupport },
    { icon: <AppSettingsAltIcon />, label: "App Settings", component: AdminSettingsAppSettings },
    { icon: <AccountBalanceWalletIcon />, label: "My Money", component: AdminSettingsMyMoney },
    { icon: <GavelIcon />, label: "Compliance", component: AdminSettingsCompliance },
    { icon: <MonetizationOnIcon />, label: "Payroll", component: AdminSettingsPayroll },
    { icon: <InventoryIcon />, label: "Saved Items", component: SavedItemsManagerSettings },
    { icon: <NotificationsIcon />, label: "Notifications", component: AdminSettingsNotifications }
  ];

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <div className={`${styles.adminSettings} ${darkMode ? styles.darkMode : ''}`}>
      <div className={`${styles.header} ${darkMode ? styles.darkMode : ''}`}>
        <div className={styles.headerContent}>
          <Link to="/admin/dashboard" className={`${styles.backLink} ${darkMode ? styles.darkBackLink : ''}`}>
            <ArrowBackIcon /> Back to Dashboard
          </Link>
          <div className={styles.titleRow}>
            <Typography variant="h4" className={`${styles.title} ${darkMode ? styles.darkMode : ''}`}>
              Admin Settings
            </Typography>
          </div>
        </div>
      </div>

      <div className={`${styles.tabsWrapper} ${darkMode ? styles.darkMode : ''}`}>
        <Tabs 
          value={activeTab} 
          onChange={handleTabChange} 
          variant="scrollable"
          scrollButtons="auto"
          allowScrollButtonsMobile
          aria-label="admin settings tabs"
          TabIndicatorProps={{
            style: {
              backgroundColor: darkMode ? "#90caf9" : "#1976d2"
            }
          }}
          sx={{
            '& .MuiTab-root': {
              color: darkMode ? 'rgba(255, 255, 255, 0.7)' : 'rgba(0, 0, 0, 0.7)',
              '&.Mui-selected': {
                color: darkMode ? '#90caf9' : '#1976d2',
              },
            },
            '& .MuiTabs-scrollButtons': {
              color: darkMode ? 'rgba(255, 255, 255, 0.7)' : 'rgba(0, 0, 0, 0.7)',
            },
          }}
        >
          {menuItems.map((item, index) => (
            <Tab 
              key={index}
              icon={item.icon} 
              label={item.label}
              aria-label={item.label}
            />
          ))}
        </Tabs>
      </div>

      <div className={`${styles.contentWrapper} ${darkMode ? styles.darkMode : ''}`}>
        {menuItems.map((item, index) => (
          <Fade 
            key={index} 
            in={activeTab === index} 
            timeout={500}
          >
            <div 
              className={`${styles.tabPanel} ${darkMode ? styles.darkMode : ''}`} 
              style={{ display: activeTab === index ? 'block' : 'none' }}
            >
              <Paper 
                className={`${styles.settingsContainer} ${darkMode ? styles.darkSettingsContainer : ''}`}
                elevation={darkMode ? 2 : 1}
                sx={{
                  backgroundColor: darkMode ? 'var(--background-primary)' : '#fff'
                }}
              >
                <Box sx={{ p: 3 }}>
                  {React.createElement(item.component, { darkMode })}
                </Box>
              </Paper>
            </div>
          </Fade>
        ))}
      </div>
    </div>
  );
}

export default AdminSettings;
