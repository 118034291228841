import React from 'react';
import { Paper, List, Box, Divider } from '@mui/material';
import { styled } from '@mui/material/styles';
import ConversationSearch from './ConversationSearch';
import ContactItem from './ContactItem';

const ContactsList = styled(Paper)(({ theme }) => ({
  width: 320,
  height: 'calc(100vh - 280px)',
  borderRadius: 12,
  overflow: 'hidden',
  border: `1px solid ${theme.palette.divider}`,
  backgroundColor: theme.palette.background.paper
}));

const ContactsSidebar = ({ selectedContact, setSelectedContact, searchTerm, setSearchTerm }) => {
  const contacts = [
    { 
      id: 1, 
      name: 'John Doe', 
      lastMessage: "Thanks for the follow-up", 
      status: 'online',
      unread: 2,
      time: '10:30 AM',
      type: 'email'
    },
    // Add more contacts...
  ];

  return (
    <ContactsList>
      <Box sx={{ p: 2, borderBottom: 1, borderColor: 'divider' }}>
        <ConversationSearch 
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
        />
      </Box>
      <List>
        {contacts.map((contact, index) => (
          <React.Fragment key={contact.id}>
            <ContactItem
              contact={contact}
              selected={selectedContact?.id === contact.id}
              onClick={() => setSelectedContact(contact)}
            />
            {index < contacts.length - 1 && <Divider />}
          </React.Fragment>
        ))}
      </List>
    </ContactsList>
  );
};

export default ContactsSidebar; 