import React from 'react';
import { styled } from '@mui/material/styles';
import {
  Box,
  Typography,
  TextField,
  Button,
  Rating,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  FormControlLabel,
  Checkbox,
  Select,
  MenuItem,
  InputLabel,
} from '@mui/material';
import DOMPurify from 'dompurify';

const PreviewContainer = styled(Box)(({ theme }) => ({
  backgroundColor: '#ffffff',
  borderRadius: '12px',
  border: '2px solid #e0e0e0',
  padding: theme.spacing(4),
  position: 'relative',
  '&:before': {
    content: '"Preview"',
    position: 'absolute',
    top: '-12px',
    left: '20px',
    padding: '4px 12px',
    backgroundColor: theme.palette.primary.main,
    color: '#ffffff',
    borderRadius: '12px',
    fontSize: '0.875rem',
    fontWeight: 600,
    boxShadow: '0 2px 4px rgba(33, 150, 243, 0.3)',
  }
}));

const FormPreviewSection = ({ formData }) => {
  const createMarkup = (html) => {
    return { __html: DOMPurify.sanitize(html) };
  };

  const fieldWrapper = {
    p: 2,
    mb: 2,
    backgroundColor: '#ffffff',
    borderRadius: formData.style.borderRadius,
    border: '1px solid #e0e0e0',
    '&:hover': {
      borderColor: formData.style.primaryColor,
      boxShadow: '0 2px 8px rgba(0,0,0,0.08)',
    },
    transition: 'all 0.2s ease',
  };

  const renderField = (field) => {
    const commonInputProps = {
      fullWidth: true,
      size: "small",
      required: field.required,
      placeholder: field.placeholder,
      sx: {
        '& .MuiOutlinedInput-root': {
          backgroundColor: '#ffffff',
          transition: 'all 0.2s ease-in-out',
          '& fieldset': {
            borderWidth: '1.5px',
            borderColor: '#e0e0e0',
          },
          '&:hover fieldset': {
            borderColor: formData.style.primaryColor,
          },
          '&.Mui-focused fieldset': {
            borderColor: formData.style.primaryColor,
            borderWidth: '2px',
          },
        },
        '& .MuiInputLabel-root': {
          color: '#666',
          '&.Mui-focused': {
            color: formData.style.primaryColor,
          },
        },
        '& .MuiInputBase-input': {
          padding: '10px 14px',
          fontSize: '0.95rem',
        },
        '& .MuiSelect-select': {
          padding: '10px 14px',
        },
        mb: 2
      }
    };

    switch (field.type) {
      case 'select':
        return (
          <FormControl {...commonInputProps}>
            <Select
              displayEmpty
              defaultValue=""
              sx={{
                '& .MuiSelect-select': {
                  padding: '10px 14px',
                }
              }}
            >
              <MenuItem value="" disabled>
                <em>Select {field.label}</em>
              </MenuItem>
              {field.options?.map((option, idx) => (
                <MenuItem key={idx} value={option}>{option}</MenuItem>
              ))}
            </Select>
          </FormControl>
        );

      case 'radio':
        return (
          <FormControl component="fieldset" sx={{ mt: 1 }}>
            <RadioGroup>
              {field.options?.map((option, idx) => (
                <FormControlLabel
                  key={idx}
                  value={option}
                  control={
                    <Radio 
                      size="small"
                      sx={{
                        color: '#bdbdbd',
                        '&.Mui-checked': {
                          color: formData.style.primaryColor,
                        },
                      }}
                    />
                  }
                  label={option}
                  sx={{ 
                    my: 0.5,
                    '& .MuiFormControlLabel-label': {
                      fontSize: '0.95rem',
                      color: '#424242',
                    }
                  }}
                />
              ))}
            </RadioGroup>
          </FormControl>
        );

      case 'checkbox':
        return (
          <FormControlLabel
            control={
              <Checkbox 
                size="small"
                sx={{
                  color: '#bdbdbd',
                  '&.Mui-checked': {
                    color: formData.style.primaryColor,
                  },
                }}
              />
            }
            label={field.label}
            sx={{ 
              '& .MuiFormControlLabel-label': {
                fontSize: '0.95rem',
                color: '#424242',
              }
            }}
          />
        );

      case 'rating':
        return (
          <Rating 
            name={`rating-${field.label}`}
            sx={{ 
              mt: 1,
              '& .MuiRating-iconFilled': {
                color: formData.style.primaryColor,
              },
              '& .MuiRating-iconHover': {
                color: formData.style.primaryColor,
              }
            }}
          />
        );

      case 'textarea':
        return (
          <TextField
            {...commonInputProps}
            multiline
            rows={3}
            sx={{
              ...commonInputProps.sx,
              '& .MuiOutlinedInput-root': {
                ...commonInputProps.sx['& .MuiOutlinedInput-root'],
                '& textarea': {
                  padding: '12px 14px',
                }
              }
            }}
          />
        );

      default:
        return (
          <TextField
            {...commonInputProps}
            type={field.type}
          />
        );
    }
  };

  return (
    <PreviewContainer>
      <Box sx={{ mt: 2 }}>
        <Typography 
          variant="h5" 
          component="h2"
          dangerouslySetInnerHTML={createMarkup(formData.name)}
          sx={{ mb: 2, fontWeight: 600 }}
        />
        
        {formData.description && (
          <Typography 
            variant="body1" 
            sx={{ mb: 4 }}
            dangerouslySetInnerHTML={createMarkup(formData.description)}
          />
        )}

        <Box sx={{ 
          '& > *:not(:last-child)': { 
            mb: 3 
          }
        }}>
          {formData.fields?.map((field, index) => (
            <Box 
              key={index}
              sx={fieldWrapper}
            >
              <Typography 
                variant="subtitle1" 
                component="div"
                sx={{ mb: 1, fontWeight: 500 }}
                dangerouslySetInnerHTML={createMarkup(field.label)}
              />
              {renderField(field)}
            </Box>
          ))}
        </Box>
      </Box>
    </PreviewContainer>
  );
};

export default FormPreviewSection;