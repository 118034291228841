import React from 'react';
import {
  Box,
  TextField,
  Typography,
  Switch,
  FormControlLabel,
} from '@mui/material';

const FormSettingsSection = ({ formData, onChange }) => {
  const handleChange = (field) => (event) => {
    onChange({
      ...formData,
      settings: {
        ...formData.settings,
        [field]: event.target.value
      }
    });
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
      <Typography variant="subtitle1" sx={{ mb: 1 }}>Form Settings</Typography>
      
      <TextField
        fullWidth
        label="Submit Button Text"
        value={formData.settings.submitButtonText}
        onChange={handleChange('submitButtonText')}
      />

      <TextField
        fullWidth
        label="Success Message"
        value={formData.settings.successMessage}
        onChange={handleChange('successMessage')}
      />

      <TextField
        fullWidth
        label="Redirect URL"
        value={formData.settings.redirectUrl}
        onChange={handleChange('redirectUrl')}
      />

      <FormControlLabel
        control={
          <Switch
            checked={formData.settings.enableRecaptcha}
            onChange={(e) => onChange({
              ...formData,
              settings: {
                ...formData.settings,
                enableRecaptcha: e.target.checked
              }
            })}
          />
        }
        label="Enable reCAPTCHA"
      />
    </Box>
  );
};

export default FormSettingsSection;