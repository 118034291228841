import React from 'react';
import { 
  Box, Typography, Avatar, IconButton
} from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import BusinessIcon from '@mui/icons-material/Business';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import EditIcon from '@mui/icons-material/Edit';
import TagsSection from '../TagsSection';
import styles from './ClientSidebar.module.scss';

const ClientSidebar = ({ client, onEditClick }) => {
  // Add phone formatting function
  const formatPhoneNumber = (value) => {
    if (!value) return '';
    
    // Remove all non-digits
    const phoneNumber = value.replace(/[^\d]/g, '');
    
    // Get length of the input
    const phoneNumberLength = phoneNumber.length;
    
    // Return if length is less than 4
    if (phoneNumberLength < 4) return phoneNumber;
    
    // Return formatted phone number
    if (phoneNumberLength < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }
    
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
  };

  return (
    <div className={styles.sidebar} style={{ backgroundColor: 'var(--background-default)' }}>
      {/* Client Profile Section */}
      <Box className={styles.profileSection} sx={{ 
        backgroundColor: 'var(--background-default)',
        p: 2
      }}>
        <Avatar
          src={client.avatar_url}
          alt={client.name}
          className={styles.avatar}
        />
        <Box className={styles.profileInfo}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Typography variant="h6" sx={{ color: 'var(--text-primary)' }}>
              {client.name}
            </Typography>
            <IconButton 
              onClick={onEditClick}
              sx={{ 
                ml: 'auto',
                color: 'var(--primary-main)',
                '&:hover': {
                  backgroundColor: 'var(--hover-overlay)'
                }
              }}
              size="small"
            >
              <EditIcon fontSize="small" />
            </IconButton>
          </Box>
          <Typography variant="body2" sx={{ color: 'var(--text-secondary)' }}>
            {client.company || ''}
          </Typography>
        </Box>
      </Box>

      {/* Client Details Section */}
      <Box className={styles.detailsSection} sx={{ 
        p: 2,
        backgroundColor: 'var(--background-default)'
      }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          {/* Email Box */}
          <Box sx={{ 
            display: 'flex',
            alignItems: 'center',
            gap: 2,
            p: 2,
            backgroundColor: 'var(--background-default)',
            borderRadius: '8px',
            border: '1px solid var(--divider)',
            '&:hover': {
              backgroundColor: 'var(--hover-overlay)'
            }
          }}>
            <EmailIcon sx={{ color: 'var(--primary-main)' }} />
            <Box>
              <Typography variant="caption" sx={{ color: 'var(--text-secondary)' }}>
                Email
              </Typography>
              <Typography variant="body2" sx={{ color: 'var(--text-primary)' }}>
                {client.email || 'No email provided'}
              </Typography>
            </Box>
          </Box>

          {/* Phone Box */}
          <Box sx={{ 
            display: 'flex',
            alignItems: 'center',
            gap: 2,
            p: 2,
            backgroundColor: 'var(--background-default)',
            borderRadius: '8px',
            border: '1px solid var(--divider)',
            '&:hover': {
              backgroundColor: 'var(--hover-overlay)'
            }
          }}>
            <PhoneIcon sx={{ color: 'var(--success-main)' }} />
            <Box>
              <Typography variant="caption" sx={{ color: 'var(--text-secondary)' }}>
                Phone
              </Typography>
              <Typography variant="body2" sx={{ color: 'var(--text-primary)' }}>
                {client.phone ? formatPhoneNumber(client.phone) : 'No phone provided'}
              </Typography>
            </Box>
          </Box>

          {/* Company Box */}
          <Box sx={{ 
            display: 'flex',
            alignItems: 'center',
            gap: 2,
            p: 2,
            backgroundColor: 'var(--background-default)',
            borderRadius: '8px',
            border: '1px solid var(--divider)',
            '&:hover': {
              backgroundColor: 'var(--hover-overlay)'
            }
          }}>
            <BusinessIcon sx={{ color: 'var(--info-main)' }} />
            <Box>
              <Typography variant="caption" sx={{ color: 'var(--text-secondary)' }}>
                Company
              </Typography>
              <Typography variant="body2" sx={{ color: 'var(--text-primary)' }}>
                {client.company || 'No company provided'}
              </Typography>
            </Box>
          </Box>

          {/* Address Box */}
          <Box sx={{ 
            display: 'flex',
            alignItems: 'flex-start',
            gap: 2,
            p: 2,
            backgroundColor: 'var(--background-default)',
            borderRadius: '8px',
            border: '1px solid var(--divider)',
            '&:hover': {
              backgroundColor: 'var(--hover-overlay)'
            }
          }}>
            <LocationOnIcon sx={{ color: 'var(--error-main)' }} />
            <Box>
              <Typography variant="caption" sx={{ color: 'var(--text-secondary)' }}>
                Address
              </Typography>
              <Typography variant="body2" sx={{ color: 'var(--text-primary)' }}>
                {client.address || 'No address provided'}
                {client.city && `, ${client.city}`}
                {client.state && `, ${client.state}`}
                {client.zip_code && ` ${client.zip_code}`}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>

      {/* Tags Section */}
      <Box className={styles.tagsSection} sx={{ 
        p: 2,
        backgroundColor: 'var(--background-default)'
      }}>
        <TagsSection 
          entityType="clients"
          entityId={client.id}
        />
      </Box>
    </div>
  );
};

export default ClientSidebar; 